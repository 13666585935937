import { Spinner } from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import React, { FC, useEffect, useMemo, useState, forwardRef, useImperativeHandle } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { OptionLayer } from "../../../../../components/option-layer/OptionLayer";
import { VERTICALS } from "../../../../../config-global";
import {
  MoviesType,
  useGetPantheonRanking,
} from "../api/hooks/useGetPantheonRankingData";
import {
  combineMappedResponses,
  mapGetPantheonResponse,
} from "../api/mappers/mapGetPantheonResponse";

const LIMIT = 100;

const verticalMap = {
  [VERTICALS.MOVIES]: "movies",
  [VERTICALS.SERIES]: "series",
  [VERTICALS.GAMES]: "gaming",
};

interface GRPantheonRankingProps {
  selectedIpIds: Array<any>;
  setSelectedIpIds: (selectedIpIds: any) => void;
  setSplitPanelStatus: (status: boolean) => void;
  filterQuery: any;
  vertical: string;
  weightageValues: any;
  dateRange: any;
  useInfiniteScroll?: boolean;
  onItemCountSet?: (count: number) => void;
  sortField?: string;
  sortOrder?: string;
}

export const GRPantheonRanking: FC<GRPantheonRankingProps> = forwardRef(({
  selectedIpIds = [],
  setSelectedIpIds = null,
  setSplitPanelStatus,
  filterQuery,
  vertical,
  weightageValues,
  dateRange,
  useInfiniteScroll = true,
  onItemCountSet = null,
  sortField = "score",
  sortOrder = "desc",
}, ref) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [listData, setListData] = useState(null);
  const [innerSelectedIpIds, setInnerSelectedIpIds] = useState(selectedIpIds);

  const limit = useMemo(
    () => (useInfiniteScroll === true ? LIMIT : 1000),
    [useInfiniteScroll],
  );

  const {
    error: pantheonDataError,
    data: pantheonData,
    isLoading: pantheonDataLoading,
  } = useGetPantheonRanking({
    filterQuery,
    sort: sortField,
    vertical: verticalMap[vertical] as MoviesType,
    sortOrder: sortOrder,
    offset: currentPageIndex * limit,
    paginationPageSize: limit,
    startTs: dateRange?.startDate ? moment.utc(dateRange.startDate).unix() : null,
    endTs: dateRange?.endDate ? moment.utc(dateRange.endDate).unix() : null,
    weightageValues,
  });

  const fetchMoreData = () => {
    if (!pantheonDataLoading && hasMore) {
      setCurrentPageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleSetSelectedIpIds = (selectedIpIds) => {
    setInnerSelectedIpIds(selectedIpIds);
    setSelectedIpIds(selectedIpIds);
  };

  useImperativeHandle(ref, () => ({ setInnerSelectedIpIds }));

  useEffect(() => {
    if (!pantheonData) return;
    const mappedData = mapGetPantheonResponse(pantheonData);
    if (currentPageIndex === 0) {
      setListData(mappedData);
    } else {
      setListData((prevItems) => combineMappedResponses(prevItems, mappedData));
    }
    setHasMore(pantheonData.data.total >= limit);
    if (onItemCountSet) {
      onItemCountSet(pantheonData.data.total);
    }
  }, [pantheonData]);

  useEffect(() => {
    setCurrentPageIndex(0);
    setHasMore(true);
  }, [dateRange, sortField, sortOrder]);

  useEffect(() => {
    if (onItemCountSet && pantheonDataLoading) {
      onItemCountSet(-1);
    }
  }, [pantheonDataLoading]);

  useEffect(() => {
    if (!_.isEqual(selectedIpIds, innerSelectedIpIds)) {
      setInnerSelectedIpIds(selectedIpIds);
    }
  }, [selectedIpIds]);

  return (
    <div>
      {pantheonDataLoading && currentPageIndex === 0 && (
        <div className="flex flex-row justify-center h-48">
          <CustomLoadingOverlay />
        </div>
      )}
      {pantheonDataError && (
        <div className="flex justify-center my-20">
          <div>
            {`Error loading data: 
            ${pantheonDataError?.message}`}
          </div>
        </div>
      )}
      {!(pantheonDataLoading && currentPageIndex === 0) &&
        !pantheonDataError &&
        (useInfiniteScroll ? (
          <InfiniteScroll
            style={{ overflow: "100%" }}
            dataLength={listData?.data?.length || 0}
            next={fetchMoreData}
            hasMore={hasMore}
            loader={
              <div className="flex justify-center my-10">
                <Spinner size="large" />
              </div>
            }
            endMessage={<p></p>}
          >
            <OptionLayer
              optionData={listData?.data?.map((y) => ({ ...y, vertical }))}
              selectedIpIds={innerSelectedIpIds}
              setSelectedIpIds={handleSetSelectedIpIds}
              setSplitPanelStatus={setSplitPanelStatus}
            />
          </InfiniteScroll>
        ) : (
          <OptionLayer
            optionData={listData?.data?.map((y) => ({ ...y, vertical }))}
            selectedIpIds={innerSelectedIpIds}
            setSelectedIpIds={handleSetSelectedIpIds}
            setSplitPanelStatus={setSplitPanelStatus}
          />
        ))}
    </div>
  );
});
