import { CognitoUser, CognitoUserPool } from "amazon-cognito-identity-js";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ResetPasswordLayout } from "../../layouts/ResetPasswordLayout";
// form
import { SpaceBetween } from "@cloudscape-design/components";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import FormProvider from "../../components/forms/FormProvider";
import LoadingButton from "../../components/forms/LoadingButton";
import OtpInputController from "../../components/forms/OtpInputController";
import PasswordTextField from "../../components/forms/PasswordTextField";
import { TextField } from "../../components/forms/TextField";
import { COGNITO_API } from "../../config-global";
import SnackbarContext from "../../hooks/notify/snack-bar/snackbar-context";
const userPool = new CognitoUserPool({
  UserPoolId: COGNITO_API.userPoolId || "",
  ClientId: COGNITO_API.clientId || "",
});
export const ResetPasswordPage = () => {
  const snackbarCtx = useContext(SnackbarContext);
  const navigate = useNavigate();
  const emailRecovery =
    typeof window !== "undefined" ? sessionStorage.getItem("email") : "";
  const resetPasswordSchemaDefaultValues = {
    code1: "",
    code2: "",
    code3: "",
    code4: "",
    code5: "",
    code6: "",
    email: emailRecovery || "",
    password: "",
    confirmPassword: "",
  };

  const resetPasswordSchema = Yup.object().shape({
    code1: Yup.string().required("Code is required"),
    code2: Yup.string().required("Code is required"),
    code3: Yup.string().required("Code is required"),
    code4: Yup.string().required("Code is required"),
    code5: Yup.string().required("Code is required"),
    code6: Yup.string().required("Code is required"),
    email: Yup.string()
      .required("Email is required")
      .email("Email must be a valid email address"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .required("Confirm password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const methods = useForm({
    mode: "onChange",
    resolver: yupResolver(resetPasswordSchema),
    defaultValues: resetPasswordSchemaDefaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isLoading, errors },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const userData = new CognitoUser({
        Username: data.email,
        Pool: userPool,
      });

      await userData.confirmPassword(
        `${data.code1}${data.code2}${data.code3}${data.code4}${data.code5}${data.code6}`,
        data.password,
        {
          onSuccess: (result) => {
            sessionStorage.removeItem("email");
            snackbarCtx.displayMsg(
              "Success",
              "Change password success!",
              "success",
              3000,
            );

            navigate("/");
          },
          onFailure: (error) => {
            snackbarCtx.displayMsg("Success", error.message, "error", 3000);
          },
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ResetPasswordLayout>
      <Helmet>
        <title> Reset Password | Pantheon</title>
      </Helmet>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SpaceBetween direction="vertical" size="s">
          <TextField
            name={"email"}
            label="Email"
            placeholder={"Email"}
            type={"text"}
          />
          <OtpInputController
            keyName="code"
            inputs={["code1", "code2", "code3", "code4", "code5", "code6"]}
          />
          {(!!errors.code1 ||
            !!errors.code2 ||
            !!errors.code3 ||
            !!errors.code4 ||
            !!errors.code5 ||
            !!errors.code6) && (
            <p className="mt-2 text-sm text-red-600">Code is required</p>
          )}
          <PasswordTextField
            name={"password"}
            label="Password"
            placeholder={"password"}
          />
          <PasswordTextField
            name={"confirmPassword"}
            label="Confirm Password"
            placeholder={"Confirm Password"}
          />
          <div className="mt-5">
            <LoadingButton
              text={"Update Password"}
              loading={isSubmitting}
              type={"submit"}
              classes={"bg-blue-600"}
            />
          </div>
        </SpaceBetween>
      </FormProvider>
      <div className="flex items-center justify-center mt-5">
        <div className="text-sm">
          <a
            href="/"
            className="font-medium text-blue-500 hover:text-blue-500 flex flex-row justify-center items-center"
          >
            <ChevronLeftIcon className="h-4 w-4" /> Back to login
          </a>
        </div>
      </div>
    </ResetPasswordLayout>
  );
};
