import { ExpandableSection } from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React, { FC } from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { useGetRecentVideos } from "../../api/hooks/useGetRecentVideos";
import { columnDefs } from "../TopVideos/gridColumnDefs";
import { defaultColDef } from "../TopVideos/gridDefaultColDef";
import { EmptyGrid } from "../../../../../components/grid/EmptyGrid";

interface RecentVideosProps {
  channelId: string;
}

const RecentVideos: FC<RecentVideosProps> = ({ channelId }) => {
  const { theme } = useGetGridTheme();
  const { data: videos } = useGetRecentVideos({
    channelId,
    sort: { publishedAt: "desc" },
  });

  if (videos?.data?.length === 0) return;

  return (
    <ExpandableSection
      defaultExpanded
      disableContentPaddings
      variant="container"
      headerText="Recent Videos"
    >
      <hr className="border-slate-600 border-t-[1px]" />
      <div className={`${theme} compact`}>
        <AgGridReact
          rowData={videos?.data}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          domLayout="autoHeight"
          loadingOverlayComponent={() => (
            <CustomLoadingOverlay compact={true} />
          )}
          noRowsOverlayComponent={EmptyGrid}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          suppressContextMenu={true}
        />
      </div>
    </ExpandableSection>
  );
};

export default React.memo(RecentVideos);
