import { dispatch } from "../../../../../app/store";
import { fetchAmazonVendorTimeseriesData } from "../slices/get-timeseries-slice";
import { fetchAmazonVendorTopProductsData } from "../slices/get-top-products-slice";
import { fetchAutoComplete } from "../slices/autocomplete-slice";
import { fetchWikipediaTopics } from "../slices/wikipedia-topic-search-slice";
import { fetchAmazonVendorTermSuggestionsData } from "../slices/get-term-suggestions";
import { fetchAmazonVendorTermPoolList } from "../slices/get-term-pool-list-slice";
import { fetchAmazonVendorTopCategoriesData } from "../slices/get-top-categories-slice";

export async function getAmazonVendorTimeseriesData(params) {
  await dispatch(fetchAmazonVendorTimeseriesData({ params }));
}

export async function getAmazonVendorTopProductsData(params) {
  await dispatch(fetchAmazonVendorTopProductsData({ params }));
}

export async function getAmazonVendorTopCategoriesData(params) {
  await dispatch(fetchAmazonVendorTopCategoriesData({ params }));
}

export async function getAutoComplete(params) {
  await dispatch(fetchAutoComplete({ params }));
}

export async function getWikipediaTopics(params) {
  await dispatch(fetchWikipediaTopics({ params }));
}

export async function getSuggestedTopicTerms(params) {
  await dispatch(fetchAmazonVendorTermSuggestionsData({ params }));
}

export async function getTermPools() {
  await dispatch(fetchAmazonVendorTermPoolList());
}