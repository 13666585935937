import { CHART_LABELS } from "../../hooks/useGridConfig";

export const defaultColumn = CHART_LABELS.PLAYERS_COUNT_TRENDS;

export const additionalPlatformColumns = [
  CHART_LABELS.FRANCHISE,
  CHART_LABELS.PLATFORM,
  CHART_LABELS.GENRE,
  CHART_LABELS.THEME,
  CHART_LABELS.CUSTOM_TAGS,
  CHART_LABELS.DEVELOPER,
  CHART_LABELS.PUBLISHER,
  CHART_LABELS.AGE_RATINGS,
  CHART_LABELS.COUNTRIES,
  CHART_LABELS.PLAYER_PERSPECTIVE,
  CHART_LABELS.GAME_MODE,
  CHART_LABELS.PLAYERS_COUNT_TRENDS,
  CHART_LABELS.POSITIVE_REVIEW_TRENDS,
  CHART_LABELS.RECOMMENDATIONS_TRENDS
];

export const corePlatformColumns = [
  CHART_LABELS.AVG_PLAYER_COUNT,
  CHART_LABELS.PEAK_PLAYER_COUNT,
  CHART_LABELS.RECOMMENDATIONS,
  CHART_LABELS.POSITIVE_REVIEWS,
  CHART_LABELS.NEGATIVE_REVIEWS,
];
