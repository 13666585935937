import { Container, ExpandableSection, FormField, Header, Link, Select, SelectProps, SpaceBetween, Toggle } from '@cloudscape-design/components';
import { AgGridReact } from 'ag-grid-react';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  parseISO,
  startOfWeek,
  subDays
} from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CustomLoadingOverlay from '../../../../../components/PantheonLoading';
import { useGetGridTheme } from '../../../../../hooks/UseTheme/useGetGridTheme';
import shortenInteger from '../../../../../utils/shortenInteger';
import useFetchData from './hooks/useFetchData';
import useFetchSummaryData from './hooks/useFetchSummaryData';
import { NetflixSummaryChartsGrid } from './NetflixSummaryChartGrid';
const WeeksInTop10Renderer = React.memo<any>(({ value }) => (
  <div className="flex items-center">
    <span className="mr-2">{value}</span>
    <div className="text-blue-500 text-xl font-extrabold">{'|'.repeat(value)}</div>
  </div>
));

const useWeeklyIntervals = () => {
  return useMemo(() => {
    const intervals = [];
    const startDate = new Date(2022, 0, 3);
    const currentDate = new Date();
    let currentWeekStart = startOfWeek(startDate, { weekStartsOn: 1 });

    while (isBefore(currentWeekStart, currentDate)) {
      const weekEnd = addDays(currentWeekStart, 6);
      const intervalString = `${format(currentWeekStart, 'MMMM d')} - ${format(
        weekEnd,
        'MMMM d, yyyy'
      )}`;
      intervals.push({
        label: intervalString,
        value: format(currentWeekStart, 'yyyy-MM-dd'),
        endDate: weekEnd,
      });
      currentWeekStart = addDays(currentWeekStart, 7);
    }

    if (intervals.length > 0 && !isAfter(currentDate, intervals[intervals.length - 1].endDate)) {
      intervals.pop();
    }

    return intervals.reverse();
  }, []);
};

const mapTitle = (vertical: string, language: string) => {
  const isDomestic = language === 'en';
  if (vertical === 'Films') {
    return isDomestic ? 'Movies - Domestic' : 'Movies - International';
  } else if (vertical === 'TV') {
    return isDomestic ? 'TV Shows - Domestic' : 'TV Shows - International';
  }
  return `${vertical} - ${language}`;
};

const WeeklyComponent: React.FC = () => {
  const {
    data: weeklyData,
    loading: weeklyLoading,
    error: weeklyError,
    fetchData: fetchWeeklyData
  } = useFetchData();
  const {
    data: summaryData,
    isLoading: summaryLoading,
    error: summaryError
  } = useFetchSummaryData();

  const [groupedData, setGroupedData] = useState<{ [key: string]: { [key: string]: any[] } }>({});
  const { theme } = useGetGridTheme();
  const [selectedWeek, setSelectedWeek] = useState<string | null>(null);
  const weeklyIntervals = useWeeklyIntervals();
  const [showViews, setShowViews] = useState(false);

  const tables = useMemo(() =>
    Object.entries(groupedData).flatMap(([vertical, languages]) =>
      Object.entries(languages).map(([language, items]) => ({
        title: mapTitle(vertical, language),
        data: items,
      }))
    ), [groupedData]);

  const handleWeekChange = useCallback<SelectProps['onChange']>((event) => {
    setSelectedWeek(event.detail.selectedOption.value);
  }, []);

  const columnDefs = useMemo(
    () => [
      { headerName: '#', field: 'weekly_rank', maxWidth: 50 },
      {
        headerName: 'Title',
        field: 'title',
        flex: 2,
        cellRenderer: (params: any) => {
          const title =
            params.data.vertical === 'TV'
              ? params.data.season_title || params.data.title
              : params.data.title;
          return params.data.ip_id ? (
            <Link href={`/item/${params.data.ip_id}`}>{title}</Link>
          ) : (
            title
          );
        },
      },
      {
        headerName: 'Runtime',
        field: 'runtime',
        valueFormatter: (params: any) => {
          if (!params.value) return '';
          const rounded = Math.round(params.value * 10) / 10;
          return rounded % 1 === 0 ? `${Math.floor(rounded)}h` : `${rounded.toFixed(1)}h`;
        },
      },
      {
        headerName: 'Hours Viewed',
        field: 'weekly_hours_viewed',
        valueFormatter: (params: any) => shortenInteger(params.value).toString(),
      },
      {
        headerName: 'Views',
        field: 'weekly_views',
        minWidth: 100,
        valueFormatter: (params: any) => shortenInteger(params.value).toString(),
      },
      {
        headerName: 'Weeks in Top 10',
        field: 'cumulative_weeks_in_top_10',
        cellRenderer: WeeksInTop10Renderer,
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: true,
      wrapHeaderText: false,
      autoHeaderHeight: false,
    }),
    []
  );

  useEffect(() => {
    if (weeklyIntervals.length > 0 && !selectedWeek) {
      setSelectedWeek(weeklyIntervals[0].value);
    }
  }, [weeklyIntervals, selectedWeek]);

  const selectedWeekEpoch = useMemo(() => {
    if (!selectedWeek) return null;

    let selectedWeekDate = parseISO(selectedWeek + 'T00:00:00Z');
    selectedWeekDate = subDays(selectedWeekDate, 1);

    return Math.floor(selectedWeekDate.getTime() / 1000);
  }, [selectedWeek]);

  useEffect(() => {
    if (selectedWeekEpoch !== null) {
      fetchWeeklyData(selectedWeekEpoch);
    }
  }, [fetchWeeklyData, selectedWeekEpoch]);

  useEffect(() => {
    if (Array.isArray(weeklyData)) {
      const grouped = weeklyData.reduce((acc, item) => {
        acc[item.vertical] = acc[item.vertical] || {};
        acc[item.vertical][item.language] = acc[item.vertical][item.language] || [];
        acc[item.vertical][item.language].push(item);
        return acc;
      }, {} as { [key: string]: { [key: string]: any[] } });
      setGroupedData(grouped);
    }
  }, [weeklyData]);

  if (weeklyError) return <div>Error: {weeklyError.message}</div>;
  return (
    <SpaceBetween direction="vertical" size="s">
      <ExpandableSection
        defaultExpanded={true}
        headerText="Global Top 10 Summary Over Time"
        headerDescription="Hours viewed and views for the top 10 most-viewed titles on Netflix over time"
        headerActions={
            <Toggle
              onChange={({ detail }) => setShowViews(detail.checked)}
              checked={showViews}
            >
              {showViews ? "View Count" : "Hours Viewed"}
            </Toggle>
        }
      >
        <NetflixSummaryChartsGrid data={summaryData} showViews={showViews} />
      </ExpandableSection>
      <ExpandableSection
        defaultExpanded={true}
        headerText="Global Top 10"
        headerDescription="Top 10 most viewed titles on Netflix for the selected week"
        headerActions={
          <div>
            <Select
              expandToViewport
              selectedOption={
                weeklyIntervals.find(
                  (interval) => interval.value === selectedWeek,
                ) || null
              }
              onChange={handleWeekChange}
              options={weeklyIntervals}
              placeholder="Select a week"
            />
          </div>
        }
      >
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-2">
          {tables.map((table, index) => (
            <Container disableContentPaddings fitHeight key={index}>
              <div className="font-bold text-lg bg-slate-200 p-2 dark:bg-slate-800 text-center truncate rounded-t-2xl">
                {table.title}
              </div>
              <hr className="border-slate-600 border-t-[1px]" />
              <div className={`${theme} compact h-85`}>
                <AgGridReact
                  rowData={table.data}
                  defaultColDef={defaultColDef}
                  suppressDragLeaveHidesColumns={true}
                  loading={weeklyLoading}
                  loadingOverlayComponent={CustomLoadingOverlay}
                  columnDefs={columnDefs}
                  rowHeight={31.6}
                  suppressContextMenu={true}
                />
              </div>
            </Container>
          ))}
        </div>
      </ExpandableSection>
    </SpaceBetween>
  );
};

export default WeeklyComponent;
