export interface GetImdbDataResponseItem {
  timestamp: number;
  daily_rank: number;
  daily_votes: number;
  votes: number;
  rating: number;
  daily_rating: number;
  rank: number;
}

export interface MappedGetImdbResponseItem {
  dailyRank: number;
  timestamp: number;
  dailyVotes: number;
  allTimeVotes: number;
  allTimeRating: number;
  dailyRating: number;
  allTimeRank: number;
}

export const mapImdbResponse = (item: GetImdbDataResponseItem) => ({
  dailyRank: item.daily_rank,
  timestamp: item.timestamp * 1000,
  dailyVotes: item.daily_votes,
  allTimeVotes: item.votes,
  allTimeRating: item.rating,
  dailyRating: item.daily_rating,
  allTimeRank: item.rank,
});

export const IMDB_GRID_HEADER_MAP = {
  dailyRank: "Daily rank",
  dailyVotes: "Daily votes",
  dailyRating: "Daily rating",
  allTimeRank: "All time rank",
  allTimeVotes: "All time votes",
  allTimeRating: "All time rating",
};
