import { TrendsContent, TrendsFooter, TrendsHeader } from "../component/Trends";

const title = "Youtube Movies Trends";

export const youtubeMovies = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Daily Youtube Trailer views for movies",
    header: () => <TrendsHeader title={title} />,
    content: () => <TrendsContent vertical="movies" platform="youtube" sort="daily_rank" />,
    footer: () => <TrendsFooter linkHref="/platforms/youtube?tab=trends&category=movies" />,
  },
};