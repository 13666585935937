import { createSlice } from "@reduxjs/toolkit";

const rolesListInitialState= {
    isRolesListLoading:false,
    rolesList:[],
    isRolesListLoadingCompleted:false,
    isRolesListError: false,
    message:""
}


const rolesSlice = createSlice({
    name:"rolesList",
    initialState:rolesListInitialState,
    reducers:{
        loading:(state)=>{
            state.isRolesListLoading = true;
            state.rolesList = [];
        },
        insertRolesList: (state, action) => {
            state.isRolesListLoading = false;
            state.rolesList = action.payload;
            state.isRolesListLoadingCompleted = true;
            state.isRolesListError = false;
        },
        clearRolesList:() => {
            return rolesListInitialState;
        },
        insertError:(state, action) => {
            state.isRolesListLoading = false;
            state.message = action.payload;
            state.isRolesListLoadingCompleted = true;
            state.isRolesListError = false;
        },
    }
});

export default rolesSlice.reducer;

export const roleSliceActions = rolesSlice.actions;