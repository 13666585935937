import axiosInstance from "../../../../../utils/axios";

export interface GetPersonalListDataProps {
  category: string;
}

// Remove the DEFAULT_API_PARAMS constant as we won't need it anymore

export const getPersonalListData = async ({
  category,
}: GetPersonalListDataProps) => {
  return axiosInstance.get(`/report`, {
    params: { category },
  });
};
