import { Autosuggest } from "@cloudscape-design/components";
import React, { FC, useContext, useEffect } from "react";
import "./AutoSuggestEditor.css";
import { YoutubeVideosContext } from "../../Layout";
import { useGetTopSearchData } from "../../../../components/CommonSearch/api/hooks/useGetTopSearchData";
import { VERTICALS } from "../../../../config-global";
import { convertData } from "../../../../components/datapoint-comparison/utils";

type AutoSuggestEditorProps = {
  videoId: string;
};

export const AutoSuggestEditor: FC<AutoSuggestEditorProps> = ({ videoId }) => {
  const [value, setValue] = React.useState("");
  const { modifiedData, setModifiedData } = useContext(YoutubeVideosContext);
  const { data: topSearchData, isLoading, error } = useGetTopSearchData({ 
    keyword: value, 
    fields: ["ip", "ip_id", "vertical", "release_date", "image_url"], 
    verticals: [VERTICALS.GAMES, VERTICALS.MOVIES, VERTICALS.SERIES],
  });

  useEffect(() => {
    if (Object.keys(modifiedData).length === 0) {
      setValue("");
    } else {
      const modifiedValue = modifiedData[videoId];
      if (modifiedValue) {
        setValue(modifiedValue.label);
      }
    }
  }, [modifiedData]);

  const getStatusType = () => {
    if (error) return "error";
    if (isLoading) return "loading";
    if (topSearchData && topSearchData.length > 0) return "finished";

    return "pending";
  };

  return (
    <Autosuggest
      onChange={({ detail }) => {
        setValue(detail.value);

        if (detail.value === "") {
          delete modifiedData[videoId];
          setModifiedData({ ...modifiedData });
        }
      }}
      onSelect={({ detail }) => {
        const label = detail.selectedOption.label;
        const value = detail.selectedOption.value;
        const newModifiedValue = {
          ...modifiedData,
          [videoId]: {
            label,
            value,
          },
        };

        setModifiedData(newModifiedValue);
      }}
      value={value}
      options={topSearchData ? convertData(topSearchData) : []}
      ariaLabel="Autosuggest example with suggestions"
      placeholder="Search for a title"
      empty="No matches found"
      expandToViewport
      statusType={getStatusType()}
    />
  );
};
