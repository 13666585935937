export const organizationFilteringProperties = [
  {
    propertyLabel: "Name",
    key: "name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Email",
    key: "email",
    groupValuesLabel: "Email",
    operators: [":", "!:", "=", "!="],
  },
].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));
export const organizationVisibleContentPreference = [
  {
    id: "name",
    label: "Name",
    visible: true,
  },
  {
    id: "email",
    label: "Email",
    visible: true,
  },
  {
    id: "org_status",
    label: "Status",
    visible: true,
  },

  { id: "created", label: "Created At" },
  { id: "last_updated", label: "Updated At" },
];
