import { Button, SpaceBetween } from "@cloudscape-design/components";
import { useState } from "react";
import ReactQuill from "react-quill";
import { qModules } from "../modules/bug-reporting/constants";

export const EditComment = ({ user, comment, onSubmit, onCancel }) => {
  const [editComment, setEditComment] = useState(comment.comment);
  return (
    <div className=" flex-1">
      <ReactQuill
        value={editComment}
        modules={qModules}
        onChange={(newDesc) => setEditComment(newDesc)}
      />
      <div className="flex justify-end pt-4">
        <SpaceBetween direction="horizontal" size="m">
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!editComment}
            onClick={() => {
              onSubmit({
                comment: editComment,
                id: comment.id,
                issueId: comment.issue_id,
              });
            }}
          >
            Submit
          </Button>
        </SpaceBetween>
      </div>
    </div>
  );
};
