import React, { useMemo } from "react";
import moment from "moment";

const numberShortFormatter = (n, decimalPlaces=1) => {
  n = parseFloat(n);
  if (n > 0.0 && n < 1.0) return n.toFixed(2);
  if (n >= 1.0 && n < 1e3) return n.toFixed(decimalPlaces);
  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(decimalPlaces) + "K";
  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(decimalPlaces) + "M";
  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(decimalPlaces) + "B";
  if (n >= 1e12) return +(n / 1e12).toFixed(decimalPlaces) + "T";
  return n.toFixed(decimalPlaces);
};

export const useGenreTrendsChartConfig = ({
  genreInfo,
  genreCounts,
  selectedMetric="counts",
  visibleGenreValues=[],
}) => {
  const genres = genreInfo?.map(g => g.value);

  const genreCountsData = useMemo(() => {
    return genreCounts ? Object.keys(genreCounts).map((date) => {
      return {
        date: moment(date).toDate(),
        ...Object.fromEntries(genres.map((genre, i) => [genre, genreCounts[date][i]])),
      };
    }) : [];
  }, [genreCounts, genres]);
  
  const genreDistributionData = useMemo(() => {
    return genreCounts ? Object.keys(genreCounts).map((date) => {
      const total = genreCounts[date].reduce((a, b) => a + b, 0);
      return {
        date: moment(date).toDate(),
        ...Object.fromEntries(genres.map((genre, i) => [genre, genreCounts[date][i] / total * 100])),
      };
    }) : [];
  }, [genreCounts, genres]);

  const series = useMemo(() => {
    return genreInfo ? genreInfo.filter(genre => visibleGenreValues.includes(genre.value)).map(genre => (
      {
        type: "area",
        xKey: "date",
        yKey: genre.value,
        yName: `${genre.label} (${genre.vertical})`,
        marker: {
          enabled: false,
        },
        stacked: true,
        normalizedTo: selectedMetric === "distribution" ? 100 : undefined,
        tooltip: {
          renderer: ({ datum, xKey, yKey }) => {
            return {
              title: moment(datum[xKey]).format("MMMM YYYY"),
              content: `${genre.label} (${genre.vertical}): ${selectedMetric === "counts" ? numberShortFormatter(datum[yKey], 0) : `${datum[yKey].toFixed(2)}%`}`,
            };
          },
        },
      }
    )) : [];
  }, [genreInfo, visibleGenreValues]);

  const axes = useMemo(() => {
    return [
      {
        type: "time",
        position: "bottom",
        nice: false,
        interval: {
          minSpacing: 50,
          maxSpacing: 200,
        },
      },
      {
        type: "number",
        position: "left",
        title: {
          text: selectedMetric === "counts" ? "Number of Titles Per Genre" : "Distribution Percent of Titles Per Genre",
        },
        label: {
          formatter: (params) => selectedMetric === "counts" ? numberShortFormatter(params.value, 0) : `${params.value}%`,
        },
        crosshair: {
          label: {
            renderer: ({ value, fractionDigits}) => (
              {
                text: selectedMetric === "counts" ? numberShortFormatter(value, 0) : `${value.toFixed(2)}%`,
              }
            ),
          },
        },
      },
    ];
  }, [selectedMetric]);

  return {
    data: selectedMetric === "counts" ? genreCountsData : genreDistributionData,
    series: series,
    axes: axes,
  };
};