import React from 'react';

const CompareIcon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_105_1836)">
      <path d="M13 3.99976H6C4.89543 3.99976 4 4.89519 4 5.99976V17.9998C4 19.1043 4.89543 19.9998 6 19.9998H13M17 3.99976H18C19.1046 3.99976 20 4.89519 20 5.99976V6.99976M20 16.9998V17.9998C20 19.1043 19.1046 19.9998 18 19.9998H17M20 10.9998V12.9998M12 1.99976V21.9998" stroke="#292929" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_105_1836">
        <rect fill="white" height="24" transform="translate(0 -0.000244141)" width="24" />
      </clipPath>
    </defs>
  </svg>
);

export default CompareIcon;
