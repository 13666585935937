import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getBoByStudioData } from "../request";
import { isSomeOrElse } from "../../../../../../utils/sugarUtils";

export const useGetBOData = (year) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_BOBYSTUDIO_DATA, year], 
    () => getBoByStudioData(year), 
    {
      enabled: !!year,
    }
  );

  return {
    data: isSomeOrElse(data, []),
    isLoading,
    error,
  };
};