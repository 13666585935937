import { ExpandableSection, Toggle } from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import React, { useEffect, useState } from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import {
    CHART_TYPES,
    useGetChartTheme,
} from "../../../../hooks/UseTheme/useGetChartTheme";
import { useFetchWeeklyCategoryData } from "../hooks/useFetchData";

const colorPalette = [
    "#FF9AA2", // Soft Red
    "#C7CEEA", // Soft Blue
    "#FFDAC1", // Light Peach
    "#B5EAD7", // Mint
    "#FFB7B2", // Pale Pink
    "#A2D2FF", // Sky Blue
    "#E2F0CB", // Pale Green
    "#F2D5F8", // Lavender
    "#FFE5B9", // Pale Yellow
    "#D4E4BC", // Sage Green
    "#FEC8D8", // Pale Rose
    "#CDB4DB", // Light Purple
    "#FCE1E4", // Blush Pink
    "#BDE0FE", // Light Blue
    "#D8E2DC", // Light Gray-Green
];

export const CategoryChart = () => {
    const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
    const { loading, error, fetchWeeklyCategoryData } = useFetchWeeklyCategoryData();
    const [options, setOptions] = useState(null);
    const [isMonthly, setIsMonthly] = useState(false);
    const [rawData, setRawData] = useState(null);

    useEffect(() => {
        const loadWeeklyData = async () => {
            try {
                const data = await fetchWeeklyCategoryData();
                setRawData(data);
                processData(data, isMonthly);
            } catch (error) {
                console.error("Error fetching weekly data:", error);
            }
        };

        loadWeeklyData();
    }, [fetchWeeklyCategoryData]);

    useEffect(() => {
        if (rawData) {
            processData(rawData, isMonthly);
        }
    }, [isMonthly, rawData]);

    const processData = (weeklyData, monthly) => {
        if (weeklyData && weeklyData.length > 0) {
            let processedData = weeklyData[0].data.map((item) => ({
                date: new Date(item.date.split('/')[0]),
            }));

            weeklyData.forEach((category) => {
                category.data.forEach((item, index) => {
                    processedData[index][category.category] = item.value;
                });
            });

            if (monthly) {
                processedData = aggregateToMonthly(processedData);
            }

            processedData = processedData.map(item => {
                const total = weeklyData.reduce((sum, category) => sum + (item[category.category] || 0), 0);
                const normalizedItem = { date: item.date };
                weeklyData.forEach(category => {
                    normalizedItem[category.category] = (item[category.category] / total) * 100;
                });
                return normalizedItem;
            });

            const tooltip = {
                showArrow: true,
                position: {
                    type: 'pointer',
                    xOffset: 160,
                },
                renderer: ({ datum, xKey }) => {
                    const date = new Date(datum[xKey]);
                    const formattedDate = date.toLocaleDateString('en-US', {
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric'
                    });

                    const categoryValues = weeklyData
                        .map((category, index) => ({
                            category: category.category,
                            value: datum[category.category],
                            color: colorPalette[index % colorPalette.length]
                        }))
                        .filter(item => item.value !== undefined);

                    categoryValues.sort((a, b) => b.value - a.value);

                    let content = categoryValues
                        .map(item => `
                            <div style="display: flex; align-items: center; margin-bottom: 4px;">
                                <div style="width: 12px; height: 12px; background-color: ${item.color}; margin-right: 8px;"></div>
                                <b>${item.category}:</b> ${Number(item.value).toFixed(2)}%
                            </div>
                        `)
                        .join('');

                    return {
                        title: `<span style="font-size: 14px;">${formattedDate}</span>`,
                        content: `<div style="padding: 5px;">${content}</div>`,
                    };
                },
            };

            const newOptions = {
                theme: theme,
                data: processedData,
                series: weeklyData.map((category, index) => ({
                    type: "area",
                    xKey: "date",
                    yKey: category.category,
                    yName: category.category,
                    stacked: true,
                    normalizedTo: 100,
                    fillOpacity: 1,
                    fill: colorPalette[index % colorPalette.length],

                    tooltip: tooltip,
                })),

                axes: [
                    {
                        position: "bottom",
                        type: "time",
                        label: {
                            format: monthly ? "%Y-%m" : "%Y-%m-%d",
                        },
                    },
                    {
                        position: "left",
                        type: "number",
                        label: {
                            formatter: (params) => {
                                return Number(params.value).toFixed(0) + '%';
                            },
                        },
                        min: 0,
                        max: 100,
                    },
                ],
                legend: { position: 'bottom' },
                zoom: {
                    enabled: true,
                },

            };
            setOptions(newOptions);
        }
    };

    const aggregateToMonthly = (weeklyData) => {
        const monthlyData = {};
        weeklyData.forEach((week) => {
            const monthKey = week.date.toISOString().slice(0, 7); // YYYY-MM
            if (!monthlyData[monthKey]) {
                monthlyData[monthKey] = { date: new Date(monthKey), ...week };
            } else {
                Object.keys(week).forEach((key) => {
                    if (key !== 'date') {
                        monthlyData[monthKey][key] = (monthlyData[monthKey][key] || 0) + week[key];
                    }
                });
            }
        });
        return Object.values(monthlyData);
    };

    const toggleView = () => {
        setIsMonthly(!isMonthly);
    };

    if (loading) {
        return (
            <div className="flex flex-row justify-center h-[400px]">
                <CustomLoadingOverlay />
            </div>
        );
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    if (!options) {
        return <div>No data to display</div>;
    }

    return (
        <ExpandableSection
            defaultExpanded={true}
            headerText="Category Trends Overview"
            headerActions={
                <Toggle onChange={toggleView} checked={isMonthly} >
                    {isMonthly ? 'Monthly' : 'Weekly'} View
                </Toggle>
            }
        >
            <AgCharts options={options} className="h-[400px]" />
        </ExpandableSection>
    );
};
