export const defaultOverrides = {
  overrides: {
    common: {
      axes: {
        number: {
          label: {
              fontFamily: "Open Sans",
          },
        },
        time: {
          label: {
              fontFamily: "Open Sans",
          },
        },
      },
      series: {
        highlightStyle: {
          series: {
            dimOpacity: 0.2,
            strokeWidth: 2,
          },
        },
      },
      navigator: {
        miniChart: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
      legend: {
        item: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
    },
  },
};