import {
  ExpandableSection,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useMemo } from "react";
import { CompanyCards } from "./components/CompanyCards";

interface ItemCompanyProps {
  itemData: any;
}

interface CategoryData {
  items: any[];
  distribution: { [format: string]: number };
  production: { count: number };
}

export default function ItemCompany({ itemData }: ItemCompanyProps) {
  const organizeData = (data: any): { [category: string]: CategoryData } => {
    const categories: { [category: string]: CategoryData } = {
      Movies: { items: [], distribution: {}, production: { count: 0 } },
      "Television Series": {
        items: [],
        distribution: {},
        production: { count: 0 },
      },
      "Video Games": { items: [], distribution: {}, production: { count: 0 } },
    };

    ["Film", "Television Series", "Video Game"].forEach((category) => {
      const categoryKey = category === "Film" ? "Movies" : category;
      data[category]?.forEach((item) => {
        const categoryData = categories[categoryKey];

        item.distributors?.forEach((dist) => {
          if (dist.id === data.id) {
            dist.formats.forEach((format) => {
              categoryData.distribution[format] =
                (categoryData.distribution[format] || 0) + 1;
            });
          }
        });

        item.production?.forEach((prod) => {
          if (prod.id === data.id) {
            categoryData.production.count++;
          }
        });

        if (
          item.distributors?.some((d) => d.id === data.id) ||
          item.production?.some((p) => p.id === data.id)
        ) {
          categoryData.items.push(item);
        }
      });
    });

    return categories;
  };

  const organizedData = useMemo(() => {
    if (itemData.loading || !itemData.data?.type) return {};
    return organizeData(itemData.data);
  }, [itemData.data, itemData.loading]);

  if (itemData.loading || !itemData.data?.type) return null;
  return (
    <SpaceBetween size="l">
      <div className="flex space-x-6">
        <div className="flex-1 flex-col">
          <Header variant="h1">{itemData.data.name}</Header>
          <div className="text-slate-400">{itemData.data.type.name}</div>
          {itemData.data.description && (
            <>
              <Header variant="h2">Overview</Header>
              <p>{itemData.data.description}</p>
            </>
          )}
        </div>
      </div>

      <Tabs
        tabs={[
          organizedData.Movies &&
            organizedData.Movies.items.length > 0 && {
              label: "Movies",
              id: "movies",
              content: (
                <CategoryContent
                  category={organizedData.Movies}
                  headerText="Movies"
                  companyId={itemData.data.id}
                />
              ),
            },
          organizedData["Television Series"] &&
            organizedData["Television Series"].items.length > 0 && {
              label: "Television Series",
              id: "tv",
              content: (
                <CategoryContent
                  category={organizedData["Television Series"]}
                  headerText="Television Series"
                  companyId={itemData.data.id}
                />
              ),
            },
          organizedData["Video Games"] &&
            organizedData["Video Games"].items.length > 0 && {
              label: "Video Games",
              id: "games",
              content: (
                <CategoryContent
                  category={organizedData["Video Games"]}
                  headerText="Video Games"
                  companyId={itemData.data.id}
                />
              ),
            },
        ]}
      />
    </SpaceBetween>
  );
}

function CategoryContent({ category, headerText, companyId }) {
  return (
    <SpaceBetween size="l">
      {Object.entries(category.distribution).map(([format, count]) => (
        <ExpandableSection
          defaultExpanded={true}
          key={format}
          headerText={`Distributed ${headerText} (${format}) : ${count}`}
        >
          <CompanyCards
            items={category.items.filter((item) =>
              item.distributors?.some(
                (d) => d.id === companyId && d.formats.includes(format),
              ),
            )}
          />
        </ExpandableSection>
      ))}
      {category.production.count > 0 && (
        <ExpandableSection
          defaultExpanded={true}
          headerText={`Produced ${headerText} : ${category.production.count}`}
        >
          <CompanyCards
            items={category.items.filter((item) =>
              item.production?.some((p) => p.id === companyId),
            )}
          />
        </ExpandableSection>
      )}
    </SpaceBetween>
  );
}
