import { Flashbar } from "@cloudscape-design/components";
import { Component } from "react";

export default class PantheonFlashbar extends Component {
  state = {
    flashbarItems: [],
    dismissTimer: null,
  };
  flashbarId = crypto.randomUUID();

  constructor() {
    super();
  }

  setFlashbarMessage(type="success", content, header=null, dismissTime=null) {
    let newState = {
      ...this.state,
      flashbarItems: [
        {
          type: type === "loading" ? "success" : type,
          loading: type === "loading",
          header: header,
          content: content,
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: () => this.setState({ ...this.state, flashbarItems: [] }),
          id: this.flashbarId,
        },
      ],
    };
    if (dismissTime) {
      if(this.state.dismissTimer)
        clearTimeout(this.state.dismissTimer);
      newState.dismissTimer = setTimeout(() => {
        this.setState({ ...this.state, flashbarItems: [] })
      }, dismissTime);
    }
    this.setState(newState);
  }

  render() {
    return (
      <Flashbar items={this.state.flashbarItems} {...this.props} />
    );
  }
};