import { createSlice } from "@reduxjs/toolkit";

const permissionsListInitialState= {
    isPermissionsListLoading:false,
    permissionsList:[],
    isPermissionsListLoadingCompleted:false,
    isPermissionsListError: false,
    message:""
}


const permissionsSlice = createSlice({
    name:"permissionsList",
    initialState:permissionsListInitialState,
    reducers:{
        loading:(state)=>{
            state.isPermissionsListLoading = true;
            state.permissionsList = [];
        },
        createPermissionsList: (state, action) => {
            state.isPermissionsListLoading = false;
            state.permissionsList = action.payload;
            state.isPermissionsListLoadingCompleted = true;
            state.isPermissionsListError = false;
        },
        clearPermissionsList:() => {
            return permissionsListInitialState;
        },
        createError:(state, action) => {
            state.isPermissionsListLoading = false;
            state.message = action.payload;
            state.isPermissionsListLoadingCompleted = true;
            state.isPermissionsListError = false;
        },
    }
});
const permissionsReducer = permissionsSlice.reducer;
export default permissionsReducer;

export const permissionsSliceActions = permissionsSlice.actions;