import { Drawer, HelpPanel } from '@cloudscape-design/components';
import React from 'react';

export const GlobalInfoDrawer = () => {

  return (
    <Drawer header="Information">
      <div>
        <div className="text-base font-bold mt-4">How ranks are calculated</div>
        <p>Each day, every title within Pantheon is assigned platform-specific Z-scores, which measure its performance on different platforms. These Z-scores are then combined using weighted sums to calculate a total score for the title. Finally, the titles are ranked by sorting them based on their total scores, with the highest score receiving the top rank.</p>
        <div className="text-base font-bold mt-4">How trends are calculated</div>
        <p>For aggregations over a selected timeframe, scores are calculated as usual, but they take into account the Z-scores from all days within that period. The same process is applied to the previous period for comparison. For example, if you select a 7-day period, the 7 days prior to that are considered the "previous period." Rank changes are then determined by comparing a title's rank in the current period with its rank in the previous period, and the difference is used to show how the rank has shifted.</p>
      </div>
    </Drawer>
  );
};