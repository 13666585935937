export const mapPiracyTrendsData = (data) => {
  if (!data?.data?.data) return [];

  return data?.data?.data?.map((item) => {
    return {
      ...item,
      timestamp: item.timestamp * 1000,
    };
  });
};
