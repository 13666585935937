import { dispatch } from "../../../../app/store";
import { ipManagement, pantheonManagement } from "../slices/ip-management-slice";

export async function getIpManagementData() {
    await dispatch(ipManagement.asyncThunk());
}

export function clearIpManagementData() {
    dispatch(ipManagement.actions.clearData());
}

export async function getPantheonManagementData() {
    await dispatch(pantheonManagement.asyncThunk());
}

export function clearPantheonManagementData() {
    dispatch(pantheonManagement.actions.clearData());
}
