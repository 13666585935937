import React, { useState } from "react";
import { Autosuggest, Button } from "@cloudscape-design/components";
import axiosInstance from "../../utils/axios";

const AutosuggestSelector = ({ value, onValueSelected, platform, placeholder, onPreview }) => {
  
  const [ isLoading, setIsLoading ] = useState(false);
  const [ autosuggestOptions, setAutosuggestOptions ] = useState([]);

  const getAutosuggestOptions = (query) => {
    if (!query || query.length < 2) {
      return;
    }
    setIsLoading(true);
    axiosInstance.get(
      `/autocomplete?platform=${platform}&q=${query}`
    ).then(response => {
      setAutosuggestOptions(response.data);
      setIsLoading(false);
    });
  };

  return (
    <div className="flex space-x-1">
      <Autosuggest
        onSelect={({ detail }) => {
          onValueSelected(detail.selectedOption?.value || detail.value);
        }}
        onChange={({ detail }) => {
          onValueSelected(detail.value);
        }}
        value={value}
        options={autosuggestOptions}
        onLoadItems={({ detail }) => getAutosuggestOptions(detail.filteringText)}
        statusType={isLoading ? "loading" : "finished"}
        filteringType="none"
        placeholder={placeholder}
        empty="No results found"
        loadingText="Loading results"
        enteredTextLabel={(value) => `Use: "${value}"`}
      />
      {onPreview && (
        <Button 
          iconName="external" 
          variant="inline-icon"
          onClick={() => onPreview(value)}
        />
      )}
    </div>
  );
};

export default AutosuggestSelector;