import shortenInteger from "../../../utils/shortenInteger";

export const numberFilterOptions = [
  "equals",
  "notEqual",
  "greaterThan",
  "lessThan",
];
export const stringFilterOptions = [
  "contains",
  "notContains",
  "equals",
  "notEqual",
  "blank",
  "notBlank",
];
export const listFilterOptions = [
  "contains",
  "notContains",
  "equals",
  "blank",
  "notBlank",
];

export const numberColumnDefinition = (field: string, headerName: string) => ({
  field,
  headerName,
  valueFormatter: (params) => shortenInteger(params.value),
  cellDataType: "number",
  filterParams: {
    filterOptions: numberFilterOptions,
  },
});

export const dateColumnDefinition = (field: string, headerName: string) => ({
  field,
  headerName,
  cellDataType: "date",
  valueFormatter: (params) => {
    const dateStr = params.value;
    if (!dateStr) return "";
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6) - 1;
    const day = dateStr.substring(6, 8);
    const date = new Date(year, month, day);

    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  },
});

export const getChartDefinition = (field: string, headerName: string) => ({
  field,
  headerName,
  maxWidth: 600,
  minWidth: 200,
  cellRenderer: "agSparklineCellRenderer",
  cellRendererParams: {
    sparklineOptions: {
      type: "area",
      marker: {
        size: 1,
        shape: "circle",
        fill: "white",
      },
      fill: "#0972d3",
      highlightStyle: {
        fill: "#033160",
      },
    },
  },
  filter: false,
});

export const getAdditionalColumnDefinition = (
  field: string,
  headerName: string,
  cellDataType: string,
  hide: boolean,
) => ({
  field,
  headerName,
  filter: true,
  sortable: false,
  flex: 1,
  minWidth: 150,
  filterParams: {
    filterOptions: listFilterOptions,
  },
  cellDataType,
  hide,
});
