/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, PropertyFilterProps, SpaceBetween } from "@cloudscape-design/components";
import React, { FC, useState } from "react";
import { ApiURLParams } from "../../services/generic_v2/requests";
import { DeleteFilterModalV2 } from "./DeleteFilterModal_V2";
import { SaveOrUpdateFilterModalV2 } from "./SaveOrUpdateFilterModal_V2";

export type GridFilterProps = {
  colId: string;
  filter: string | number;
  filterType: string;
  type: string;
};

type AdvancedFiltersProps = {
  apiParams: ApiURLParams;
  filter: PropertyFilterProps.Query | GridFilterProps;
  existingPref: any;
  selectedFilterName: string;
  getPreferencesAfterDelete: (name: string) => void;
  getPreferencesAfterSaveOrUpdate: (
    name: string,
    prevName: string,
    isUpdate: boolean,
  ) => void;
  handleClearFilter: () => void;
};

export const AdvancedFiltersV2: FC<AdvancedFiltersProps> = ({
  apiParams,
  filter,
  selectedFilterName,
  existingPref,
  handleClearFilter,
  getPreferencesAfterDelete,
  getPreferencesAfterSaveOrUpdate,
}) => {
  const initialModalStatus = {
    save: false,
    update: false,
    delete: false,
  };

  const [modalStatus, setModalstatus] = useState({ ...initialModalStatus });

  const onDismiss = () => setModalstatus({ ...initialModalStatus });

  return (
    <>
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          ariaLabel="Clear Filter"
          iconName="close"
          variant="primary"
          disabled={!filter}
          onClick={handleClearFilter}
        />
        <Button
          ariaLabel="Save Filter"
          iconName="envelope"
          variant="primary"
          disabled={!filter}
          onClick={() => setModalstatus({ ...initialModalStatus, save: true })}
        />
        <Button
          ariaLabel="Edit Filter"
          iconName="edit"
          variant="primary"
          disabled={!filter}
          onClick={() => setModalstatus({ ...initialModalStatus, update: true })}
        />
        <Button
          ariaLabel="Delete Filter"
          iconName="remove"
          variant="normal"
          disabled={!filter}
          onClick={() => setModalstatus({ ...initialModalStatus, delete: true })}
        />
      </SpaceBetween>
      <SaveOrUpdateFilterModalV2
        title={`${modalStatus.save ? "Save" : "Update"} Filter`}
        visible={modalStatus.save || modalStatus.update}
        isUpdate={modalStatus.update}
        existingPref={existingPref}
        onDismiss={onDismiss}
        apiParams={apiParams}
        filterName={modalStatus.save ? "" : selectedFilterName}
        getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
      />
      <DeleteFilterModalV2
        title="Delete"
        visible={modalStatus.delete}
        existingPref={existingPref}
        onDismiss={onDismiss}
        apiParams={apiParams}
        getPreferencesAfterDelete={getPreferencesAfterDelete}
        name={selectedFilterName}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};
