import { Flashbar } from "@cloudscape-design/components";
import React, { forwardRef, useImperativeHandle, useState } from 'react';

const CustomFlashBar = forwardRef((props, ref) => {
  const [flashbarItems, setFlashbarItems] = useState([]);

  const removeItem = (id) => {
    setFlashbarItems(items => items.filter(item => item.id !== id));
  };

  useImperativeHandle(ref, () => ({
    setFlashbarMessage: (type, content, id) => {
      const newItem = {
        type,
        content,
        id: id || Date.now().toString(),
        dismissible: true,
        onDismiss: () => removeItem(id || newItem.id),
        dismissLabel: "Dismiss message",
      };
      setFlashbarItems(items => [newItem, ...items]);
      setTimeout(() => removeItem(newItem.id), 50000);
    },
    clearFlashbarMessages: () => {
      setFlashbarItems([]);
    },
  }));

  if (flashbarItems.length === 0) return null;

  return (
    <Flashbar
      items={flashbarItems}
      i18nStrings={{
        ariaLabel: "Notifications",
        notificationBarAriaLabel: "View all notifications",
        notificationBarText: "Notifications",
        errorIconAriaLabel: "Error",
        warningIconAriaLabel: "Warning",
        successIconAriaLabel: "Success",
        infoIconAriaLabel: "Info",
        inProgressIconAriaLabel: "In progress",
      }}
      stackItems
    />
  );
});

export default CustomFlashBar;
