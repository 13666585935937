import { dispatch } from "../../../../../app/store";
import axiosInstance from "../../../../../utils/axios";
import { createRoleAction } from "../slices/create-role-slice";
import { editRoleAction } from "../slices/edit-role-slice";
import { roleSliceActions } from "../slices/roles-slice";


export async function getRolesList() {
   
    dispatch(roleSliceActions.loading());
    try {
        const response = await axiosInstance.request({
            url: `roles`,
            method: `GET`,
        });
        dispatch(roleSliceActions.insertRolesList(response.data));
    } catch (e) {
        dispatch(roleSliceActions.insertError(e.message));
    }

}

export async function createRoleFn({ roleName, permissions }) {

    dispatch(createRoleAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `roles`,
            method: `POST`,
            data: {
                roleName,
                permissions:permissions.map(d=>d.value)
            }
        });
        dispatch(createRoleAction.createRoleComepleted(response.data.message));
    } catch (e) {
        dispatch(createRoleAction.createRoleFailed(e.message));
    }

}


export function clearCreateRoleFn () {
    dispatch(createRoleAction.clearRoleCreateReducer());
}


export async function editRoleFn({ roleId, roleName, permissions }) {

    dispatch(editRoleAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `roles/${roleId}`,
            method: `PATCH`,
            data: {
                roleName,
                permissions:permissions.map(d=>d.value)
            }
        });
        dispatch(editRoleAction.editRoleComepleted(response.data.message));
    } catch (e) {
        dispatch(editRoleAction.editRoleFailed(e.message));
    }

}


export function clearEditRoleFn () {
    dispatch(editRoleAction.clearRoleEditReducer());
}
