import { Grid } from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgChartOptions } from "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import React, { FC } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../utils/shortenInteger";
import { useGetChannelTrends } from "../api/hooks/useGetChannelTrends";

interface ChannelTrendsProps {
  channelId: string;
}

export const ChannelTrends: FC<ChannelTrendsProps> = ({ channelId }) => {
  const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
  const { theme: viewsThemeOverrides } = useGetChartTheme(CHART_TYPES.VIVID, {
    palette: {
      fills: ["#E69138"],
    },
  });
  const { theme: videoThemeOverrides } = useGetChartTheme(CHART_TYPES.VIVID, {
    palette: {
      fills: ["#e63a38"],
    },
  });
  const { data: channelTrendsData } = useGetChannelTrends(channelId);
  const filteredData = channelTrendsData.filter(
    (item) => item.videoGrowth >= 0 && item.viewCount >= 0,
  );

  const viewsOptions = {
    theme: viewsThemeOverrides,
    
    zoom: {
      enabled: true,
    },
    title: {
      text: "Viewership Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: filteredData,
    series: [
      {
        type: "area",
        xKey: "timestamp",
        yKey: "viewCount",
        yName: "Views",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Daily Views",
              content: `
                <b>Date:</b> ${date.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })} <br />
                <b>Views:</b> ${Math.round(
                  params.datum.viewCount,
                ).toLocaleString()}<br/>
              `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "log",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Views",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["viewCount"],
        label: {
          formatter: (params) => {
            return String(shortenInteger(params.value));
          },
        },
      },
    ],
  } as AgChartOptions;

  const subscriberOptions = {
    theme: theme,
    
    zoom: {
      enabled: true,
    },
    title: {
      text: "Subscription Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: channelTrendsData,
    series: [
      {
        type: "line",
        xKey: "timestamp",
        yKey: "subscriberCount",
        yName: "Subscribers",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Subscribers",
              content: `
                          <b>Date:</b> ${date.toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })} <br />
                          <b>Subscibers:</b> ${Math.round(
                            params.datum.subscriberCount,
                          ).toLocaleString()}<br/>
                          <b>New Subscibers:</b> ${Math.round(
                            params.datum.subscriberGrowth,
                          ).toLocaleString()}<br/>
                          `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Subscribers",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["subscriberCount"],
        label: {
          formatter: (params) => {
            return shortenInteger(params.value);
          },
        },
      },
    ],
  } as AgChartOptions;

  const videoOptions = {
    theme: videoThemeOverrides,
    
    zoom: {
      enabled: true,
    },
    title: {
      text: "Video Count Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: channelTrendsData,
    series: [
      {
        type: "bar",
        xKey: "timestamp",
        yKey: "videoGrowth",
        yName: "Videos",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Videos",
              content: `
                          <b>Date:</b> ${date.toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })} <br />
                          <b>Videos:</b> ${Math.round(
                            params.datum.videoCount,
                          ).toLocaleString()}<br/>
                          <b>New Videos:</b> ${Math.round(
                            params.datum.videoGrowth,
                          ).toLocaleString()}<br/>
                          `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        min: 0,
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Videos",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["videoGrowth"],
      },
    ],
  } as AgChartOptions;

  return (
    <Grid
      gridDefinition={[
        { colspan: { xxs: 12, s: 6, m: 4, xl: 4 } },
        { colspan: { xxs: 12, s: 6, m: 4, xl: 4 } },
        { colspan: { xxs: 12, s: 6, m: 4, xl: 4 } },
      ]}
    >
      <div className="h-60">
        <AgCharts options={viewsOptions} style={{ height: "100%" }} />
      </div>
      <div className="h-60">
        <AgCharts options={subscriberOptions} style={{ height: "100%" }} />
      </div>
      <div className="h-60">
        <AgCharts options={videoOptions} style={{ height: "100%" }} />
      </div>
    </Grid>
  );
};
