import {
  ButtonDropdown,
  ContentLayout,
  Header,
  Tabs,
} from "@cloudscape-design/components";
import moment from "moment";
import React, { useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import { addQueryParams, getQueryParams } from "../../../utils/queryUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import { Layout } from "../Layout";
import PiracyGenericSummaryPage from "./components/PiracyGenericSummaryPage";
import { PiracyTrendsCharts } from "./components/PiracyTrendsCharts";
import { defaultCategory } from "./constants";

const title = "Piracy";
const tabs = ["trends, tile"];

function PiracyTrendsChartsIndexPage() {
  const breadcrumbs = [{ text: "Platforms" }, { text: title }];
  const tabQuery = getQueryParams("tab");
  const categoryQuery = getQueryParams("category") ?? defaultCategory;
  const isValidTab = tabs.some((str) => str.includes(tabQuery));
  const [activeTabId, setActiveTabId] = React.useState<string>(
    isValidTab ? tabQuery : "trends",
  );

  if (!isValidTab) {
    addQueryParams({ tab: "trends" });
  }

  const [dateRange, setDateRange] = useState({
    startDate: moment().subtract(1, "months").startOf("day").unix(),
    endDate: moment().endOf("day").unix(),
  });
  const [selectedCountry, setSelectedCountry] = useState({
    label: "United States",
    value: "United States",
  });

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      contentType="table"
      content={
        <ContentLayout
          disableOverlap
          header={
            <>
              <Header
                description="This module provides information based on torrent downloads of titles. The data is updated daily."
                variant="h2"
              >
                {title}
              </Header>
              <CustomFlashBar />
            </>
          }
        >
          <Tabs
            onChange={({ detail }) => {
              setActiveTabId(detail.activeTabId);
              addQueryParams({ tab: detail.activeTabId });
            }}
            activeTabId={activeTabId}
            fitHeight
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: (
                  <PiracyTrendsCharts 
                    defaultCategory={categoryQuery} 
                    setSelectedVertical={(vertical) => addQueryParams({ category: vertical.value })}
                  />
                ),
                // action: (
                //   <ButtonDropdown
                //     variant="icon"
                //     items={[
                //       { id: "1", text: "Set as primary", disabled: true },
                //     ]}
                //     expandToViewport={true}
                //   />
                // ),
              },
              {
                label: "Tile View",
                id: "tile",
                content: (
                  <PiracyGenericSummaryPage
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    selectedCountry={selectedCountry}
                    setSelectedCountry={setSelectedCountry}
                    activeTabId={activeTabId}
                  />
                ),
                // action: (
                //   <ButtonDropdown
                //     variant="icon"
                //     items={[
                //       { id: "1", text: "Set as primary", disabled: true },
                //     ]}
                //     expandToViewport={true}
                //   />
                // ),
              },
            ]}
          />
        </ContentLayout>
      }
    />
  );
}

export default PiracyTrendsChartsIndexPage;
