interface MapCreateMediaResponseProps {
  ip: string;
  genre: string;
  ip_id: string;
  status: string;
  release_date: string;
  image_url: string;
  summary: string;
}

export const mapCreateMediaResponse = ({
  ip,
  genre,
  ip_id,
  status,
  release_date,
  image_url,
  summary,
}: MapCreateMediaResponseProps) => ({
  ip,
  genre,
  ip_id,
  status,
  summary,
  url: image_url,
  releaseDate: release_date,
});
