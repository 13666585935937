import { TrendsContent, TrendsFooter, TrendsHeader } from "../component/Trends";

const title = "Steam Trends";

export const steamTrends = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Based on Steam Player in steam platform",
    header: () => <TrendsHeader title={title} />,
    content: () => <TrendsContent vertical="gaming" platform="steam" sort="daily_rank" />,
    footer: () => <TrendsFooter linkHref="/platforms/steam?tab=trends&category=gaming" />,
  },
};