import { useCallback } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../../utils/shortenInteger";

const tooltip = {
  renderer: ({ datum }) => {
    const date = new Date(datum.timestamp);
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const humanReadableDate = date.toLocaleString("en-US", options);
    const title = `<span style="font-size: 12px">${humanReadableDate}</span>`;
    return {
      title: title,
      content: ` 
      <b>Daily Rank:</b> ${datum.dailyRank}<br/>
       <b>Page Views:</b> ${shortenInteger(datum.pageViews)} <br />
       `,
    };
  },
};

export const useChartConfig = (data) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  const getLineChartOptions = useCallback(() => {
    return {
      padding: { right: 0, bottom: 0, left: 0 },
      minWidth: 0,
      minHeight: 0,
      theme: theme,
      legend: {
        enabled: true,
        position: "right",
        spacing: 8,
        item: { paddingY: 14, line: { strokeWidth: 12 } },
      },
      series: [
        {
          type: "line",
          xKey: "timestamp",
          yKey: "pageViews",
          yName: "Page Views",
          marker: { enabled: false },
          tooltip,
        },
      ],
      sync: { enabled: true, axes: "x", nodeInteraction: true },
      zoom: { enabled: true, enableSelecting: true },
      axes: [
        {
          type: "ordinal-time",
          position: "bottom",
          label: { format: "%b %-d, %Y", fontSize: 10 },
          crosshair: { label: { format: "%b %-d, %Y" } },
        },
        {
          type: "log",
          position: "left",
          nice: true,
          keys: ["pageViews"],
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
          title: {
            enabled: false,
            text: "Page Views",
            fontSize: 10,
            color: "rgb(100 116 139)",
            spacing: 4,
          },
        },
      ],
    };
  }, [theme, data]);

  return { getLineChartOptions };
};
