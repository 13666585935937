import React, { useState } from "react";

const ReadMore = ({ children, text, maxLength=600, className }) => {
  const inner = text ?? children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  if (!inner) return null;
  return (
    <a className={"text " + className}>
      {(isReadMore && inner.length > maxLength) ? inner.slice(0, maxLength) + "..." : inner}
      {inner.length > maxLength && (
        <span onClick={toggleReadMore} className="read-or-hide font-bold cursor-pointer text-blue-400 hover:text-blue-300">
          {isReadMore ? " show more" : " show less"}
        </span>
      )}
    </a>
  );
};

export default ReadMore;