import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import {
  getPiracyGenericSummary,
  GetPiracyGenericSummaryProps,
} from "../request";

interface UseGetPiracyGenericSummaryParams
  extends GetPiracyGenericSummaryProps {
  activeTabId: string;
}

export const useGetPiracyGenericSummary = (
  params: UseGetPiracyGenericSummaryParams,
) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_PIRACY_GENERIC_SUMMARY, { ...params }],
    () => getPiracyGenericSummary(params),
    { enabled: params.activeTabId === "tile" },
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};
