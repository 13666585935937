import React, { useState } from "react";
import ButtonDropdown from "@cloudscape-design/components/button-dropdown";
import BoardItem from "@cloudscape-design/board-components/board-item";
import { RemoveDialog } from "./remove-dialog";
import { boardItemI18nStrings } from "../../utils/i18n-strings";
import { Header } from "@cloudscape-design/components";

export function ConfigurableWidget({
  config,
  onRemove,
  actions = [],
  removeConfirmationText = "",
}) {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  return (
    <>
      <BoardItem
        header={config?.header ? <config.header /> : null}
        disableContentPaddings
        i18nStrings={boardItemI18nStrings}
        // data-style-id="border-radius-0-parent"
        settings={
          <ButtonDropdown
            items={[
              ...actions.map((action) => ({
                id: action.text,
                text: action.text,
              })),
              { id: "remove", text: "Remove" },
            ]}
            ariaLabel="Widget settings"
            variant="icon"
            onItemClick={(event) => {
              if (event.detail.id === "remove") {
                if (removeConfirmationText) {
                  setShowRemoveDialog(true);
                } else {
                  onRemove();
                }
              } else {
                const actionMatch = actions?.find(
                  (action) => action.text === event.detail.id,
                );
                actionMatch.onClick();
              }
            }}
          />
        }
        footer={config?.footer && <config.footer />}
      >
        {config?.content && <config.content />}
      </BoardItem>
      {showRemoveDialog && (
        <RemoveDialog
          title={removeConfirmationText ?? ""}
          onDismiss={() => setShowRemoveDialog(false)}
          onConfirm={() => {
            setShowRemoveDialog(false);
            onRemove();
          }}
        />
      )}
    </>
  );
}
