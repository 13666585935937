import { useParams } from "react-router";
import axiosInstance from "../../../utils/axios";
import {
  deleteFranchiseAction,
  getItemData,
  mergeFranchiseAction,
  updateFranchiseAction,
} from "../redux/actions/item-actions";
import { useCallback } from "react";

export const useFranchiseActions = () => {
  const { id } = useParams();

  const updateFranchiseCards = useCallback((item) => {
    axiosInstance
      .put("franchise", {
        ...item,
      })
      .then((res) => res.data)
      .then(() => {
        window.location.reload();
      });
  }, []);

  const mergeFranchise = useCallback(
    (data) => {
      mergeFranchiseAction(data?.source, data?.destination).then(() => {
        getItemData(id, {});
      });
    },
    [id],
  );

  const updateFranchise = useCallback(
    (data) => {
      updateFranchiseAction(id, data.name, data.summary).then(() => {
        getItemData(id, {});
      });
    },
    [id],
  );

  const deleteFranchise = useCallback((ipId: string) => {
    deleteFranchiseAction(ipId).then((d) => {
      window.location.href = "/explore/franchise";
    });
  }, []);

  return {
    updateFranchiseCards,
    mergeFranchise,
    updateFranchise,
    deleteFranchise,
  };
};
