import React, { useEffect, useState } from "react";
import Calendar from "@cloudscape-design/components/calendar";
import DateInput from "@cloudscape-design/components/date-input";
import FormField from "@cloudscape-design/components/form-field";

export function DateTimeForm({ filter, _operator, value, onChange }) {
  const [{ dateValue }, setState] = useState(
    parseValue(value ?? new Date().toISOString().split("T")[0])
  );

  const onChangeDate = (dateValue) => {
    setState((state) => ({ ...state, dateValue }));
  };

  useEffect(
    () => {
      filter && setState(parseDateTimeFilter(filter.trim()));
    },
    [filter]
  );

  useEffect(
    () => {
      if (!dateValue.trim()) {
        onChange(null);
      } else if (isValidIsoDate(dateValue)) {
        onChange(dateValue);
      }
    },
    [dateValue]
  );

  return (
    <div className="date-time-form">
      <FormField description="Date" constraintText="Use YYYY/MM/DD format.">
        <DateInput
          placeholder="YYYY/MM/DD"
          value={dateValue}
          onChange={(event) => onChangeDate(event.detail.value)}
        />
      </FormField>

      <Calendar
        value={dateValue}
        locale="en-EN"
        previousMonthAriaLabel="Previous month"
        nextMonthAriaLabel="Next month"
        todayAriaLabel="Today"
        onChange={(event) => onChangeDate(event.detail.value)}
      />
    </div>
  );
}

export function formatDateTime(isoDate) {
  return isoDate ?? "";
}

function parseDateTimeFilter(filter) {
  const regexDate = /^(\d\d\d\d(-|\/\d\d)?(-|\/\d\d)?)(T\d\d(:\d\d)?(:\d\d)?)?/;
  const dateTime = filter.match(regexDate)?.[0] || "";

  let [dateValue] = dateTime.split("T");
  const [year, month = "01", day = "01"] = dateValue.split(/-|\//);
  dateValue = year.length === 4 ? `${year}-${month}-${day}` : "";

  const value = dateValue;
  return isValidIsoDate(value) ? { dateValue } : { dateValue: "" };
}

function isValidIsoDate(isoDate) {
  return !isNaN(new Date(isoDate).getTime());
}

function parseValue(value) {
  const [datePart = ""] = (value ?? "").split("T");
  return { dateValue: datePart };
}
