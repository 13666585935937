import React from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { MediaHome } from "../media/components/MediaHome";

const breadcrumbs = [
  { text: "Explore", href: "/explore" },
  { text: "Companies" },
];

export default function ExploreCompanies() {
  return (
    <Layout
      title="Explore Companies"
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      contentType="table"
      content={<MediaHome vertical="companies" header="Companies" />}
    />
  );
}
