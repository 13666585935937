import { ButtonDropdown } from "@cloudscape-design/components";
import { useGetGenericPreference_V2 } from "../services/generic_v2/hooks/useGetGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../services/generic_v2/hooks/usePostGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../services/generic_v2/hooks/usePutGenericPreference_V2";
import { invalidatePreferenceData } from "../services/generic_v2/utils/serviceUtils";


export const TabActions = ({
  tabId,
  preferencesApiParams,
}) => {

  const { data: preferenceData } = useGetGenericPreference_V2({ apiParams: preferencesApiParams });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams: preferencesApiParams,
    onSuccess: invalidatePreferenceData,
  });

  const savePreferences = () => {
    const prefValue = preferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;

      data.primaryTab = tabId;

      updatePreference({ id: prefValue.id, payload: data });
      return;
    }

    const data = { primaryTab: tabId };
    postGenericPreference(data);
  };

  return (
    <ButtonDropdown
      expandToViewport
      variant="icon"
      items={[
        { id: "set-as-primary", text: "Set as primary view" },
      ]}
      onItemClick={({ detail }) => {
        switch (detail.id) {
          case "set-as-primary":
            savePreferences();
            break;
        }
      }}
    />
  );
};