import React, { FC, useEffect, useRef } from "react";

const limit = (low, actual, high) => {
  return Math.max(low, Math.min(high, actual));
};

const lerp = (a, b, t) => {
  return a + (b - a) * t;
}

const hexToRgb = (hex) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
};

const rgbToHex = (rgb) => {
  return `#${((1 << 24) + (Math.floor(rgb.r) << 16) + (Math.floor(rgb.g) << 8) + Math.floor(rgb.b)).toString(16).slice(1)}`;
}

const interpolateColor = (color1, color2, color3, t) => {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);
  const rgb3 = hexToRgb(color3);

  const r = lerp(lerp(rgb1.r, rgb2.r, t), rgb3.r, t);
  const g = lerp(lerp(rgb1.g, rgb2.g, t), rgb3.g, t);
  const b = lerp(lerp(rgb1.b, rgb2.b, t), rgb3.b, t);

  return rgbToHex({ r, g, b });
};

interface HorizontalGuageProps {
  value: number;
  text?: string;
  min?: number;
  max?: number;
  colorText?: boolean;
}

export const HorizontalGuage: FC<HorizontalGuageProps> = ({
  value,
  text="",
  min=0,
  max=1,
  colorText=false,
}) => {

  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  
  const percentage = (value - min) / (max - min) * 100;
  const thumbColor = interpolateColor("#ef4444", "#eab308", "#22c55e", percentage / 100.0);

  const repositionText = () => {
    if (containerRef.current && textRef.current) {
      const containerRect = containerRef.current.getBoundingClientRect();
      
      const maxLeft = containerRect.width - textRef.current.clientWidth;
      
      const left = limit(0, textRef.current.offsetLeft - textRef.current.clientWidth / 2, maxLeft) - textRef.current.offsetLeft;

      textRef.current.style.transform = `translateX(${left}px)`;
    }
  };

  useEffect(() => {
    repositionText();
  }, [value, min, max, text]);

  useEffect(() => {
    if (!containerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      repositionText();
    });
    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className="relative" ref={containerRef}>
      <div className="relative w-full h-5">
        <div className="text-sm text-nowrap font-bold absolute" style={{ left: `${percentage}%`, color: (colorText ? thumbColor : "") }} ref={textRef}>{text}</div>
      </div>
      <div className="relative w-full min-w-16 min-h-3 h-5">
        <div className="h-1 w-full top-1/2 translate-y-[-0.125rem] rounded-full bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 absolute" style={{ maskImage: `linear-gradient(90deg, black 0%, black calc(${percentage}% - 0.4rem), transparent calc(${percentage}% - 0.4rem), transparent calc(${percentage}% + 0.4rem), black calc(${percentage}% + 0.4rem), black 100%)` }}></div>
        <div className="h-5 w-1 translate-x-[-0.125rem] absolute rounded-full" style={{ left: `${percentage}%`, backgroundColor: thumbColor }}></div>
      </div>
      <div className="text-xs font-bold top-2 mt-[-0.2rem] flex justify-between">
        <span>{min.toLocaleString()}</span>
        <span>{max.toLocaleString()}</span>
      </div>
    </div>
  );
};