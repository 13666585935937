import {
  Alert,
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import { useDeletePlatformPreference } from "../../../../../services/platformPreference/hooks/useDeletePlatformPreference";
import { PLATFORMS } from "../../../../../services/platformPreference/constants";

type DeletePreferenceModalProps = {
  title: string;
  description: string;
  selectedFileName: string;
  visible: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
  onError: () => void;
};

export const DeletePreferenceModal: FC<DeletePreferenceModalProps> = ({
  title,
  description,
  selectedFileName,
  visible,
  onDismiss,
  onSuccess,
  onError,
}) => {
  const { mutateAsync: deletePreferenceData } = useDeletePlatformPreference(
    PLATFORMS.TRACKED_RANKING,
    onError,
  );

  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                const params = {
                  name: selectedFileName,
                  type: "personal",
                };

                deletePreferenceData(params).then(onSuccess);
              }}
            >
              Continue
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={title}
    >
      <Alert statusIconAriaLabel="Warning" type="warning">
        {description}
      </Alert>
    </Modal>
  );
};
