import { AgCharts } from "ag-charts-react";
import React, { useState } from "react";
import { useChartConfig } from "./hooks/useChartConfig";
import { useCompareContext } from "../../hooks/useCompareContext";

const RadialChart = ({}) => {
  const { options } = useCompareContext();

  const [ chartBaseTheme, setChartBaseTheme ] = useState(null);

  const { chartOptions } = useChartConfig({
    chartHeight: options.chart.height,
    chartBaseTheme,
  });

  return (
    <div 
      style={{ height: `${options.chart.height}px`, width: "100%", position: "relative" }} 
      className="border dark:border-slate-600 border-slate-400 rounded-lg h-full relative overflow-hidden" 
    >
      <AgCharts
        options={chartOptions}
      />
    </div>
  );
};

export default RadialChart;