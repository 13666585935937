import { useEffect, useState } from "react";
import { formatFilter, idMap } from "../../../utils/filterUtils";

export const useGetApiParams = ({
  currentPage,
  pageSize,
  sortingColumn,
  sortingDescending,
  filteringQuery,
  vertical,
}) => {
  const [from, setFrom] = useState(0);
  const [range, setRange] = useState();
  const [body, setBody] = useState({});

  useEffect(() => {
    setFrom((currentPage - 1) * pageSize);

    const sort = sortingColumn
      ? [
          {
            [`${idMap.get(sortingColumn.id)}`]: sortingDescending
              ? "desc"
              : "asc",
          },
        ]
      : [];

    const include = filteringQuery.tokens
      .map((t) =>
        t.operator === "="
          ? formatFilter(t, null)
          : t.operator === ":"
          ? formatFilter(t, "contain")
          : null,
      )
      .filter((e) => e);

    const exclude = filteringQuery.tokens
      .map((t) => (t.operator === "!=" ? formatFilter(t, null) : null))
      .filter((e) => e);

    setBody({
      sort,
      include,
      exclude,
      include_condition: filteringQuery.operation,
    });

    const rangeValue = () => {
      if (vertical === "games" || vertical === "companies") {
        if (!filteringQuery.tokens.map((e) => e.propertyKey)) return null;

        return;
      }

      if (
        !filteringQuery.tokens
          .map((e) => e.propertyKey)
          .includes("release_date")
      ) {
        return null;
      }

      const rangeParams = filteringQuery.tokens
        .map((t) => {
          if (t.propertyKey === "release_date") {
            switch (t.operator) {
              case ">=":
                return `&gte=${t?.value}`;

              case "<=":
                return `&lte=${t?.value}`;

              case ">":
                return `&gt=${t?.value}`;

              case "<":
                return `&lt=${t?.value}`;

              default:
                return null;
            }
          }
          return "";
        })
        .filter((param) => param !== "");

      return rangeParams.length > 0 ? rangeParams.join("") : null;
    };
    setRange(rangeValue);
  }, [
    currentPage,
    filteringQuery.operation,
    filteringQuery.tokens,
    pageSize,
    sortingColumn,
    sortingDescending,
    vertical,
  ]);

  return {
    from,
    pageSize,
    range,
    body,
  };
};
