import {
  AppLayout,
  AppLayoutProps,
  BreadcrumbGroup,
  SideNavigation,
} from "@cloudscape-design/components";
import React, { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  BreadcrumbsItem,
  NavItemWithId,
  NavItemsWithId,
  SERVICES_GROUP,
} from "../../layouts/common/menu/side-menu";

interface LayoutProps {
  title: string;
  breadcrumbs: Array<BreadcrumbsItem>;
  navItems: NavItemsWithId;
  content: ReactNode;
  contentType: AppLayoutProps.ContentType;
}

export const Layout: FC<LayoutProps> = ({
  title,
  breadcrumbs,
  navItems,
  content,
  contentType,
}) => {
  const navigate = useNavigate();

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const filteredNavItems = navItems.filter(
    (item: NavItemWithId) =>
      item.type === "section-group" && item?.id === SERVICES_GROUP.DASHBOARDS,
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            onFollow={onFollowHandler}
            activeHref={window.location.pathname}
            items={filteredNavItems}
          />
        }
        content={content}
        contentType={contentType}
        breadcrumbs={
          <BreadcrumbGroup
            onFollow={onFollowHandler}
            items={breadcrumbs as any}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};
