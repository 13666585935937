import React, { useState, useEffect } from "react";
import { Box, SpaceBetween, Link, TextContent, Container, Table, Button, Header } from "@cloudscape-design/components";
import axiosInstance from "../../../../../../utils/axios";
import "ag-grid-enterprise";

export const gamesLastWeek = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 1 },
  data: {
    title: "Recent Games",
    description: "Games released in the last 7 days",
    header: AccountAttributesHeader,
    content: AccountAttributesContent,
    // footer: AccountAttributesFooter,
  },
};

function AccountAttributesHeader() {
  return (
    <Box variant="h4" textAlign="center">
      {gamesLastWeek.data.title}
    </Box>
  );
}

function AccountAttributesContent() {
  const [games, setGames] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async (vertical = "Gaming") => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/widget/${vertical}/recent?gte=now-7d/d&lte=now&fields=ip,ip_id,release_date`);
      if (response.status >= 200 && response.status < 300) {
        const data = response.data.data;
        if (!data) {
          setGames([]);
          return;
        }
        const sortedData = data.sort((a, b) => new Date(b.release_date) - new Date(a.release_date));
        setGames(sortedData);
      } else {
        throw new Error("Error fetching data");
      }
    } catch (error) {
      setError(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <hr className="border-slate-600 border-t-[1px] overflow-y-visible" />
      <Table
        contentDensity="compact"
        variant="borderless"
        columnDefinitions={[
          {
            id: "title",
            cell: item => <>
              <Link variant="secondary" href={`/item/${item.ip_id}`}>
                <span className="pl-2"> {item.ip}</span>
              </Link>
              <span className="pl-2">
                - {new Date(item.release_date).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </span>
            </>
          }
        ]}
        items={games}
        loadingText="Loading games..."
        empty={
          <Box textAlign="center">
            <TextContent>No games found</TextContent>
          </Box>
        }
      />
    </>
  );
}