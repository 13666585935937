import { createSlice } from "@reduxjs/toolkit";

const organizationsInitialState = {
    isOrganizationsListLoading: false,
    organizationsList: [],
    isOrganizationsListLoadingCompleted: false,
    isOrganizationsListError: false,
    message: ""
}

const organizationsSlice = createSlice(
    {
        initialState: organizationsInitialState,
        name: "organizationsList",
        reducers: {
            loading: (state) => {
                state.isOrganizationsListLoading = true;
                state.organizationsList = [];
            },
            insertOrganizationsList: (state, action) => {
                state.isOrganizationsListLoading = false;
                state.organizationsList = action.payload;
                state.isOrganizationsListLoadingCompleted = true;
                state.isOrganizationsListError = false;
            },
            clearOrganizationsList: () => {
                return organizationsInitialState;
            },
            insertError: (state, action) => {
                state.isOrganizationsListLoading = false;
                state.message = action.payload;
                state.isOrganizationsListLoadingCompleted = true;
                state.isOrganizationsListError = false;
            },
        }
    });
export const organizationsListActions = organizationsSlice.actions;
export default organizationsSlice.reducer;

