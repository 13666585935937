import { useCallback, useState } from "react";
import { useParams } from "react-router";
import { postCreateChannel } from "../../youtube/channelsList/api/request";
import { getItemData, updateTagAction } from "../redux/actions/item-actions";

export const useTagActions = () => {
  const { id } = useParams();
  const [attributeProcessing, setAttributeProcessing] = useState(false);

  const tagUpdateSubmit = useCallback(
    (data) => {
      setAttributeProcessing(true);
      updateTagAction(id, data)
        .then(() => {
          setAttributeProcessing(false);
          getItemData(id, {});
        })
        .finally(() => {
          setAttributeProcessing(false);
        });
      if (data?.update?.youtubeChannel) {
        postCreateChannel({
          channel_id: data?.update?.youtubeChannel,
          type: "Franchise",
          todaysViews: 0,
        });
      }
    },
    [id],
  );

  return {
    tagUpdateSubmit,
    attributeProcessing,
  };
};
