import { Box, Button, Drawer } from "@cloudscape-design/components";
import React, { FC, forwardRef, useState } from "react";
import { isSome } from "../../../../utils/sugarUtils";
import { ServicesListItem } from "./ServicesListItem";
import { servicesList } from "./menuConfig";


interface MenuDrawerProps {
  ref: React.Ref<HTMLDivElement>;
  visible: boolean;
  favoriteServices: Object;
  handleFavorites: (path: string, label: string) => void;
  onClose: () => void;
}

export const MenuDrawer: FC<MenuDrawerProps> = forwardRef(
  ({ visible, favoriteServices, handleFavorites, onClose }, ref) => {
    const [selectedGroup, setSelectedGroup] = useState<string>("favorites");

    const getContent = (services) => {
      const isFavoriteSelected = selectedGroup === "favorites";
      const label = isFavoriteSelected ? "Favorites" : services.label;
      const items = isFavoriteSelected
        ? Object.keys(favoriteServices).map((key) => ({
          path: key,
          label: favoriteServices[key],
          description: "",
        }))
        : services.items;

      return { label, items };
    };

    if (!visible) return null;

    return (
      <div className="z-[10000] absolute top-11 left-24 w-200 mt-2.5 rounded-md bg-slate-800 border-slate-600 border   shadow-lg pt-8 pl-8" ref={ref} style={{ height: '80vh', overflow: 'hidden' }}>
        <div className="flex space-x-8 h-full" style={{ overflow: 'hidden' }}>
          <ul className="basis-2/5 overflow-y-auto h-full">
            {servicesList.map(({ label, key }) => (
              <li
                key={key}
                className={`text-lg first:mb-6 first:pb-6 mb-4 first:border-b first:border-slate-600 hover:text-blue-400 ${selectedGroup === key ? 'text-blue-400' : 'text-white'}`}
              >
                <button onClick={() => setSelectedGroup(key)}>{label}</button>
              </li>
            ))}
          </ul>
          <div className="flex space-x-8 basis-3/5 h-full">
            <div className="border-l border-slate-600 mb-8"></div>
            <div className="w-full h-full">
              {servicesList
                .filter((item) => item.key === selectedGroup)
                .map((services) => {
                  const { label, items } = getContent(services);
                  return (
                    <div key={label} className="text-textDefaultColor h-full flex flex-col space-y-8">
                      <h2 className="text-2xl font-bold text-gray-400">{label}</h2>
                      <div className="overflow-y-auto pr-5 h-full pb-5">
                        <ul>
                          {items.map(({ label, path, description }) => (
                            <ServicesListItem
                              key={path}
                              path={path}
                              isFavorite={isSome(favoriteServices[path])}
                              handleFavorites={() => handleFavorites(path, label)}
                            >
                              <h4 className="text-lg text-white group-hover:text-blue-400">{label}</h4>
                              <p className="text-xs text-gray-400">{description}</p>
                            </ServicesListItem>
                          ))}
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        <div className="absolute top-4 right-4">
          <Button iconName="close" variant="icon" onClick={onClose} />
        </div>
      </div>
    );
  },
);
