import { 
  AppLayout,
  ContentLayout,
  Container,
  Header,
  SideNavigation,
  BreadcrumbGroup,
  Button,
  Table,
  Modal,
  SpaceBetween,
  Box,
  Input,
  Select,
  FormField,
  ButtonDropdown,
  StatusIndicator,
  Tabs,
  TextFilter,
  Pagination,
  AttributeEditor,
  Popover,
  Link,
  DatePicker,
  Spinner,
  Textarea,
  Autosuggest,
  TokenGroup,
} from "@cloudscape-design/components";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";
import React, { useEffect, useRef, useState } from "react";
import PantheonFlashbar from "../../../../components/PantheonFlashbar";
import { useAuthContext } from "../../../../auth/useAuthContext";
import axiosInstance from "../../../../utils/axios";
import moment from "moment";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import convertIntervalDate from "../../../../utils/convertIntervalDate";
import ResultsTable from "./ResultsTable";
import { slugify } from "./utils";
import { Layout } from "../../Layout";
import { navItems } from "../../../../layouts/common/menu/side-menu";

const getBrowserVisibilityProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
};

const getBrowserDocumentHiddenProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
};

const getIsDocumentHidden = () => {
  return !document[getBrowserDocumentHiddenProp()];
};

const usePageVisibility = () => {
  const [ isVisible, setIsVisible ] = useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();
    document.addEventListener(visibilityChange, onVisibilityChange, false);
    return () => document.removeEventListener(visibilityChange, onVisibilityChange);
  }, []);

  return isVisible;
};

const TwitterQueryTool = () => {

  const { user } = useAuthContext();

  const flashbarRef = useRef(null);
  const queriesFileUploadRef = useRef(null);
  const reportDataTableRef = useRef(null);

  const isVisibleTab = usePageVisibility();
  const navigate = useNavigate();
  const [ queryParams, setQueryParams ] = useSearchParams();



  const platformName = "Twitter";
  const breadcrumbs = [{ text: "Tools", }, { text: platformName + " Query Trends", href: "/", },];
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const defaultDateRange = {
    type: "absolute",
    startDate: moment()
      .subtract(90, "days")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  }
  const listRefreshInterval = 10 * 1000;
  const listOptions = [
    { value: "personal", label: "Personal" },
    { value: "organization", label: "Organization" },
  ];

  const [ myJobsListResponse, setMyJobsListResponse ] = useState(defaultResponseState);
  const [ getJobResponse, setGetJobResponse ] = useState(defaultResponseState);
  const [ getUserResponse, setGetUserResponse ] = useState(defaultResponseState);

  const [ queries, setQueries ] = useState([]);
  const [ dateRange, setDateRange ] = useState(defaultDateRange);
  const [ jobName, setJobName ] = useState(null);
  const [ listToSaveTo, setListToSaveTo ] = useState(listOptions[0]);
  const [ startSearchModalVisible, setStartSearchModalVisible ] = useState(false);
  const [ dateRangeValid, setDateRangeValid ] = useState(true);
  const [ userAutoSuggestValue, setUserAutoSuggestValue ] = useState("");
  const [ userAutoSuggestOptions, setUserAutoSuggestOptions ] = useState([]);
  const [ shareWithUsers, setShareWithUsers ] = useState([]);

  const [ listTabId, setListTabId ] = useState(listOptions.find(x => x.value === queryParams.get("list"))?.value || "personal");
  const [ myJobsTableItems, setMyJobsTableItems ] = useState([]);
  const [ selectedMyJobsTableItems, setSelectedMyJobsTableItems ] = useState([]);
  const [ myJobsInitiallyLoaded, setMyJobsInitiallyLoaded ] = useState(false);
  const [ jobMarkedForDeletion, setJobMarkedForDeletion ] = useState(null);

  const [ renameSearchModalVisible, setRenameSearchModalVisible ] = useState(false);
  const [ moveToModalVisible, setMoveToModalVisible ] = useState(false);
  const [ deleteSearchModalVisible, setDeleteSearchModalVisible ] = useState(false);
  const [ currentlyEditingJob, setCurrentlyEditingJob ] = useState(null);

  const [ timeseriesTableData, setTimeseriesTableData ] = useState([]);
  const [ downloadFormat, setDownloadFormat ] = useState("csv");

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(myJobsTableItems || [], {
    filtering: {},
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {},
  });



  const handleNewSearchFormSubmit = () => {
    if (!newSearchFormValid()) return;
    setStartSearchModalVisible(false);
    if (jobMarkedForDeletion) {
      const queryValues = queries.map((queries) => queries.value).filter(query => query?.length > 0);
      updateJob(jobMarkedForDeletion.id, "edit", {
        start_ts: moment.utc(dateRange.startDate).unix(),
        end_ts: moment.utc(dateRange.endDate).unix(),
        name: jobName,
        list: listToSaveTo.value,
        share_with: shareWithUsers.map(user => user.value).join(","),
      }, { queries: queryValues });
      setJobMarkedForDeletion(null);
    } else {
      startJob();
    }
  };

  const handleShowNewSearchModal = () => {
    setJobName("");
    setQueries([{ value: "" }]);
    setDateRange(defaultDateRange);
    setStartSearchModalVisible(true); 
    setJobMarkedForDeletion(null);
    setListToSaveTo(listTabId === "shared" ? listOptions.find(x => x.value == "personal") : listOptions.find(x => x.value == listTabId));
    setShareWithUsers([]);
  };
  
  const handleQueriesFileContent = (content) => {
    const newQueries = content.match(/[^\r\n]+/g).map(query => ({ value: query }));
    setQueries(newQueries);
  };

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const processJobTimeseriesData = (data) => {
    const validQueries = data.parameters.queries.filter(query => !data.errors.find(error => error.query_id === query.query_id));
    const timeseriesTable = validQueries.map((query, queryIndex) => {
      const point = { 
        query: query.query, 
        query_id: query.query_id, 
        color: "#ffffff",
      };
      const timestamps = {};
    
      data.timeseries.forEach(dataPoint => {
        point.total = (point.total || 0) + dataPoint.data[queryIndex];
        timestamps[dataPoint.timestamp] = dataPoint.data[queryIndex];
      });
    
      return { ...point, ...timestamps };
    });
    setTimeseriesTableData(timeseriesTable);
  };





  const newSearchFormValid = () => {
    if (queries.length == 0) return false;
    if (queries.map(query => query.value).filter(query => query?.length > 0)?.length == 0) return false;
    if (new Set(queries.map(query => query.value)).size !== queries.map(query => query.value).length) return false;
    if (!dateRange.startDate || !dateRange.endDate) return false;
    if (!getDateRangeValid(dateRange.startDate, dateRange.endDate)) return false;
    if (currentlyEditingJob && currentlyEditingJob.status !== "FAILED") {
      const currentQueries = queries.map(query => query.value).filter(query => query?.length > 0);
      const jobQueries = currentlyEditingJob.parameters.queries.map(query => query.query);
      if (currentQueries.length == jobQueries.length && 
        currentQueries.every((value, index) => value === jobQueries[index]) && 
        jobName == currentlyEditingJob.name && 
        listToSaveTo.value == currentlyEditingJob.list && 
        shareWithUsers.map(user => user.value).join(",") == currentlyEditingJob.shared_with.join(",") &&
        dateRange.startDate == moment.utc(currentlyEditingJob.parameters.start_ts * 1000).format("YYYY-MM-DD") &&
        dateRange.endDate == moment.utc(currentlyEditingJob.parameters.end_ts * 1000).format("YYYY-MM-DD")
      ) {
        return false;
      }
    }
    return true;
  };

  const getDateRangeValid = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return false;
    }
    if (typeof startDate === 'string' || startDate instanceof String) {
      startDate = new Date(Date.parse(startDate));
    }
    if (typeof endDate === 'string' || endDate instanceof String) {
      endDate = new Date(Date.parse(endDate));
    }
    if (startDate < new Date(Date.parse("2006-03-21")) || endDate < new Date(Date.parse("2006-03-21")) || startDate > new Date() || startDate > endDate) {
      return false;
    }
    return true;
  };

  const getEstimatedJobTime = () => {
    const numQueries = queries.map(query => query.value).filter(query => query?.length > 0)?.length || 0;
    const numMonths = Math.ceil(moment(dateRange.endDate).diff(moment(dateRange.startDate), 'days', true) / 31);
    const num300Requests = Math.floor(numQueries * numMonths / 300);
    const totalEstimatedTime = numMonths * 0.23 * numQueries + (num300Requests * 15 * 60);
    
    return totalEstimatedTime;
  };

  const startJob = () => {
    const queryValues = queries.map((queries) => queries.value).filter(query => query?.length > 0);
    const startTimestamp = moment.utc(dateRange.startDate).unix();
    const endTimestamp = moment.utc(dateRange.endDate).unix();

    flashbarRef?.current?.setFlashbarMessage("loading", <div>Creating search job.</div>, null, 5000);

    const params = {
      start_ts: startTimestamp,
      end_ts: endTimestamp,
      name: jobName,
      list: listToSaveTo.value,
    };

    if (shareWithUsers.length > 0) {
      params.share_with = shareWithUsers.map(user => user.value).join(",");
    }

    axiosInstance.request({
      method: "POST",
      url: "/twitter/querytool/startjob",
      params: params,
      data: {
        queries: queryValues,
      },
    }).then(response => {
      flashbarRef?.current?.setFlashbarMessage("success", <div>Successfully created search job.</div>, null, 5000);
      fetchJobsList();
    }).catch(e => {
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to create search job.", "Failed to create search job");
    });
  };

  const fetchJobsList = (listToGet) => {
    setMyJobsListResponse({ ...myJobsListResponse, loading: true, completed: false, error: null });

    if (!listToGet) listToGet = listTabId;

    axiosInstance.request({
      method: "GET",
      url: "/twitter/querytool/listjobs",
      params: {
        list: listToGet,
      }
    }).then(response => {
      setMyJobsListResponse({ ...myJobsListResponse, data: response.data, loading: false, completed: true, error: null });
      const tableItems = response.data.data;
      setMyJobsTableItems(tableItems);
      setMyJobsInitiallyLoaded(true);
    }).catch(error => {
      setMyJobsListResponse({ ...myJobsListResponse, data: null, error: error, loading: false, completed: true });
    });
  };

  const deleteJob = (jobId) => {
    flashbarRef?.current?.setFlashbarMessage("loading", <div>Deleting search.</div>, null, 5000);

    const newMyJobsTableItems = myJobsTableItems.filter(item => item.id !== jobId);
    setMyJobsTableItems(newMyJobsTableItems);

    axiosInstance.request({
      method: "PATCH",
      url: "/twitter/querytool/updatejob",
      params: {
        id: jobId,
        action: "delete",
      }
    }).then(response => {
      flashbarRef?.current?.setFlashbarMessage("success", <div>Successfully deleted search.</div>, null, 5000);
      fetchJobsList();
    }).catch(e => {
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to delete search job.", "Failed to delete search job");
    });
  };

  const updateJob = (jobId, action, params, data) => {
    flashbarRef?.current?.setFlashbarMessage("loading", <div>Updating search.</div>, null, 5000);

    axiosInstance.request({
      method: "PATCH",
      url: "/twitter/querytool/updatejob",
      params: {
        id: jobId,
        action: action,
        ...params,
      },
      data: data,
    }).then(response => {
      flashbarRef?.current?.setFlashbarMessage("success", <div>Successfully updated search.</div>, null, 5000);
      fetchJobsList();
    }).catch(e => {
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to update search job.", "Failed to update search job");
    });
  };

  const getJob = (jobId, forDownload, format) => {  
    if (forDownload) flashbarRef?.current?.setFlashbarMessage("loading", <div>Generating report.</div>, null);
    setGetJobResponse({ ...getJobResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/twitter/querytool/getjob",
      params: {
        id: jobId,
        fetch_results: forDownload,
      }
    }).then(response => {
      setGetJobResponse({ ...getJobResponse, loading: false, completed: true, data: response.data, error: null });
      if (forDownload) {
        setDownloadFormat(format);
        processJobTimeseriesData(response.data);
      } else {
        const data = response.data;
        setJobName(data.name);
        setDateRange({
          type: "absolute",
          startDate: moment.utc(data.parameters.start_ts * 1000).format("YYYY-MM-DD"),
          endDate: moment.utc(data.parameters.end_ts * 1000).format("YYYY-MM-DD"),
        });
        setListToSaveTo(data.list === "shared" ? listOptions.find(x => x.value == "personal") : listOptions.find(x => x.value == data.list));
        setQueries(data.parameters.queries.map(query => ({ value: query.query })));
        setShareWithUsers(data.shared_with.map(user => ({ value: user, label: user })));
        setCurrentlyEditingJob(data);
      }
    }).catch(e => {
      setGetJobResponse({ ...getJobResponse, loading: false, completed: true, error: e });
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to get search job.", "Failed to get search job");
    });
  };

  const getUser = (username) => {  
    setGetUserResponse({ ...getUserResponse, loading: true, completed: false, error: null });

    axiosInstance.request({
      method: "GET",
      url: "/twitter/querytool/getuser",
      params: {
        user: username,
      }
    }).then(response => {
      setGetUserResponse({ ...getUserResponse, loading: false, completed: true, data: response.data, error: null });
      setUserAutoSuggestOptions([
        {
          value: response.data.user_name,
          label: response.data.name,
          tags: [response.data.email_address, response.data.organization],
        },
      ]);
    }).catch(e => {
      setGetUserResponse({ ...getUserResponse, loading: false, completed: true, error: e });
      setUserAutoSuggestOptions([]);
    });
  };

  const jobListTable = () => {
    return (
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => setSelectedMyJobsTableItems(detail.selectedItems)}
        selectedItems={selectedMyJobsTableItems}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: item => (
              <Header
                variant="h4"
                description={item.num_queries + ` ${item.num_queries == 1 ? "query" : "queries"}${item.num_failed_queries > 0 ? ` (${item.num_failed_queries} failed)` : ""} \u2022 ` + moment.utc(item.start_ts * 1000).format("MMM D, YYYY") + " - " + moment.utc(item.end_ts * 1000).format("MMM D, YYYY")}
              >
                <div className="flex items-center space-x-2">
                  <Button 
                    href={`/tools/twitter-query-trends/searches/${item.id}`} 
                    variant="inline-link" 
                    disabled={item.status !== "FINISHED"}
                    onFollow={onFollowHandler}
                  >
                    {item.name}
                  </Button>
                  {item.shared_with.length > 0 && (
                    <Popover
                      dismissButton={false}
                      position="right"
                      triggerType="custom"
                      size="large"
                      content={
                        <div className="space-y-2">
                          <p>
                            Shared with: <b>{item.shared_with.join(", ")}</b>
                          </p>
                        </div>
                      }
                    >
                      <Button iconName="group-active" variant="inline-icon" />
                    </Popover>
                  )}
                </div>
              </Header>
            ),
            isRowHeader: true,
            minWidth: 300,
          },
          {
            id: "created_at",
            header: "Created at",
            cell: item => new Date(item.created_at * 1000).toLocaleString("en-US", { month: "long", day: "numeric", year: "numeric", hour: "numeric", minute: "numeric", hour12: true }),
          },
          {
            id: "created_by",
            header: "Created by",
            cell: item => item.created_by,
          },
          {
            id: "status",
            header: "Status",
            cell: item => {
              switch (item.status) {
                case "RUNNING":
                  return (
                    <StatusIndicator type="pending">
                      Running
                    </StatusIndicator>
                  );
                case "FINISHED":
                  if (item.num_failed_queries > 0) {
                    return (
                      <Popover
                        dismissButton={true}
                        position="top"
                        size="large"
                        triggerType="custom"
                        content={
                          <div>
                            <p>The search completed, but {item.num_failed_queries} {item.num_failed_queries == 1 ? "query" : "queries"} failed.</p>
                            <p><Link href={`/tools/twitter-query-trends/searches/${item.id}`}>Check the search</Link> for more details.</p>
                          </div>
                        }
                      >
                        <StatusIndicator type="warning">
                          <span className="underline cursor-pointer">Finished</span>
                        </StatusIndicator>
                      </Popover>
                    );
                  } else {
                    return (
                      <StatusIndicator>
                        Finished
                      </StatusIndicator>
                    );
                  }
                case "FAILED":
                  return (
                    <StatusIndicator type="error">
                      Failed
                    </StatusIndicator>
                  );
              }
            },
          },
        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "created_at", visible: true },
          { id: "created_by", visible: listTabId == "organization" || listTabId == "shared" },
          { id: "status", visible: true },
          { id: "actions", visible: true },
        ]}
        loadingText="Loading searches"
        items={items}
        variant="embedded"
        selectionType="single"
        loading={!myJobsInitiallyLoaded}
        pagination={
          <Pagination {...paginationProps} />
        }
        filter={
          <TextFilter
            {...filterProps}
            //filteringText={filteringText}
            //onChange={({ detail }) => setFilteringText(detail.value)}
            filteringPlaceholder="Find searches"
            filteringAriaLabel="Filter searches"
          />
        }
        empty={
          <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
          >
            <SpaceBetween size="m">
              <b>No searches</b>
              <Button
                variant="primary"
                iconName="search"
                onClick={handleShowNewSearchModal}
              >
                Start search
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            variant="h2"
            description={listTabId == "personal" ? "View your saved searches and run new ones" : listTabId == "organization" ? "View your organization's saved searches and run new ones" : "View searches shared with you"}
            counter={`(${myJobsTableItems.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  items={[
                    { text: "Download report as CSV", id: "download_report_csv", disabled: selectedMyJobsTableItems[0]?.status !== "FINISHED" },
                    { text: "Download report as XLSX", id: "download_report_xlsx", disabled: selectedMyJobsTableItems[0]?.status !== "FINISHED" },
                    { text: "Edit", id: "edit", disabled: selectedMyJobsTableItems[0]?.status === "RUNNING" },
                    /*{ text: "Rename", id: "rename" },
                    { text: "Move to", id: "move_to", disabled: selectedMyJobsTableItems[0]?.created_by !== user.username },*/
                    { text: "Delete", id: "delete", disabled: selectedMyJobsTableItems[0]?.status === "RUNNING" },
                  ]}
                  disabled={selectedMyJobsTableItems.length == 0}
                  onItemClick={({ detail }) => {
                    const selectedJob = selectedMyJobsTableItems[0];
                    switch (detail.id) {
                      case "rename":
                        setRenameSearchModalVisible(true);
                        setCurrentlyEditingJob(selectedJob);
                        setJobName(selectedJob.name);
                        break;
                      case "move_to":
                        setMoveToModalVisible(true);
                        setCurrentlyEditingJob(selectedJob);
                        setListToSaveTo(selectedJob.list === "shared" ? listOptions.find(x => x.value == "personal") : listOptions.find(x => x.value == selectedJob.list));
                        break;
                      case "edit":
                        setJobName("");
                        setQueries([]);
                        setDateRange(defaultDateRange);
                        setListToSaveTo(listOptions[0]);
                        getJob(selectedJob.id, false);
                        setJobMarkedForDeletion(selectedJob);
                        setCurrentlyEditingJob(selectedJob);
                        setStartSearchModalVisible(true);
                        break;
                      case "delete":
                        setJobMarkedForDeletion(selectedJob);
                        setDeleteSearchModalVisible(true);
                        break;
                      case "download_report_csv":
                        getJob(selectedJob.id, true, "csv");
                        break;
                      case "download_report_xlsx":
                        getJob(selectedJob.id, true, "xlsx");
                        break;
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant="primary"
                  iconName="search"
                  onClick={handleShowNewSearchModal}
                >
                  Start search
                </Button>
              </SpaceBetween>
            }
          >
            {listTabId == "personal" ? "My searches" : listTabId == "organization" ? `Organization searches - ${user["custom:organization"]}` : "Shared with me"}
          </Header>
        }
      />
    );
  };



  useEffect(() => {
    if (isVisibleTab === true) {
      fetchJobsList();
    }

    const interval = setInterval(() => {
      if (isVisibleTab) {
        fetchJobsList(listTabId);
      }
    }, listRefreshInterval);

    return () => {
      clearInterval(interval);
    };
  }, [listTabId, isVisibleTab]);

  useEffect(() => {
    setSelectedMyJobsTableItems(myJobsTableItems.filter(item => selectedMyJobsTableItems.map(x => x.id).includes(item.id)));
  }, [myJobsTableItems]);

  const title = `${platformName} Query Tool`;

  return (
    <>
      <Layout
        title={title}
        navItems={navItems}
        breadcrumbs={breadcrumbs}
        content={
          <>
            <ContentLayout
              header={<PantheonFlashbar ref={flashbarRef} />}
            >
              <Container variant="borderless" disableContentPaddings disableHeaderPaddings>
                <Tabs
                  onChange={({ detail }) => {
                    setMyJobsInitiallyLoaded(false);
                    setListTabId(detail.activeTabId);
                    setQueryParams({ list: detail.activeTabId });
                  }}
                  activeTabId={listTabId}
                  tabs={[
                    {
                      label: "My searches",
                      id: "personal",
                      content: jobListTable(),
                    },
                    {
                      label: "Organization searches",
                      id: "organization",
                      content: jobListTable(),
                    },
                    {
                      label: "Shared with me",
                      id: "shared",
                      content: jobListTable(),
                    },
                  ]}
                />
              </Container>
            </ContentLayout>



            <Modal
              header={!!jobMarkedForDeletion ? "Edit search" : "Start a new search"}
              visible={startSearchModalVisible}
              onDismiss={() => setStartSearchModalVisible(false)}
              size="large"
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    {queries.map(query => query.value).filter(query => query?.length > 0)?.length > 0 && (
                      <div className="flex flex-col h-full justify-center text-gray-400">Estimated job time: {moment.utc(getEstimatedJobTime() * 1000).format("H[h] m[m] s[s]")}</div>
                    )}
                    <Button 
                      variant="link" 
                      onClick={() => setStartSearchModalVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button 
                      variant="primary" 
                      disabled={!newSearchFormValid()}
                      onClick={handleNewSearchFormSubmit}
                    >
                      Submit
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              {getJobResponse?.loading ? (
                <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                  <Spinner size="large" />
                </div> 
              ) : (
                <SpaceBetween direction="vertical" size="m">
                  <SpaceBetween direction="horizontal" size="m">
                    <FormField
                      label="Date range"
                      info={
                        <Popover
                          dismissButton={true}
                          position="top"
                          triggerType="custom"
                          size="large"
                          content={
                            <div className="space-y-2">
                              <p>
                                Date ranges that extend past today will enable daily updates on this search until the specified end date.
                              </p>
                            </div>
                          }
                        >
                          <Button iconName="status-info" variant="inline-icon" />
                        </Popover>
                      }
                    >
                      <RangeDateSelector
                        defaults={dateRange}
                        onChange={(e) => {
                          setDateRangeValid(getDateRangeValid(e.startDate, e.endDate));
                          setDateRange(e);
                        }}
                        isValidRange={(value) => {
                          let startDate = null;
                          let endDate = null;
                          if (value.type === "relative") {
                            const newDateRange = convertIntervalDate(value);
                            startDate = newDateRange.startDate;
                            endDate = newDateRange.endDate;
                          } else if (value.type === "absolute") {
                            startDate = new Date(Date.parse(value.startDate.split("T")[0]));
                            endDate = new Date(Date.parse(value.endDate.split("T")[0]));
                          }
                          return getDateRangeValid(startDate, endDate);
                        }}
                        isDateEnabled={(date) => {
                          return date > new Date(Date.parse("2006-03-21"));
                        }}
                        invalid={dateRangeValid === false}
                      />
                    </FormField>
                  </SpaceBetween>
                  <FormField
                    label="Name"
                    stretch={true}
                  >
                    <Input
                      autoFocus
                      onChange={({ detail }) => setJobName(detail.value)}
                      value={jobName}
                      placeholder="Enter a name for this search"
                    />
                  </FormField>
                  <FormField
                    label="Save to list"
                    stretch={true}
                  >
                    <Select
                      onChange={({ detail }) => setListToSaveTo(detail.selectedOption)}
                      selectedOption={listToSaveTo}
                      options={listOptions}
                    />
                  </FormField>
                  <FormField
                    label="Share with"
                    stretch={true}
                  >
                    <>
                      <Autosuggest
                        onChange={({ detail }) => setUserAutoSuggestValue(detail.value)}
                        value={userAutoSuggestValue}
                        onLoadItems={({ detail }) => detail.filteringText?.length > 0 ? getUser(detail.filteringText) : []}
                        onSelect={({ detail }) => {
                          if (!detail.selectedOption) return;
                          const user = detail.selectedOption;
                          setShareWithUsers([...shareWithUsers, user]);
                          setUserAutoSuggestValue("");
                        }}
                        options={userAutoSuggestOptions}
                        placeholder="Add users by email or username"
                        empty="No users found"
                        loadingText="Loading users..."
                        statusType={getUserResponse.loading ? "loading" : "finished"}
                        enteredTextLabel={(value) => `Use: ${value}`}
                      />
                      <TokenGroup
                        items={shareWithUsers.map(u => ({ ...u, disabled: u.value === user.username }))}
                        onDismiss={({ detail: { itemIndex } }) => {
                          setShareWithUsers([
                            ...shareWithUsers.slice(0, itemIndex),
                            ...shareWithUsers.slice(itemIndex + 1)
                          ]);
                        }}
                      />
                    </>
                  </FormField>
                  <FormField
                    label={<span>Queries ({queries.map(query => query.value).filter(query => query?.length > 0)?.length || 0})</span>}
                    stretch={true}
                    info={
                      <span className="space-x-1">
                        <Popover
                          dismissButton={true}
                          position="top"
                          triggerType="custom"
                          size="large"
                          content={
                            <div className="space-y-2">
                              <p>
                                Queries must follow <Link href="https://developer.twitter.com/en/docs/twitter-api/tweets/counts/integrate/build-a-query" external>{platformName}'s query format.</Link>
                              </p>
                              <p>
                                When previewing queries, please note that {platformName}'s search feature may not support all operators that the API does.
                              </p>
                              <p>
                                You may also import queries from a text file. Each query should be on a separate line.
                              </p>
                            </div>
                          }
                        >
                          <Button iconName="status-info" variant="inline-icon" />
                        </Popover>
                        <span style={{ 
                          borderLeft: "var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-j74lyz, #b6bec9)", 
                          paddingLeft: "16px",
                          marginLeft: "8px",
                        }}>
                          <Button
                            iconName="upload"
                            variant="inline-link"
                            onClick={() => queriesFileUploadRef.current.click()}
                          >
                            Import queries
                          </Button>
                          <input 
                            ref={queriesFileUploadRef}
                            type="file"
                            accept="text/plain"
                            multiple={false}
                            onChange={(e) => {
                              e.preventDefault();
                              if (e.target.files.length == 0) return;
                              const reader = new FileReader();
                              reader.onload = async (e) => { 
                                const text = (e.target.result);
                                handleQueriesFileContent(text);
                              };
                              reader.readAsText(e.target.files[0]);
                            }}  
                            className="hidden"
                          />
                        </span>
                        <span style={{ 
                          borderLeft: "var(--border-divider-section-width-1061zr, 1px) solid var(--color-border-divider-default-j74lyz, #b6bec9)", 
                          paddingLeft: "12px",
                          marginLeft: "12px",
                        }}>
                          <Button
                            variant="inline-link"
                            onClick={() => {
                              setQueries([{ value: "" }]);
                              queriesFileUploadRef.current.value = "";
                            }}
                          >
                            Clear
                          </Button>
                        </span>
                      </span>
                    }
                  >
                    <style>
                      {`
                        #query-input {
                          resize: none !important;
                          white-space: pre !important;
                          overflow-wrap: normal !important;
                        }
                        #query-input::-webkit-scrollbar {
                          display: none;
                        }
                      `}
                    </style>
                    <AttributeEditor
                      onAddButtonClick={() => setQueries([...queries, {}])}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tmpItems = [...queries];
                        tmpItems.splice(itemIndex, 1);
                        setQueries(tmpItems);
                      }}
                      items={queries}
                      addButtonText="Add query"
                      removeButtonText="Remove"
                      definition={[
                        {
                          control: (item, itemIndex) => (
                            <div className="flex items-center space-x-2">
                              <div className="grow">
                                <Textarea
                                  rows={1} // Have to use a textarea and custom CSS just to be able to paste multiple lines
                                  value={item.value}
                                  placeholder="Enter a query"
                                  onChange={({ detail }) => {
                                    setQueries(queries => {
                                      const updatedQueries = [...queries];
                                      const lines = detail.value.match(/[^\r\n]+/g);
                                      if (!lines || lines.length == 0) {
                                        updatedQueries[itemIndex] = {
                                          ...updatedQueries[itemIndex],
                                          value: detail.value,
                                        };
                                        return updatedQueries;
                                      }
                                      updatedQueries[itemIndex] = {
                                        ...updatedQueries[itemIndex],
                                        value: lines[0],
                                      };
                                      lines.slice(1).forEach(line => {
                                        updatedQueries.push({ value: line });
                                      });
                                      return updatedQueries;
                                    });
                                  }}
                                  controlId="query-input"
                                  invalid={queries.filter(query => query.value === item.value).length > 1 && item.value.length > 0}
                                  ariaLabel="hello"
                                />
                              </div>
                              <Button 
                                iconName="external" 
                                variant="inline-icon" 
                                href={`https://twitter.com/search?f=live&q=${encodeURIComponent(item.value)}`} 
                                target="_blank" 
                                disabled={!item.value || item.value.length == 0}
                              />
                            </div>
                          ),
                        },
                      ]}
                      isItemRemovable={(item) => queries.length > 1}
                      empty={<div>Click <b>Add query</b> to start building your search</div>}
                    />
                  </FormField>
                </SpaceBetween>
              )}
            </Modal>

            <Modal
              header="Rename search"
              visible={renameSearchModalVisible}
              onDismiss={() => setRenameSearchModalVisible(false)}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button 
                      variant="link" 
                      onClick={() => setRenameSearchModalVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button 
                      variant="primary" 
                      disabled={!currentlyEditingJob || jobName == currentlyEditingJob.name || !jobName || jobName.length == 0}
                      onClick={() => {
                        updateJob(currentlyEditingJob.id, "edit_name", { name: jobName });
                        setRenameSearchModalVisible(false);
                      }}
                    >
                      Submit
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <FormField
                label="Name"
              >
                <Input
                  onChange={({ detail }) => setJobName(detail.value)}
                  value={jobName}
                />
              </FormField>
            </Modal>

            <Modal
              header="Move search"
              visible={moveToModalVisible}
              onDismiss={() => setMoveToModalVisible(false)}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button 
                      variant="link" 
                      onClick={() => setMoveToModalVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button 
                      variant="primary" 
                      disabled={!currentlyEditingJob || listToSaveTo.value == currentlyEditingJob.list}
                      onClick={() => {
                        updateJob(currentlyEditingJob.id, "change_list", { list: listToSaveTo.value });
                        setMoveToModalVisible(false);
                      }}
                    >
                      Submit
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              <FormField
                label="Save to list"
              >
                <Select
                  onChange={({ detail }) => setListToSaveTo(detail.selectedOption)}
                  selectedOption={listToSaveTo}
                  options={listOptions}
                />
              </FormField>
            </Modal>

            <Modal
              header="Confirm deletion"
              visible={deleteSearchModalVisible}
              onDismiss={() => setDeleteSearchModalVisible(false)}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button 
                      variant="link" 
                      onClick={() => setDeleteSearchModalVisible(false)}
                    >
                      Cancel
                    </Button>
                    <Button 
                      variant="primary" 
                      disabled={!jobMarkedForDeletion}
                      onClick={() => {
                        deleteJob(jobMarkedForDeletion.id);
                        setJobMarkedForDeletion(null);
                        setDeleteSearchModalVisible(false);
                      }}
                    >
                      Delete
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              Delete search <b>{jobMarkedForDeletion?.name}</b>?
            </Modal>

            {timeseriesTableData.length > 0 && (
              <div className="hidden">
                <ResultsTable
                  ref={reportDataTableRef}
                  seriesData={timeseriesTableData || []}
                  onStateUpdated={(e) => {
                    if (downloadFormat === "csv") {
                      e.api.exportDataAsCsv({ fileName: `${slugify(getJobResponse.data.name).slice(0, 32)}_${moment().format("YYYY-MM-DD")}.csv` });
                    } else if (downloadFormat === "xlsx") {
                      const spreadsheets = [
                        e.api.getSheetDataForExcel({ sheetName: "Post Counts" }),
                      ];
                      e.api.exportMultipleSheetsAsExcel({
                        data: spreadsheets,
                        fileName: `${slugify(getJobResponse.data.name).slice(0, 32)}_${moment().format("YYYY-MM-DD")}.xlsx`,
                      });
                    }
                    flashbarRef?.current?.setFlashbarMessage("success", <div>Successfully generated report.</div>, null, 5000);
                  }}
                />
              </div>
            )}
          </>
        }
      />
    </>
  );
};

export default TwitterQueryTool;