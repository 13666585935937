import { DateRangePicker } from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import convertIntervalDate from "../utils/convertIntervalDate";

export const RangeDateSelector = ({ onChange, defaults, ...props }) => {
  const [dateRange, setDateRange] = useState(null);
  useEffect(() => {
    setDateRange(defaults);
  }, [defaults]);

  return (
    <DateRangePicker
      onChange={({ detail }) => {
        if (detail.value) {
          if (detail.value.type === "relative") {
            
            setDateRange(detail.value);
            onChange(convertIntervalDate(detail.value));
          } else if (detail.value.type === "absolute") {
            setDateRange(detail.value);
            onChange(detail.value);
          }
        } else {
          setDateRange(null);
          onChange(null);
        }
      }}
      value={dateRange}
      relativeOptions={[
        {
          key: "previous-7-days",
          amount: 7,
          unit: "day",
          type: "relative",
        },
        {
          key: "previous-14-days",
          amount: 14,
          unit: "day",
          type: "relative",
        },
        {
          key: "previous-1-month",
          amount: 1,
          unit: "month",
          type: "relative",
        },
        {
          key: "previous-3-months",
          amount: 3,
          unit: "month",
          type: "relative",
        },
      ]}
      isValidRange={(range) => {
        if (range.type === "absolute") {
          const [startDateWithoutTime] = range.startDate.split("T");
          const [endDateWithoutTime] = range.endDate.split("T");
          if (!startDateWithoutTime || !endDateWithoutTime) {
            return {
              valid: false,
              errorMessage:
                "The selected date range is incomplete. Select a start and end date for the date range.",
            };
          }
          if (new Date(range.startDate) - new Date(range.endDate) > 0) {
            return {
              valid: false,
              errorMessage:
                "The selected date range is invalid. The start date must be before the end date.",
            };
          }
        }
        return { valid: true };
      }}
      isDateEnabled={(date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Set time to midnight for comparison
        return date <= today;
      }}
      i18nStrings={{
        todayAriaLabel: "Today",
        nextMonthAriaLabel: "Next month",
        previousMonthAriaLabel: "Previous month",
        customRelativeRangeDurationLabel: "Duration",
        customRelativeRangeDurationPlaceholder: "Enter duration",
        customRelativeRangeOptionLabel: "Custom range",
        customRelativeRangeOptionDescription: "Set a custom range in the past",
        customRelativeRangeUnitLabel: "Unit of time",
        formatRelativeRange: (e) => {
          return `Last ${e.amount} ${1 === e.amount ? e.unit : e.unit + "s"}`;
        },
        formatUnit: (e, n) => (1 === n ? e : `${e}s`),
        dateTimeConstraintText: "For date, use YYYY/MM/DD.",
        relativeModeTitle: "Relative range",
        absoluteModeTitle: "Absolute range",
        relativeRangeSelectionHeading: "Choose a range",
        startDateLabel: "Start date",
        endDateLabel: "End date",
        startTimeLabel: "Start time",
        endTimeLabel: "End time",
        clearButtonLabel: "Clear and dismiss",
        cancelButtonLabel: "Cancel",
        applyButtonLabel: "Apply",
      }}
      dateOnly
      expandToViewport
      placeholder="Filter by a date and time range"
      {...props}
    />
  );
};
