import axiosInstance from "../../../../../utils/axios";

export const getBoByStudioData = (year) => {
  return axiosInstance
    .post(`/pql?key=boxoffice/boxoffice_distributors&file_type=P`, {
      sql: `SELECT * FROM s3object WHERE "year" = ${year}`,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Failed to fetch BoxOffice data:", error);
      throw error;
    });
};
