import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
import _ from "lodash";

const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchWikiRealTimeDataApi = createAsyncThunk(
  "trends/fetchWikiRealTimeDataApi",

  async ({ params }) => {
    const response = await axiosInstance.request({
      url: `/realtime/wikipedia`,
      method: `GET`,
      params: params,
    });
    return response;
  }
);

export const fetchRealTimeMonthApi = createAsyncThunk(
  "trends/fetchRealTimeMonthApi",

  async ({ params }) => {
    const response = await axiosInstance.request({
      url: `/realtime/googletrends`,
      method: `GET`,
      params: params,
    });
    return response;
  }
);

export const fetchRealTimeMonthWithKeywordAndPropertyApi = createAsyncThunk(
  "trends/fetchRealTimeMonthWithKeywordAndPropertyApi",

  async ({ params }) => {
    const response = await axiosInstance.request({
      url: `/realtime/googletrends`,
      method: `GET`,
      params: params,
    });
    return response;
  }
);

const trendsDataSlice = createSlice({
  name: "trendsData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWikiRealTimeDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWikiRealTimeDataApi.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        _.set(state, "data.wikipedia", action.payload.data);
      })
      .addCase(fetchWikiRealTimeDataApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
    builder
      .addCase(fetchRealTimeMonthApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRealTimeMonthApi.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        _.set(state, "data.googleTrends", action.payload.data);
      })
      .addCase(fetchRealTimeMonthApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
    builder
      .addCase(fetchRealTimeMonthWithKeywordAndPropertyApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchRealTimeMonthWithKeywordAndPropertyApi.fulfilled,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          _.set(state, "data.youtubeTrends", action.payload.data);
        }
      )
      .addCase(
        fetchRealTimeMonthWithKeywordAndPropertyApi.rejected,
        (state, action) => {
          state.loading = false;
          state.completed = true;
          state.hasError = true;
          state.message = action.error.message;
        }
      );
  },
});

const trendsDataReducer = trendsDataSlice.reducer;

export default trendsDataReducer;

export const trendsDataActions = trendsDataSlice.actions;
