import "ag-grid-enterprise";
import React from "react";
import { PlatformTrendsCharts } from "../../components/PlatformTrendsCharts";
import { getSteamTrendsData } from "../api/request";
import { steamCategoryOptions, steamApiParams ,defaultCategory as defCategory} from "../constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "../utils/gridConfig";

export const SteamTrendsCharts = ({
  defaultCategory=defCategory,
  setSelectedVertical,
}) => {
  return (
    <PlatformTrendsCharts
      apiParams={steamApiParams}
      defaultColumn={defaultColumn}
      defaultCategory={defaultCategory}
      categoryOptions={steamCategoryOptions}
      corePlatformColumns={corePlatformColumns}
      fetchMethod={getSteamTrendsData}
      setSelectedVertical={setSelectedVertical}
      additionalPlatformColumns={additionalPlatformColumns}
    />
  );
};
