import axiosInstance from "../../../../utils/axios";

export type GetVideosListDataProps = {
  from: number;
  size: number;
  include?: Array<any>;
  exclude: Array<any>;
  condition: string;
  sort: Array<any>;
  contentType: string;
};

export const DEFAULT_API_PARAMS = {
  from: 0,
  size: 50,
  include: [],
  exclude: [],
  sort: [],
  condition: "and",
};

export const getVideosListData = async ({
  from = DEFAULT_API_PARAMS.from,
  size = DEFAULT_API_PARAMS.size,
  include = DEFAULT_API_PARAMS.include,
  exclude = DEFAULT_API_PARAMS.exclude,
  sort = DEFAULT_API_PARAMS.sort,
  condition = "and",
  contentType,
}: GetVideosListDataProps) => {
  let contentTypeFilter;
  let sortConditions = [...sort];
  if (contentType === "unmapped") {
    contentTypeFilter = [
      {
        bool: {
          should: [
            { bool: { must_not: { exists: { field: "ip_id" } } } },
            { match: { ip_id: "" } },
          ],
        },
      },
    ];
    sortConditions.push({ publishedAt: "desc" });
  } else if (contentType === "unofficial") {
    contentTypeFilter = [{ match: { official: false } }];
    sortConditions.push({ publishedAt: "desc" });
  } else if (contentType === "kids") {
    contentTypeFilter = [{ match: { madeForKids: true } }];
  } else if (contentType === "all") {
    contentTypeFilter = [];
    sortConditions.push({ publishedAt: "desc" });
  } else {
    contentTypeFilter = [
      { prefix: { ip_id: contentType } },
      { match: { official: true } },
      { match: { madeForKids: false } },
    ];
    sortConditions.push({ todayViews: "desc" });
  }

  const combinedIncludeFilters = [...contentTypeFilter, ...include];

  const requestBody = {
    include: combinedIncludeFilters,
    exclude,
    sort: sortConditions,
    condition,
  };

  return axiosInstance.request({
    url: `youtube/v1/videos/list?from=${from}&size=${size}`,
    method: "POST",
    data: requestBody,
  });
};

export type TopSearchResponseType = {
  image_url: string;
  ip: string;
  ip_id: string;
  release_date: string;
  vertical: string;
};

export const getTopSearch = (keyword: string) => {
  return axiosInstance.request({
    url: `/topsearch?keyword=${keyword}&fields=ip,ip_id,vertical,image_url,release_date&vertical=Movies,Television,Gaming`,
    method: "GET",
  });
};

export type DeleteVideosParamsType = { payload: Array<string> };

export const deleteVideos = ({ payload }: DeleteVideosParamsType) =>
  axiosInstance.request({
    url: `/youtube/v1/videos`,
    method: "DELETE",
    headers: {
      "Content-Type": "text/plain",
    },
    data: payload,
  });
