import { PLATFORM_INDEX_MAP } from "../../constants";
import { PantheonRankingResponseItem } from "./api/request";

export const getOptions = (key: string, data) => {
  if (key === "category") {
    return [
      { label: "Movies", value: "movies" },
      { label: "Television", value: "television" },
      { label: "Games", value: "gaming" },
    ];
  }

  if (key === "sort") {
    return [
      { label: "Pantheon score", value: "pantheon_score" },
      { label: "Search score", value: "search_score" },
      { label: "Engagement score", value: "engagement_score" },
      { label: "Demand score", value: "demand_score" },
    ];
  }

  if (key === "interval") {
    return [
      { label: "Yearly", value: "yearly" },
      { label: "Monthly", value: "monthly" },
      { label: "Weekly", value: "weekly" },
    ];
  }

  if (key === "intervalDateRange") {
    return data?.map((item) => ({
      label: item,
      value: item,
    }));
  }

  if (!data?.[key] || data[key].length === 0) return [];

  const allOption = { label: "All", value: "All" };
  const options = data[key].map((item: PantheonRankingResponseItem) => ({
    label: item.toString(),
    value: item.toString(),
  }));
  options.unshift(allOption);

  return options;
};

export const weightsObjectToWeightageValues = (weightsObject) => {
  const newWeightageValues = {};
  weightsObject.weights.forEach((weight) => {
    newWeightageValues[PLATFORM_INDEX_MAP[weight.platform.key]] = weight.enabled
      ? parseFloat(weight.weight)
      : 0.0;
  });
  return newWeightageValues;
};