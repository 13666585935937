import {
  ExpandableSection,
  Select,
  SpaceBetween,
  Spinner
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TrailerCard } from "../../../../item/pages/components/Trailer/TrailerCard";
import { getChannelVideos } from "../../api/request";

const VideoList = ({ channelId }) => {
  const [videos, setVideos] = useState([]);
  const [mappedVideos, setMappedVideos] = useState(0);
  const [loading, setLoading] = useState(false);
  const [from, setFrom] = useState(0);
  const hasMore = useRef(true);
  const loader = useRef(null);
  const [counter, setCounter] = useState(0);
  const [fetchTrigger, setFetchTrigger] = useState(0);
  const [sortOrder, setSortOrder] = useState({ label: "desc", value: "desc" });
  const [selectedField, setSelectedField] = useState({
    label: "Upload date",
    value: "publishedAt",
  });
  const [sort, setSort] = useState({ publishedAt: "desc" });

  const handleObserver = useCallback(
    (entities) => {
      const target = entities[0];
      if (target.isIntersecting && hasMore.current && !loading) {
        setFetchTrigger((prev) => prev + 1);
      }
    },
    [loading],
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "20px",
      threshold: 0.1,
    });

    const currentLoader = loader.current;

    if (currentLoader) {
      observer.observe(currentLoader);
    }

    return () => {
      if (currentLoader) {
        observer.unobserve(currentLoader);
      }
    };
  }, [handleObserver]);

  useEffect(() => {
    const fetchVideos = async () => {
      if (loading || !hasMore.current) {
        return;
      }
      setLoading(true);
      try {
        const response = await getChannelVideos({ channelId, from, sort });
        setVideos((prev) => [...prev, ...response.data.data]);
        setCounter(response.data.count);
        setMappedVideos(response.data.mapped);
        if (response.data.data.length < 4) {
          hasMore.current = false;
        } else {
          setFrom((prevFrom) => prevFrom + response.data.data.length);
        }
      } catch (error) {
        console.error("Failed to fetch videos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideos();
  }, [channelId, fetchTrigger, sort]);

  return (
    ((videos.length > 0 || loading) && (<ExpandableSection
      defaultExpanded
      variant="container"
      headerText="Videos"
      headerCounter={`(${mappedVideos}/${counter})`}
      headerActions={
        <SpaceBetween direction="horizontal" size="xs">
          <Select
            selectedOption={selectedField}
            onChange={({ detail }) => {
              setSelectedField(detail.selectedOption);
              setSort({ [detail.selectedOption.value]: sortOrder.value });
              hasMore.current = true;
              setFrom(0);
              setVideos([]);
            }}
            expandToViewport
            controlId="select-field"
            placeholder="Field"
            options={[
              { label: "Upload date", value: "publishedAt" },
              { label: "Views", value: "views" },
              { label: "Likes", value: "likes" },
              { label: "Comments", value: "comments" },
              { label: "Score", value: "sentimentScore" },
            ]}
          />
          <Select
            selectedOption={sortOrder}
            expandToViewport
            onChange={({ detail }) => {
              setSortOrder(detail.selectedOption);
              setSort({ [selectedField.value]: detail.selectedOption.value });
              hasMore.current = true;
              setFrom(0);
              setVideos([]);
            }}
            controlId="select-order"
            placeholder="Sort order"
            options={[
              { label: "asc", value: "asc" },
              { label: "desc", value: "desc" },
            ]}
          />
        </SpaceBetween>
      }
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-4 gap-2">
        {videos.map((video) => (
          <TrailerCard video={video} key={video.videoId} />
        ))}
      </div>
      {loading && (
        <div className="flex justify-center">
          <Spinner size="large" />
        </div>
      )}
      <div ref={loader} />
    </ExpandableSection>))
  );
};

export default VideoList;
