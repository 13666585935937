import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTitleSearchData } from "../request";

export const useGetTitleSearchData = ({
  queryString="",
}) => {
  const params = {
    queryString,
  };
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TITLE_SEARCH_DATA, { ...params }],
    () => getTitleSearchData(params),
  );

  return {
    data: data?.data,
    isLoading,
    ...error,
  };
};
