import React from "react";
import { CollectionPreferences } from "@cloudscape-design/components";

export default function PreferencesComponent({
  initialPreferences,
  onPreferencesChange,
}) {
  const handleConfirm = (newPreferences) => {
    onPreferencesChange(newPreferences);
  };

  return (
    <CollectionPreferences
      title="Preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      onConfirm={(d) => handleConfirm(d.detail)}
      preferences={initialPreferences}
      pageSizePreference={{
        title: "Page size",
        options: [
          { value: 20, label: "20 titles" },
          { value: 50, label: "50 titles" },
          { value: 100, label: "100 titles" },
          { value: 200, label: "200 titles" },
        ],
      }}
      visibleContentPreference={{
        title: "Select visible content",
        options: [
          {
            label: "Main properties",
            options: [
              { id: "platforms", label: "Platforms" },
              { id: "type", label: "Type" },
              { id: "parentCompany", label: "Parent Company" },
              { id: "poster", label: "Poster" },
              { id: "description", label: "Summary" },
            ],
          },
        ],
      }}
    />
  );
}
