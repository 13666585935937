import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../utils/axios';
const initialState = {
    data: [],
    loading: false,
    message: "",
    completed: false,
    hasError: false,
};

export const fetchLeaderboardData = createAsyncThunk(
    'leaderboardData/fetchLeaderboardData',
    async ({ gte, lte, option, size }) => {
        const response = await axiosInstance.request({
            url: `/ranking/leaderboard`,

            method: `GET`,
            params: {
                gte, lte, option, size
            }
        });
        return response;
    }
);


const leaderboardDataSlice = createSlice({
    name: 'leaderboardData',
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchLeaderboardData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchLeaderboardData.fulfilled, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.data = action.payload.data;
            })
            .addCase(fetchLeaderboardData.rejected, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.hasError = true;
                state.message = action.error.message;
            });

    },
});

const LeaderboardDataReducer = leaderboardDataSlice.reducer;

export default LeaderboardDataReducer;

export const leaderboardDataAction = leaderboardDataSlice.actions;