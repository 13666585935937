import {
  ContentLayout,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { genreMapping } from "../../../../../components/GenreMapping";
import PantheonFlashbar from "../../../../../components/PantheonFlashbar";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { RangeDateSelector } from "../../../../../components/RangeDateSelector";
import { OptionLayer } from "../../../../../components/option-layer/OptionLayer";
import { IP_LIST } from "../../../../../config-global";
import axiosInstance from "../../../../../utils/axios";
import { getListoptionData } from "../../../../home/redux/actions/leaderboard-actions";

export const TrendingPantheonRanking = ({
  selectedIps,
  setSelectedIps,
  setSplitPanelStatus,
  comparedIp,
  setComparedIp,
}) => {
  const listOptionsData = useSelector((state) => state.listOptionsData);
  const [dateRange, setDateRange] = useState(null);
  const [isTabsLoading, setIsTabsLoading] = useState(false);
  const [visibleData, setVisibleData] = useState({});
  const [selectedOptionSelectIPList, setSelectedOptionSelectIPList] = useState({
    label: "Pantheon",
    value: "Pantheon",
  });
  const [selectedOptionGenre, setSelectedOptionGenre] = useState(null);
  const [selectedOptionVertical, setSelectedOptionVertical] = useState(null);
  const [genreOptions, setGenreOptions] = useState([]);
  const [isGenreDisabled, setIsGenreDisabled] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");

  const flashbarRef = useRef(null);

  useEffect(() => {
    if (selectedOptionVertical && selectedOptionVertical.value) {
      const genresForVertical = Object.entries(genreMapping)
        .filter(([_, verticals]) =>
          verticals.includes(selectedOptionVertical.value),
        )
        .map(([genre]) => ({ label: genre, value: genre }));
      setGenreOptions([{ label: "All", value: "" }, ...genresForVertical]);
      setIsGenreDisabled(false);
    } else {
      setGenreOptions([]);
      setIsGenreDisabled(true);
    }
  }, [selectedOptionVertical]);

  const [currentTab, setCurrentTab] = useState({
    activeTabId: "tab_0",
    activeTabHref: undefined,
  });

  useEffect(() => {
    setIsTabsLoading(true);
    getListoptionData().finally(() => {
      setIsTabsLoading(false);
    });
    return;
  }, []);

  const compareIp = useCallback(
    (ip) => {
      const gte = dateRange
        ? moment(dateRange.startDate).unix()
        : moment().add(-7, "days").unix();
      const lte = dateRange
        ? moment(dateRange.endDate).unix()
        : moment().unix();

      axiosInstance
        .request({
          url: `ranking/score/${ip}`,
          params: { gte, lte },
        })
        .then((data) => data.data)
        .then((data) => {
          if (data && data?.ip) {
            if (
              !_.some(comparedIp, (item) =>
                _.isEqual(item, { ...data, ...data?.data?.platformScores }),
              )
            ) {
              setSplitPanelStatus(true);
              setComparedIp([
                ...comparedIp,
                { ...data, ...data?.data?.platformScores },
              ]);
            }
          }
        });
    },
    [dateRange, comparedIp],
  );

  const checkAndInsertIps = (ips) => {
    const index = selectedIps.findIndex((ip) => ip.ip_id === ips.ip_id);
    if (index === -1) {
      setSelectedIps((prevIps) => [...prevIps, ips]);
      compareIp(ips.ip_id);
    } else {
      const newSelectedIps = [...selectedIps];
      newSelectedIps.splice(index, 1);
      setSelectedIps(newSelectedIps);
    }
  };

  const getOption = ({
    gte,
    lte,
    option,
    size,
    vertical,
    name,
    id,
    ip_list,
    genre,
  }) => {
    return axiosInstance
      .request({
        url: "ranking/leaderboard/v2",
        params: {
          gte,
          lte,
          option,
          size,
          vertical,
          ip_list,
          genre,
        },
      })
      .then((data) => {
        return {
          name: name,
          data: data.data,
          id,
          isLoading: false,
        };
      })
      .catch((e) => {
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          "An error occurred while fetching data.",
        );
      });
  };

  function extractIntFromString(str) {
    const match = str.match(/\d+/);
    if (match) {
      const extractedInt = parseInt(match[0], 10);
      return extractedInt;
    } else {
      return null;
    }
  }

  const fetchTabData = (index) => {
    getOption({
      name: listOptionsData?.data[index]?.name,
      gte: dateRange ? moment(dateRange.startDate).unix() : "now-7d/d",
      lte: dateRange ? moment(dateRange.endDate).unix() : "now/d",
      option: listOptionsData?.data[index]?.id,
      size: 10000,
      id: index,
      ip_list: selectedOptionSelectIPList?.value ?? "",
      vertical: selectedOptionVertical?.value ?? "",
      genre:
        selectedOptionVertical?.value && selectedOptionGenre?.value
          ? selectedOptionGenre?.value
          : "",
    })
      .then((data) => {
        const newVisibleData = {
          ...visibleData,
          [index]: data,
        };
        setVisibleData(newVisibleData);
      })
      .catch((error) => {
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          "An error occurred while fetching data. Please try a different date range.",
        );
      });
  };

  useEffect(() => {
    const index = extractIntFromString(currentTab.activeTabId);
    if (listOptionsData.data.length > 0) {
      const initialData = {
        name: listOptionsData?.data[index]?.name,
        data: [],
        id: index,
        isLoading: true,
      };
      setVisibleData({ ...visibleData, [index]: initialData });

      fetchTabData(index);
    }
  }, [
    dateRange,
    selectedOptionVertical,
    listOptionsData,
    selectedOptionSelectIPList,
    selectedOptionGenre,
  ]);

  useEffect(() => {
    const index = extractIntFromString(currentTab.activeTabId);
    if (listOptionsData.data.length > 0) {
      if (index in visibleData) return;
      fetchTabData(index);
    }
  }, [currentTab]);

  return (
    <ContentLayout
      disableOverlap
      header={
        <div>
          <PantheonFlashbar ref={flashbarRef} />
          <Header
            variant="h3"
            actions={
              <>
                <SpaceBetween direction="horizontal" size="xs">
                  <FormField description="Filter IPs">
                    <Input
                      type="search"
                      placeholder="Search IPs..."
                      onChange={({ detail }) => setSearchFilter(detail.value)}
                      value={searchFilter}
                    />
                  </FormField>
                  <FormField description="IP list">
                    <Select
                      selectedOption={selectedOptionSelectIPList}
                      onChange={({ detail }) => {
                        setSelectedOptionSelectIPList(detail.selectedOption);
                      }}
                      filteringType="auto"
                      expandToViewport
                      controlId="select-iplist"
                      options={IP_LIST}
                    />
                  </FormField>
                  <FormField description="Category">
                    <Select
                      selectedOption={selectedOptionVertical}
                      expandToViewport
                      onChange={({ detail }) => {
                        setSelectedOptionVertical(detail.selectedOption);
                      }}
                      controlId="select-vertical"
                      placeholder="Category"
                      options={[
                        { label: "All", value: "" },
                        { label: "Movies", value: "Movies" },
                        { label: "Television", value: "Television" },
                        { label: "Games", value: "Gaming" },
                      ]}
                    />
                  </FormField>
                  <FormField description="Genre">
                    <Select
                      selectedOption={selectedOptionGenre}
                      onChange={({ detail }) => {
                        setSelectedOptionGenre(detail.selectedOption);
                      }}
                      expandToViewport
                      controlId="select-genre"
                      placeholder="Genre"
                      disabled={isGenreDisabled}
                      options={genreOptions}
                    />
                  </FormField>
                  <FormField description="Date range">
                    <RangeDateSelector
                      defaults={
                        dateRange
                          ? dateRange?.type === "relative"
                            ? {
                                ...dateRange,
                                startDate: moment(dateRange.startDate),
                                endDate: moment(dateRange.endDate),
                              }
                            : dateRange
                          : {
                              type: "absolute",
                              startDate: moment()
                                .subtract(7, "days")
                                .startOf("day")
                                .format("YYYY-MM-DD"),
                              endDate: moment().format("YYYY-MM-DD"),
                            }
                      }
                      onChange={(e) => {
                        if (e.type === "relative") {
                          setDateRange({
                            ...e,
                            startDate: moment(e.startDate).format("YYYY-MM-DD"),
                            endDate: moment(e.endDate).format("YYYY-MM-DD"),
                          });
                        } else {
                          setDateRange(e);
                        }
                      }}
                    />
                  </FormField>
                </SpaceBetween>
              </>
            }
          >
            Pantheon Ranking
          </Header>
        </div>
      }
    >
      {isTabsLoading ? (
        <CustomLoadingOverlay />
      ) : (
        <Tabs
          onChange={({ detail }) => {
            setCurrentTab(detail);
            setSearchFilter("");
          }}
          tabs={listOptionsData?.data?.map((item, index) => {
            return {
              label: item.name,
              id: `tab_${index}`,
              content:
                index in visibleData ? (
                  visibleData[index]?.isLoading ? (
                    <CustomLoadingOverlay />
                  ) : (
                    <OptionLayer
                      key={visibleData[index]?.name}
                      optionData={visibleData[index]?.data}
                      name={visibleData[index]?.name}
                      onItemSelect={(item) => checkAndInsertIps(item)}
                      selectedIps={selectedIps}
                      searchFilter={searchFilter}
                    />
                  )
                ) : (
                  <div className="flex justify-center items-center h-full">
                    <CustomLoadingOverlay />
                  </div>
                ),
            };
          })}
        />
      )}
    </ContentLayout>
  );
};
