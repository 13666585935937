import { createSlice } from "@reduxjs/toolkit";

const initialRawDataState = {
    loading: false,
    data: undefined,
    completed: false,
    hasError: false,
    message: ""
}


const wikiRawDataSlice = createSlice({
    name: "wikiRawData",
    initialState: initialRawDataState,
    reducers: {
        loading: (state) => {
            return { ...state, loading: true, hasError: false }
        },
        insertData: (state, action) => {
            return { ...state, loading: false, completed: true, data: action.payload, hasError: false }
        },
        insertError: (state, action) => {
            return { ...state, loading: false, completed: true, hasError: true, message: action.payload }
        },
        clearData:()=>{
            return initialRawDataState;
        }
    }
});
const wikiRawDataReducer = wikiRawDataSlice.reducer;
export default wikiRawDataReducer;


export const wikiRawDataActions = wikiRawDataSlice.actions; 