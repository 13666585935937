import { SERVICES, TYPES } from "../../../config-global";
import { CATEGORIES, categoryOptions } from "../utils/categoryUtils";

const module = "global";
const TABS = {
  TRENDS: "trends",
};

export const globalApiParams = {
  type: TYPES.PERSONAL,
  service: SERVICES.PLATFORMS,
  module,
  tab: TABS.TRENDS,
};

const globalCategories = [
  CATEGORIES.MOVIES,
  CATEGORIES.SERIES,
  CATEGORIES.GAMING,
];

export const globalCategoryOptions = categoryOptions.filter((option) =>
  globalCategories.includes(option.value),
);

export const defaultCategory = CATEGORIES.MOVIES;
