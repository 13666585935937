import { createSlice } from "@reduxjs/toolkit";

const initialUserListState = {
    isUserListLoading:false,
    userList:[],
    isUserListLoadingCompleted:false,
    isUserListError: false,
    message:""
}


const userListSlice = createSlice({
    name:"userList",
    initialState:initialUserListState,
    reducers:{
        loading:(state)=>{
            state.isUserListLoading = true;
            state.userList = [];
        },
        insertUserList: (state, action) => {
           
            state.userList = action.payload;
            state.isUserListLoading = false;
            state.isUserListLoadingCompleted = true;
            state.isUserListError = false;
        },
        clearUserList:() => {
            return initialUserListState;
        },
        insertError:(state, action) => {
            state.isUserListLoading = false;
            state.message = action.payload;
            state.isUserListLoadingCompleted = true;
            state.isUserListError = false;
        },
    }
});
export const userListAction = userListSlice.actions;
export default userListSlice.reducer;


