import { useEffect } from "react";
import { getFilteredTokens } from "../../../utils/filters/filterUtils";
import { getQueryParams } from "../../../utils/queryUtils";
import { isSome, isSomeOrElse } from "../../../utils/sugarUtils";

export const useFiltering = ({
  currentPage,
  preferences,
  initialApiParams,
  filteringQuery,
  sortingColumn,
  sortingDescending,
  setApiParams,
  setCurrentPage,
  setFilteringQuery,
}) => {
  const type = getQueryParams("type");
  const size = isSomeOrElse(preferences?.pageSize, initialApiParams.size);

  useEffect(() => {
    // Reset to the first page when filters or sorting change
    setCurrentPage(1);
  }, [filteringQuery, sortingColumn, sortingDescending, setCurrentPage]);

  useEffect(() => {
    if (isSome(type)) {
      const query = {
        operation: "and",
        tokens:
          type === "Kids"
            ? [{ operator: "=", propertyKey: "madeForKids", value: "true" }]
            : [{ operator: "=", propertyKey: "type", value: type }],
      };

      setFilteringQuery(query);
    }
  }, [type, setFilteringQuery]);

  useEffect(() => {
    const { include, exclude } = getFilteredTokens(filteringQuery.tokens);
    const from = (currentPage - 1) * size;

    const sort = sortingColumn
      ? [
          {
            [`${sortingColumn.id}`]: sortingDescending ? "desc" : "asc",
          },
        ]
      : [];

    setApiParams({
      ...initialApiParams,
      from,
      size,
      include,
      exclude,
      sort,
      condition: filteringQuery.operation,
    });
  }, [
    currentPage,
    size,
    filteringQuery,
    sortingColumn,
    sortingDescending,
    initialApiParams,
    setApiParams,
  ]);
};
