import { SERVICES, TYPES } from "../../../config-global";
import { CATEGORIES, categoryOptions } from "../utils/categoryUtils";

const module = "piracy";
const TABS = {
  TRENDS: "trends",
  TILES: "tiles",
};

export const piracyTrendsApiParams = {
  type: TYPES.PERSONAL,
  service: SERVICES.PLATFORMS,
  module,
  tab: TABS.TRENDS,
};

export const piracyTilesApiParams = {
  type: TYPES.PERSONAL,
  service: SERVICES.PLATFORMS,
  module,
  tab: TABS.TILES,
};

const piracyCategories = [
  CATEGORIES.MOVIES,
  CATEGORIES.SERIES,
];

export const piracyCategoryOptions = categoryOptions.filter((option) =>
  piracyCategories.includes(option.value),
);

export const defaultCategory = CATEGORIES.MOVIES;