import {
  Alert,
  CollectionPreferences,
  Toggle,
} from "@cloudscape-design/components";
import React from "react";

export const CommonPreference = ({
  title = "Preference",
  preferences,
  onConfirm,
  pageSizes = [25, 50, 75, 100],
  // pageSizeLabel = "items",
  pageSizePreferenceLabel = "Page Size",
  wrapLinesLabel = "Wrap Line",
  wrapLinesDescription = "Select to see all the text and wrap the lines",
  stripedRows = "Stripe Rows",
  stripedRowsDescription = "Select to add alternating shaded rows",
  contentDensityLabel = "Compact mode",
  contentDensityDescription = "Select to display content in a denser, more compact mode",
  contentDisplayOptions = [],
  contentDisplayTitle = "Column preferences",
  contentDisplayDescription = "Customize the columns visibility and order",
  disabled = false,
  confirmLabel = "Confirm",
  cancelLabel = "Cancel",
  showStickyPreference = true,
  stickyFirstColumnsTitle = "Stick first column(s)",
  stickyFirstColumnsDescription = "Keep the first column(s) visible while horizontally scrolling the table content.",
  stickyFirstColumnsOptions = [
    { label: "None", value: 0 },
    { label: "First column", value: 1 },
    { label: "First two columns", value: 2 },
  ],
  stickyLastColumnsTitle = "Stick last column",
  stickyLastColumnsDescription = "Keep the first column(s) visible while horizontally scrolling the table content.",
  stickyLastColumnsOptions = [
    { label: "None", value: 0 },
    { label: "Last column", value: 1 },
  ],
}) => {
  return (
    <CollectionPreferences
      title={title}
      disabled={disabled}
      preferences={preferences}
      onConfirm={(event) => {
        const detail = event.detail.custom ? {} : event.detail;
        onConfirm({ detail });
      }}
      pageSizePreference={{
        options: pageSizes.map((pageSize) => ({
          value: pageSize,
          // label: `${pageSize} ${pageSizeLabel}`,
          label: `${pageSize}`,
        })),
        title: pageSizePreferenceLabel,
      }}
      wrapLinesPreference={{
        label: wrapLinesLabel,
        description: wrapLinesDescription,
      }}
      stripedRowsPreference={{
        label: stripedRows,
        description: stripedRowsDescription,
      }}
      contentDensityPreference={{
        label: contentDensityLabel,
        description: contentDensityDescription,
      }}
      contentDisplayPreference={{
        options: contentDisplayOptions,
        title: contentDisplayTitle,
        description: contentDisplayDescription,
      }}
      confirmLabel={confirmLabel}
      cancelLabel={cancelLabel}
      stickyColumnsPreference={
        showStickyPreference
          ? {
              firstColumns: {
                title: stickyFirstColumnsTitle,
                description: stickyFirstColumnsDescription,
                options: stickyFirstColumnsOptions,
              },
              lastColumns: {
                title: stickyLastColumnsTitle,
                description: stickyLastColumnsDescription,
                options: stickyLastColumnsOptions,
              },
            }
          : {}
      }
      customPreference={(customValue, setCustomValue) => (
        <Toggle
          onChange={({ detail }) => {
            setCustomValue(detail.checked);
          }}
          checked={customValue}
        >
          Reset Preferences
          {customValue && (
            <Alert statusIconAriaLabel="Warning" type="warning">
              This action will remove all previously set attributes in the
              preferences. Disable the toggle if you want to keep the settings
              you selected.
            </Alert>
          )}
        </Toggle>
      )}
    />
  );
};
