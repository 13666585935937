import classnames from "classnames";
import React, { FC, useEffect, useRef, useState } from "react";
import { BiSolidGrid } from "react-icons/bi";
import { MenuDrawer } from "./MenuDrawer";

export type FavoriteServices = {
  [path: string]: string;
};

interface ServicesMenuProps {
  favoriteServices: FavoriteServices;
  handleFavorites: (path: string, label: string) => void;
}

export const ServicesMenu: FC<ServicesMenuProps> = ({
  favoriteServices,
  handleFavorites,
}) => {
  let timerId: ReturnType<typeof setTimeout>;
  const drawerRef = useRef<HTMLDivElement>(null);
  const [displayDrawer, setDisplayDrawer] = useState<boolean>(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef?.current?.contains(event.target)) return;

      clearTimeout(timerId);
      setDisplayDrawer(false);
    };

    if (displayDrawer) document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [displayDrawer, timerId]);

  return (
    <div className="z-999 flex items-center">
      <div
        className={classnames(
          "w-fit h-8 flex items-center justify-start hover:text-blue-400 border-l border-slate-500 cursor-pointer gap-2 pl-4",
          {
            "text-blue-400": displayDrawer,
            "text-white": !displayDrawer,
          },
        )}
        // setTimeout is needed to handle the logic after the clickoutside handler is triggered
        onClick={(e) => (timerId = setTimeout(() => setDisplayDrawer(true), 0))}
      >
        <BiSolidGrid size={30} />
        <span className="hidden md:block">Services</span>
      </div>
      <MenuDrawer
        ref={drawerRef}
        visible={displayDrawer}
        favoriteServices={favoriteServices}
        handleFavorites={handleFavorites}
        onClose={() => setDisplayDrawer(false)}
      />
    </div>
  );
};
