import {
  FormField,
  Header,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import moment from "moment";
import React from "react";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";
import { useGetPiracyGenericSummary } from "../api/hooks/useGetPiracyGenericSummary";
import { PiracyOptionLayer } from "./PiracyOptionLayer";

const COUNTRIES_OPTIONS = [
  { label: "United States", value: "United States" },
  { label: "United Kingdom", value: "United Kingdom" },
  { label: "Canada", value: "Canada" },
  { label: "Australia", value: "Australia" },
  { label: "France", value: "France" },
  { label: "Germany", value: "Germany" },
  { label: "Italy", value: "Italy" },
  { label: "Japan", value: "Japan" },
  { label: "Spain", value: "Spain" },
  { label: "Ireland", value: "Ireland" },
  { label: "Mexico", value: "Mexico" },
  { label: "Thailand", value: "Thailand" },
  { label: "Brazil", value: "Brazil" },
  { label: "South Korea", value: "South Korea" },
  { label: "Other", value: "Other" },
  { label: "All", value: "all" },
];

const PiracyGenericSummaryPage = ({
  dateRange,
  setDateRange,
  selectedCountry,
  setSelectedCountry,
  activeTabId,
}) => {
  const { data: piracyGenericMoviesSummary, isLoading: moviesSummaryLoading } =
    useGetPiracyGenericSummary({
      gte: dateRange.startDate,
      lte: dateRange.endDate,
      size: 500,
      vertical: { label: "Movies", value: "Movies" },
      country: selectedCountry.value,
      activeTabId,
    });

  const {
    data: piracyGenericTelevisionSummary,
    isLoading: televisioSummaryLoading,
  } = useGetPiracyGenericSummary({
    gte: dateRange.startDate,
    lte: dateRange.endDate,
    size: 500,
    vertical: { label: "Television", value: "Television" },
    country: selectedCountry.value,
    activeTabId,
  });

  return (
    <SpaceBetween direction="vertical" size="xs">
      <Header
        description="Last 15 days loaded by default"
        variant="h3"
        actions={
          <>
            <SpaceBetween direction="horizontal" size="s">
              <FormField description="Country">
                <Select
                  expandToViewport
                  selectedOption={selectedCountry}
                  onChange={({ detail }) => {
                    setSelectedCountry(detail.selectedOption);
                  }}
                  options={COUNTRIES_OPTIONS}
                />
              </FormField>
              <FormField description="Date range">
                <RangeDateSelector
                  defaults={
                    dateRange
                      ? dateRange?.type === "relative"
                        ? {
                            ...dateRange,
                            startDate: moment()
                              .subtract(15, "days")
                              .startOf("day")
                              .format("YYYY/MM/DD"),
                            endDate: moment(dateRange.endDate)
                              .endOf("day")
                              .format("YYYY/MM/DD"),
                          }
                        : dateRange
                      : {
                          type: "absolute",
                          startDate: moment()
                            .subtract(15, "days")
                            .startOf("day")
                            .format("YYYY/MM/DD"),
                          endDate: moment().endOf("day").format("YYYY/MM/DD"),
                        }
                  }
                  onChange={(e) => {
                    if (e.type === "relative") {
                      const newDateRange = {
                        startDate: moment(e.startDate).startOf("day").unix(),
                        endDate: moment(e.endDate).endOf("day").unix(),
                      };
                      setDateRange({
                        ...e,
                        ...newDateRange,
                      });
                    } else {
                      const newDateRange = {
                        startDate: moment(e.startDate).startOf("day").unix(),
                        endDate: moment(e.endDate).endOf("day").unix(),
                      };
                      setDateRange({
                        ...e,
                        ...newDateRange,
                      });
                    }
                  }}
                />
              </FormField>
            </SpaceBetween>
          </>
        }
      >
        Top Pirated Downloads by Category
      </Header>
      <SpaceBetween direction="vertical" size="xs">
        <PiracyOptionLayer
          loading={moviesSummaryLoading}
          vertical={{
            label: "Movies",
            value: "Movies",
          }}
          optionData={piracyGenericMoviesSummary}
        />
        <PiracyOptionLayer
          loading={televisioSummaryLoading}
          vertical={{
            label: "Television",
            value: "Television",
          }}
          optionData={piracyGenericTelevisionSummary}
        />
      </SpaceBetween>
    </SpaceBetween>
  );
};

export default React.memo(PiracyGenericSummaryPage);
