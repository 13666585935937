import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  SideNavigation,
  SpaceBetween,
  Tabs
} from "@cloudscape-design/components";
import Slider from "@mui/material/Slider";
import _ from "lodash";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { navItems } from "../../layouts/common/menu/side-menu";
import createFlashMessage from "../../utils/createFlashMessage";
import CustomFlashBar from "../common/CustomFlashBar";
import { addMessageToFlash, cleanAllFlashMessage } from "../common/redux/flash-action";
import "./gauge.css";
import {
  getNbcuSettingData,
  saveNbcuSettingData
} from "./redux/actions/settings-nbcu-actions";
const MAX_SLIDER_VALUE = 100;
export const OptionsEditPage = () => {
  const { id } = useParams();
  useEffect(() => {
    getNbcuSettingData(id);
  }, [id]);
  const nbcuSetting = useSelector((state) => state.nbcuSetting);
  const [data, setData] = useState(null);
  const [searchDemandEditing, setSearchDemandEditing] = useState(false);
  const [contentConsumptionEditing, setContentConsumptionEditing] =
    useState(false);
  const [piracyEditing, setPiracyEditing] = useState(false);
  const [fanSitesEditing, setFanSitesEditing] = useState(false);

  useEffect(() => {
    if (nbcuSetting && nbcuSetting.data) {
      setData(nbcuSetting.data);
    }
  }, [nbcuSetting]);
  const options = {
    width: 250,
    height: 250,
    minorTicks: 5,
    animation: {
      duration: 1000,
      easing: "out",
    },
  };
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);
  useEffect(() => {
    if (nbcuSetting.completed && nbcuSetting.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: nbcuSetting.message,
        })
      );
    }
  }, [nbcuSetting]);

  const handleChange = (index, newValue, row) => {
    const { child } = row;
    const newChild = [...child];
    let total = 0;

    newChild[index] = { ...newChild[index], value: Number(newValue) };

    newChild.forEach((item) => {
      total += Number(item.value);
    });

    if (total > 100) {
      let diff = total - 100;
      let i = child.length;

      while (diff > 0) {
        if (newChild[i] && newChild[i].value > diff) {
          newChild[i] = {
            ...newChild[i],
            value: Number(newChild[i].value) - diff,
          };
          diff = 0;
        } else {
          if (newChild[i]) {
            diff -= Number(newChild[i].value);
            newChild[i] = { ...newChild[i], value: 0 };
          }
        }

        i--;

        if (i < 0) {
          break;
        }
      }
    }
    return newChild;
  };

  function checkData() {
    if (
      _.sumBy(data.searchDemand.child, (e) => parseInt(e.value)) ===
        MAX_SLIDER_VALUE &&
      _.sumBy(data.contentConsumption.child, (e) => parseInt(e.value)) ===
        MAX_SLIDER_VALUE &&
      // _.sumBy(data.cpMarketplace.child, (e) => parseInt(e.value)) ===
      //   MAX_SLIDER_VALUE &&
      _.sumBy(data.piracy.child, (e) => parseInt(e.value)) ===
        MAX_SLIDER_VALUE &&
      _.sumBy(data.fanSites.child, (e) => parseInt(e.value)) ===
        MAX_SLIDER_VALUE
    ) {
      return true;
    } else {
      return false;
    }
  }

  const saveData = (data, id) => {
    if (id) {
      saveNbcuSettingData(data, id);
    }
  };

  const GeneralConfig = () => (
    <Container header={<Header variant="h2">Summary</Header>}>
      <ColumnLayout columns={4} minColumnWidth={200} variant="text-grid">
        <div>
          <Box variant="awsui-key-label" textAlign="center">Search Demand</Box>
          <Box textAlign="center">{data && data.searchDemand.value}%</Box>
        </div>
        <div>
          <Box variant="awsui-key-label" textAlign="center">Content Consumption</Box>
          <Box textAlign="center">{data && data.contentConsumption.value}%</Box>
        </div>
        <div>
          <Box variant="awsui-key-label" textAlign="center">Piracy</Box>
          <Box textAlign="center">{data && data.piracy.value}%</Box>
        </div>
        <div>
          <Box variant="awsui-key-label" textAlign="center">Fan Sites</Box>
          <Box textAlign="center">{data && data.fanSites.value}%</Box>
        </div>
      </ColumnLayout>
    </Container>
  );

  const tabs = [
    {
      label: "Search Demand",
      id: "searchDemand",
      content: (
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {searchDemandEditing ? (
                    <>
                      <Button
                        variant="primary"
                        disabled={
                          _.sumBy(data.searchDemand.child, (e) =>
                            parseInt(e.value)
                          ) !== MAX_SLIDER_VALUE
                        }
                        onClick={() => {
                          if (checkData()) saveData(data, id);
                          setSearchDemandEditing(false);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setSearchDemandEditing(false);
                          getNbcuSettingData(id);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button onClick={() => setSearchDemandEditing(true)}>
                      Edit
                    </Button>
                  )}
                </SpaceBetween>
              }
            >
              Details
            </Header>
          }
        >
          <ColumnLayout columns={3}>
            {data &&
              data.searchDemand.child.map((d, i) => (
                <Box key={i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "400px",
                    }}
                  >
                    <div>
                      <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                        {d.label}
                      </div>
                      <Slider
                        disabled={!searchDemandEditing}
                        value={d.value}
                        onChange={(event, newValue) => {
                          const newChild = handleChange(
                            i,
                            newValue,
                            data.searchDemand
                          );
                          setData((prevData) => {
                            return {
                              ...prevData,
                              searchDemand: {
                                ...prevData.searchDemand,
                                child: newChild,
                              },
                            }; // return a new object with updated child array
                          });
                        }}
                        aria-labelledby={`slider-${i}`}
                        step={5}
                        marks
                        min={0}
                        max={100}
                        size="large"
                        valueLabelDisplay="auto"
                      />

                      <Chart
                        options={options}
                        chartType="Gauge"
                        graphID={`gauge-page-searchDemand-${i}`}
                        data={[
                          ["Label", "value"],
                          [d.label, Number(d.value)],
                        ]}
                      />
                    </div>
                  </div>
                </Box>
              ))}
          </ColumnLayout>
        </Container>
      ),
    },
    {
      label: "Content Consumption",
      id: "contentConsumption",
      content: (
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {contentConsumptionEditing ? (
                    <>
                      <Button
                        variant="primary"
                        disabled={
                          _.sumBy(data.contentConsumption.child, (e) =>
                            parseInt(e.value)
                          ) !== MAX_SLIDER_VALUE
                        }
                        onClick={() => {
                          if (checkData()) saveData(data, id);
                          setContentConsumptionEditing(false);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setSearchDemandEditing(false);
                          getNbcuSettingData(id);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button onClick={() => setContentConsumptionEditing(true)}>
                      Edit
                    </Button>
                  )}
                </SpaceBetween>
              }
            >
              Details
            </Header>
          }
        >
          <ColumnLayout columns={4}>
            {data &&
              data.contentConsumption.child.map((d, i) => (
                <Box key={i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "400px",
                    }}
                  >
                    <div>
                      <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                        {d.label}
                      </div>
                      <Slider
                        disabled={!contentConsumptionEditing}
                        value={d.value}
                        onChange={(event, newValue) => {
                          const newChild = handleChange(
                            i,
                            newValue,
                            data.contentConsumption
                          );
                          setData((prevData) => {
                            return {
                              ...prevData,
                              contentConsumption: {
                                ...prevData.contentConsumption,
                                child: newChild,
                              },
                            }; // return a new object with updated child array
                          });
                        }}
                        aria-labelledby={`slider-${i}`}
                        step={5}
                        marks
                        min={0}
                        max={100}
                        size="large"
                        valueLabelDisplay="auto"
                      />
                      <div>
                        <Chart
                          graphID={`gauge-page-contentConsumption-${i}`}
                          options={options}
                          chartType="Gauge"
                          data={[
                            ["Label", "value"],
                            [d.label, Number(d.value)],
                          ]}
                        />
                      </div>
                    </div>
                  </div>
                </Box>
              ))}
          </ColumnLayout>
        </Container>
      ),
    },
    // {
    //   label: "Consumer Products",
    //   id: "cpMarketplace",
    //   content: (
    //     <Container
    //       header={
    //         <Header
    //           actions={
    //             <SpaceBetween direction="horizontal" size="xs">
    //               {cpMarketplaceEditing ? (
    //                 <>
    //                   <Button
    //                     variant="primary"
    //                     disabled={
    //                       _.sumBy(data.cpMarketplace.child, (e) =>
    //                         parseInt(e.value)
    //                       ) !== MAX_SLIDER_VALUE
    //                     }
    //                     onClick={() => {
    //                       if (checkData()) saveData(data, id);
    //                       setCpMarketplaceEditing(false);
    //                     }}
    //                   >
    //                     Save
    //                   </Button>
    //                   <Button
    //                     onClick={() => {
    //                       setSearchDemandEditing(false);
    //                       getNbcuSettingData(id);
    //                     }}
    //                   >
    //                     Cancel
    //                   </Button>
    //                 </>
    //               ) : (
    //                 <Button onClick={() => setCpMarketplaceEditing(true)}>
    //                   Edit
    //                 </Button>
    //               )}
    //             </SpaceBetween>
    //           }
    //         >
    //           Details
    //         </Header>
    //       }
    //     >
    //       <ColumnLayout columns={4}>
    //         {data &&
    //           data.cpMarketplace.child.map((d, i) => (
    //             <Box key={i}>
    //               <div
    //                 style={{
    //                   display: "flex",
    //                   justifyContent: "center",
    //                   height: "400px",
    //                 }}
    //               >
    //                 <div>
    //                   <div className="text-center pb-10 font-bold text-lg  text-sky-600">
    //                     {d.label}
    //                   </div>
    //                   <Slider
    //                     value={d.value}
    //                     disabled={!cpMarketplaceEditing}
    //                     onChange={(event, newValue) => {
    //                       const newChild = handleChange(
    //                         i,
    //                         newValue,
    //                         data.cpMarketplace
    //                       );
    //                       setData((prevData) => {
    //                         return {
    //                           ...prevData,
    //                           cpMarketplace: {
    //                             ...prevData.cpMarketplace,
    //                             child: newChild,
    //                           },
    //                         }; // return a new object with updated child array
    //                       });
    //                     }}
    //                     aria-labelledby={`slider-${ i }`}
    //                     step={5}
    //                     marks
    //                     min={0}
    //                     max={100}
    //                     size="large"
    //                     valueLabelDisplay="auto"
    //                   />

    //                   <Chart
    //                     graphID={`gauge-page-cpMarketplace-${ i }`}
    //                     options={options}
    //                     chartType="Gauge"
    //                     data={[
    //                       [ "Label", "value" ],
    //                       [ d.label, Number(d.value) ],
    //                     ]}
    //                   />
    //                 </div>
    //               </div>
    //             </Box>
    //           ))}
    //       </ColumnLayout>
    //     </Container>
    //   ),
    // },
    {
      label: "Piracy",
      id: "piracy",
      content: (
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {piracyEditing ? (
                    <>
                      <Button
                        variant="primary"
                        disabled={
                          _.sumBy(data.piracy.child, (e) =>
                            parseInt(e.value)
                          ) !== MAX_SLIDER_VALUE
                        }
                        onClick={() => {
                          if (checkData()) saveData(data, id);
                          setPiracyEditing(false);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setSearchDemandEditing(false);
                          getNbcuSettingData(id);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button onClick={() => setPiracyEditing(true)}>Edit</Button>
                  )}
                </SpaceBetween>
              }
            >
              Details
            </Header>
          }
        >
          <ColumnLayout columns={4}>
            {data &&
              data.piracy.child.map((d, i) => (
                <Box key={i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "400px",
                    }}
                  >
                    <div>
                      <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                        {d.label}
                      </div>
                      <Slider
                        disabled={!piracyEditing}
                        value={d.value}
                        onChange={(event, newValue) => {
                          const newChild = handleChange(
                            i,
                            newValue,
                            data.piracy
                          );
                          setData((prevData) => {
                            return {
                              ...prevData,
                              piracy: {
                                ...prevData.piracy,
                                child: newChild,
                              },
                            }; // return a new object with updated child array
                          });
                        }}
                        aria-labelledby={`slider-${i}`}
                        step={5}
                        marks
                        min={0}
                        max={100}
                        size="large"
                        valueLabelDisplay="auto"
                      />

                      <Chart
                        graphID={`gauge-page-piracy-${i}`}
                        options={options}
                        chartType="Gauge"
                        data={[
                          ["Label", "value"],
                          [d.label, Number(d.value)],
                        ]}
                      />
                    </div>
                  </div>
                </Box>
              ))}
          </ColumnLayout>
        </Container>
      ),
    },
    {
      label: "Fan Sites",
      id: "fanSites",
      content: (
        <Container
          header={
            <Header
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {fanSitesEditing ? (
                    <>
                      <Button
                        variant="primary"
                        disabled={
                          _.sumBy(data.fanSites.child, (e) =>
                            parseInt(e.value)
                          ) !== MAX_SLIDER_VALUE
                        }
                        onClick={() => {
                          if (checkData()) saveData(data, id);
                          setFanSitesEditing(false);
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        onClick={() => {
                          setSearchDemandEditing(false);
                          getNbcuSettingData(id);
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <Button onClick={() => setFanSitesEditing(true)}>
                      Edit
                    </Button>
                  )}
                </SpaceBetween>
              }
            >
              Details
            </Header>
          }
        >
          <ColumnLayout columns={4}>
            {data &&
              data.fanSites.child.map((d, i) => (
                <Box key={i}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "400px",
                    }}
                  >
                    <div>
                      <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                        {d.label}
                      </div>
                      <Slider
                        disabled={!fanSitesEditing}
                        value={d.value}
                        onChange={(event, newValue) => {
                          const newChild = handleChange(
                            i,
                            newValue,
                            data.fanSites
                          );
                          setData((prevData) => {
                            return {
                              ...prevData,
                              fanSites: {
                                ...prevData.fanSites,
                                child: newChild,
                              },
                            }; // return a new object with updated child array
                          });
                        }}
                        aria-labelledby={`slider-${i}`}
                        step={5}
                        marks
                        min={0}
                        max={100}
                        size="large"
                        valueLabelDisplay="auto"
                      />

                      <Chart
                        graphID={`gauge-page-fanSites-${i}`}
                        options={options}
                        chartType="Gauge"
                        data={[
                          ["Label", "value"],
                          [d.label, Number(d.value)],
                        ]}
                      />
                    </div>
                  </div>
                </Box>
              ))}
          </ColumnLayout>
        </Container>
      ),
    },
  ];

  const breadcrumbs = [
    { text: "Settings" },
    { text: "NBCU Weightage" },
    { text: "Ranking", href: "/settings/nbcu-weightage/ranking" },
    { text: data ? data.name : "", href: document.location.pathname },
  ];

  return (
    <AppLayout
      stickyNotifications
      toolsHide
      contentType="dashboard"
      headerSelector="#header"
      content={
        <ContentLayout
          header={
            <>
              <Header>{data ? data.name : ""}</Header>

              <div className="py-5">
                <CustomFlashBar />
              </div>
            </>
          }
        >
          <SpaceBetween size="l">
            <GeneralConfig />
            <Tabs tabs={tabs} ariaLabel="Resource details" />
          </SpaceBetween>
        </ContentLayout>
      }
      navigation={
        <SideNavigation
          activeHref={"/settings/nbcu-weightage/ranking"}
          items={navItems}
        />
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={breadcrumbs}
          expandAriaLabel="Show path"
          ariaLabel="Breadcrumbs"
        />
      }
      ariaLabels={{ navigationClose: "close" }}
    />
  );
};
