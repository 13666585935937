import { useMemo } from "react";
import { getFilterConditionsFromFilters } from "../../../utils/filters/filterUtils";


export const useFilterQuery = (filterObject) => {
  const filterQuery = useMemo(() => {
    const mustConditions = [];

    // Quick title search
    if (
      filterObject.titleSearchQuery &&
      filterObject.titleSearchQuery.length > 0
    ) {
      mustConditions.push({
        filterType: "text",
        colId: "ip",
        type: "contains",
        filter: filterObject.titleSearchQuery,
      });
    }

    // Table advanced filters
    if (filterObject.advancedFilter) {
      mustConditions.push(filterObject.advancedFilter);
    }

    // Filters drawer filters
    const filterConditions = getFilterConditionsFromFilters(
      filterObject.filters,
    );
    if (filterConditions.length > 0) {
      filterConditions.forEach((condition) => mustConditions.push(condition));
    }

    if (mustConditions.length === 0) {
      return {};
    } else if (mustConditions.length === 1) {
      return mustConditions[0];
    } else {
      return {
        filterType: "join",
        type: "AND",
        conditions: mustConditions,
      };
    }
  }, [filterObject]);

  return filterQuery;
};