import axiosInstance from "../../../../utils/axios";

export const getGoogleSearchData = ({
  ipIds=[],
  categories=[],
  geoType="country",
  geoNames=[],
  startDate,
  endDate,
  totalsOnly=false,
}) => {
  if (!ipIds.length || !categories.length || !geoNames.length) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/google/search/getdata",
    method: "GET",
    params: {
      ip_ids: ipIds.join(","),
      categories: categories.join(","),
      geo_type: geoType,
      geo_names: geoNames.map(x => x.replaceAll(",", "\\,")).join(","),
      start_date: startDate,
      end_date: endDate,
      totals_only: totalsOnly,
    },
  });
};

export const getGoogleRankingData = ({
  geoType="country",
  geoName="us",
  startDate,
  endDate,
  totalsOnly=false,
  ...params
}) => {
  return axiosInstance.request({
    url: "/google/search/getrankings",
    method: "GET",
    params: {
      geo_type: geoType,
      geo_name: geoName,
      start_date: startDate,
      end_date: endDate,
      totals_only: totalsOnly,
      ...params,
    },
  });
};
  