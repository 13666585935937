import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React from "react";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";

const ResultsTable = React.forwardRef(({ seriesData, ...props }, ref) => {
  const { theme } = useGetGridTheme();

  return seriesData?.length > 0 ? (
    <div style={{ height: seriesData.length > 17 ? "580px" : "auto", width: "100%" }} className={theme}>
      <style>{`
      .ag-theme-quartz-dark {
        --ag-grid-size: 5px;
        --ag-list-item-height: 20px;
      }
      `}</style>
      <AgGridReact
        ref={ref}
        rowData={seriesData}
        columnDefs={[
          {
            field: "query",
            headerName: "Query",
            pinned: "left",
            width: 300,
            suppressHeaderMenuButton: true,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            cellRenderer: (params) => (
              <div className="flex items-center">
                <div className="min-w-3 min-h-3 rounded-sm mr-1" style={{ backgroundColor: params.data.color }}></div>
                {params.value}
              </div>
            ),
          },
          {
            field: "total",
            headerName: "Total",
            pinned: "right",
            width: 100,
            suppressHeaderMenuButton: true,
            cellRenderer: (params) => (
              <div>{params.value?.toLocaleString()}</div>
            ),
          },
          ...Object.keys(seriesData[0]).filter(key => !["query", "query_id", "color", "total", "error"].includes(key)).map(key => (
            {
              field: key,
              headerName: moment.utc(key * 1000).format("l"),
              type: "numericColumn",
              width: 100,
              suppressHeaderMenuButton: true,
              cellRenderer: (params) => (
                <div>{params.value?.toLocaleString()}</div>
              ),
            }
          )),
        ]}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        domLayout={seriesData.length <= 17 ? "autoHeight" : "normal"}
        {...props}
      />
    </div>
  ) : null;
});

export default ResultsTable;