import { FormField } from "@cloudscape-design/components";
import _ from "lodash";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import {
  CheckboxConfigType,
  CustomMultiSelect,
} from "../../../components/CustomMultiSelect";
import { usePostGenericPreference } from "../../../services/generic/hooks/usePostGenericPreference";
import { isSomeOrElse } from "../../../utils/sugarUtils";

type PlatformsDropdownProps = {
  checkboxConfig: CheckboxConfigType;
  setCheckboxConfig: (config: CheckboxConfigType) => void;
};

export const PlatformsDropdown: FC<PlatformsDropdownProps> = ({
  checkboxConfig,
  setCheckboxConfig,
}) => {
  const { mutate: postGenericPreference } = usePostGenericPreference();
  const globalPref = useSelector(
    (state: any) => state?.globalPersonalPref?.preferences,
  );

  const handleBlur = () => {
    const selectedPreference = isSomeOrElse(
      globalPref?.ipManagement?.dataMapping?.preferences?.platforms?.selected,
      [],
    );
    const selectedOptions = checkboxConfig.reduce((acc, curr) => {
      if (curr.visible) acc.push(curr.value);
      return acc;
    }, []);
    if (_.isEqual(selectedPreference, selectedOptions)) return;
    const newGlobalPreferences = {
      ...globalPref,
      ipManagement: {
        ...globalPref?.ipManagement,
        dataMapping: {
          ...globalPref?.ipManagement?.dataMapping,
          preferences: {
            ...globalPref?.ipManagement?.dataMapping?.preferences,
            platforms: {
              ...globalPref?.ipManagement?.dataMapping?.preferences?.platforms,
              selected: selectedOptions,
            },
          },
        },
      },
    };
    postGenericPreference(newGlobalPreferences);
  };

  return (
    <FormField description="Platforms">
      <CustomMultiSelect
        checkboxConfig={checkboxConfig}
        onBlur={handleBlur}
        onChange={setCheckboxConfig}
        placeholder="Select Platforms"
      />
    </FormField>
  );
};
