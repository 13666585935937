import { useMemo } from "react";

export const useGetBreadcrumbs = (itemData) => {
  const breadcrumbs = useMemo(
    () => [
      { text: "Explore", href: "/explore" },
      { text: "Franchises", href: "/explore/franchise" },
      { text: itemData?.data?.name, href: "/" },
    ],
    [itemData],
  );

  return { breadcrumbs };
};
