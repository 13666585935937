import { TrendsContent, TrendsFooter, TrendsHeader } from "../component/Trends";

const title = "Youtube Series Trends";

export const youtubeSeries = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Daily Youtube Trailer views for series",
    header: () => <TrendsHeader title={title} />,
    content: () => <TrendsContent vertical="series" platform="youtube" sort="daily_rank" />,
    footer: () => <TrendsFooter linkHref="/platforms/youtube?tab=trends&category=series" />,
  },
};