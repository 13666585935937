import { Link } from "@cloudscape-design/components";
import React from "react";
import shortenInteger from "../../../../../utils/shortenInteger";
import { dateFormatter } from "../../../../../utils/dateFormatter";

export const columnDefs = [
  {
    field: "title",
    headerName: "Title",
    cellRenderer: (params) => {
      return (
        <Link href={`/youtube/videos/${params.data.videoId}`}>
          {params.value}
        </Link>
      );
    },
    sortable: false,
    flex: 2,
  },
  {
    field: "todayViews",
    headerName: "Views",
    minWidth: 65,
    maxWidth: 65,
    cellRenderer: (params) => {
      return shortenInteger(params.value);
    },
  },
  // {
  //   field: "todayLikes",
  //   headerName: "Likes",
  //   minWidth: 80,
  //   maxWidth: 80,
  //   cellRenderer: (params) => {
  //     return shortenInteger(params.value);
  //   },
  // },
  // {
  //   field: "todayComments",
  //   headerName: "Comments",
  //   minWidth: 120,
  //   maxWidth: 120,
  //   cellRenderer: (params) => {
  //     return shortenInteger(params.value);
  //   },
  // },
  {
    field: "trends",
    headerName: "Trends",
    flex: 1,
    cellRenderer: "agSparklineCellRenderer",
    cellRendererParams: {
      sparklineOptions: {
        axis: {
          strokeWidth: 0,
        },
        tooltip: {
          renderer(params) {
            const { xValue, yValue } = params;
            return {
              title: `${dateFormatter(xValue)}`,
              content: `${shortenInteger(yValue)}`,
            };
          },
        },
        type: "column",
        xKey: "timestamp",
        yKey: "value",
        marker: {
          size: 3,
          shape: "circle",
          fill: "white",
        },
        fill: "#0972d3",
        highlightStyle: {
          fill: "#033160",
        },
      },
    },
  },
];
