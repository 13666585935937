import { Box, Container, SpaceBetween } from "@cloudscape-design/components";
import React from "react";
import ForgotPasswordIcon from "../../assets/images/ForgotPasswordIcon";
import Logo from "../../assets/images/Logo";
import "./index.css";

export const ForgotPasswordLayout = ({ children }) => {
  return (
    <>
      <Logo height={12} width={12} />
      <div className="flex min-h-screen w-full justify-center items-center">
        <div
          className="w-120 flex flex-col justify-center items-center"
          id="forgotPasswordLayoutPage"
        >
          <Container
            media={{
              content: (
                <div className="pt-4 flex w-full justify-center items-center">
                  <ForgotPasswordIcon />
                </div>
              ),
              position: "top",
            }}
          >
            <SpaceBetween direction="vertical" size="s">
              <Box variant="h1" textAlign="center">
                <span className="text-white">Forgot your password?</span>
              </Box>
              <span className="text-white">
                Please enter the email address associated with your account and
                we will email you the code to reset your password.
              </span>
              {children}
            </SpaceBetween>
          </Container>
        </div>
      </div>
    </>
  );
};
