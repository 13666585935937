import { Modal } from "@cloudscape-design/components";
export default function FormModel({
  header = "",
  open,
  data,
  onClose,
  children,
  footer,
}) {
  return (
    <Modal header={header} visible={open} onDismiss={onClose} footer={footer}>
      {children}
    </Modal>
  );
}
