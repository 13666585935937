import React, { useState } from "react";
import { SpaceBetween } from "@cloudscape-design/components";
import { useIMDbRankingTableConfig } from "../hooks/useIMDbRankingTableConfig";
import { getIMDbTrendsData } from "../../platforms/imdb/api/request";
import { getTwitchTrendsData } from "../../platforms/twitch/api/request";
import { genreCategoryOptions, defaultCategory, GENRE_TYPES } from "../constants";
import { CATEGORIES } from "../../platforms/utils/categoryUtils";
import { PlatformRankingParameters } from "./PlatformRankingParameters";
import { PlatformRankingTable } from "./PlatformRankingTable";
import { useTwitchRankingTableConfig } from "../hooks/useTwitchRankingTableConfig";

export const PlatformRanking = () => {
  const {
    columnDefs: imdbColumnDefs,
  } = useIMDbRankingTableConfig({});
  const {
    columnDefs: twitchColumnDefs,
  } = useTwitchRankingTableConfig({});

  const [ selectedCategory, setSelectedCategory ] = useState(genreCategoryOptions.find(({ value }) => value === defaultCategory));
  const [ selectedGenres, setSelectedGenres ] = useState([]);
  const [ filter, setFilter ] = useState({});
  const [ fetchMethod, setFetchMethod ] = useState({ method: getIMDbTrendsData });
  const [ extraColumnDefs, setExtraColumnDefs ] = useState(imdbColumnDefs);

  return (
    <SpaceBetween size="m" direction="vertical">
      <PlatformRankingParameters 
        selectedCategory={selectedCategory}
        setSelectedCategory={(category) => {
          switch(category.value) {
            case CATEGORIES.MOVIES:
            case CATEGORIES.SERIES:
              setFetchMethod({ method: getIMDbTrendsData });
              setExtraColumnDefs(imdbColumnDefs);
              break;
            case CATEGORIES.GAMING:
              setFetchMethod({ method: getTwitchTrendsData });
              setExtraColumnDefs(twitchColumnDefs);
              break;
          }
          setSelectedCategory(category);
        }}
        selectedGenres={selectedGenres}
        setSelectedGenres={(genres) => {
          if (genres?.length > 0) {
            const colMap = {
              [GENRE_TYPES.GENRE]: "genre",
              [GENRE_TYPES.CUSTOM_TAG]: "cTags",
              [GENRE_TYPES.GAME_THEME]: "themes",
            };
            const conditions = [];
            Object.values(GENRE_TYPES).forEach(type => {
              const genresOfType = genres.filter(g => g.genre.type === type);
              if (genresOfType.length == 0) return;
              conditions.push({
                colId: colMap[type],
                filterType: "object",
                filter: genresOfType.map(g => g.value).join(","),
                type: "contains",
              });
            });
            const payload = conditions.length > 1 ? {
              filterType: "join",
              type: "AND",
              conditions: conditions,
            } : conditions[0];
            setFilter(payload);
          } else {
            setFilter({});
          }
          setSelectedGenres(genres);
        }}
      />
      <PlatformRankingTable 
        fetchMethod={fetchMethod.method}
        extraColumnDefs={extraColumnDefs}
        filter={filter}
        selectedCategory={selectedCategory}
        selectedGenres={selectedGenres}
      />
    </SpaceBetween>
  );
};