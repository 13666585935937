import React, { useMemo } from "react";
import { Link, SpaceBetween } from "@cloudscape-design/components";
import TitleSelector from "../../../../components/datapoint-comparison/components/Parameters/TitleSelector";
import { convertData } from "../../../../components/datapoint-comparison/utils";

export const Parameters = ({
  selectedTitle,
  setSelectedTitle,
}) => {
  const [searchQuery, setSearchQuery] = React.useState("");

  const convertedTitle = useMemo(() => (selectedTitle ? convertData([selectedTitle])[0] : null), [selectedTitle]);

  return (
    <div className="flex justify-between">
      <div className="flex gap-3 items-center">
        <div>
          <TitleSelector
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            onSelectedItemChanged={(item) => setSelectedTitle(item.originalData)}
          />
        </div>
        <div className="flex items-center gap-2">
          {convertedTitle && convertedTitle.label && (
            <>
              <img src={convertedTitle?.iconUrl} alt="logo" className="w-8 h-8 rounded-md shadow-md object-cover" />
              <div className="flex flex-col">
                <Link href={`/item/${convertedTitle.value}`} external><b>{convertedTitle.label}</b></Link>
                <div className="text-xs dark:text-slate-400 text-slate-600">{convertedTitle.tags.join(" \u2022 ")}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};