import { createSlice } from "@reduxjs/toolkit";

const createOrgInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const createOrgSlice = createSlice({
    name:"createOrg",
    initialState:createOrgInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        createOrgComepleted: (state, action) => {
            state.isLoading = false;
            state.message = "Organization Created"
            state.isCompleted = true;
            state.isError = false;
        },
        clearOrgCreateReducer:() => {
            return createOrgInitialState;
        },
        createOrgFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const createOrgAction = createOrgSlice.actions;
export default createOrgSlice.reducer;


