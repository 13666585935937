import Button from "@cloudscape-design/components/button";

export default function LoadingButton({ text, loading, onClick, ...other }) {
  return (
    <Button
      {...other}
      onClick={onClick}
     loading={loading}
     loadingText="Processing..."
     variant="primary"
     {...other}
    >
      {text}
    </Button>
  );
}
