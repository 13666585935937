import "ag-grid-enterprise";
import React from "react";
import { PlatformTrendsCharts } from "../../components/PlatformTrendsCharts";
import { getTwitchTrendsData } from "../api/request";
import { defaultCategory as defCategory, twitchApiParams, twitchCategoryOptions } from "../constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "../utils/gridConfig";

export const TwitchTrendsCharts = ({
  defaultCategory=defCategory,
  setSelectedVertical,
}) => {
  return (
    <PlatformTrendsCharts
      apiParams={twitchApiParams}
      defaultColumn={defaultColumn}
      defaultCategory={defaultCategory}
      categoryOptions={twitchCategoryOptions}
      corePlatformColumns={corePlatformColumns}
      fetchMethod={getTwitchTrendsData}
      setSelectedVertical={setSelectedVertical}
      additionalPlatformColumns={additionalPlatformColumns}
    />
  );
};
