import { Cards, ExpandableSection, Link } from "@cloudscape-design/components";
import React, { FC } from "react";

interface CompanyCardItem {
  ip_id: string;
  name: string;
  image_url: string;
  summary?: string;
}

interface CompanyCardsProps {
  items: CompanyCardItem[];
}

export const CompanyCards: FC<CompanyCardsProps> = ({ items }) => {
  return (
      <Cards
        cardDefinition={{
          header: (item) => (
            <div className="flex justify-center items-center p-2">
              <Link
                href={`${window.location.origin}/item/${item.ip_id}`}
                fontSize="heading-s"
              >
                {item.name}
              </Link>
            </div>
          ),

          sections: [
            {
              id: "image-summary",
              content: (item) => (
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <img
                    style={{
                      width: "150px",
                      height: "200px",
                      borderRadius: "10px",
                      marginRight: "20px",
                    }}
                    src={`https://image.tmdb.org/t/p/w185${item.image_url}`}
                    alt={item.name}
                  />
                  <div>
                    {item.summary && item.summary.length > 150
                      ? `${item.summary.substring(0, 150)}...`
                      : item.summary}
                  </div>
                </div>
              ),
            },
          ],
        }}
        cardsPerRow={[
          { cards: 1 },
          { minWidth: 800, cards: 3 },
          { minWidth: 1300, cards: 4 },
          { minWidth: 1600, cards: 5 },
        ]}
        items={items}
        trackBy="ip_id" 
        loadingText="Loading..."
      />
  );
};
