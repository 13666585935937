import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";
import { debounce } from 'lodash';

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

const handler = async ({ params }) => {
  const response = await axiosInstance.request({
    url: `/amazonvendor/autocomplete`,
    method: `GET`,
    params: params,
  });
  return response;
};
const debouncedHandler = (func, wait) => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args).then(resolve).catch(reject);
  }, wait);
  return (...args) => new Promise((resolve, reject) => {
    debounced(resolve, reject, args);
  });
}

export const fetchAutoComplete = createAsyncThunk(
  "amazonvendor/fetchAutoComplete",
  debouncedHandler(handler, 500)
);

const autoCompleteSlice = createSlice({
  name: "amazonvendor",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAutoComplete.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAutoComplete.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
      })
      .addCase(fetchAutoComplete.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const AutoCompleteReducer = autoCompleteSlice.reducer;

export default AutoCompleteReducer;

export const autoCompleteAction = autoCompleteSlice.actions;
