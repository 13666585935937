import { useQuery } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { QUERY_KEYS } from "../constants";
import { ApiURLParams, getGenericPreference } from "../requests";

type AdditionalColumns = Array<string>;

interface DataItem {
  [key: string]: {
    additionalColumns?: AdditionalColumns;
  };
}

export interface PreferenceItem {
  id: string;
  path: string;
  data: DataItem;
}

type PreferenceResponse = Array<PreferenceItem> | [];

interface UseGetGenericPreferenceProps {
  apiParams: ApiURLParams;
  onSuccess?: (data: any) => void;
  onError?: () => void;
  onSettled?: () => void;
}

export const useGetGenericPreference_V2 = ({
  apiParams,
  onSuccess,
  onError,
  onSettled,
}: UseGetGenericPreferenceProps) => {
  const { user } = useAuthContext();
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_GENERAL_PREFERENCE_V2, { ...apiParams }],
    () => getGenericPreference({ ...apiParams, user: user.username }),
    {
      onSuccess,
      onError,
      onSettled,
    },
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};
