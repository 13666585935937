import { useMutation } from "react-query";
import { FetchMediaDataProps, fetchMediaData } from "../request";

export const useFetchMediaData = ({ vertical, onError }) => {
  const { isLoading, data, mutate, error } = useMutation(
    (params: FetchMediaDataProps) => fetchMediaData({ ...params, vertical }),
    { onError },
  );

  return {
    mutate,
    data: data?.data,
    isLoading,
    error,
  };
};
