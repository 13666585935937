import React from "react";
import {
  navItems,
  NavItemsWithId,
} from "../../../../layouts/common/menu/side-menu";
import { useBreadCrumbs } from "../../hooks/useBreadCrumbs";
import { useItemActions } from "../../hooks/useItemActions";
import { Layout } from "../../Layout";
import ItemTelevisionSeries from "./television-series";

interface TelevisionIndexProps {
  itemData: any;
  flashbarRef: React.RefObject<any>;
}

export const TelevisionIndex: React.FC<TelevisionIndexProps> = ({
  itemData,
  flashbarRef,
}) => {
  const { breadcrumbs } = useBreadCrumbs(itemData);
  const { trackItem, unTrackItem, deleteItem } = useItemActions(flashbarRef);

  return (
    <Layout
      flashbarRef={flashbarRef}
      title={itemData?.data?.name ?? "Television"}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      content={
        <ItemTelevisionSeries
          itemData={itemData}
          onTrackItem={trackItem}
          onUntrackItem={unTrackItem}
          onDelete={deleteItem}
        />
      }
      contentType="dashboard"
    ></Layout>
  );
};
