import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Textarea,
} from "@cloudscape-design/components";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../../../../../components/forms/FormProvider";
import { useEffect } from "react";
import DOMPurify from "dompurify";

const franchiseEditFormValidationSchema = Yup.object().shape({
  title: Yup.string().required("Title field is required"),
  description: Yup.string().required("Description field is required"),
});

export const FranchiseEditModal = ({
  visible = false,
  onDiscard = () => {},
  title = "",
  description = "",
  onSubmit = (request) => {},
}) => {
  const methods = useForm({
    defaultValues: {
      title,
      description,
    },
    mode: "all",
    reValidateMode: "all",
    resolver: yupResolver(franchiseEditFormValidationSchema),
  });

  const { control, handleSubmit, setValue } = methods;

  useEffect(() => {
    setValue("title", title);
    setValue("description", description);
  }, []);
  const onFormSubmit = (data) => {
    onSubmit({
      name: data.title,
      summary: DOMPurify.sanitize(data.description),
    });
  };
  return (
    <Modal
      size="large"
      visible={visible}
      onDismiss={onDiscard}
      header={"Edit franchise"}
      closeAriaLabel="Close dialog"
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onFormSubmit)}>
        <SpaceBetween direction="vertical" size="l">
          <Controller
            name={"title"}
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <FormField label="Title" stretch errorText={error?.message}>
                <Input
                  {...field}
                  value={field.value}
                  invalid={invalid}
                  onChange={({ detail }) => field.onChange(detail.value)}
                />
              </FormField>
            )}
          />

          <Controller
            name={"description"}
            control={control}
            render={({
              field,
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <FormField stretch label="Description" errorText={error?.message}>
                <Textarea
                  {...field}
                  value={field.value}
                  invalid={invalid}
                  onChange={({ detail }) => field.onChange(detail.value)}
                  autoComplete="on"
                  rows={20}
                  spellcheck={true}
                />
              </FormField>
            )}
          />
          <div className="py-2 flex flex-row w-full justify-end">
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={(e) => {
                    e.preventDefault();
                    onDiscard();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="primary" formAction="submit">
                  Submit
                </Button>
              </SpaceBetween>
            </Box>
          </div>
        </SpaceBetween>
      </FormProvider>
    </Modal>
  );
};
