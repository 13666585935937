import { Controller, useFormContext } from "react-hook-form";
import { FormField, Select } from "@cloudscape-design/components";

export default function SelectBox({ name, items, helperText, ...other }) {
  const { control } = useFormContext();
  
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <FormField
              label={other.label}
              description={other.description}
              errorText={error ? error?.message : helperText}
            >
              <Select
                {...field}
                {...other}
                selectedOption={field.value}
                onChange={({ detail }) => field.onChange(detail.selectedOption)}
                options={items}
                invalid={error?.message}
                selectedAriaLabel="Selected"
              />
            </FormField>
          </>
        );
      }}
    />
  );
}
