import { ContentLayout, Header, Tabs } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import { addQueryParams, getQueryParams } from "../../../utils/queryUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import { Layout } from "../Layout";
import NonOTTTitleComponent from "./components/NonOTTTitleComponent/NonOTTTitleComponent";

const title = "Non-OTT Titles";
const tabs = ["Table view"];

function NonOTTDashboardPage() {
  const breadcrumbs = [{ text: "Dashboards" }, { text: title }];
  const tabQuery = getQueryParams("tab");
  const isValidTab = tabs.some((str) => str.includes(tabQuery));
  const [activeTabId, setActiveTabId] = useState(
    isValidTab ? tabQuery : "table-view",
  );
  const [drawers, setDrawers] = useState([]);
  const [splitPanel, setSplitPanel] = useState(null);
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const flashbarRef = useRef(null);

  if (!isValidTab) {
    addQueryParams({ tab: activeTabId });
  }

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      drawers={drawers}
      contentType="table"
      content={
        <ContentLayout
          header={
            <>
              <Header
               variant="h2"
               description="This section covers theatrical and traditional media titles that is not currently available on OTT/streaming platforms. It excludes titles released in the past 6 months."
               >{title}</Header>
              <CustomFlashBar ref={flashbarRef} />
            </>
          }
        >
          <Tabs
            onChange={({ detail }) => {
              setActiveTabId(detail.activeTabId);
              addQueryParams({ tab: detail.activeTabId });
            }}
            activeTabId={activeTabId}
            fitHeight
            tabs={[
              {
                label: "Table view",
                id: "table-view",
                content: (
                  <NonOTTTitleComponent
                    setDrawers={setDrawers}
                    setSplitPanel={setSplitPanel}
                    setSplitPanelStatus={setSplitPanelStatus}
                    flashbarRef={flashbarRef}

                  />
                ),
              },
            ]}
          />
        </ContentLayout>
      }
      splitPanel={splitPanel}
      splitPanelStatus={splitPanelStatus}
      setSplitPanelStatus={setSplitPanelStatus}
    />
  );
}

export default NonOTTDashboardPage;
