import { FormField, Multiselect } from "@cloudscape-design/components";
import { useFormContext, Controller } from "react-hook-form";

export const MultiSelectBox = ({ name, options, ...props }) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <>
            <FormField
              label={props.label}
              description={props.description}
              errorText={error ? error?.message : ""}
            >
              <Multiselect
                {...field}
                selectedOptions={field.value ? field.value : []}
                onChange={({ detail }) =>
                  field.onChange(detail.selectedOptions)
                }
                deselectAriaLabel={(e) => `Remove ${e.label}`}
                options={options}
                placeholder="Choose options"
                selectedAriaLabel="Selected"
                invalid={error?.message}
              />
            </FormField>
          </>
        );
      }}
    />
  );
};
