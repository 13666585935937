import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Alert,
  AppLayout,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  PropertyFilter,
  SideNavigation,
  SpaceBetween,
  Table
} from "@cloudscape-design/components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { CommonPreference } from "../../../components/CommonPreference";
import FormModel from "../../../components/FormModel";
import { navItems } from "../../../layouts/common/menu/side-menu";
import TableEmptyState from "../../../utils/TableEmptyState";
import TableNoMatchState from "../../../utils/TableNoMatchState";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import createTableSortLabelFn from "../../../utils/createTableSortLabelFn";
import getTextFilterCounterText from "../../../utils/getTextFilterCounterText";
import CustomFlashBar from "../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import { getPermissionsList } from "../permissions/redux/actions/permissions-actions";
import CreateRoleForm from "./forms/create-role-form";
import EditRoleForm from "./forms/edit-role-form";
import { getRolesList } from "./redux/actions/roles-action";
import {
  roleVisibleContentPreference,
  rolesFilteringProperties,
} from "./table-properties/role-table-properties";
import { useNavigate } from "react-router-dom";
import { SERVICES_GROUP } from "../../../layouts/common/menu/side-menu";

export default function RoleListPage() {
  const rolesList = useSelector((state) => state.rolesList);
  const permissionsList = useSelector((state) => state.permissionsList);

  const [openModel, setOpenModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openRoleDeleteModel, setOpenRoleDeleteModel] = useState(false);

  const [selectedItems, setSelectedItems] = useState([]);

  const createRole = useSelector((state) => state.createRole);

  const navigate = useNavigate();

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const filteredNavItems = navItems.filter(
    (item) =>
      item.type === "section-group" && item?.id === SERVICES_GROUP.ACCOUNT_MANAGEMENT,
  );

  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);
  useEffect(() => {
    if (
      !createRole.isLoading &&
      createRole.isCompleted &&
      !createRole.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: createRole.message,
        })
      );
    } else if (
      !createRole.isLoading &&
      createRole.isCompleted &&
      createRole.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: createRole.message,
        })
      );
    }
  }, [createRole]);

  const editRole = useSelector((state) => state.editRole);
  useEffect(() => {
    if (!editRole.isLoading && editRole.isCompleted && !editRole.isError) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: editRole.message,
        })
      );
    } else if (
      !editRole.isLoading &&
      editRole.isCompleted &&
      editRole.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: editRole.message,
        })
      );
    }
  }, [editRole]);

  const [preferences, setPreferences] = React.useState({
    pageSize: 20,
    wrapLines: false,
    visibleContent: ["roleName", "permissions"],
  });

  useEffect(() => {
    setSelectedItems([]);
    getRolesList();
    getPermissionsList();
  }, []);
  // role delete
  const roleDelete = (ids) => {
    setOpenRoleDeleteModel(false);
    axiosInstance
      .request({
        url: "roles",
        method: "DELETE",
        data: {
          id: ids,
        },
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: "Items deleted successfully",
          })
        );

        setSelectedItems([]);
        getRolesList();
      })
      .catch((e) =>
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.message,
          })
        )
      );
  };

  const columnDefinitions = [
    {
      header: "Role",
      id: "roleName",
      sortingField: "roleName",
      cell: (e) => e.roleName,
      width: 150,
      maxWidth: 250,
    },
    {
      header: "Permissions",
      id: "permissions",
      sortingField: "permissions",
      cell: (e) => (
        <div
          className="flex flex-row flex-wrap mx-1"
          style={{ width: "auto" }} // Set the maxWidth for the cell content
        >
          {e.permissions.map((val) => (
            <span
              key={val}
              className="rounded-full bg-blue-200 px-2.5 py-0.5 text-sm  text-black m-1"
            >
              {val}
            </span>
          ))}
        </div>
      ),
    },
    {
      header: "Created At",
      id: "createdAt",
      sortingField: "createdAt",
      cell: (e) =>
        e.createdAt ? moment(e.createdAt).format("MM/DD/yyyy, hh:mm A") : "",
    },
    {
      header: "Updated At",
      id: "updatedAt",
      sortingField: "updatedAt",
      cell: (e) =>
        e.updatedAt ? moment(e.updatedAt).format("MM/DD/yyyy, hh:mm A") : "",
    },
  ];

  const deletingItemsSelected =
    selectedItems.filter((it) => it.state === "deleting").length > 0;

  //delete Model
  function DeleteModal({ roles, visible, onDiscard, onDelete }) {
    const deleteConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onDelete();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === deleteConsentText;

    const isMultiple = roles.length > 1;
    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={isMultiple ? "Delete roles" : "Delete role"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onDelete}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {roles.length > 0 && (
          <SpaceBetween size="m">
            {isMultiple ? (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {roles.length} users
                </Box>
                ? You can’t undo this action.
              </Box>
            ) : (
              <Box variant="span">
                Permanently delete user
                <Box variant="span" fontWeight="bold">
                  {roles[0].id}
                </Box>
                ? You can’t undo this action.
              </Box>
            )}

            <Alert type="warning" statusIconAriaLabel="Warning">
              Proceeding with this action will delete the
              {isMultiple
                ? " roles with all their content "
                : " role with all its content"}
              and can affect related items.
            </Alert>

            <Box>
              To avoid accidental deletions, we ask you to provide additional
              written consent.
            </Box>

            <ColumnLayout columns={2}>
              <form onSubmit={handleDeleteSubmit}>
                <FormField
                  label={`To confirm this deletion, type "${deleteConsentText}".`}
                >
                  <Input
                    placeholder={deleteConsentText}
                    onChange={(event) => setDeleteInputText(event.detail.value)}
                    value={deleteInputText}
                    ariaRequired={true}
                  />
                </FormField>
              </form>
            </ColumnLayout>
          </SpaceBetween>
        )}
      </Modal>
    );
  }

  const rawColumns = columnDefinitions.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(rolesList.rolesList, {
    selection: {},
    propertyFiltering: {
      filteringProperties: rolesFilteringProperties,
      empty:
        rolesList.isUserListLoading && !rolesList.isUserListLoadingCompleted ? (
          ""
        ) : (
          <TableEmptyState resourceName={"User"} />
        ),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: "and" });
          }}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: rawColumns[0] } },
  });

  const Content = () => {
    return (
      <>
        <Table
          {...collectionProps}
          wrapLines={preferences.wrapLines}
          stripedRows={preferences.stripedRows}
          contentDensity={preferences.contentDensity}
          columnDisplay={preferences.contentDisplay}
          onSelectionChange={(d) => setSelectedItems(d.detail.selectedItems)}
          selectedItems={selectedItems}
          items={items}
          columnDefinitions={columnDefinitions}
          variant="full-page"
          selectionType="multi"
          resizableColumns={true}
          ariaLabels={{
            itemSelectionLabel: (_data, row) => `select ${row.id}`,
            allItemsSelectionLabel: () => "select all",
            selectionGroupLabel: `User selection`,
          }}
          preferences={
            <CommonPreference
              preferences={preferences}
              onConfirm={({ detail }) => setPreferences(detail)}
              contentDisplayOptions={roleVisibleContentPreference}
            />
          }
          pagination={
            <Pagination
              {...paginationProps}
              ariaLabels={{
                nextPageLabel: "Next page",
                previousPageLabel: "Previous page",
                pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
              }}
            />
          }
          header={
            <>
              <Header
                variant="h3"
                actions={
                  <SpaceBetween direction="horizontal" size="l">
                    <Button
                      variant="primary"
                      onClick={() => setOpenModel(true)}
                    >
                      Create
                    </Button>
                    <Button
                      disabled={selectedItems.length !== 1}
                      onClick={() => setOpenEditModel(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      disabled={
                        selectedItems.length === 0 || deletingItemsSelected
                      }
                      onClick={() => {
                        // onDeleteInit();
                        setOpenRoleDeleteModel(true);
                      }}
                    >
                      Delete
                    </Button>
                  </SpaceBetween>
                }
                counter={
                  selectedItems.length
                    ? "(" +
                      selectedItems.length +
                      "/" +
                      rolesList.rolesList.length +
                      ")"
                    : "(" + rolesList.rolesList.length + ")"
                }
              >
                Roles
              </Header>

              <div className="py-5">
                <CustomFlashBar />
              </div>
            </>
          }
          filter={
            <PropertyFilter
              countText={getTextFilterCounterText(filteredItemsCount)}
              {...propertyFilterProps}
              i18nStrings={{
                filteringAriaLabel: "your choice",
                dismissAriaLabel: "Dismiss",
                clearAriaLabel: "Clear",

                filteringPlaceholder: "Filter users by text, property or value",
                groupValuesText: "Values",
                groupPropertiesText: "Properties",
                operatorsText: "Operators",

                operationAndText: "and",
                operationOrText: "or",

                operatorLessText: "Less than",
                operatorLessOrEqualText: "Less than or equal",
                operatorGreaterText: "Greater than",
                operatorGreaterOrEqualText: "Greater than or equal",
                operatorContainsText: "Contains",
                operatorDoesNotContainText: "Does not contain",
                operatorEqualsText: "Equals",
                operatorDoesNotEqualText: "Does not equal",

                editTokenHeader: "Edit filter",
                propertyText: "Property",
                operatorText: "Operator",
                valueText: "Value",
                cancelActionText: "Cancel",
                applyActionText: "Apply",
                allPropertiesLabel: "All properties",

                tokenLimitShowMore: "Show more",
                tokenLimitShowFewer: "Show fewer",
                clearFiltersText: "Clear filters",
                removeTokenButtonAriaLabel: (token) =>
                  `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
                enteredTextLabel: (text) => `Use: "${text}"`,
              }}
              {...propertyFilterProps}
              expandToViewport={true}
            />
          }
        />
      </>
    );
  };

  const breadcrumbs = [
    {
      text: "Account Management",
      // href: '/users',
    },
    {
      text: "Roles",
      href: "/roles",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Pantheon - Roles</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
          onFollow={onFollowHandler}
          activeHref={window.location.pathname}
          items={filteredNavItems}
          />
        }
        content={<Content />}
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
      <DeleteModal
        visible={openRoleDeleteModel}
        roles={selectedItems}
        onDelete={(e) => {
          if (selectedItems.length > 0) {
            roleDelete(selectedItems.map((m) => m.id));
          }
        }}
        onDiscard={(e) => {
          setSelectedItems([]);
          setOpenRoleDeleteModel(false);
        }}
      />
      <div className="px-4">
        <div className="px-4 sm:px-6 lg:px-8"></div>
        <FormModel
          open={openModel}
          onClose={() => {
            setOpenModel(false);
          }}
          header="Create Role"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="normal"
                  formAction="none"
                  onClick={() => {
                    setOpenModel(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  formAction="submit"
                  form="role_create_form"
                >
                  Submit
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <>
            <CreateRoleForm
              formStatus={openModel}
              setCompleted={() => {
                getRolesList();
              }}
              setCancel={() => setOpenModel(false)}
              permissionsList={permissionsList.permissionsList}
            />
          </>
        </FormModel>
        <FormModel
          header="Edit Role"
          open={openEditModel}
          onClose={() => {
            setOpenEditModel(false);
          }}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="normal"
                  formAction="none"
                  onClick={() => {
                    setOpenEditModel(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  formAction="submit"
                  form="edit_role_form"
                >
                  Submit
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          <>
            <EditRoleForm
              defaults={selectedItems.length === 1 ? selectedItems[0] : null}
              setCompleted={() => {
                getRolesList();
                setSelectedItems([]);
              }}
              setCancel={() => setOpenEditModel(false)}
              permissionsList={permissionsList.permissionsList}
            />
          </>
        </FormModel>
      </div>
    </>
  );
}
