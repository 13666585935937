import { Alert, Button, SpaceBetween } from "@cloudscape-design/components";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormProvider from "../../../../components/forms/FormProvider";
import SwitchButton from "../../../../components/forms/SwitchButton";
import { TextField } from "../../../../components/forms/TextField";
import SnackbarContext from "../../../../hooks/notify/snack-bar/snackbar-context";
import {
  clearOrgCreateFn,
  createOrgFn,
} from "../redux/actions/organizations-actions";
export default function CreateOrganizationForm({
  setCancel,
  setCompleted,
  ...others
}) {
  const snackbarCtx = useContext(SnackbarContext);
  const onSubmit = async (data) => {
    createOrgFn({ ...data });
  };

  const createOrg = useSelector((state) => state.createOrg);
  useEffect(() => {
    if (!createOrg.isLoading && createOrg.isCompleted && !createOrg.isError) {
      setCompleted();
      setCancel(true);
      reset();
    }
    return () => {
      clearOrgCreateFn();
    };
  }, [createOrg]);

  const createUserDefaultValue = {
    email: "",
    name: "",
    org_status: "Active" ? true : false,
    unrestrictedAccess: false,
  };
  const createUserSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email().required("Email is required"),
    org_status: Yup.boolean().required("Status is required"),
    unrestrictedAccess: Yup.boolean().required(
      "Unrestricted Access is required",
    ),
  });

  const methods = useForm({
    defaultValues: createUserDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createUserSchema),
  });

  const {
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className="py-5">
        {!createOrg.isLoading && createOrg.isCompleted && createOrg.isError && (
          <Alert type="error" dismissible={true}>
            {createOrg.message}
          </Alert>
        )}
        {!createOrg.isLoading &&
          createOrg.isCompleted &&
          !createOrg.isError && (
            <Alert type="success" dismissible={true}>
              {createOrg.message}
            </Alert>
          )}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
          <span>
            <TextField
              name={"name"}
              label="Name"
              placeholder={"Name"}
              type={"text"}
            />
          </span>
          <span>
            <TextField
              name={"email"}
              label="Email"
              placeholder={"Email"}
              type={"text"}
            />
          </span>
          <span>
            <SwitchButton
              name={"unrestrictedAccess"}
              label="Unrestricted Access"
              placeholder={"Unrestricted Access"}
            />
          </span>
          <span>
            <SwitchButton
              name={"org_status"}
              label="Status"
              placeholder={"Status"}
            />
          </span>
        </div>
        <div className="flex flex-row justify-end items-center pt-3">
          <SpaceBetween direction="horizontal" size="m">
            <span>
              <Button
                formAction="none"
                loading={isSubmitting}
                onClick={() => {
                  setCancel(true);
                  reset();
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                variant="primary"
                loading={isSubmitting}
                formAction="submit"
              >
                Submit
              </Button>
            </span>
          </SpaceBetween>
        </div>
      </div>
    </FormProvider>
  );
}
