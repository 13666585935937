import {
  Button,
  ContentLayout,
  Header,
  Tabs
} from "@cloudscape-design/components";
import React, { useCallback, useState } from "react";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../common/CustomFlashBar";
import { Layout } from "../Layout";
import { ReportTable } from "./reports/ReportTable";
import GenerateReportModal from "./reports/components/GenerateReportModal";

function OfflineReportsIndexPage() {
  const [activeTabId, setActiveTabId] = useState('my-reports');
  const [isGenerateReportModalOpen, setIsGenerateReportModalOpen] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  const title = "Offline Reports";
  const breadcrumbs = [
    { text: "Tools" },
    { text: title },
  ];

  const handleModalClose = useCallback(() => {
    setIsGenerateReportModalOpen(false);
    setRefreshTrigger(prev => prev + 1); // Trigger a refresh when modal closes
  }, []);

  return (
    <Layout
      contentType="table"
      title={title}
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      content={
        <ContentLayout
          header={
            <>
              <Header
                description="Offline Report allows you to generate reports in the backend and download them as a CSV file."
                variant="h2"
                actions={
                  <>
                  <Button
                    onClick={() => setIsGenerateReportModalOpen(true)}
                    variant="primary"
                  >Generate Report
                  </Button>
                  <GenerateReportModal
                    visible={isGenerateReportModalOpen}
                    onDismiss={handleModalClose}
                  />
                  </>
                }
              >
                Offline Reports
              </Header>
              <CustomFlashBar />
            </>
          }
        >
          <Tabs
            activeTabId={activeTabId}
            onChange={({ detail }) => setActiveTabId(detail.activeTabId)}
            tabs={[
              {
                id: "my-reports",
                label: "My Reports",
                content: <div><ReportTable category="personal" activeTabId={activeTabId} refreshTrigger={refreshTrigger} /></div>,
              },
              {
                id: "organization-reports",
                label: "Organization Reports",
                content: <div><ReportTable category="organization" activeTabId={activeTabId} refreshTrigger={refreshTrigger} /></div>,
              },
            ]}
          />
        </ContentLayout>
      }
    />
  );
}

export default OfflineReportsIndexPage;
