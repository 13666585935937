import { Checkbox, TextContent } from "@cloudscape-design/components";
import Input from "@cloudscape-design/components/input";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const InputField = ({ name, isChecked, helperText, ...other }) => {
  const { control, watch, setValue } = useFormContext();
  const checked = watch(isChecked);

  return (
    <div className="flex items-center gap-4">
      {isChecked && (
        <Controller
          control={control}
          name={isChecked}
          render={({ field }) => (
            <Checkbox
              {...field}
              checked={checked}
              defaultChecked={field.value}
              onChange={() => setValue(isChecked, !checked)}
            />
          )}
        />
      )}
      <TextContent>
        <span className={checked ? "" : "text-slate-600"}>{other.label}</span>
      </TextContent>
      <div className="w-14  ml-auto">
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Input
              {...field}
              value={field.value}
              {...other}
              onChange={({ detail }) => field.onChange(detail.value)}
              invalid={error?.message}
              disabled={!checked}
            />
          )}
        />
      </div>
    </div>
  );
};
