import React, { useState } from "react";
import { CATEGORIES, Category } from "../../constants";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { getAllCategories } from "../../utils";
import { AgGridReact } from "ag-grid-react";
import { useGetGoogleRankingData } from "../../api/hooks/useGetGoogleRankingData";
import { useGridConfig } from "./hooks/useGridConfig";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { RangeDateSelector } from "../../../../../components/RangeDateSelector";
import { Input, Select } from "@cloudscape-design/components";
import TableLegend from "../../../../../components/table/TableLegend";
import { VERTICALS } from "../../../../../config-global";

const verticalOptions = [
  { label: "All", value: "all" },
  { label: "Movies", value: VERTICALS.MOVIES },
  { label: "Series", value: VERTICALS.SERIES },
  { label: "Games", value: VERTICALS.GAMES },
];

export const RankingTable = ({ setSelectedTab }) => {

  const { theme } = useGetGridTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  const [quickFilterText, setQuickFilterText] = useState<string>("");
  const [selectedVertical, setSelectedVertical] = useState<any>(searchParams.get("vertical") ? verticalOptions.find(o => o.value === searchParams.get("vertical")) : verticalOptions[0]);
  const [selectedCategory, setSelectedCategory] = useState<Category>(searchParams.get("category") ? getAllCategories(CATEGORIES).find(c => c.value === searchParams.get("category")) : CATEGORIES.GENERAL.CATEGORY);
  const [dateRange, setDateRange] = useState<any>({
    type: "relative",
    amount: 2,
    unit: "week",
    startDate: moment().subtract(2, "week").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const { data, isLoading, error } = useGetGoogleRankingData({
    enabled: selectedCategory != null,
    category: selectedCategory.value,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
  });

  const gridOptions = useGridConfig({ data, selectedVertical, setSearchParams, setSelectedTab });

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex gap-3 justify-between">
        <div>
          <Input
            value={quickFilterText}
            onChange={({ detail }) => setQuickFilterText(detail.value)}
            placeholder="Search for a title"
            type="search"
          />
        </div>
        <div className="flex gap-3">
          <Select
            options={verticalOptions}
            selectedOption={selectedVertical as any}
            onChange={({ detail }) => {
              setSelectedVertical(detail.selectedOption as any);
              setSearchParams(params => {
                params.set("vertical", detail.selectedOption.value);
                return params;
              });
            }}
          />
          <Select
            options={getAllCategories(CATEGORIES)}
            selectedOption={selectedCategory}
            onChange={({ detail }) => {
              setSelectedCategory(detail.selectedOption as Category);
              setSearchParams(params => {
                params.set("category", detail.selectedOption.value);
                return params;
              });
            }}
          />
          <RangeDateSelector 
            defaults={dateRange}
            onChange={setDateRange}
          />
        </div>
      </div>
      <div style={{ height: "450px", width: "100%" }} className={theme}>
        <AgGridReact
          {...gridOptions as any}
          loading={isLoading}
          loadingOverlayComponent={CustomLoadingOverlay}
          pagination={true}
          paginationPageSize={50}
          quickFilterText={quickFilterText}
        />
      </div>
      <TableLegend />
    </div>
  );
};