import { Calendar, DateInput, FormField } from "@cloudscape-design/components";
import React from "react";

// get date in YYYY/MM/DD format from 7 days ago
const sevenDaysAgo = new Date();
sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

const year = sevenDaysAgo.getFullYear();
const month = String(sevenDaysAgo.getMonth() + 1).padStart(2, '0');
const day = String(sevenDaysAgo.getDate()).padStart(2, '0');

const formattedDate = `${year}-${month}-${day}`;


export const filteringProperties = [
  {
    propertyLabel: "Title",
    key: "title",
    groupValuesLabel: "Title",
    operators: [":", "=", "blank", "notBlank"],
  },
  {
    propertyLabel: "Channel",
    key: "channel",
    groupValuesLabel: "Channel",
    operators: [":", "=", "blank", "notBlank"],
  },
  {
    propertyLabel: "IP",
    key: "ip",
    groupValuesLabel: "IP",
    operators: [":", "=", "blank", "notBlank"],
  },
  {
    propertyLabel: "Published on",
    key: "publishedAt",
    groupValuesLabel: "Published on",
    operators: ["=", ">", "<", ">=", "<="].map((operator) => ({
      operator,
      form: ({ value, onChange }) => (
        <div className="date-form">
          <FormField>
            <DateInput
              value={value ?? ""}
              onChange={(event) => onChange(event.detail.value)}
              placeholder="YYYY/MM/DD"
            />
          </FormField>
          <Calendar
            value={value ?? ""}
            onChange={(event) => onChange(event.detail.value)}
            locale="en-GB"
          />
        </div>
      ),
      match: "date",
    })),
  },
  {
    propertyLabel: "Views",
    key: "views",
    groupValuesLabel: "Views",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Today's Views",
    key: "todayViews",
    groupValuesLabel: "Today's Views",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Likes",
    key: "likeCount",
    groupValuesLabel: "Likes",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Today's Likes",
    key: "todayLikes",
    groupValuesLabel: "Today's Likes",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Comments",
    key: "comments",
    groupValuesLabel: "Comments",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Today's Comments",
    key: "todayComments",
    groupValuesLabel: "Today's Comments",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Sentiment Score",
    key: "sentimentScore",
    groupValuesLabel: "Sentiment Score",
    operators: [">", "<", ">=", "<=", "blank", "notBlank"],
  },
  {
    propertyLabel: "Made for Kids",
    key: "madeForKids",
    operators: [":", "=", "blank", "notBlank"],
  },
];

const defaultFiltersMovieTrailers = {
  "Published in Last 7 days": {
    operation: "and",
    tokens: [{ propertyKey: "publishedAt", operator: ">=", value: formattedDate }],
  },
};

const defaultFilterstvShowTrailers = {
  "Published in Last 7 days": {
    operation: "and",
    tokens: [{ propertyKey: "publishedAt", operator: ">=", value: formattedDate }],
  },
};

const defaultFiltersGameTrailers = {
  "Published in Last 7 days": {
    operation: "and",
    tokens: [{ propertyKey: "publishedAt", operator: ">=", value: formattedDate }],
  },
};

const defaultFiltersUnmappedTrailers = {
  "Published in Last 7 days": {
    operation: "and",
    tokens: [{ propertyKey: "publishedAt", operator: ">=", value: formattedDate }],
  },
};

const defaultFiltersUnofficialTrailers = {
  "Published in Last 7 days": {
    operation: "and",
    tokens: [{ propertyKey: "publishedAt", operator: ">=", value: formattedDate }],
  },
};

const defaultFiltersKidsTrailers = {
  "Published in Last 7 days": {
    operation: "and",
    tokens: [{ propertyKey: "publishedAt", operator: ">=", value: formattedDate }],
  },
};

const defaultFiltersAllTrailers = {
  "Published in Last 7 days": {
    operation: "and",
    tokens: [{ propertyKey: "publishedAt", operator: ">=", value: formattedDate }],
  },
};

export const getDefaultFilters = (type: string) => {
  switch (type) {
    case "film":
      return defaultFiltersMovieTrailers;
    case "series":
      return defaultFilterstvShowTrailers;
    case "game":
      return defaultFiltersGameTrailers;
    case "unmapped":
      return defaultFiltersUnmappedTrailers;
    case "unofficial":
      return defaultFiltersUnofficialTrailers;
    case "kids":
      return defaultFiltersKidsTrailers;
    case "all":
      return defaultFiltersAllTrailers;
    default:
      return {};
  }
};
