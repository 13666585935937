import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { GetVideosListDataProps, getVideosListData } from "../request";

export const useVideosListData = (
  params: GetVideosListDataProps,
  enabled: boolean = false,
) => {
  const { isLoading, data, error } = useQuery(
    [
      QUERY_KEYS.GET_VIDEOS_LIST_DATA,
      params.from,
      params.size,
      params.include,
      params.exclude,
      params.sort,
      params.condition,
      params.contentType,
    ],
    () => getVideosListData(params),
    {
      enabled,
    },
  );

  return {
    data: isSomeOrElse(data?.data, []),
    isLoading,
    error,
  };
};
