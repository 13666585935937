import { useMemo } from 'react';

export const useSideBarConfig = () => {
    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: "columns",
                    labelDefault: "Columns",
                    labelKey: "columns",
                    iconKey: "columns",
                    toolPanel: "agColumnsToolPanel",
                    minWidth: 225,
                    maxWidth: 400,
                    width: 225,
                },
                {
                    id: "filters",
                    labelDefault: "Filters",
                    labelKey: "filters",
                    iconKey: "filter",
                    toolPanel: "agFiltersToolPanel",
                    minWidth: 225,
                    maxWidth: 400,
                    width: 225,
                },
            ],
            defaultToolPanel: '',
        };
    }, []);

    return sideBar;
};
