import { TrendsContent, TrendsFooter, TrendsHeader } from "../component/Trends";

const title = "Piracy Series Trends";

export const piracySeries = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Daily Piracy downloads for series",
    header: () => <TrendsHeader title={title} />,
    content: () => <TrendsContent vertical="series" platform="piracy" sort="daily_rank" />,
    footer: () => <TrendsFooter linkHref="/platforms/piracy?tab=trends&category=series" />,
  },
};