import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getWikipediaLeaderBoard } from "../request";
import moment from "moment";

type UseGetWikipediaLeaderBoardDataProps = {
  dateRange: any;
  selectedOptionSelectIPList: { label: string; value: string };
  selectedOptionVertical: any;
  selectedOptionGenre: any;
  keyword: string;
  currentPageIndex: number;
  enabled: boolean;
  onSuccess: () => void;
  onError: () => void;
};

export const useGetWikipediaLeaderBoardData = ({
  dateRange,
  selectedOptionSelectIPList,
  selectedOptionVertical,
  selectedOptionGenre,
  keyword,
  currentPageIndex,
  enabled,
  onSuccess,
  onError,
}: UseGetWikipediaLeaderBoardDataProps) => {
  const indexOfLastItem = currentPageIndex * 48;
  const indexOfFirstItem = indexOfLastItem - 48;

  const params = {
    gte: dateRange?.startDate
      ? moment(dateRange.startDate).unix()
      : moment().subtract(15, "days").unix(),
    lte: dateRange?.endDate
      ? moment(dateRange.endDate).unix()
      : moment().unix(),
    ip_list: selectedOptionSelectIPList?.value ?? "",
    vertical: selectedOptionVertical?.value ?? "",
    genre:
      selectedOptionVertical?.value && selectedOptionGenre?.value
        ? selectedOptionGenre?.value
        : "",
    keyword: keyword ?? "",
    size: 48,
    from: indexOfFirstItem,
  };

  const queryKey = [
    QUERY_KEYS.GET_WIKIPEDIA_LEADERBOARD_DATA,
    dateRange,
    params.ip_list,
    params.vertical,
    params.genre,
    params.keyword,
    params.size,
    params.from,
  ];

  const { isLoading, data, error } = useQuery({
    queryKey,
    queryFn: () => getWikipediaLeaderBoard(params),
    enabled,
    onSuccess,
    onError,
  });

  return {
    data: data?.data,
    isLoading,
    error,
  };
};
