import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { postPantheonRankingPreference } from "../requests";
import { queryClient } from "../../../app/ReactQueryWrapper";
import { QUERY_KEYS } from "../constants";

export const usePostPlatformPreference = (
  platform: string,
  onSuccess,
  onError,
) => {
  const { user } = useAuthContext();

  const { isLoading, mutateAsync, error } = useMutation<any, AxiosError, any>(
    ({ isDefault, name, type, payload }) =>
      postPantheonRankingPreference({
        user,
        fileName: name,
        platform,
        isDefault,
        type,
        payload,
      }),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_PLATFORM_WEIGHTAGES);
        if (result.error) return onError(result.error);
        else onSuccess();
      },
      onError,
    },
  );

  return {
    mutateAsync,
    isLoading,
    error,
  };
};
