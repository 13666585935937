import { Mode } from "@cloudscape-design/global-styles";
import { useThemeContext } from "../../app/ThemeProvider";
import "./ag-themes.css";

export const useGetGridTheme = () => {
  const { mode } = useThemeContext();

  return {
    theme: mode === Mode.Dark ? "ag-theme-quartz-dark" : "ag-theme-quartz",
  };
};
