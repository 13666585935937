import { useMutation } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { putGenericPreference } from "../requests";
import { invalidatePreferenceData } from "../utils/serviceUtils";

export const usePutGenericPreference_V2 = () => {
  const { user } = useAuthContext();
  const { mutate, isLoading, error } = useMutation(
    ({ id, payload }: { id: string; payload: any }) =>
      putGenericPreference(user.username, id, payload),
    {
      onSuccess: invalidatePreferenceData,
    },
  );

  return {
    mutate,
    isLoading,
    error,
  };
};
