import React, { useEffect, useState } from "react";
import { useGetGenreTimeseriesData } from "../api/hooks/useGetGenreTimeseriesData";
import { Select, SpaceBetween, Box, Container, FormField, Multiselect, Spinner } from "@cloudscape-design/components";
import { useTopGenresTableConfig } from "../hooks/useTopGenresTableConfig";
import { useGetGridTheme } from "../../../hooks/UseTheme/useGetGridTheme";
import { AgGridReact } from "ag-grid-react";
import { GENRE_TYPES, TYPE_LABELS } from "../constants";

const genreTypeOptions = [
  { label: "Genres", value: GENRE_TYPES.GENRE },
  { label: "Custom Tags", value: GENRE_TYPES.CUSTOM_TAG },
  { label: "Game Themes", value: GENRE_TYPES.GAME_THEME },
];

const TopGenresTable = () => {

  const [ genreInfo, setGenreInfo ] = useState([]);
  const [ genreTotals, setGenreTotals ] = useState([]);
  const [ selectedCategory, setSelectedCategory ] = useState({ label: "Movies", value: "Movies" });
  const [ selectedGenreTypes, setSelectedGenreTypes ] = useState(genreTypeOptions);
  const [ genreImdbData, setGenreImdbData ] = useState({});
  const [ genreTwitchData, setGenreTwitchData ] = useState({});


  const {
    data: genreTimeseriesResponse,
    isLoading,
    error,
  } = useGetGenreTimeseriesData("1Y");
  const { theme } = useGetGridTheme();
  const gridOptions = useTopGenresTableConfig({
    genreTotals: genreTotals,
    selectedCategory: selectedCategory?.value,
    selectedGenreTypes: selectedGenreTypes.map(t => t.value),
    imdbData: genreImdbData,
    twitchData: genreTwitchData,
  });


  const processTimeseriesData = (data) => {
    const metadata = data?.metadata;

    const genreInfo = metadata?.genres;
    setGenreInfo(genreInfo);

    const genreTotals = genreInfo.map((genre, i) => {
      const total = Object.values(data?.genre_counts).reduce((a, b) => a + b[i], 0);
      return {
        genre,
        total,
      };
    });
    setGenreTotals(genreTotals);

    const imdbData = metadata?.imdb_data;
    setGenreImdbData(imdbData);

    const twitchData = metadata?.twitch_data;
    setGenreTwitchData(twitchData);
  };

  useEffect(() => {
    if (genreTimeseriesResponse) {
      processTimeseriesData(genreTimeseriesResponse.data);
    }
  }, [genreTimeseriesResponse]);

  return (
    isLoading ? (
      <div className="w-full h-[200px] flex items-center justify-center">
        <Spinner size="large" />
      </div>
    ) : (
      <SpaceBetween direction="vertical" size="m">
        <Container>
          <Box float="left">
            <div className="flex space-x-3 items-center">
              <FormField description="Category">
                <Select
                  options={[...new Set(genreInfo.map(genre => genre.vertical))].map(v => ({ label: v, value: v }))}
                  selectedOption={selectedCategory}
                  onChange={({ detail }) => setSelectedCategory(detail.selectedOption)}
                />
              </FormField>
              <FormField description="Genre types">
                <Multiselect
                  selectedOptions={selectedGenreTypes}
                  onChange={({ detail }) => setSelectedGenreTypes(detail.selectedOptions)}
                  options={genreTypeOptions}
                  placeholder="Choose options"
                  hideTokens
                  filteringType="none"
                />
              </FormField>
            </div>
          </Box>
        </Container>
        <div style={{ width: "100%", height: "500px" }} className={theme}>
          <AgGridReact
            {...gridOptions}
          />
        </div>
      </SpaceBetween>
    )
  );
};

export default TopGenresTable;