import { Drawer } from '@cloudscape-design/components';
import React from 'react';

export const InfoDrawer = () => {
  return (
    <Drawer header="Information">
      <div className="flex flex-col gap-2">
        <div className="text-base font-bold">Search interest vs. popularity</div>
        <p>The <b>search interest</b> of a term does not represent the exact number of searches. Instead, it reflects the term's search volume relative to the total number of searches. You can find these values in the "Rankings" tab and the "Title Insights" time series chart.</p>
        <p>The <b>search popularity</b> of a term represents its share of the total search volume within a certain set of parameters. For example, if a region records 40 searches for "coffee" out of 50 total searches, the search popularity of "coffee" would be 80%. You'll find these values in the "Title Insights" region breakdown section where they are normalized to a 0-100 scale.</p>
      </div>
    </Drawer>
  );
};