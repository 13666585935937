import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchAmazonVendorTopProductsData = createAsyncThunk(
  "amazonvendor/fetchAmazonVendorTopProductsData",
  async ({ params }) => {
    const response = await axiosInstance.request({
      url: `/amazonvendor/topproducts`,
      method: `GET`,
      params: params,
    });
    return response;
  }
);

const amazonVendorTopProductsSlice = createSlice({
  name: "amazonvendor",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAmazonVendorTopProductsData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAmazonVendorTopProductsData.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
      })
      .addCase(fetchAmazonVendorTopProductsData.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const AmazonVendorTopProductsReducer = amazonVendorTopProductsSlice.reducer;

export default AmazonVendorTopProductsReducer;

export const amazonVendorTopProductsAction = amazonVendorTopProductsSlice.actions;
