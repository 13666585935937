import {
  Alert,
  AppLayout,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Link,
  Modal,
  SideNavigation,
  SpaceBetween
} from "@cloudscape-design/components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { navItems } from "../../layouts/common/menu/side-menu";
import axiosInstance from "../../utils/axios";
import createFlashMessage from "../../utils/createFlashMessage";
import {
  getSettingsNbcuData,
  saveNbcuSettingData,
} from "./redux/actions/settings-nbcu-actions";
import { RankingTable } from "./table/RankingTable";

import CustomFlashBar from "../common/CustomFlashBar";
import { addMessageToFlash, cleanAllFlashMessage } from "../common/redux/flash-action";

export const RankingPage = () => {
  const navigate = useNavigate();
  const settingsNbcuData = useSelector((state) => state.settingsNbcuData);
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [editingRow, setEditingRow] = useState(null);
  const [editingRowData, setEditingRowData] = useState(null);
  const [initialRowData, setInitialRowData] = useState(null);
  const [canEdit, setCanEdit] = useState(false);
  const [openRankDeleteModel, setOpenRankDeleteModel] = useState(false);

  const handleRowEdit = (rowData) => {
    setEditingRow(rowData.id);
    setEditingRowData(rowData);
    setInitialRowData(rowData);
  };
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);
  const handleRowSave = (id) => {
    if (editingRowData) {
      let totalPercentage =
        parseInt(editingRowData.searchDemand) +
        parseInt(editingRowData.fanSites) +
        parseInt(editingRowData.contentConsumption) +
        parseInt(editingRowData.cpMarketplace) +
        parseInt(editingRowData.piracy);
      if (totalPercentage === 100) {
        saveNbcuSettingData(
          {
            searchDemand: { value: editingRowData.searchDemand },
            fanSites: { value: editingRowData.fanSites },
            contentConsumption: { value: editingRowData.contentConsumption },
            cpMarketplace: { value: editingRowData.cpMarketplace },
            piracy: { value: editingRowData.piracy },
            order: editingRowData.order,
          },
          editingRowData.id
        );
        setEditingRowData(null);
      } else {
        setErrorMessages([
          {
            header: "Failed to update values",
            type: "error",
            content: "Total percentage must be equal to 100.",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: () => setErrorMessages([]),
            id: "error_1",
          },
        ]);
      }
    }
    setEditingRow(null);
  };

  const roleDelete = (ids) => {
    setOpenRankDeleteModel(false);
    axiosInstance
      .request({
        url: "/settings/nbcuweighting",
        method: "DELETE",
        data: {
          id: ids,
        },
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: "Items deleted successfully",
          })
        );

        setSelectedItems([]);
        getSettingsNbcuData();
      })
      .catch((e) =>
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.message,
          })
        )
      );
  };

  const handleRowCancel = () => {
    setEditingRowData(initialRowData);
    setEditingRow(null);
  };

  const saveSaveNbcuSettingsData = useSelector(
    (state) => state.saveSaveNbcuSettingsData
  );
  useEffect(() => {
    getSettingsNbcuData();
  }, []);
  useEffect(() => {
    if (
      !saveSaveNbcuSettingsData.loading &&
      saveSaveNbcuSettingsData.completed
    ) {
      getSettingsNbcuData();
      setCanEdit(false);
    }
  }, [saveSaveNbcuSettingsData]);

  const breadcrumbs = [
    { text: "Settings" },
    { text: "NBCU Weightage" },
    { text: "Ranking", href: "/settings/nbcu-weightage/ranking" },
  ];

  const columnDefinitions = [
    {
      header: "Id",
      id: "id",
      sortingField: "id",
      cell: (e) => <span>{e.id}</span>,
    },
    {
      header: "Name",
      id: "name",
      sortingField: "name",
      cell: (e) => (
        <Link href={`/settings/nbcu-weightage/ranking/${e.id}`}>{e.name}</Link>
      ),
    },
    {
      header: "Search Demand",
      id: "searchDemand",
      sortingField: "searchDemand",
      cell: (e) => (
        <>
          {editingRow !== e.id ? (
            <span>{e.searchDemand}%</span>
          ) : (
            <Input
              autoFocus={true}
              value={editingRowData.searchDemand}
              onChange={(event) => {
                setEditingRowData((prevData) => ({
                  ...prevData,
                  searchDemand: Math.round(parseFloat(event.detail.value) || 0),
                }));
              }}
            />
          )}
        </>
      ),
    },
    {
      header: "Fan Sites",
      id: "fanSites",
      sortingField: "fanSites",
      cell: (e) => (
        <>
          {editingRow !== e.id ? (
            <span>{e.fanSites}%</span>
          ) : (
            <Input
              autoFocus={true}
              value={editingRowData.fanSites}
              onChange={(event) => {
                setEditingRowData((prevData) => ({
                  ...prevData,
                  fanSites: Math.round(parseFloat(event.detail.value) || 0),
                }));
              }}
            />
          )}
        </>
      ),
    },
    {
      header: "Content Consumption",
      id: "contentConsumption",
      sortingField: "contentConsumption",
      cell: (e) => (
        <>
          {editingRow !== e.id ? (
            <span>{e.contentConsumption}%</span>
          ) : (
            <Input
              autoFocus={true}
              value={editingRowData.contentConsumption}
              onChange={(event) => {
                setEditingRowData((prevData) => ({
                  ...prevData,
                  contentConsumption: Math.round(
                    parseFloat(event.detail.value) || 0
                  ),
                }));
              }}
            />
          )}
        </>
      ),
    },
    // {
    //   header: "Consumer Products",
    //   id: "cpMarketplace",
    //   sortingField: "cpMarketplace",
    //   cell: (e) => (
    //     <>
    //       {editingRow !== e.id ? (
    //         <span>{e.cpMarketplace}%</span>
    //       ) : (
    //         <Input
    //           autoFocus={true}
    //           value={editingRowData.cpMarketplace}
    //           onChange={(event) => {
    //             setEditingRowData((prevData) => ({
    //               ...prevData,
    //               cpMarketplace: Math.round(
    //                 parseFloat(event.detail.value) || 0
    //               ),
    //             }));
    //           }}
    //         />
    //       )}
    //     </>
    //   ),
    // },
    {
      header: "Piracy",
      id: "piracy",
      sortingField: "piracy",
      cell: (e) => (
        <>
          {editingRow !== e.id ? (
            <span>{e.piracy}%</span>
          ) : (
            <Input
              autoFocus={true}
              value={editingRowData.piracy}
              onChange={(event) => {
                setEditingRowData((prevData) => ({
                  ...prevData,
                  piracy: Math.round(parseFloat(event.detail.value) || 0),
                }));
              }}
            />
          )}
        </>
      ),
    },
    {
      header: "Sort Order",
      id: "order",
      sortingField: "order",
      cell: (e) => (
        <>
          {editingRow !== e.id ? (
            <span>{e.order}</span>
          ) : (
            <Input
              autoFocus={true}
              value={editingRowData.order}
              onChange={(event) => {
                setEditingRowData((prevData) => ({
                  ...prevData,
                  order: Math.round(parseFloat(event.detail.value) || 0),
                }));
              }}
            />
          )}
        </>
      ),
    },
    {
      header: "",
      id: "actions",
      cell: (e) => (
        <>
          {editingRow !== e.id && (
            <Button
              iconName="edit"
              variant="primary"
              onClick={() => handleRowEdit(e)}
            />
          )}
          {editingRow === e.id && (
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="check"
                variant="primary"
                onClick={() => handleRowSave(e.id)}
              />
              <Button
                iconName="close"
                variant="normal"
                onClick={handleRowCancel}
              />
            </SpaceBetween>
          )}
        </>
      ),
    },
  ];

  function DeleteModal({ ranks, visible, onDiscard, onDelete }) {
    const deleteConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onDelete();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === deleteConsentText;

    const isMultiple = ranks.length > 1;
    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={isMultiple ? "Delete ranks" : "Delete rank"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onDelete}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {ranks.length > 0 && (
          <SpaceBetween size="m">
            {isMultiple ? (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {ranks.length}
                </Box>
                ? You can’t undo this action.
              </Box>
            ) : (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {ranks[0].id}
                </Box>
                ? You can’t undo this action.
              </Box>
            )}

            <Alert type="warning" statusIconAriaLabel="Warning">
              <span style={{ color: "red" }}>
                Proceeding with this action will delete the
                {isMultiple
                  ? " ranks with all their content "
                  : " rank with all its content "}
                and can affect related resources.
              </span>
            </Alert>

            <Box>
              To avoid accidental deletions, we ask you to provide additional
              written consent.
            </Box>

            <ColumnLayout columns={2}>
              <form onSubmit={handleDeleteSubmit}>
                <FormField
                  label={`To confirm this deletion, type "${deleteConsentText}".`}
                >
                  <Input
                    placeholder={deleteConsentText}
                    onChange={(event) => setDeleteInputText(event.detail.value)}
                    value={deleteInputText}
                    ariaRequired={true}
                  />
                </FormField>
              </form>
            </ColumnLayout>
          </SpaceBetween>
        )}
      </Modal>
    );
  }
  const deletingItemsSelected =
    selectedItems.filter((it) => it.state === "deleting").length > 0;
  return (
    <>
      <AppLayout
        stickyNotifications
        toolsHide
        headerSelector="#header"
        contentType="table"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        content={
          <>
            <CustomFlashBar />
            <RankingTable
              loading={settingsNbcuData.loading && !settingsNbcuData.completed}
              loadingText={"Resource Loading..."}
              data={settingsNbcuData.data}
              onSelectionChange={(d) =>
                setSelectedItems(d.detail.selectedItems)
              }
              selectedItems={selectedItems}
              columnDefinitions={columnDefinitions}
              onSubmit={() => { }}
              enableEdit={canEdit}
              actionsButton={
                <SpaceBetween direction="horizontal" size="l">
                  <Button
                    variant="primary"
                    onClick={() =>
                      navigate("/settings/nbcu-weightage/ranking/create")
                    }
                  >
                    Create
                  </Button>
                  <Button onClick={() => setCanEdit(true)}> Edit </Button>
                  <Button
                    disabled={
                      selectedItems.length === 0 || deletingItemsSelected
                    }
                    onClick={() => setOpenRankDeleteModel(true)}
                  >

                    Delete
                  </Button>
                </SpaceBetween>
              }
            />
          </>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
      <DeleteModal
        visible={openRankDeleteModel}
        ranks={selectedItems}
        onDelete={(e) => {
          if (selectedItems.length > 0) {
            roleDelete(selectedItems.map((m) => m.id));
          }
        }}
        onDiscard={(e) => {
          setSelectedItems([]);
          setOpenRankDeleteModel(false);
        }}
      />
    </>
  );
};
