import React from "react";
import {
  ColumnLayout,
  Link,
  Box,
  SpaceBetween,
  Container,
  ExpandableSection,
} from "@cloudscape-design/components";

const sections = [
  { title: "Cast", key: "cast", columns: 4 },
  { title: "Director", key: "director", columns: 3 },
  { title: "Writer", key: "writer", columns: 3 },
  { title: "Producer", key: "producer", columns: 3 },
];

const CastAndCrewComponent = ({ itemData }) => {
  const renderPerson = (person) => {
    const [, name, job] = person.split(":");
    return (
      <div key={person}>
        <Link href="#" variant="primary">{name}</Link>
        {job && <span className="text-slate-500"> ({job})</span>}
      </div>
    );
  };

  const renderItemCategory = (title, items, columns = 1) => {
    const chunkArray = (arr, size) =>
      Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
        arr.slice(i * size, i * size + size)
      );

    const itemChunks = columns > 1 ? chunkArray(items, Math.ceil(items.length / columns)) : [items];

    return (
      <ExpandableSection 
        defaultExpanded
        variant="container"
        headerText={title}>
        {items.length === 0 ? (
          <div className="text-center text-gray-500">No data available</div>
        ) : (
          <ColumnLayout columns={columns} variant="text-grid">
            {itemChunks.map((chunk, index) => (
              <div key={index}>
                {chunk.map(renderPerson)}
              </div>
            ))}
          </ColumnLayout>
        )}
      </ExpandableSection>
    );
  };

  return (
    <SpaceBetween direction="vertical" size="s">
      {sections.map(({ title, key, columns }) =>
        renderItemCategory(title, itemData[key] || [], columns)
      )}
    </SpaceBetween>
  );
};

export default CastAndCrewComponent;
