import {
  Button,
  Icon,
  Multiselect,
  Select,
  SpaceBetween,
  TextFilter,
} from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { EmptyGrid } from "../../../../../components/grid/EmptyGrid";
import TableLegend from "../../../../../components/table/TableLegend";
import { SERVICES, TYPES, VERTICALS } from "../../../../../config-global";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import axiosInstance from "../../../../../utils/axios";
import { CHART_LABELS } from "../../../../platforms/hooks/useGridConfig";
import {
  CATEGORIES,
  getCategories,
} from "../../../../platforms/utils/categoryUtils";
import { FiltersDrawer } from "../../../../ranking/components/FiltersDrawer";
import { DEFAULT_WEIGHTS_OBJECTS } from "../../../../ranking/constants";
import { useFilterQuery } from "../../../../ranking/hooks/useFilterQuery";
import { CompareContainer } from "../CompareContainer";
import { getNonOTTTitlesData } from "./api/request";
import { DEFAULT_FILTER_OBJECTS } from "./filters";
import { useFetchServerSideGridData } from "./hooks/useFetchServerSideGridData";
import { useGridConfig } from "./hooks/useGridConfig";

const verticalOptions = [
  { label: "Movies", value: VERTICALS.MOVIES },
  { label: "Series", value: VERTICALS.SERIES },
];

const additionalColumns = {
  [VERTICALS.MOVIES]: [
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        label !== "OTT" &&
        !label.toLowerCase().includes("trend") &&
        (getCategories(label)?.includes(CATEGORIES.MOVIES) ||
          getCategories(label)?.includes(CATEGORIES.PLATFORMDATA)),
    ),
    CHART_LABELS.TREND,
  ],
  [VERTICALS.SERIES]: [
    ...Object.values(CHART_LABELS).filter(
      (label) =>
        label !== "OTT" &&
        !label.toLowerCase().includes("trend") &&
        (getCategories(label)?.includes(CATEGORIES.SERIES) ||
          getCategories(label)?.includes(CATEGORIES.PLATFORMDATA)),
    ),
    CHART_LABELS.TREND,
  ],
};

export const NonOTTTitleComponent = ({
  setDrawers,
  setSplitPanel,
  setSplitPanelStatus,
  flashbarRef,
}) => {
  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.DASHBOARDS, module: "nonott" };

  const gridRef = useRef(null);
  const filterDrawerRef = useRef(null);

  const [selectedVertical, setSelectedVertical] = useState<any>(
    verticalOptions[1],
  );
  const [paginationPageSize, setPaginationPageSize] = useState<number>(20);
  const [filterText, setFilterText] = useState<string>("");
  const [filterObject, setFilterObject] = useState(
    DEFAULT_FILTER_OBJECTS[selectedVertical.value],
  );
  const [selectedColumns, setSelectedColumns] = useState<any>(
    [
      CHART_LABELS.WIKIPEDIA_PAGE_VIEWS,
      CHART_LABELS.YOUTUBE_VIEWS,
      CHART_LABELS.IMDB_VOTES,
      CHART_LABELS.PIRACY_DOWNLOADS,
      CHART_LABELS.TREND,
    ].map((x) => ({ label: x, value: x })),
  );
  const [selectedIpIds, setSelectedIpIds] = useState([]);

  const { theme } = useGetGridTheme();
  const { columnDefs, defaultColDef } = useGridConfig({
    additionalColumns: additionalColumns[selectedVertical.value],
    selectedColumns,
  });
  const filterQuery = useFilterQuery(filterObject);

  const handleExport = async () => {
    flashbarRef?.current?.setFlashbarMessage(
      "info",
      `${moment().format("YYYY-MM-DD HH:mm:ss")}. Generating report.`,
      null
    );

    try {
      const filepath =
        selectedVertical.value === VERTICALS.MOVIES
          ? "platform/nonott_movies.parquet"
          : "platform/nonott_series.parquet";

      const response = await axiosInstance.post("/export", {
        filepath,
        include: [
          "ip",
          "release_date",
          "countries",
          "genre",
          "cTags",
          "franchise",
          "production",
          "wiki_views",
          "youtube_views",
          "imdb_votes",
          "imdb_rating",
          "rt_votes",
          "rt_rating",
          "piracy_downloads",
          "daily_rank",
          "weekly_rank",
          "monthly_rank",
        ],
        exclude: [],
      });

      if (response.data) {
        const presignedUrl = response.data;

        const link = document.createElement("a");
        link.href = presignedUrl;
        link.setAttribute(
          "download",
          filepath.split("/").pop() || "export.parquet",
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        flashbarRef?.current?.setFlashbarMessage(
          "success",
          `${moment().format("YYYY-MM-DD HH:mm:ss")}. Report generated successfully.`,
          null,
        );
      }
    } catch (error) {
      console.error("Export failed:", error);
      flashbarRef?.current?.setFlashbarMessage(
        "error",
        `${moment().format("YYYY-MM-DD HH:mm:ss")}. Report generation failed. Please try again.`,
        null,
      );
    }
  };

  useFetchServerSideGridData({
    filter: filterQuery,
    gridRef,
    vertical: selectedVertical.value,
    fetchMethod: getNonOTTTitlesData,
    disabled: false,
  });

  const filterDrawerConfig = useMemo(
    () => ({
      id: "filterDrawer",
      content: (
        <FiltersDrawer
          ref={filterDrawerRef}
          prefsApiParams={apiParams}
          filterObject={filterObject}
          setFilterObject={setFilterObject}
          vertical={selectedVertical.value}
          defaultFilterObjects={DEFAULT_FILTER_OBJECTS}
        />
      ),
      trigger: {
        iconName: "filter",
        iconSvg: <Icon name="filter" />,
      },
      ariaLabels: {
        drawerName: "My Drawer",
        closeButton: "Close",
        triggerButton: "Open",
        resizeHandle: "Resize",
      },
      resizable: true,
      defaultSize: 290,
      badge: !_.isEqual(
        filterObject,
        DEFAULT_FILTER_OBJECTS[selectedVertical.value],
      ),
    }),
    [selectedVertical, filterObject],
  );

  const compareContainer = useMemo(
    () => (
      <CompareContainer
        vertical={selectedVertical.value}
        ipIds={selectedIpIds}
        dateRange={{
          startDate: moment().subtract(1, "month").format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        }}
        weightsObject={DEFAULT_WEIGHTS_OBJECTS[selectedVertical.value]}
        onParametersChanged={({ detail }) => {
          const newTitleIds = detail.parameters[0].titleIds;
          if (
            !_.isEqual(newTitleIds.sort(), selectedIpIds.sort()) &&
            !detail.titlesLoading
          ) {
            setSelectedIpIds(newTitleIds);
            if (gridRef.current) {
              gridRef.current.api.forEachNode((node) => {
                if (newTitleIds.includes(node?.data?.ip_id)) {
                  node.setSelected(true);
                } else {
                  node.setSelected(false);
                }
              });
            }
          }
        }}
      />
    ),
    [selectedVertical, selectedIpIds],
  );

  useEffect(() => {
    setDrawers([filterDrawerConfig]);
  }, [filterDrawerConfig]);

  useEffect(() => {
    setSplitPanel(compareContainer);
  }, [compareContainer]);

  useEffect(() => {
    if (filterDrawerRef.current) {
      filterDrawerRef.current.setCurrentFilterObject(
        DEFAULT_FILTER_OBJECTS[selectedVertical.value],
      );
    }
  }, [filterDrawerRef, selectedVertical]);

  useEffect(() => {
    const advancedFilter = filterObject.advancedFilter;
    const f = gridRef.current?.api?.getAdvancedFilterModel();
    if (!_.isEqual(f, filterObject.advancedFilter)) {
      gridRef.current?.api?.setAdvancedFilterModel(advancedFilter);
    }
    if (filterObject.titleSearchQuery && filterObject.titleSearchQuery !== filterText) {
      setFilterText(filterObject.titleSearchQuery);
    }
  }, [filterObject]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <div className="flex justify-between items-center">
        <div className="flex gap-2">
          <Select
            selectedOption={selectedVertical}
            onChange={({ detail }) => {
              setSelectedVertical(detail.selectedOption);
              setFilterObject(
                DEFAULT_FILTER_OBJECTS[detail.selectedOption.value],
              );
            }}
            expandToViewport
            controlId="select-vertical"
            placeholder="Category"
            options={verticalOptions}
          />
          <Multiselect
            selectedOptions={selectedColumns}
            onChange={({ detail }) =>
              setSelectedColumns(detail.selectedOptions)
            }
            options={additionalColumns[selectedVertical.value].map(
              (column) => ({ label: column, value: column }),
            )}
            placeholder="Additional columns"
            selectedAriaLabel="Selected"
            hideTokens
          />
        </div>
        <div className="flex gap-2">
          <TextFilter
            filteringText={filterText}
            filteringPlaceholder="Search titles"
            filteringAriaLabel="Filter titles"
            onChange={({ detail }) => setFilterText(detail.filteringText)}
            onDelayedChange={({ detail }) =>
              setFilterObject({
                ...filterObject,
                titleSearchQuery: detail.filteringText,
              })
            }
          />
          <Button variant="primary" iconName="download" onClick={handleExport}>
            Export
          </Button>
        </div>
      </div>
      <div style={{ height: "75vh" }} className={theme}>
        <AgGridReact
          ref={gridRef}
          paginationPageSize={paginationPageSize}
          pagination={true}
          paginationPageSizeSelector={[20, 50, 100]}
          onPaginationChanged={(params) => {
            const size = params.api.paginationGetPageSize();
            if (size !== paginationPageSize) setPaginationPageSize(size);
            params.api.forEachNode((node) => {
              if (selectedIpIds.includes(node?.data?.ip_id)) {
                node.setSelected(true);
              } else {
                node.setSelected(false);
              }
            });
          }}
          onFilterChanged={(params) => {
            const f = params?.api?.getAdvancedFilterModel();
            if (!_.isEqual(f, filterObject.advancedFilter)) {
              setFilterObject({ ...filterObject, advancedFilter: f });
            }
          }}
          defaultColDef={defaultColDef}
          loadingOverlayComponent={CustomLoadingOverlay}
          noRowsOverlayComponent={EmptyGrid}
          suppressDragLeaveHidesColumns={false}
          enableAdvancedFilter={true}
          tooltipShowDelay={100}
          columnDefs={columnDefs as any}
          rowModelType={"serverSide"}
          blockLoadDebounceMillis={100}
          includeHiddenColumnsInAdvancedFilter={true}
          onRowSelected={(params) => {
            if (params.source === "api") {
              return;
            }
            const rowIpId = params.data.ip_id;
            const selectedIpIdsCopy = [...selectedIpIds];
            if (params.node.isSelected()) {
              if (selectedIpIdsCopy.includes(rowIpId)) {
                return;
              }
              selectedIpIdsCopy.push(rowIpId);
              setSplitPanelStatus(true);
            } else {
              const index = selectedIpIdsCopy.indexOf(rowIpId);
              if (index === -1) {
                return;
              }
              selectedIpIdsCopy.splice(index, 1);
            }
            setSelectedIpIds(selectedIpIdsCopy);
          }}
        />
      </div>
      <TableLegend />
    </SpaceBetween>
  );
};

export default NonOTTTitleComponent;
