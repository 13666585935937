import {
  ButtonDropdown,
  Container,
  FormField,
  Header,
  Link,
  Select,
  SpaceBetween,
  Spinner,
  TextFilter,
} from "@cloudscape-design/components";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { CustomPagination } from "../../../components/CustomPagination";
import { genreMapping } from "../../../components/GenreMapping";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { useGetGenericPreference } from "../../../services/generic/hooks/useGetGenericPreference";
import { usePostAndGetGenericPreference } from "../../../services/generic/hooks/usePostAndGetGenericPreference";
import createFlashMessage from "../../../utils/createFlashMessage";
import {
  generateNestedObject,
  getNestedObjectValue,
} from "../../../utils/helpers";
import shortenInteger from "../../../utils/shortenInteger";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import { useGetWikipediaLeaderBoardData } from "../api/hooks/useGetWikipediaLeaderBoardData";

const PREFERENCE_PATH = "wikipedia.leaderboard.preferences";

export const WikipediaContent = () => {
  const [dateRange, setDateRange] = useState(null);
  const [keyword, setKeyword] = useState("");
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  const [selectedIps, setSelectedIps] = useState([]);
  const [selectedOptionSelectIPList, setSelectedOptionSelectIPList] = useState({
    label: "Pantheon",
    value: "Pantheon",
  });
  const [selectedOptionVertical, setSelectedOptionVertical] = useState(null);
  const [selectedOptionGenre, setSelectedOptionGenre] = useState(null);
  const [genreOptions, setGenreOptions] = useState([]);
  const [isGenreDisabled, setIsGenreDisabled] = useState(true);
  const [isPreferenceLoaded, setIsPreferenceLoaded] = useState(false);

  const indexOfLastItem = currentPageIndex * 48;
  const indexOfFirstItem = indexOfLastItem - 48;

  const checkAndInsertIps = (ips) => {
    const index = selectedIps.findIndex((ip) => ip.ip_id === ips.ip_id);
    if (index === -1) {
      setSelectedIps((prevIps) => [...prevIps, ips]);
    } else {
      const newSelectedIps = [...selectedIps];
      newSelectedIps.splice(index, 1);
      setSelectedIps(newSelectedIps);
    }
  };

  useEffect(() => {
    if (selectedOptionVertical && selectedOptionVertical.value) {
      const genresForVertical = Object.entries(genreMapping)
        .filter(([_, verticals]) =>
          verticals.includes(selectedOptionVertical.value),
        )
        .map(([genre]) => ({ label: genre, value: genre }));
      setGenreOptions([{ label: "All", value: "" }, ...genresForVertical]);
      setIsGenreDisabled(false);
    } else {
      setGenreOptions([]);
      setIsGenreDisabled(true);
    }
  }, [selectedOptionVertical]);

  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    setCurrentPageIndex(1);
  }, [
    dateRange,
    selectedOptionSelectIPList,
    selectedOptionVertical,
    selectedOptionGenre,
    keyword,
  ]);

  const {
    data: leaderboardData,
    isLoading: isLoadingLeaderboardData,
    error: leaderboardDataError,
  } = useGetWikipediaLeaderBoardData({
    dateRange,
    selectedOptionSelectIPList,
    selectedOptionVertical,
    selectedOptionGenre,
    keyword,
    currentPageIndex,
    enabled: isPreferenceLoaded,
    onError: () => {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: leaderboardDataError.message,
        }),
      );
    },
  });

  const { data: genericPreference } = useGetGenericPreference({
    onSettled: () => setIsPreferenceLoaded(true),
    onSuccess: (data) => {
      onSuccessPreference(data?.data);
    },
  });

  const { postAndGetGenericPreference } = usePostAndGetGenericPreference({
    onSuccess: (data) => {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: data?.data?.message,
        }),
      );
      setTimeout(cleanAllFlashMessage, 3000);
    },
    onError: (error) => {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: error || "Failed to save preference ",
        }),
      );
      setTimeout(cleanAllFlashMessage, 3000);
    },
  });

  const saveResponse = () => {
    let payload = {
      unit: dateRange?.unit ?? "",
      amount: dateRange?.amount ?? "",
      dateType: dateRange?.type ?? "absolute",
      ip_list: selectedOptionSelectIPList ?? "",
      vertical: selectedOptionVertical ?? "",
      genre: selectedOptionGenre ?? "",
      keyword: keyword ?? "",
      size: 48,
      from: indexOfFirstItem,
    };
    if (payload.dateType === "absolute") {
      payload.gte = dateRange?.startDate
        ? moment(dateRange.startDate).unix()
        : moment().subtract(15, "days").unix();
      payload.lte = dateRange?.endDate
        ? moment(dateRange.endDate).unix()
        : moment().unix();
    }

    const newGlobalPreferences = generateNestedObject(
      { ...genericPreference },
      PREFERENCE_PATH,
      payload,
    );

    postAndGetGenericPreference(newGlobalPreferences);
  };

  const onSuccessPreference = useCallback((data) => {
    const pref = getNestedObjectValue(data, PREFERENCE_PATH);

    if (!pref) return;

    const {
      keyword,
      ip_list,
      genre,
      vertical,
      dateType,
      unit,
      amount,
      gte,
      lte,
    } = pref;

    setKeyword(keyword);
    setSelectedOptionSelectIPList(ip_list);
    setSelectedOptionGenre(genre);
    setSelectedOptionVertical(vertical);

    if (!dateType) return;

    let newDateRange = {
      type: dateType ?? "absolute",
      unit: unit ?? "",
      amount: amount ?? "",
    };

    if (newDateRange.type === "absolute") {
      newDateRange.startDate = moment(gte * 1000).format("YYYY-MM-DD");
      newDateRange.endDate = moment(lte * 1000).format("YYYY-MM-DD");
    } else if (newDateRange.type === "relative") {
      newDateRange.startDate = moment()
        .subtract(amount, unit)
        .format("YYYY-MM-DD");
      newDateRange.endDate = moment().format("YYYY-MM-DD");
    }

    setDateRange(newDateRange);
  }, []);

  const getDateRange = useCallback(() => {
    return dateRange
      ? dateRange?.type === "relative"
        ? {
            ...dateRange,
            startDate: moment(dateRange.startDate),
            endDate: moment(dateRange.endDate),
          }
        : dateRange
      : {
          type: "absolute",
          startDate: moment()
            .subtract(15, "days")
            .startOf("day")
            .format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        };
  }, [dateRange]);

  return (
    <Container
      style={{ height: "87vh" }}
      fitHeight
      header={
        <Header
          variant="h3"
          actions={
            <ButtonDropdown
              onItemClick={({ detail }) => {
                if (detail.id === "df") {
                  saveResponse();
                }
              }}
              expandToViewport
              items={[
                { text: "Export", id: "ex", disabled: true },
                { text: "Set as Default", id: "df" },
              ]}
            >
              Actions
            </ButtonDropdown>
          }
        >
          Worldwide Leaderboard
        </Header>
      }
    >
      <div className="flex w-full justify-between items-center">
        <div>
          <TextFilter
            filteringPlaceholder="Filter by IP"
            filteringText={keyword}
            onChange={({ detail }) => {
              setKeyword(detail.filteringText);
            }}
          />
        </div>
        <div>
          <SpaceBetween direction="horizontal" size="xs">
            <FormField description="Date range selector">
              <RangeDateSelector
                defaults={getDateRange()}
                onChange={(e) => {
                  if (e.type === "relative") {
                    setDateRange({
                      ...e,
                      startDate: moment(e.startDate).format("YYYY-MM-DD"),
                      endDate: moment(e.endDate).format("YYYY-MM-DD"),
                    });
                  } else {
                    setDateRange(e);
                  }
                }}
              />
            </FormField>

            <FormField description="IP list">
              <Select
                selectedOption={selectedOptionSelectIPList}
                onChange={({ detail }) => {
                  setSelectedOptionSelectIPList(detail.selectedOption);
                }}
                filteringType="auto"
                expandToViewport
                controlId="select-iplist"
                options={[
                  {
                    label: "NBCUniversal",
                    value: "nbcu",
                  },
                  {
                    label: "Pantheon",
                    value: "Pantheon",
                  },
                ]}
              />
            </FormField>
            <FormField description="Category">
              <Select
                selectedOption={selectedOptionVertical}
                expandToViewport
                onChange={({ detail }) => {
                  setSelectedOptionVertical(detail.selectedOption);
                }}
                controlId="select-vertical"
                placeholder="Category"
                options={[
                  { label: "All", value: "" },
                  { label: "Movies", value: "Movies" },
                  { label: "Television", value: "Television" },
                  { label: "Games", value: "Gaming" },
                ]}
              />
            </FormField>
            <FormField description="Genre">
              <Select
                selectedOption={selectedOptionGenre}
                onChange={({ detail }) => {
                  setSelectedOptionGenre(detail.selectedOption);
                }}
                expandToViewport
                controlId="select-genre"
                placeholder="Genre"
                disabled={isGenreDisabled}
                options={genreOptions}
              />
            </FormField>
          </SpaceBetween>
        </div>
      </div>
      <div className="flex flex-wrap justify-center items-center">
        {isLoadingLeaderboardData && (
          <div className="flex flex-row justify-center items-center">
            <Spinner size="large" />
          </div>
        )}

        {(leaderboardData?.data ?? []).map((item) => {
          let imageUrl = "-";
          if (
            item.ip_id.startsWith("film-") ||
            item.ip_id.startsWith("series-")
          ) {
            imageUrl = `https://image.tmdb.org/t/p/w154/${item.image_url}`;
          } else if (item.ip_id.startsWith("game-")) {
            imageUrl = `https://images.igdb.com/igdb/image/upload/t_cover_big/${item.image_url}.png`;
          }

          return (
            <div className="inline-block relative group mt-2" key={item.ip_id}>
              <div
                className={`w-32 h-52 max-w-xs overflow-hidden rounded-lg shadow-md bg-optionCard hover:shadow-xl transition-shadow duration-300 ease-in-out border-2 border-slate-600 m-1`}
              >
                <div className="flex justify-center items-center">
                  <Link
                    href={
                      item.ip_id
                        ? `${window.location.origin}/item/${item.ip_id}`
                        : undefined
                    }
                  >
                    <img
                      src={imageUrl}
                      alt={item.IP}
                      className={`w-36 rounded-md`}
                    />
                    <div className="absolute inset-0 rounded-md items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity border-2 border-slate-600 bg-black bg-opacity-90 p-4">
                      <span className="text-white">{item.ip}</span>
                    </div>
                  </Link>

                  <div className="absolute top-2 right-2 p-3 h-2 w-2 flex items-center justify-center bg-black bg-opacity-50 rounded-full text-yellow-500 font-bold text-md">
                    {item.vertical.charAt(0)}
                  </div>

                  <div
                    className={`absolute bottom-2 left-2 bg-sidebarLow border-2 border-slate-600 rounded-full px-2.5 py-1`}
                  >
                    <span className="text-amber-500 font-bold flex flex-row items-center">
                      <p>#</p> {item.rank}
                    </span>
                  </div>

                  <button
                    type="button"
                    onClick={() => checkAndInsertIps(item)}
                    className={`absolute bottom-2 right-2 rounded-full px-3 py-1.5 text-sm font-semibold border-2 border-slate-600 shadow-sm ${
                      selectedIps.filter((ip) => ip.ip_id === item.ip_id)
                        .length > 0
                        ? "bg-blue-500 text-white"
                        : "bg-sidebarLow text-white"
                    } hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600`}
                  >
                    {`${shortenInteger(item.views)}`}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <CustomPagination
        currentPage={currentPageIndex}
        totalPages={
          leaderboardData?.total ? Math.ceil(leaderboardData?.total / 48) : 1
        }
        onNext={() => setCurrentPageIndex(currentPageIndex + 1)}
        onPrevious={() => setCurrentPageIndex(currentPageIndex - 1)}
        onPageClick={(page) => setCurrentPageIndex(page)}
      />
    </Container>
  );
};
