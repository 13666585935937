import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import { useGridConfig } from "../../../../../platforms/hooks/useGridConfig";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import { useFetchServerSideGridData } from "../../api/hooks/useFetchServerSideGridData";
import { useSetGridAdvancedFilterTheme } from "../../../../../../hooks/UseTheme/useSetGridAdvancedFilterTheme";
import { getGlobalTrendsData } from "../../../../../platforms/global/api/request";
import { additionalPlatformColumns, corePlatformColumns, defaultColumn } from "../../../../../platforms/global/utils/gridConfig";

export const TrendingTable = ({
  vertical,
  selectedIpIds,
  setSelectedIpIds,
  setSplitPanelStatus,
  filterQuery,
  weightageValues,
  dateRange,
  advancedFilter,
  setAdvancedFilter,
}) => {

  const { theme } = useGetGridTheme();
  const gridRef = useRef(null);

  const [paginationPageSize, setPaginationPageSize] = useState(50);
  const [disabled, setDisabled] = useState(true);
  const [selectedAdditionalColumns, setSelectedAdditionalColumns] = useState([{ label: defaultColumn, value: defaultColumn }]);

  const gridOptions = useGridConfig(
    selectedAdditionalColumns,
    corePlatformColumns,
    additionalPlatformColumns,
    vertical,
  )

  useSetGridAdvancedFilterTheme();
  useFetchServerSideGridData({
    filter: filterQuery,
    gridRef,
    vertical,
    fetchMethod: getGlobalTrendsData,
  });

  useEffect(() => {
    if (disabled) {
      setTimeout(() => setDisabled(false), 100);
    }
  }, []);

  return (
    <div style={{ width: "100%", height: "70vh" }} className={theme}>
      <AgGridReact
        ref={gridRef}
        enableAdvancedFilter={true}
        paginationPageSize={paginationPageSize}
        pagination={true}
        paginationPageSizeSelector={[20, 50, 100]}
        onPaginationChanged={(params) => {
          const size = params.api.paginationGetPageSize();
          if (size !== paginationPageSize) setPaginationPageSize(size);
          params.api.forEachNode(node => {
            if (selectedIpIds.includes(node?.data?.ip_id)) {
              node.setSelected(true);
            } else {
              node.setSelected(false);
            }
          });
        }}
        rowModelType={"serverSide"}
        blockLoadDebounceMillis={100}
        rowSelection="multiple"
        onFilterChanged={(params) => {
          const f = params?.api?.getAdvancedFilterModel();
          setAdvancedFilter(f);
        }}
        onRowSelected={(params) => {
          const rowIpId = params.data.ip_id;
          const selectedIpIdsCopy = [...selectedIpIds];
          if (params.node.selected) {
            if (selectedIpIdsCopy.includes(rowIpId)) {
              return;
            }
            selectedIpIdsCopy.push(rowIpId);
            setSplitPanelStatus(true);
          } else {
            const index = selectedIpIdsCopy.indexOf(rowIpId);
            if (index === -1) {
              return;
            }
            selectedIpIdsCopy.splice(index, 1);
          }
          setSelectedIpIds(selectedIpIdsCopy);
        }}
        {...gridOptions}
      />
    </div>
  );
};