import React, { useMemo } from "react";
import { Box, Link } from "@cloudscape-design/components";
import { VERTICALS } from "../../../../../../config-global";
import axiosInstance from "../../../../../../utils/axios";
import { filterObjectToSqlQuery } from "../../../../../../utils/filters/filterUtils";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import renderTrend from "../../../../../../utils/trendIcon";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../../../../../../components/PantheonLoading";
import { useQuery } from "react-query";
import moment from "moment";

const globalUrlMap: Record<string, string> = {
  [VERTICALS.MOVIES]: "movies",
  [VERTICALS.SERIES]: "series",
  [VERTICALS.GAMES]: "gaming",
};

type FilteredTrendsHeaderProps = {
  title: string;
};

export function FilteredTrendsHeader({ title }: FilteredTrendsHeaderProps) {
  return (
    <Box variant="h4" textAlign="center">
      {title}
    </Box>
  );
}

type FilteredTrendsFooterProps = {
  filterObject: object;
  vertical: string;
};

export function FilteredTrendsFooter({ filterObject, vertical }: FilteredTrendsFooterProps) {
  const filterUrlEncoded = encodeURIComponent(JSON.stringify(filterObject));
  const targetDateUtc = moment.utc().subtract(2, "day").startOf("day").format("YYYY-MM-DD");
  const href = `/dashboards/globalranking/${globalUrlMap[vertical]}?tab=table&startDate=${targetDateUtc}&endDate=${targetDateUtc}&filter=${filterUrlEncoded}`;
  return (
    <Box>
      <Link fontSize="inherit" href={href} variant="secondary">
        View All
      </Link>
    </Box>
  );
}

const fetchData = async (vertical: string, filterObject: any, sort: string) => {
  const sqlConditions = filterObjectToSqlQuery(filterObject, "s");

  const response = await axiosInstance.request({
    url: `/pql?key=platform/global_${globalUrlMap[vertical]}&file_type=P&sort=${sort}:asc&size=10`,
    method: "POST",
    data: {
      "sql": `SELECT s.ip_id, s.ip, s.daily_rank, s.weekly_rank, s.daily_rank_change, s.weekly_rank_change, s.daily_rank_trend, s.weekly_rank_trend FROM s3object s WHERE ${sqlConditions}`,
    },
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error("Error fetching Data");
  }

  return response.data;
};

type FilteredTrendsContentProps = {
  vertical: string;
  filterObject: any;
  sort: string;
};

export function FilteredTrendsContent({ vertical, filterObject, sort }: FilteredTrendsContentProps) {
  const { theme } = useGetGridTheme();

  const { data, isLoading, error } = useQuery(
    ["trendsData", vertical, filterObject, sort],
    () => fetchData(vertical, filterObject, sort),
    {}
  );

  const renderDailyRank = (params: any) =>
    renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend");

  const renderWeeklyRank = (params: any) =>
    renderTrend(params, "weekly_rank", "weekly_rank_change", "weekly_rank_trend");

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        headerName: "Rank",
        maxWidth: 90,
        cellDataType: "number",
        minWidth: 90,
        sortable: true,
        cellRenderer: renderDailyRank,
        valueGetter: (params: any) => parseInt(params.data.weekly_rank),
      },
      /*{
        headerName: "7-Day Rank",
        maxWidth: 90,
        cellDataType: "number",
        minWidth: 90,
        sortable: true,
        cellRenderer: renderWeeklyRank,
        valueGetter: (params: any) => parseInt(params.data.weekly_rank),
      },*/
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 100,
        cellRenderer: (params: any) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          return (
            <Link href={`/item/${ipId}`}>
              <span>{params.data.ip}</span>
            </Link>
          );
        },
      },
    ];
    return allColumns;
  }, []);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    []
  );

  return (
    <>
      <hr className="border-slate-600 border-t-[1px]" />
      <div className={`${theme} compact`} style={{ height: "99.5%" }}>
        <AgGridReact
          rowData={data || []}
          defaultColDef={defaultColDef}
          suppressDragLeaveHidesColumns={true}
          loading={isLoading}
          loadingOverlayComponent={CustomLoadingOverlay}
          columnDefs={columnDefs}
          rowHeight={29.4}
          suppressContextMenu={true}
        />
      </div>
    </>
  );
}
