import React, { forwardRef, ReactElement, useEffect, useImperativeHandle, useState } from "react";
import { Autosuggest } from "@cloudscape-design/components";
import { useGetTitleSearchData } from "./api/hooks/useGetTitleSearchData";
import { convertData } from "../../utils";

interface TitleSelectorProps {
  onSelectedItemChanged: (item: any) => void;
  defaultSelectedTitle?: any;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  onFocus?: () => void;
}

const TitleSelector = forwardRef<ReactElement | null, TitleSelectorProps>(({ 
  onSelectedItemChanged, 
  defaultSelectedTitle=null, 
  searchQuery, 
  setSearchQuery, 
  onFocus=null,
}, ref) => {

  const [ searchResults, setSearchResults ] = useState([]);
  const [ selectedItem, setSelectedItem ] = useState(null);
  const [ queryString, setQueryString ] = useState("");

  const {
    data: titleSearchData,
    isLoading,
  } = useGetTitleSearchData({ queryString: queryString });

  useImperativeHandle(ref, () => ({
    setSearchResults,
  } as any), []);

  useEffect(() => {
    if (defaultSelectedTitle) {
      const convertedItems = convertData(defaultSelectedTitle);
      setSelectedItem(convertedItems[0]);
      onSelectedItemChanged(convertedItems[0]);
    }
  }, [defaultSelectedTitle]);

  useEffect(() => {
    if (titleSearchData) {
      setSearchResults(titleSearchData);
    }
  }, [titleSearchData]);

  return (
    <Autosuggest
      options={(searchResults && searchResults?.length > 0) ? convertData(searchResults) : []}
      value={searchQuery}
      onChange={({ detail }) => {
        setSearchQuery(detail.value);
      }}
      onLoadItems={({ detail }) => setQueryString(detail.filteringText)}
      onBlur={() => setSearchResults([])}
      onFocus={() => onFocus && onFocus()}
      onSelect={({ detail }) => {
        setSelectedItem(detail.selectedOption);
        onSelectedItemChanged(detail.selectedOption);
        setSearchQuery(detail.selectedOption.label);
      }}
      statusType={isLoading ? "loading" : "finished"}
      loadingText="Loading"
      placeholder="Search for a title"
      filteringType="manual"
    />
  );
});

export default TitleSelector;