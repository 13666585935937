import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/20/solid";
import { uniqueId } from "lodash";

export const CustomPagination = ({ currentPage, totalPages, onNext, onPrevious , onPageClick}) => {
  const pageNumbersToShow = 5;  

  const firstPage = Math.max(1, currentPage - pageNumbersToShow);
  const lastPage = Math.min(totalPages, currentPage + pageNumbersToShow);

  return (
    <nav className="flex items-center justify-between border-t border-gray-600 px-4 sm:px-0 mt-6">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={onPrevious}
          disabled={currentPage === 1}
          className={`inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium ${
            currentPage === 1
              ? "text-gray-600"
              : "text-gray-300 hover:border-blue-400 hover:text-gray-100"
          }`}
        >
          <ArrowLongLeftIcon
            className={`mr-3 h-5 w-5 ${
              currentPage === 1 ? "text-gray-300" : "text-gray-400"
            }`}
            aria-hidden="true"
          />
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {firstPage > 1 && (
          <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-300">
            ...
          </span>
        )}

        {Array.from({ length: lastPage - firstPage + 1 }, (_, i) => (
          <a
            key={uniqueId()}
            href="#"
            onClick={() => onPageClick(firstPage + i)}
            className={`inline-flex items-center border-t-2 ${
              currentPage === firstPage + i
                ? "border-blue-400"
                : "border-transparent"
            } px-4 pt-4 text-sm font-medium ${
              currentPage === firstPage + i ? "text-blue-400" : "text-gray-300"
            }`}
          >
            {firstPage + i}
          </a>
        ))}

        {lastPage < totalPages && (
          <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-300">
            ...
          </span>
        )}
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          onClick={onNext}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-300 hover:border-blue-400 hover:text-gray-100"
        >
          Next
          <ArrowLongRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </button>
      </div>
    </nav>
  );
};

