import { useQuery } from "react-query";
import { YOUTUBE_TRENDS_QUERY_KEYS } from "../contants";
import { getYouTubeCategoryData } from "../request";

export const useGetYoutubeCategoryData = (date: string) => {
  return useQuery(
    [YOUTUBE_TRENDS_QUERY_KEYS.GET_YOUTUBE_CATEGORY_DATA, date],
    () => getYouTubeCategoryData(date),
    {
      enabled: !!date,
    }
  );
};
