import {
  AppLayout,
  BreadcrumbGroup,
  SideNavigation,
} from "@cloudscape-design/components";
import React, { createContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";
import CategoryDataMapping from "./data-mapping-page";

export const DataMappingContext = createContext(null);

const breadcrumbs = [
  { text: "Settings" },
  { text: "Data Mapping" },
  { text: "ID Management" },
];

function IdDataMappingIndex() {
  const [selectedVertical, setSelectedVertical] = useState({
    label: "All",
    value: "all",
  });

  return (
    <>
      <Helmet>
        <title>ID Management</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        ariaLabels={{ navigationClose: "close" }}
        navigationHide
        content={
          <DataMappingContext.Provider
            value={{ selectedVertical, setSelectedVertical }}
          >
            <CategoryDataMapping />
          </DataMappingContext.Provider>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default IdDataMappingIndex;
