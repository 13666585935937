import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../../../utils/axios';
const initialState = {
    data: [],
    loading: false,
    message: "",
    completed: false,
    hasError: false,
};

export const fetchSettingsNbcuData = createAsyncThunk(
    'settingsNbcuData/fetchSettingsNbcuData',
    async () => {
        const response = await axiosInstance.request({
            url: `/settings/nbcuweighting/list`,

            method: `GET`,
        });
        return response;
    }
);


const settingsNbcuDataSlice = createSlice({
    name: 'settingsNbcuData',
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchSettingsNbcuData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSettingsNbcuData.fulfilled, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.data = action.payload.data;
            })
            .addCase(fetchSettingsNbcuData.rejected, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.hasError = true;
                state.message = action.error.message;
            });

    },
});

const SettingsNbcuDataReducer = settingsNbcuDataSlice.reducer;

export default SettingsNbcuDataReducer;

export const settingsNbcuDataAction = settingsNbcuDataSlice.actions;