import React, { useEffect, useState } from "react";
import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  Flashbar,
  FormField,
  Header,
  Input,
  SideNavigation,
  SpaceBetween,
  Wizard,
} from "@cloudscape-design/components";
import Slider from "@mui/material/Slider";
import { navItems } from "../../../layouts/common/menu/side-menu";
import "./wizard.css";
import Chart from "react-google-charts";
import _ from "lodash";
import createFlashMessage from "../../../utils/createFlashMessage";
import {
  clearCreateNbcuSettingData,
  createNbcuSettingData,
  getSettingsNbcuData,
} from "../redux/actions/settings-nbcu-actions";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import CustomFlashBar from "../../common/CustomFlashBar";

const MAX_SLIDER_VALUE = 100;
const CreateRankPage = () => {
  const navigate = useNavigate();

  const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </div>
  );
  const [activeStepIndex, setActiveStepIndex] = React.useState(0);
  const createCreateNbcuSettingsData = useSelector(
    (state) => state.createCreateNbcuSettingsData
  );
  const [data, setData] = React.useState({
    name: null,
    order: 100,
    searchDemand: {
      value: 40,
      child: [
        { name: "gtrends", label: "Google Search Trends", value: 50 },
        { name: "wiki", label: "Wikipedia", value: 50 },
      ],
    },
    fanSites: {
      value: 20,
      child: [
        { name: "fandom", label: "Fandom", value: 20 },
        { name: "reddit", label: "Reddit", value: 20 },
        { name: "wattpad", label: "Wattpad", value: 20 },
        { name: "a03", label: "AO3", value: 20 },
        { name: "steam", label: "Steam", value: 20 },
      ],
    },
    contentConsumption: {
      value: 20,
      child: [
        { name: "youtube", label: "YouTube (Supply)", value: 20 },
        { name: "twitter", label: "X", value: 15 },
        { name: "tiktok", label: "TikTok", value: 15 },
        { name: "instagram", label: "Instagram", value: 15 },
        { name: "twitch", label: "Twitch", value: 15 },
        { name: "imdb", label: "IMDb", value: 20 }
      ],
    },
    piracy: {
      value: 20,
      child: [{ name: "piracy", label: "Piracy", value: 100 }],
    },
  });

  const breadcrumbs = [
    { text: "Settings" },
    { text: "NBCU Weightage" },
    { text: "Ranking", href: "/settings/nbcu-weightage/ranking" },
    { text: "Create Ranking", href: "" },
  ];

  useEffect(() => {
    if (
      createCreateNbcuSettingsData.completed &&
      !createCreateNbcuSettingsData.loading
    ) {
      getSettingsNbcuData();
      navigate("/settings/nbcu-weightage/ranking/");
    }
    return () => {
      clearCreateNbcuSettingData();
    };
  }, [createCreateNbcuSettingsData]);
  const options = {
    width: 250,
    height: 250,
    minorTicks: 5,
    animation: {
      duration: 1000,
      easing: "out",
    },
  };
  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);
  const handleChange = (index, newValue, row) => {
    const { child } = row;
    const newChild = [...child];
    let total = 0;

    newChild[index] = { ...newChild[index], value: Number(newValue) };

    newChild.forEach((item) => {
      total += Number(item.value);
    });

    if (total > 100) {
      let diff = total - 100;
      let i = child.length;

      while (diff > 0) {
        if (newChild[i] && newChild[i].value > diff) {
          newChild[i] = {
            ...newChild[i],
            value: Number(newChild[i].value) - diff,
          };
          diff = 0;
        } else {
          if (newChild[i]) {
            diff -= Number(newChild[i].value);
            newChild[i] = { ...newChild[i], value: 0 };
          }
        }
        i--;
        if (i < 0) {
          break;
        }
      }
    }
    return newChild;
  };


  function categoryValueCheck() {
    const val1 = data.searchDemand ? parseInt(data.searchDemand.value) : 0;
    const val2 = data.contentConsumption
      ? parseInt(data.contentConsumption.value)
      : 0;
    //const val3 = data.cpMarketplace ? parseInt(data.cpMarketplace.value) : 0;
    const val4 = data.fanSites ? parseInt(data.fanSites.value) : 0;
    const val5 = data.piracy ? parseInt(data.piracy.value) : 0;
    const total = val1 + val2 + val4 + val5;
    return total > MAX_SLIDER_VALUE || total < MAX_SLIDER_VALUE ? true : false;
  }

  const handleCancel = () => {
    navigate("/settings/nbcu-weightage/ranking");
  };

  const sumChildValues = (child) => _.sumBy(child, (e) => parseInt(e.value));

  const showErrorFlashMessage = (message) => {
    addMessageToFlash(
      createFlashMessage({
        type: "error",
        message,
      })
    );
  };

  const checkChildSum = (child) => {
    const sum = sumChildValues(child);
    return sum >= MAX_SLIDER_VALUE && sum <= MAX_SLIDER_VALUE;
  };

  return (
    <>
      <AppLayout
        contentType="wizard"
        content={
          <>
            <div className="py-5">
              <CustomFlashBar />
            </div>

            <Wizard
              i18nStrings={{
                stepNumberLabel: (stepNumber) => `Step ${stepNumber}`,
                collapsedStepsLabel: (stepNumber, stepsCount) =>
                  `Step ${stepNumber} of ${stepsCount}`,
                skipToButtonLabel: (step, stepNumber) =>
                  `Skip to ${step.title}`,
                navigationAriaLabel: "Steps",
                cancelButton: "Cancel",
                previousButton: "Previous",
                nextButton: "Next",
                submitButton: "Submit",
                optional: "optional",
              }}
              onCancel={handleCancel}
              onNavigate={({ detail }) => {
                if (categoryValueCheck() || !data.name) {
                  showErrorFlashMessage(
                    "Title is required and Total of the categories must be equal to 100"
                  );
                  return;
                }

                const errorPlatformMessage =
                  "Total of all platform must be equal to 100";
                const stepChildData = [
                  { step: 1, child: data.searchDemand.child },
                  { step: 2, child: data.contentConsumption.child },
                  { step: 3, child: data.piracy.child },
                  { step: 4, child: data.fanSites.child },
                  /* { step: 5, child: data.cpMarketplace.child }, */
                ];

                const currentStepData = stepChildData.find(
                  (item) => item.step === activeStepIndex
                );


                if (currentStepData && !checkChildSum(currentStepData.child)) {
                  showErrorFlashMessage(errorPlatformMessage);
                  return;
                }

                setActiveStepIndex(detail.requestedStepIndex);
              }}
              activeStepIndex={activeStepIndex}
              allowSkipTo
              steps={[
                {
                  title: "Title and Categories",
                  description:
                    "Create an option with associated weights that will be used for ranking platforms. The weights would determine how much each categories & platforms contributes to the final score.",
                  content: (
                    <Container>
                      <SpaceBetween direction="vertical" size="xxl">
                        <FormField
                          label="Title"
                          description="The name that will be used to display  in the summary section"
                        >
                          <Input
                            value={data.name}
                            onChange={(e) => {
                              setData((prevState) => {
                                return { ...prevState, name: e.detail.value };
                              });
                            }}
                          />
                        </FormField>
                        <FormField
                          label={
                            <span>

                              Sort Order
                              <span style={{ color: "#838383" }}>

                                - optional
                              </span>
                            </span>
                          }
                          description="Will be used to sort based on the value. Ascending: lowest to highest or Descending: highest to lowest"
                        >
                          <Input
                            type="number"
                            value={data.order ? data.order.toString() : "100"}
                            onChange={(e) => {
                              setData((prevState) => {
                                return {
                                  ...prevState,
                                  order: parseInt(e.detail.value),
                                };
                              });
                            }}
                          />
                        </FormField>
                        <FormField
                          label={<span>Search Demand</span>}
                          description="Platforms: Google Search Trends, Wikipedia"
                        >
                          <Input
                            type="number"
                            step={1}
                            value={
                              data.searchDemand && data.searchDemand.value
                                ? data.searchDemand.value.toString()
                                : "0"
                            }
                            onChange={(e) => {
                              setData((prevState) => ({
                                ...prevState,
                                searchDemand: {
                                  ...prevState.searchDemand,
                                  value: parseInt(e.detail.value),
                                },
                              }));
                            }}
                          />
                        </FormField>
                        <FormField
                          label={<span>Content Consumption</span>}
                          description="Platforms: YouTube, X, TikTok, Instagram, Twitch, IMDb"
                        >
                          <Input
                            type="number"
                            step={1}
                            value={
                              data.contentConsumption &&
                                data.contentConsumption.value
                                ? data.contentConsumption.value.toString()
                                : "0"
                            }
                            onChange={(e) => {
                              setData((prevState) => ({
                                ...prevState,
                                contentConsumption: {
                                  ...prevState.contentConsumption,
                                  value: parseInt(e.detail.value),
                                },
                              }));
                            }}
                          />
                        </FormField>
                        <FormField
                          label={<span>Fan Sites</span>}
                          description="Platforms: Fandom, Reddit, Wattpad, AO3, Steam "
                        >
                          <Input
                            type="number"
                            step={1}
                            value={
                              data.fanSites && data.fanSites.value
                                ? data.fanSites.value.toString()
                                : "0"
                            }
                            onChange={(e) => {
                              setData((prevState) => ({
                                ...prevState,
                                fanSites: {
                                  ...prevState.fanSites,
                                  value: parseInt(e.detail.value),
                                },
                              }));
                            }}
                          />
                        </FormField>
                        <FormField
                          label={<span>Piracy</span>}
                          description="Platforms: P2P"
                        >
                          <Input
                            type="number"
                            step={1}
                            value={
                              data.piracy && data.piracy.value
                                ? data.piracy.value.toString()
                                : "0"
                            }
                            onChange={(e) => {
                              setData((prevState) => ({
                                ...prevState,
                                piracy: {
                                  ...prevState.piracy,
                                  value: parseInt(e.detail.value),
                                },
                              }));
                            }}
                          />
                        </FormField>
                        {/* <FormField label={<span>Consumer Products</span>} description="Platforms: Ebay, Etsy, Redbubble, Amazon Books">
                            <Input
                              type="number"
                              step={1}
                              value={
                                data.cpMarketplace && data.cpMarketplace.value
                                  ? data.cpMarketplace.value.toString()
                                  : "0"
                              }
                              onChange={(e) => {
                                setData((prevState) => ({
                                  ...prevState,
                                  cpMarketplace: {
                                    ...prevState.cpMarketplace,
                                    value: parseInt(e.detail.value),
                                  },
                                }));
                              }}
                            />
                          </FormField> */}
                      </SpaceBetween>
                    </Container>
                  ),
                },
                {
                  title: "Search Demand",
                  description:
                    "Configure the weightage for various platforms such as Google Search Trends, YouTube Search Trends, and Wikipedia in order to determine their importance when evaluating a set of intellectual properties. Assign a weight to each platform to represent its significance, with the total sum of all platform weights equating to 100. This configuration allows for a tailored approach when analyzing and comparing data across different platforms, ensuring a comprehensive and well-balanced understanding of the intellectual properties in question.",
                  content: (
                    <Container>
                      <ColumnLayout columns={3}>
                        {data &&
                          data.searchDemand.child.map((d, i) => (
                            <Box key={i}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "400px",
                                }}
                              >
                                <div>
                                  <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                                    {d.label}
                                  </div>
                                  <Slider
                                    value={d.value}
                                    onChange={(event, newValue) => {
                                      const newChild = handleChange(
                                        i,
                                        newValue,
                                        data.searchDemand
                                      );
                                      setData((prevData) => {
                                        return {
                                          ...prevData,
                                          searchDemand: {
                                            ...prevData.searchDemand,
                                            child: newChild,
                                          },
                                        };
                                      });
                                    }}
                                    aria-labelledby={`slider-${i}`}
                                    step={5}
                                    marks
                                    min={0}
                                    max={100}
                                    size="large"
                                    valueLabelDisplay="auto"
                                  />
                                  <Chart
                                    options={options}
                                    chartType="Gauge"
                                    graphID={`gauge-page-searchDemand-${i}`}
                                    data={[
                                      ["Label", "value"],
                                      [d.label, Number(d.value)],
                                    ]}
                                  />
                                </div>
                              </div>
                            </Box>
                          ))}
                      </ColumnLayout>
                    </Container>
                  ),
                },
                {
                  title: "Content Consumption",
                  description:
                    "Configure the weightage for various platforms such as YouTube, TikTok, X (formerly Twitter) and Instagram in order to determine their importance when evaluating a set of intellectual properties. Assign a weight to each platform to represent its significance, with the total sum of all platform weights equating to 100. This configuration allows for a tailored approach when analyzing and comparing data across different platforms, ensuring a comprehensive and well-balanced understanding of the intellectual properties in question.",
                  content: (
                    <Container>
                      <ColumnLayout columns={2}>
                        {data &&
                          data.contentConsumption.child.map((d, i) => (
                            <Box key={i}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "400px",
                                }}
                              >
                                <div>
                                  <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                                    {d.label}
                                  </div>
                                  <Slider
                                    value={d.value}
                                    onChange={(event, newValue) => {
                                      const newChild = handleChange(
                                        i,
                                        newValue,
                                        data.contentConsumption
                                      );
                                      setData((prevData) => {
                                        return {
                                          ...prevData,
                                          contentConsumption: {
                                            ...prevData.contentConsumption,
                                            child: newChild,
                                          },
                                        };
                                      });
                                    }}
                                    aria-labelledby={`slider-${i}`}
                                    step={5}
                                    marks
                                    min={0}
                                    max={100}
                                    size="large"
                                    valueLabelDisplay="auto"
                                  />
                                  <div>
                                    <Chart
                                      graphID={`gauge-page-contentConsumption-${i}`}
                                      options={options}
                                      chartType="Gauge"
                                      data={[
                                        ["Label", "value"],
                                        [d.label, Number(d.value)],
                                      ]}
                                    />
                                  </div>
                                </div>
                              </div>
                            </Box>
                          ))}
                      </ColumnLayout>
                    </Container>
                  ),
                },

                {
                  title: "Piracy",
                  description:
                    "Configure the weightage for various platforms such as Piracy in order to determine their importance when evaluating a set of intellectual properties. Assign a weight to each platform to represent its significance, with the total sum of all platform weights equating to 100. This configuration allows for a tailored approach when analyzing and comparing data across different platforms, ensuring a comprehensive and well-balanced understanding of the intellectual properties in question.",
                  content: (
                    <Container>
                      <ColumnLayout columns={3}>
                        {data &&
                          data.piracy.child.map((d, i) => (
                            <Box key={i}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "400px",
                                }}
                              >
                                <div>
                                  <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                                    {d.label}
                                  </div>
                                  <Slider
                                    value={d.value}
                                    onChange={(event, newValue) => {
                                      const newChild = handleChange(
                                        i,
                                        newValue,
                                        data.piracy
                                      );
                                      setData((prevData) => {
                                        return {
                                          ...prevData,
                                          piracy: {
                                            ...prevData.piracy,
                                            child: newChild,
                                          },
                                        };
                                      });
                                    }}
                                    aria-labelledby={`slider-${i}`}
                                    step={5}
                                    marks
                                    min={0}
                                    max={100}
                                    size="large"
                                    valueLabelDisplay="auto"
                                  />

                                  <Chart
                                    graphID={`gauge-page-piracy-${i}`}
                                    options={options}
                                    chartType="Gauge"
                                    data={[
                                      ["Label", "value"],
                                      [d.label, Number(d.value)],
                                    ]}
                                  />
                                </div>
                              </div>
                            </Box>
                          ))}
                      </ColumnLayout>
                    </Container>
                  ),
                },

                {
                  title: "Fan Sites",

                  description:
                    "Configure the weightage for various platforms such as Google Search Trends, YouTube Search Trends, and Wikipedia in order to determine their importance when evaluating a set of intellectual properties. Assign a weight to each platform to represent its significance, with the total sum of all platform weights equating to 100. This configuration allows for a tailored approach when analyzing and comparing data across different platforms, ensuring a comprehensive and well-balanced understanding of the intellectual properties in question.",
                  content: (
                    <Container header={<Header variant="h2">Fan Sites</Header>}>
                      <ColumnLayout columns={2}>
                        {data &&
                          data.fanSites.child.map((d, i) => (
                            <Box key={i}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  height: "400px",
                                }}
                              >
                                <div>
                                  <div className="text-center pb-10 font-bold text-lg  text-sky-600">
                                    {d.label}
                                  </div>
                                  <Slider
                                    value={d.value}
                                    onChange={(event, newValue) => {
                                      const newChild = handleChange(
                                        i,
                                        newValue,
                                        data.fanSites
                                      );
                                      setData((prevData) => {
                                        return {
                                          ...prevData,
                                          fanSites: {
                                            ...prevData.fanSites,
                                            child: newChild,
                                          },
                                        };
                                      });
                                    }}
                                    aria-labelledby={`slider-${i}`}
                                    step={5}
                                    marks
                                    min={0}
                                    max={100}
                                    size="large"
                                    valueLabelDisplay="auto"
                                  />

                                  <Chart
                                    graphID={`gauge-page-fanSites-${i}`}
                                    options={options}
                                    chartType="Gauge"
                                    data={[
                                      ["Label", "value"],
                                      [d.label, Number(d.value)],
                                    ]}
                                  />
                                </div>
                              </div>
                            </Box>
                          ))}
                      </ColumnLayout>
                    </Container>
                  ),
                },
                {
                  title: "Review and Save",

                  description:
                    "In this final step of the 7-step wizard, carefully review all the information and settings you have entered in the previous steps. Ensure that the details are accurate and complete before proceeding. Once you are satisfied with your inputs, click the 'Submit' button to finalize and store your configurations. This step is crucial, as it allows you to verify your work and make any necessary adjustments before committing to the changes. Remember, a well-reviewed and saved configuration leads to a smoother experience and better results.",
                  content: (
                    <SpaceBetween direction="vertical" size="l">
                      <Container
                        header={
                          <Header variant="h3">Title & Sort Order </Header>
                        }
                      >
                        <ColumnLayout columns={2} variant="text-grid">
                          <SpaceBetween size="l">
                            <ValueWithLabel label="Title">
                              {data.name}
                            </ValueWithLabel>
                          </SpaceBetween>
                          <SpaceBetween size="l">
                            <ValueWithLabel label="Sort Order">
                              {data.order}
                            </ValueWithLabel>
                          </SpaceBetween>
                        </ColumnLayout>
                      </Container>
                      <Container
                        header={
                          <Header variant="h3">
                            Search Demand ( {data.searchDemand.value} %)
                          </Header>
                        }
                      >
                        <ColumnLayout columns={3} variant="text-grid">
                          {data.searchDemand.child.map((item, index) => (
                            <SpaceBetween key={index} size="l">
                              <ValueWithLabel label={item.label}>
                                {item.value}
                              </ValueWithLabel>
                            </SpaceBetween>
                          ))}
                        </ColumnLayout>
                      </Container>
                      <Container
                        header={
                          <Header variant="h3">
                            Content Consumption (
                            {data.contentConsumption.value} %)
                          </Header>
                        }
                      >
                        <ColumnLayout columns={4} variant="text-grid">
                          {data.contentConsumption.child.map((item, index) => (
                            <SpaceBetween key={index} size="l">
                              <ValueWithLabel label={item.label}>
                                {item.value}
                              </ValueWithLabel>
                            </SpaceBetween>
                          ))}
                        </ColumnLayout>
                      </Container>

                      <Container
                        header={
                          <Header variant="h3">
                            Piracy ( {data.piracy.value} %)
                          </Header>
                        }
                      >
                        <ColumnLayout columns={1} variant="text-grid">
                          {data.piracy.child.map((item, index) => (
                            <SpaceBetween key={index} size="l">
                              <ValueWithLabel label={item.label}>
                                {item.value}
                              </ValueWithLabel>
                            </SpaceBetween>
                          ))}
                        </ColumnLayout>
                      </Container>
                      <Container
                        header={
                          <Header variant="h3">
                            Fan Sites ( {data.fanSites.value} %)
                          </Header>
                        }
                      >
                        <ColumnLayout columns={4} variant="text-grid">
                          {data.fanSites.child.map((item, index) => (
                            <SpaceBetween key={index} size="l">
                              <ValueWithLabel label={item.label}>
                                {item.value}
                              </ValueWithLabel>
                            </SpaceBetween>
                          ))}
                        </ColumnLayout>
                      </Container>
                    </SpaceBetween>
                  ),
                },
              ]}
              onSubmit={() => {
                createNbcuSettingData(data);
              }}
            />
          </>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
      />
    </>
  );
};

export default CreateRankPage;
