import { dispatch } from "../../../../../app/store";
import axiosInstance from "../../../../../utils/axios";
import { createOrgAction } from "../slices/create-organization-slice";
import { editOrgAction } from "../slices/edit-organization-slice";
import { organizationsListActions } from "../slices/organizations-slice";


export async function getOrganizationsList() {

    dispatch(organizationsListActions.loading());
    try {
        const response = await axiosInstance.request({
            url: `org`,
            method: `GET`,
        });
        dispatch(organizationsListActions.insertOrganizationsList(response.data));
    } catch (e) {
        dispatch(organizationsListActions.insertError(e.message));
    }

}

export async function createOrgFn({
    email,
    name,
    org_status,
    unrestrictedAccess
}) {
    dispatch(createOrgAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `org`,
            method: `POST`,
            data: {
                email,
                name,
                org_status:org_status ? 'Active' : "Inactive",
                unrestrictedAccess
            }
        });
        dispatch(createOrgAction.createOrgComepleted(response.data));
    } catch (e) {
        dispatch(createOrgAction.createOrgFailed(e.message));
    }

}

export  function clearOrgCreateFn() {
    dispatch(createOrgAction.clearOrgCreateReducer());
}


export async function editOrgFn({
    id,
    email,
    name,
    org_status,
    unrestrictedAccess
}) {
    dispatch(editOrgAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `org/${id}`,
            method: `PATCH`,
            data: {
                email,
                name,
                org_status:org_status ? 'Active' : "Inactive",
                unrestrictedAccess
            }
        });
        dispatch(editOrgAction.editOrgComepleted(response.data));
    } catch (e) {
        dispatch(editOrgAction.editOrgFailed(e.message));
    }

}

export  function clearOrgEditFn() {
    dispatch(editOrgAction.clearOrgCreateReducer());
}

