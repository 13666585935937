import axiosInstance from "../../utils/axios";

export const getGenericPreference = async (user) => {
  const response = await axiosInstance.get(
    `/preferences/personal/${user.username}/pantheon`,
  );
  return response;
};

export const postGenericPreference = (user, data) => {
  return axiosInstance.request({
    url: `/preferences/personal/${user.username}/pantheon`,
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
    data: JSON.stringify(data),
  });
};
