import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getGenreTimeseriesData } from "../request";

export const useGetGenreTimeseriesData = (granularity: string) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_GENRE_TIMESERIES_DATA,granularity],
    () => getGenreTimeseriesData(granularity),
  );

  return {
    data,
    isLoading,
    error,
  };
};
