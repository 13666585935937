import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
import _ from "lodash";

export const getGeneralTrendsDataApi = createAsyncThunk(
  "generalWikipediaTrendsData/getGeneralTrendsDataApi",
  async ({ date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`wikipedia/general_trends`, {
        params: date ? { date } : {},
      });
      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialGeneralWikipediaTrendsState = {
  loading: false,
  data: {
    timestamp: null,
    total_articles: 0,
    articles: [],
  },
  completed: false,
  hasError: false,
  message: "",
};

const generalWikipediaTrendsSlice = createSlice({
  name: "generalWikipediaTrendsData",
  initialState: initialGeneralWikipediaTrendsState,
  reducers: {
    clearData: () => {
      return initialGeneralWikipediaTrendsState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralTrendsDataApi.pending, (state, { payload }) => {
        _.set(state, "loading", true);
        _.set(state, "hasError", false);
        _.set(state, "data", {
          timestamp: null,
          total_articles: 0,
          articles: [],
        });
      })
      .addCase(getGeneralTrendsDataApi.fulfilled, (state, { payload }) => {
        _.set(state, "loading", false);
        _.set(state, "hasError", false);
        _.set(state, "data", payload);
        _.set(state, "completed", true);
      })
      .addCase(getGeneralTrendsDataApi.rejected, (state, { payload }) => {
        _.set(state, "loading", false);
        _.set(state, "hasError", true);
        _.set(state, "completed", true);
        _.set(state, "message", payload);
      });
  },
});

const GeneralWikipediaTrendsReducer = generalWikipediaTrendsSlice.reducer;
export default GeneralWikipediaTrendsReducer;

export const generalWikipediaTrendsActions =
  generalWikipediaTrendsSlice.actions;
