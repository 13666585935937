import { getYearList } from "../../../../../../../utils/dateFormatter";
import { ImdbResponse, ImdbResponseItem } from "../request";

const initialValue = {
  distributors: [],
  genre: [],
  ott: [],
  production: [],
  data: [],
};

type InitialValueType = typeof initialValue;

export const mapImdbResponse = (data: ImdbResponse) => {
  const currentYear = new Date().getFullYear();

  const mappedResponse = data?.reduce(
    (acc: InitialValueType, item: ImdbResponseItem) => {
      Object.keys(item).forEach((key) => {
        if (initialValue[key]) {
          acc[key] = Array.from(new Set([...acc[key], ...item[key]]));
        }
      });

      acc["data"] = acc["data"].concat({
        ...item,
        release_date: item.release_date * 1000,
      });

      return acc;
    },
    {
      ...initialValue,
    },
  );

  const yearList = getYearList(currentYear);

  return { ...mappedResponse, year: yearList };
};
