import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const skipTrackingHosts = ['localhost', 'dev.searchpantheon.com'];
    const currentHost = window.location.hostname;

    if (!skipTrackingHosts.includes(currentHost) && window.gtag) {
      window.gtag('config', 'G-RWZC9XD3MB', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null;
};

export default PageTracker;
