import { useMemo, useState } from "react";
import { useGetChartTheme, CHART_TYPES } from "../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../utils/shortenInteger";
import { useCompareContext } from "../../../hooks/useCompareContext";

const defaultOverrides = {
  overrides: {
    common: {
      axes: {
        number: {
          label: {
              fontFamily: "Open Sans",
          },
        },
        time: {
          label: {
              fontFamily: "Open Sans",
          },
        },
        "angle-category": {
          label: {
            fontFamily: "Open Sans",
          },
        },
        "radius-number": {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
      series: {
        highlightStyle: {
          series: {
            dimOpacity: 0.2,
            strokeWidth: 2,
          },
        },
      },
      navigator: {
        miniChart: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
      legend: {
        item: {
          label: {
            fontFamily: "Open Sans",
          },
        },
      },
    },
  },
};

export const useChartConfig = ({
  chartHeight=450,
  chartBaseTheme,
}) => {

  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT, defaultOverrides);
  const { titles, selectedMetrics, multiParamInfo, timeseriesData } = useCompareContext();

  const [ titleMetricInfo, setTitleMetricInfo ] = useState([]);

  const seriesDef = useMemo(() => {
    if (titles?.length === 0) return null;

    const series = multiParamInfo.map((paramInfo, i) => (
      titles.filter(title => paramInfo.title_ids.includes(title.ip_id)).map((title) => (
        {
          type: "radar-area",
          radiusKey: `${title.ip_id}`,
          radiusName: `${title.ip}`,
          angleKey: "metric",
          metadata: { title },
        }
      ))
    )).flat();

    const titleMetricKeys = Object.values(series.map(s => (
      { [s.metadata.title.value]: { label: s.yName, value: s.yKey, metadata: s.metadata } }
    )).reduce((acc, obj) => ({ ...acc, ...obj }), {}));
    setTitleMetricInfo(titleMetricKeys);
    
    return series;
  }, [titles, multiParamInfo]);

  const seriesData = useMemo(() => {
    if (timeseriesData?.length === 0 || selectedMetrics?.length === 0) return [];

    const titleMap = titles.map(title => ({ [title.value]: title })).reduce((acc, obj) => ({ ...acc, ...obj }), {});
    const titleObjects = Object.values(titleMap);

    const newSeriesData = selectedMetrics.filter(metric => !metric.hideInSummary).map(metric => {
      const data = {};
      multiParamInfo.forEach((paramInfo, i) => {
        titleObjects.filter(title => paramInfo.title_ids.includes(title.value)).forEach(title => {
          const titleData = timeseriesData.filter(d => d.timestamp >= paramInfo.start_ts && d.timestamp <= paramInfo.end_ts);
          if (!titleData.find(d => d[`${title.value}_${metric.key}`])) return;
          data[title.value] = 0;
          titleData.forEach(d => {
            const value = d[`${title.value}_${metric.key}`];
            data[title.value] += value;
          });
          //data[title.value] = data[title.value].reduce((acc, val) => acc + val, 0) / data[title.value].length;
        });
      });
      return {
        ...data,
        metric: metric.name,
      };
    });
    
    return newSeriesData;
  }, [timeseriesData, multiParamInfo, titleMetricInfo, selectedMetrics, titles]);

  const chartOptions = useMemo(() => {
    return {
      height: chartHeight,
      data: seriesData,
      series: seriesDef,
      axes: [
        { type: "angle-category" },
        {
          type: "radius-number",
          label: {
            formatter: (params) => shortenInteger(params.value),
          },
        },
      ],
      theme: { 
        ...theme, 
        baseTheme: chartBaseTheme ?? theme.baseTheme,
        ...chartBaseTheme === "ag-default" ? {
          overrides: {
            common: {
              background: { fill: "#FFFFFF" },
            },
          },
        } : {},
      },
      legend: {
        position: "bottom",
        item: {
          line: {
            strokeWidth: 12,
          },
        },
      },
    };
  }, [seriesData, seriesDef, chartBaseTheme, chartHeight, theme]);

  return {
    chartOptions,
  };
};