import React from "react";
import {
  navItems,
  NavItemsWithId,
} from "../../../../layouts/common/menu/side-menu";
import { useBreadCrumbs } from "../../hooks/useBreadCrumbs";
import { Layout } from "../../Layout";
import ItemCompany from "./company";

interface CompanyIndexProps {
  itemData: any;
  flashbarRef: React.RefObject<any>;
}

export const CompanyIndex: React.FC<CompanyIndexProps> = ({
  itemData,
  flashbarRef,
}) => {
  const { breadcrumbs } = useBreadCrumbs(itemData);

  return (
    <Layout
      flashbarRef={flashbarRef}
      title={itemData?.data?.name ?? "Game"}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      content={<ItemCompany itemData={itemData} />}
      contentType="dashboard"
    ></Layout>
  );
};
