import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  Icon,
  SpaceBetween,
  Table,
  Tabs,
  Spinner,
  Popover,
  PropertyFilter,
} from "@cloudscape-design/components";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import getTextFilterCounterText from "../../../utils/getTextFilterCounterText";
import CustomPropertyFilter from '../../../components/CustomPropertyFilter';
import { useCollection } from "@cloudscape-design/collection-hooks";
import TableEmptyState from "../../../utils/TableEmptyState";
import TableNoMatchState from "../../../utils/TableNoMatchState";
import createTableSortLabelFn from "../../../utils/createTableSortLabelFn";

const PlatformJobState = () => {
  const breadcrumbs = [{ text: "Support" }, { text: "Platform Job State" }];
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState([]);

  const [activeService, setActiveService] = useState("fandom");

  const fetchData = async (service) => {
    axiosInstance
      .request({
        url: `/platformjobstate?gte=now-7d&lte=now%2Fd&service=${service}`,
        method: "GET",
      })
      .then((data) => {
        setResponse(data.data);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchData(activeService);
  }, [activeService]);

  const ipMap = new Map();

  response.forEach((item) => {
    const ip = item.ip || "Unknown";
    item.data.forEach((data) => {
      if (!ipMap.has(ip)) {
        ipMap.set(ip, {});
      }
      ipMap.get(ip)[data.date] = data.state;
    });
  });

  const transformedData = Array.from(ipMap, ([ip, data]) => ({ ip, ...data }));
  // const dateColumns = transformedData.length > 0 ? Object.keys(transformedData[0]).filter((key) => key !== "ip") : [];
  const allDates = new Set();

  transformedData.forEach((item) => {
    Object.keys(item)
      .filter((key) => key !== "ip")
      .forEach((date) => allDates.add(date));
  });

  const getStatusIcon = (state, errorMessage) => {
    if (state === "success") {
      return (
        <Box textAlign="center">
          <Icon name="status-positive" variant="success" />
        </Box>
      );
    } else if (state === "failed") {
      return (
        <Box textAlign="center">
          <Popover
            dismissButton={true}
            position="top"
            size="large"
            header="Error Message"
            triggerType="custom"
            renderWithPortal
            content={<Box color="red">{errorMessage}</Box>}
          >
            <Icon name="status-negative" variant="error" />
          </Popover>
        </Box>
      );
    }
    return null;
  };

  const dateColumns = Array.from(allDates).sort((a, b) => moment(b).valueOf() - moment(a).valueOf()).map((date) => ({
    id: date,
    header: date,
    cell: (e) => {
      const item = response.find((item) => item.ip === e.ip);
      const data = item.data.find((data) => data.date === date);
      return data ? getStatusIcon(e[date], data.errorMessage) : null;
    },
    sortingField: date,
    width: "1rem",
  }));

  const columnDefinitions = [
    {
      id: "selection",
      header: "",
      cell: () => null,
      width: "1rem",
    },
    {
      id: "ip",
      header: "IP",
      cell: (e) => e.ip,
      sortingField: "ip",
      width: "auto",
    },
    ...dateColumns
  ];

  const handleTabChange = (event) => {
    setIsLoading(true);
    setActiveService(event.detail.activeTabId);
  };
  const tabs = [
    { label: "Fandom", id: "fandom" },
    { label: "Reddit", id: "reddit" },
    { label: "Wikipedia", id: "wikipedia" },
    { label: "YouTube", id: "youtube" },
    { label: "TikTok", id: "tiktok" },
    { label: "Instagram", id: "instagram" },
    { label: "Wattpad", id: "wattpad" },
    { label: "AO3", id: "AO3" },
    { label: "Etsy", id: "etsy" },
    { label: "Ebay", id: "ebay" },
    { label: "Redbubble", id: "redbubble" },
    { label: "Google Trends", id: "google_trends" },
    { label: "YouTube Trends", id: "youtube_trends" },
    { label: "X", id: "twitter" },
  ];

  const findLatestDate = (items) => {
    let latestDate = "";
    if (items && items.length > 0) {
      items[0].data.reduce((p, cv, ci) => {
        if (p) {
          if (moment(cv.date) > moment(p)) {
            latestDate = cv.date;
          }
        } else {
          latestDate = cv.date;
        }
      }, latestDate);
    }
    return latestDate;
  };
  function extractDates(response) {
    let dates = [];
    if (response.length > 0) {
      let item = response[0];
      item.data.forEach((dataItem) => {
        dates.push(dataItem.date);
      });
    }
    return dates;
  }

  const filteringProperties = [
    {
      propertyLabel: "Ip",
      key: "ip",
      groupValuesLabel: "Ip",
      operators: [":", "!:", "=", "!="],
    },
    ...extractDates(response).map((d) => ({
      propertyLabel: d,
      key: d,
      groupValuesLabel: d,
      operators: [":", "!:", "=", "!="],
    })),
  ].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));

  const rawColumns = columnDefinitions.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(transformedData, {
    selection: {},
    propertyFiltering: {
      filteringProperties: filteringProperties,
      empty: isLoading ? "" : <TableEmptyState resourceName={"Jobs"} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: "and" });
          }}
        />
      ),
    },

    sorting: { defaultState: { sortingColumn: rawColumns[0] } },
  });

  const tableContent = isLoading ? (
    <Box textAlign="center" color="inherit">
      <Spinner size="large" />
    </Box>
  ) : (
    <Table
      {...collectionProps}
      columnDefinitions={columnDefinitions}
      items={items}
      header={<Header variant="h3">Job Status for last 7 Days</Header>}
      loadingText="Loading Job Status..."
      stickyHeader
      variant="embedded"
      resizableColumns={false}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No IPs to display.
          </Box>
        </Box>
      }
    />
  );

  return (
    <>
      <Helmet>
        <title>Pantheon</title>
      </Helmet>
      <AppLayout
        disableContentPaddings={false}
        stickyNotifications
        toolsHide
        headerSelector="#header"
        navigationHide
        contentType="table"
        content={
          <ContentLayout
            header={<Header variant="h3">Platform Job State</Header>}
          >
            <SpaceBetween size="l">
              <Container
                header={
                  <Header variant="h3">Job status summary for today</Header>
                }
              >
                <ColumnLayout columns={3} variant="text-grid">
                  <div>
                    <Box variant="awsui-key-label">Total Jobs</Box>
                    <div>{transformedData.length}</div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Success</Box>
                    <div>
                      {
                        response.filter(
                          (d) => {
                            const foundData = d.data.find((s) => s.date === findLatestDate(response));
                            return foundData && foundData.state === "success";
                          }
                        ).length
                      }
                    </div>
                  </div>
                  <div>
                    <Box variant="awsui-key-label">Failure</Box>
                    <div>
                      {
                        response.filter(
                          (d) => {
                            const foundData = d.data.find((s) => s.date === findLatestDate(response));
                            return foundData && foundData.state === "failed";
                          }
                        ).length

                      }
                    </div>
                  </div>
                </ColumnLayout>
              </Container>
              <CustomPropertyFilter
                countText={getTextFilterCounterText(filteredItemsCount)}
                {...propertyFilterProps}
                expandToViewport={true}
              />
              <Tabs
                tabs={tabs.map((tab) =>
                  tab.id === activeService
                    ? { ...tab, content: tableContent }
                    : tab
                )}
                ariaLabel="Summary"
                onChange={handleTabChange}
              />
            </SpaceBetween>
          </ContentLayout>
        }

        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
};

export default PlatformJobState;
