import axiosInstance from "../../../utils/axios";
import { ID_TYPES, checkIdType } from "../utils/checkIdType";

export const createMedia = (id: string | number | number[], vertical: string) => {
  const isGamesVertical = vertical === "games";
  
  const idToCheck = isGamesVertical 
    ? (Array.isArray(id) ? id[0].toString() : id.toString())
    : (id as string);
  
  const idType = checkIdType(idToCheck);

  const baseUrl = `/${vertical}/v1`;
  const url = isGamesVertical 
    ? baseUrl 
    : `${baseUrl}?${ idType === ID_TYPES.IMDB ? "imdb_id" : "id" }=${idToCheck}`;

  // Prepare the request payload for games only
  const data = isGamesVertical 
    ? { igdb_id: Array.isArray(id) ? id.map(Number) : [Number(id)] } // Convert ids to integers
    : null;

  return axiosInstance.request({
    url,
    method: "POST",
    headers: {
      "Content-Type": isGamesVertical ? "application/json" : "text/plain",
    },
    ...(isGamesVertical && { data }), // Include data only for games
  });
};
