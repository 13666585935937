import { ButtonDropdown, Modal } from "@cloudscape-design/components";
import DatapointComparison from "../../../../../../components/datapoint-comparison/DatapointComparison";
import { useEffect, useMemo, useState } from "react";
import { useMetrics } from "../../../../../tools/compare/hooks/useMetrics";
import { SERVICES, TYPES, VERTICALS } from "../../../../../../config-global";
import { METRIC_GROUPS } from "../../../../../../components/datapoint-comparison/constants";
import { useGetGenericPreference_V2 } from "../../../../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { CustomMetricsModal } from "../../../../../tools/compare/modals/CustomMetricsModal";

const useWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return { width, height };
};

export const CompareModal = ({ visible, setVisible, titleId }) => {
  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.TOOLS, module: "compare" };

  const { height: windowHeight } = useWindowDimensions();
  const { metrics } = useMetrics({});

  const [properties, setProperties] = useState({});
  const [customMetricsModalVisible, setCustomMetricsModalVisible] = useState(false);
  const [customMetrics, setCustomMetrics] = useState([]);

  const metricKeys = useMemo(() => {
    const idPrefix = titleId.split("-")[0];
    const metricKeys = metrics.filter(metric => metric.group === METRIC_GROUPS.ZSCORE && metric.datapoints.some(dp => typeof dp.weight === "number" ? dp.weight > 0 : dp.weight.filter(w => w.idPrefix === idPrefix && w.weight > 0).length > 0)).map(metric => metric.key);
    return metricKeys;
  }, [titleId]);

  const { data: preferenceData } = useGetGenericPreference_V2({ apiParams });

  useEffect(() => {
    const pref = preferenceData?.[0]?.data;

    if (pref) {
      if (pref.customMetrics) {
        setCustomMetrics(pref.customMetrics);
      }
    }
  }, [preferenceData]);

  useEffect(() => {
    setProperties({
      activeMetricKey: properties.activeMetricKey,
      metricKeys: metricKeys,
      parameters: [
        {
          titleIds: [titleId],
        },
      ]
    });
  }, [titleId, metricKeys]);

  return (
    <Modal
      size="max"
      onDismiss={() => setVisible(false)}
      visible={visible}
      header={
        <div className="modal-header-text w-full flex justify-between">
          <div>Compare Titles</div>
          <div>
            <ButtonDropdown
              items={[
                { text: "Manage custom metrics", id: "manage-custom-metrics", disabled: false },
              ]}
              onItemClick={({ detail }) => {
                switch (detail.id) {
                  case "manage-custom-metrics":
                    setCustomMetricsModalVisible(true);
                    break;
                  default:
                    break;
                }
              }}
              expandToViewport
            >
              Actions
            </ButtonDropdown>
          </div>
        </div>
      }
    >
      {visible && (
        <DatapointComparison
          metrics={[...customMetrics, ...metrics]}
          properties={properties}
          setProperties={setProperties}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            chart: {
              height: windowHeight - 325,
            },
            table: {
              enabled: false,
            },
            summary: {
              enabled: true,
            },
          }}
        />
      )}
      <style>{`
        div[class^='awsui_title_']:has( div[class^='modal-header-text']) {
          width: 100%;
        }
      `}</style>
      <CustomMetricsModal
        visible={customMetricsModalVisible}
        setVisible={setCustomMetricsModalVisible}
        setCustomMetrics={setCustomMetrics}
      />
    </Modal>
  );
};
