import React, { createContext, ReactNode, useContext, useState } from "react";
import { SERVICES, TYPES } from "../../config-global";
import { useGetGenericPreference_V2 } from "../../services/generic_v2/hooks/useGetGenericPreference_V2";

interface PlatformContextData {
  platformPreference: any;
  hasApiCompleted: boolean;
  isLoading: boolean;
  platformFilterName: string;
  setPlatformFilterName: (name: string) => void;
}

const PlatformContext = createContext<PlatformContextData | null>(null);

export const PlatformProvider = ({ children }: { children: ReactNode }) => {
  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.PLATFORMS };
  const [hasApiCompleted, setHasApiCompleted] = useState<boolean>(false);
  const [platformFilterName, setPlatformFilterName] = useState<string>(null);

  const { data, isLoading } = useGetGenericPreference_V2({
    apiParams,
    onSettled: () => setHasApiCompleted(true),
  });

  return (
    <PlatformContext.Provider
      value={{
        platformPreference: data,
        hasApiCompleted,
        isLoading,
        platformFilterName,
        setPlatformFilterName,
      }}
    >
      {children}
    </PlatformContext.Provider>
  );
};

export const usePlatformContext = () => {
  const platformContext = useContext(PlatformContext);

  if (!platformContext) {
    throw new Error("context must be used inside a provider");
  }

  return platformContext;
};
