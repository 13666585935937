import { Calendar, DateInput, FormField } from "@cloudscape-design/components";
import React from "react";

export const filteringProperties = [
  {
    propertyLabel: "Title & Summary",
    key: "title",
    groupValuesLabel: "Title & Summary",
    operators: [":", "=", "blank", "notBlank"],
  },
  {
    propertyLabel: "Published on",
    key: "publishedAt",
    groupValuesLabel: "Published on",
    operators: ["=", ">", "<", ">=", "<="].map((operator) => ({
      operator,
      form: ({ value, onChange }) => (
        <div className="date-form">
          <FormField>
            <DateInput
              value={value ?? ""}
              onChange={(event) => onChange(event.detail.value)}
              placeholder="YYYY/MM/DD"
            />
          </FormField>
          <Calendar
            value={value ?? ""}
            onChange={(event) => onChange(event.detail.value)}
            locale="en-GB"
          />
        </div>
      ),
      match: "date",
    })),
  },
  {
    propertyLabel: "Views",
    key: "viewCount",
    groupValuesLabel: "Views",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Videos",
    key: "videoCount",
    groupValuesLabel: "Videos",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Subscribers",
    key: "subscriberCount",
    groupValuesLabel: "Subscribers",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Today's Views",
    key: "todaysViews",
    groupValuesLabel: "Today's Views",
    operators: [">", "<", ">=", "<="],
  },
  {
    propertyLabel: "Channel Type",
    key: "type",
    groupValuesLabel: "Channel Type",
    operators: [":", "="],
  },
  {
    propertyLabel: "Made for Kids",
    key: "madeForKids",
    operators: [":", "=", "blank", "notBlank"],
  },
];
