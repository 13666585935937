import { createSlice } from "@reduxjs/toolkit";

const editPermissionRouteInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const editPermissioinsRouteSlice = createSlice({
    name:"editPermissionRoute",
    initialState:editPermissionRouteInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        editPermissionRouteComepleted: (state, action) => {
            state.isLoading = false;
            state.message = "Permission Route Updated"
            state.isCompleted = true;
            state.isError = false;
        },
        clearPermissionRouteCreateReducer:() => {
            return editPermissionRouteInitialState;
        },
        editPermissionRouteFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const editPermissioinsRouteAction = editPermissioinsRouteSlice.actions;
const editPermissioinsRouteReducer = editPermissioinsRouteSlice.reducer;
export default editPermissioinsRouteReducer;


