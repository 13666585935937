import { ContentLayout, Header, Tabs } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import { addQueryParams, getQueryParams } from "../../../utils/queryUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import { Layout } from "../Layout";
import WeeklyComponent from "./components/weekly/WeeklyComponent";

const title = "Netflix Dashboard";
const tabs = ["weekly-top-10"];

function NetflixDashboardPage() {
  const breadcrumbs = [{ text: "Dashboards" }, { text: title }];
  const tabQuery = getQueryParams("tab");
  const isValidTab = tabs.some((str) => str.includes(tabQuery));
  const [activeTabId, setActiveTabId] = useState(
    isValidTab ? tabQuery : "weekly-top-10",
  );
  const flashbarRef = useRef(null);

  if (!isValidTab) {
    addQueryParams({ tab: activeTabId });
  }

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      contentType="table"
      content={
        <ContentLayout
          header={
            <>
              <Header
               variant="h2"
               description="Netflix content consumption data aggregated from Netflix's available datasets. "
               >{title}</Header>
              <CustomFlashBar ref={flashbarRef} />
            </>
          }
        >
          <Tabs
            onChange={({ detail }) => {
              setActiveTabId(detail.activeTabId);
              addQueryParams({ tab: detail.activeTabId });
            }}
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Weekly Top 10",
                id: "weekly-top-10",
                content: (
                  <WeeklyComponent />
                ),
              },
            ]}
          />
        </ContentLayout>
      }
    />
  );
}

export default NetflixDashboardPage;
