import { useEffect, useRef, useState } from "react";
import { useContainerQuery } from "@cloudscape-design/component-toolkit";
import { getDefaultLayout } from "./widgets";
import { saveGlobalPersonalPref } from "../../../common/redux/global-pref-action";
import { useAuthContext } from "../../../../auth/useAuthContext";

export function useItemsLayout({ viewersPulsePreferences }) {
  const { user } = useAuthContext();

  const [width, ref] = useContainerQuery((entry) => entry.contentBoxWidth);
  const [layout, setLayout] = useState([]);
  const itemsChanged = useRef(layout !== null);
  useEffect(() => {
    setLayout(viewersPulsePreferences);
  }, [viewersPulsePreferences]);



  useEffect(() => {
    if (itemsChanged.current || !width) {
      return;
    }
    setLayout(getDefaultLayout(width));
  }, [width]);

  function handleLayoutChange(layout) {
    itemsChanged.current = true;
    saveGlobalPersonalPref({
      username: user?.username,
      data: {
        dashboards: {
          viewersPulse: {
            personalDashboard: layout,
          },
        },
      },
    });
    setLayout(layout);
  }

  function resetLayout() {
    itemsChanged.current = false;
    saveGlobalPersonalPref({
      username: user?.username,
      data: {
        dashboards: {
          viewersPulse: {
            personalDashboard: [],
          },
        },
      },
    });
    setLayout(getDefaultLayout(width));
  }

  return [ref, layout ?? [], handleLayoutChange, resetLayout];
}
