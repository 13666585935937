import React from "react";
import IndexPageGrid from "../../../components/IndexPageGrid";

export const TrendingPageCards = () => {
  const items = [
    {
      link: "/dashboards/pantheonranking",
      image: "/images/pantheonranking.png",
      name: "Movies/Series/Gaming",
      path: "Ranking > Trending",
      description: "Curated ranking based on defined platform weights",
    },
    {
      link: "/dashboards/raw-data",
      image: "/images/prankingtable.png",
      name: "Data Table",
      path: "Ranking > Trending",
      description:
        "Pantheon Scores for platforms based on weights that can be customized",
    },
  ];
  const gridDefinition = items.map(() => ({
    colspan: { xxs: 6, xs: 6, s: 4, m: 3, xl: 2 },
  }));

  return <IndexPageGrid items={items} gridDefinition={gridDefinition} />;
};
