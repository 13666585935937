import axiosInstance from "../../utils/axios";

export interface ApiURLParams {
  type: string;
  service?: string;
  module?: string;
  tab?: string;
}

interface GenericPreferenceParams extends ApiURLParams {
  user: string;
}

const constructUrl = (params: GenericPreferenceParams) => {
  const { type = "personal", user, service, module, tab } = params;

  let url = `/preferences/v1/${type}/${user}`;

  if (service) url += `/${service}`;
  if (module) url += `/${module}`;
  if (tab) url += `/${tab}`;

  return url;
};

export const getGenericPreference = async (params: GenericPreferenceParams) => {
  const url = constructUrl(params);
  const response = await axiosInstance.get(url);

  return response;
};

export const postGenericPreference = (
  urlParams: GenericPreferenceParams,
  data: any,
) => {
  const url = constructUrl(urlParams);

  return axiosInstance.request({
    url,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};

export const putGenericPreference = (
  userName: string,
  id: string,
  data: any,
) => {
  return axiosInstance.request({
    url: `/preferences/v1/${userName}/${id}`,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    data: JSON.stringify(data),
  });
};
