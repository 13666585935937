import { useMutation } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { fetchGlobalPref } from "../../../modules/common/redux/global-pref-action";
import { postGenericPreference } from "../requests";

export const usePostGenericPreference = () => {
  const { user } = useAuthContext();
  const { isLoading, mutate, error } = useMutation(
    (payload: any) => postGenericPreference(user, payload),
    { onSuccess: () => fetchGlobalPref(user.username) },
  );

  return {
    mutate,
    isLoading,
    error,
  };
};
