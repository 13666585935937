import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { getTopVideos, GetTopVideosProps } from "../request";

export const useGetTopVideos = (params: GetTopVideosProps) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_CHANNEL_VIDEOS, params.channelId],
    () => getTopVideos(params),
  );

  return {
    data: isSomeOrElse(data?.data, []),
    isLoading,
    error,
  };
};
