import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getFilterOptionsData } from "../request";

export const useGetFilterOptionsData = (params) => {
  const { vertical, filterKey } = params;
  const requestKey = `${QUERY_KEYS.GET_FILTER_OPTIONS_DATA}_${vertical}_${filterKey}`;

  const { isLoading, data, error } = useQuery(
    [requestKey, { ...params }],
    () => getFilterOptionsData(params),
    {}
  );

  return { data, isLoading, error };
};
