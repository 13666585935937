import React from "react";
import { RangeDateSelector } from "../../../../components/RangeDateSelector";
import moment from "moment";

export interface DateRangeType {
  type: string;
  startDate: string;
  endDate: string;
}

export const DateRange = ({ dateRange, setDateRange }) => {
  const dateFormatter = (date: Date) => moment(date).format("YYYY-MM-DD");

  return (
    <RangeDateSelector
      defaults={dateRange}
      onChange={(e) => {
        setDateRange({
          ...e,
          startDate: dateFormatter(e.startDate),
          endDate: dateFormatter(e.endDate),
        });
      }}
    />
  );
};
