import axiosInstance from "../../../utils/axios";

export const getTimelineEvents = ({
  ipIds=[],
}) => {
  if (!ipIds.length) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/pantheon/events/v1",
    method: "GET",
    params: {
      ip_ids: ipIds.join(","),
    },
  });
};
  