import { SERVICES, TYPES } from "../../../config-global";
import { CATEGORIES, categoryOptions } from "../utils/categoryUtils";

const module = "steam";
const TABS = {
  TRENDS: "trends",
};

export const steamApiParams = {
  type: TYPES.PERSONAL,
  service: SERVICES.PLATFORMS,
  module,
  tab: TABS.TRENDS,
};

const steamCategories = [
  CATEGORIES.GAMING,
];

export const steamCategoryOptions = categoryOptions.filter((option) =>
  steamCategories.includes(option.value),
);

export const defaultCategory = CATEGORIES.GAMING;