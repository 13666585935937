import axiosInstance from "../../../../utils/axios";
import { GetPlatformTrendsDataProps } from "../../types";
import { CategoryItem } from "../../../../types/optionTypes";

export const getPiracyTrendsData = ({
  filterQuery,
  vertical,
  sort = "daily_rank",
  sortOrder = "asc",
  offset = 0,
  paginationPageSize,
}: GetPlatformTrendsDataProps) => {
  return axiosInstance.request({
    url: `/ranking/daily/piracy/v3?offset=${offset}&limit=${paginationPageSize}&sort=${sort}&sort_order=${sortOrder}&vertical=${vertical}`,
    method: "POST",
    data: {
      fields: [],
      q: filterQuery,
    },
  });
};

export interface GetPiracyGenericSummaryProps {
  gte: number;
  lte: number;
  option?: string;
  size: number;
  vertical: CategoryItem;
  country: string;
}

export const getPiracyGenericSummary = ({
  gte,
  lte,
  option,
  size,
  vertical,
  country,
}: GetPiracyGenericSummaryProps) => {
  return axiosInstance.request({
    url: `/piracy/generic/us/summary`,
    method: `GET`,
    params: {
      gte,
      lte,
      option,
      size,
      vertical: vertical.value,
      country: country,
    },
  });
};
