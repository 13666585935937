import { ButtonDropdown, SpaceBetween } from "@cloudscape-design/components";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import { getNestedObjectValue } from "../../utils/helpers";
import { isSomeOrElse } from "../../utils/sugarUtils";

type FilterDropdownProps = {
  defaultFilters?: any;
  handleFilterItemClick: (id: string, filters: any) => void;
  path: string;
};

export const FiltersDropdown: FC<FilterDropdownProps> = ({
  defaultFilters = {},
  handleFilterItemClick,
  path,
}) => {
  const globalPref = useSelector(
    (state: any) => state?.globalPersonalPref?.preferences,
  );
  const currentFilters = isSomeOrElse(
    getNestedObjectValue(globalPref, path),
    {},
  );
  const filters = { ...currentFilters, ...defaultFilters };

  const items = Object.keys(filters).map((key) => ({
    text: key,
    id: key,
  }));

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <ButtonDropdown
        items={items}
        onItemClick={(event) => handleFilterItemClick(event.detail.id, filters)}
      >
        Saved Filters
      </ButtonDropdown>
    </SpaceBetween>
  );
};
