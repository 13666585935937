import moment from "moment";
import { useCallback, useMemo } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../hooks/UseTheme/useGetChartTheme";
import { utcTimestampToLocal } from "../../../../utils/dateFormatter";
import shortenInteger from "../../../../utils/shortenInteger";

const calculateMedian = (data) => {
  const sortedData = data.slice().sort((a, b) => a.dailyRank - b.dailyRank);
  const middle = Math.floor(sortedData.length / 2);

  if (sortedData.length % 2 === 0) {
    return (
      (sortedData[middle - 1].dailyRank + sortedData[middle].dailyRank) / 2
    );
  } else {
    return sortedData[middle].dailyRank;
  }
};

const splitDataByMedian = (data, median) => {
  const aboveMedian = [];
  const belowMedian = [];

  data.forEach((point, index) => {
    if (point.dailyRank >= median) {
      if (index > 0 && data[index - 1].dailyRank < median) {
        const slope =
          (point.dailyRank - data[index - 1].dailyRank) /
          (point.timestamp - data[index - 1].timestamp);
        const yIntercept = point.dailyRank - slope * point.timestamp;
        const medianTimestamp = (median - yIntercept) / slope;
        const medianPoint = {
          ...point,
          timestamp: medianTimestamp,
          dailyRank: median,
        };
        aboveMedian.push(medianPoint);
        belowMedian.push(medianPoint);
      }
      aboveMedian.push(point);
    } else {
      if (index > 0 && data[index - 1].dailyRank >= median) {
        const slope =
          (point.dailyRank - data[index - 1].dailyRank) /
          (point.timestamp - data[index - 1].timestamp);
        const yIntercept = point.dailyRank - slope * point.timestamp;
        const medianTimestamp = (median - yIntercept) / slope;
        const medianPoint = {
          ...point,
          timestamp: medianTimestamp,
          dailyRank: median,
        };
        aboveMedian.push(medianPoint);
        belowMedian.push(medianPoint);
      }
      belowMedian.push(point);
    }
  });

  return { aboveMedian, belowMedian };
};

export const useBasicChartConfig = (data, crosslines = [], itemName) => {
  const { theme } = useGetChartTheme(CHART_TYPES.DEFAULT);

  const hoveredPointData = useMemo(
    () =>
      data.map((point, index) => ({
        ...point,
        previousValue: index === 0 ? null : data[index - 1].dailyRank,
        timestamp: utcTimestampToLocal(point.timestamp, true, "day"),
      })),
    [data],
  );

  const crosshairTooltipRenderer = useCallback(
    (params) => {
      const hoveredMasterTimestamp =
        utcTimestampToLocal(params.value, false, "day") + 86400000;

      const selectedPoint = hoveredPointData.find(
        (point) => point.timestamp === hoveredMasterTimestamp,
      );

      if (!selectedPoint) return null;

      let trendColor = "text-neutral-300";
      let trendIcon = "&#8210;";
      let difference = 0;

      if (selectedPoint.previousValue === null) {
        trendColor = "text-green-500";
        trendIcon = "&#9650;";
        difference = selectedPoint.dailyRank;
      } else {
        difference = selectedPoint.previousValue - selectedPoint.dailyRank;
        trendIcon =
          difference > 0 ? "&#9650;" : difference < 0 ? "&#9660;" : "";
        trendColor =
          difference > 0
            ? "text-green-500"
            : difference < 0
            ? "text-red-500"
            : "text-neutral-300";
      }

      return `
      <div class=" p-2 rounded-md flex flex-col space-y-4 bg-slate-200/80 dark:bg-slate-800/80">
        <div>
          <span>
            ${moment.utc(selectedPoint.timestamp).format("LL")}
          </span>
          <div>
            <div class="flex items-center space-x-1">
              <b>Rank Change / Rank: </b>
              <span class="${trendColor} px-2">
                ${trendIcon}
                ${Math.abs(difference).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
              </span>
              <span class="px-2">
                ${selectedPoint.dailyRank}
            </span>
            </div>
          </div>
      </div>  
    `;
    },
    [hoveredPointData, itemName],
  );

  const getLineChartOptions = useCallback(() => {
    if (!data || data.length === 0) {
      return {};
    }

    const medianRank = calculateMedian(data);
    const { aboveMedian, belowMedian } = splitDataByMedian(data, medianRank);

    return {
      tooltip: { enabled: false },
      padding: { right: 0, bottom: 0, left: 0, top: 25 },
      minWidth: 0,
      minHeight: 0,
      theme: theme,
      legend: { enabled: false },
      series: [
        {
          type: "line",
          xKey: "timestamp",
          yKey: "dailyRank",
          yName: "Daily Ranking (Above Median)",
          marker: { enabled: false },
          stroke: "red",
          data: aboveMedian,
        },
        {
          type: "line",
          xKey: "timestamp",
          yKey: "dailyRank",
          yName: "Daily Ranking (Below Median)",
          marker: { enabled: false },
          stroke: "green",
          data: belowMedian,
        },
      ],
      sync: { enabled: true, axes: "x", nodeInteraction: true },
      zoom: { enabled: true, enableSelecting: true },
      axes: [
        {
          type: "time",
          position: "bottom",
          crossLines: crosslines,
          crosshair: {
            enabled: true,
            label: {
              enabled: true,
              yOffset: -275,
              renderer: crosshairTooltipRenderer,
            },
          },
        },
        {
          type: "log",
          reverse: true,
          keys: ["dailyRank"],
          // crosshair: { label: { format: "d" } },
          gridLine: {
            enabled: true,
          },
          label: {
            formatter: function (params) {
              return shortenInteger(params.value);
            },
          },
          crossLines: [
            {
              type: "line",
              value: medianRank,
              stroke: "gray",
              strokeWidth: 2,
              label: {
                text: `Median: ${medianRank}`,
                position: "right",
                color: "grey",
                fontSize: 12,
              },
            },
          ],
        },
      ],
    };
  }, [data, theme, crosslines, crosshairTooltipRenderer]);

  return { getLineChartOptions };
};
