import React, {useState} from 'react';
import { CollectionPreferences } from "@cloudscape-design/components";

export const PreferencesComponent = ({ initialPreferences }) => {
    const [preferences, setPreferences] = useState(initialPreferences);

    const handleConfirm = (newPreferences) => {
        setPreferences(newPreferences);
    };

    return (
        <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            // pageSizePreference={{
            //     title: "Page size",
            //     options: [
            //         { value: 200, label: "200 resources" },
            //         { value: 400, label: "400 resources" },
            //         { value: 600, label: "600 resources" },
            //         { value: 800, label: "800 resources" },
            //     ],
            // }}
            wrapLinesPreference={{
                label: "Wrap lines",
                description:
                    "Select to see all the text and wrap the lines",
            }}
            stripedRowsPreference={{
                label: "Striped rows",
                description: "Select to add alternating shaded rows",
            }}
            contentDensityPreference={{
                label: "Compact mode",
                description:
                    "Select to display content in a denser, more compact mode",
            }}
            contentDisplayPreference={{
                title: "Column preferences",
                description: "Customize the columns visibility and order.",
                liveAnnouncementDndStarted: (position, total) =>
                    `Picked up item at position ${position} of ${total}`,
                liveAnnouncementDndDiscarded: "Reordering canceled",
                liveAnnouncementDndItemReordered: (
                    initialPosition,
                    currentPosition,
                    total
                ) =>
                    initialPosition === currentPosition
                        ? `Moving item back to position ${currentPosition} of ${total}`
                        : `Moving item to position ${currentPosition} of ${total}`,
                liveAnnouncementDndItemCommitted: (
                    initialPosition,
                    finalPosition,
                    total
                ) =>
                    initialPosition === finalPosition
                        ? `Item moved back to its original position ${initialPosition} of ${total}`
                        : `Item moved from position ${initialPosition} to position ${finalPosition} of ${total}`,
                dragHandleAriaDescription:
                    "Use Space or Enter to activate drag for an item, then use the arrow keys to move the item's position. To complete the position move, use Space or Enter, or to discard the move, use Escape.",
                dragHandleAriaLabel: "Drag handle",
                options: [
                    {
                        id: "id",
                        label: "ID",
                    },
                    { id: "name", label: "Name", alwaysVisible: true },
                    {
                        id: "type",
                        label: "Type",
                    },
                ],
            }}
        />
    );
};