import { FormField, Input } from "@cloudscape-design/components";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

PasswordTextField.propTypes = {
  name: PropTypes.string,
  helperText: PropTypes.node,
};

export default function PasswordTextField({ name, helperText, ...other }) {
  const [showPassword, setShowPassword] = useState(false);
  const { control } = useFormContext();

  const normalStyle =
    "block w-full rounded-md border-gray-300 shadow-sm text-gray-700 focus:border-blue-500 focus:ring-blue-500 sm:text-sm";
  const errorStyle =
    "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm";

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div>
            <div className="relative mt-1 rounded-md shadow-sm">
              <FormField label={other.label} description={other.description}>
                <Input
                  type={showPassword ? "text" : "password"}
                  {...field}
                  value={field.value}
                  {...other}
                  onChange={({ detail }) => field.onChange(detail.value)}
                  invalid={error?.message}
                />
              </FormField>
              <div
                className="absolute inset-y-0 right-0 pr-3 pt-3 flex items-center"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeSlashIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                ) : (
                  <EyeIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            {error && (
              <p className="mt-2 text-sm text-red-600" id={`${name}-error`}>
                {error.message}
              </p>
            )}
            {!error && helperText && (
              <p className="mt-2 text-sm text-gray-500">{helperText}</p>
            )}
          </div>
        );
      }}
    />
  );
}
