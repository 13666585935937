import { ContentLayout, Header } from "@cloudscape-design/components";
import React from "react";
import { NavItemsWithId, navItems } from "../../layouts/common/menu/side-menu";
import { ExploreCards } from "./ExploreCards";
import { Layout } from "./Layout";

function ExploreHomePage() {
  const breadcrumbs = [ { text: "Explore" }];

  return (
    <Layout
      title="Explore"
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      contentType="cards"
      content={
        <ContentLayout
          disableOverlap
          header={
            <Header
              variant="h2"
              description="Explore enables you to discover an array of movies, series, games, and franchises, all through customizable filters and specific metadata parameters. Dive into a world of data-driven insights and uncover trends that shape the entertainment landscape."
            >
              Explore
            </Header>
          }
        >
          <ExploreCards />
        </ContentLayout>
      }
    />
  );
}

export default ExploreHomePage;
