import axiosInstance from "../../../../../../utils/axios";

export interface ImdbResponseItem {
  name: string;
  imdb_vote: number;
  release_date: number;
  ip_id: number;
  imdb_rating: number;
  genre: Array<string>;
  distributors: Array<string>;
  ott: Array<string>;
  production: Array<string>;
}

export type ImdbResponse = Array<ImdbResponseItem>;

export const getIMDbData = (filters) => {
  const { imdbRating, yearRange, genre, distributor, ottPlatform } = filters;

  // Construct SQL WHERE clauses based on filters
  let whereClauses = [];
  if (imdbRating) {
    whereClauses.push(
      `s.imdb_rating >= ${imdbRating[0]} AND s.imdb_rating <= ${imdbRating[1]}`,
    );
  }
  // if (yearRange) {
  //     whereClauses.push(`s.release_date >= ${yearRange[0]} AND s.release_date <= ${yearRange[1]}`);
  // }
  if (genre) {
    whereClauses.push(`s.genre = '${genre}'`);
  }
  if (distributor) {
    whereClauses.push(`s.distributor = '${distributor}'`);
  }
  if (ottPlatform) {
    whereClauses.push(`s.ott_platform = '${ottPlatform}'`);
  }

  const whereClause =
    whereClauses.length > 0 ? `WHERE ${whereClauses.join(" AND ")}` : "";

  return axiosInstance
    .post(`/test/s3path?key=movies_data.jsonl&file_type=JSON`, {
      sql: `SELECT s.name, s.imdb_vote, s.release_date, s.ip_id, s.imdb_rating, s.genre, s.distributors, s.ott, s.production, s.certificates FROM s3object s ${whereClause} AND s.imdb_vote > 20000 `,
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Failed to fetch IMDb data:", error);
      throw error;
    });
};
