import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export const granularityItems = [{ value: "none", label: "None" },{ value: "1d", label: "Day" }, { value: "1w", label: "Week" }, { value: "1M", label: "Month" }, { value: "1y", label: "Year" }];
export default function GranularitySelect({ items = [], onSelect }) {
  const [selected, setSelected] = useState({ label:'Day', value:'1d'});
  const defaultItems = [{ value: "1d", label: "Day" }, { value: "1w", label: "Week" }, { value: "1M", label: "Month" }, { value: "1y", label: "Year" }];
  return (
    <Menu as="div" className="relative inline-block text-left mx-2">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-700  px-4 py-1 text-sm font-medium text-gray-200 shadow-sm hover:bg-popup focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {selected ? selected.label : 'Granularity'}
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2  origin-top-right bg-popup rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {items.length > 0 ? items.map((d, i) => (<Menu.Item key={i}>
              {({ active }) => (
                <a
               
                  onClick={() => { setSelected(d); onSelect(d) }}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-200',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {d.label}
                </a>
              )}
            </Menu.Item>)) : defaultItems.map((d, i) => (<Menu.Item key={i}>
              {({ active }) => (
                <a
                  
                  onClick={() => { setSelected(d); onSelect(d) }}
                  href="#"
                  className={classNames(
                    active ? 'bg-legendBg text-gray-100' : 'text-gray-900',
                    'block px-4 py-2 text-sm'
                  )}
                >
                  {d.label}
                </a>
              )}
            </Menu.Item>))}


          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}