export interface GetTwitchDataResponseItem {
  timestamp: number;
  peak_broadcasters: number;
  average_broadcasters: number;
  peak_viewers: number;
  average_viewers: number;
  daily_rank: number;
  max_followers: number;
  new_followers: number;
}

export interface MappedGetTwitchResponseItem {
  averageBroadcasters: number;
  averageViewers: number;
  dailyRank: number;
  peakBroadcasters: number;
  peakViewers: number;
  maxFollowers: number;
  newFollowers: number;
  timestamp: number;
}

export const mapTwitchResponse = (item: GetTwitchDataResponseItem) => ({
  averageBroadcasters: item.average_broadcasters,
  averageViewers: item.average_viewers,
  dailyRank: item.daily_rank,
  peakBroadcasters: item.peak_broadcasters,
  peakViewers: item.peak_viewers,
  maxFollowers: item.max_followers,
  newFollowers: item.new_followers,
  timestamp: item.timestamp * 1000,
});

export const TWITCH_GRID_HEADER_MAP = {
  averageBroadcasters: "Avg broadcasters",
  averageViewers: "Avg viewers",
  dailyRank: "Dialy rank",
  peakBroadcasters: "Peak broadcasters",
  peakViewers: "Peak viewers",
  maxFollowers: "Max followers",
  newFollowers: "New followers",
};
