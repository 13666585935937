import {
  Alert,
  AppLayout,
  Box,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  PropertyFilter,
  SideNavigation,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import React from "react";

import { useCollection } from "@cloudscape-design/collection-hooks";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import PermissionsIcon from "../../../assets/images/PermissionsIcon";
import { CommonPreference } from "../../../components/CommonPreference";
import FormModel from "../../../components/FormModel";
import { navItems } from "../../../layouts/common/menu/side-menu";
import TableEmptyState from "../../../utils/TableEmptyState";
import TableNoMatchState from "../../../utils/TableNoMatchState";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import createTableSortLabelFn from "../../../utils/createTableSortLabelFn";
import getTextFilterCounterText from "../../../utils/getTextFilterCounterText";
import CustomFlashBar from "../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import CreatePermissionsForm from "./forms/create-permissions-form";
import EditPermissionsForm from "./forms/edit-permission-form";
import {
  getPermissionsList,
  getRouteListFn,
  getUnmappedRouteListFn,
} from "./redux/actions/permissions-actions";
import {
  permissionsFilteringProperties,
  permissionsVisibleContentPreference,
} from "./table-properties/permissions-table-properties";
import { useNavigate } from "react-router-dom";
import { SERVICES_GROUP } from "../../../layouts/common/menu/side-menu";

export default function PermissionsListPage() {
  const permissionsList = useSelector((state) => state.permissionsList);
  const ummappedRouteList = useSelector((state) => state.ummappedRouteList);
  const createPermissionRoute = useSelector(
    (state) => state.createPermissionRoute,
  );
  const editPermissionRoute = useSelector((state) => state.editPermissionRoute);
  const routesList = useSelector((state) => state.routesList);
  const [openPermissionDeleteModel, setOpenPermissionDeleteModel] =
    useState(false);
  const [openPermissionEditModel, setOpenPermissionEditModel] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [permissionsAction, setPermissionAction] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const filteredNavItems = navItems.filter(
    (item) =>
      item.type === "section-group" && item?.id === SERVICES_GROUP.ACCOUNT_MANAGEMENT,
  );

  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);
  useEffect(() => {
    if (
      !createPermissionRoute.isLoading &&
      createPermissionRoute.isCompleted &&
      !createPermissionRoute.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: createPermissionRoute.message,
        }),
      );
    } else if (
      !createPermissionRoute.isLoading &&
      createPermissionRoute.isCompleted &&
      createPermissionRoute.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: createPermissionRoute.message,
        }),
      );
    }
  }, [createPermissionRoute]);

  useEffect(() => {
    if (
      !editPermissionRoute.isLoading &&
      editPermissionRoute.isCompleted &&
      !editPermissionRoute.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: editPermissionRoute.message,
        }),
      );
    } else if (
      !editPermissionRoute.isLoading &&
      editPermissionRoute.isCompleted &&
      editPermissionRoute.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: editPermissionRoute.message,
        }),
      );
    }
  }, [editPermissionRoute]);

  const [preferences, setPreferences] = React.useState({
    pageSize: 20,
    wrapLines: false,
    visibleContent: ["permission_name", "api_route"],
  });

  useEffect(() => {
    getRouteListFn();
    getUnmappedRouteListFn();
    getPermissionsList();
  }, []);

  useEffect(() => {
    if (
      !createPermissionRoute.isLoading &&
      createPermissionRoute.isCompleted &&
      !createPermissionRoute.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: createPermissionRoute.message,
        }),
      );
    }
  }, [createPermissionRoute]);

  useEffect(() => {
    if (
      !editPermissionRoute.isLoading &&
      editPermissionRoute.isCompleted &&
      !editPermissionRoute.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: editPermissionRoute.message,
        }),
      );
    }
  }, [editPermissionRoute]);
  useEffect(() => {
    if (permissionsAction) {
      switch (permissionsAction.action) {
        case "edit_permissions": {
          setOpenPermissionEditModel(true);
          return;
        }

        case "delete_permissions": {
          setOpenPermissionDeleteModel(true);
          return;
        }
      }
    }
  }, [permissionsAction]);

  const getRouteFromList = (routeId) => {
    if (routesList.data.length > 0) {
      return (
        <span className=" rounded-full bg-blue-200 px-2.5 py-0.5 text-sm  text-black m-1">
          {_.find(routesList.data, ["routeId", routeId]).routeKey}
        </span>
      );
    }
  };

  const columnDefinitions = [
    {
      header: "Permission",
      id: "permission_name",
      sortingField: "permission_name",
      cell: (e) => <div>{e.permission_name}</div>,
      width: 150,
      maxWidth: 250,
    },
    {
      header: "Route",
      id: "api_route",
      sortingField: "api_route",
      cell: (e) => (
        <div
          className="flex flex-row flex-wrap mx-1"
          style={{ width: "auto" }} // Set the maxWidth for the cell content
        >
          {e.api_route.map((d) => getRouteFromList(d))}
        </div>
      ),
      width: "auto",
    },
    {
      header: "Created At",
      id: "created",
      sortingField: "created",
      cell: (e) => (
        <div>
          {e.created
            ? moment(e.created * 1000).format("MM/DD/yyyy, hh:mm A")
            : ""}
        </div>
      ),
    },
    {
      header: "Updated At",
      id: "last_updated",
      sortingField: "last_updated",
      cell: (e) => (
        <div>
          {e.last_updated
            ? moment(e.last_updated * 1000).format("MM/DD/yyyy, hh:mm A")
            : ""}
        </div>
      ),
    },
  ];

  const permissionsDelete = (ids) => {
    setOpenPermissionDeleteModel(false);
    axiosInstance
      .request({
        url: "permissions",
        method: "DELETE",
        data: {
          permission_name: ids,
        },
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: "Items deleted successfully",
          }),
        );

        setPermissionAction(null);
        setSelectedItems([]);
        getPermissionsList();
      })
      .catch((e) =>
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.message,
          }),
        ),
      );
  };
  const deletingItemsSelected =
    selectedItems.filter((it) => it.state === "deleting").length > 0;
  const rawColumns = columnDefinitions.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(permissionsList.permissionsList, {
    selection: {},
    propertyFiltering: {
      filteringProperties: permissionsFilteringProperties,
      empty:
        permissionsList.isPermissionsListLoading &&
        !permissionsList.isPermissionsListLoadingCompleted ? (
          ""
        ) : (
          <TableEmptyState resourceName={"Permissions"} />
        ),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: "and" });
          }}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: rawColumns[0] } },
  });

  //table components
  const Content = () => {
    return (
      <Table
        {...collectionProps}
        onSelectionChange={(d) => setSelectedItems(d.detail.selectedItems)}
        selectedItems={selectedItems}
        items={items}
        columnDefinitions={columnDefinitions}
        variant="full-page"
        selectionType="multi"
        resizableColumns={true}
        ariaLabels={{
          itemSelectionLabel: (_data, row) => `select ${row.id}`,
          allItemsSelectionLabel: () => "select all",
          selectionGroupLabel: `User selection`,
        }}
        preferences={
          <CommonPreference
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            contentDisplayOptions={permissionsVisibleContentPreference}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: "Next page",
              previousPageLabel: "Previous page",
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        visibleColumns={preferences.visibleContent}
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        columnDisplay={preferences.contentDisplay}
        header={
          <>
            <Header
              variant="h3"
              actions={
                <SpaceBetween direction="horizontal" size="l">
                  <Button
                    variant="primary"
                    onClick={() => {
                      setOpenModel(true);
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    disabled={selectedItems.length !== 1}
                    onClick={() => {
                      setOpenPermissionEditModel(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    disabled={
                      selectedItems.length === 0 || deletingItemsSelected
                    }
                    onClick={() => {
                      setOpenPermissionDeleteModel(true);
                    }}
                  >
                    Delete
                  </Button>
                </SpaceBetween>
              }
              counter={
                selectedItems.length
                  ? "(" +
                    selectedItems.length +
                    "/" +
                    permissionsList.permissionsList.length +
                    ")"
                  : "(" + permissionsList.permissionsList.length + ")"
              }
            >
              Permissions
            </Header>

            <div className="py-5">
              <CustomFlashBar />
            </div>
          </>
        }
        filter={
          <PropertyFilter
            countText={getTextFilterCounterText(filteredItemsCount)}
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: "your choice",
              dismissAriaLabel: "Dismiss",
              clearAriaLabel: "Clear",

              filteringPlaceholder: "Filter users by text, property or value",
              groupValuesText: "Values",
              groupPropertiesText: "Properties",
              operatorsText: "Operators",

              operationAndText: "and",
              operationOrText: "or",

              operatorLessText: "Less than",
              operatorLessOrEqualText: "Less than or equal",
              operatorGreaterText: "Greater than",
              operatorGreaterOrEqualText: "Greater than or equal",
              operatorContainsText: "Contains",
              operatorDoesNotContainText: "Does not contain",
              operatorEqualsText: "Equals",
              operatorDoesNotEqualText: "Does not equal",

              editTokenHeader: "Edit filter",
              propertyText: "Property",
              operatorText: "Operator",
              valueText: "Value",
              cancelActionText: "Cancel",
              applyActionText: "Apply",
              allPropertiesLabel: "All properties",

              tokenLimitShowMore: "Show more",
              tokenLimitShowFewer: "Show fewer",
              clearFiltersText: "Clear filters",
              removeTokenButtonAriaLabel: (token) =>
                `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
              enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            {...propertyFilterProps}
            expandToViewport={true}
          />
        }
      />
    );
  };

  //delete Model
  function DeleteModal({ permissions, visible, onDiscard, onDelete }) {
    const deleteConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onDelete();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === deleteConsentText;

    const isMultiple = permissions.length > 1;
    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={isMultiple ? "Delete permissions" : "Delete permission"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onDelete}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {permissions.length > 0 && (
          <SpaceBetween size="m">
            {isMultiple ? (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {permissions.length} permissions
                </Box>
                ? You can’t undo this action.
              </Box>
            ) : (
              <Box variant="span">
                Permanently delete permission
                <Box variant="span" fontWeight="bold">
                  {permissions[0].id}
                </Box>
                ? You can’t undo this action.
              </Box>
            )}

            <Alert type="warning" statusIconAriaLabel="Warning">
              Proceeding with this action will delete the
              {isMultiple
                ? " permissions with all their content "
                : " role with all its content"}
              and can affect related items.
            </Alert>

            <Box>
              To avoid accidental deletions, we ask you to provide additional
              written consent.
            </Box>

            <ColumnLayout columns={2}>
              <form onSubmit={handleDeleteSubmit}>
                <FormField
                  label={`To confirm this deletion, type "${deleteConsentText}".`}
                >
                  <Input
                    placeholder={deleteConsentText}
                    onChange={(event) => setDeleteInputText(event.detail.value)}
                    value={deleteInputText}
                    ariaRequired={true}
                  />
                </FormField>
              </form>
            </ColumnLayout>
          </SpaceBetween>
        )}
      </Modal>
    );
  }

  //  bread crumbs
  const breadcrumbs = [
    { text: "Account Management" },
    { text: "Permissions", href: "/permissions" },
  ];
  return (
    <>
      <Helmet>
        <title>Pantheon - Permissions</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
          onFollow={onFollowHandler}
          activeHref={window.location.pathname}
          items={filteredNavItems}
          />
        }
        content={<Content />}
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
      <DeleteModal
        visible={openPermissionDeleteModel}
        permissions={selectedItems}
        onDelete={(e) => {
          if (selectedItems.length > 0) {
            permissionsDelete(selectedItems.map((m) => m.permission_name));
          }
        }}
        onDiscard={(e) => {
          setSelectedItems([]);
          setOpenPermissionDeleteModel(false);
        }}
      />

      <FormModel
        open={openModel}
        onClose={() => {
          setOpenModel(false);
        }}
        header="Create Permissions"
      >
        <CreatePermissionsForm
          setCompleted={() => {
            getRouteListFn();
            getUnmappedRouteListFn();
            getPermissionsList();
          }}
          setCancel={() => setOpenModel(false)}
          routesList={ummappedRouteList.data}
        />
      </FormModel>
      <FormModel
        open={openPermissionEditModel}
        onClose={() => {
          setOpenPermissionEditModel(false);
        }}
      >
        <>
          <div className="flex flex-col items-center self-stretch flex-none order-none px-0 py-2 gap-5 rounded-xl dark:bg-white bg-legendBg">
            <PermissionsIcon />
            <h1 className="font-bold text-lg leading-6 text-center not-italic">
              Edit Permissions
            </h1>
          </div>
          <EditPermissionsForm
            defaults={selectedItems ? selectedItems[0] : null}
            setCompleted={() => {
              setSelectedItems([]);
              getRouteListFn();
              getUnmappedRouteListFn();
              getPermissionsList();
            }}
            setCancel={() => setOpenPermissionEditModel(false)}
            routesList={routesList.data}
          />
        </>
      </FormModel>
    </>
  );
}
