import { dispatch } from "../../../app/store";
import { flashMessagesAction } from "./flash-slice";

const addMessageToFlash = (payload) =>
  dispatch(flashMessagesAction.addFlashMessage(payload));
const removeFlashMessage = (payload) =>
  dispatch(flashMessagesAction.removeFlash(payload));

const cleanAllFlashMessage = () =>
  dispatch(flashMessagesAction.cleanFlashMessages());

export { addMessageToFlash, cleanAllFlashMessage , removeFlashMessage};
