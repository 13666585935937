import axiosInstance from "../../../utils/axios";

export const updateDataMapping = ({ id, data }: { id: string; data: any }) => {
  return axiosInstance.put(`/item/${id}/tags/v2`, data);
};

export const getDataMappingIps = ({
  vertical = "Movies",
  from = 0,
  size = 10000,
  includes = [],
  excludes = [],
  sort = [
    {
      release_date: "desc",
    },
  ],
  include_condition = "and",
}) => {
  const requestBody = {};
  requestBody["include"] = includes;
  requestBody["exclude"] = excludes;

  if (sort.length > 0) {
    requestBody["sort"] = sort;
  }

  if (include_condition) {
    requestBody["include_condition"] = include_condition;
  }

  return axiosInstance.request({
    url: `/datamapping/ips?vertical=${vertical}&from=${from}&size=${size}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: requestBody,
  });
};
