import React from "react";
import { RouterProvider } from "react-router";
import PageTracker from "./PageTracker";
import navigation from "./router";

export default function Navigator() {
  return (
    <RouterProvider router={navigation}>
      <PageTracker />
    </RouterProvider>
  );
}
