import {
  Box,
  Button,
  Pagination,
  SpaceBetween,
  StatusIndicator,
  Table
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { CommonPreference } from "../../../../components/CommonPreference";
import { DEFAULT_FILTERING_QUERY } from "../../../../config-global";
import { usePostGenericPreference } from "../../../../services/generic/hooks/usePostGenericPreference";
import {
  generateNestedObject,
  getNestedObjectValue,
} from "../../../../utils/helpers";
import { isNone, isSome, isSomeOrElse } from "../../../../utils/sugarUtils";
import CustomFlashBar from "../../../common/CustomFlashBar";
import { fetchGlobalPref } from "../../../common/redux/global-pref-action";
import { usePersonalListData } from "./api/hooks/usePersonalListData";
import {
  columnDefinitions,
  getVisibleContentPreference,
} from "./gridConfig";

const defaultPreferences = {
  visibleContent: [
    "timestamp",
    "filename",
    "start_date",
    "end_date",
    "report_type",
    "vertical",
    "user_name",
  ],
};

export const ReportTable = ({ category, activeTabId, refreshTrigger }) => {
  const { user } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortingColumn, setSortingColumn] = useState(null);
  const [sortingDescending, setSortingDescending] = useState(false);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);
  const [apiParams, setApiParams] = useState();
  const [apiEnabled, setApiEnabled] = useState(false);
  const [count, setCount] = useState(0);
  const [preferences, setPreferences] = useState(defaultPreferences);
  const PREFERENCE_PATH = `tools.offlineReport.personal.preferences`;
  const FILTERS_PATH = `tools.offlineReport.personal.filters`;
  const [lastRefreshTime, setLastRefreshTime] = useState(null);
  const [refreshStatus, setRefreshStatus] = useState(null);

  useEffect(() => {
    (async () => {
      await fetchGlobalPref(user.username);
    })();
  }, [user.username]);

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );
  const hasApiCompleted = useSelector(
    (state) => state?.globalPersonalPref?.hasApiCompleted,
  );

  useEffect(() => {
    const pref = getNestedObjectValue(globalPref, PREFERENCE_PATH);

    setPreferences(isSome(pref) ? pref : defaultPreferences);
    if (hasApiCompleted) setApiEnabled(true);
  }, [globalPref, hasApiCompleted, PREFERENCE_PATH]);

  const { mutate: postGenericPreference } = usePostGenericPreference();

  const { 
    data: personalListData, 
    isLoading, 
    refetch,
    error
  } = usePersonalListData(
    {
      ...apiParams?.queryParams,
      size: preferences?.pageSize || 20,
    },
    apiEnabled,
  );

  useEffect(() => {
    if (apiEnabled) {
      refetch();
    }
  }, [refreshTrigger, apiEnabled, refetch]);

  useEffect(() => {
    if (personalListData?.count) setCount(personalListData.count);
  }, [personalListData, count]);

  useEffect(() => {
    setApiParams(prevParams => ({
      ...prevParams,
      queryParams: {
        ...prevParams?.queryParams,
        category: category
      }
    }));
    setApiEnabled(true);
  }, [category]);

  const handlePreferenceChange = ({ detail }) => {
    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      PREFERENCE_PATH,
      detail,
    );

    postGenericPreference(newGlobalPreferences);
  };

  const getPaginationCount = (count, preferences) =>
    Math.ceil(
      (count > 10000 ? 10000 : count) /
      isSomeOrElse(preferences?.pageSize, 10),
    );

  const getPreferencesAfterDelete = (name) => {
    const filterPreference = isSomeOrElse(
      getNestedObjectValue(globalPref, FILTERS_PATH),
      {},
    );
    const currentFilters = { ...filterPreference };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return getExistingPreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const filterPreference = isSomeOrElse(
      getNestedObjectValue(globalPref, FILTERS_PATH),
      {},
    );
    const currentFilters = { ...filterPreference };
    currentFilters[name] = { ...filteringQuery };

    if (isUpdate) delete currentFilters[prevName];

    return getExistingPreferences(currentFilters);
  };

  const getExistingPreferences = (currentFilters) => {
    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      FILTERS_PATH,
      currentFilters,
    );

    return newGlobalPreferences;
  };

  const handleRefresh = useCallback(async () => {
    setRefreshStatus('loading');
    try {
      await refetch();
      setLastRefreshTime(new Date().toLocaleString());
      setRefreshStatus('success');
    } catch (error) {
      console.error('Refresh failed:', error);
      setRefreshStatus('error');
    } finally {
      setRefreshStatus(null);
    }
  }, [refetch]);

  return (
    <>
      <Table
        columnDefinitions={columnDefinitions(activeTabId)}
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        columnDisplay={preferences.contentDisplay}
        onSortingChange={(event) => {
          setSortingDescending(event.detail.isDescending);
          setSortingColumn(event.detail.sortingColumn);
        }}
        trackBy="timestamp"
        loading={isLoading || refreshStatus === 'loading'}
        loadingText="Loading resources"
        stickyHeader
        resizableColumns={true}
        variant="full-page"
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        stickyColumns={preferences.stickyColumns}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No items</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No items to display.
            </Box>
          </Box>
        }
        error={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              Error displaying the data.
            </Box>
          </Box>
        }
        header={
          <SpaceBetween direction="vertical" size="xs">
            <CustomFlashBar />
            
          </SpaceBetween>
        }
        preferences={
          <CommonPreference
            preferences={preferences}
            onConfirm={handlePreferenceChange}
            contentDisplayOptions={getVisibleContentPreference()}
          />
        }
        pagination={
          <Pagination
            currentPageIndex={currentPage}
            pagesCount={getPaginationCount(count, preferences)}
            onChange={(page) => {
              setCurrentPage(page.detail.currentPageIndex);
            }}
          />
        }
        filter={
          <div className="flex flex-row gap-2">
            {/* <TableFilter
              filteringQuery={filteringQuery}
              filteringProperties={filteringProperties}
              setFilteringQuery={setFilteringQuery}
              getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
              getPreferencesAfterDelete={getPreferencesAfterDelete}
              filtersPath={FILTERS_PATH}
            /> */}
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                onClick={handleRefresh}
                disabled={isLoading || refreshStatus === 'loading'}
                loading={refreshStatus === 'loading'}
              />
              {lastRefreshTime && (
                <Box variant="p" color="text-body-secondary">
                  Last updated: {lastRefreshTime}
                </Box>
              )}
              {error && (
                <StatusIndicator type="error">
                  Error loading data. Please try again.
                </StatusIndicator>
              )}
            </SpaceBetween>
          </div>
        }
        items={personalListData || []}
      />
    </>
  );
};
