import { TrendsContent, TrendsFooter, TrendsHeader } from "../component/Trends";

const title = "Twitch Trends";

export const twitchTrends = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Based on Daily Viewership in twitch",
    header: () => <TrendsHeader title={title} />,
    content: () => <TrendsContent vertical="gaming" platform="twitch" sort="daily_rank" />,
    footer: () => <TrendsFooter linkHref="/platforms/twitch?tab=trends&category=gaming" />,
  },
};