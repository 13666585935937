import React, { useEffect, useState } from "react";
import { AgCharts } from "ag-charts-react";
import { CHART_TYPES, useGetChartTheme } from "../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../utils/shortenInteger";

interface ChartData {
    timestamp: string;
    date: string;
    Films_English: number;
    Films_Non_English: number;
    TV_English: number;
    TV_Non_English: number;
}

interface NetflixSummaryChartProps {
    data: ChartData[];
    showViews: boolean;
}

const tooltip = {
    renderer: ({ datum, xKey }) => {
        const date = new Date(datum[xKey]);
        const formattedDate = date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });

        return {
            title: `<span style="font-size: 14px; ">${formattedDate}</span>`,
            content: `
                <b>Movies (English):</b> ${shortenInteger(datum.Films_English)}<br>
                <b>Movies (Non-English):</b> ${shortenInteger(datum.Films_Non_English)}<br>
                <b>Series (English):</b> ${shortenInteger(datum.TV_English)}<br>
                <b>Series (Non-English):</b> ${shortenInteger(datum.TV_Non_English)}
            `,
        };
    },
};

export const NetflixSummaryChart: React.FC<NetflixSummaryChartProps> = ({ 
    data, 
    showViews
}) => {
    const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
    const [options, setOptions] = useState({});

    useEffect(() => {
        if (data && data.length > 0) {
            const processedData = data.map((item) => ({
                ...item,
                timestamp: parseInt(item.timestamp) * 1000,
            }));

            const newOptions = {
                theme: theme,
                data: processedData,
                zoom: { enabled: true, enableSelecting: true },
                series: [
                    {
                        type: "line",
                        xKey: "timestamp",
                        yKey: "Films_English",
                        yName: "Movies (English)",
                        tooltip,
                    },
                    {
                        type: "line",
                        xKey: "timestamp",
                        yKey: "Films_Non_English",
                        yName: "Movies (Non-English)",
                        tooltip,
                    },
                    {
                        type: "line",
                        xKey: "timestamp",
                        yKey: "TV_English",
                        yName: "Series (English)",
                        tooltip,
                    },
                    {
                        type: "line",
                        xKey: "timestamp",
                        yKey: "TV_Non_English",
                        yName: "Series (Non-English)",
                        tooltip,
                    },
                ],
                axes: [
                    {
                        type: "time",
                        position: "bottom",
                        label: { format: "%b %Y" },
                    },
                    {
                        type: "number",
                        position: "left",
                        keys: ["Films_English", "Films_Non_English", "TV_English", "TV_Non_English"],
                        title: { text: showViews ? "Views" : "Hours Viewed" },
                        label: {
                            formatter: (params) => `${(params.value / 1_000_000).toFixed(0)}M`,
                        },
                    },
                ],
                legend: { position: "right" },
            };

            setOptions(newOptions);
        }
    }, [data, theme, showViews]);

    return (
        <div className="h-[300px] w-full rounded-3xl overflow-hidden">
            <AgCharts options={options} style={{ height: "100%" }} />
        </div>
    );
};
