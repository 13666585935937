import { SERVICES, TYPES } from "../../../config-global";
import { CATEGORIES, categoryOptions } from "../utils/categoryUtils";

const module = "youtube";
const TABS = {
  TRAILERS: "trailers",
};

export const youtubeApiParams = {
  type: TYPES.PERSONAL,
  service: SERVICES.PLATFORMS,
  module,
  tab: TABS.TRAILERS,
};

const youtubeCategories = [
  CATEGORIES.MOVIES,
  CATEGORIES.SERIES,
  CATEGORIES.GAMING,
  CATEGORIES.KIDSMOVIES,
  CATEGORIES.KIDSTV,
];

export const youtubeCategoryOptions = categoryOptions.filter((option) =>
  youtubeCategories.includes(option.value),
);

export const defaultCategory = CATEGORIES.MOVIES;
