type VideoTrendsItemType = {
  videoId: string;
  timestamp: number;
  views: number;
  likes: number;
  comments: number;
};

export const mapToUpdateTrends = (data: Array<VideoTrendsItemType>) => {
  const trendsData = data.map((trend) => ({
    ...trend,
    timestamp: trend.timestamp * 1000, // Convert timestamp to milliseconds
  }));

  // Extract the initial values to use as a baseline for growth calculation
  const initialViews = trendsData[0]?.views;
  const initialLikes = trendsData[0]?.likes;
  const initialComments = trendsData[0]?.comments;

  return trendsData.map((trend, index) => {
    // Calculate the growth percentages based on the initial data point
    const viewGrowth = index === 0 ? 0 : ((trend.views - initialViews) / initialViews) * 100;
    const likeGrowth = index === 0 ? 0 : ((trend.likes - initialLikes) / initialLikes) * 100;
    const commentGrowth = index === 0 ? 0 : ((trend.comments - initialComments) / initialComments) * 100;

    return {
      ...trend,
      viewGrowth: isNaN(viewGrowth) ? 0 : viewGrowth,
      likeGrowth: isNaN(likeGrowth) ? 0 : likeGrowth,
      commentGrowth: isNaN(commentGrowth) ? 0 : commentGrowth,
    };
  });
};
