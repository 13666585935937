import {
  ButtonDropdown,
  PropertyFilterProps,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC, useState } from "react";
import { isNone } from "../../utils/sugarUtils";
import { DeleteFilterModal } from "./DeleteFilterModal";
import { SaveOrUpdateFilterModal } from "./SaveOrUpdateFilterModal";

export type GridFilterProps = {
  colId: string;
  filter: string | number;
  filterType: string;
  type: string;
};

type AdvancedFiltersProps = {
  filter: PropertyFilterProps.Query | GridFilterProps;
  defaultFilters?: any;
  selectedFilterName: string;
  getPreferencesAfterDelete: (name: string) => void;
  getPreferencesAfterSaveOrUpdate: (
    name: string,
    prevName: string,
    isUpdate: boolean,
  ) => void;
  handleClearFilter: () => void;
};

export const AdvancedFilters: FC<AdvancedFiltersProps> = ({
  filter,
  defaultFilters = {},
  selectedFilterName,
  handleClearFilter,
  getPreferencesAfterDelete,
  getPreferencesAfterSaveOrUpdate,
}) => {
  const initialModalStatus = {
    save: false,
    update: false,
    delete: false,
  };

  const [modalStatus, setModalstatus] = useState({ ...initialModalStatus });

  const isDefaultFilter =
    Object.keys(defaultFilters).includes(selectedFilterName);

  const getFilterItems = (disabled: boolean) => [
    { text: "Save as new filter", id: "save", disabled: disabled },
    {
      text: "Update current filter",
      id: "update",
      disabled: disabled || isDefaultFilter,
    },
    {
      text: "Delete current filter",
      id: "delete",
      disabled: disabled || isDefaultFilter,
    },
  ];

  const onDismiss = () => setModalstatus({ ...initialModalStatus });

  return (
    <>
      <SpaceBetween direction="horizontal" size="xs">
        <ButtonDropdown
          variant="primary"
          items={getFilterItems(isNone(filter))}
          onItemClick={(event) => {
            setModalstatus({
              ...initialModalStatus,
              [event.detail.id]: true,
            });
          }}
          mainAction={{
            text: "Clear Filter",
            onClick: handleClearFilter,
          }}
        />
      </SpaceBetween>
      <SaveOrUpdateFilterModal
        title={`${modalStatus.save ? "Save" : "Update"} Filter`}
        visible={modalStatus.save || modalStatus.update}
        isUpdate={modalStatus.update}
        onDismiss={onDismiss}
        filterName={modalStatus.save ? "" : selectedFilterName}
        getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
      />
      <DeleteFilterModal
        title="Delete"
        visible={modalStatus.delete}
        onDismiss={onDismiss}
        getPreferencesAfterDelete={getPreferencesAfterDelete}
        name={selectedFilterName}
        handleClearFilter={handleClearFilter}
      />
    </>
  );
};
