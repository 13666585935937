import axiosInstance from "../../../utils/axios";

export const getComparisonData = ({
  ipIds=[],
  datapoints=[],
  startTs,
  endTs,
}) => {
  if (!ipIds.length) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/compare/ips",
    method: "POST",
    data: {
      ip_ids: ipIds,
      datapoints: datapoints,
      start_ts: startTs,
      end_ts: endTs,
    },
  });
};
  
export const getGeneratedLabelsData = ({
  q,
  date,
}) => {
  if (!q || !date || q?.length === 0 || date?.length === 0) {
    return Promise.resolve({ data: null });
  }

  return axiosInstance.request({
    url: "/pantheon/events/generatelabels",
    method: "GET",
    params: {
      q,
      date,
    },
  });
};