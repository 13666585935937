import React from 'react';
import Icon from './icons/Permissions.png';

export default function PermissionsIcon() {
  return (
    <div className="text-white pt-5">
      <img src={Icon} className="h-10 w-10" alt="PermissionsIcon" />
    </div>
  );
}
