export const agChartLightTheme = {
  baseTheme: "ag-default",
};

export const agChartDarkTheme = {
  baseTheme: "ag-default-dark",
  overrides: {
    common: {
      background: { fill: "#161D26" },
    },
  },
};

export const agChartVividDarkTheme = {
  baseTheme: "ag-vivid-dark",
  overrides: {
    common: {
      background: { fill: "#161D26" },
    },
  },
};

export const agChartPolychromaDarkTheme = {
  baseTheme: "ag-polychroma-dark",
  overrides: {
    common: {
      background: { fill: "#161D26" },
    },
  },
};
