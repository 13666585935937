import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchTrailersDataApi = createAsyncThunk(
  "itemData/fetchTrailersDataApi",
  async ({ id }) => {

    const requestBody = {
      "sort": [ { "publishedAt": "desc" } ],
      "include": [ { "match": { "ip_id": id } } ],
      "exclude": [],
      "condition": "and"
    };
    const response = await axiosInstance.request({
      url: `/youtube/v1/videos/list?from=0&size=500`,
      method: "POST",
      data: requestBody,
    });
    return response;
  }
);

const itemTrailerDataSlice = createSlice({
  name: "itemTrailerData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTrailersDataApi.pending, (state) => {
        state.loading = true;
        state.data = [];
      })
      .addCase(fetchTrailersDataApi.fulfilled, (state, action) => {
        state.completed = true;
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchTrailersDataApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const itemTrailerDataReducer = itemTrailerDataSlice.reducer;

export default itemTrailerDataReducer;

export const itemTrailerDataActions = itemTrailerDataSlice.actions;
