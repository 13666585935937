import { useCollection } from "@cloudscape-design/collection-hooks";
import { PropertyFilter, Table } from "@cloudscape-design/components";
import React from "react";
import createTableSortLabelFn from "../../../../utils/createTableSortLabelFn";
import getTextFilterCounterText from "../../../../utils/getTextFilterCounterText";
import {
  awardColumnDefinition,
  organizationFilteringProperties,
} from "../config";

export const AwardsTable = ({ itemData }: { itemData: any }) => {
  const rawColumns = awardColumnDefinition.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  const { items, collectionProps, propertyFilterProps, filteredItemsCount } =
    useCollection(itemData.data.awards, {
      sorting: { defaultState: { sortingColumn: rawColumns[0] } },
      propertyFiltering: {
        filteringProperties: organizationFilteringProperties,
      },
    });

  return (
    <Table
      {...collectionProps}
      columnDefinitions={awardColumnDefinition}
      items={items}
      variant="embedded"
      filter={
        <PropertyFilter
          countText={getTextFilterCounterText(filteredItemsCount)}
          {...propertyFilterProps}
          i18nStrings={{
            filteringAriaLabel: "your choice",
            dismissAriaLabel: "Dismiss",
            clearAriaLabel: "Clear",

            filteringPlaceholder: "Filter users by text, property or value",
            groupValuesText: "Values",
            groupPropertiesText: "Properties",
            operatorsText: "Operators",

            operationAndText: "and",
            operationOrText: "or",

            operatorLessText: "Less than",
            operatorLessOrEqualText: "Less than or equal",
            operatorGreaterText: "Greater than",
            operatorGreaterOrEqualText: "Greater than or equal",
            operatorContainsText: "Contains",
            operatorDoesNotContainText: "Does not contain",
            operatorEqualsText: "Equals",
            operatorDoesNotEqualText: "Does not equal",

            editTokenHeader: "Edit filter",
            propertyText: "Property",
            operatorText: "Operator",
            valueText: "Value",
            cancelActionText: "Cancel",
            applyActionText: "Apply",
            allPropertiesLabel: "All properties",

            tokenLimitShowMore: "Show more",
            tokenLimitShowFewer: "Show fewer",
            clearFiltersText: "Clear filters",
            removeTokenButtonAriaLabel: (token) =>
              `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
            enteredTextLabel: (text) => `Use: "${text}"`,
          }}
          {...propertyFilterProps}
          expandToViewport={true}
        />
      }
    />
  );
};
