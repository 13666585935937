import React, { useState, useEffect } from "react";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { ButtonDropdown, Header, SpaceBetween } from "@cloudscape-design/components";
import DatapointComparison from "../../../components/datapoint-comparison/DatapointComparison";
import { parametersFromUrlParams, urlParamsFromParameters } from "../../../components/datapoint-comparison/utils";
import { useSearchParams } from "react-router-dom";
import { WeightsModal } from "./modals/WeightsModal";
import { DEFAULT_WEIGHTS_OBJECTS } from "../../ranking/constants";
import { useMetrics } from "./hooks/useMetrics";
import { CustomMetricsModal } from "./modals/CustomMetricsModal";
import { SERVICES, TYPES } from "../../../config-global";
import { useGetGenericPreference_V2 } from "../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import _ from "lodash";

export const CompareTool = () => {

  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.TOOLS, module: "compare" };

  const [searchParams] = useSearchParams();

  const title = "Compare";
  const breadcrumbs = [
    { text: "Tools", href: "/" },
    { text: "Compare" },
  ];
  const defaultProperties = {
    metricKeys: [
      "weighted_score", 
      "global_wiki_score", 
      "global_imdb_score", 
      "global_rt_score", 
      "global_piracy_score", 
      "global_yt_score", 
      "global_steam_score", 
      "global_twitch_score"
    ],
    activeMetricKey: "weighted_score",
  };

  const [properties, setProperties] = useState(defaultProperties);
  const [weightsModalVisible, setWeightsModalVisible] = useState(false);
  const [weightsObjects, setWeightsObjects] = useState(DEFAULT_WEIGHTS_OBJECTS);
  const [customMetricsModalVisible, setCustomMetricsModalVisible] = useState(false);
  const [customMetrics, setCustomMetrics] = useState([]);

  const { metrics } = useMetrics({ weightsObjects });

  const { data: preferenceData } = useGetGenericPreference_V2({ apiParams });

  useEffect(() => {
    const pref = preferenceData?.[0]?.data;

    if (pref) {
      if (pref.customMetrics) {
        setCustomMetrics(pref.customMetrics);
      }
    }
  }, [preferenceData]);

  useEffect(() => {
    setProperties(parametersFromUrlParams(searchParams) || defaultProperties);
  }, [searchParams]);

  useEffect(() => {
    if (!properties) return;
    const urlParams = urlParamsFromParameters(properties);
    window.history.pushState({}, "", `?${urlParams}`);
  }, [properties]);

  return (
    <>
      <Layout
        title={title}
        breadcrumbs={breadcrumbs}
        navItems={navItems as NavItemsWithId}
        content={
          <div className="flex flex-col gap-4">
            <Header
              variant="h2"
              actions={
                <SpaceBetween size="s">
                  <ButtonDropdown
                    items={[
                      /*{ text: "Manage Z-Score weights", id: "manage-weights", disabled: false },*/
                      { text: "Manage custom metrics", id: "manage-custom-metrics", disabled: false },
                    ]}
                    onItemClick={({ detail }) => {
                      switch (detail.id) {
                        case "manage-weights":
                          setWeightsModalVisible(true);
                          break;
                        case "manage-custom-metrics":
                          setCustomMetricsModalVisible(true);
                          break;
                        default:
                          break;
                      }
                    }}
                    expandToViewport
                  >
                    Actions
                  </ButtonDropdown>
                </SpaceBetween>
              }
            >
              Compare Titles
            </Header>
            <DatapointComparison 
              metrics={[...customMetrics, ...metrics]}
              properties={properties}
              setProperties={setProperties}
              defaultProperties={undefined}
              options={{
                container: {
                  disablePadding: true,
                  disableBorder: true,
                },
                chart: {
                  chartNotes: {
                    enabled: true,
                  },
                },
                summary: {
                  enabled: true,
                },
              }}
            />
          </div>
        }
      />
      <WeightsModal
        visible={weightsModalVisible}
        setVisible={setWeightsModalVisible}
        weightsObjects={weightsObjects}
        setWeightsObjects={setWeightsObjects}
      />
      <CustomMetricsModal
        visible={customMetricsModalVisible}
        setVisible={setCustomMetricsModalVisible}
        setCustomMetrics={setCustomMetrics}
      />
    </>
  );
};