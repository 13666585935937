import { AgCharts } from "ag-charts-react";
import React from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../../utils/shortenInteger";

const tooltip = {
  renderer: ({ datum }) => {
    return {
      title: `<span style="font-size: 16px; color: black">${datum.distributor}</span>`,
      content: ` <b># of Movies:</b> ${
        datum.count
      } <br /> <b>Domestic Collection:</b> ${shortenInteger(
        datum.domestic,
      )}<br/> <b>International Collection:</b> ${shortenInteger(
        datum.international,
      )}<br/> <b>Worldwide Collection:</b> ${shortenInteger(
        datum.worldwide,
      )}<br/> <b>Production Budget:</b> ${shortenInteger(
        datum.productionBudget,
      )}<br/> <b>Profit Margin:</b> ${datum.profitMargin.toFixed(2)}%<br/> `,
    };
  },
};

export const BoByStudioChart = ({ data, isLoading, error }) => {
  const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
  const top15 = data?.slice(0, 15);
  const options = top15
    ? {
        theme: theme,
        legend: { enabled: true, item: { label: { fontFamily: "Open Sans"}} },
        data: top15,
        animation: { enabled: true },
        series: [
          {
            type: "bar",
            direction: "horizontal",
            xKey: "distributor",
            yKey: "domestic",
            yName: "Domestic BoxOffice",
            stackGroup: "BO",
            tooltip,
          },
          {
            type: "bar",
            direction: "horizontal",
            xKey: "distributor",
            yKey: "international",
            yName: "International BoxOffice",
            stackGroup: "BO",
            tooltip,
          },
          {
            type: "bar",
            direction: "horizontal",
            xKey: "distributor",
            yKey: "productionBudget",
            yName: "Production Budget",
            tooltip,
          },
          {
            type: "bubble",
            xKey: "count",
            yKey: "distributor",
            yName: "Number of Movies",
            sizeKey: "profitMargin",
            labelKey: "count",
            tooltip,
          },
        ],
        axes: [
          {
            position: "left",
            type: "category",
            keys: ["distributor"],
            nice: true,
            gridLine: { enabled: false },
            line: { enabled: false },
            label: {fontFamily: "Open Sans"},
          },
          {
            position: "bottom",
            type: "number",
            keys: ["domestic", "international", "productionBudget"],
            label: {
              formatter: function (params) {
                const value = params.value;
                return `${(value / 1_000_000).toFixed(0)} M`;
              },
              fontFamily: "Open Sans",
            },
            line: { width: 0.5 },
            interval: { step: 500000000 },
            gridLine: { enabled: true },
            title: { text: "$ in Millions",fontFamily: "Open Sans", },
          },
          {
            position: "top",
            type: "number",
            keys: ["count"],
            label: {
              formatter: function (params) {
                const value = params.value;
                return `${value}`;
              },
              fontFamily: "Open Sans",
            },
          },
        ],
      }
    : null;

  if (isLoading) {
    return (
      <div className="flex flex-row justify-center h-200">
        <CustomLoadingOverlay />
      </div>
    );
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  if (!options) {
    return <div>No data to display</div>;
  }

  return (
    <div className="h-200">
      <AgCharts options={options} style={{ height: "100%" }} />
    </div>
  );
};
