import { Button, Icon, Link } from "@cloudscape-design/components";
import React, { useMemo } from "react";
import { convertData } from "../../../../../../components/datapoint-comparison/utils";
import { VerticalIcon } from "../../../../../../components/VerticalIcon";
import renderTrend from "../../../../../../utils/trendIcon";

export const useGridConfig = ({
  data,
  selectedVertical,
  setSearchParams,
  setSelectedTab,
}) => {
  
  const rowData = useMemo(() => {
    if (!data) return [];
    const filteredData = data.filter((x) => selectedVertical.value === "all" || x.vertical === selectedVertical.value);
    const convertedData = convertData(filteredData).map(x => ({ ...x, ...x.originalData }));
    return convertedData;
  }, [data, selectedVertical]);

  const columnDefs = [
    {
      field: "rank",
      headerText: "Rank",
      suppressHeaderMenuButton: true,
      width: 150,
      minWidth: 100,
      cellRenderer: (params) => {
        if (params.data.rank_change != null) {
          const rankTrend = params.data.rank_change > 0 ? "up" : params.data.rank_change < 0 ? "down" : "same";
          const newParams = { ...params, data: { ...params.data, rank_trend: rankTrend } };
          return renderTrend(newParams, "rank", "rank_change", "rank_trend");
        }
        return params.value;
      },
    },
    {
      field: "ip",
      headerName: "Title",
      minWidth: 300,
      flex: 1,
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        let iconVariant;
        if (params.data.tracked === true) {
          iconVariant = "success";
        } else if (params.data.tracked === false) {
          iconVariant = "disabled";
        } else {
          iconVariant = "disabled";
        }
  
        if (!params.value) return null;
        const ipId = params.data.ip_id;
        const release_year = params.data.release_date ? new Date(Date.parse(params.data.release_date)).getFullYear() : "N/A";
        return (
          <div className="flex gap-2 mt-2.5 align-middle">
            <Icon
              name={"status-positive"}
              variant={iconVariant}
            />
            <span className="text-slate-500 flex justify-center">
              <VerticalIcon vertical={params.data.vertical} />
            </span>
            <Link href={`/item/${ipId}`}>
              <span>{params.data.ip}</span>
            </Link>
            <span className="text-xs text-slate-500 mt-0.5">
              {release_year}
            </span>
          </div>
        );
      },
    },
    {
      field: "indexed_volume",
      headerName: "Search Interest",
      suppressHeaderMenuButton: true,
      valueGetter: (params) => params.data.indexed_volume * 1000000,
      valueFormatter: (params) => params.value.toLocaleString(undefined, { maximumFractionDigits: 1 }),
    },
    {
      headerName: "Actions",
      suppressHeaderMenuButton: true,
      cellRenderer: (params) => {
        return (
          <Button
            variant="inline-link"
            onClick={() => {
              setSearchParams(prev => {
                prev.set("title", params.data.ip_id);
                prev.set("tab", "insights");
                setSelectedTab("insights");
                return prev;
              });
            }}
          >
            <span className="text-xs">View title insights</span>
          </Button>
        );
      },
    },
  ];

  return {
    rowData,
    columnDefs,
  };
};