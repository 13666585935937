export const filteringProperties = [
  {
    propertyLabel: "Name",
    key: "name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Username",
    key: "username",
    groupValuesLabel: "Username",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Email",
    key: "email",
    groupValuesLabel: "Email",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Organization",
    key: "organization",
    groupValuesLabel: "Organization",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Status",
    key: "status",
    groupValuesLabel: "Status",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Role",
    key: "role",
    groupValuesLabel: "Role",
    operators: ["=", "!="],
  },
  {
    propertyLabel: "Enabled",
    key: "enabled",
    groupValuesLabel: "Enabled",
    operators: ["=", "!="],
  },
].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));
export const visibleContentPreference = [
  {
    id: "name",
    label: "Name",
    visible: true,
  },
  {
    id: "username",
    label: "Username",
    visible: true,
    alwaysVisible: true,
  },
  {
    id: "email",
    label: "Email",
    visible: true,
  },
  {
    id: "organization",
    label: "Organization",
    visible: true,
  },
  {
    id: "status",
    label: "Status",
    visible: true,
  },
  {
    id: "role",
    label: "Role",
    visible: true,
  },
  {
    id: "enabled",
    label: "Enabled",
    visible: true,
  },
  {
    id: "createdAt",
    label: "Created At",
    visible: true,
  },
  {
    id: "updatedAt",
    label: "Updated At",
    visible: true,
  }
];
