import React, { useEffect, useState } from "react";
import { useGetGenreTimeseriesData } from "../api/hooks/useGetGenreTimeseriesData";
import { useGetChartTheme, CHART_TYPES } from "../../../hooks/UseTheme/useGetChartTheme";
import { AgCharts } from "ag-charts-react";
import { useGenreTrendsChartConfig } from "../hooks/useGenreTrendsChartConfig";
import { Select, SpaceBetween, Box, Multiselect, Checkbox, Container, FormField, Spinner } from "@cloudscape-design/components";
import { defaultOverrides } from "../chartConfig";
import { TYPE_LABELS } from "../constants";

const viewOptions = [
  { label: "Counts", value: "counts" },
  { label: "Distribution", value: "distribution" },
];

const granularityOptions = [
  { label: "3 months", value: "3M" },
  { label: "6 months", value: "6M" },
  { label: "1 year", value: "1Y" },
];

const GenreTrendsCharts = () => {

  const [ genreInfo, setGenreInfo ] = useState([]);
  const [ genreCounts, setGenreCounts ] = useState({});
  const [ genreOptions, setGenreOptions ] = useState([]);
  const [ selectedViewOption, setSelectedViewOption ] = useState(viewOptions[0]);
  const [ selectedGenres, setSelectedGenres ] = useState([]);
  const [ selectedAggregations, setSelectedAggregations ] = useState([]);
  const [ selectedGranularity, setSelectedGranularity ] = useState(granularityOptions[2]);

  const [ isLoadedFirst, setIsLoadedFirst ] = useState(false);


  const {
    data: genreTimeseriesResponse,
    isLoading,
    error,
  } = useGetGenreTimeseriesData(selectedGranularity.value);
  const { theme: chartTheme } = useGetChartTheme(CHART_TYPES.DEFAULT, defaultOverrides);
  const chartOptions = useGenreTrendsChartConfig({
    genreInfo: genreInfo,
    genreCounts: genreCounts,
    selectedMetric: selectedViewOption?.value,
    visibleGenreValues: selectedGenres.map(genre => genre.value),
  });


  const processTimeseriesData = (data) => {
    const metadata = data?.metadata;
    const aggregatedVerticals = selectedAggregations.length <= 1 ? [] : selectedAggregations.map(v => v.value);

    const alreadyAggGenres = [];
    const genreInfo = metadata?.genres.map((genre, i) => {
      if (aggregatedVerticals.includes(genre.vertical)) {
        if (alreadyAggGenres.includes(genre.value)) return null;
        const genresWithValue = metadata.genres.filter(g => g.raw_value === genre.raw_value && aggregatedVerticals.includes(g.vertical));
        genresWithValue.forEach(g => alreadyAggGenres.push(g.value));
        return {
          ...genre,
          value: `${genre.raw_value}_aggregated`,
          vertical: "Aggregated",
          aggregate_indices: genresWithValue.map(g => metadata.genres.indexOf(g)),
          aggregated_verticals: genresWithValue.map(g => g.vertical),
        };
      } else {
        return {
          ...genre,
          aggregate_indices: [i],
          aggregated_verticals: [genre.vertical],
        };
      }
    }).filter(g => g !== null);
    setGenreInfo(genreInfo);

    const genreCounts = Object.keys(data?.genre_counts).reduce((acc, date) => {
      acc[date] = genreInfo.map(genre => {
        return genre.aggregate_indices.reduce((a, i) => a + data.genre_counts[date][i], 0);
      });
      return acc;
    }, {});
    setGenreCounts(genreCounts);

    const newGenreOptions = [...new Set(genreInfo.map(genre => genre.vertical))].map(vertical => (
      {
        label: vertical,
        options: genreInfo.filter(genre => genre.vertical === vertical).map(genre => (
          { 
            label: `${genre.label} (${TYPE_LABELS[genre.type]})`, 
            value: genre.value,
            genre: genre,
          }
        )),
      }
    ));
    setGenreOptions(newGenreOptions);

    const newSelectedGenres = [];
    selectedGenres.forEach(genre => {
      if (genre.value.endsWith("_aggregated")) {
        const optionsWithSameRawValue = newGenreOptions.map(o => o.options.filter(o => o.genre.raw_value === genre.genre.raw_value && genre.genre.aggregated_verticals.includes(o.genre.vertical))).flat();
        optionsWithSameRawValue.forEach(o => newSelectedGenres.push(o));
        if (genreInfo.find(info => info.value === genre.value)) {
          newSelectedGenres.push(genre);
        }
      } else {
        const optionsWithAggregate = newGenreOptions.map(o => o.options.filter(o => o.genre.aggregated_verticals.includes(genre.genre.vertical) && o.genre.raw_value === genre.genre.raw_value)).flat();
        optionsWithAggregate.forEach(o => newSelectedGenres.push(o));
      }
    });
    setSelectedGenres(newSelectedGenres);

    if (!isLoadedFirst && genreInfo?.length > 0) {
      const genreTotals = genreInfo.map((genre, i) => {
        const total = Object.values(data?.genre_counts).reduce((a, b) => a + b[i], 0);
        return {
          genre,
          total,
        };
      }).sort((a, b) => b.total - a.total);
      setSelectedGenres(genreTotals.slice(0, 5).map(genreTotal => (
        { 
          label: `${genreTotal.genre.label} (${TYPE_LABELS[genreTotal.genre.type]})`, 
          value: genreTotal.genre.value,
          genre: genreTotal.genre,
        }
      )));
      setIsLoadedFirst(true);
    }
  };

  useEffect(() => {
    if (genreTimeseriesResponse) {
      processTimeseriesData(genreTimeseriesResponse.data);
    }
  }, [genreTimeseriesResponse, selectedAggregations]);

  return (
    isLoading ? (
      <div className="w-full h-[200px] flex items-center justify-center">
        <Spinner size="large" />
      </div>
    ) : (
      <SpaceBetween size="m" direction="vertical">
        <Container>
          <Box float="left">
            <div className="flex space-x-3 items-start">
              <FormField description="Metric">
                <Select
                  options={viewOptions}
                  selectedOption={selectedViewOption}
                  onChange={({ detail }) => setSelectedViewOption(detail.selectedOption)}
                />
              </FormField>
              <FormField description="Granularity">
                <Select
                  options={granularityOptions}
                  selectedOption={selectedGranularity}
                  onChange={({ detail }) => setSelectedGranularity(detail.selectedOption)}
                />
              </FormField>
              <FormField description="Aggregate categories">
                <Multiselect
                  selectedOptions={selectedAggregations}
                  onChange={({ detail }) => {
                    setSelectedAggregations(detail.selectedOptions);
                  }}
                  options={[...new Set((genreTimeseriesResponse?.data?.metadata?.genres ?? []).map(genre => genre.vertical))].map(vertical => (
                    {
                      label: vertical,
                      value: vertical,
                    }
                  ))}
                  placeholder="Choose options"
                  hideTokens
                  filteringType="none"
                />
              </FormField>
              <FormField description="Filter genres and tags">
                <Multiselect
                  selectedOptions={selectedGenres}
                  onChange={({ detail }) => setSelectedGenres(detail.selectedOptions)}
                  options={genreOptions}
                  placeholder="Choose options"
                  //hideTokens
                  i18nStrings={{
                    tokenLimitShowFewer: "Show less",
                    tokenLimitShowMore: "Show more",
                  }}
                  tokenLimit={3}
                  filteringType="auto"
                />
              </FormField>
            </div>
          </Box>
        </Container>
        <div style={{ width: "100%", height: "500px" }}>
          {selectedGenres.length === 0 ? (
            <div className="w-full h-[200px] flex items-center justify-center">
              <span className="text-sm text-gray-400">Select a genre to view data</span>
            </div>
          ) : (
            <AgCharts
              options={{
                theme: chartTheme,
                ...chartOptions,
              }}
              style={{ height: "100%" }}
            />
          )}
        </div>
      </SpaceBetween>
    )
  );
};

export default GenreTrendsCharts;