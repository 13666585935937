import {
  YouTubeCategoryResponse,
  YouTubeWeeklyCategoryTrendsResponse,
  YouTubeVideoTrendsResponse,
  YouTubeChannelTrendsResponse,
} from "./types";
import axiosInstance from "../../../../utils/axios";


export const getYouTubeCategoryData = async (
  date: string,
): Promise<YouTubeCategoryResponse> => {
  try {
    const response = await axiosInstance.get(
      `/file?key=youtube_trends/category_monthly/${date}.json`,
    );
    const data = response.data;
    return data as YouTubeCategoryResponse;
  } catch (error) {
    console.error("Error fetching YouTube trends data:", error);
    throw error;
  }
};

export const getYouTubeVideoTrendsData = async (
  interval: string,
): Promise<YouTubeVideoTrendsResponse> => {
  try {
    const response = await axiosInstance.get<string>(
      `/file?key=youtube_trends/video_trends/${interval}.json`,
    );
    
    const data = JSON.parse(response.data.replace(/:\s*NaN/g, ': null'));
    
    return data.map((item: any) => ({
      ...item,
      like_count: Number(item.like_count) || 0,
      comment_count: Number(item.comment_count) || 0
    }));
  } catch (error) {
    console.error("Error fetching YouTube video trends data:", error);
    throw error;
  }
};

export const getWeeklyCategoryTrendsData =
  async (): Promise<YouTubeWeeklyCategoryTrendsResponse> => {
    try {
      const response = await axiosInstance.get(
        "/file?key=youtube_trends/at_category_trends.json",
      );
      const data = await response.data;
      return data as YouTubeWeeklyCategoryTrendsResponse;
    } catch (error) {
      console.error("Error fetching YouTube category trends data:", error);
      throw error;
    }
  };

  export const getYouTubeChannelTrendsData = async (
    interval: string,
  ): Promise<YouTubeChannelTrendsResponse> => {
    try {
      const response = await axiosInstance.get(
        `/file?key=youtube_trends/channel_trends/${interval}.json`,
      );
      const data = response.data;
      return data as YouTubeChannelTrendsResponse;
    } catch (error) {
      console.error("Error fetching YouTube trends data:", error);
      throw error;
    }
  };
