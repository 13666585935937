import { AgCharts } from "ag-charts-react";
import moment from "moment";
import { lineTheme } from "./chartConfig";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import { useGetChartTheme, CHART_TYPES } from "../../../../hooks/UseTheme/useGetChartTheme";

const groupSeries = (seriesData, groupBy) => {
  const grouped = {};

  seriesData.forEach((item) => {
    const startOfGroup = moment(item.timestamp).startOf(groupBy).toDate();
    const key = moment(startOfGroup).format('YYYY-MM-DD');

    const otherValueKeys = Object.keys(item).filter(k => k !== 'timestamp');
    if (!grouped[key]) {
      grouped[key] = { timestamp: startOfGroup, ...otherValueKeys.reduce((acc, k) => ({ ...acc, [k]: 0 }), {}) };
    }
    otherValueKeys.forEach(k => {
      grouped[key][k] += item[k];
    });
  });

  const newSeriesData = Object.values(grouped).sort((a, b) => a.timestamp - b.timestamp);

  return newSeriesData;
};

const ResultsChart = ({ 
  seriesData, 
  granularity, 
  queryData, 
  selectedQueryData, 
  axisType, 
  seriesColors, 
  postName, 
  showMarkers, 
  seriesType, 
  ...props 
}) => {
  const { theme } = useGetGridTheme();
  const { theme: chartTheme } = useGetChartTheme(CHART_TYPES.VIVID);
    
  return (
    <div style={{ height: "500px", width: "100%" }} className={theme}>
      <AgCharts
        options={{
          data: granularity === "day" ? seriesData : groupSeries(seriesData, granularity),
          series: queryData.filter(query => selectedQueryData.includes(query.query_id)).map((query, index) => (
            {
              xKey: "timestamp",
              yKey: query.query_id,
              yName: query.query,
              type: seriesType,
              title: query.query,
              stroke: seriesColors[query.query],
              fill: seriesColors[query.query],
              stacked: seriesType === "bar" || seriesType === "area",
              normalizedTo: seriesType === "area" ? 100 : undefined,
              marker: { 
                fill: seriesColors[query.query],
                fillOpacity: 0.5,
                enabled: showMarkers,
              },
              tooltip: {
                renderer: (params) => (
                  {
                    title: params.title?.slice(0, 32) + (params.title?.length > 32 ? "..." : ""),
                    content: (granularity === "day" ? 
                      moment(params.datum.timestamp).format("ll")
                      : granularity === "week" ?
                      moment(params.datum.timestamp).format("ll") + " - " + moment(params.datum.timestamp).add(6, "days").format("ll")
                      : moment(params.datum.timestamp).format("MMM YYYY")) + ": " + params.datum[params.yKey].toLocaleString() + " posts",
                    backgroundColor: seriesColors[query.query],
                    color: ["#FFFFFF", "#EEE685"].includes(seriesColors[query.query]) ? "black" : "white",
                  }
                ),
              },
              listeners: {
                nodeClick: (params) => {
                  const clickedDate = moment(params.datum.timestamp).format("YYYY-MM-DD");
                  const startDate = clickedDate;
                  const endDate = granularity === "day" ? moment(clickedDate).add(1, "days").format("YYYY-MM-DD") : granularity === "week" ? moment(clickedDate).add(7, "days").format("YYYY-MM-DD") : moment(clickedDate).endOf("month").add(1, "days").format("YYYY-MM-DD");
                  const previewQuery = queryData.find(query => query.query_id === params.yKey).query + " since:" + startDate + " until:" + endDate;
                  const previewUrl = `https://twitter.com/search?f=live&q=${encodeURIComponent(previewQuery)}`;
                  window.open(previewUrl, "_blank");
                },
              },
              nodeClickRange: 20,
            }
          )),
          axes: [
            {
              type: "time",
              position: "bottom",
              title: "Date",
              label: {
                format: "%b %d, %Y",
              },
              tick: {
                minSpacing: 50,
                maxSpacing: 200,
              },
            },
            {
              type: axisType,
              position: "left",
              title: postName + " count",
              label: {
                formatter: (params) => {
                  const n = params.value;
                  if (n < 1e3) return n;
                  if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
                  if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
                  if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
                  if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
                }
              },
            },
          ],
          navigator: { enabled: true },
          legend: { enabled: false },
          theme: chartTheme,
          ...props,
        }}
        style={{ height: "100%" }}
      />
    </div>
  );
};

export default ResultsChart;