import React, { useCallback, useState, useEffect } from "react";
import { Autosuggest } from "@cloudscape-design/components";
import { useGetTopSearchData } from "./api/hooks/useGetTopSearchData";

const getImageUrl = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const convertData = (data) => {
  const convertedData = data.map(item => {
    const imageUrl = getImageUrl(item.vertical, item.ip_id, item.image_url);
    const labelTags = [item.vertical];
    if (item.release_date) {
      labelTags.push(new Date(item.release_date).getFullYear().toString());
    }
    return {
      value: item.ip_id,
      label: item.ip,
      iconUrl: imageUrl,
      iconAlt: `${item.ip} cover`,
      tags: labelTags,
    };
  });

  return convertedData;
};

const CommonSearch = () => {
  const [query, setQuery] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);

  const { data: searchResults, isLoading } = useGetTopSearchData({
    keyword: searchQuery,
    fields: ["ip", "ip_id", "vertical", "image_url", "release_date"],
    verticals: ["Movies", "Television", "Gaming", "Franchise"],
  });

  return (
    <Autosuggest
      onChange={({ detail }) => setQuery(detail.value)}
      onLoadItems={({ detail }) => setSearchQuery(detail.filteringText)}
      onSelect={({ detail }) => {
        window.location = `${window.location.origin}/item/${detail.value}`;
      }}
      expandToViewport
      value={query}
      options={ (searchResults && searchResults?.length > 0) ? convertData(searchResults) : [] }
      placeholder="Search"
      filteringType="none"
      empty={query?.length > 0 ? "No results found" : null}
      statusType={isLoading ? "loading" : "finished"}
    />
  );
};

export default CommonSearch;