import axiosInstance from "../../../../utils/axios";
import { GetPlatformTrendsDataProps } from "../../types";

export const getYoutubeTrendsData = ({
  filterQuery,
  sort = "daily_rank",
  vertical,
  sortOrder = "asc",
  offset = 0,
  paginationPageSize,
}: GetPlatformTrendsDataProps) => {
  return axiosInstance.request({
    url: `/ranking/daily/youtube/v3?offset=${offset}&limit=${paginationPageSize}&sort=${sort}&sort_order=${sortOrder}&vertical=${vertical}`,
    method: "POST",
    data: {
      fields: [],
      q: filterQuery,
    },
  });
};
