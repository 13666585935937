import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import CustomLoadingOverlay from "../../components/PantheonLoading";
import { ItemTypes } from "../../utils/itemUtils";
import { CompanyIndex } from "./pages/company";
import ItemFranchise from "./pages/franchise/franchise";
import { GameIndex } from "./pages/game";
import { MovieIndex } from "./pages/movie";
import { TelevisionIndex } from "./pages/television";
import { getItemData } from "./redux/actions/item-actions";

const itemTypes = {
  [ItemTypes.FILM]: MovieIndex,
  [ItemTypes.TELEVISION_SERIES]: TelevisionIndex,
  [ItemTypes.VIDEO_GAME]: GameIndex,
  [ItemTypes.FRANCHISE]: ItemFranchise,
  [ItemTypes.COMPANY]: CompanyIndex,
};

export default function Item() {
  const { id } = useParams();
  const flashbarRef = useRef(null);
  const itemData = useSelector((state: any) => state?.itemData);
  const itemDataLoading = useSelector((state: any) => state?.itemData?.loading);

  useEffect(() => {
    getItemData(id, {});
  }, [id]);

  if (itemDataLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <CustomLoadingOverlay />
      </div>
    );
  }

  const Component = itemTypes[itemData?.data?.type?.id];

  if (Component) {
    return <Component itemData={itemData} flashbarRef={flashbarRef} />;
  }
}
