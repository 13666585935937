import { createSlice } from "@reduxjs/toolkit";

const ummappedRouteListState= {
    isLoading:false,
    data:[],
    isLoadingCompleted:false,
    isError: false,
    message:""
}


const ummappedRouteSlice = createSlice({
    name:"ummappedRouteList",
    initialState:ummappedRouteListState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
            state.data = [];
        },
        createRouteList: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            state.isLoadingCompleted = true;
            state.isError = false;
        },
        clearRouteList:() => {
            return ummappedRouteListState;
        },
        createError:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isLoadingCompleted = true;
            state.isError = false;
        },
    }
});
const ummappedRouteListReducer = ummappedRouteSlice.reducer;
export default ummappedRouteListReducer;

export const ummappedRouteSliceActions = ummappedRouteSlice.actions;