export interface GetSteamDataResponseItem {
  timestamp: number;
  player_count_peak: number;
  daily_rank: number;
  player_count_avg: number;
  recommendations: number;
  positive_reviews: number;
  negative_reviews: number;
}

export interface MappedGetSteamResponseItem {
  dailyRank: number;
  playerCountPeak: number;
  timestamp: number;
  playerCountAvg: number;
  recommendations: number;
  positiveReviews: number;
  negativeReviews: number;
}

export const mapSteamResponse = (item: GetSteamDataResponseItem) => ({
  dailyRank: item.daily_rank,
  playerCountPeak: item.player_count_peak,
  timestamp: item.timestamp * 1000,
  playerCountAvg: item.player_count_avg,
  recommendations: item.recommendations,
  positiveReviews: item.positive_reviews,
  negativeReviews: item.negative_reviews,
});

export const STEAM_GRID_HEADER_MAP = {
  dailyRank: "Daily rank",
  playerCountPeak: "Player count peak",
  playerCountAvg: "Player count avg",
  recommendations: "Recommendations",
  positiveReviews: "Positive reviews",
  negativeReviews: "Negative reviews",
};
