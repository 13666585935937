import { useQuery } from "react-query";
import { isSome } from "../../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { getPantheonRankingDataV2 } from "../request";

export type MoviesType = "movies" | "series" | "gaming";

export interface Filters {
  genre?: string;
  distributor?: string;
  production?: string;
  sort?: string;
}

export type UseGetPantheonRankingProps = {
  filterQuery: any;
  sort: string;
  vertical: MoviesType;
  sortOrder: string;
  offset: number;
  paginationPageSize: number;
  startTs: number;
  endTs: number;
  weightageValues: any;
};

export const useGetPantheonRanking = (params: UseGetPantheonRankingProps) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_PANTHEON_RANKING_DATA, { ...params }],
    () => getPantheonRankingDataV2(params),
    {
      enabled:
        isSome(params.startTs) &&
        isSome(params.endTs),
    },
  );

  return { data, isLoading, error: error as any };
};
