export enum QUERY_KEYS {
  GET_ALL_PLATFORM_WEIGHTAGES = "GET_ALL_PLATFORM_WEIGHTAGES",
  GET_PLATFORM_WEIGHTAGES = "GET_PLATFORM_WEIGHTAGES",
}

export enum PLATFORMS {
  PANTHEON_RANKING = "pantheon_ranking",
  TRACKED_RANKING = "tracked_ranking",
}

export enum UserTypes {
  PERSONAL = "personal",
  ORGANIZATION = "organization",
}
