import { dispatch } from "../../../../../app/store";
import axiosInstance from "../../../../../utils/axios";
import {
  fetchFilteredFranchiseData,
  fetchFranchiseData,
} from "./franchise_slice";

export async function getFranchiseData(from, size, range) {
  await dispatch(fetchFranchiseData({ from, size, range }));
}

export async function getFilteredFranchiseData(from, size, range, body) {
  await dispatch(fetchFilteredFranchiseData({ from, size, range, body }));
}

export const createFranchiseAction = async (name, summary, added) => {
  const response = await axiosInstance.post(`/franchise/create`, {
    name,
    summary,
    added,
  });
  return response;
};
