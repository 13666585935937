import { Button, DatePicker, Input, Popover } from "@cloudscape-design/components";
import React, { useEffect, useMemo, useRef, useState } from "react";
import TitleSelector from "../Parameters/TitleSelector";
import { FaEyeDropper } from "react-icons/fa6";
import _ from "lodash";
import { useCompareContext } from "../../hooks/useCompareContext";
import moment from "moment";
import { MessageBox } from "../../../MessageBox";
import axiosInstance from "../../../../utils/axios";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "../../api/constants";
import { useGetGeneratedLabelsData } from "../../api/hooks/useGetGeneratedLabelsData";
import { Tooltip } from "react-tooltip";

const verifyDate = (date) => {
  return moment(date, "YYYY-MM-DD", true).isValid();
}

function sum(a) {
  return a.reduce((acc, val) => acc + val)
}

function mean(a) {
  return sum(a) / a.length
}

function stddev(arr) {
  const arr_mean = mean(arr)
  const r = function(acc, val) {
      return acc + ((val - arr_mean) * (val - arr_mean))
  }
  return Math.sqrt(arr.reduce(r, 0.0) / arr.length)
}

// https://stackoverflow.com/questions/22583391/peak-signal-detection-in-realtime-timeseries-data
function smoothed_z_score(y, params) {
  var p = params || {}
  // init cooefficients
  const lag = p.lag || 5
  const threshold = p.threshold || 3.5
  const influence = p.influece || 0.5

  if (y === undefined || y.length < lag + 2) {
      throw ` ## y data array to short(${y.length}) for given lag of ${lag}`
  }
  //console.log(`lag, threshold, influence: ${lag}, ${threshold}, ${influence}`)

  // init variables
  var signals = Array(y.length).fill(0)
  var filteredY = y.slice(0)
  const lead_in = y.slice(0, lag)
  //console.log("1: " + lead_in.toString())

  var avgFilter = []
  avgFilter[lag - 1] = mean(lead_in)
  var stdFilter = []
  stdFilter[lag - 1] = stddev(lead_in)
  //console.log("2: " + stdFilter.toString())

  for (var i = lag; i < y.length; i++) {
      //console.log(`${y[i]}, ${avgFilter[i-1]}, ${threshold}, ${stdFilter[i-1]}`)
      if (Math.abs(y[i] - avgFilter[i - 1]) > (threshold * stdFilter[i - 1])) {
          if (y[i] > avgFilter[i - 1]) {
              signals[i] = +1 // positive signal
          } else {
              signals[i] = -1 // negative signal
          }
          // make influence lower
          filteredY[i] = influence * y[i] + (1 - influence) * filteredY[i - 1]
      } else {
          signals[i] = 0 // no signal
          filteredY[i] = y[i]
      }

      // adjust the filters
      const y_lag = filteredY.slice(i - lag, i)
      avgFilter[i] = mean(y_lag)
      stdFilter[i] = stddev(y_lag)
  }

  return signals
}

export const AddTimelineEventBar = ({
  visible,
  setVisible,
  chartRef,
}) => {

  const { titles, state, crosslineData, setCrosslineData, timeseriesData, activeMetricKey, selectedTitles } = useCompareContext();
  const queryClient = useQueryClient();

  const titleSelectorRef = useRef(null);
  const messageBoxRef = useRef(null);
  const tooltipRef = useRef(null);

  const [searchQuery, setSearchQuery] = useState(titles.length === 1 ? titles[0].ip : "");
  const [genSearchQuery, setGenSearchQuery] = useState(null);
  const [selectedTitle, setSelectedTitle] = useState(titles.length === 1 ? titles[0] : null);
  const [selectedDate, setSelectedDate] = useState("");
  const [eventText, setEventText] = useState("");
  const [isPickingDate, setIsPickingDate] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: generatedLabelsData, isLoading: generatedLabelsLoading, error: generatedLabelsError } = useGetGeneratedLabelsData({
    q: genSearchQuery?.q,
    date: genSearchQuery ? moment.utc(genSearchQuery.date).format("YYYY-MM-DD") : null,
  });

  const signals = useMemo(() => {
    if (!selectedTitle) return [];

    const valueKey = `${selectedTitle.ip_id}_${activeMetricKey}`;
    const values = timeseriesData.map(d => d[valueKey]);
    
    const signals = smoothed_z_score(values, {
      lag: 4,
      threshold: 4.0,
      influence: 0.2,
    });

    return signals;
  }, [timeseriesData, selectedTitle, activeMetricKey]);

  const getSignal = (targetDate) => {
    if (!selectedTitle || !targetDate || !verifyDate(targetDate)) return;
    
    const targetTimestamp = moment.utc(targetDate).unix() * 1000;
    const valueIndex = timeseriesData.findIndex(d => d.timestamp === targetTimestamp);
    const signal = signals[valueIndex];
    
    return signal;
  };

  const dateSignal = useMemo(() => getSignal(moment(selectedDate).format("YYYY-MM-DD")), [selectedDate, signals]);

  const handleMouseClick = (e) => {
    if (isPickingDate) {
      setIsPickingDate(false);
      if (state.hoveredPointData) {
        if (state.hoveredPointData.length === 0) return;
        const hoveredDate = moment.utc(state.hoveredPointData[0].timestamp).format("YYYY-MM-DD");
        setSelectedDate(hoveredDate);
      }
    }
  };

  const handleSubmit = () => {
    setIsSubmitting(true);

    axiosInstance.request({
      method: "PUT",
      url: "/pantheon/events/v1",
      data: {
        [selectedTitle.ip_id]: [
          {
            timestamp: moment.utc(selectedDate).unix(),
            details: eventText,
            event_type: "custom_event",
          },
        ],
      },
    }).then(response => {
      console.log("Successfully added timeline event:", response);
      setVisible(false);
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.GET_COMPARISON_DATA });
      }, 500);
    }).catch(error => {
      console.error("Error adding timeline event:", error);
    });
  };

  useEffect(() => {
    chartRef.current?.chart?.canvasElement?.addEventListener("click", handleMouseClick);
    return () => {
      chartRef.current?.chart?.canvasElement?.removeEventListener("click", handleMouseClick);
    };
  }, [chartRef.current, isPickingDate]);

  useEffect(() => {
    const origCrosslineData = crosslineData.filter(c => c.type !== "_placeholder");
    if (visible) {
      //if (!selectedTitle && titles.length === 1) {
      //  setSelectedTitle(titles[0]);
      //}
      if (!selectedTitle || !verifyDate(selectedDate)) {
        setCrosslineData(origCrosslineData);
        return;
      }
      const placeholderCrosslines = [{
        type: "_placeholder",
        value: moment.utc(selectedDate).unix() * 1000,
        label: eventText.length === 0 ? "Event description" : eventText,
        ip_id: selectedTitle.ip_id,
        ip_name: selectedTitle.ip,
      }];
      const newCrosslineData = [...origCrosslineData, ...placeholderCrosslines];
      setCrosslineData(newCrosslineData);
    } else {
      setCrosslineData(origCrosslineData);
    }
  }, [selectedTitle, selectedDate, eventText, visible]);

  useEffect(() => {
    if (!generatedLabelsData) return;
    if (generatedLabelsError) {
      tooltipRef.current.open({
        content: "Failed to generate label",
      });
      return;
    }
    const labels = generatedLabelsData.labels;
    if (!labels[0] || labels[0] === "Not enough information") {
      tooltipRef.current.open({
        content: "No events found",
      });
      return;
    }
    setEventText(labels[0]);
    const determinedDate = generatedLabelsData.determined_date;
    if (verifyDate(determinedDate)) {
      setSelectedDate(determinedDate);
    }
  }, [generatedLabelsData, generatedLabelsError]);

  return (
    <div className={visible ? "w-full" : ""}>
      {visible ? (
        <div className="flex justify-between flex-wrap w-full gap-2">
          <div className="grow flex flex-nowrap items-center relative">
            <div className="grow">
              <Input
                placeholder="Add an event description"
                value={eventText}
                onChange={({ detail }) => setEventText(detail.value)}
              />
            </div>
            <div className="absolute right-2">
              <Button 
                data-tooltip-id="generate-label-button"
                variant="inline-icon"
                iconName="gen-ai"
                onClick={() => {
                  setGenSearchQuery({
                    q: selectedTitle.ip,
                    date: selectedDate,
                  });
                }}
                loading={generatedLabelsLoading}
                disabled={!selectedTitle || !selectedDate || dateSignal === 0}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <div>
              <TitleSelector
                ref={titleSelectorRef}
                searchQuery={searchQuery}
                setSearchQuery={(query) => {
                  setSearchQuery(query);
                  if (query.length === 0) {
                    titleSelectorRef.current.setSearchResults(titles);
                    setSelectedTitle(null);
                  }
                }}
                onSelectedItemChanged={(item) => setSelectedTitle(item.originalData)}
                onFocus={() => {
                  titleSelectorRef.current.setSearchResults(titles);
                }}
              />
            </div>
            <div className="flex gap-2 flex-nowrap">
              <DatePicker 
                onChange={({ detail }) => setSelectedDate(detail.value)}
                value={selectedDate}
                placeholder={isPickingDate ? "Click on a date in the chart" : "YYYY/MM/DD"}
              />
              <Button
                iconSvg={<div className="flex items-center justify-center"><FaEyeDropper /></div>}
                onClick={() => setIsPickingDate(!isPickingDate)}
                variant={isPickingDate ? "primary" : "normal"}
              />
            </div>
          </div>
          <div className="flex gap-2 items-center">
            <Button 
              variant="primary" 
              onClick={() => {
                messageBoxRef.current.open({
                  headerText: "Add timeline event?",
                  messageBody: (
                    <div>
                      <div>
                        <div><b>Description: </b>{eventText}</div>
                        <div><b>Date: </b>{moment(selectedDate).format("LL")}</div>
                        <div><b>Associated title: </b>{selectedTitle.ip}</div>
                      </div>
                    </div>
                  ),
                  primaryButtonText: "Submit",
                  secondaryButtonText: "Cancel",
                  onPrimaryButtonClick: () => {
                    setVisible(false);
                    handleSubmit();
                  },
                  onSecondaryButtonClick: () => {},
                });
              }}
              disabled={!selectedTitle || selectedDate.length === 0 || eventText.length === 0}
            >
              Add
            </Button>
            <Button 
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <Button
          variant="inline-link"
          onClick={() => setVisible(true)}
        >
          (+) Add timeline event
        </Button>
      )}
      <MessageBox ref={messageBoxRef} />
      <Tooltip id="generate-label-button" ref={tooltipRef} />
    </div>
  );
};