import { useEffect, useState } from "react";
import { useGetPantheonRanking, UseGetPantheonRankingProps } from "./useGetPantheonRankingData";
import { useDeferredPromise } from "../../../../../../hooks/useDeferredPromise";


export const useGetPantheonRankingDataPromise = () => {

  const [requestParams, setRequestParams] = useState({});

  const { data, isLoading, error } = useGetPantheonRanking(requestParams as UseGetPantheonRankingProps);
  const { defer, deferRef } = useDeferredPromise();

  useEffect(() => {
    if (!isLoading) {
      setRequestParams({});
      if (error) {
        deferRef.reject(error);
      } else if (data) {
        deferRef.resolve(data);
      }
    }
  }, [data, isLoading, error]);

  const getPantheonRankingData = async (params: UseGetPantheonRankingProps) => {
    setRequestParams(params);
    return defer().promise;
  };

  return { getPantheonRankingData, isLoading, error };
};