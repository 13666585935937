import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  Modal,
  SideNavigation,
  StatusIndicator,
  Textarea,
  Toggle,
  Box,
  SpaceBetween,
  FormField,
  Input,
} from "@cloudscape-design/components";

import { FaBug, FaLightbulb, FaRocket } from "react-icons/fa";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router";
import { navItems } from "../../layouts/common/menu/side-menu";
import axiosInstance from "../../utils/axios";
import { useAuthContext } from "../../auth/useAuthContext";
import CommentBox from "../../components/CommentBox";
import UserCommentView from "../../components/UserCommentView";
import { EditComment } from "../../components/EditComment";

const ViewIssuePage = () => {
  const { user } = useAuthContext();
  const [issue, setIssue] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [comments, setComments] = useState([]);
  const [userComment, setUserComment] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [commentEdit, setCommentEdit] = useState({
    status: false,
    user: null,
    comment: null,
  });

  const breadcrumbs = [
    { text: "Support" },
    { text: "Issues", href: "/issues/" },
    { text: issue?.id },
  ];

  const goBackToMainPage = () => {
    navigate("/issues/");
  };

  const getIssue = useCallback(
    (id) => {
      axiosInstance
        .get(`/issues/${id}`)
        .then((d) => d.data)
        .then((d) => {
          setIssue(d);
        });
    },
    [axiosInstance, setIssue]
  );

  const getComments = useCallback(
    (id) => {
      axiosInstance
        .get(`/issues/${id}/comments`)
        .then((d) => d.data)
        .then((d) => {
          setComments(d);
        });
    },
    [axiosInstance, setComments]
  );

  const postComment = useCallback(
    (id, comment) => {
      const commentId = `C-${Math.floor(Date.now() / 1000)}`;
      axiosInstance
        .post(`/issues/${id}/comments`, {
          id: commentId,
          comment,
          created_by: user.username,
        })
        .then((d) => d.data)
        .then((d) => {
          setUserComment("");
          getComments(id);
        });
    },
    [axiosInstance, setUserComment, user]
  );

  const reopenIssue = useCallback(
    (id) => {
      axiosInstance
        .put(`/issues/${id}`, {
          status: "Open",
        })
        .then((d) => d.data)
        .then((d) => {
          getComments(id);
          getIssue(id);
        });
    },
    [axiosInstance, getComments]
  );

  const deleteComment = useCallback(
    (commentId) => {
      axiosInstance
        .request({
          url: `/issues/${issue.id}/comments`,
          data: {
            id: [commentId],
          },
          method: "DELETE",
        })
        .then((d) => d.data)
        .then((d) => {
          setUserComment("");
          setDeleteId(null);
          setShowDeleteModal(false);
          getComments(issue?.id);
        });
    },
    [axiosInstance, setUserComment, user, issue, setShowDeleteModal]
  );

  const editIssue = useCallback(
    (data) => {
      axiosInstance
        .put(`/issues/${issue.id}`, data)
        .then((d) => d.data)
        .then((d) => {
          setIsEditMode(false);
          getIssue(issue.id);
        });
    },
    [axiosInstance, getIssue, issue]
  );

  const editIssueComment = useCallback(
    ({ issueId, id, comment }) => {
      axiosInstance
        .put(`/issues/${issueId}/comments/${id}`, { comment: comment })
        .then((d) => d.data)
        .then((d) => {
          setCommentEdit({ ...commentEdit, status: false });
          getComments(issueId);
        })
        .catch((e) => setCommentEdit({ ...commentEdit, status: false }));
    },
    [axiosInstance, getIssue]
  );

  useEffect(() => {
    if (id) {
      getIssue(id);
      getComments(id);
    }
  }, [id]);
  const onDeleteInit = () => setShowDeleteModal(true);
  const onDeleteDiscard = () => setShowDeleteModal(false);
  function DeleteModal({ entries, visible, onDiscard, onDelete }) {
    const deleteConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onDelete();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === deleteConsentText;

    const isMultiple = entries?.length > 1;
    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={isMultiple ? "Delete entries" : "Delete entry"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={goBackToMainPage}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onDelete}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {entries?.length > 0 && (
          <SpaceBetween size="m">
            {isMultiple ? (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {entries.length} entries
                </Box>
                ? You can’t undo this action.
              </Box>
            ) : (
              <Box variant="span">
                Permanently delete entry
                <Box variant="span" fontWeight="bold">
                  {entries[0].id}
                </Box>
                ? You can’t undo this action.
              </Box>
            )}

            <Box>
              To avoid accidental deletions, we ask you to provide additional
              written consent.
            </Box>

            <form onSubmit={handleDeleteSubmit}>
              <FormField
                label={`To confirm this deletion, type "${deleteConsentText}".`}
              >
                <ColumnLayout columns={2}>
                  <Input
                    placeholder={deleteConsentText}
                    onChange={(event) => setDeleteInputText(event.detail.value)}
                    value={deleteInputText}
                    ariaRequired={true}
                  />
                </ColumnLayout>
              </FormField>
            </form>
          </SpaceBetween>
        )}
      </Modal>
    );
  }

  const createdDate = new Date(issue?.created_at * 1000).toLocaleString();

  return (
    <>
      <Helmet>
        <title>[{issue?.id ?? ""}]</title>
      </Helmet>
      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        contentType="form"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <div>
            <BreadcrumbGroup
              items={breadcrumbs}
              expandAriaLabel="Show path"
              ariaLabel="Breadcrumbs"
            />
          </div>
        }
        content={
          <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <ContentLayout
              disableOverlap
              header={
                <Header variant="h3">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span className="pr-5">{issue?.id}</span>
                    <Toggle
                      checked={isFullWidth}
                      onChange={() => setIsFullWidth(!isFullWidth)}
                    >
                      Full width
                    </Toggle>
                  </div>
                </Header>
              }
            >
              <div className="p-8">
                <div className="space-y-8">
                  <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-8">
                    <div className="h-full flex-1 flex flex-col space-y-4">
                      <div className="text-2xl font-bold flex items-center">
                        {issue?.type === "Bug" && (
                          <FaBug className="mr-2" style={{ color: "red" }} />
                        )}
                        {issue?.type === "Feedback" && (
                          <FaLightbulb
                            className="mr-2"
                            style={{ color: "yellow" }}
                          />
                        )}
                        {issue?.type === "New Feature" && (
                          <FaRocket
                            className="mr-2"
                            style={{ color: "green" }}
                          />
                        )}
                        {issue?.title}
                      </div>
                      <div className="flex items-center space-x-4">
                        {/* Status Chip */}
                        <div
                          className={
                            issue?.status === "Open"
                              ? "bg-green-700 text-white rounded-full px-3 py-1"
                              : "bg-gray-600 text-white rounded-full px-3 py-1"
                          }
                        >
                          {issue?.status === "Open" ? "Open" : "Closed"}
                        </div>

                        <div className="text-gray-600">
                          <span>Opened by {issue?.created_by}</span>
                          <span className="mx-2">•</span>
                          <span>Opened on {createdDate}</span>
                          <span className="mx-2">•</span>
                          <span>{comments?.length} Comments</span>
                        </div>
                        <div className="flex-1 flex justify-end">
                          {user?.username === issue?.created_by && (
                            <Button
                              variant="secondary"
                              onClick={() => {
                                if (isEditMode) {
                                  editIssue({ description: editedDescription });
                                } else {
                                  setEditedDescription(
                                    issue?.description || ""
                                  );
                                }
                                setIsEditMode(!isEditMode);
                              }}
                            >
                              {isEditMode ? "Save" : "Edit"}
                            </Button>
                          )}
                        </div>
                      </div>

                      <div className="comment-234-wrapper border-2 rounded-lg p-4 border-slate-800 shadow-xl ">
                        <ReactQuill
                          value={
                            isEditMode
                              ? editedDescription
                              : issue?.description || ""
                          }
                          onChange={(newDesc) => setEditedDescription(newDesc)}
                          readOnly={!isEditMode}
                          theme={"bubble"}
                        />
                      </div>
                    </div>
                  </div>
                  {comments?.length > 0 &&
                    comments.map((item) => {
                      return (
                        <div className="border-2 rounded-lg p-4 border-slate-800 shadow-xl ">
                          <div key={item.id}>
                            {commentEdit.status && commentEdit?.comment?.id === item.id ? (
                              <EditComment
                                user={commentEdit.user}
                                comment={commentEdit.comment}
                                onCancel={() =>
                                  setCommentEdit({
                                    ...commentEdit,
                                    status: false,
                                  })
                                }
                                onSubmit={({ comment, id, issueId }) => {
                                  editIssueComment({ comment, id, issueId });
                                }}
                              />
                            ) : (
                              <UserCommentView
                                user={user}
                                comment={item}
                                onEdit={(editItem) => {
                                  setCommentEdit({
                                    status: true,
                                    user: editItem.user,
                                    comment: editItem.comment,
                                  });
                                }}
                                onDelete={() => {
                                  setDeleteId(item.id);
                                  onDeleteInit();
                                }}
                              />
                            )}
                          </div>
                        </div>
                      );
                    })}

                  {issue?.status !== "Closed" && !commentEdit.status ? (
                    <div>
                      <CommentBox
                        onCancel={() => {
                          setUserComment("");
                          navigate("/issues");
                        }}
                        onChange={(t) => setUserComment(t)}
                        userComment={userComment}
                        user={user}
                        onSubmit={() => {
                          postComment(issue?.id, userComment);
                        }}
                        onSubmitAndClose={() => {
                          postComment(issue?.id, userComment);
                          setTimeout(() => {
                            editIssue({ status: "Closed" });
                          }, 500);
                        }}
                      />
                    </div>
                  ) : (
                    <div className="flex justify-end space-x-4">
                      <Button variant="secondary" onClick={goBackToMainPage}>
                        Back
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => reopenIssue(issue?.id)}
                      >
                        Reopen
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </ContentLayout>
          </div>
        }
      />
      <DeleteModal
        visible={showDeleteModal}
        onDiscard={() => onDeleteDiscard()}
        onDelete={() => {
          deleteComment(deleteId);
        }}
        entries={deleteId ? [deleteId] : []}
      />
    </>
  );
};

export default ViewIssuePage;
