import React, { useEffect, useState } from "react";
import { Box, Button, ContentLayout, ExpandableSection, Header, Link, Popover, SpaceBetween, Spinner, StatusIndicator, Tabs } from "@cloudscape-design/components";
import { navItems, NavItemsWithId } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import moment from "moment";
import { CATEGORIES, COUNTRIES, Country, DMA_REGIONS } from "./constants";
import { Parameters } from "./components/Parameters";
import { CountryMap } from "./components/CountryMap/CountryMap";
import { getAllCategories } from "./utils";
import { TimeseriesChart } from "./components/TimeseriesChart/TimeseriesChart";
import { OptionLayer } from "../../../components/option-layer/OptionLayer";
import { useGetGoogleRankingData } from "./api/hooks/useGetGoogleRankingData";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { useSearchParams } from "react-router-dom";
import { RankingTable } from "./components/RankingTable/RankingTable";
import axiosInstance from "../../../utils/axios";
import { InfoDrawer } from "./components/InfoDrawer";

const mainCountries: Array<Country> = [
  COUNTRIES.AUSTRALIA,
  COUNTRIES.BRAZIL,
  COUNTRIES.CANADA,
  COUNTRIES.GERMANY,
  COUNTRIES.SPAIN,
  COUNTRIES.FRANCE,
  COUNTRIES.UNITED_KINGDOM,
  COUNTRIES.IRELAND,
  COUNTRIES.ITALY,
  COUNTRIES.JAPAN,
  COUNTRIES.SOUTH_KOREA,
  COUNTRIES.MEXICO,
  COUNTRIES.THAILAND,
  COUNTRIES.UNITED_STATES_OF_AMERICA,
  COUNTRIES.INDIA,
];

const overviewCategories = [
  CATEGORIES.GENERAL.CATEGORY,
  CATEGORIES.LEARN_ABOUT.GENERAL,
  CATEGORIES.INTENT_TO_WATCH.THEATRICAL,
  CATEGORIES.INTENT_TO_WATCH.PIRACY,
  CATEGORIES.INTENT_TO_PLAY.PHYSICAL.PC,
  CATEGORIES.INTENT_TO_PLAY.PHYSICAL.CONSOLE,
  CATEGORIES.LEARN_ABOUT.TRAILERS,
  CATEGORIES.REVIEWS, 
  CATEGORIES.LEARN_ABOUT.AWARDS,
];

const fullDateRange = {
  type: "absolute",
  startDate: "2023-01-01",
  endDate: moment().format("YYYY-MM-DD"),
};

export const GoogleDashboard = () => {
  const title = "Google Search Dashboard";

  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTab, setSelectedTab] = useState(searchParams.get("tab") ?? "overview");
  const [selectedTitle, setSelectedTitle] = useState<any | null>(null);

  const [dateRange, setDateRange] = useState<any>({
    type: "relative",
    amount: 2,
    unit: "week",
    startDate: moment().subtract(2, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });

  const { data: rankingData, isLoading: rankingDataLoading, error: rankingDataError } = useGetGoogleRankingData({
    enabled: selectedTab === "overview",
    geoName: COUNTRIES.UNITED_STATES_OF_AMERICA.value,
    geoType: "country",
    startDate: moment(dateRange.startDate).format("YYYY-MM-DD"),
    endDate: moment(dateRange.endDate).format("YYYY-MM-DD"),
  });

  useEffect(() => {
    if (searchParams.get("title")) {
      const id = searchParams.get("title");
      setSelectedTitle({ ip_id: id });
      axiosInstance.get(
        `ipmanagement/v2/iplist?id=${id}&include=ip,ip_id,vertical,image_url,release_date`,
      ).then((response) => {
        const title = response.data[0];
        setSelectedTitle(title);
      });
    }
  }, [searchParams]);

  const infoDrawerConfig = {
    id: "infoDrawer",
    content: (
      <InfoDrawer />
    ),
    trigger: {
      iconName: "status-info",
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
  };

  return (
    <Layout
      title={title}
      breadcrumbs={[]}
      navItems={navItems as NavItemsWithId}
      drawers={[infoDrawerConfig]}
      contentType="dashboard"
      content={
        <ContentLayout
          header={
            <>
              <Header
                variant="h2"
              >
                {title}
              </Header>
            </>
          }
        >
          <SpaceBetween size="m" direction="vertical">
            <Tabs
              activeTabId={selectedTab}
              onChange={({ detail }) => {
                setSelectedTab(detail.activeTabId);
                setSearchParams({ tab: detail.activeTabId });
              }}
              tabs={[
                { label: "Overview", id: "overview" },
                { label: "Rankings", id: "rankings" },
                { label: "Title Insights", id: "insights" },
              ]}
              disableContentPaddings
            />
            {selectedTab === "overview" ? (
              <>
                <Header
                  actions={
                    <RangeDateSelector
                      onChange={setDateRange}
                      defaults={dateRange}
                    />
                  }
                >
                  Rankings by category in the United States
                </Header>
                {rankingDataLoading ? (
                  <div className="w-full h-96 flex items-center justify-center"><Spinner size="large" /></div>
                ) : rankingData ? (
                  overviewCategories.map((category) => (
                    <div>
                      <Header
                        variant="h3"
                        description={category.description}
                        info={
                          <Button
                            variant="inline-link"
                            onClick={() => {
                              setSearchParams(params => {
                                params.set("category", category.value);
                                params.set("tab", "rankings");
                                setSelectedTab("rankings");
                                return params;
                              });
                            }}
                          >
                            <span className="text-xs">view more</span>
                          </Button>
                        }
                      >
                        {category.label}
                      </Header>
                      <OptionLayer
                        variant="row"
                        optionData={rankingData[category.value]}
                      />
                    </div>
                  ))
                ) : (
                  <div className="w-full h-96 flex items-center justify-center">
                    {rankingDataError ? "An error occurred while fetching data" : "No data available"}
                  </div>
                )}
              </>
            ) : selectedTab === "insights" ? (
              <>
                <Parameters
                  selectedTitle={selectedTitle}
                  setSelectedTitle={setSelectedTitle}
                />
                {selectedTitle ? (
                  <>
                    <TimeseriesChart
                      selectedTitle={selectedTitle}
                      dateRange={fullDateRange}
                      categories={getAllCategories(CATEGORIES)}
                      countries={mainCountries}
                    />
                    <CountryMap
                      selectedTitle={selectedTitle}
                      categories={getAllCategories(CATEGORIES)}
                      countries={Object.values(COUNTRIES)}
                      dmaRegions={Object.values(DMA_REGIONS)}
                      dateRange={fullDateRange}
                    />
                  </>
                ) : (
                  <div className="w-full h-96 flex items-center justify-center">
                    Select a title to get started
                  </div>
                )}
              </>
            ) : (
              <>
                <RankingTable setSelectedTab={setSelectedTab} />
              </>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
    />
  );
};
