import axiosInstance from "../../../../utils/axios";

export const getBOData = () => {
  return axiosInstance
    .post(`/pql?key=boxoffice/box_office_data&file_type=P`, {
      sql: "SELECT * FROM s3object",
    })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error("Failed to fetch BoxOffice data:", error);
      throw error;
    });
};
