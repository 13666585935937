import { Container, Header } from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import { AgChartOptions } from "ag-grid-enterprise";
import React, { FC } from "react";
import { useGetTrackedRedditData } from "../../../../api/hooks/useGetTrackedRedditData";
import { DateRangeType } from "../../DateRange";
import { EmptyTrends } from "../EmptyTrends";
import { useChartConfig } from "./useChartConfig";

interface RedditTrendsProps {
  ipId: string;
  dateRange: DateRangeType;
}

export const RedditTrends: FC<RedditTrendsProps> = ({ ipId, dateRange }) => {
  const { data: redditTrendsData } = useGetTrackedRedditData({
    ipId,
    dateRange,
  });
  const { redditTrendChartOption } = useChartConfig(redditTrendsData);

  return (
    <Container
      header={
        <Header
          variant="h3"
        >
          Reddit
        </Header>
      }
    >
      {redditTrendChartOption && redditTrendsData?.length > 0 && (
        <div className="h-[260px]">
          <AgCharts
            options={redditTrendChartOption as any | AgChartOptions}
            style={{ height: "100%" }}
          />
        </div>
      )}
      {redditTrendsData?.length === 0 && (
        <EmptyTrends text="No data available for the selected date range" />
      )}
    </Container>
  );
};
