import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { getChannelsSearchData } from "../request";

export const useChannelsSearch = (queryString: string) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_CHANNELS_SEARCH_DATA, queryString],
    () => getChannelsSearchData(queryString),
    { enabled: queryString.length > 0 },
  );

  return {
    data: isSomeOrElse(data?.data, []),
    isLoading,
    error,
  };
};
