import { Alert, Box, Container, Link, Spinner } from '@cloudscape-design/components';
import "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react';
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useGetGridTheme } from '../../../hooks/UseTheme/useGetGridTheme';
import axiosInstance from '../../../utils/axios';
import renderTrend from '../../../utils/trendIcon';
import CustomLoadingOverlay from '../../../components/PantheonLoading';
import { useNavigate } from 'react-router';

interface TrendingWidgetProps {
    title: any;
    payload: object;
    endpoint?: string;
    viewMoreHref?: string;
}

interface TrendingData {
    results: Array<{
        daily_rank: number;
        daily_rank_change: number;
        daily_rank_trend: string;
        weekly_rank: number;
        weekly_rank_change: number;
        weekly_rank_trend: string;
        ip: string;
        ip_id: string;
        month_score: number[];
        // Add release date as date type
        release_date: string;
    }>;
}

const fetchTrendingData = async ({ queryKey }) => {

    const [_key, { endpoint, payload }] = queryKey;
    const { data } = await axiosInstance.request({
        url: endpoint,
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: payload
    });
    return data;
}

const renderDailyRank = (params) =>
    renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend");

const renderWeeklyRank = (params) =>
    renderTrend(params, "weekly_rank", "weekly_rank_change", "weekly_rank_trend");

const releaseYear = (releaseDate) => {
    if (!releaseDate) return null;
    return new Date(releaseDate).getFullYear();
};

const TrendingWidget: React.FC<TrendingWidgetProps> = ({ title, payload, endpoint, viewMoreHref }) => {
    const { theme } = useGetGridTheme();

    const navigate = useNavigate();

    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            flex: 1,
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            filter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }),
        [],
    );

    const columnDefs = useMemo(() => {
        const allColumns = [
            {
                headerName: "Rank",
                maxWidth: 90,
                cellDataType: "number",
                minWidth: 90,
                sortable: true,
                cellRenderer: renderWeeklyRank,
                valueGetter: (params) => parseInt(params.data.weekly_rank),
            },
            // {
            //     headerName: "7-Day Rank",
            //     maxWidth: 90,
            //     cellDataType: "number",
            //     minWidth: 90,
            //     sortable: true,
            //     cellRenderer: renderWeeklyRank,
            //     valueGetter: (params) => parseInt(params.data.weekly_rank),
            // },
            {
                field: "ip",
                headerName: "Title",
                cellDataType: "text",
                minWidth: 100,
                cellRenderer: (params) => {
                    if (!params.value) return null;

                    const ipId = params.data.ip_id;
                    const releaseDate = params.data.release_date;
                    return (
                        <Link href={`/item/${ipId}`}>
                            <span> {params.data.ip}
                                <span className="text-xs text-slate-500"> {releaseYear(releaseDate)}</span>
                            </span>
                        </Link>
                    );
                },
            },
            {
                field: "month_score",
                headerName: "Trend",
                cellRenderer: "agSparklineCellRenderer",
                cellRendererParams: {
                    sparklineOptions: {
                        type: "area",
                        fill: "#0972d3",
                    },
                },
            },
        ];
        return allColumns;
    }, []);

    const { data, error, isLoading } = useQuery(['trending', { endpoint, payload }], fetchTrendingData);

    if (error) return <Alert type="error">Error Fetching data</Alert>;

    return (
        <Container
            disableContentPaddings
            fitHeight
        >
            <div className="font-bold text-lg bg-slate-200 p-2 dark:bg-slate-800 text-center truncate rounded-t-2xl">{title}</div>
            <hr className="border-slate-600 border-t-[1px]" />
            <div className={`${theme} compact h-86`}>
                <AgGridReact
                    rowData={data}
                    defaultColDef={defaultColDef}
                    suppressDragLeaveHidesColumns={true}
                    columnDefs={columnDefs}
                    suppressContextMenu={true}
                    loadingOverlayComponent={CustomLoadingOverlay}
                />
            </div>
            {viewMoreHref && (
                <>
                    <hr className="border-slate-600 border-t-[1px]" />
                    <div className="bg-slate-200 p-2 dark:bg-slate-800 text-center truncate rounded-b-2xl">
                        <Link
                            onClick={({ detail }) => {
                                navigate(viewMoreHref);
                            }}
                        >
                            View more
                        </Link>
                    </div>
                </>
            )}
        </Container>
    );
}

export default TrendingWidget;