import {
  Box,
  Button,
  DatePicker,
  HelpPanel,
  SpaceBetween,
  Table,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import TrendsPopover from "../TrendsPopover";
import moment from "moment";
import { getGeneralTrendsData } from "../redux/actions";
import { ArticleItem } from "../api/request";
import { useSelector } from "react-redux";
import { getGeneralWikipediaTrendsData } from "../redux/selectors";

export const GeneralTrendsDrawer = () => {
  const generalWikipediaTrendsData = useSelector(getGeneralWikipediaTrendsData);
  const generalTrendsData = useSelector(
    (state: any) => state?.generalWikipediaTrendsData,
  );

  const [generalTrendsDate, setGeneralTrendsDate] = useState("");

  useEffect(() => {
    const unixTimestampToSend = moment.utc(generalTrendsDate).unix();

    getGeneralTrendsData({
      date: unixTimestampToSend,
    });
  }, [generalTrendsDate]);

  return (
    <HelpPanel header="Wikipedia General Trends">
      <div className="flex justify-end">
        <DatePicker
          expandToViewport
          value={generalTrendsDate.toString()}
          placeholder="YYYY/MM/DD"
          onChange={({ detail }) => {
            setGeneralTrendsDate(detail.value);
          }}
          isDateEnabled={(date) => {
            const minDate = new Date(2023, 0, 1);
            const maxDate = new Date();
            maxDate.setDate(maxDate.getDate() - 2);
            return date >= minDate && date <= maxDate;
          }}
        />
      </div>

      <div>
        <Table
          variant="borderless"
          loading={generalTrendsData?.loading}
          columnDefinitions={[
            {
              id: "rank",
              header: "#",
              cell: (item: ArticleItem) => item.rank,
              sortingField: "rank",
            },
            {
              id: "article",
              header: "Article",
              cell: (item: ArticleItem) =>
                item.ip_id ? (
                  <Button
                    variant="inline-link"
                    href={`${window.location.origin}/item/${item.ip_id}`}
                  >
                    {item.article.replace(/_/g, " ")}
                  </Button>
                ) : (
                  <div className="py-2">{item.article.replace(/_/g, " ")}</div>
                ),
              sortingField: "IP",
            },
            {
              id: "views",
              header: "Views",
              minWidth: 130,
              cell: (item) => {
                const totalViews = item.views_trend.reduce(
                  (sum, view) => sum + view.views,
                  0,
                );
                const data = item.views_trend.map((trend) => ({
                  timestamp: trend.timestamp,
                  views: trend.views,
                }));
                return <TrendsPopover data={data} totalViews={totalViews} />;
              },
              sortingField: "views",
            },
          ]}
          items={generalWikipediaTrendsData?.articles ?? []}
          loadingText=""
          sortingDisabled
          empty={
            <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
              <SpaceBetween size="m">
                <b>No items</b>
              </SpaceBetween>
            </Box>
          }
        />
      </div>
    </HelpPanel>
  );
};
