import { Icon, SpaceBetween } from "@cloudscape-design/components";
import React from "react";
import { DecreaseIcon, IncreaseIcon, NeutralIcon, NewIcon } from "../Icons";

const TableLegend = () => {
  return (
    <div className="text-xs font-thin text-gray-800 dark:text-gray-300">
      <SpaceBetween direction="horizontal" size="s">
        <span>
          <Icon name="status-positive" variant="success" /> Title is tracked
          with advanced metrics
        </span>
        <span>
          <Icon name="status-positive" variant="disabled" /> Title is available
          with basic details
        </span>
        <span>
          <Icon name="status-negative" variant="disabled" /> Title is not
          available on the platform
        </span>
        <span>
          <Icon svg={<IncreaseIcon />} variant="success" /> Upward trend
        </span>
        <span>
          <Icon svg={<DecreaseIcon />} variant="error" /> Downward trend
        </span>
        <span>
          <Icon svg={<NeutralIcon />} variant="subtle" /> Stable trend
        </span>
        <span>
          <Icon svg={<NewIcon />} variant="success" /> New entry
        </span>
      </SpaceBetween>
    </div>
  );
};

export default TableLegend;
