import React, { useState, useEffect } from "react";
import { Box, Link, TextContent, Table } from "@cloudscape-design/components";
import axiosInstance from "../../../../../../utils/axios";
import "ag-grid-enterprise";

export const ipAdded = {
  definition: { defaultRowSpan: 3, defaultColumnSpan: 1 },
  data: {
    title: "New IPs",
    description: "New IPs added in the last 7 days",
    header: AccountAttributesHeader,
    content: AccountAttributesContent,
    // footer: AccountAttributesFooter,
  },
};

function AccountAttributesHeader() {
  return (
    <Box variant="h4" textAlign="center">
      {ipAdded.data.title}
    </Box>
  );
}

function AccountAttributesContent() {
  const [ips, setIps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(`/audit?gte=now-7d/d&lte=now&size=10000&event_type=ip_added`);
      if (response.status >= 200 && response.status < 300) {
        const data = response.data;
        if (!data) {
          setIps([]);
          return;
        }
        const sortedData = data.sort((b, a) => new Date(b.timestamp) - new Date(a.timestamp));
        setIps(sortedData);
      } else {
        throw new Error("Error fetching data");
      }
    } catch (error) {
      setError(error.toString());
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      <hr className="border-slate-600 border-t-[1px]" />
      <Table
        stickyHeader
        contentDensity="compact"
        variant="borderless"
        resizableColumns
        columnDefinitions={[
          {
            id: "title",
            header: "Title",
            width: 220,
            cell: item => <><Link variant="secondary" href={`/item/${item.ip_id}`}>
              <span className="pl-2"> {item.ip}</span>
            </Link>
              <span className="pl-2 text-slate-500">({item.vertical.charAt(0)})</span></>,
          },
          {
            id: "timestamp",
            header: "Added On",
            cell: item => new Date(item.timestamp * 1000).toLocaleDateString("en-US", {
              day: "numeric",
              month: "short",
              year: "numeric",
            }),

          }
        ]}
        items={ips}
        loadingText="Loading ips..."
        empty={
          <Box textAlign="center">
            <TextContent>No ips found</TextContent>
          </Box>
        }
      />
    </>
  );
}