import {
  AppLayout,
  AppLayoutProps,
  BreadcrumbGroup,
  SideNavigation,
} from "@cloudscape-design/components";
import React, { FC, ReactNode } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BreadcrumbsItem,
  NavItemWithId,
  NavItemsWithId,
  SERVICES_GROUP,
} from "../../layouts/common/menu/side-menu";

interface LayoutProps {
  title: string;
  breadcrumbs: Array<BreadcrumbsItem>;
  navItems: NavItemsWithId;
  drawers?: Array<any>;
  content: ReactNode;
  contentType: AppLayoutProps.ContentType;
  splitPanel?: ReactNode;
  splitPanelStatus?: boolean;
  setSplitPanelStatus?: (status: boolean) => void;
}

export const Layout: FC<LayoutProps> = ({
  title,
  breadcrumbs,
  navItems,
  drawers,
  content,
  contentType,
  splitPanel,
  splitPanelStatus,
  setSplitPanelStatus,
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();



  const filteredNavItems = navItems.filter(
    (item) =>
      item.type === "section-group" && item?.id === SERVICES_GROUP.DASHBOARDS,
  );

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        ariaLabels={{ navigationClose: "close" }}
        drawers={drawers}
        navigationWidth={200}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={filteredNavItems}
          />
        }
        content={content}
        contentType={contentType}
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs as any}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
        splitPanel={splitPanel}
        splitPanelOpen={splitPanelStatus}
        onSplitPanelToggle={({ detail: { open } }) => setSplitPanelStatus(open)}
      />
    </>
  );
};
