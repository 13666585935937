import React from 'react';
import { PropertyFilter } from '@cloudscape-design/components';
import getTextFilterCounterText from '../utils/getTextFilterCounterText';

const CustomPropertyFilter = ({ countText, filteredItemsCount, ...props }) => {
    return (
        <PropertyFilter
            countText={getTextFilterCounterText(filteredItemsCount)}
            {...props}
           
            i18nStrings={{
                filteringAriaLabel: 'your choice',
                dismissAriaLabel: 'Dismiss',
                clearAriaLabel: 'Clear',
                filteringPlaceholder: 'Filter by IP, Status or Date',
                groupValuesText: 'Values',
                groupPropertiesText: 'Properties',
                operatorsText: 'Operators',
                operationAndText: 'and',
                operationOrText: 'or',
                operatorLessText: 'Less than',
                operatorLessOrEqualText: 'Less than or equal',
                operatorGreaterText: 'Greater than',
                operatorGreaterOrEqualText: 'Greater than or equal',
                operatorContainsText: 'Contains',
                operatorDoesNotContainText: 'Does not contain',
                operatorEqualsText: 'Equals',
                operatorDoesNotEqualText: 'Does not equal',
                editTokenHeader: 'Edit filter',
                propertyText: 'Property',
                operatorText: 'Operator',
                valueText: 'Value',
                cancelActionText: 'Cancel',
                applyActionText: 'Apply',
                allPropertiesLabel: 'All properties',
                tokenLimitShowMore: 'Show more',
                tokenLimitShowFewer: 'Show fewer',
                clearFiltersText: 'Clear filters',

                removeTokenButtonAriaLabel: (token) =>
                    `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
                enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            expandToViewport={true}
        />
    );
};

export default CustomPropertyFilter;
