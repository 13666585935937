export const transformApiResponse = (apiResponse) => {
  return apiResponse.map((item) => ({
    name: item.ip,
    fullDescription: item.summary,
    id: item.ip_id,
    description:
      item.summary.length > 200
        ? item.summary.substr(0, 200) + "..."
        : item.summary,
    type: item.vertical,
    parentCompany: item.parentCompany,
    imageUrl: item.image_url,
    twitter: item?.twitter ?? null,
    youtube: item?.youtube ?? null,
    google: item?.trends ?? null,
    instagram: item?.instagram ?? null,
    a03: item?.a03 ?? null,
    wattpad: item?.wattpad ?? null,
    subreddit: item?.subreddit ?? null,
    tiktok: item?.tiktok ?? null,
    ecommerce: item?.ecommerce ?? null,
    fandom: item?.fandom ?? null,
    wikipedia: item?.wikipedia ?? null,
    trends: item?.trends ?? null,
    imbd: item?.imbd_id ?? null,
    genre: item?.genre ?? null, 
  }));
};

const operatorConvert = (isIn, operator) => {
  if (operator === "=") return isIn;
  return !isIn;
};

export const isPresent = (
  key,
  value,
  operator,
  { ip, summary, vertical, parentCompany }
) => {
  switch (key) {
    case "name": {
      const present = ip?.toLowerCase().indexOf(value?.toLowerCase()) > -1;
      return operatorConvert(present, operator);
    }
    case "type": {
      const present =
        vertical?.toLowerCase().indexOf(value?.toLowerCase()) > -1;
      return operatorConvert(present, operator);
    }
    case "parentCompany": {
      const present =
        parentCompany?.toLowerCase().indexOf(value?.toLowerCase()) > -1;
      return operatorConvert(present, operator);
    }
    case "description": {
      const present = summary?.toLowerCase().indexOf(value?.toLowerCase()) > -1;
      return operatorConvert(present, operator);
    }
    default: {
      return false;
    }
  }
};
