// box-office-daily-slice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import axiosInstance from "../../../../../../utils/axios";
import { createBoxOfficeUrl } from "../../../utils/utils";

// Define the initial state for the daily box office slice
const initialState = {
  data: [],
  status: "idle",
  error: null,
};

// Define an asynchronous thunk to fetch daily box office data
const fetchTimeseriesBoxOfficeData = createAsyncThunk(
  "boxOfficeTimeseries/fetchData",
  async ({ occasion, region, startDate, endDate }, { rejectWithValue }) => {
    try {
      // Make an API call to fetch daily box office data
      const response = await axiosInstance.get(
        createBoxOfficeUrl({ occasion, region, startDate, endDate }),
      );
      // Return the data to be used as the payload
      return response.data;
    } catch (error) {
      // If there's an error, throw it to be handled
      return rejectWithValue(error);
    }
  },
);

// Create a slice for the daily box office data
const boxOfficeTimeseriesSlice = createSlice({
  name: "boxOfficeTimeseries",
  initialState,
  reducers: {
    // Add any synchronous reducers if needed
  },
  extraReducers: (builder) => {
    // Handle the pending state when the async thunk is in progress
    builder.addCase(fetchTimeseriesBoxOfficeData.pending, (state) => {
      // Use _.set to update the state
      _.set(state, "status", "loading");
    });

    // Handle the fulfilled state when the async thunk is successful
    builder.addCase(fetchTimeseriesBoxOfficeData.fulfilled, (state, action) => {
      // Use _.set to update the state
      _.set(state, "status", "succeeded");
      _.set(state, "data", action?.payload || []);
    });

    // Handle the rejected state when the async thunk fails
    builder.addCase(fetchTimeseriesBoxOfficeData.rejected, (state, action) => {
      // Use _.set to update the state
      _.set(state, "status", "failed");
      _.set(state, "error", action.error.message);
    });
  },
});

// Export the async thunk for use in components or other parts of the app
export { fetchTimeseriesBoxOfficeData };

// Export the reducer to be used in the store
export default boxOfficeTimeseriesSlice.reducer;
