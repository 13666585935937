import { AgCharts } from "ag-charts-react";
import React from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../hooks/UseTheme/useGetChartTheme";

const numFormatter = new Intl.NumberFormat("en-US");

export const BoxOfficeShareChart = ({ data, isLoading, error , marketShareKey, title }) => {
  const { theme } = useGetChartTheme(CHART_TYPES.POLYCHROMA);
  const top15 = data?.slice(0, 15);

  const options = top15
    ? {
        theme: theme,
        legend: { enabled: false },
        data: top15,
        animation: { enabled: true },
        title: {
          text: title,
          fontSize: 16,
          fontFamily: "Open Sans",
        },
        series: [
          {
            type: "pie",
            calloutLabelKey: "distributor",
            sectorLabelKey: marketShareKey,
            angleKey: marketShareKey,
            calloutLabel: {
              offset: 10,
            },
            sectorLabel: {
              formatter: ({ datum }) => `${numFormatter.format(datum[marketShareKey])}%`,
            },
            tooltip: {
              renderer: ({ datum }) => ({
                title: `${datum.distributor}`,
                content: `${datum[marketShareKey].toFixed(2)}%`,
              }),
            },
          },
        ],
      }
    : null;

  if (isLoading) {
    return (
      <div className="flex flex-row justify-center h-160">
        <CustomLoadingOverlay />
      </div>
    );
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  if (!options) {
    return <div>No data to display</div>;
  }

  return (
    <div className="h-160">
      <AgCharts options={options} style={{ height: "100%" }} />
    </div>
  );
};