import {
  Box,
  ExpandableSection,
  Grid,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import { isDataAvailable } from "../../../utils";
import { DateRange } from "../DateRange";
import { ImdbTrends } from "./ImdbTrends/ImdbTrends";
import { PiracyTrends } from "./PiracyTrends/PiracyTrends";
import { RedditTrends } from "./RedditTrends/RedditTrends";
import SocialTrends from "./SocialTrends";
import { WikipediaTrends } from "./WikipediaTrends/WikipediaTrends";
import { YoutubeTrends } from "./YoutubeTrends/YoutubeTrends";

interface PlatformTrendsProps {
  dateRange: any;
  setDateRange: (dateRange: { startDate: string; endDate: string }) => void;
  itemData: any;
  wikipediaTrendsData: any;
  youtubeTrendsData: any;
  imdbTrendsData: any;
}

export const PlatformTrends: FC<PlatformTrendsProps> = ({
  dateRange,
  setDateRange,
  itemData,
  wikipediaTrendsData,
  youtubeTrendsData,
  imdbTrendsData,
}) => {
  return (
    <SpaceBetween direction="vertical" size="l">
      <Box float="right" variant="p">
        <DateRange dateRange={dateRange} setDateRange={setDateRange} />
      </Box>
      {/* {itemData?.data?.tracked && itemData?.data?.instagram && (
        <ExpandableSection
          defaultExpanded
          headingTagOverride="h4"
          variant="container"
          headerText="Instagram Trends"
        >
          <SocialTrends itemData={itemData} />
        </ExpandableSection>
      )} */}
      <Grid
        gridDefinition={[
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
        ]}
      >
        {isDataAvailable(wikipediaTrendsData) && (
          <WikipediaTrends
            data={wikipediaTrendsData}
            dateRange={dateRange}
            configType="advanced"
          />
        )}
        {isDataAvailable(youtubeTrendsData) && (
          <YoutubeTrends
            data={youtubeTrendsData}
            dateRange={dateRange}
            configType="advanced"
          />
        )}
        {isDataAvailable(imdbTrendsData) && (
          <ImdbTrends
            data={imdbTrendsData}
            dateRange={dateRange}
            configType="advanced"
          />
        )}
        <RedditTrends ipId={itemData?.data?.ip_id} dateRange={dateRange} />
        <PiracyTrends ipId={itemData?.data?.ip_id} dateRange={dateRange} />
      </Grid>
    </SpaceBetween>
  );
};
