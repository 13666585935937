import {
  Box,
  Button,
  FormField,
  Modal,
  SpaceBetween,
  Select,
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../../../../utils/axios";
import { useFranchiseActions } from "../../../hooks/useFranchiseActions";

export const AddToFranchiseModal = ({
  visible = false,
  itemData,
  onDiscard = () => {},
}) => {
  const { updateFranchiseCards } = useFranchiseActions();
  const [searchItems, setSearchItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [query, setQuery] = useState("");
  const [statusType, setStatusType] = useState("finished");
  const [selectedOption, setSelectedOption] = useState({
    label: "",
    value: "",
  });

  const searchItem = useCallback(
    (query) => {
      if (!query) return;

      axiosInstance
        .get(`topsearch?keyword=${query}&vertical=Franchise`)
        .then((data) => data.data)
        .then((data) => {
          setSearchItems(data);
          setStatusType("finished");
        })
        .catch((e) => {
          console.error(e);
          setStatusType("error");
        });
    },
    [setSearchItems, selectedItems],
  );

  useEffect(() => {
    if (query) {
      searchItem(query);
    }
  }, [query, searchItem]);

  return (
    <Modal
      size="medium"
      visible={visible}
      onDismiss={onDiscard}
      header={"Add Title to Franchise"}
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={(e) => {
                e.preventDefault();
                onDiscard();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={!selectedOption?.value}
              onClick={() => {
                updateFranchiseCards({
                  id: selectedOption.value,
                  name: selectedOption.label,
                  removed: [],
                  added: [itemData?.data?.ip_id],
                });
                onDiscard();
              }}
            >
              Add
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div>
        Select a franchise from the options below to add the current title.
        Then, click 'Add' to confirm.
      </div>
      <FormField stretch>
        <Select
          selectedOption={selectedOption}
          options={
            searchItems.length > 0
              ? searchItems.map((item) => ({
                  label: item?.ip,
                  value: item?.ip_id,
                }))
              : []
          }
          onLoadItems={({ detail }) => {
            const { filteringText } = detail;
            if (filteringText) {
              setStatusType("loading");
              setSearchItems([]);
              searchItem(filteringText);
            }
          }}
          onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
          statusType={statusType}
          loadingText="Loading..."
          placeholder="Search..."
          filteringPlaceholder="Search for Franchises"
          filteringType="manual"
          filteringResultsText={(count) => `${count} results found`}
        />
      </FormField>
    </Modal>
  );
};
