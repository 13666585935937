import { dispatch } from "../../../../../../app/store";
import { fetchTimeseriesBoxOfficeData } from "../slices/box-office-timeseries-slice";

const getBoxOfficeTimeseriesData = ({
  occasion,
  region,
  startDate,
  endDate,
}) => {
  dispatch(
    fetchTimeseriesBoxOfficeData({ occasion, region, startDate, endDate }),
  );
};

export { getBoxOfficeTimeseriesData };
