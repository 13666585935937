import axiosInstance from "../../utils/axios";
import { UserTypes } from "./constants";

interface PlatformWeightageAPIProps {
  user: any;
  fileName: string;
  platform: string;
  isDefault?: boolean;
  type: string;
}

interface PostPlatformWeightageProps extends PlatformWeightageAPIProps {
  payload: any;
}

interface DeletePlatformWeightageProps extends PlatformWeightageAPIProps {}

export const getAllPlatformWeightages = (platform: string) => {
  const url = `https://api.searchpantheon.com/preferences/${platform}/weightage`;

  return axiosInstance.request({
    url,
    method: "GET",
  });
};

export const constructPlatformWeightageUrl = (weightage) => {
  return weightage.type.value === UserTypes.ORGANIZATION
    ? `/preferences/${UserTypes.ORGANIZATION}/${weightage.user["custom:organization"]}/${weightage.platform}/${weightage.service}/${weightage.fileName}`
    : `/preferences/${UserTypes.PERSONAL}/${weightage.user.username}/${weightage.platform}/${weightage.service}/${weightage.fileName}`;
};

export const getPlatformWeightages = (platformWeightage) => {
  const url = constructPlatformWeightageUrl(platformWeightage);

  return axiosInstance.request({
    url,
    method: "GET",
  });
};

export const postPantheonRankingPreference = ({
  user,
  fileName,
  platform,
  isDefault,
  type,
  payload,
}: PostPlatformWeightageProps) => {
  const url = `/preferences/${type}/${
    type === UserTypes.ORGANIZATION
      ? user["custom:organization"]
      : user.username
  }/${platform}/weightage/${fileName}.json`;

  return axiosInstance
    .request({
      url,
      method: "POST",
      params: isDefault
        ? {
            default: isDefault,
          }
        : {},
      data: payload,
    })
    .catch((error) => {
      return error;
    });
};

export const deletePantheonRankingPreference = ({
  user,
  fileName,
  platform,
  type,
}: DeletePlatformWeightageProps) => {
  const url = `/preferences/${type}/${
    type === UserTypes.ORGANIZATION
      ? user["custom:organization"]
      : user.username
  }/${platform}/weightage`;

  return axiosInstance
    .request({
      url,
      method: "DELETE",
      data: {
        files: [`${fileName}.json`],
      },
    })
    .catch((error) => {
      return error;
    });
};
