import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getPlatformWeightages } from "../requests";

export const useGetPlatformWeightages = (platformWeightage) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_PLATFORM_WEIGHTAGES, platformWeightage?.fileName],
    () => getPlatformWeightages(platformWeightage),
    {
      enabled: !!platformWeightage,
    },
  );

  return {
    data,
    isLoading,
    error,
  };
};
