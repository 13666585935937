import axiosInstance from "../../../../../utils/axios";

type Body = {
  sort: Array<string>;
  include: Array<string>;
  exclude: Array<string>;
  include_condition: string;
};

export type FetchMediaDataProps = {
  from: number;
  pageSize: number;
  vertical: string;
  range: string | null;
  body: Body | {};
};

export const fetchMediaData = async ({
  from,
  pageSize,
  range,
  body,
  vertical,
}: FetchMediaDataProps) => {
  const verticalMap = new Map([
    ["movies", "Movies"],
    ["series", "Television"],
    ["games", "Gaming"],
    ["companies", "company"],
    ["franchises", "Franchise"],
  ]);

  return await axiosInstance.request({
    url: `explore/${verticalMap.get(vertical)}?from=${from}&size=${pageSize}${
      range ? range : ""
    }`,
    method: "POST",
    data: body,
  });
};
