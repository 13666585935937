import {
  Box,
  Button,
  Checkbox,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";

interface TrackUnTrackConfirmationModalProps {
  ip_id: string;
  itemData: any;
  ipLists?: Array<{ value: string; label: string }>;
  visible: boolean;
  onDiscard: () => void;
  onConfirm: (params: {
    track: boolean;
    ip_id: string;
    ip_list: string[];
    data: any;
  }) => void;
  consentText?: string;
  track?: boolean;
  defaultSelectedIps?: string[];
}

export function TrackUnTrackConfirmationModal({
  ip_id,
  itemData,
  ipLists = [],
  visible,
  onDiscard,
  onConfirm,
  consentText,
  track = true,
  defaultSelectedIps,
}: TrackUnTrackConfirmationModalProps) {
  const [consentInputText, setConsentInputText] = useState("");
  const [selectedIps, setSelectedIps] = useState(defaultSelectedIps || []);

  useEffect(() => {
    setConsentInputText("");
  }, [visible]);

  const inputMatchesConsentText =
    consentInputText.toLowerCase() === consentText?.toLowerCase();

  const checkAndRemoveIps = (item) => {
    if (selectedIps.includes(item)) {
      const newSelectedIps = selectedIps.filter((i) => i !== item);
      setSelectedIps(newSelectedIps);
    } else {
      setSelectedIps([...selectedIps, item]);
    }
  };

  return (
    <Modal
      visible={visible}
      onDismiss={onDiscard}
      header={
        track
          ? "Remove title from tracking list "
          : "Add title to tracking list"
      }
      closeAriaLabel="Close dialog"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDiscard}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() =>
                onConfirm({
                  track,
                  ip_id,
                  ip_list: selectedIps,
                  data: itemData,
                })
              }
              disabled={
                !inputMatchesConsentText || (!track && selectedIps.length === 0)
              }
              data-testid="submit"
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <Box variant="span">
          {track
            ? `Are you sure you want to remove `
            : "Are you sure you want to track "}
          <span style={{ color: "red", fontWeight: "bold" }}>
            {consentText}
          </span>
          {track
            ? " from Tracking List?"
            : " ?. Tracking the item may take some time. Expect to see it in your list within 30 seconds and Wikipedia data in 10 minutes. Trend data will follow in 24 hours."}
        </Box>

        <Box>
          <div className="font-bold">IP List</div>
          {ipLists?.length > 0 && (
            <div className="flex flex-grow flex-row items-center gap-2 mt-2">
              {ipLists.map((item) => {
                return (
                  <Checkbox
                    key={item.value}
                    onChange={({ detail }) => checkAndRemoveIps(item.value)}
                    checked={selectedIps?.includes(item.value)}
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
            </div>
          )}
        </Box>

        <div className="text-red-500">
          To avoid accidental action, we ask you to provide additional written
          consent.
        </div>

        <form>
          <FormField label={`To confirm this, type "${consentText}".`}>
            <ColumnLayout columns={2}>
              <Input
                placeholder={consentText}
                onChange={(event) => setConsentInputText(event.detail.value)}
                value={consentInputText}
                ariaRequired={true}
              />
            </ColumnLayout>
          </FormField>
        </form>
      </SpaceBetween>
    </Modal>
  );
}
