import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Autosuggest,
  Box,
  Button,
  ButtonDropdown,
  Container,
  ContentLayout,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  Select,
  SpaceBetween,
  StatusIndicator,
  Table,
  Tabs,
  TextFilter,
  TokenGroup,
} from "@cloudscape-design/components";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuthContext } from "../../../auth/useAuthContext";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import convertIntervalDate from "../../../utils/convertIntervalDate";
import { Layout } from "../Layout";

const getBrowserVisibilityProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "visibilitychange";
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
};

const getBrowserDocumentHiddenProp = () => {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
};

const getIsDocumentHidden = () => {
  return !document[getBrowserDocumentHiddenProp()];
};

const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(getIsDocumentHidden());
  const onVisibilityChange = () => setIsVisible(getIsDocumentHidden());

  useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();
    document.addEventListener(visibilityChange, onVisibilityChange, false);
    return () =>
      document.removeEventListener(visibilityChange, onVisibilityChange);
  }, []);

  return isVisible;
};

const GoogleRealtimeSearch = () => {
  const { user } = useAuthContext();

  const flashbarRef = useRef(null);

  const isVisibleTab = usePageVisibility();
  const [queryParams, setQueryParams] = useSearchParams();

  const breadcrumbs = [
    { text: "Tools" },
    { text: "Google Search Trends", href: "/" },
  ];
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const defaultDateRange = {
    type: "absolute",
    startDate: moment()
      .subtract(90, "days")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
  const listRefreshInterval = 10 * 1000;
  const regionOptions = [
    { value: "", label: "Worldwide" },
    { value: "US", label: "United States" },
  ];
  const listOptions = [
    { value: "personal", label: "Personal" },
    { value: "organization", label: "Organization" },
  ];

  const [myJobsListResponse, setMyJobsListResponse] =
    useState(defaultResponseState);
  const [autosuggestResponse, setAutosuggestResponse] =
    useState(defaultResponseState);

  const [terms, setTerms] = useState([]);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [region, setRegion] = useState(regionOptions[0]);
  const [jobName, setJobName] = useState(null);
  const [listToSaveTo, setListToSaveTo] = useState(listOptions[0]);
  const [autosuggestValue, setAutosuggestValue] = useState(null);
  const [autosuggestOptions, setAutosuggestOptions] = useState([]);
  const [startSearchModalVisible, setStartSearchModalVisible] = useState(false);
  const [dateRangeValid, setDateRangeValid] = useState(true);

  const [listTabId, setListTabId] = useState(
    listOptions.find((x) => x.value === queryParams.get("list"))?.value ||
      "personal",
  );
  const [myJobsTableItems, setMyJobsTableItems] = useState([]);
  const [selectedMyJobsTableItems, setSelectedMyJobsTableItems] = useState([]);
  const [myJobsInitiallyLoaded, setMyJobsInitiallyLoaded] = useState(false);
  const [jobMarkedForDeletion, setJobMarkedForDeletion] = useState(null);

  const [renameSearchModalVisible, setRenameSearchModalVisible] =
    useState(false);
  const [moveToModalVisible, setMoveToModalVisible] = useState(false);
  const [deleteSearchModalVisible, setDeleteSearchModalVisible] =
    useState(false);
  const [currentlyEditingJob, setCurrentlyEditingJob] = useState(null);

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(myJobsTableItems, {
    filtering: {},
    pagination: { pageSize: 10 },
    sorting: {},
    selection: {},
  });

  const handleNewSearchFormSubmit = () => {
    if (!newSearchFormValid()) return;
    if (jobMarkedForDeletion) {
      deleteJob(jobMarkedForDeletion.id);
      setJobMarkedForDeletion(null);
    }
    setStartSearchModalVisible(false);
    startJob();
  };

  const handleShowNewSearchModal = () => {
    setJobName("");
    setTerms([]);
    setRegion(regionOptions[0]);
    setDateRange(defaultDateRange);
    setStartSearchModalVisible(true);
    setJobMarkedForDeletion(null);
    setListToSaveTo(listOptions.find((x) => x.value == listTabId));
  };

  const getReport = (jobData) => {
    flashbarRef?.current?.setFlashbarMessage(
      "loading",
      <div>Generating report.</div>,
      null,
    );

    axiosInstance
      .request({
        method: "GET",
        url: "/googletrends/realtime/getreport",
        params: {
          id: jobData.id,
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${jobData.name}.zip`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        flashbarRef?.current?.setFlashbarMessage(
          "success",
          <div>Successfully generated report.</div>,
          null,
          5000,
        );
      })
      .catch((error) => {
        console.log(error);
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          error.response?.data?.message || "Failed to generate report.",
          "Failed to generate report",
        );
      });
  };

  const newSearchFormValid = () => {
    if (terms.length == 0) return false;
    if (!dateRange.startDate || !dateRange.endDate) return false;
    if (!region) return false;
    if (!getDateRangeValid(dateRange.startDate, dateRange.endDate))
      return false;
    return true;
  };

  const getDateRangeValid = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return false;
    }
    if (typeof startDate === "string" || startDate instanceof String) {
      startDate = new Date(Date.parse(startDate));
    }
    if (typeof endDate === "string" || endDate instanceof String) {
      endDate = new Date(Date.parse(endDate));
    }
    if (
      startDate < new Date(Date.parse("2004-01-01")) ||
      endDate > new Date()
    ) {
      return false;
    }
    return true;
  };

  const startJob = () => {
    const searchTerms = terms.map((terms) => terms.value).join(",");
    const formattedStartDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    const formattedDateRange = `${formattedStartDate} ${formattedEndDate}`;

    flashbarRef?.current?.setFlashbarMessage(
      "loading",
      <div>Creating search job.</div>,
      null,
      5000,
    );

    axiosInstance
      .request({
        method: "POST",
        url: "/googletrends/realtime/startjob",
        params: {
          name: jobName,
          q: searchTerms,
          geo: region.value,
          gprop: "",
          date: formattedDateRange,
          list: listToSaveTo.value,
        },
      })
      .then((response) => {
        flashbarRef?.current?.setFlashbarMessage(
          "success",
          <div>Successfully created search job.</div>,
          null,
          5000,
        );
        fetchJobsList();
      })
      .catch((e) => {
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          e.response?.data?.message || "Failed to create search job.",
          "Failed to create search job",
        );
      });
  };

  const fetchJobsList = (listToGet) => {
    setMyJobsListResponse({
      ...myJobsListResponse,
      loading: true,
      completed: false,
      error: null,
    });

    if (!listToGet) listToGet = listTabId;

    axiosInstance
      .request({
        method: "GET",
        url: "/googletrends/realtime/listjobs",
        params: {
          list: listToGet,
        },
      })
      .then((response) => {
        setMyJobsListResponse({
          ...myJobsListResponse,
          data: response.data,
          loading: false,
          completed: true,
          error: null,
        });
        const tableItems = response.data;
        setMyJobsTableItems(tableItems);
        setMyJobsInitiallyLoaded(true);
      })
      .catch((error) => {
        setMyJobsListResponse({
          ...myJobsListResponse,
          data: null,
          error: error,
          loading: false,
          completed: true,
        });
      });
  };

  const deleteJob = (jobId) => {
    flashbarRef?.current?.setFlashbarMessage(
      "loading",
      <div>Deleting search.</div>,
      null,
      5000,
    );

    const newMyJobsTableItems = myJobsTableItems.filter(
      (item) => item.id !== jobId,
    );
    setMyJobsTableItems(newMyJobsTableItems);

    axiosInstance
      .request({
        method: "PATCH",
        url: "/googletrends/realtime/updatejob",
        params: {
          id: jobId,
          action: "delete",
        },
      })
      .then((response) => {
        flashbarRef?.current?.setFlashbarMessage(
          "success",
          <div>Successfully deleted search.</div>,
          null,
          5000,
        );
        fetchJobsList();
      })
      .catch((e) => {
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          e.response?.data?.message || "Failed to delete search job.",
          "Failed to delete search job",
        );
      });
  };

  const updateJob = (jobId, action, params) => {
    flashbarRef?.current?.setFlashbarMessage(
      "loading",
      <div>Updating search.</div>,
      null,
      5000,
    );

    axiosInstance
      .request({
        method: "PATCH",
        url: "/googletrends/realtime/updatejob",
        params: {
          id: jobId,
          action: action,
          ...params,
        },
      })
      .then((response) => {
        flashbarRef?.current?.setFlashbarMessage(
          "success",
          <div>Successfully updated search.</div>,
          null,
          5000,
        );
        fetchJobsList();
      })
      .catch((e) => {
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          e.response?.data?.message || "Failed to update search job.",
          "Failed to update search job",
        );
      });
  };

  const getAutosuggestions = (value) => {
    if (value.length == 0) return;
    setAutosuggestResponse({
      ...autosuggestResponse,
      loading: true,
      completed: false,
      error: null,
    });

    axiosInstance
      .request({
        method: "GET",
        url: `/googletrends/realtime/autocomplete/${value}`,
      })
      .then((response) => {
        setAutosuggestResponse({
          ...autosuggestResponse,
          loading: false,
          completed: true,
          data: response.data,
          error: null,
        });
        const newAutosuggestOptions = response.data
          .filter((option) => option.type !== "Search term")
          .map((option) => ({
            value: option.mid,
            label: option.title,
            description: option.type,
          }));
        setAutosuggestOptions(newAutosuggestOptions);
      })
      .catch((error) => {
        setAutosuggestResponse({
          ...autosuggestResponse,
          loading: false,
          completed: true,
          error: error,
        });
      });
  };

  const jobListTable = () => {
    return (
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) =>
          setSelectedMyJobsTableItems(detail.selectedItems)
        }
        selectedItems={selectedMyJobsTableItems}
        columnDefinitions={[
          {
            id: "name",
            header: "Name",
            cell: (item) => (
              <Header
                variant="h4"
                description={
                  <>
                    {item.parameters.q.split(",").length} terms &bull;{" "}
                    {
                      regionOptions.find((x) => x.value == item.parameters.geo)
                        .label
                    }{" "}
                    &bull;{" "}
                    {new Date(
                      Date.parse(item.parameters.date.split(" ")[0]),
                    ).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      timeZone: "UTC",
                    })}{" "}
                    to{" "}
                    {new Date(
                      Date.parse(item.parameters.date.split(" ")[1]),
                    ).toLocaleString("en-US", {
                      month: "short",
                      day: "numeric",
                      year: "numeric",
                      timeZone: "UTC",
                    })}
                  </>
                }
              >
                <Button
                  href={`/tools/google-search-trends/searches/${item.id}`}
                  variant="inline-link"
                  disabled={item.status !== "FINISHED"}
                >
                  {item.name}
                </Button>
              </Header>
            ),
            isRowHeader: true,
          },
          {
            id: "created_at",
            header: "Created at",
            cell: (item) =>
              new Date(item.created_at * 1000).toLocaleString("en-US", {
                month: "long",
                day: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              }),
          },
          {
            id: "created_by",
            header: "Created by",
            cell: (item) => item.created_by,
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => {
              switch (item.status) {
                case "RUNNING":
                  return (
                    <StatusIndicator type="pending">Running</StatusIndicator>
                  );
                case "FINISHED":
                  return <StatusIndicator>Finished</StatusIndicator>;
                case "FAILED":
                  return <StatusIndicator type="error">Failed</StatusIndicator>;
              }
            },
          },
        ]}
        columnDisplay={[
          { id: "name", visible: true },
          { id: "created_at", visible: true },
          { id: "created_by", visible: listTabId == "organization" },
          { id: "status", visible: true },
          { id: "actions", visible: true },
        ]}
        loadingText="Loading searches"
        items={items}
        variant="embedded"
        selectionType="single"
        loading={!myJobsInitiallyLoaded}
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            //filteringText={filteringText}
            //onChange={({ detail }) => setFilteringText(detail.value)}
            filteringPlaceholder="Find searches"
            filteringAriaLabel="Filter searches"
          />
        }
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            <SpaceBetween size="m">
              <b>No searches</b>
              <Button
                variant="primary"
                iconName="search"
                onClick={handleShowNewSearchModal}
              >
                Start search
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={
          <Header
            variant="h2"
            description={
              listTabId == "personal"
                ? "View your saved searches and run new ones"
                : "View your organization's saved searches and run new ones"
            }
            counter={`(${myJobsTableItems.length})`}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <ButtonDropdown
                  items={[
                    {
                      text: "Download report",
                      id: "download_report",
                      disabled:
                        selectedMyJobsTableItems[0]?.status !== "FINISHED",
                    },
                    {
                      text: "Run again",
                      id: "run_again",
                      disabled:
                        selectedMyJobsTableItems[0]?.status === "RUNNING",
                    },
                    { text: "Rename", id: "rename" },
                    {
                      text: "Move to",
                      id: "move_to",
                      disabled:
                        selectedMyJobsTableItems[0]?.created_by !==
                        user.username,
                    },
                    {
                      text: "Delete",
                      id: "delete",
                      disabled:
                        selectedMyJobsTableItems[0]?.status === "RUNNING",
                    },
                  ]}
                  disabled={selectedMyJobsTableItems.length == 0}
                  onItemClick={({ detail }) => {
                    const selectedJob = selectedMyJobsTableItems[0];
                    switch (detail.id) {
                      case "rename":
                        setRenameSearchModalVisible(true);
                        setCurrentlyEditingJob(selectedJob);
                        setJobName(selectedJob.name);
                        break;
                      case "move_to":
                        setMoveToModalVisible(true);
                        setCurrentlyEditingJob(selectedJob);
                        setListToSaveTo(
                          listOptions.find((x) => x.value == selectedJob.list),
                        );
                        break;
                      case "run_again":
                        setTerms(
                          selectedJob.parameters.q
                            .split(",")
                            .map((term) => ({ value: term, label: term })),
                        );
                        setRegion(
                          regionOptions.find(
                            (x) => x.value == selectedJob.parameters.geo,
                          ),
                        );
                        setDateRange({
                          type: "absolute",
                          startDate: selectedJob.parameters.date.split(" ")[0],
                          endDate: selectedJob.parameters.date.split(" ")[1],
                        });
                        setJobName(selectedJob.name);
                        setStartSearchModalVisible(true);
                        setJobMarkedForDeletion(selectedJob);
                        setListToSaveTo(
                          listOptions.find((x) => x.value == selectedJob.list),
                        );
                        break;
                      case "delete":
                        setJobMarkedForDeletion(selectedJob);
                        setDeleteSearchModalVisible(true);
                        break;
                      case "download_report":
                        getReport(selectedJob);
                        break;
                    }
                  }}
                >
                  Actions
                </ButtonDropdown>
                <Button
                  variant="primary"
                  iconName="search"
                  onClick={handleShowNewSearchModal}
                >
                  Start search
                </Button>
              </SpaceBetween>
            }
          >
            {listTabId == "personal"
              ? "My searches"
              : `Organization searches - ${user["custom:organization"]}`}
          </Header>
        }
      />
    );
  };

  useEffect(() => {
    if (isVisibleTab === true) {
      fetchJobsList();
    }

    const interval = setInterval(() => {
      if (isVisibleTab) {
        fetchJobsList(listTabId);
      }
    }, listRefreshInterval);

    return () => {
      clearInterval(interval);
    };
  }, [listTabId, isVisibleTab]);

  useEffect(() => {
    setSelectedMyJobsTableItems(
      myJobsTableItems.filter((item) =>
        selectedMyJobsTableItems.map((x) => x.id).includes(item.id),
      ),
    );
  }, [myJobsTableItems]);

  return (
    <Layout
      title="Google Realtime Search"
      content={
        <>
          <ContentLayout header={<PantheonFlashbar ref={flashbarRef} />}>
            <Container variant="borderless" disableContentPaddings disableHeaderPaddings>
              <Tabs
                onChange={({ detail }) => {
                  setMyJobsInitiallyLoaded(false);
                  setListTabId(detail.activeTabId);
                  setQueryParams({ list: detail.activeTabId });
                }}
                activeTabId={listTabId}
                tabs={[
                  {
                    label: "My searches",
                    id: "personal",
                    content: jobListTable(),
                  },
                  {
                    label: "Organization searches",
                    id: "organization",
                    content: jobListTable(),
                  },
                ]}
              />
            </Container>
          </ContentLayout>

          <Modal
            header={
              !!jobMarkedForDeletion ? "Re-run search" : "Start a new search"
            }
            visible={startSearchModalVisible}
            onDismiss={() => setStartSearchModalVisible(false)}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="link"
                    onClick={() => setStartSearchModalVisible(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!newSearchFormValid()}
                    onClick={handleNewSearchFormSubmit}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            <SpaceBetween direction="vertical" size="m">
              <SpaceBetween direction="horizontal" size="xs">
                <FormField label="Region">
                  <Select
                    onChange={({ detail }) => setRegion(detail.selectedOption)}
                    selectedOption={region}
                    options={regionOptions}
                  />
                </FormField>
                <FormField label="Date range">
                  <RangeDateSelector
                    defaults={dateRange}
                    onChange={(e) => {
                      setDateRangeValid(
                        getDateRangeValid(e.startDate, e.endDate),
                      );
                      setDateRange(e);
                    }}
                    isValidRange={(value) => {
                      let startDate = null;
                      let endDate = null;
                      if (value.type === "relative") {
                        const newDateRange = convertIntervalDate(value);
                        startDate = newDateRange.startDate;
                        endDate = newDateRange.endDate;
                      } else if (value.type === "absolute") {
                        startDate = new Date(
                          Date.parse(value.startDate.split("T")[0]),
                        );
                        endDate = new Date(
                          Date.parse(value.endDate.split("T")[0]),
                        );
                      }
                      return getDateRangeValid(startDate, endDate);
                    }}
                    invalid={dateRangeValid === false}
                  />
                </FormField>
              </SpaceBetween>
              <FormField label="Search terms">
                <Autosuggest
                  onChange={({ detail }) => setAutosuggestValue(detail.value)}
                  onLoadItems={({ detail }) =>
                    getAutosuggestions(detail.filteringText)
                  }
                  onSelect={({ detail }) => {
                    let option = detail.selectedOption;
                    if (!detail.selectedOption) {
                      option = {
                        value: detail.value,
                        label: detail.value,
                        description: "Search term",
                      };
                    }
                    setAutosuggestValue("");
                    setTerms([...terms, option]);
                  }}
                  value={autosuggestValue}
                  placeholder="Enter search terms"
                  options={autosuggestOptions}
                  enteredTextLabel={(value) => `Use: "${value}"`}
                  statusType={
                    autosuggestResponse.loading ? "loading" : "finished"
                  }
                  loadingText="Loading suggestions"
                  filteringType="none"
                />
                <TokenGroup
                  onDismiss={({ detail: { itemIndex } }) => {
                    setTerms([
                      ...terms.slice(0, itemIndex),
                      ...terms.slice(itemIndex + 1),
                    ]);
                  }}
                  items={terms}
                  limit={9}
                  i18nStrings={{
                    limitShowFewer: "Show less",
                    limitShowMore: "Show more",
                  }}
                />
              </FormField>
              <FormField label="Name">
                <Input
                  onChange={({ detail }) => setJobName(detail.value)}
                  value={jobName}
                  placeholder="Enter a name"
                />
              </FormField>
              <FormField label="Save to list">
                <Select
                  onChange={({ detail }) =>
                    setListToSaveTo(detail.selectedOption)
                  }
                  selectedOption={listToSaveTo}
                  options={listOptions}
                />
              </FormField>
            </SpaceBetween>
          </Modal>

          <Modal
            header="Rename search"
            visible={renameSearchModalVisible}
            onDismiss={() => setRenameSearchModalVisible(false)}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="link"
                    onClick={() => setRenameSearchModalVisible(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    disabled={
                      !currentlyEditingJob ||
                      jobName == currentlyEditingJob.name ||
                      !jobName ||
                      jobName.length == 0
                    }
                    onClick={() => {
                      updateJob(currentlyEditingJob.id, "edit_name", {
                        name: jobName,
                      });
                      setRenameSearchModalVisible(false);
                    }}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            <FormField label="Name">
              <Input
                onChange={({ detail }) => setJobName(detail.value)}
                value={jobName}
              />
            </FormField>
          </Modal>

          <Modal
            header="Move search"
            visible={moveToModalVisible}
            onDismiss={() => setMoveToModalVisible(false)}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="link"
                    onClick={() => setMoveToModalVisible(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    disabled={
                      !currentlyEditingJob ||
                      listToSaveTo.value == currentlyEditingJob.list
                    }
                    onClick={() => {
                      updateJob(currentlyEditingJob.id, "change_list", {
                        list: listToSaveTo.value,
                      });
                      setMoveToModalVisible(false);
                    }}
                  >
                    Submit
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            <FormField label="Save to list">
              <Select
                onChange={({ detail }) =>
                  setListToSaveTo(detail.selectedOption)
                }
                selectedOption={listToSaveTo}
                options={listOptions}
              />
            </FormField>
          </Modal>

          <Modal
            header="Confirm deletion"
            visible={deleteSearchModalVisible}
            onDismiss={() => setDeleteSearchModalVisible(false)}
            footer={
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="link"
                    onClick={() => setDeleteSearchModalVisible(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    disabled={!jobMarkedForDeletion}
                    onClick={() => {
                      deleteJob(jobMarkedForDeletion.id);
                      setJobMarkedForDeletion(null);
                      setDeleteSearchModalVisible(false);
                    }}
                  >
                    Delete
                  </Button>
                </SpaceBetween>
              </Box>
            }
          >
            Delete search <b>{jobMarkedForDeletion?.name}</b>?
          </Modal>
        </>
      }
      navItems={navItems}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default GoogleRealtimeSearch;
