import { ExpandableSection } from '@cloudscape-design/components';
import { ColDef, ColGroupDef } from 'ag-grid-community';
import "ag-grid-enterprise";
import { AgGridReact } from 'ag-grid-react';
import React, { useMemo } from 'react';
import CustomLoadingOverlay from '../../../../components/PantheonLoading';
import { useGetGridTheme } from '../../../../hooks/UseTheme/useGetGridTheme';
import renderTrend from '../../../../utils/trendIcon';

interface RankingTableProps {
    ip_ranking: any;
}

const platformLabels: { [key: string]: string } = {
    wiki: "Wikipedia Pageviews",
    youtube: "YouTube Trailers",
    imdb: "IMDb Votes",
    rt: "Rotten Tomatoes Votes",
    piracy: "Piracy Downloads",
    twitch: "Twitch Viewers",
    steam: "Steam Players"
};

const renderDailyRank = (params) =>
    renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend");

const renderWeeklyRank = (params) =>
    renderTrend(params, "weekly_rank", "weekly_rank_change", "weekly_rank_trend");

const renderMonthlyRank = (params) =>
    renderTrend(params, "monthly_rank", "monthly_rank_change", "monthly_rank_trend");

const renderAllTimeRank = (params) =>
    renderTrend(params, "rank", "rank_change", "rank_trend");

const RankingTable: React.FC<RankingTableProps> = ({ ip_ranking }) => {
    const { theme } = useGetGridTheme();
    const defaultColDef = useMemo(
        () => ({
            sortable: true,
            flex: 1,
            suppressHeaderMenuButton: true,
            suppressHeaderFilterButton: true,
            filter: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        }),
        [],
    );

    const transformIpRanking = (ipRanking: any) => {
        return Object.entries(ipRanking)
            .filter(([platform]) => platform !== 'ip_id')
            .map(([platform, data]) => ({
                platform,
                platformLabel: platformLabels[platform] || platform,
                ...(data as Record<string, unknown>),
            }));
    };

    const rowData = transformIpRanking(ip_ranking);
    const columns: (ColDef | ColGroupDef)[] = [
        {
            field: "platformLabel",
            headerName: "Platform",
            valueGetter: (params) => platformLabels[params.data.platform] || params.data.platform
        },
        { field: "daily_rank", headerName: "Daily Rank", cellRenderer: renderDailyRank },
        { field: "weekly_rank", headerName: "7 Day Rank", cellRenderer: renderWeeklyRank },
        { field: "monthly_rank", headerName: "30 Day Rank", cellRenderer: renderMonthlyRank },
        { field: "rank", headerName: "All Time Rank", cellRenderer: renderAllTimeRank },
    ];

    return (
        <ExpandableSection
            defaultExpanded={true}
            headerText="Ranking Summary"
        >
            {/* <Container
                disableContentPaddings
                fitHeight
            > */}
                <div className={`${theme} compact h-46`}>
                    <AgGridReact
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        suppressDragLeaveHidesColumns={true}
                        columnDefs={columns}
                        loadingOverlayComponent={CustomLoadingOverlay}
                    />
                </div>
            {/* </Container> */}
        </ExpandableSection>
    );
}

export default RankingTable;
