import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTitleSearchByIdData } from "../request";

export const useGetTitleSearchByIdData = ({
  ipIds=[],
}) => {
  const params = {
    ipIds,
  };
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TITLE_SEARCH_BY_ID_DATA, { ...params }],
    () => getTitleSearchByIdData(params),
  );

  return {
    data: data?.data,
    isLoading,
    ...error,
  };
};
