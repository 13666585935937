import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

export const fetchAllIssuesApi = createAsyncThunk(
  "issues/fetchAllIssuesApi",
  async (params) => {
    let params_items = [];
    params?.query?.map((param) => {
      if (param.operator === "=") {
        params_items.push(`${param.propertyKey}=${param.value}`);
      } else {
        params_items.push(`${param.propertyKey}=*${param.value}*`);
      }
    });

    const url =
      params && params_items.length > 0
        ? "/issues?" + params_items.join("&")
        : "/issues?status=Open";
    const response = await axiosInstance.request({
      url: url,
      method: "GET",
    });
    return response.data;
  }
);

const issuesSlice = createSlice({
  name: "issues",
  initialState: {
    data: [],
    status: "idle",

    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllIssuesApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllIssuesApi.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAllIssuesApi.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default issuesSlice.reducer;
