import { CHART_LABELS } from "../../hooks/useGridConfig";

export const defaultColumn = CHART_LABELS.DOWNLOAD_TRENDS;

export const additionalPlatformColumns = [
  CHART_LABELS.FRANCHISE,
  CHART_LABELS.GENRE,
  CHART_LABELS.CUSTOM_TAGS,
  CHART_LABELS.COUNTRIES,
  CHART_LABELS.DISTRIBUTORS,
  CHART_LABELS.PRODUCTION,
  CHART_LABELS.OTT,
  CHART_LABELS.DOWNLOAD_TRENDS,];

export const corePlatformColumns = [
  CHART_LABELS.DOWNLOADS,
  CHART_LABELS.WEEKLY_SUM,
  CHART_LABELS.MONTHLY_SUM,
  CHART_LABELS.TOTAL_DOWNLOADS,
  CHART_LABELS.DAYS_SINCE_START,
  CHART_LABELS.DAYS_IN_TOP_10,
  CHART_LABELS.FIRST_SEEN,
  
];
