import { GetPlatformDataResponse } from "../hooks/useGetPlatformData";
import {
  GetSteamDataResponseItem,
  MappedGetSteamResponseItem,
  mapSteamResponse,
} from "./mapSteamResponse";
import {
  GetTwitchDataResponseItem,
  MappedGetTwitchResponseItem,
  mapTwitchResponse,
} from "./mapTwitchResponse";
import {
  GetYoutubeDataResponseItem,
  MappedGetYoutubeResponseItem,
  mapYoutubeResponse,
} from "./mapYoutubeResponse";
import {
  GetImdbDataResponseItem,
  MappedGetImdbResponseItem,
  mapImdbResponse,
} from "./mapImdbResponse";
import {
  GetWikipediaDataResponseItem,
  MappedGetWikipediaResponseItem,
  mapWikipediaResponse,
} from "./mapWikipediaResponse";

interface MapGetPlatformResponseProps {
  data: GetPlatformDataResponse;
  ipId: string;
  platform: string;
}

interface ErrorItem {
  code: string;
  description: string;
}

export type PlatformError = Array<ErrorItem>;

export interface MappedGetPlatformResponse {
  data:
    | Array<MappedGetTwitchResponseItem>
    | Array<MappedGetSteamResponseItem>
    | Array<MappedGetYoutubeResponseItem>
    | Array<MappedGetImdbResponseItem>
    | Array<MappedGetWikipediaResponseItem>;
  errors: PlatformError;
}

export const mapGetPlatformResponse = ({
  data,
  ipId,
  platform,
}: MapGetPlatformResponseProps): MappedGetPlatformResponse => {
  let mappedResponse;
  const response = data?.data?.[ipId]?.[platform];

  if (!Array.isArray(response)) return { data: [], errors: data?.errors };

  switch (platform) {
    case "twitch":
      mappedResponse = response.map((item: GetTwitchDataResponseItem) =>
        mapTwitchResponse(item),
      );
      break;
    case "steam":
      mappedResponse = response.map((item: GetSteamDataResponseItem) =>
        mapSteamResponse(item),
      );
      break;
    case "youtube":
      mappedResponse = response.map((item: GetYoutubeDataResponseItem) =>
        mapYoutubeResponse(item),
      );
      break;
    case "imdb":
      mappedResponse = response.map((item: GetImdbDataResponseItem) =>
        mapImdbResponse(item),
      );
      break;
    case "wiki":
      mappedResponse = response.map((item: GetWikipediaDataResponseItem) =>
        mapWikipediaResponse(item),
      );
  }

  return { data: mappedResponse, errors: data?.errors };
};
