import { useQuery } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { QUERY_KEYS } from "../constants";
import { getGenericPreference } from "../requests";

type UseGetGenericPreferenceProps = {
  onSuccess?: (data: any) => void;
  onError?: () => void;
  onSettled?: () => void;
};

export const useGetGenericPreference = ({
  onSuccess,
  onError,
  onSettled,
}: UseGetGenericPreferenceProps = {}) => {
  const { user } = useAuthContext();
  const { isLoading, data, error } = useQuery(
    QUERY_KEYS.GET_GENERAL_PREFERENCE,
    () => getGenericPreference(user),
    {
      onSuccess,
      onError,
      onSettled,
    },
  );

  return {
    data: data?.data,
    isLoading,
    error,
  };
};
