import { useMutation } from "react-query";
import { PostYoutubeVideosParams, postYoutubeVideos } from "../request";

export const useAddYoutubeVideos = (onSuccess) => {
  const { isLoading, mutate, error } = useMutation(
    (params: PostYoutubeVideosParams) => postYoutubeVideos(params),
    { onSuccess },
  );

  return {
    mutate,
    isLoading,
    error,
  };
};
