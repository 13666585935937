import { dispatch } from "../../../../app/store";
import { createCreateNbcuSettingsDataAction, fetchCreateNbcuSettingsData } from "../slices/create-nbcu-settings-slice";
import {
  fetchGetNbcuSettingData,
  getNbcuSettingDataAction,
} from "../slices/get-nbcu-settings-slice";
import { fetchSaveNbcuSettingsData } from "../slices/save-nbcu-settings-slice";
import {
  fetchSettingsNbcuData,
  settingsNbcuDataAction,
} from "../slices/settings-nbcu-slice";

export async function getSettingsNbcuData() {
  await dispatch(fetchSettingsNbcuData());
}

export function clearSettingsNbcuData() {
  dispatch(settingsNbcuDataAction.clearData());
}

export async function getNbcuSettingData(id) {
  await dispatch(fetchGetNbcuSettingData(id));
}

export function clearNbcuSettingData() {
  dispatch(getNbcuSettingDataAction.clearData());
}
export async function makeChangeNbcuSettingData(data) {
  await dispatch(getNbcuSettingDataAction.makeChange(data));
}
export async function saveNbcuSettingData(data, id) {
  await dispatch(fetchSaveNbcuSettingsData({ data, id }));
}
export async function createNbcuSettingData(data) {
  await dispatch(fetchCreateNbcuSettingsData({ data }));
}

export function clearCreateNbcuSettingData() {
  dispatch(createCreateNbcuSettingsDataAction.clearData());
}



