import { dispatch } from "../../../../app/store";
import axiosInstance from "../../../../utils/axios";
import { googleTrendsRawDataAction } from "../slices/google-trends-rawdata-slice";

export async function getGoogleTrendsRawData({ interval, lte, gte}) {

    dispatch(googleTrendsRawDataAction.startLoading());
    try {
        const response = await axiosInstance.request({
            url: `googletrends/raw`,
            method: `GET`,
            params: { interval, lte, gte }
        });
     
        dispatch(googleTrendsRawDataAction.insertTrendRowData(response.data));
    } catch (e) {
       
    }

}