import React from "react";
import { Box, SpaceBetween } from "@cloudscape-design/components";
import styles from "./styles.module.css";

export const EmptyState = ({
  icon,
  title,
  description,
  action,
  verticalCenter,
}) => (
  <div className={verticalCenter ? styles.verticalCenter : ""}>
    <Box
      margin={{ vertical: "xs" }}
      textAlign="center"
      color="text-body-secondary"
    >
      <SpaceBetween size="xxs">
        <div>
          {icon && <div>{icon}</div>}
          <Box variant="strong" color="inherit">
            {title}
          </Box>
          <Box variant="p" color="inherit">
            {description}
          </Box>
        </div>
        {action}
      </SpaceBetween>
    </Box>
  </div>
);
