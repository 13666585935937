import { applyTheme } from "@cloudscape-design/components/theming";
import * as awsui from "@cloudscape-design/design-tokens";

const theme = {
  tokens: {
    colorTextNotificationDefault: {
      light: awsui.colorTextLinkDefault,
    },
    colorTextLinkHover: {
      // light: awsui.colorTextLinkHover,
      light: "414d5c",
    },
    colorTextInteractiveHover: {
      // light: awsui.colorTextLinkHover,
      light: "414d5c",
    },
  },
  contexts: {
    header: {
      tokens: {
        colorBackgroundLayoutMain: {
          light: "#FFF",
        },
        colorBackgroundContainerContent: {
          light: "#FFF",
        },
        colorTextHeadingDefault: {
          light: "#000",
        },
        colorTextHeadingSecondary: {
          light: "#414d5c",
        },
        colorBackgroundInputDefault: {
          light: "#FFF",
        },
        colorBackgroundInputDisabled: {
          light: "#FFF",
        },
        colorBorderInputDefault: {
          light: "#000",
        },
        colorBorderButtonNormalDisabled: {
          light: "#000",
        },
        colorBackgroundButtonNormalDefault: {
          light: "#FFF",
        },
        colorBackgroundButtonNormalHover: {
          light: "#FFF",
        },
        colorBackgroundButtonNormalActive: {
          light: "#FFF",
        },
        colorBackgroundButtonPrimaryHover: {
          light: "#89bdee",
        },
        colorBackgroundButtonPrimaryActive: {
          light: "#FFF",
        },
        colorTextButtonNormalHover: {
          light: "#000",
        },
        colorBorderButtonNormalHover: {
          light: "#000",
        },
        colorBackgroundDropdownItemDefault: {
          light: "#000",
        },
        colorBackgroundDropdownItemFilterMatch: {
          light: "#000",
        },
        colorBackgroundDropdownItemHover: {
          light: "#000",
        },
        colorTextDropdownItemDefault: {
          light: "#FFF",
        },
      },
    },
  },
};

applyTheme({ theme });
