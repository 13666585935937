import { Box, Container, SpaceBetween } from "@cloudscape-design/components";
import Logo from "../assets/images/Logo";
import ResetPasswordIcon from "../assets/images/ResetPasswordIcon";

export const ResetPasswordLayout = ({ children }) => {
    return (
        <>
            <Logo height={12} width={12} />
            <div className="flex min-h-screen w-full justify-center items-center">
                <div className="w-120 flex flex-col justify-center items-center">
                    <Container
                        media={{
                            content: (
                                <div className="pt-4 flex w-full justify-center items-center">
                                    <ResetPasswordIcon />
                                </div>
                            ),
                            position: "top"
                        }}
                    >
                        <SpaceBetween direction="vertical" size="s">
                            <Box variant="h1" textAlign="center">Request sent successfully!</Box>
                            We've sent a 6-digit confirmation email to your associated email. Please enter the code in below box to verify your identity. 
                            {children}
                        </SpaceBetween>
                    </Container>
                </div>
            </div>
        </>
    );
};
