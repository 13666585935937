
import CsvDownloadButton from "react-json-to-csv";
const CsvDownloader = ({ data }) => {
  return (
    <CsvDownloadButton
      data={data}
      target="_blank"
      filename={"compare_titles.csv"}
    >
     Save as Csv
    </CsvDownloadButton>
  );
};

export default CsvDownloader;
