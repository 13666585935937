import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { 
  AppLayout, 
  BreadcrumbGroup, 
  ContentLayout, 
  Container, 
  Link, 
  Spinner, 
  Header, 
  Select, 
  Multiselect, 
  SideNavigation, 
  SpaceBetween, 
  Button, 
  LineChart, 
  Table,
  Pagination,
  Modal,
  Box,
  Autosuggest,
  TokenGroup,
  Popover,
  StatusIndicator,
  ButtonDropdown,
  Input,
  PieChart,
} from "@cloudscape-design/components";
import moment from "moment";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { Helmet } from "react-helmet-async";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { 
  getAmazonVendorTimeseriesData, 
  getAmazonVendorTopProductsData, 
  getAmazonVendorTopCategoriesData,
  getAutoComplete, 
  getWikipediaTopics, 
  getSuggestedTopicTerms, 
  getTermPools 
} from "./redux/actions/amazon-vendor-actions";
import { useAuthContext } from "../../../auth/useAuthContext";
import axiosInstance from "../../../utils/axios";
import PantheonFlashbar from "../../../components/PantheonFlashbar";

export const AmazonVendorCentralPage = () => {
  const timeseriesData = useSelector((state) => state.amazonVendorTimeseries);
  const topProductsData = useSelector((state) => state.amazonVendorTopProducts);
  const topCategoriesData = useSelector((state) => state.amazonVendorTopCategories);
  const autoCompleteOptions = useSelector((state) => state.amazonVendorAutoComplete);
  const wikipediaTopicSearch = useSelector((state) => state.wikipediaTopicSearch);
  const suggestedTopicTerms = useSelector((state) => state.amazonVendorTermSuggestions);
  const termPoolList = useSelector((state) => state.amazonVendorTermPoolList);

  const { user } = useAuthContext();

  const wikipediaTopicSearchBarRef = useRef(null);
  const flashbarRef = useRef(null);

  const breadcrumbs = [{ text: "Consumer Products", }, { text: "Amazon Search", }, { text: "Analysis", href: "/", },];
  const regionOptions = [
    { value: "US", label: "United States" },
    { value: "UK", label: "United Kingdom" },
  ];
  const scaleOptions = [
    { value: "linear", label: "Linear" },
    { value: "log", label: "Logarithmic" },
  ];
  const defaultDateRange = {
    type: "absolute",
    startDate: moment()
      .subtract(365, "days")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  }

  const productsPerPage = 10;
  const categoriesPerPage = 7;
  const termRankingsPerPage = 10;

  const [ regions, setRegions ] = useState([regionOptions[0]]);
  const [ dateRange, setDateRange ] = useState(defaultDateRange);
  const [ selectedSuggestOptions, setSelectedSuggestOptions ] = useState([]);

  const [ chartSeries, setChartSeries ] = useState([]);
  const [ chartVisibleSeries, setChartVisibleSeries ] = useState([]);
  const [ yDomain, setYDomain ] = useState([0, 10000000]);
  const [ chartScale, setChartScale ] = useState(scaleOptions[1]);
  const [ termRankings, setTermRankings ] = useState([]);
  const [ termRankingsPageIndex, setTermRankingsPageIndex ] = useState(1);
  const [ hasData, setHasData ] = useState(true);

  const [ processedTopProductsData, setProcessedTopProductsData ] = useState([]);
  const [ selectedProductsRegion, setSelectedProductsRegion ] = useState(regionOptions[0]);
  const [ productsPageIndex, setProductsPageIndex ] = useState(1);

  const [ processedTopCategoriesData, setProcessedTopCategoriesData ] = useState([]);
  const [ processedTopCategoriesChartData, setProcessedTopCategoriesChartData ] = useState([]);
  const [ categoriesPageIndex, setCategoriesPageIndex ] = useState(1);

  const [ searchValue, setSearchValue ] = useState("");
  const [ suggestions, setSuggestions ] = useState([]);

  const [ suggestModalVisible, setSuggestModalVisible ] = useState(false);
  const [ wikipediaTopicSearchOptions, setWikipediaTopicSearchOptions ] = useState([]);
  const [ wikipediaTopicSelected, setWikipediaTopicSelected ] = useState(null);
  const [ wikipediaTopicSearchValue, setWikipediaTopicSearchValue ] = useState("");
  const [ topicTermOptions, setTopicTermOptions ] = useState([]);
  const [ selectedTopicTermOptions, setSelectedTopicTermOptions ] = useState([]);

  const [ saveTermPoolModalVisible, setSaveTermPoolModalVisible ] = useState(false);
  const [ saveTermPoolName, setSaveTermPoolName ] = useState("");
  const [ saveTermPoolNameValid, setSaveTermPoolNameValid ] = useState(true);

  const [ loadTermPoolModalVisible, setLoadTermPoolModalVisible ] = useState(false);
  const [ loadTermPoolOptions, setLoadTermPoolOptions ] = useState([]);
  const [ loadTermPoolSelectedOption, setLoadTermPoolSelectedOption ] = useState(null);
  const [ loadTermPoolLoading, setLoadTermPoolLoading ] = useState(false);
  const [ loadTermPoolTableItems, setLoadTermPoolTableItems ] = useState([]);
  const [ loadTermPoolSelectedTableItems, setLoadTermPoolSelectedTableItems ] = useState([]);

  const [ currentReport, setCurrentReport ] = useState(null);
  const [ currentReportId, setCurrentReportId ] = useState(null);



  // Main search terms bar: submit button
  const handleSubmit = () => {
    const formattedStartDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    const formattedDateRange = `${formattedStartDate} ${formattedEndDate}`;

    const terms = selectedSuggestOptions.map(x => x.value);

    setSelectedProductsRegion(regions[0]);
    setTermRankingsPageIndex(1);
    setProductsPageIndex(1);
    setChartSeries([]);
    setYDomain([0, 10000000]);
    setTermRankings([]);
    setProcessedTopProductsData([]);
    setCurrentReport(null);
    setCurrentReportId(null);

    getAmazonVendorTimeseriesData({
      terms: terms.join(","),
      regions: regions.map(x => x.value).join(","),
      timeframe: formattedDateRange,
    });
    getAmazonVendorTopProductsData({
      terms: terms.join(","),
      regions: regions.map(x => x.value).join(","),
      timeframe: formattedDateRange,
    });
    getAmazonVendorTopCategoriesData({
      terms: terms.join(","),
      regions: regions.map(x => x.value).join(","),
      timeframe: formattedDateRange,
    });
  };

  // Main search terms bar: input change
  const handleInputChange = (value) => {
    if (searchValue != value) {
      setSearchValue(value);
    }
  }

  // Topic terms search: add selected terms to master list
  const handleAddSelectedTerms = (selectedTermOptions) => {
    const newSelectedOptions = [...selectedSuggestOptions];
    selectedTermOptions.forEach(x => {
      if (!newSelectedOptions.map(y => y.value).includes(x.value))
      newSelectedOptions.push(x);
    });
    setSelectedSuggestOptions(newSelectedOptions);
    handleCloseTopicModal();
  };

  // Topic terms search: get Wikipedia article autosuggestions
  const handleWikipediaTopicSearch = (value) => {
    setWikipediaTopicSearchValue(value);
    if (value.length > 0) {
      getWikipediaTopics({
        q: value,
        limit: 10,
      });
      setWikipediaTopicSelected(wikipediaTopicSearchOptions.map(x => x.value).includes(value) ? value : null);
    }
  };

  // Topic terms search: get suggested terms from Wikipedia article
  const handleGetTopicTerms = (value) => {
    getSuggestedTopicTerms({
      wikipedia_article: value,
      i: 8,
    });
  };

  const handleShowTopicModal = () => {
    wikipediaTopicSearchBarRef.current.focus();
    setSuggestModalVisible(true);
  };

  const handleCloseTopicModal = () => {
    setSuggestModalVisible(false);
    setWikipediaTopicSearchValue("");
    setWikipediaTopicSearchOptions([]);
    setWikipediaTopicSelected(null);
    setTopicTermOptions([]);
    setSelectedTopicTermOptions([]);
  };

  const handleSetSaveTermPoolName = (termPoolName) => {
    const regexVerified = /^[a-zA-Z0-9_\- ]*$/.test(termPoolName);
    setSaveTermPoolNameValid(regexVerified);
    setSaveTermPoolName(termPoolName);
  };

  const handleSetLoadTermPoolSelectedOption = (option) => {
    setLoadTermPoolSelectedOption(option);
    loadTermPool(option.value);
  };

  const handleAddSelectedTermPoolTerms = () => {
    const newSelectedOptions = [...selectedSuggestOptions];
    loadTermPoolSelectedTableItems.forEach(x => {
      if (!newSelectedOptions.map(y => y.value).includes(x.value)) {
        newSelectedOptions.push(x);
      }
    });
    setSelectedSuggestOptions(newSelectedOptions);
    setLoadTermPoolModalVisible(false);
  };

  const handleReportDownload = () => {
    flashbarRef?.current?.setFlashbarMessage("loading", <div>Fetching report.</div>, null, 5000);

    axiosInstance.request({
      url: `/amazonvendor/report`,
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        id: currentReportId,
      },
    }).then(response => {
      const downloadUrl = response.data;
      axiosInstance.request({
        url: downloadUrl,
        method: "GET",
        responseType: "blob",
        headers: {
          "Authorization": null
        },
      }).then(dlResponse => {
        const href = URL.createObjectURL(dlResponse.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${currentReportId}.zip`);
        link.click();

        URL.revokeObjectURL(href);

        flashbarRef?.current?.setFlashbarMessage("success", <div>Successfully fetched report.</div>, null, 5000);
      });
    }).catch(e => {
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to fetch report.", "Failed to fetch report");
    });
  };



  const createReport = () => {
    const formattedStartDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const formattedEndDate = moment(dateRange.endDate).format("YYYY-MM-DD");

    const reportMetadata = {
      author: user.username,
      parameters: {
        terms: selectedSuggestOptions.map(x => x.value),
        regions: regions.map(x => x.value),
        start_date: formattedStartDate,
        end_date: formattedEndDate,
      },
    };

    const reportTimeseries = chartSeries.map(x => {
      return {
        term: x.title,
        data: x.data.map(y => (
          {
            date: y.x.toISOString().split("T")[0],
            rank: y.y,
            interpolated: y.interpolated || false,
          }
        )),
      }
    });

    const reportTermRankings = [...termRankings].map(x => (
      {
        rank: x.rank,
        term: x.term,
        peak_rank: x.peak_rank,
        peak_rank_date: x.peak_rank_date.toISOString().split("T")[0],
      }
    ));

    const reportTopProducts = {...processedTopProductsData};

    const reportTopCategories = [...processedTopCategoriesData].map(x => (
      {
        rank: x.rank,
        category: x.title,
        total_hits: x.value
      }
    ));

    const report = {
      metadata: reportMetadata,
      timeseries: reportTimeseries,
      term_rankings: reportTermRankings,
      top_products: reportTopProducts,
      top_categories: reportTopCategories,
    };

    return report;
  };

  const interpolateValues = (data) => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].value === null) {
        let before = null;
        let after = null;
  
        for (let j = i - 1; j >= 0; j--) {
          if (data[j].value !== null) {
            before = data[j];
            break;
          }
        }
  
        for (let k = i + 1; k < data.length; k++) {
          if (data[k].value !== null) {
            after = data[k];
            break;
          }
        }
  
        if (before !== null && after !== null) {
          const timeDifferenceBefore = data[i].date - before.date;
          const timeDifferenceAfter = after.date - data[i].date;
  
          const totalDifference = timeDifferenceBefore + timeDifferenceAfter;
  
          const weightBefore = timeDifferenceAfter / totalDifference;
          const weightAfter = timeDifferenceBefore / totalDifference;
  
          data[i].value = (before.value * weightBefore) + (after.value * weightAfter);
        } else if (before !== null) {
          data[i].value = before.value;
        } else if (after !== null) {
          data[i].value = after.value;
        }
        data[i].value = Math.round(data[i].value);
        data[i].interpolated = true;
      } else {
        data[i].interpolated = false;
      }
    }
  
    return data;
  };

  const createLineChartSeries = (timeseriesResponseData) => {
    const timeseriesData = timeseriesResponseData.data;
    const uniqueTerms = timeseriesResponseData.terms;
    const uniqueRegions = timeseriesResponseData.regions;

    if (timeseriesData.length == 0) {
      setHasData(false);
      return;
    }

    const valueFormatter = (value) => `#${value?.toLocaleString()}`;
    //const firstDate = new Date(Date.parse(timeseriesData[0].reporting_date));
    const lastDate = new Date(Date.parse(timeseriesData[timeseriesData.length - 1].reporting_date));
    const firstDate = new Date(Date.parse(dateRange.startDate));
    //const lastDate = new Date(Date.parse(dateRange.endDate));
    const dateList = [];
    lastDate.setDate(lastDate.getDate() + 1);

    for (let date = firstDate; date <= lastDate; date.setDate(date.getDate() + 1)) {
      dateList.push(date.toISOString().split('T')[0]);
    }
    
    let seriesData = {};
    uniqueTerms.forEach(term => {
      uniqueRegions.forEach(region => {
        seriesData[`${term}_${region}`] = [];
      });
    });
    dateList.forEach(date => {
      const dataPoint = timeseriesData.find(x => x.reporting_date === date);
      uniqueTerms.forEach(term => {
        uniqueRegions.forEach(region => {
          const key = `${term}_${region}`;
          if (dataPoint && dataPoint.terms) {
            const termValue = dataPoint.terms.find(x => x.term === term);
            if (termValue && termValue.hasOwnProperty(region)) {
              const value = termValue ? termValue[region] : null;
              seriesData[key].push({
                date: date,
                value: value,
              });
            } else {
              seriesData[key].push({
                date: date,
                value: null,
              });
            }
          } else {
            seriesData[key].push({
              date: date,
              value: null,
            });
          }
        });
      });
    });

    let interpolatedSeriesData = Object.keys(seriesData).map(key => {
      const dated = seriesData[key].map(x => ({ ...x, date: new Date(Date.parse(x.date)) })); 
      const interpolated = interpolateValues(dated).map(x => ({ x: x.date, y: x.value, interpolated: x.interpolated }));
      if (interpolated.filter(x => x.interpolated == true).length == interpolated.length) return null;
      const titleParts = key.split("_");
      const title = `${titleParts.slice(0, titleParts.length - 1).join("_")} (${titleParts[titleParts.length - 1]})`;
      return {
        title: title,
        type: "line",
        data: interpolated,
        valueFormatter: valueFormatter,
      };
    }).filter(x => !!x);
    let allTimeSeriesData = interpolatedSeriesData.map(x => x.data);
    let dailyWeightedData = {};

    allTimeSeriesData.forEach((tsData, i) => {
      tsData.forEach(entry => {
        const date = entry.x;
        const weight = 1.0 / entry.y;
        dailyWeightedData[date] = dailyWeightedData[date] || { weightedSum: 0, sumOfWeights: 0 };
        dailyWeightedData[date].weightedSum += entry.y * weight;
        dailyWeightedData[date].sumOfWeights += weight;
      });
    });

    let dailyWeightedAverages = {};
    Object.keys(dailyWeightedData).forEach(date => {
      const { weightedSum, sumOfWeights } = dailyWeightedData[date];
      dailyWeightedAverages[date] = weightedSum / sumOfWeights;
    });
    const weightedAverageSeries = {
      title: "Weighted Average Rank",
      type: "line",
      data: Object.keys(dailyWeightedAverages).map(key => (
        {
          x: new Date(Date.parse(key)),
          y: Math.round(dailyWeightedAverages[key] * 100) / 100,
        }
      )),
      valueFormatter: valueFormatter,
    };


    const newTermRankings = interpolatedSeriesData.map(x => {
      const peakRank = Math.min(...x.data.map(z => z.y));
      const peakPoint = x.data.find(y => y.y === peakRank);
      if (!peakPoint) return {};
      const nonInterpolatedData = x.data.filter(y => !y.interpolated);
      // Rank based on the series sum weighted by the number of non-interpolated data points
      const avg = x.data.reduce((a, b) => a + b.y, 0) * (1.0 / nonInterpolatedData.length);
      interpolatedSeriesData.find(y => y.title === x.title).rank_avg = avg;
      return {
        term: x.title,
        rank_avg: avg,
        peak_rank: peakPoint.y,
        peak_rank_date: peakPoint.x,
      };
    }).filter(x => x.rank_avg > 0)
      .sort((a, b) => a.rank_avg - b.rank_avg)
      .map((x, i) => ({ ...x, rank: i + 1 }));

    interpolatedSeriesData.sort((a, b) => a.rank_avg - b.rank_avg);
    interpolatedSeriesData.unshift(weightedAverageSeries);
    

    setChartSeries(interpolatedSeriesData);
    setChartVisibleSeries([weightedAverageSeries]);
    recalculateYDomain([weightedAverageSeries]);
    setTermRankings(newTermRankings);
    setHasData(true);
  };

  const recalculateYDomain = (visibleSeries) => {
    let newYDomain = [0, 10000000];
    visibleSeries.forEach(x => {
      const min = Math.min(...x.data.map(y => y.y));
      const max = Math.max(...x.data.map(y => y.y));
      if (min < newYDomain[1])
        newYDomain[1] = min;
      if (max > newYDomain[0])
        newYDomain[0] = max;
    });

    setYDomain(newYDomain);
  };

  const saveTermPool = () => {
    if (!saveTermPoolNameValid) return;
    flashbarRef?.current?.setFlashbarMessage("loading", <div>Creating new term list <b>{saveTermPoolName}</b>.</div>, null, 5000);
    axiosInstance.request({
      url: `/preferences/personal/${user.username}/amazonvendor/term_pools/${saveTermPoolName}.json`,
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
      },
      data: JSON.stringify({
        name: saveTermPoolName,
        terms: selectedSuggestOptions.map(x => x.value),
      }),
    }).then(response => {
      flashbarRef?.current?.setFlashbarMessage("success", <div>Successfully saved term list <b>{saveTermPoolName}</b>.</div>, null, 5000);
    }).catch(e => {
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to save term list.", "Failed to save term list");
    });
    setSaveTermPoolModalVisible(false);
  };

  const loadTermPool = (termPoolName) => {
    setLoadTermPoolLoading(true);
    setLoadTermPoolTableItems([]);
    setLoadTermPoolSelectedTableItems([]);
    axiosInstance.request({
      url: `/preferences/personal/${user.username}/amazonvendor/term_pools/${termPoolName}.json`,
      method: "GET",
    }).then(response => {
      setLoadTermPoolLoading(false);
      const tableItems = response.data.terms.map(term => ({ value: term }));
      setLoadTermPoolTableItems(tableItems);
      setLoadTermPoolSelectedTableItems(tableItems);
    }).catch(e => {
      flashbarRef?.current?.setFlashbarMessage("error", e.response?.data?.message || "Failed to load term list.", "Failed to load term list");
    });
  };



  useEffect(() => {
    if (timeseriesData.completed && !timeseriesData.loading && timeseriesData.data) {
      createLineChartSeries(timeseriesData.data);
    }
  }, [timeseriesData]);
  
  useEffect(() => {
    if (topProductsData.completed && !topProductsData.loading && topProductsData.data) {
      let newTopProductsData = {};
      Object.keys(topProductsData.data).forEach(key => {
        if (topProductsData.data[key] && topProductsData.data[key].length > 0) {
          try {
            newTopProductsData[key] = topProductsData.data[key].filter(x => x.product_asin).map((x, i) => {
              return {
                ...x,
                rank: i + 1,
              };
            })
          } catch {
            return;
          }
        }
      });
      setProcessedTopProductsData(newTopProductsData);
    }
  }, [topProductsData]);

  useEffect(() => {
    if (topCategoriesData.completed && !topCategoriesData.loading && topCategoriesData.data) {
      const sumOccurrences = topCategoriesData.data.reduce((a, b) => a + b.total_occurrences, 0);
      let otherCategories = topCategoriesData.data.filter(x => Math.round((x.total_occurrences / sumOccurrences) * 100) <= 5);
      let newTopCategoriesData = topCategoriesData.data.filter(x => Math.round((x.total_occurrences / sumOccurrences) * 100) > 5).map(x => {
        return {
          title: x.value.replaceAll("_", " "),
          value: x.total_occurrences,
          percentage: Math.round((x.total_occurrences / sumOccurrences) * 100),
        };
      });
      const sumOccurrencesOther = otherCategories.reduce((a, b) => a + b.total_occurrences, 0);
      const other = {
        title: "Other",
        value: sumOccurrencesOther,
        percentage: Math.round((sumOccurrencesOther / sumOccurrences) * 100),
      };
      newTopCategoriesData.push(other);
      setProcessedTopCategoriesChartData(newTopCategoriesData);

      newTopCategoriesData = [...topCategoriesData.data].sort((a,b) => b.total_occurrences - a.total_occurrences).map((x,i) => {
        return {
          title: x.value.replaceAll("_", " "),
          value: x.total_occurrences,
          percentage: Math.round((x.total_occurrences / sumOccurrences) * 100),
          rank: i + 1,
        };
      });
      setProcessedTopCategoriesData(newTopCategoriesData);
    }
  }, [topCategoriesData]);

  // Main search terms bar: get term autosuggestions
  useEffect(() => {
    if (searchValue.length > 1) {
      getAutoComplete({
        q: searchValue,
        k: 16,
      });
    }
  }, [searchValue]);

  // Main search terms bar: set term autosuggestions
  useEffect(() => {
    if (autoCompleteOptions?.completed && !autoCompleteOptions?.loading && autoCompleteOptions?.data) {
      const newAutoSuggestOptions = autoCompleteOptions.data.suggestions.map(x => (
        { 
          value: x, 
          label: x, 
        }
      ));
      setSuggestions(newAutoSuggestOptions);
    }
  }, [autoCompleteOptions]);

  // Topic terms search: set Wikipedia article autosuggestions
  useEffect(() => {
    if (wikipediaTopicSearch?.completed && !wikipediaTopicSearch?.loading && wikipediaTopicSearch?.data) {
      const newAutoSuggestOptions = wikipediaTopicSearch.data.pages.map(x => (
        {
          value: x.key,
          label: x.title,
          description: x.description,
        }
      ));
      setWikipediaTopicSearchOptions(newAutoSuggestOptions);
    }
  }, [wikipediaTopicSearch]);

  // Topic terms search: add suggested terms to table
  useEffect(() => {
    if (suggestedTopicTerms?.completed && !suggestedTopicTerms?.loading && suggestedTopicTerms?.data?.suggestions?.length > 0) {
      const newTopicTermOptions = suggestedTopicTerms.data.suggestions.map(x => {
        return {
          value: x,
          label: x,
          name: x,
        };
      });
      setTopicTermOptions(newTopicTermOptions);
      setSelectedTopicTermOptions(newTopicTermOptions);
    }
  }, [suggestedTopicTerms]);

  useEffect(() => {
    if (termPoolList?.completed && !termPoolList?.loading && termPoolList?.data) {
      const newLoadTermPoolOptions = termPoolList.data.map(x => ({
        label: x.filename.slice(0, -5),
        value: x.filename.slice(0, -5),
        description: "Created " + new Date(Date.parse(x.created_date + " UTC")).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour12: true,
          hour: "numeric",
          minute: "numeric",
        }),
        timestamp: Date.parse(x.created_date + " UTC"),
      })).sort((a, b) => b.timestamp - a.timestamp);
      setLoadTermPoolOptions(newLoadTermPoolOptions);
    }
  }, [termPoolList]);

  useEffect(() => {
    if (selectedSuggestOptions.length > 0 && chartSeries.length > 0 && termRankings.length > 0 &&
      Object.keys(processedTopProductsData).length > 0 && processedTopCategoriesData.length > 0) {
      const report = createReport();
      setCurrentReport(report);

      axiosInstance.request({
        url: `/amazonvendor/report`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(report),
      }).then(response => {
        const reportId = response.data;
        setCurrentReportId(reportId);
      }).catch(e => {
        console.log(e.response?.data?.message);
      });
    }
  }, [chartSeries, termRankings, processedTopProductsData, processedTopCategoriesData]);

  useEffect(() => {
    setCurrentReport(null);
    setCurrentReportId(null);
  }, [selectedSuggestOptions, regions, dateRange]);




  

  return (<>
    <Helmet>
      <title>Amazon Search Analysis</title>
    </Helmet>
    <AppLayout
      disableContentPaddings={false}
      stickyNotifications
      toolsHide
      headerSelector="#header"
      ariaLabels={{ navigationClose: "close" }}
      content={
        <ContentLayout
          header={<PantheonFlashbar ref={flashbarRef} />}
        >
          <SpaceBetween size="l">
            <Container
              header={
                <Header
                  variant="h3"
                  //description={<div className="top-0">Enter search terms and filters to analyze Amazon searches</div>}
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Multiselect
                        selectedOptions={regions}
                        onChange={({ detail }) =>
                          setRegions(detail.selectedOptions)  
                        }
                        selectedAriaLabel="Selected"
                        options={regionOptions}
                        placeholder={regions.length > 0 ? <div className="not-italic text-white">{regions.map(x => x.label).join(", ")}</div> : "Choose regions"}
                        hideTokens={true}
                      />
                      <RangeDateSelector
                        defaults={dateRange}
                        onChange={(e) => setDateRange(e)}
                      />
                      <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={timeseriesData.loading || selectedSuggestOptions.length == 0 || regions.length == 0}
                      >
                        Submit
                      </Button>
                    </SpaceBetween>
                  }
                >
                  Search Analysis
                </Header>
              }
            >
              <div className="flex flex-col space-y-4">
                <div>
                  <div className="flex space-x-2">
                    <div className="grow">
                      <Multiselect
                        options={suggestions}
                        selectedOptions={selectedSuggestOptions}
                        onChange={({ detail }) => setSelectedSuggestOptions(detail.selectedOptions)}
                        filteringType="manual"
                        onLoadItems={({ detail }) => handleInputChange(detail.filteringText)}
                        hideTokens
                        statusType={autoCompleteOptions.loading ? "loading" : "finished"}
                        loadingText="Loading terms"
                        placeholder="Select terms"
                      />
                    </div>
                  </div>
                  {selectedSuggestOptions.length > 0 && (
                    <TokenGroup
                      onDismiss={({ detail: { itemIndex } }) => {
                        setSelectedSuggestOptions([
                          ...selectedSuggestOptions.slice(0, itemIndex),
                          ...selectedSuggestOptions.slice(itemIndex + 1)
                        ]);
                      }}
                      items={selectedSuggestOptions}
                      limit={10}
                      i18nStrings={{
                        limitShowMore: "Show more",
                        limitShowFewer: "Show less"
                      }}
                    />
                  )}
                </div>
                <div className="flex justify-between content-start">
                  <Button
                    variant="inline-link"
                    onClick={handleShowTopicModal}
                  >Find terms by topic</Button>
                  <SpaceBetween direction="horizontal" size="m">
                    {currentReportId && (
                      <Button
                        href="#"
                        iconName="download"
                        variant="inline-link"
                        onClick={handleReportDownload}
                      >
                        Download report
                      </Button>
                    )}
                    <ButtonDropdown
                      items={[
                        { text: "Save term list", id: "save", disabled: selectedSuggestOptions.length == 0 },
                        { text: "Load term list", id: "load", disabled: false },
                        { text: "Clear terms", id: "clear", disabled: selectedSuggestOptions.length == 0 },
                      ]}
                      variant="inline-icon"
                      onItemClick={({ detail }) => {
                        switch(detail.id) {
                          case "save":
                            setSaveTermPoolName("");
                            setSaveTermPoolModalVisible(true);
                            break;
                          case "load":
                            getTermPools();
                            setLoadTermPoolSelectedOption(null);
                            setLoadTermPoolSelectedTableItems([]);
                            setLoadTermPoolTableItems([]);
                            setLoadTermPoolLoading(false);
                            setLoadTermPoolModalVisible(true);
                            break;
                          case "clear":
                            setSelectedSuggestOptions([]);
                            break;
                        }
                      }}
                    />
                  </SpaceBetween>
                </div>
              </div>
            </Container>
            {(timeseriesData.loading || timeseriesData.completed) && (
              <Container
                header={
                  <Header
                    variant="h2"
                    description="Frequency of searches for each term over time"
                  >
                    Search Frequency Rank
                  </Header>
                }
              >
                {timeseriesData.loading ? (
                  <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                    <Spinner size="large" />
                  </div>
                ) : timeseriesData.completed && timeseriesData.data?.data?.length > 0 ? (
                  <SpaceBetween direction="vertical" size="l">
                    <div className="h-fit">
                      <LineChart 
                        series={chartSeries}
                        xDomain={[
                          new Date(Date.parse(timeseriesData.data.data[0].reporting_date)), 
                          new Date(Date.parse(timeseriesData.data.data[timeseriesData.data.data.length - 1].reporting_date))
                        ]}
                        yDomain={yDomain}
                        xScaleType="time"
                        yScaleType={chartScale.value}
                        xTitle="Reporting Date"
                        yTitle="Rank"
                        height={300}
                        visibleSeries={chartVisibleSeries}
                        onFilterChange={({ detail }) => {
                          setChartVisibleSeries(detail.visibleSeries);
                          recalculateYDomain(detail.visibleSeries);
                        }}
                        i18nStrings={{
                          xTickFormatter: (value) => value.toLocaleDateString("en-US", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }),
                          yTickFormatter: (value) => {
                            return `#${Math.abs(value) >= 1e9
                              ? (value / 1e9).toFixed(1).replace(/\.0$/, "") +
                                  "G"
                              : Math.abs(value) >= 1e6
                              ? (value / 1e6).toFixed(1).replace(/\.0$/, "") +
                                "M"
                              : Math.abs(value) >= 1e3
                              ? (value / 1e3).toFixed(1).replace(/\.0$/, "") +
                                "K"
                              : value.toFixed(0)}`;
                          },
                          filterPlaceholder: "Filter data"
                        }}
                        additionalFilters={
                          <div className="mt-1">
                            <Select 
                              selectedOption={chartScale}
                              onChange={({ detail }) => setChartScale(detail.selectedOption)}
                              options={scaleOptions}
                            />
                          </div>
                        }
                      />
                    </div>
                    <Table
                      variant="embedded"
                      sortingDisabled
                      columnDefinitions={[
                        {
                          id: "rank",
                          header: "Rank",
                          cell: item => <b>{item.rank}</b>,
                          sortingField: "rank",
                          isRowHeader: true,
                          maxWidth: 40,
                        },
                        {
                          id: "term",
                          header: "Search term",
                          cell: item => item.term,
                        },
                        {
                          id: "peak_rank",
                          header: "Peak rank",
                          cell: item => `#${item.peak_rank.toLocaleString()}`,
                        },
                        {
                          id: "peak_rank_date",
                          header: "Peak rank date",
                          cell: item => item.peak_rank_date.toLocaleDateString("en-US", { year: 'numeric', month: 'short', day: 'numeric' }),
                        },
                        {
                          id: "occurrences",
                          header: "Occurrences",
                          cell: item => chartSeries.find(x => x.title === item.term).data.filter(x => x.interpolated === false).length,
                        },
                      ]}
                      columnDisplay={[
                        { id: "rank", visible: true },
                        { id: "term", visible: true },
                        { id: "peak_rank", visible: true },
                        { id: "peak_rank_date", visible: true },
                        { id: "occurrences", visible: true },
                      ]}
                      loadingText="Loading resources"
                      items={termRankings.slice((termRankingsPageIndex - 1) * termRankingsPerPage, termRankingsPageIndex * termRankingsPerPage)}
                      pagination={
                        <Pagination
                          currentPageIndex={termRankingsPageIndex}
                          onChange={({ detail }) =>
                            setTermRankingsPageIndex(detail.currentPageIndex)
                          }
                          pagesCount={Math.ceil(termRankings.length / termRankingsPerPage)}
                        />
                      }
                      header={
                        <Header
                          variant="h2"
                        >
                          Top Terms
                        </Header>
                      }
                    />
                  </SpaceBetween>
                ) : !hasData ? (
                  <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                    No data available for selected terms and date range.
                  </div>
                ) : (null)}
              </Container>
            )}
            {(topCategoriesData.loading || topCategoriesData.completed) && (
              <Container>
                {topCategoriesData.loading ? (
                  <>
                    <Header
                    >
                      Top Categories
                    </Header>
                    <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                      <Spinner size="large" />
                    </div>
                  </>
                ) : topCategoriesData.completed && processedTopCategoriesData.length > 0 ? (
                  <>
                    <Header
                    >
                      Top Categories
                    </Header>
                    <div className="flex flex-wrap space-x-4">
                      <PieChart
                        data={processedTopCategoriesChartData}
                        detailPopoverContent={(datum, sum) => [
                          { key: "Occurrences", value: datum.value.toLocaleString() },
                          { key: "Percentage", value: `${((datum.value / sum) * 100).toFixed(0)}%` },
                        ]}
                        segmentDescription={(datum, sum) => `${datum.value.toLocaleString()} occurrences, ${((datum.value / sum) * 100).toFixed(0)}%`}
                        i18nStrings={{
                          filterPlaceholder: "Filter data",
                        }}
                        hideFilter
                      />
                      <div className="grow">
                      <Table
                        variant="embedded"
                        sortingDisabled
                        columnDefinitions={[
                          {
                            id: "rank",
                            header: "Rank",
                            cell: item => <b>{item.rank}</b>,
                            sortingField: "rank",
                            isRowHeader: true,
                            minWidth: 40,
                            maxWidth: 40,
                          },
                          {
                            id: "category",
                            header: "Category",
                            cell: item => item.title,
                          },
                          {
                            id: "occurrences",
                            header: "Occurrences",
                            cell: item => item.value.toLocaleString(),
                            maxWidth: 80,
                          },
                        ]}
                        columnDisplay={[
                          { id: "rank", visible: true },
                          { id: "category", visible: true },
                          { id: "occurrences", visible: true },
                        ]}
                        loadingText="Loading resources"
                        pagination={
                          <Pagination
                            currentPageIndex={categoriesPageIndex}
                            onChange={({ detail }) =>
                              setCategoriesPageIndex(detail.currentPageIndex)
                            }
                            pagesCount={Math.ceil(processedTopCategoriesData.length / categoriesPerPage)}
                          />
                        }
                        items={processedTopCategoriesData.slice((categoriesPageIndex - 1) * categoriesPerPage, categoriesPageIndex * categoriesPerPage)}
                      />
                      </div>
                    </div>
                  </>
                ) : null}
              </Container>
            )}
            {(topProductsData.loading || topProductsData.completed) && (
              <Container>
                {topProductsData.loading ? (
                  <>
                    <Header
                      actions={
                        <SpaceBetween
                          direction="horizontal"
                          size="xs"
                        >
                          <Select
                            options={regions}
                            selectedOption={selectedProductsRegion}
                            onChange={({ detail }) => setSelectedProductsRegion(detail.selectedOption)}
                          />
                        </SpaceBetween>
                      }
                    >
                      Top Products in {selectedProductsRegion.label}
                    </Header>
                    <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                      <Spinner size="large" />
                    </div>
                  </>
                ) : topProductsData.completed && processedTopProductsData[selectedProductsRegion.value] ? (
                  <Table
                    variant="embedded"
                    sortingDisabled
                    columnDefinitions={[
                      {
                        id: "rank",
                        header: "Rank",
                        cell: item => <b>{item.rank}</b>,
                        sortingField: "rank",
                        isRowHeader: true,
                        maxWidth: 40,
                      },
                      {
                        id: "product_title",
                        header: "Product Name",
                        cell: item => <>
                          <Link 
                            external
                            href={selectedProductsRegion.value === "UK" ? `https://amazon.co.uk/dp/${item.product_asin}` : `https://amazon.com/dp/${item.product_asin}`} 
                            target="_blank"
                            title={item.product_title}
                          >
                            {item.product_title}
                          </Link>
                        </>,
                        maxWidth: 300,
                      },
                      {
                        id: "product_asin",
                        header: "ASIN",
                        cell: item => <>
                          <span className="custom-wrapping">
                            <Box margin={{ right: 'xxs' }} display="inline-block">
                              <Popover
                                size="small"
                                position="top"
                                triggerType="custom"
                                dismissButton={false}
                                content={<StatusIndicator type="success">ASIN copied</StatusIndicator>}
                              >
                                <Button
                                  variant="inline-icon"
                                  iconName="copy"
                                  ariaLabel="Copy ASIN"
                                  onClick={() => {
                                    navigator.clipboard.writeText(item.product_asin);
                                  }}
                                />
                              </Popover>
                            </Box>
                            {item.product_asin}
                          </span>
                        </>,
                      },
                      {
                        id: "total_hits",
                        header: "Occurrences",
                        cell: item => item.total_hits.toLocaleString(),
                      },
                    ]}
                    columnDisplay={[
                      { id: "rank", visible: true },
                      { id: "product_title", visible: true },
                      { id: "product_asin", visible: true },
                      { id: "total_hits", visible: true },
                    ]}
                    loadingText="Loading resources"
                    pagination={
                      <Pagination
                        currentPageIndex={productsPageIndex}
                        onChange={({ detail }) =>
                          setProductsPageIndex(detail.currentPageIndex)
                        }
                        pagesCount={Math.ceil(processedTopProductsData[selectedProductsRegion.value].length / productsPerPage)}
                      />
                    }
                    header={
                      <Header
                        counter={`(${processedTopProductsData[selectedProductsRegion.value].length})`}
                        actions={
                          <SpaceBetween
                            direction="horizontal"
                            size="xs"
                          >
                            {Object.keys(processedTopProductsData).length > 1 && (
                              <Select
                                options={regions}
                                selectedOption={selectedProductsRegion}
                                onChange={({ detail }) => setSelectedProductsRegion(detail.selectedOption)}
                              />
                            )}
                          </SpaceBetween>
                        }
                      >
                        Top Products in {selectedProductsRegion.label}
                      </Header>
                    }
                    items={processedTopProductsData[selectedProductsRegion.value].slice((productsPageIndex - 1) * productsPerPage, productsPageIndex * productsPerPage)}
                  />
                ) : (null)}
              </Container>
            )}
          </SpaceBetween>
        </ContentLayout>
      }
      navigation={
        <SideNavigation
          activeHref={window.location.pathname}
          items={navItems}
        />
      }
      breadcrumbs={
        <BreadcrumbGroup
          items={breadcrumbs}
          expandAriaLabel="Show path"
          ariaLabel="Breadcrumbs"
        />
      }
    />
    <Modal
      onDismiss={() => setSaveTermPoolModalVisible(false)}
      visible={saveTermPoolModalVisible}
      header={
        <Header
          variant="h3"
        >
          Save Term List
        </Header>
      }
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => setSaveTermPoolModalVisible(false)}
            >
              Cancel
            </Button>
            <Button 
              variant="primary" 
              disabled={selectedSuggestOptions.length == 0 || saveTermPoolName.length == 0 || !saveTermPoolNameValid}
              onClick={() => saveTermPool()}
            >
              Save
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="m">
        <Input 
          placeholder="Enter a name" 
          value={saveTermPoolName} 
          onChange={({ detail }) => handleSetSaveTermPoolName(detail.value)} 
          invalid={!saveTermPoolNameValid}
        />
        <TokenGroup
          onDismiss={({ detail: { itemIndex } }) => {
            setSelectedSuggestOptions([
              ...selectedSuggestOptions.slice(0, itemIndex),
              ...selectedSuggestOptions.slice(itemIndex + 1)
            ]);
          }}
          items={selectedSuggestOptions}
          limit={10}
          disableOuterPadding
          i18nStrings={{
            limitShowMore: "Show more",
            limitShowFewer: "Show less"
          }}
        />
      </SpaceBetween>
    </Modal>
    <Modal
      onDismiss={() => setLoadTermPoolModalVisible(false)}
      visible={loadTermPoolModalVisible}
      header={
        <Header
          variant="h3"
        >
          Load Term List
        </Header>
      }
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={() => setLoadTermPoolModalVisible(false)}
            >
              Cancel
            </Button>
            <Button 
              variant="primary"
              onClick={() => handleAddSelectedTermPoolTerms()}
              disabled={loadTermPoolSelectedTableItems.length == 0}
            >
              {loadTermPoolSelectedTableItems.length > 0 ? `Add selected (${loadTermPoolSelectedTableItems.length})` : "Add selected"}
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween direction="vertical" size="m">
        <Select
          options={loadTermPoolOptions}
          selectedOption={loadTermPoolSelectedOption}
          onChange={({ detail }) => handleSetLoadTermPoolSelectedOption(detail.selectedOption)}
          placeholder="Choose an option"
          loadingText="Loading term lists"
          statusType={termPoolList.loading ? "loading" : "finished"}
        />
        <div className="max-h-96 overflow-y-auto">
          <Table
            onSelectionChange={({ detail }) => setLoadTermPoolSelectedTableItems(detail.selectedItems)}
            selectedItems={loadTermPoolSelectedTableItems}
            columnDefinitions={[
              {
                id: "term",
                header: "Search term",
                cell: item => item.value,
                isRowHeader: true,
              }
            ]}
            columnDisplay={[
              { id: "term", visible: true },
            ]}
            items={loadTermPoolTableItems}
            loadingText="Loading terms"
            selectionType="multi"
            empty={<div className="text-slate-500">Choose a term list</div>}
            loading={loadTermPoolLoading}
            sortingDisabled
            variant="embedded"
            stickyHeader
          />
        </div>
      </SpaceBetween>
    </Modal>
    <Modal
      onDismiss={handleCloseTopicModal}
      visible={suggestModalVisible}
      header={
        <Header
          variant="h3"
          description="Search for a topic to find related Amazon search terms"
        >
          Suggest Terms
        </Header>
      }
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="link"
              onClick={handleCloseTopicModal}
            >
              Cancel
            </Button>
            <Button 
              variant="primary" 
              disabled={selectedTopicTermOptions.length == 0}
              onClick={() => handleAddSelectedTerms(selectedTopicTermOptions)}
            >
              Add
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div className="flex flex-col space-y-4">
        <div className="flex space-x-2">
          <div className="grow">
            <Autosuggest
              onChange={({ detail }) => handleWikipediaTopicSearch(detail.value)}
              onSelect={({ detail }) => setWikipediaTopicSelected(detail.value)}
              value={wikipediaTopicSearchValue}
              options={wikipediaTopicSearchOptions}
              placeholder="Search"
              filteringType="none"
              statusType={wikipediaTopicSearch.loading ? "loading" : "finished"}
              ref={wikipediaTopicSearchBarRef}
            />
          </div>
          <Button
            variant="primary"
            onClick={() => handleGetTopicTerms(wikipediaTopicSearchValue) }
            disabled={!wikipediaTopicSelected}
          >
            Search
          </Button>
        </div>
        <div className="max-h-96 overflow-y-auto">
          <Table
            selectedItems={selectedTopicTermOptions}
            onSelectionChange={({ detail }) => setSelectedTopicTermOptions(detail.selectedItems)}
            columnDefinitions={[
              {
                id: "value",
                header: "Search term",
                cell: item => item.name,
                sortingField: "name"
              },
            ]}
            columnDisplay={[
              { id: "value", visible: true },
            ]}
            items={topicTermOptions}
            loading={suggestedTopicTerms.loading}
            loadingText="Loading terms"
            selectionType="multi"
            variant="embedded"
            stickyHeader
            sortingDisabled
            empty={<div className="text-slate-500">Start typing to search for a topic</div>}
          />
        </div>
      </div>
    </Modal>
  </>);
};