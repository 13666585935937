import React, { useMemo } from "react";

export const useTwitchRankingTableConfig = ({
  
}) => {
  const colDefs = useMemo(() => {
    return [
      {
        headerName: "Today's Peak Viewers (Twitch)",
        field: "peak_viewers",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      {
        headerName: "All-time Peak Viewers (Twitch)",
        field: "at_pv",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      {
        headerName: "Total Followers (Twitch)",
        field: "max_followers",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      {
        headerName: "Viewers Trend (Twitch)",
        field: "monthly_avg_viewers",
        cellRenderer: "agSparklineCellRenderer",
        wrapHeaderText: true,
        autoHeaderHeight: true,
        flex: 1,
      },
    ];
  }, []);

  return {
    columnDefs: colDefs,
  };
};