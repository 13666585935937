import { Input } from "@cloudscape-design/components";
import { useState, useRef, useEffect } from "react";
import { IoMdClose, IoMdCheckmark } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";

export const EditableTextAttribute = ({
  value,
  onChange,
}) => {

  const inputRef = useRef(null);

  const [ isEditing, setIsEditing ] = useState(false);
  const [ inputValue, setInputValue ] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    isEditing ? (
      <div className="flex space-x-2 items-center">
        <Input
          ref={inputRef}
          value={inputValue}
          onChange={({ detail }) => setInputValue(detail.value)}
        />
        <span className="cursor-pointer dark:hover:text-white hover:text-slate-600">
          <IoMdCheckmark
            onClick={() => {
              onChange(inputValue);
              setIsEditing(false);
            }}
          />
        </span>
        <span className="cursor-pointer dark:hover:text-white hover:text-slate-600">
          <IoMdClose
            onClick={() => {
              setInputValue(value);
              setIsEditing(false);
            }}
          />
        </span>
      </div>
    ) : (
      <div className="flex items-center space-x-2">
        <span className="font-bold text-ellipsis overflow-hidden">{value}</span>
        <span className="cursor-pointer dark:hover:text-white hover:text-slate-600">
          <MdModeEdit
            onClick={() => {
              setIsEditing(true);
              setTimeout(() => inputRef.current.select(), 0);
            }}
          />
        </span>
      </div>
    )
  );
};