import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getRecentVideos } from "../request";

export const useGetRecentVideos = (params) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_RECENT_VIDEOS, params.channelId],
    () => getRecentVideos(params),
  );

  return {
    data: data?.data ?? [],
    isLoading,
    error,
  };
};
