import { useEffect, useRef } from "react";


export const CrosshairBoundingBox = ({ chartRef }) => {

  const animRequestRef = useRef(null);
  const stateRef = useRef(null);

  const limit = (low, actual, high) => {
    return Math.max(low, Math.min(high, actual));
  };

  const chartUpdate = () => {
    animRequestRef.current = requestAnimationFrame(chartUpdate);
    if (stateRef?.current?.tooltipElement && !stateRef?.current?.tooltipElement.classList.contains("ag-crosshair-label-hidden")) {
      const { width, height } = stateRef.current.tooltipElement.parentNode.getBoundingClientRect();
      const maxLeft = width - stateRef.current.tooltipElement.clientWidth - 1;
      const maxTop = height - stateRef.current.tooltipElement.clientHeight;
      
      const left = limit(0, stateRef.current.tooltipElement.offsetLeft, maxLeft) - stateRef.current.tooltipElement.offsetLeft;
      const top = limit(0, stateRef.current.tooltipElement.offsetTop, maxTop) - stateRef.current.tooltipElement.offsetTop;

      stateRef.current.tooltipElement.style.transform = `translate(${Math.round(left)}px, ${Math.round(top)}px)`;
    }

    // Unrelated: disable stacking of x axes when we have multiple date ranges
    chartRef.current.chart.axes.filter(axis => axis.direction === "x").forEach(axis => {
      axis.layoutConstraints.stacked = false;
    });
  };

  useEffect(() => {
    animRequestRef.current = requestAnimationFrame(chartUpdate);

    let intervalId = null;
    if (chartRef?.current) {
      intervalId = setInterval(() => {
        const tooltipElement = chartRef?.current.chart.container.querySelector(":scope .ag-crosshair-label:has(div)");
        if (tooltipElement) {
          clearInterval(intervalId);
          stateRef.current = { tooltipElement };
        }
      }, 1000);
    }

    return () => {
      cancelAnimationFrame(animRequestRef.current);
      clearInterval(intervalId);
    };
  }, [chartRef?.current]);

  return null;
};