import {
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  Link,
} from "@cloudscape-design/components";
import React from "react";
import IndexPageGrid from "../../../components/IndexPageGrid";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { BOChart } from "./components/BOChart";

export const BoxOfficeHomePage = () => {
  const items = [
    {
      link: "/box-office/ranking",
      image: "/images/BoxofficeRanking.png",
      name: "Box Office Ranking",
      path: "Box Office Analysis",
      description:
        "Box Office Rankings offers a definitive glance at the fiscal success of film releases throughout the year.",
    },
    {
      link: "/box-office/grosstimeseries",
      image: "/images/Timeseries.png",
      name: "Box Office Timeseries",
      path: "Box Office Analysis",
      description:
        "Dive into the timeseries dashboard, illustrating daily revenue trends across selected movies.",
    },
    {
      link: "/box-office/bo-by-studio",
      image: "/images/BoxofficeByStudio.png",
      name: "Box Office By Studio",
      path: "Box Office Analysis",
      description:
        "Box Office By Studio Dashboard provide insights into the performance of studios",
    },
  ];

  const breadcrumbs = [{ text: "Tools" }, { text: "Box Office Analysis" }];
  return (
    <Layout
      title="Box Office Analysis"
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={
        <ContentLayout
          header={
            <Header
              variant="h2"
              description="Comprehensive box office performance analysis and financial performance of films across various markets. This section offers detailed insights into daily, weekly, weekend and cumulative gross earnings."
            >
              Box Office Analysis
            </Header>
          }
        >
          <SpaceBetween direction="vertical" size="m">
            <Container>
              <BOChart />
            </Container>
            <div className="mx-auto">
              <div className="grid gap-4 sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-3 lg:grid-rows-1">
                {items.map((item) => (
                  <Container
                    fitHeight
                    disableContentPaddings
                    key={item.link}
                    media={{
                      content: (
                        <Link href={item.link}>
                          <img src={item.image} alt={item.name} className="object-cover" />
                        </Link>
                      ),
                      height: 150,
                      position: "top",
                    }}
                  >
                    <SpaceBetween direction="vertical" size="xs">
                      <div key={item.path} className="py-4 px-4">
                        <Link href={item.link}><b className="text-base">{item.name}</b></Link>
                        <p key={item.description}>{item.description}</p>
                      </div>
                    </SpaceBetween>
                  </Container>
                ))}
              </div>
            </div>
          </SpaceBetween>
        </ContentLayout>
      }
      contentType="dashboard"
    />
  );
};
