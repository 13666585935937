import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
import _ from "lodash";
const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchItemBoxOfficeDataApi = createAsyncThunk(
  "itemBoxOfficeData/fetchItemBoxOfficeDataApi",
  async ({ id }) => {
    const response = await axiosInstance.request({
      url: `boxoffice/${id}/all`,
      method: "GET",
    });
    return response;
  }
);

const itemBoxOfficeDataSlice = createSlice({
  name: "itemBoxOfficeData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchItemBoxOfficeDataApi.pending, (state) => {
        _.set(state, "loading", true);
        _.set(state, "data", null);
      })
      .addCase(fetchItemBoxOfficeDataApi.fulfilled, (state, action) => {
        _.set(state, "completed", true);
        _.set(state, "data", action?.payload?.data || null);
        _.set(state, "loading", false);
      })
      .addCase(fetchItemBoxOfficeDataApi.rejected, (state, action) => {
        _.set(state, "completed", true);
        _.set(state, "loading", false);
        _.set(state, "hasError", true);
        _.set(state, "message", action?.error?.message || "");
      });
  },
});

const itemBoxOfficeDataReducer = itemBoxOfficeDataSlice.reducer;

export default itemBoxOfficeDataReducer;

export const itemBoxOfficeDataActions = itemBoxOfficeDataSlice.actions;
