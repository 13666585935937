import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getGeneratedLabelsData } from "../request";

export const useGetGeneratedLabelsData = ({
  q,
  date,
}) => {
  const params = {
    q,
    date,
  };

  const { isLoading, isFetching, data, error } = useQuery(
    [QUERY_KEYS.GET_GENERATED_LABELS_DATA, { ...params }],
    () => getGeneratedLabelsData(params),
  );

  return {
    data: data?.data,
    isLoading: isLoading || isFetching,
    ...error,
  };
};
