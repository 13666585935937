import "ag-grid-enterprise";
import React from "react";
import { PlatformTrendsCharts } from "../../components/PlatformTrendsCharts";
import { getYoutubeTrendsData } from "../api/request";
import {
  defaultCategory as defCategory,
  youtubeApiParams,
  youtubeCategoryOptions,
} from "../constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "../utils/gridConfig";

export const YoutubeTrendsCharts = (
{
  defaultCategory=defCategory,
  setSelectedVertical,
}
) => {
  return (
    <PlatformTrendsCharts
      apiParams={youtubeApiParams}
      defaultColumn={defaultColumn}
      defaultCategory={defaultCategory}
      categoryOptions={youtubeCategoryOptions}
      corePlatformColumns={corePlatformColumns}
      fetchMethod={getYoutubeTrendsData}
      setSelectedVertical={setSelectedVertical}
      additionalPlatformColumns={additionalPlatformColumns}
    />
  );
};
