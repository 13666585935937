import { Multiselect } from "@cloudscape-design/components";
import _ from "lodash";
import React, { FC, useEffect, useState } from "react";
import { isSome } from "../utils/sugarUtils";

type CheckboxConfigItem = {
  label: string;
  value: string;
  visible: boolean;
};

export type CheckboxConfigType = Array<CheckboxConfigItem>;

type CustomMultiSelectProps = {
  checkboxConfig: CheckboxConfigType;
  placeholder: string;
  onBlur: () => void;
  onChange: (config: CheckboxConfigType) => void;
};

export const CustomMultiSelect: FC<CustomMultiSelectProps> = ({
  onBlur,
  onChange,
  checkboxConfig,
  placeholder,
}) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectConfig, setSelectConfig] = useState([]);

  useEffect(() => {
    const withAllConfig = [
      { label: "Select All", value: "select_all", visible: selectAll },
    ];

    setSelectConfig([...withAllConfig, ...checkboxConfig]);
  }, [checkboxConfig, selectAll]);

  const handleMultiselectChange = ({ detail: { selectedOptions } }) => {
    let updatedConfig = [];
    const isClearAll = selectedOptions.length === checkboxConfig.length;

    const isSelectAll = selectedOptions.find(
      (option) => option.value === "select_all",
    );

    if (isSome(isSelectAll) && !isClearAll) {
      updatedConfig = checkboxConfig.map((checkbox) => ({
        ...checkbox,
        visible: true,
      }));

      setSelectAll(true);
      onChange(updatedConfig);
      return;
    }

    if (
      isClearAll &&
      _.differenceWith(selectedOptions, checkboxConfig).length === 0
    ) {
      updatedConfig = checkboxConfig.map((checkbox) => ({
        ...checkbox,
        visible: false,
      }));

      setSelectAll(false);
      onChange(updatedConfig);
      return;
    }

    updatedConfig = checkboxConfig.map((checkbox) => ({
      ...checkbox,
      visible: selectedOptions.some(
        (option) => option.value === checkbox.value,
      ),
    }));

    setSelectAll(false);
    onChange(updatedConfig);
  };

  return (
    <Multiselect
      hideTokens
      onBlur={onBlur}
      onChange={handleMultiselectChange}
      placeholder={placeholder}
      selectedOptions={selectConfig.filter((c) => c.visible)}
      options={selectConfig}
    />
  );
};
