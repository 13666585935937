import { createSlice } from "@reduxjs/toolkit";

const createUserInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const createUserSlice = createSlice({
    name:"createUser",
    initialState:createUserInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        createUserComepleted: (state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = false;
        },
        clearUserCreateReducer:() => {
            return createUserInitialState;
        },
        createUserFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const createUserAction = createUserSlice.actions;
export default createUserSlice.reducer;


