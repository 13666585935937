import { IconProps } from "@cloudscape-design/components";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import {
  PlatformWeightageDrawer,
  defaultWeightageValues,
} from "../../components/PlatformWeightageDrawer";
import { RankingRawData } from "./components/RankingRawData";

export const RankingRawDataPageIndex = () => {
  const [weightageValues, setWeightageValues] = useState(
    defaultWeightageValues,
  );
  const title = "Data Table";

  const breadcrumbs = [
    { text: "Rankings" },
    { text: title },
  ];

  const weightageDrawer = {
    id: "weightageDrawer",
    content: (
      <PlatformWeightageDrawer
        weightageValues={weightageValues}
        setWeightageValues={setWeightageValues}
      />
    ),
    trigger: {
      iconName: "Weightage" as IconProps.Name,
      iconSvg: <img src="/svg/weight.svg" alt="Weight" />,
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
  };

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      drawers={[weightageDrawer]}
      contentType="table"
      headerSelector="#header"
      content={
        <RankingRawData
          weightageValues={weightageValues}
          setWeightageValues={setWeightageValues}
        />
      }
    />
  );
};
