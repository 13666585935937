import { isSomeOrElse } from "../../../../../utils/sugarUtils";

export const mapResponseToFormValue = ({ data }) => {
  return {
    wikipedia: {
      weightage: Number(isSomeOrElse(data.wiki?.weightage, 0)),
      isChecked: isSomeOrElse(data.wiki?.isChecked, false),
    },
    google: {
      weightage: Number(isSomeOrElse(data.gtrends?.weightage, 0)),
      isChecked: isSomeOrElse(data.gtrends?.isChecked, false),
    },
    ao3: {
      weightage: Number(isSomeOrElse(data.a03?.weightage, 0)),
      isChecked: isSomeOrElse(data.a03?.isChecked, false),
    },
    youtube: {
      weightage: Number(isSomeOrElse(data.youtube?.weightage, 0)),
      isChecked: isSomeOrElse(data.youtube?.isChecked, false),
    },
    fandom: {
      weightage: Number(isSomeOrElse(data.fandom?.weightage, 0)),
      isChecked: isSomeOrElse(data.fandom?.isChecked, false),
    },
    twitter: {
      weightage: Number(isSomeOrElse(data.twitter?.weightage, 0)),
      isChecked: isSomeOrElse(data.twitter?.isChecked, false),
    },
    reddit: {
      weightage: Number(isSomeOrElse(data.reddit?.weightage, 0)),
      isChecked: isSomeOrElse(data.reddit?.isChecked, false),
    },
    instagram: {
      weightage: Number(isSomeOrElse(data.instagram?.weightage, 0)),
      isChecked: isSomeOrElse(data.instagram?.isChecked, false),
    },
    steam: {
      weightage: Number(isSomeOrElse(data.steam?.weightage, 0)),
      isChecked: isSomeOrElse(data.steam?.isChecked, false),
    },
    twitch: {
      weightage: Number(isSomeOrElse(data.twitch?.weightage, 0)),
      isChecked: isSomeOrElse(data.twitch?.isChecked, false),
    },
    wattpad: {
      weightage: Number(isSomeOrElse(data.wattpad?.weightage, 0)),
      isChecked: isSomeOrElse(data.wattpad?.isChecked, false),
    },
    tiktok: {
      weightage: Number(isSomeOrElse(data.tiktok?.weightage, 0)),
      isChecked: isSomeOrElse(data.tiktok?.isChecked, false),
    },
    imdb: {
      weightage: Number(isSomeOrElse(data.imdb?.weightage, 0)),
      isChecked: isSomeOrElse(data.imdb?.isChecked, false),
    },
  };
};
