import { SpaceBetween } from "@cloudscape-design/components";
import React, { useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { GetPanelContent } from "./components/GetPanelContent";
import SplitPanelContainer from "./components/SplitPanelContainer";
import { TrendingPantheonRanking } from "./components/TrendingPantheonRanking";

const title = "Trending";
export const TrendingPantheonRankingIndex = () => {
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [selectedIps, setSelectedIps] = useState([]);
  const [comparedIp, setComparedIp] = useState([]);

  const breadcrumbs = [
    { text: "Rankings" },
    { text: title, href: "/ranking/trending" },
    { text: "Pantheon Ranking" },
  ];

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      contentType="dashboard"
      headerSelector="#header"
      navItems={navItems as NavItemsWithId}
      splitPanel={
        <SplitPanelContainer
          selectedItems={selectedIps}
          contents={
            <SpaceBetween size={"s"}>
              <GetPanelContent
                selectedItems={selectedIps}
                setSelectedIps={setSelectedIps}
                items={comparedIp}
                setItems={(items) => {
                  if (items?.length <= 0) {
                    setSplitPanelStatus(false);
                  }
                  setComparedIp(items);
                }}
              />
            </SpaceBetween>
          }
        />
      }
      content={
        <TrendingPantheonRanking
          selectedIps={selectedIps}
          setSelectedIps={setSelectedIps}
          setSplitPanelStatus={setSplitPanelStatus}
          comparedIp={comparedIp}
          setComparedIp={setComparedIp}
        />
      }
      selectedIps={selectedIps}
      setSelectedIps={setSelectedIps}
      comparedIp={comparedIp}
      setComparedIp={setComparedIp}
      splitPanelStatus={splitPanelStatus}
      setSplitPanelStatus={setSplitPanelStatus}
    />
  );
};
