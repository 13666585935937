
import { dispatch } from "../../../../../app/store";
import axiosInstance from "../../../../../utils/axios";
import { createUserAction } from "../slices/createUserSlice";
import { editUserAction } from "../slices/editUserSlice";
import { userListAction } from "../slices/userListSlice"

export async function getUsersList() {

    dispatch(userListAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `users`,
            method: `GET`,
        });
        dispatch(userListAction.insertUserList(response.data));
    } catch (e) {
        dispatch(userListAction.insertError(e.message));
    }

}


export async function createUserFn({
    fullname,
    username,
    role,
    org,
    title,
    email,
    tempPassword
}) {

    dispatch(createUserAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `/admin/user`,
            method: `POST`,
            data: {
                userName: username,
                userEmail:email,
                name:fullname,
                roleName:role.label,
                tempPassword,
                organization:org.label,
                title
            }
        });
        dispatch(createUserAction.createUserComepleted(response.data.message));
    } catch (e) {
        dispatch(createUserAction.createUserFailed(e.error));
    }

}

export  function clearUserCreateFn() {
    dispatch(createUserAction.clearUserCreateReducer());
}


export async function editUserFn({
    userName,
    fullname,
    role,
    org,
}) {

    dispatch(editUserAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `/admin/user/${userName}`,
            method: `PATCH`,
            data: {
                name:fullname,
                roleName:role.label,
                organization:org.label,
            }
        });
        dispatch(editUserAction.editUserComepleted(response.data.message));
    } catch (e) {
        dispatch(editUserAction.editUserFailed(e.error));
    }

}

export  function clearUserEditFn() {
    dispatch(editUserAction.clearUserEditReducer());
}
