import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getVideoTrends } from "../request";
import { mapToUpdateTrends } from "../mappers/mapToUpdatedTrends";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";

export const useGetVideoTrends = (videoId: string) => {
  const { isLoading, data, error } = useQuery(
    QUERY_KEYS.GET_VIDEO_TRENDS,
    () => getVideoTrends(videoId),
  );

  return {
    data: mapToUpdateTrends(isSomeOrElse(data?.data, [])),
    isLoading,
    error,
  };
};
