import { Button } from "@cloudscape-design/components";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { FaImdb, FaSteam, FaTwitch, FaWikipediaW, FaYoutube } from "react-icons/fa6";
import { GiPirateFlag } from "react-icons/gi";
import { SiRottentomatoes } from "react-icons/si";
import { useNavigate } from "react-router";
import { Tooltip as ReactTooltip } from "react-tooltip";
import CompareIcon from "../../assets/images/CompareIcon";
import { renderTrendSimple } from "../../utils/trendIcon";
import { VerticalIcon } from "../VerticalIcon";

const getImageUrl = (item) => {
  if (item.ip_id.startsWith("film-") || item.ip_id.startsWith("series-")) {
    return `https://image.tmdb.org/t/p/w300/${item.image_url}`;
  } else if (item.ip_id.startsWith("game-")) {
    return `https://images.igdb.com/igdb/image/upload/t_cover_big/${item.image_url}.png`;
  }
  return null;
}

export const RenderItemV2 = ({
  item, 
  enableSelection=true,
  selected,
  onSelectedChanged,
}) => {
  const ref = useRef(null);
  const navigate = useNavigate();

  const [ checked, setChecked ] = useState(selected);
  const [ onRightSide, setOnRightSide ] = useState(false);
  const [ imageErrored, setImageErrored ] = useState(false);

  const imageUrl = getImageUrl(item);

  const checkIfOnRightSide = () => {
    const parentWidth = ref?.current?.parentElement?.getBoundingClientRect().width;
    const parentPos = ref?.current?.parentElement?.getBoundingClientRect().x;
    const relativeXPos = ref?.current?.getBoundingClientRect().x - parentPos;
    setOnRightSide(relativeXPos > parentWidth / 2);
  };

  const invertSides = (className) => {
    if (!onRightSide) return className;
    return className.replaceAll("left", "__tmp__").replaceAll("right", "left").replaceAll("__tmp__", "right");
  };

  useEffect(() => {
    if (selected !== checked) {
      setChecked(selected);
    }
  }, [selected]);

  return (
    <div 
      ref={ref} 
      className="min-w-22 min-h-32 max-w-64 aspect-[44/64] bg-slate-600 rounded-lg shadow-md relative group hover:scale-110 ease-out duration-300 z-0 hover:z-10 grow"
      onMouseEnter={checkIfOnRightSide}
    >
      <div className={item.score ? invertSides("h-full aspect-[44/64] bg-black bg-opacity-70 backdrop-blur-md absolute top-0 left-0 group-hover:aspect-[80/64] ease-out duration-300 rounded-lg pointer-events-none") : ""}>
        <div 
          className={invertSides("relative top-0 left-0 float-left h-full aspect-[44/64] z-20 pointer-events-auto cursor-pointer" + ` cover-image-${item.ip_id}`)}
          onClick={(event) => {
            if (!event.target.classList.contains("clickable-item")) return;
            window.location.href = `/item/${item.ip_id}`;
          }}
          onMouseDown={(event) => {
            if (!event.target.classList.contains("clickable-item")) return;
            if (event.which === 2 || event.button === 1) {
              event.preventDefault();
              window.open(`/item/${item.ip_id}`, '_blank').focus();
            }
          }}
        >
          {(!imageErrored && imageUrl) ? (
            <img 
              src={imageUrl} 
              alt={item.ip} 
              className="w-full h-full object-cover rounded-lg clickable-item" 
              loading="lazy" 
              onError={() => {
                if (!imageErrored) {
                  setImageErrored(true);
                }
              }}
            />
          ) : (
            <div className="flex items-center justify-center w-full h-full text-white text-sm clickable-item bg-slate-600 rounded-lg">
              {item.ip}
            </div>
          )}
          {item.rank && (
            <div className="absolute top-1 left-1 bg-sidebarLow bg-opacity-70 backdrop-blur-md rounded-full min-w-6 px-2 h-6 flex items-center justify-center text-amber-300 font-bold">
              {item.rank_change != null ? renderTrendSimple(item.rank, item.rank_change) : item.rank}
            </div>
          )}
          {item.vertical && (
            <div className={"absolute top-1 right-1 bg-sidebarLow bg-opacity-70 backdrop-blur-md rounded-full min-w-6 px-2 h-6 flex items-center justify-center text-slate-200" + (enableSelection ? "group-hover:hidden": "")}>
              <VerticalIcon vertical={item.vertical} className="w-4 h-4" />
            </div>
          )}
          {item.release_date && (
            <div className="absolute bottom-1 right-1 bg-sidebarLow bg-opacity-70 backdrop-blur-md rounded-full min-w-6 px-2 h-6 flex items-center justify-center text-slate-200 text-xs font-bold">
              {moment(item.release_date).format("YYYY")}
            </div>
          )}
          {enableSelection && (
            <div className={`absolute top-1 right-1 z-50 group-hover:block ${checked ? "block" : "hidden"}`} title={checked ? "Remove from compare" : "Add to compare"}>
              <Button
                iconSvg={<CompareIcon />}
                onClick={() => {
                  setChecked(!checked);
                  onSelectedChanged(!checked);
                }}
                variant={checked ? "primary" : "normal"}
              />
            </div>
          )}
          <ReactTooltip
            anchorSelect={`.cover-image-${item.ip_id}`}
            content={item.ip}
            place="top"
            opacity={1.0}
            style={{ backgroundColor: "#333", borderRadius: "0.5rem", maxWidth: "100%" }}
          />
        </div>
        {item.score != null && (
          <div className={invertSides("ease-out duration-300 absolute top-0 right-0 z-0 whitespace-nowrap h-full p-4 aspect-[36/64] flex flex-col gap-y-2 items-center")}>
            <div className="flex flex-col space-y-2 w-full">
              <div className={`rounded-md px-2 py-1 text-sm font-semibold border-2 border-slate-600 text-slate-200 flex justify-center gap-1`}>
                Score: <span className="font-bold text-yellow-300">{parseFloat(item.score).toFixed(1)}</span>
              </div>
              <div className="flex flex-col gap-y-1 text-slate-200">
                {item.wiki != null && item.wiki > 0 && (
                  <div className="text-sm flex space-x-2 items-center wiki_score">
                    <FaWikipediaW className="h-5 w-5" />
                    <span>{`${parseFloat(item.wiki).toFixed(1)}`}</span>
                    {/*<ReactTooltip
                      anchorSelect=".wiki_score"
                      content="Wikipedia Score"
                      place="right"
                    />*/}
                  </div>
                )}
                {item.youtube != null && item.youtube > 0 && (
                  <div className="text-sm flex space-x-2 items-center youtube_score">
                    <FaYoutube className="h-5 w-5" />
                    <span>{`${parseFloat(item.youtube).toFixed(1)}`}</span>
                    {/*<ReactTooltip
                      anchorSelect=".youtube_score"
                      content="YouTube Score"
                      place="right"
                    />*/}
                  </div>
                )}
                {item.twitch != null && item.twitch > 0 && (
                  <div className="text-sm flex space-x-2 items-center twitch_score">
                    <FaTwitch className="h-5 w-5" />
                    <span>{`${parseFloat(item.twitch).toFixed(1)}`}</span>
                    {/*<ReactTooltip
                      anchorSelect=".twitch_score"
                      content="Twitch Score"
                      place="right"
                    />*/}
                  </div>
                )}
                {item.steam != null && item.steam > 0 && (
                  <div className="text-sm flex space-x-2 items-center steam_score">
                    <FaSteam className="h-5 w-5" />
                    <span>{`${parseFloat(item.steam).toFixed(1)}`}</span>
                    {/*<ReactTooltip
                      anchorSelect=".steam_score"
                      content="Steam Score"
                      place="right"
                    />*/}
                  </div>
                )}
                {item.piracy != null && item.piracy > 0 && (
                  <div className="text-sm flex space-x-2 items-center piracy_score">
                    <GiPirateFlag className="h-5 w-5" />
                    <span>{`${parseFloat(item.piracy).toFixed(1)}`}</span>
                    {/*<ReactTooltip
                      anchorSelect=".piracy_score"
                      content="Piracy Score"
                      place="right"
                    />*/}
                  </div>
                )}
                {item.imdb != null && item.imdb > 0 && (
                  <div className="text-sm flex space-x-2 items-center imdb_score">
                    <FaImdb className="h-5 w-5" />
                    <span>{`${parseFloat(item.imdb).toFixed(1)}`}</span>
                    {/*<ReactTooltip
                      anchorSelect=".imdb_score"
                      content="IMDb Score"
                      place="right"
                    />*/}
                  </div>
                )}
                {item.rt != null && item.rt > 0 && (
                  <div className="text-sm flex space-x-2 items-center rt_score">
                    <SiRottentomatoes className="h-5 w-5" />
                    <span>{`${parseFloat(item.rt).toFixed(1)}`}</span>
                    {/*<ReactTooltip
                      anchorSelect=".rt_score"
                      content="Rotten Tomatoes Score"
                      place="right"
                    />*/}
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};