

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
    loading: false,
    data: [],
    completed: false,
};

const rowDataSlice = createSlice({
    name: 'rankingRowData',
    initialState,
    reducers: {
        startLoading (state, action) {
            state.loading = true;
            state.data = [];
        },
        insertTrendRowData (state, action) {

            state.loading = false;
            state.completed = true;
            state.data = action.payload;
        },
        updateTrendRowData (state, action) {
            state.loading = false;
            state.completed = true;
        },
        clearTrendsSummary () {
            return initialState;
        },
    },
});

const rankingRawDataReducer =  rowDataSlice.reducer;
const rankingRawDataAction = rowDataSlice.actions;

export {rankingRawDataAction};
export default rankingRawDataReducer;

