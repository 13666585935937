import React from "react";
import Header from "@cloudscape-design/components/header";
import BoardItem from "@cloudscape-design/board-components/board-item";
import Box from "@cloudscape-design/components/box";
import { EmptyState } from "../EmptyState";
import { boardItemI18nStrings } from "../../utils/i18n-strings";

export function PaletteItem({ title, description, showPreview }) {
  return (
    <BoardItem
      header={<Header variant="h3">{title}</Header>}
      i18nStrings={boardItemI18nStrings}
    >
      {showPreview ? (
        <EmptyState
          title={title}
          description={description}
          verticalCenter={true}
        />
      ) : (
        <Box>{description}</Box>
      )}
    </BoardItem>
  );
}
