import { Box, Container, Link } from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { useGetYoutubeChannelsData } from "../../api/hooks/useGetYoutubeChannelsData";
import { columnDefs } from "./gridColumnDefs";
import { defaultColDef } from "./gridDefaultColDef";
import { EmptyGrid } from "../../../../../components/grid/EmptyGrid";

export const YoutubeChannelWidget = ({
  sort = [],
  include = [],
  exclude = [],
  condition = "and",
  headerLink = "#",
  headerText = " ",
}) => {
  const { theme } = useGetGridTheme();
  const { data: youtubeChannelsData } = useGetYoutubeChannelsData({
    sort,
    include,
    exclude,
    condition,
  });

  return (
    <Container
      disableContentPaddings
      fitHeight
    >
      <div className="font-bold text-lg bg-slate-200 dark:bg-slate-800 text-center truncate p-2 rounded-t-2xl">
        {headerText}
      </div>
      <hr className="dark:border-slate-600 border-slate-300 border-t-[1px]" />
      <div className={`${theme} compact h-[340px]`}>
        <AgGridReact
          rowData={youtubeChannelsData}
          defaultColDef={defaultColDef}
          loadingOverlayComponent={() => (
            <CustomLoadingOverlay compact={true} />
          )}
          noRowsOverlayComponent={EmptyGrid}
          suppressDragLeaveHidesColumns={true}
          columnDefs={columnDefs}
          suppressContextMenu={true}
        />
      </div>
      <hr className="dark:border-slate-600 border-slate-300 border-t-[1px]" />
      <div className="text-md bg-slate-200 dark:bg-slate-800 text-center truncate p-2 rounded-b-2xl">
        <Link href={headerLink} variant="secondary">
          View All
        </Link>
      </div>
    </Container>
  );
};
