import { Alert } from "@cloudscape-design/components";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormProvider from "../../../../components/forms/FormProvider";
import PasswordTextField from "../../../../components/forms/PasswordTextField";
import SelectBox from "../../../../components/forms/SelectBox";
import { TextField } from "../../../../components/forms/TextField";
import SnackbarContext from "../../../../hooks/notify/snack-bar/snackbar-context";
import { clearUserCreateFn, createUserFn } from "../redux/actions/user-actions";

export default function CreateUserForm({
  formStatus = true,
  setCancel,
  setCompleted,
  ...others
}) {
  const snackbarCtx = useContext(SnackbarContext);
  const createUser = useSelector((state) => state.createUser);

  const onSubmit = async (data) => {
    createUserFn({ ...data });
  };
  const createUserDefaultValue = {
    fullname: "",
    username: "",
    role: {},
    org: {},
    title: "",
    email: "",
    tempPassword: "",
  };
  const createUserSchema = Yup.object().shape({
    fullname: Yup.string().trim().required("Fullname is required"),
    username: Yup.string()
      .trim()
      .min(6, "Username must be 6 charaters in length")
      .required("Username is required"),
    role: Yup.object()
      .required("Role is required")
      .test(
        "is-not-empty",
        "Role is required",
        (obj) => Object.keys(obj).length > 0,
      ),
    org: Yup.object()
      .required("Team is required")
      .test(
        "is-not-empty",
        "Team is required",
        (obj) => Object.keys(obj).length > 0,
      ),
    title: Yup.string().required("Title is required"),
    email: Yup.string().email().required("Email is required"),
    tempPassword: Yup.string().min(6).required("Temp password is required"),
  });

  const methods = useForm({
    defaultValues: createUserDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createUserSchema),
  });

  const {
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (
      !createUser.isLoading &&
      createUser.isCompleted &&
      !createUser.isError
    ) {
      setCompleted();
      setCancel(true);
      clearUserCreateFn();
      reset();
    }
  }, [createUser]);

  useEffect(() => {
    if (!formStatus) {
      reset();
    }
  }, [formStatus]);

  return (
    <FormProvider
      methods={methods}
      onSubmit={handleSubmit(onSubmit)}
      id="user_create_form"
    >
      <div className="py-5">
        {!createUser.isLoading &&
          createUser.isCompleted &&
          createUser.isError && (
            <Alert type="error" dismissible={true}>
              {createUser.message}
            </Alert>
          )}
        {!createUser.isLoading &&
          createUser.isCompleted &&
          !createUser.isError && (
            <Alert type="success" dismissible={true}>
              {createUser.message}
            </Alert>
          )}
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 ">
          <span>
            <TextField
              name={"fullname"}
              label="Full Name"
              placeholder={"Full Name"}
              type={"text"}
            />
          </span>
          <span>
            <TextField
              name={"username"}
              label="Username"
              placeholder={"Username"}
              type={"text"}
            />
          </span>
          <span>
            <SelectBox
              name={"role"}
              label="Roles"
              placeholder={"Roles"}
              items={others.rolesList}
            />
          </span>
          <span>
            <SelectBox
              name={"org"}
              label="Team"
              placeholder={"Team"}
              items={others.orgs}
            />
          </span>
          <span>
            <TextField
              name={"title"}
              label="Title"
              placeholder={"Title"}
              type={"text"}
            />
          </span>
          <span>
            <TextField
              name={"email"}
              label="Email"
              placeholder={"Email"}
              type={"text"}
            />
          </span>
          <span>
            <PasswordTextField
              name={"tempPassword"}
              label="Temp Password"
              placeholder={"Temp Password"}
            />
          </span>
          <span></span>
          <span className="not-italic  font-normal text-sm text-gray-500">
            Your password must be greater than 4 characters.
          </span>
          <span></span>
        </div>
        {/*  <div className="flex flex-row justify-end items-center pt-3">
          <SpaceBetween direction="horizontal" size="m">
            <span>
              <Button
                formAction="none"
                loading={isSubmitting}
                onClick={() => {
                  reset();
                  setCancel(true);
                }}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                variant="primary"
                loading={isSubmitting}
                formAction="submit"
              >
                Submit
              </Button>
            </span>
          </SpaceBetween>
        </div> */}
      </div>
    </FormProvider>
  );
}
