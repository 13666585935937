import React, { useMemo } from "react";
import { PropertyFilter } from "@cloudscape-design/components";

const propertyLabel = new Map([
  ["type", "Type"],
  ["parentCompany", "Parent Company"],
  ["description", "Description"],
]);

const Filtering = ({ query, handleChange, data, preferences }) => {
  const handleQuery = ({ detail }) => {
    handleChange?.(detail);
  };

  const filteringOptions = useMemo(() => {
    const queryVals = query.tokens.map((f) => f.value);
    return [
      ...[...new Set(data.map((d) => d.vertical))]
        .filter((e) => e && !queryVals.includes(e))
        .sort()
        .map((f) => ({
          propertyKey: "type",
          value: f,
        })),
      ...[...new Set(data.map((d) => d.parentCompany))]
        .filter((e) => e && !queryVals.includes(e))
        .sort()
        .map((f) => ({
          propertyKey: "parentCompany",
          value: f,
        })),
    ];
  }, [data, query]);

  const filteringProperties = useMemo(() => {
    return [
      {
        key: "name",
        operators: ["=", "!="],
        propertyLabel: "Name",
        groupValuesLabel: "Name values",
      },
      ...preferences.visibleContent
        .map((p) => {
          const prop = propertyLabel.get(p);
          if (prop == null) return null;

          return {
            key: p,
            propertyLabel: prop,
            groupValuesLabel: `${prop} values`,
            operators: ["=", "!="],
          };
        })
        .filter((p) => p),
    ];
  }, [preferences.visibleContent]);

  return (
    <PropertyFilter
      onChange={handleQuery}
      query={query}
      i18nStrings={{
        filteringAriaLabel: "Find resources",
        filteringPlaceholder: "Find resources",
        groupValuesText: "Values",
        valueText: "Value",
        groupPropertiesText: "Properties",
        propertyText: "Property",
        operatorsText: "Operators",
        operatorText: "Operator",
        operationAndText: "and",
        operationOrText: "or",
        allPropertiesLabel: "All properties",
        cancelActionText: "Cancel",
        applyActionText: "Apply",
        tokenLimitShowMore: "Show more",
        tokenLimitShowFewer: "Show fewer",
        clearFiltersText: "Clear filters",
        removeTokenButtonAriaLabel: (token) =>
          `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: (text) => `Use: "${text}"`,
      }}
      expandToViewport
      filteringOptions={filteringOptions}
      filteringProperties={filteringProperties}
    />
  );
};

export default Filtering;
