import { ExpandableSection } from "@cloudscape-design/components";
import { AgGridReact } from "ag-grid-react";
import React, { FC } from "react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { useGetTopVideos } from "../../api/hooks/useGetTopVideos";
import { columnDefs } from "./gridColumnDefs";
import { defaultColDef } from "./gridDefaultColDef";
import { EmptyGrid } from "../../../../../components/grid/EmptyGrid";

interface TopVideosProps {
  channelId: string;
}

const TopVideos: FC<TopVideosProps> = ({ channelId }) => {
  const { theme } = useGetGridTheme();

  const { data: videos } = useGetTopVideos({
    channelId,
    sort: { views: "desc" },
  });
  if (videos?.data?.length === 0) return null;

  return (
      <ExpandableSection
        defaultExpanded
        disableContentPaddings
        variant="container"
        headerText="Top Videos"
      >
        <hr className="border-slate-600 border-t-[1px]" />
        <div className={`${theme} compact`}>
          <AgGridReact
            rowData={videos?.data}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            domLayout="autoHeight"
            loadingOverlayComponent={() => (
              <CustomLoadingOverlay compact={true} />
            )}
            noRowsOverlayComponent={EmptyGrid}
            suppressDragLeaveHidesColumns={true}
            columnDefs={columnDefs}
            suppressContextMenu={true}
          />
        </div>
      </ExpandableSection>
  );
};

export default React.memo(TopVideos);
