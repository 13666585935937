import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
import _ from "lodash";

const googletrendsLeaderBoardApi = createAsyncThunk(
  "googletrendsLeaderBoard/googletrendsLeaderBoardApi",
  async (
    { gte, lte, from, size, ip_list, vertical, keyword, genre },
    { rejectWithValue }
  ) => {
    let params = {};

    params["gte"] = gte;
    params["lte"] = lte;
    params["from"] = from ?? 0;
    params["size"] = size ?? 12;
    params["ip_list"] = ip_list;
    if (vertical) params["vertical"] = vertical;
    if (keyword) params["keyword"] = keyword;
    if (genre) params["genre"] = genre;
    try {
      const response = await axiosInstance.get("googletrends/leaderboard", {
        params: params,
      });

      return response.data;
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

const initialState = {
  total: 0,
  data: [],
  completed: false,
  hasError: false,
  loading: false,
  message: "",
};

const googletrendsLeaderBoardSlice = createSlice({
  name: "googletrendsLeaderBoard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(googletrendsLeaderBoardApi.pending, (state, { payload }) => {
        _.set(state, "loading", true);
        _.set(state, "data", []);
        _.set(state, "total", 0);
        _.set(state, "hasError", false);
      })
      .addCase(googletrendsLeaderBoardApi.fulfilled, (state, { payload }) => {
        _.set(state, "loading", false);
        _.set(state, "completed", true);
        _.set(state, "hasError", false);
        _.set(state, "data", payload?.data ?? []);
        _.set(state, "total", payload?.total ?? 0);
      })
      .addCase(googletrendsLeaderBoardApi.rejected, (state, { payload }) => {
        _.set(state, "loading", false);
        _.set(state, "completed", true);
        _.set(state, "hasError", true);
        _.set(state, "message", payload);
      });
  },
});

const GoogleLeaderBoardReducer = googletrendsLeaderBoardSlice.reducer;
const GoogleLeaderBoardActions = googletrendsLeaderBoardSlice.actions;

export {
  googletrendsLeaderBoardApi,
  GoogleLeaderBoardReducer,
  GoogleLeaderBoardActions,
};
