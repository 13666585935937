import { useEffect, useState } from "react";
import { BreadcrumbsItem } from "../../../layouts/common/menu/side-menu";
import { ItemTypes } from "../../../utils/itemUtils";

const typeBcLabels = {
  [ItemTypes.TELEVISION_SERIES]: "Television",
  [ItemTypes.FILM]: "Movies",
  [ItemTypes.VIDEO_GAME]: "Games",
  [ItemTypes.FRANCHISE]: "Franchise",
  [ItemTypes.COMPANY]: "Companies",
};

export const useBreadCrumbs = (itemData) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Array<BreadcrumbsItem>>([
    {
      text: "Explore",
      href: "/explore",
    },
  ]);

  useEffect(() => {
    if (
      !itemData.completed ||
      !itemData.data.type ||
      (itemData.hasError && itemData.message.includes("not found"))
    ) {
      return;
    }

    let bc = [
      { text: "Explore", href: "/explore" },
      {
        text: typeBcLabels[itemData.data.type.id],
        href: `/explore/${typeBcLabels[itemData.data.type.id].toLowerCase()}`,
      },
      { text: itemData.data ? itemData.data.name : "Not Found" },
    ];

    setBreadcrumbs(bc as Array<BreadcrumbsItem>);
  }, [itemData]);

  return { breadcrumbs };
};
