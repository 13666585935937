import { useMemo } from "react";
import countriesTopology from "../data/countries.json";
import dmaTopology from "../data/nielsendma.json";
import { useGetChartTheme } from "../../../../../../hooks/UseTheme/useGetChartTheme";
import { COUNTRIES, DMA_REGIONS } from "../../../constants";
import shortenFloat from "../../../../../../utils/shortenFloat";
import { useThemeContext } from "../../../../../../app/ThemeProvider";
import { Mode } from "@cloudscape-design/global-styles";

export const useChartConfig = ({
  totalsData,
  selectedCategory,
  selectedMap,
}) => {

  const { theme } = useGetChartTheme();
  const { mode } = useThemeContext();

  const data = useMemo(() => {
    if (!totalsData || totalsData.length === 0) return [];

    const maxNormalizedVolume = Math.max(...totalsData.filter(d => d.category == selectedCategory.value && d.has_data).map(d => d.normalized_volume));
    const data = totalsData.filter(d => d.category == selectedCategory.value && d.has_data).map(d => {
      if (selectedMap.value === "countries") {
        return {
          countryCode: d.geo_name.toUpperCase(),
          country: Object.values(COUNTRIES).find(country => country.value === d.geo_name),
          value: d.normalized_volume / maxNormalizedVolume * 100,
        };
      } else if (selectedMap.value === "dma") {
        return {
          countryCode: d.geo_name,
          country: Object.values(DMA_REGIONS).find(dma => dma.value === d.geo_name),
          value: d.normalized_volume / maxNormalizedVolume * 100,
        };
      }
    });

    return data;
  }, [totalsData, selectedCategory, selectedMap]);

  const series = useMemo(() => (
    [
      { 
        type: "map-shape-background",
      },
      {
        type: "map-shape",
        idKey: "countryCode",
        topologyIdKey: selectedMap.value === "countries" ? "ISO_A2" : "dma_name_2",
        colorKey: "value",
        colorName: "Search popularity",
        colorRange: mode === Mode.Dark ? ["#253140", "#42b4ff"] : ["#dedede", "#006ce0"],
        tooltip: {
          renderer: (params) => {
            return {
              title: params.datum.country.label,
              content: `Search popularity: ${params.datum.value.toLocaleString(undefined, { maximumFractionDigits: 2 })}`,
              backgroundColor: "#42b4ff",
            };
          },
        },
      },
    ]
  ), [selectedMap, mode]);

  const gradientLegend = useMemo(() => (
    {
      enabled: true,
      position: "right",
      gradient: {
        preferredLength: 200,
        thickness: 2,
      },
      scale: {
        label: {
          fontSize: 10,
          fontFamily: "Open Sans",
          formatter: (p) => `${shortenFloat(p.value)}`,
        },
      },
    }
  ), [totalsData, selectedCategory]);

  const topology = useMemo(() => {
    switch (selectedMap.value) {
      case "dma":
        return dmaTopology;
      case "countries":
        return countriesTopology;
      default:
        return countriesTopology;
    }
  }, [selectedMap]);

  return {
    data,
    theme,
    topology,
    series,
    //gradientLegend,
    height: 500,
  };
};