import React from 'react';
import { Select } from "@cloudscape-design/components";

const CategorySelector = ({ selectedCategory, setSelectedCategory }) => {
    return (
        <Select
            expandToViewport
            placeholder="Category"
            selectedOption={selectedCategory}
            onChange={({ detail }) => setSelectedCategory(detail.selectedOption)}
            options={[
                { label: "All", value: "all" },
                { label: "Movies", value: "Movies" },
                { label: "Series", value: "Television" },
                { label: "Games", value: "Gaming" },
            ]}
        />
    );
};

export default CategorySelector;
