export interface GetYoutubeDataResponseItem {
  timestamp: number;
  todayViews: number;
  todayLikes: number;
  todayComments: number;
  daily_rank: number;
  rank: number;
  views: number;
  likes: number;
  comments: number;
}

export interface MappedGetYoutubeResponseItem {
  dailyRank: number;
  timestamp: number;
  dailyViews: number;
  dailyLikes: number;
  dailyComments: number;
  allTimeRank: number;
  allTimeViews: number;
  allTimeLikes: number;
  allTimeComments: number;
}

export const mapYoutubeResponse = (item: GetYoutubeDataResponseItem) => ({
  dailyRank: item.daily_rank,
  timestamp: item.timestamp * 1000,
  dailyViews: item.todayViews,
  dailyLikes: item.todayLikes,
  dailyComments: item.todayComments,
  allTimeRank: item.rank,
  allTimeViews: item.views,
  allTimeLikes: item.likes,
  allTimeComments: item.comments,
});

export const YOUTUBE_GRID_HEADER_MAP = {
  dailyRank: "Daily rank",
  dailyViews: "Daily views",
  dailyLikes: "Daily likes",
  dailyComments: "Daily comments",
  allTimeRank: "All time rank",
  allTimeViews: "All time views",
  allTimeLikes: "All time likes",
  allTimeComments: "All time comments",
};
