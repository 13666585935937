import {
  Box,
  Button,
  FormField,
  Input,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC, useEffect } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import { usePostGenericPreference_V2 } from "../../services/generic_v2/hooks/usePostGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../../services/generic_v2/hooks/usePutGenericPreference_V2";
import { ApiURLParams } from "../../services/generic_v2/requests";
import FormProvider from "../forms/FormProvider";

type SaveOrUpdateFilterModalProps = {
  filterName: string;
  title: string;
  visible: boolean;
  isUpdate: boolean;
  existingPref: any;
  onDismiss: () => void;
  getPreferencesAfterSaveOrUpdate: (
    name: string,
    prevName: string,
    isUpdate: boolean,
  ) => void;
  apiParams: ApiURLParams;
};

export const SaveOrUpdateFilterModalV2: FC<SaveOrUpdateFilterModalProps> = ({
  filterName,
  title,
  visible,
  isUpdate,
  existingPref,
  onDismiss,
  getPreferencesAfterSaveOrUpdate,
  apiParams,
}) => {
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
  });

  const resolver: Resolver<{ name: string }> = async (values) => {
    return {
      errors: {
        ...(!values.name
          ? { name: { type: "required", message: "Name is required" } }
          : {}),
      },
      values,
    };
  };

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver,
  });
  const { watch, reset, control, setValue, handleSubmit, formState } = methods;

  useEffect(() => {
    setValue("name", filterName);
  }, [filterName, setValue]);

  const onSubmit = () => {
    const newGlobalPreferences = getPreferencesAfterSaveOrUpdate(
      watch().name,
      filterName,
      isUpdate,
    );

    if (existingPref)
      updatePreference({ id: existingPref.id, payload: newGlobalPreferences });
    else postGenericPreference(newGlobalPreferences);

    closeModal();
  };

  const closeModal = () => {
    onDismiss();
    reset();
  };

  return (
    <Modal onDismiss={onDismiss} visible={visible} header={title}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SpaceBetween direction="vertical" size="l">
          <Controller
            name={"name"}
            control={control}
            defaultValue={"filterName"}
            render={({ field }) => {
              return (
                <FormField
                  label="Name"
                  description="Title of the filter settings"
                  stretch={true}
                  errorText={formState.errors?.name?.message ?? ""}
                >
                  <Input
                    {...field}
                    value={field.value}
                    onChange={(e) => field.onChange(e.detail.value)}
                  />
                </FormField>
              );
            }}
          />
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" formAction="none" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="primary" formAction="submit">
                {isUpdate ? "Update" : "Save"}
              </Button>
            </SpaceBetween>
          </Box>
        </SpaceBetween>
      </FormProvider>
    </Modal>
  );
};
