import { FILTER_JOIN_OPERATIONS, PLATFORMS, VERTICALS } from "../../config-global";
import { FILTER_TYPES, FILTER_OPERATIONS } from "../../config-global";

export const DEFAULT_WEIGHTS_OBJECTS = {
  [VERTICALS.MOVIES]: {
    category: VERTICALS.MOVIES,
    name: "Default movie weights",
    weights: [
      {
        platform: PLATFORMS.WIKIPEDIA,
        weight: 3.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.YOUTUBE,
        weight: 2.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.IMDB,
        weight: 1.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.PIRACY,
        weight: 2.5,
        enabled: true,
      },
      {
        platform: PLATFORMS.ROTTEN_TOMATOES,
        weight: 1.0,
        enabled: true,
      },
    ],
  },
  [VERTICALS.SERIES]: {
    category: VERTICALS.SERIES,
    name: "Default series weights",
    weights: [
      {
        platform: PLATFORMS.WIKIPEDIA,
        weight: 3.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.YOUTUBE,
        weight: 2.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.IMDB,
        weight: 1.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.PIRACY,
        weight: 2.5,
        enabled: true,
      },
      {
        platform: PLATFORMS.ROTTEN_TOMATOES,
        weight: 1.0,
        enabled: true,
      },
    ],
  },
  [VERTICALS.GAMES]: {
    category: VERTICALS.GAMES,
    name: "Default game weights",
    weights: [
      {
        platform: PLATFORMS.WIKIPEDIA,
        weight: 2.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.YOUTUBE,
        weight: 1.0,
        enabled: true,
      },
      {
        platform: PLATFORMS.STEAM,
        weight: 3.5,
        enabled: true,
      },
      {
        platform: PLATFORMS.TWITCH,
        weight: 3.5,
        enabled: true,
      },
    ],
  },
};

export const DEFAULT_FILTER_OBJECTS = {
  [VERTICALS.MOVIES]: {
    category: VERTICALS.MOVIES,
    name: "Default movie filters",
    advancedFilter: null,
    titleSearchQuery: "",
    filters: [
      {
        name: "Genre",
        key: "genre",
        filterType: FILTER_TYPES.OBJECT,
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        allowBlank: false,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Franchise", 
        key: "franchise", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: true,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Provider", 
        key: "providers", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: true,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Distributor", 
        key: "distributors", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: false,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Production", 
        key: "production", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: false,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Release date", 
        key: "release_date", 
        filterType: FILTER_TYPES.DATE, 
        filterOperation: FILTER_OPERATIONS.LESS_THAN,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.AND,
        vertical: VERTICALS.MOVIES,
      },
    ],
  },
  [VERTICALS.SERIES]: {
    category: VERTICALS.SERIES,
    name: "Default series filters",
    advancedFilter: null,
    titleSearchQuery: "",
    filters: [
      { 
        name: "Genre", 
        key: "genre", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        allowBlank: false,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Franchise", 
        key: "franchise", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: true,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Provider", 
        key: "providers", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: true,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Distributor", 
        key: "distributors", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: false,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Production", 
        key: "production", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: false,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Release date", 
        key: "release_date", 
        filterType: FILTER_TYPES.DATE, 
        filterOperation: FILTER_OPERATIONS.LESS_THAN,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.AND,
        vertical: VERTICALS.SERIES,
      },
    ],
  },
  [VERTICALS.GAMES]: {
    category: VERTICALS.GAMES,
    name: "Default game filters",
    advancedFilter: null,
    titleSearchQuery: "",
    filters: [
      { 
        name: "Genre", 
        key: "genre", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        allowBlank: false,
        vertical: VERTICALS.GAMES,
      },
      { 
        name: "Franchise", 
        key: "franchise", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: true,
        vertical: VERTICALS.GAMES,
      },
      { 
        name: "Platform", 
        key: "platform", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: false,
        vertical: VERTICALS.GAMES,
      },
      { 
        name: "Developer", 
        key: "developer", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: false,
        vertical: VERTICALS.GAMES,
      },
      { 
        name: "Publisher", 
        key: "publisher", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        allowBlank: false,
        vertical: VERTICALS.GAMES,
      },
      { 
        name: "Game Mode", 
        key: "game_modes", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        allowBlank: false,
        vertical: VERTICALS.GAMES,
      },
      /*{ 
        name: "Game Engine", 
        key: "game_engines", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        vertical: VERTICALS.GAMES,
      },*/
      { 
        name: "Player Perspective", 
        key: "player_perspectives", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        allowBlank: false,
        vertical: VERTICALS.GAMES,
      },
      { 
        name: "Theme", 
        key: "themes", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        allowBlank: false,
        vertical: VERTICALS.GAMES,
      },
      { 
        name: "Release date", 
        key: "release_date", 
        filterType: FILTER_TYPES.DATE, 
        filterOperation: FILTER_OPERATIONS.LESS_THAN,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.AND,
        vertical: VERTICALS.GAMES ,
      },
    ],
  },
};

export const PLATFORM_INDEX_MAP = {
  [PLATFORMS.WIKIPEDIA.key]: "wiki",
  [PLATFORMS.YOUTUBE.key]: "youtube",
  [PLATFORMS.IMDB.key]: "imdb",
  [PLATFORMS.PIRACY.key]: "piracy",
  [PLATFORMS.ROTTEN_TOMATOES.key]: "rt",
  [PLATFORMS.STEAM.key]: "steam",
  [PLATFORMS.TWITCH.key]: "twitch",
};