import { Link } from "@cloudscape-design/components";

export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
  
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

    return str;
}

export const getNiceError = (error) => {
    switch (error.error_type) {
        case "InvalidRequestException":
            const errorMessage = JSON.parse(error.error_message);
            return {
                ...error,
                name: "Invalid query",
                details: <ul>
                    {errorMessage.errors.map((error) => (
                        <li key={error.field}>&bull; {error.message}</li>
                    ))}
                </ul>,
            };
        default:
            return {
                ...error,
                name: "Unknown error",
                details: <div>An unknown error has occurred with this query. Please use the <Link href="/issues" external>issue tracker</Link> to report this issue and provide the query ID.</div>,
            };
    }
};