import axiosInstance from "../../../utils/axios";

type ViewsTrendItem = {
  timestamp: number;
  views: number;
};

export interface ArticleItem {
  article: string;
  ip_id: string;
  rank: number;
  views_trend: Array<ViewsTrendItem>;
}

export interface GeneralTrendsResponse {
  timestamp: number;
  total_articles: number;
  articles: Array<ArticleItem>;
}

export type GetWikipediaLeaderBoardProps = {
  gte: number;
  lte: number;
  from: number;
  size: number;
  ip_list: string;
  vertical: string;
  keyword: string;
  genre: string;
};

export const getWikipediaLeaderBoard = ({
  gte,
  lte,
  from = 0,
  size = 12,
  ip_list = "",
  vertical = "",
  keyword = "",
  genre = "",
}: GetWikipediaLeaderBoardProps) => {
  return axiosInstance.get(
    `wikipedia/leaderboard/v2?gte=${gte}&lte=${lte}&from=${from}&size=${size}&ip_list=${ip_list}&vertical=${vertical}&keyword=${keyword}&genre=${genre}`,
  );
};
