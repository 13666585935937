import { createSlice } from "@reduxjs/toolkit";

const initialIpSearchListState = {
    loading: false,
    data: [],
    completed: false,
    hasError: false,
    message: ""
}


const ipSearchListSlice = createSlice({
    name: "ipSearchListData",
    initialState: initialIpSearchListState,
    reducers: {
        loading: (state) => {
            return { ...state, loading: true, hasError: false }
        },
        insertData: (state, action) => {
            return { ...state, loading: false, completed: true, data: action.payload, hasError: false }
        },
        insertError: (state, action) => {
            return { ...state, loading: false, completed: true, hasError: true, message: action.payload }
        },
        clearData:()=>{
            return initialIpSearchListState;
        }
    }
});
const IpSearchListReducer = ipSearchListSlice.reducer;
export default IpSearchListReducer;


export const ipSearchListActions = ipSearchListSlice.actions;