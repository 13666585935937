import axiosInstance from "../../../utils/axios";

export const callPreferences = async (domain, action, params={}) => {
  try {
    const response = await axiosInstance.request({
      method: "GET",
      url: "/topicmodeling/pantheon/prefs",
      params: { domain: domain, action: action, ...params },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const addToFavorites = async (domain, toAdd, callback) => {
  const newPreferences = await callPreferences(domain, "add_favorite", toAdd);
  if (callback) callback(newPreferences);
  return newPreferences;
};

export const removeFromFavorites = async (domain, toRemove, callback) => {
  const newPreferences = await callPreferences(domain, "remove_favorite", toRemove);
  if (callback) callback(newPreferences);
  return newPreferences;
};