import React from "react";
import { CollectionPreferences } from "@cloudscape-design/components";

const PAGE_SIZE_OPTIONS = [
  { value: 50, label: "50 items" },
  { value: 100, label: "100 items" },
  { value: 200, label: "200 items" },
];
const PROPERTIES_OPTIONS = [
  { id: "name", label: "Name" },
  { id: "poster", label: "Poster" },
  { id: "status", label: "Status" },
  { id: "release_date", label: "Release Date" },
  { id: "tracked", label: "Tracked" },
  { id: "content_type", label: "Type" },
  { id: "total_ips", label: "Total" },
  { id: "film_count", label: "Movies" },
  { id: "series_count", label: "Series" },
  { id: "game_count", label: "Games" },
];

export default function Preferences({ preferences, handleChange }) {
  return (
    <CollectionPreferences
      title="Preferences"
      confirmLabel="Confirm"
      cancelLabel="Cancel"
      onConfirm={(d) => {
        handleChange(d.detail);
      }}
      preferences={preferences}
      pageSizePreference={{
        title: "Page size",
        options: PAGE_SIZE_OPTIONS,
      }}
      visibleContentPreference={{
        title: "Select visible content",
        options: [
          {
            label: "Main properties",
            options: PROPERTIES_OPTIONS,
          },
        ],
      }}
    />
  );
}
