import { Box } from "@cloudscape-design/components";
import React, { FC, ReactNode } from "react";

type ValueWithLabelProps = {
  label: string;
  children: ReactNode;
};

export const ValueWithLabel: FC<ValueWithLabelProps> = ({
  label,
  children,
}) => (
  <div>
    <Box variant="awsui-key-label">{label}</Box>
    <div>{children}</div>
  </div>
);
