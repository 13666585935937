import {
  ButtonDropdown,
  ContentLayout,
  Header,
  Tabs,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../common/CustomFlashBar";
import { Layout } from "../Layout";
import { WikipediaTrendsCharts } from "./components/WikipediaTrendsCharts";
import { addQueryParams, getQueryParams } from "../../../utils/queryUtils";
import { defaultCategory } from "./constants";

const title = "Wikipedia";
const tabs = ["trends"];

function WikipediaTrendsChartsIndexPage() {
  const breadcrumbs = [{ text: "Platforms" }, { text: title }];
  const tabQuery = getQueryParams("tab");
  const categoryQuery = getQueryParams("category") ?? defaultCategory;
  const isValidTab = tabs.some((str) => str.includes(tabQuery));
  const [activeTabId, setActiveTabId] = useState(
    isValidTab ? tabQuery : "trends",
  );

  if (!isValidTab) {
    addQueryParams({ tab: activeTabId });
  }

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      contentType="table"
      content={
        <ContentLayout
          header={
            <>
              <Header
                description="This module provides information based on the page views of Wikipedia articles. The data is updated daily."
                variant="h2"
              >
                {title}
              </Header>
              <CustomFlashBar />
            </>
          }
        >
          <Tabs
            onChange={({ detail }) => {
              setActiveTabId(detail.activeTabId);
              addQueryParams({ tab: detail.activeTabId });
            }}
            activeTabId={activeTabId}
            fitHeight
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: <WikipediaTrendsCharts
                defaultCategory={categoryQuery}
                setSelectedVertical={(vertical) => addQueryParams({ category: vertical.value })}
                />,
                // action: (
                //   <ButtonDropdown
                //     variant="icon"
                //     items={[
                //       { id: "1", text: "Set as primary", disabled: true },
                //     ]}
                //     expandToViewport={true}
                //   />
                // ),
              },
            ]}
          />
        </ContentLayout>
      }
    />
  );
}

export default WikipediaTrendsChartsIndexPage;
