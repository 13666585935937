import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getGoogleSearchData } from "../request";

export const useGetGoogleSearchData = ({
  enabled=false,
  ipIds=[],
  categories=[],
  geoType="country",
  geoNames=[],
  startDate,
  endDate,
  totalsOnly=false,
}) => {

  const params = {
    ipIds,
    categories,
    geoType,
    geoNames,
    startDate,
    endDate,
    totalsOnly,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_GOOGLE_SEARCH_DATA, { ...params }],
    () => getGoogleSearchData(params),
    { enabled },
  );

  return {
    data: data?.data,
    isLoading,
    ...error,
  };
};
