import { isNone } from "../../utils/sugarUtils";

export class Preferences {
  existingPref: any;
  vertical: string;
  filter: any;

  constructor(existingPref: any, vertical: string, filter: any) {
    this.existingPref = existingPref;
    this.vertical = vertical;
    this.filter = filter;
  }

  private getNewPreferences = (currentFilters) => {
    const newGlobalPreferences = {
      ...this.existingPref?.data,
      [this.vertical]: {
        ...this.existingPref?.data?.[this.vertical],
        filters: currentFilters,
      },
    };

    return newGlobalPreferences;
  };

  public getPreferencesAfterDelete = (name: string) => {
    const filterPreference =
      this.existingPref?.data?.[this.vertical]?.filters ?? {};

    const currentFilters = { ...filterPreference };

    if (isNone(currentFilters[name])) return;

    delete currentFilters[name];

    return this.getNewPreferences(currentFilters);
  };

  public getPreferencesAfterSaveOrUpdate = (
    name: string,
    prevName: string,
    isUpdate: boolean,
  ) => {
    const filterPreference =
      this.existingPref?.data?.[this.vertical]?.filters ?? {};

    const currentFilters = { ...filterPreference };
    currentFilters[name] = { ...this.filter };

    if (isUpdate) delete currentFilters[prevName];

    return this.getNewPreferences(currentFilters);
  };
}
