import React, { FC } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import "./index.css";

interface FavoritesBarListProps {
  favoriteServices: Object;
  setFavoriteServices: (services: Object) => void;
  updateFavorites: (services: Object) => void;
}

export const FavoritesBarList: FC<FavoritesBarListProps> = ({
  favoriteServices,
  setFavoriteServices,
  updateFavorites,
}) => {
  if (!favoriteServices || Object.keys(favoriteServices)?.length === 0)
    return null;

  const reorder = (obj: Object, startIndex: number, endIndex: number) => {
    const keys = Object.keys(obj);
    const resultKeys = Array.from(keys);
    const [removed] = resultKeys.splice(startIndex, 1);
    resultKeys.splice(endIndex, 0, removed);

    // Reconstruct the object based on the new order of keys
    const result = resultKeys.reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {} as Object);

    return result;
  };

  const handleOnDragEnd = ({ destination, source }) => {
    if (!destination) return;

    const newServices = reorder(
      favoriteServices,
      source.index,
      destination.index,
    );

    setFavoriteServices(newServices);
    updateFavorites(newServices);
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="favorites-bar-list" direction="horizontal">
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="mx-6 overflow-x-auto hide-scrollbar flex gap-2"
          >
            {Object.keys(favoriteServices).map((key, index) => (
              <Draggable key={key} draggableId={key} index={index}>
                {(provided) => (
                  <a
                    key={key}
                    href={key}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={provided.draggableProps.style}
                    className="rounded-full whitespace-nowrap px-3 py-1 mb-1 text-blue-400 ring-1 ring-inset ring-blue-400/60 hover:ring-blue-400/80 hover:bg-blue-400/10 text-xs font-medium"
                  >
                    {favoriteServices[key]}
                  </a>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
