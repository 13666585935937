import {
  Box,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween
} from "@cloudscape-design/components";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import shortenInteger from "../../../utils/shortenInteger";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import { DeleteModal } from "../components/DeleteModal";
import { ChannelTrends } from "./components/ChannelTrends";
import RecentVideos from "./components/RecentVideos/RecentVideos";
import TopVideos from "./components/TopVideos/TopVideos";
import VideoList from "./components/VideoList/VideoList";

export const ChannelPage = () => {
  const navigate = useNavigate();
  const { id: channelId } = useParams();
  const [channelDetails, setChannelDetails] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    const fetchChannelDetails = async () => {
      try {
        const response = await axiosInstance.get(
          `/youtube/v1/channels/${channelId}`,
        );
        setChannelDetails(response.data);
      } catch (error) {
        console.error("Failed to fetch channel details:", error);
      }
    };

    fetchChannelDetails();
  }, [channelId]);

  return (
    <>
      <ContentLayout>
        <SpaceBetween direction="vertical" size="m">
        <Header
                variant="h2"
                description={`Type: ${
                  channelDetails?.type
                } \u2022 Created: ${new Date(
                  channelDetails?.publishedAt * 1000,
                ).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}`}
                counter={
                  channelDetails?.madeForKids
                    ? ((
                        <span className="ml-2 inline-flex items-center rounded-md bg-green-400/10 px-1 py-0.5 text-sm font-medium text-green-400 ring-1 ring-inset ring-green-400/30">
                          Kids
                        </span>
                      ) as unknown as string)
                    : ""
                }
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="normal"
                      onClick={() => {
                        setOpenDeleteModal(true);
                      }}
                    >
                      Delete Channel
                    </Button>
                  </SpaceBetween>
                }
              >
                <Link
                  fontSize="heading-l"
                  href={`https://www.youtube.com/channel/${channelId}`}
                  target="_blank"
                  external
                >
                  {channelDetails?.title}
                </Link>
              </Header>
          <Container
            media={{
              content: (
                <img
                  src={`https://images.searchpantheon.com/youtubechannels/${channelId}.jpeg`}
                  alt={channelDetails?.title}
                  style={{ width: "180px" }}
                />
              ),
              position: "side",
            }}
          >
            {channelDetails?.description}
          </Container>
          <Container>
            <ColumnLayout columns={4} variant="text-grid">
              <div>
                <Box variant="awsui-key-label" textAlign="center">
                  Subscribers
                </Box>
                <Box variant="h1" color="text-status-info" textAlign="center">
                  {shortenInteger(channelDetails?.subscriberCount)}
                </Box>
              </div>
              <div>
                <Box variant="awsui-key-label" textAlign="center">
                  Videos
                </Box>
                <Box variant="h1" color="text-status-info" textAlign="center">
                  {shortenInteger(channelDetails?.videoCount)}
                </Box>
              </div>
              <div>
                <Box variant="awsui-key-label" textAlign="center">
                  Total Views
                </Box>
                <Box variant="h1" color="text-status-info" textAlign="center">
                  {shortenInteger(channelDetails?.viewCount)}
                </Box>
              </div>
              <div>
                <Box variant="awsui-key-label" textAlign="center">
                  Today's Views
                </Box>
                <Box variant="h1" color="text-status-info" textAlign="center">
                  {shortenInteger(channelDetails?.todaysViews)}
                </Box>
              </div>
            </ColumnLayout>
          </Container>

          <ExpandableSection
            variant="container"
            defaultExpanded
            headerText="Channel Trends"
          >
            <ChannelTrends channelId={channelId} />
          </ExpandableSection>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <RecentVideos channelId={channelId} />
            <TopVideos channelId={channelId} />
          </div>
          <VideoList channelId={channelId} />
        </SpaceBetween>
      </ContentLayout>
      <DeleteModal
        visible={openDeleteModal}
        selectedChannels={[channelDetails]}
        onDismiss={() => setOpenDeleteModal(false)}
        onSuccess={() => {
          setOpenDeleteModal(false);
          addMessageToFlash(
            createFlashMessage({
              type: "success",
              message: "Channel Deleted!",
            }),
          );
          navigate("/youtube/channels");
          setTimeout(cleanAllFlashMessage, 3000);
        }}
        onFailure={() => {
          addMessageToFlash(
            createFlashMessage({
              type: "error",
              message: "Unable to delete channel!",
            }),
          );
          setTimeout(cleanAllFlashMessage, 3000);
        }}
      >
        {`Are you sure to delete the channel - ${channelDetails?.title} ?`}
      </DeleteModal>
    </>
  );
};
