import { SplitPanel } from "@cloudscape-design/components";
import DatapointComparison from "../../../../components/datapoint-comparison/DatapointComparison";
import { SCORE_COMPARE_METRICS, DATA_COMPARE_METRICS } from "../../../item/pages/components/modals/CompareModal/metrics";
import { VERTICALS } from "../../../../config-global";
import { useEffect, useMemo, useState } from "react";

const verticalMetricsMap = {
  [VERTICALS.MOVIES]: [
    //...SCORE_COMPARE_METRICS.MOVIES_SERIES,
    //...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.MOVIES_SERIES,
  ],
  [VERTICALS.SERIES]: [
    //...SCORE_COMPARE_METRICS.MOVIES_SERIES,
    //...SCORE_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.ALL,
    ...DATA_COMPARE_METRICS.MOVIES_SERIES,
  ],
};

export const CompareContainer = ({
  vertical,
  ipIds,
  dateRange,
  weightsObject,
  onParametersChanged,
}) => {

  const [properties, setProperties] = useState({});

  useEffect(() => {
    setProperties({
      metricKeys: verticalMetricsMap[vertical]
        .map((metric) => metric.key),
      activeMetricKey: properties.activeMetricKey ?? "global_wiki_page_views",
      parameters: [
        {
          dateRange: {
            startDate: dateRange?.startDate,
            endDate: dateRange?.endDate,
          },
          titleIds: ipIds,
        },
      ],
    });
  }, [vertical, ipIds, dateRange]);

  return (
    <SplitPanel
      header="Compare Titles"
      i18nStrings={{
        preferencesTitle: "Preferences",
        preferencesPositionLabel: "Panel position",
        preferencesPositionDescription:
          "Choose the default position for the split panel.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesCancel: "Cancel",
        preferencesConfirm: "Confirm",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
    >
      <div className="mb-4">
        <DatapointComparison
          metrics={verticalMetricsMap[vertical]}
          properties={properties}
          setProperties={setProperties}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            parameters: {
              enabled: true,
              variant: "tokens",
            },
            chart: {
              height: 400,
            },
            summary: {
              enabled: false,
            },
          }}
          onParametersChanged={onParametersChanged}
        />
      </div>
    </SplitPanel>
  );
};
