import { useMutation } from "react-query";
import { putVideosToIP, PutVideosToIPParamsType } from "../request";

export const useUpdateVideos = () => {
  const { isLoading, mutateAsync, error } = useMutation(
    (params: PutVideosToIPParamsType) => putVideosToIP(params),
  );

  return {
    mutateAsync,
    isLoading,
    error,
  };
};
