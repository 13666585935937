import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchRedditRaw = createAsyncThunk(
  "redditRawData/fetchRedditRaw",

  async ({ lte, gte, interval }) => {
    const response = await axiosInstance.request({
      url: `reddit/raw`,
      method: `GET`,
      params: {
        lte,
        gte,
        interval,
      },
    });
    return response;
  }
);

const redditRawDataSlice = createSlice({
  name: "redditRawData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRedditRaw.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRedditRaw.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
      })
      .addCase(fetchRedditRaw.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const RedditRawDataReducer = redditRawDataSlice.reducer;

export default RedditRawDataReducer;

export const redditRawDataAction = redditRawDataSlice.actions;
