export const rawNameToSlug = (rawName) => {
    return rawName.replaceAll(" ", "-").toLowerCase();
};
export const rawNameToTitle = (rawName) => {
    return rawName.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
};
export const sluggedNameToRaw = (sluggedName) => {
    return sluggedName.replaceAll("-", " ").toLowerCase();
};
export const sluggedNameToTitle = (sluggedName) => {
    return rawNameToTitle(sluggedNameToRaw(sluggedName));
};
export const sanitizeAgKey = (key) => {
    return key.replace(/[^a-zA-Z0-9]/g, "");
};

export const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, '');
    str = str.toLowerCase();
  
    const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    const to   = "aaaaeeeeiiiioooouuuunc------";
    for (let i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '').replace(/\s+/g, '-').replace(/-+/g, '-');

    return str;
}