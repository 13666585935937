import { useCallback } from 'react';

export const useContextMenuItemsConfig = () => {
    const getContextMenuItems = useCallback((params) => {
        const result = [
            "autoSizeAll",
            "resetColumns",
            "separator",
            "copy",
            "copyWithHeaders",
            "copyWithGroupHeaders",
            "separator",
            "chartRange",
            "separator",
            "export",
        ];
        return result;
    }, []);

    return getContextMenuItems;
};
