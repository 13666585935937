import { Box, ButtonDropdown, Icon } from "@cloudscape-design/components";
import React, { FC } from "react";

interface TrendsActionsProps {
  downloadChart?: () => void;
  downloadAsCsv?: () => void;
  downloadAsExcel?: () => void;
}

export const axisTypeOptions = [
  { value: "number", label: "Linear" },
  { value: "log", label: "Logarithmic" },
];

export const TrendsActions: FC<TrendsActionsProps> = ({
  downloadChart,
  downloadAsCsv,
  downloadAsExcel,
}) => {
  return (
    <Box float="right">
      <div className="flex space-x-4 items-center">
        <ButtonDropdown
          variant="icon"
          items={[
            { text: "Chart as PNG", id: "download_image" },
            { text: "Series data as CSV", id: "download_data_csv" },
            { text: "Series data as XLSX", id: "download_data_xlsx" },
          ]}
          onItemClick={({ detail }) => {
            switch (detail.id) {
              case "download_image":
                downloadChart();
                break;
              case "download_data_csv":
                downloadAsCsv();
                break;
              case "download_data_xlsx":
                downloadAsExcel();
                break;
            }
          }}
        >
          <Icon name="download" />
        </ButtonDropdown>
      </div>
    </Box>
  );
};
