import { createSlice } from "@reduxjs/toolkit";


const SettingsSlice = createSlice({
    name: "settings",
    initialState: {
        theme: 'dark'
    },
    reducers: {
        updateTheme: (state, action) => {
            state.theme = action.payload;
        }
    }
});

export default SettingsSlice.reducer;

export const SettingsActions = SettingsSlice.actions;

