import React from "react";
import { ContentLayout, Header, Tabs, ButtonDropdown, SpaceBetween } from "@cloudscape-design/components";
import CustomFlashBar from "../common/CustomFlashBar";
import { Layout } from "./Layout";
import { navItems } from "../../layouts/common/menu/side-menu";
import GenreTrendsCharts from "./components/GenreTrendsCharts";
import TopGenresTable from "./components/TopGenresTable";
import { PlatformRanking } from "./components/PlatformRanking";

const GenreDashboard = () => {

  const title = "Genres";
  const breadcrumbs = [
    { text: "Dashboards" },
    { text: title },
  ];
  const [ activeTabId, setActiveTabId ] = React.useState("trends");

  return (
    <Layout
      title="Genre Dashboard"
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      contentType="table"
      content={
        <ContentLayout
          header={
            <>
              <Header
                variant="h2"
              >
                {title}
              </Header>
              <CustomFlashBar />
            </>
          }
        >
          <Tabs
            onChange={({ detail }) =>
              setActiveTabId(detail.activeTabId)
            }
            activeTabId={activeTabId}
            fitHeight
            tabs={[
              {
                label: "Trends",
                id: "trends",
                content: (
                  <SpaceBetween size="m" direction="vertical">
                    <GenreTrendsCharts />
                    <TopGenresTable />
                  </SpaceBetween>
                ),
                action: (
                  <ButtonDropdown
                    variant="icon"
                    items={[
                      { id: "1", text: "Set as primary", disabled: true},
                    ]}
                    expandToViewport={true}
                  />
                )
              },
              {
                label: "Rankings",
                id: "rankings",
                content: (
                  <SpaceBetween size="m" direction="vertical">
                    <PlatformRanking />
                  </SpaceBetween>
                ),
                action: (
                  <ButtonDropdown
                    variant="icon"
                    items={[
                      { id: "1", text: "Set as primary", disabled: true},
                    ]}
                    expandToViewport={true}
                  />
                )
              },
            ]}
          />
         
        </ContentLayout>
      }
    />
  );
};

export default GenreDashboard;