import React, { FC } from "react";
import classnames from "classnames";

interface RatingPercentProps {
  percent: number;
  title: string;
  subtitle: string;
}

const RatingPercent: FC<RatingPercentProps> = ({
  percent = 75,
  title,
  subtitle,
}) => {
  let color = "green";
  if (percent <= 33) {
    color = "red";
  } else if (percent <= 66) {
    color = "yellow";
  }
  return (
    <div className="flex justify-center space-x-2">
      <div className="flex space-x-2 items-center">
        <div className="circle-wrap">
          <div className="circle">
            <div
              className="mask full"
              style={{
                transform: `rotate(${Math.round(
                  (percent / 100.0) * 180.0,
                )}deg)`,
              }}
            >
              <div
                style={{
                  transform: `rotate(${Math.round(
                    (percent / 100.0) * 180.0,
                  )}deg)`,
                  backgroundColor: color,
                }}
                className={classnames("fill", {})}
              ></div>
            </div>
            <div className="mask half">
              <div
                className="fill"
                style={{
                  transform: `rotate(${Math.round(
                    (percent / 100.0) * 180.0,
                  )}deg)`,
                  backgroundColor: color,
                }}
              ></div>
            </div>
            <div className="absolute flex w-10 h-10 mt-[5px] ml-[5px] z-100 rounded-full items-center text-center justify-center font-bold text-lg bg-white dark:bg-appBg">
              {Math.round(percent) / 10}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="text-sm font-bold">{title}</div>
        <div className="text-xs italic dark:text-slate-400">{subtitle}</div>
      </div>
    </div>
  );
};

export default RatingPercent;
