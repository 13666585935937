import { CATEGORIES, categoryOptions } from "../platforms/utils/categoryUtils";

export const GENRE_TYPES = {
  GENRE: "genre",
  CUSTOM_TAG: "custom_tag",
  GAME_THEME: "game_theme",
};

export const TYPE_LABELS = {
  [GENRE_TYPES.GENRE]: "Genre",
  [GENRE_TYPES.CUSTOM_TAG]: "Custom Tag",
  [GENRE_TYPES.GAME_THEME]: "Game Theme",
};

const genreCategories = [
  CATEGORIES.MOVIES,
  CATEGORIES.SERIES,
  CATEGORIES.GAMING,
];

export const genreCategoryOptions = categoryOptions.filter((option) =>
  genreCategories.includes(option.value),
);

export const defaultCategory = CATEGORIES.MOVIES;