import "ag-grid-enterprise";
import { useCallback, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import CustomLoadingOverlay from "../../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../../hooks/UseTheme/useGetGridTheme";
import { Link } from "@cloudscape-design/components";
import shortenInteger from "../../../../../utils/shortenInteger";

const flattenData = (data) => {
    return data.flatMap((distributorData) =>
        distributorData.movies.map((movie) => ({
            distributor: distributorData.distributor,
            title: movie.ip,
            ip_id: movie.ip_id,
            release_date: movie.release_date,
            budget: movie.Budget,
            domestic: movie.Domestic,
            worldwide: movie.Worldwide,
            international: movie.International,
        }))
    );
};

const percentageAggFunc = (params, field) => {
    let totalValue = 0;
    let totalWorldwide = 0;
    params.rowNode.childrenAfterGroup.forEach((childNode) => {
        if (childNode.data) {
            totalValue += childNode.data[field] || 0;
            totalWorldwide += childNode.data.worldwide || 0;
        }
    });
    if (totalWorldwide !== 0) {
        return totalValue / totalWorldwide;
    }
    return 0;
};

export const BoStudioMovieGrid = ({ data, isLoading, error, filterText }) => {
    const popupParent = useMemo(() => {
        return document.body;
    }, []);

    const { theme } = useGetGridTheme();
    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        suppressHeaderMenuButton: true,
        suppressHeaderFilterButton: true,
    };

    const columnDefs = [
        { field: "distributor", rowGroup: true, hide: true, filter: false },
        // {
        //     headerName: "Distributor",
        //     cellRenderer: "agGroupCellRenderer",
        //     showRowGroup: "distributor",
        //     minWidth: 100,
        // },
        {
            headerName: "Title",
            field: "title",
            minWidth: 200,
            flex: 1,
            cellRenderer: (params) => {
                const ipId = params.data?.ip_id;
                const destination = `/item/${ipId}`;
                return (
                    <Link variant="secondary" href={destination} target="_blank" onClick={(e) => !ipId && e.preventDefault()}>
                        {params.value}
                    </Link>
                );
            }
        },
        {
            headerName: "Release Date",
            field: "release_date",
            maxWidth: 150,
        },
        {
            headerName: "Budget",
            field: "budget",
            cellRenderer: (params) => shortenInteger(params.value),
            maxWidth: 150,
            aggFunc: "sum",
        },
        {
            headerName: "Domestic",
            field: "domestic",
            maxWidth: 150,
            cellRenderer: (params) => shortenInteger(params.value),
            aggFunc: "sum",

        },
        {
            headerName: "Domestic %",
            field: "domestic_percentage",
            maxWidth: 150,
            valueGetter: (params) => {
                return params.data?.domestic / params.data?.worldwide;
            },
            cellRenderer: (params) => {
                const value = params.value !== undefined ? params.value * 100 : 0;
                return `${value.toFixed(1)}%`;
            },
            aggFunc: (params) => percentageAggFunc(params, "domestic"),
        },
        {
            headerName: "Foreign",
            field: "international",
            maxWidth: 150,
            cellRenderer: (params) => shortenInteger(params.value),
            aggFunc: "sum",
        },
        {
            headerName: "Foreign %",
            field: "foreign_percentage",
            maxWidth: 150,
            valueGetter: (params) => {
                return params.data?.international / params.data?.worldwide;
            },
            cellRenderer: (params) => {
                const value = params.value !== undefined ? params.value * 100 : 0;
                return `${value.toFixed(1)}%`;
            },
            aggFunc: (params) => percentageAggFunc(params, "international"),
        },
        {
            headerName: "Worldwide",
            field: "worldwide",
            sort: 'desc',
            maxWidth: 150,
            cellRenderer: (params) => shortenInteger(params.value),
            aggFunc: "sum",
        },
    ];

    if (isLoading) {
        return (
            <div className="flex flex-row justify-center h-160">
                <CustomLoadingOverlay />
            </div>
        );
    }

    if (error) {
        return <div>Error loading data: {error.message}</div>;
    }

    const rowData = flattenData(data);

    return (
        <div className={theme} style={{ height: 925, width: "100%" }}>
            <AgGridReact
                defaultColDef={defaultColDef}
                quickFilterText={filterText}
                pagination={true}
                paginationPageSize={20}
                columnDefs={columnDefs}
                rowData={rowData}
                groupDisplayType={"singleColumn"}
                groupHideOpenParents={false}
                suppressAggFuncInHeader={true}
            />
        </div>
    );
};

export default BoStudioMovieGrid;
