import { useCallback, useState } from "react";
import { getWeeklyCategoryTrendsData } from "../api/request";
import { YouTubeWeeklyCategoryTrendsResponse } from "../api/types";

let weeklyCategoryTrendsCache: YouTubeWeeklyCategoryTrendsResponse | null =
  null;

export const useFetchWeeklyCategoryData = () => {
  const [weeklyData, setWeeklyData] =
    useState<YouTubeWeeklyCategoryTrendsResponse | null>(
      weeklyCategoryTrendsCache,
    );
  const [loading, setLoading] = useState<boolean>(!weeklyCategoryTrendsCache);
  const [error, setError] = useState<Error | null>(null);

  const fetchWeeklyCategoryData = useCallback(async () => {
    if (weeklyCategoryTrendsCache) return weeklyCategoryTrendsCache;

    setLoading(true);
    setError(null);

    try {
      const response = await getWeeklyCategoryTrendsData();
      setWeeklyData(response);
      weeklyCategoryTrendsCache = response;
      return response;
    } catch (err) {
      setError(
        err instanceof Error
          ? err
          : new Error(
              "An error occurred while fetching weekly category trends data",
            ),
      );
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  return { weeklyData, loading, error, fetchWeeklyCategoryData };
};
