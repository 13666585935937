/* PLEASE DO NOT REFORMAT THIS FILE (breaks spacing in certain areas) */

import {
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  Icon,
  Select,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import { Link } from "react-router-dom";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import {
  DecreaseIcon,
  IncreaseIcon,
  NeutralIcon,
} from "../../../components/Icons";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { RangeDateSelector } from "../../../components/RangeDateSelector";
import { useGetGridTheme } from "../../../hooks/UseTheme/useGetGridTheme";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import { Layout } from "../Layout";
import TopicSearchBar from "../components/TopicSearchBar";
import { addToFavorites, removeFromFavorites } from "./preferences";

const TopicModelingPantheon = () => {
  const title = "General Topics";
  const { theme } = useGetGridTheme();
  const flashbarRef = useRef(null);
  const risingGridRef = useRef(null);
  const favoritesGridRef = useRef(null);

  const domain = "pantheon-reddit";
  const breadcrumbs = [
    { text: "Topic Modeling" },
    { text: "General", href: "/" },
  ];
  const [topicsCategoryOptions, setTopicsCategoryOptions] = useState([
    { label: "All", value: "all" },
    { label: "Movies", value: "movies" },
    { label: "Series", value: "series" },
    { label: "Games", value: "gaming" },
  ]);
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const defaultDateRange = {
    type: "absolute",
    startDate: moment()
      .subtract(1, "month")
      .startOf("day")
      .format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };

  const [selectedTopicCategory, setSelectedTopicCategory] = useState(
    topicsCategoryOptions[0],
  );
  const [topicsDateRange, setTopicsDateRange] = useState(defaultDateRange);
  const [topicsOverTimeResponse, setTopicsOverTimeResponse] =
    useState(defaultResponseState);
  const [risingTopicsRowData, setRisingTopicsRowData] = useState({
    rising: [],
    favorites: [],
  });

  const [selectedTopicNodes, setSelectedTopicNodes] = useState([]);
  const [selectedFavoriteNodes, setSelectedFavoriteNodes] = useState([]);

  const processTopicsOverTimeData = (data) => {
    const risingData = data.rising;
    const topicLabels = data.topic_labels;
    const topicWords = data.topic_words;
    const differences = data.differences;
    if (
      !risingData ||
      risingData.length == 0 ||
      !topicLabels ||
      Object.keys(topicLabels).length == 0
    ) {
      setRisingTopicsRowData({ rising: [], favorites: [] });
      return;
    }

    const topTopics = risingData
      .map((monthData) =>
        monthData.data
          .filter((item) => item.is_rising === true)
          .map((item) => item.topic_id),
      )
      .flat(1)
      .filter((v, i, a) => a.indexOf(v) === i);
    const favTopics = risingData
      .map((monthData) =>
        monthData.data
          .filter((item) => item.is_favorite === true)
          .map((item) => item.topic_id),
      )
      .flat(1)
      .filter((v, i, a) => a.indexOf(v) === i);
    const allTopics = [...new Set([...topTopics, ...favTopics])];
    const countTrends = allTopics.reduce(
      (obj, key) => ((obj[key] = []), obj),
      {},
    );

    risingData.forEach((timePeriod) => {
      const data = timePeriod.data;
      const timestamp = timePeriod.timestamp;
      allTopics.forEach((topicId) => {
        const topicData = data.find((item) => item.topic_id == topicId);
        if (!topicData) {
          countTrends[topicId].push([timestamp, 0]);
        } else {
          countTrends[topicId].push([timestamp, topicData.count]);
        }
      });
    });

    const rowData = allTopics
      .map((topicId) => ({
        topic_id: topicId,
        topic_label: topicLabels[topicId],
        topic_words: topicWords[topicId],
        count: countTrends[topicId].map((x) => x[1]).reduce((a, b) => a + b, 0),
        count_difference: differences[topicId].count_difference,
        trend: countTrends[topicId],
        is_rising: topTopics.includes(topicId),
        is_favorite: favTopics.includes(topicId),
      }))
      .sort((a, b) => b.count_difference - a.count_difference);

    setRisingTopicsRowData({
      rising: rowData.filter((x) => x.is_rising && x.count > 10),
      favorites: rowData.filter((x) => x.is_favorite),
    });
  };

  const getTopicsOverTime = (params) => {
    setTopicsOverTimeResponse({
      ...topicsOverTimeResponse,
      loading: true,
      completed: false,
      error: null,
    });

    axiosInstance
      .request({
        method: "GET",
        url: "/topicmodeling/pantheon/topicsovertime",
        params: {
          domain: domain,
          ...params,
        },
      })
      .then((response) => {
        setTopicsOverTimeResponse({
          ...topicsOverTimeResponse,
          data: response.data,
          loading: false,
          completed: true,
          error: null,
        });
        processTopicsOverTimeData(response.data);
      })
      .catch((error) => {
        setTopicsOverTimeResponse({
          ...topicsOverTimeResponse,
          data: null,
          error: error,
          loading: false,
          completed: true,
        });
        console.log(error);
      });
  };

  const refreshOverview = () => {
    const payload = {
      start_date: moment(topicsDateRange.startDate).format("YYYY-MM-DD"),
      end_date: moment(topicsDateRange.endDate).format("YYYY-MM-DD"),
      n: 30,
      min_prob: 0,
      include_favorites: true,
    };
    if (selectedTopicCategory.value != "all") {
      payload.categories = selectedTopicCategory.value;
    }
    getTopicsOverTime(payload);
  };

  useEffect(() => {
    refreshOverview();
  }, [topicsDateRange, selectedTopicCategory]);

  return (
    <Layout
      title={title}
      content={
        <>
          <ContentLayout
            header={
              <>
                <Header
                  actions={
                    <TopicSearchBar
                      domain={domain}
                      searchApiUrl="/topicmodeling/pantheon/search"
                      topicPageUrl="/topic-modeling/pantheon/topic"
                      limit={10}
                    />
                  }
                >
                  Reddit Topics
                </Header>
                <PantheonFlashbar ref={flashbarRef} />
              </>
            }
          >
            <SpaceBetween size="m">
              <Container>
                <ColumnLayout columns={1} borders="horizontal" disableGutters>
                  <div className="pb-4">
                    <div className="pb-2">
                      <Header
                        description="What people are discussing within movie, series and game subreddits"
                        actions={
                          <SpaceBetween direction="horizontal" size="s">
                            <ButtonDropdown
                              items={[
                                {
                                  text: "Add to favorites",
                                  id: "add-to-favorites",
                                  disabled: selectedTopicNodes.length < 1,
                                },
                              ]}
                              onItemClick={({ detail }) => {
                                switch (detail.id) {
                                  case "add-to-favorites": {
                                    const topicsToAdd = selectedTopicNodes.map(
                                      (node) => node.data.topic_id,
                                    );
                                    const toAdd = {};
                                    if (topicsToAdd.length > 0) {
                                      toAdd.topics = topicsToAdd.join(",");
                                    }
                                    risingGridRef.current.api.deselectAll();
                                    setSelectedTopicNodes([]);

                                    const newRisingTopicsRowData = {
                                      ...risingTopicsRowData,
                                    };
                                    newRisingTopicsRowData.rising =
                                      newRisingTopicsRowData.rising.map((x) => {
                                        if (topicsToAdd.includes(x.topic_id)) {
                                          x.is_favorite = true;
                                        }
                                        return x;
                                      });
                                    newRisingTopicsRowData.favorites = [
                                      ...newRisingTopicsRowData.favorites,
                                      ...risingTopicsRowData.rising.filter(
                                        (x) => topicsToAdd.includes(x.topic_id),
                                      ),
                                    ];
                                    newRisingTopicsRowData.favorites =
                                      newRisingTopicsRowData.favorites.filter(
                                        (v, i, a) =>
                                          a.findIndex(
                                            (t) => t.topic_id === v.topic_id,
                                          ) === i,
                                      );
                                    setRisingTopicsRowData(
                                      newRisingTopicsRowData,
                                    );

                                    addToFavorites(domain, toAdd);
                                    break;
                                  }
                                }
                              }}
                              disabled={selectedTopicNodes.length < 1}
                            >
                              Actions
                            </ButtonDropdown>
                            <Select
                              selectedOption={selectedTopicCategory}
                              onChange={({ detail }) =>
                                setSelectedTopicCategory(detail.selectedOption)
                              }
                              options={topicsCategoryOptions}
                            />
                            <RangeDateSelector
                              defaults={topicsDateRange}
                              onChange={(e) => setTopicsDateRange(e)}
                            />
                          </SpaceBetween>
                        }
                      >
                        Overview
                      </Header>
                    </div>
                    {topicsOverTimeResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div>
                    ) : (
                      <div
                        style={{ height: "580px", width: "100%" }}
                        className={theme}
                      >
                        <AgGridReact
                          ref={risingGridRef}
                          rowData={risingTopicsRowData.rising}
                          rowSelection="multiple"
                          suppressRowClickSelection={true}
                          onSelectionChanged={(e) => {
                            setSelectedTopicNodes(e.api.getSelectedNodes());
                          }}
                          columnDefs={[
                            {
                              field: "topic_label",
                              headerName: "Topic",
                              cellRenderer: (props) => (
                                <div className="flex space-x-2 items-center">
                                  <div className="w-6">
                                    <Button
                                      ariaLabel={
                                        props.data.is_favorite
                                          ? "Remove from favorites"
                                          : "Add to favorites"
                                      }
                                      iconName={
                                        props.data.is_favorite
                                          ? "star-filled"
                                          : "star"
                                      }
                                      variant="inline-icon"
                                      onClick={() => {
                                        const newRisingTopicsRowData = {
                                          ...risingTopicsRowData,
                                        };
                                        const isFavorite =
                                          props.data.is_favorite;
                                        newRisingTopicsRowData.rising.find(
                                          (x) =>
                                            x.topic_id == props.data.topic_id,
                                        ).is_favorite = !props.data.is_favorite;
                                        if (isFavorite) {
                                          removeFromFavorites(domain, {
                                            topics: props.data.topic_id,
                                          });
                                          newRisingTopicsRowData.favorites =
                                            newRisingTopicsRowData.favorites.filter(
                                              (x) =>
                                                x.topic_id !=
                                                props.data.topic_id,
                                            );
                                        } else {
                                          addToFavorites(domain, {
                                            topics: props.data.topic_id,
                                          });
                                          newRisingTopicsRowData.favorites = [
                                            ...newRisingTopicsRowData.favorites,
                                            props.data,
                                          ];
                                        }
                                        setRisingTopicsRowData(
                                          newRisingTopicsRowData,
                                        );
                                      }}
                                    />
                                  </div>
                                  <div className="flex flex-col leading-tight whitespace-nowrap">
                                    <Link
                                      to={`/topic-modeling/pantheon/topic/${props.data.topic_id}`}
                                    >
                                      {props.value}
                                    </Link>
                                    <div className="text-slate-500 text-xs">
                                      {props.data.topic_words
                                        .map((word) => word.word)
                                        .join(", ")}
                                    </div>
                                  </div>
                                </div>
                              ),
                              flex: 1,
                              headerCheckboxSelection: true,
                              checkboxSelection: true,
                              showDisabledCheckboxes: true,
                            },
                            {
                              field: "count",
                              headerName: "Posts",
                              type: "numericColumn",
                              maxWidth: 120,
                              minWidth: 120,
                              cellRenderer: (props) => (
                                <div
                                  className="space-x-2"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "right",
                                  }}
                                >
                                  <span className="ml-2 inline-flex items-center justify-center rounded-md bg-slate-400/10 text-xs font-mono text-slate-400 px-2">
                                    {props.data.count_difference > 0 ? (
                                      <Icon
                                        svg={<IncreaseIcon />}
                                        variant="success"
                                      />
                                    ) : props.data.count_difference < 0 ? (
                                      <Icon
                                        svg={<DecreaseIcon />}
                                        variant="error"
                                      />
                                    ) : (
                                      <Icon
                                        svg={<NeutralIcon />}
                                        variant="subtle"
                                      />
                                    )}
                                    <span style={{ width: "3px" }}></span>
                                    {props.data.count_difference.toLocaleString()}
                                  </span>
                                  <span>{props.value.toLocaleString()}</span>
                                </div>
                              ),
                            },
                            {
                              field: "trend",
                              headerName: "Trend",
                              cellRenderer: "agSparklineCellRenderer",
                              maxWidth: 300,
                              minWidth: 300,
                              cellRendererParams: {
                                sparklineOptions: {
                                  tooltip: {
                                    renderer: (params) => {
                                      return {
                                        title: new Date(
                                          Date.parse(params.xValue),
                                        ).toLocaleDateString("en-US", {
                                          year: "numeric",
                                          month: "short",
                                          day: "numeric",
                                          timeZone: "UTC",
                                        }),
                                        content: params.yValue,
                                      };
                                    },
                                  },
                                  marker: {
                                    formatter: (params) => {
                                      const { min, max } = params;
                                      return {
                                        size: max ? 5 : 2,
                                        fill: max ? "#3ba272" : "skyBlue",
                                        stroke: max ? "#3ba272" : "skyBlue",
                                      };
                                    },
                                  },
                                },
                              },
                            },
                          ]}
                        />
                      </div>
                    )}
                  </div>
                  {topicsOverTimeResponse?.completed &&
                    risingTopicsRowData.favorites.length > 0 && (
                      <div className="pt-4">
                        <div className="pb-2">
                          <Header
                            description="Topics you favorite will appear here"
                            actions={
                              <SpaceBetween direction="horizontal" size="s">
                                <ButtonDropdown
                                  items={[
                                    {
                                      text: "Remove from favorites",
                                      id: "remove-from-favorites",
                                      disabled:
                                        selectedFavoriteNodes.length < 1,
                                    },
                                  ]}
                                  onItemClick={({ detail }) => {
                                    switch (detail.id) {
                                      case "remove-from-favorites": {
                                        const topicsToRemove =
                                          selectedFavoriteNodes.map(
                                            (node) => node.data.topic_id,
                                          );
                                        const toRemove = {};
                                        if (topicsToRemove.length > 0) {
                                          toRemove.topics =
                                            topicsToRemove.join(",");
                                        }
                                        favoritesGridRef.current.api.deselectAll();
                                        setSelectedFavoriteNodes([]);

                                        const newRisingTopicsRowData = {
                                          ...risingTopicsRowData,
                                        };
                                        newRisingTopicsRowData.rising =
                                          newRisingTopicsRowData.rising.map(
                                            (x) => {
                                              if (
                                                topicsToRemove.includes(
                                                  x.topic_id,
                                                )
                                              ) {
                                                x.is_favorite = false;
                                              }
                                              return x;
                                            },
                                          );
                                        newRisingTopicsRowData.favorites =
                                          newRisingTopicsRowData.favorites.filter(
                                            (x) =>
                                              !topicsToRemove.includes(
                                                x.topic_id,
                                              ),
                                          );
                                        newRisingTopicsRowData.favorites =
                                          newRisingTopicsRowData.favorites.filter(
                                            (v, i, a) =>
                                              a.findIndex(
                                                (t) =>
                                                  t.topic_id === v.topic_id,
                                              ) === i,
                                          );
                                        setRisingTopicsRowData(
                                          newRisingTopicsRowData,
                                        );

                                        removeFromFavorites(domain, toRemove);
                                        break;
                                      }
                                    }
                                  }}
                                  disabled={selectedFavoriteNodes.length < 1}
                                >
                                  Actions
                                </ButtonDropdown>
                              </SpaceBetween>
                            }
                          >
                            Favorites
                          </Header>
                        </div>
                        {topicsOverTimeResponse?.loading ? (
                          <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                            <Spinner size="large" />
                          </div>
                        ) : (
                          <div
                            style={{ height: "580px", width: "100%" }}
                            className={theme}
                          >
                            <AgGridReact
                              ref={favoritesGridRef}
                              rowData={risingTopicsRowData.favorites}
                              rowSelection="multiple"
                              suppressRowClickSelection={true}
                              onSelectionChanged={(e) => {
                                setSelectedFavoriteNodes(
                                  e.api.getSelectedNodes(),
                                );
                              }}
                              columnDefs={[
                                {
                                  field: "topic_label",
                                  headerName: "Topic",
                                  cellRenderer: (props) => (
                                    <Link
                                      to={`/topic-modeling/pantheon/topic/${props.data.topic_id}`}
                                    >
                                      {props.value}
                                    </Link>
                                  ),
                                  flex: 1,
                                  headerCheckboxSelection: true,
                                  checkboxSelection: true,
                                  showDisabledCheckboxes: true,
                                },
                                {
                                  field: "count",
                                  headerName: "Posts",
                                  type: "numericColumn",
                                  maxWidth: 120,
                                  minWidth: 120,
                                  cellRenderer: (props) => (
                                    <div
                                      className="space-x-2"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "right",
                                      }}
                                    >
                                      <span className="ml-2 inline-flex items-center justify-center rounded-md bg-slate-400/10 text-xs font-mono text-slate-400 px-2">
                                        {props.data.count_difference > 0 ? (
                                          <Icon
                                            svg={<IncreaseIcon />}
                                            variant="success"
                                          />
                                        ) : props.data.count_difference < 0 ? (
                                          <Icon
                                            svg={<DecreaseIcon />}
                                            variant="error"
                                          />
                                        ) : (
                                          <Icon
                                            svg={<NeutralIcon />}
                                            variant="subtle"
                                          />
                                        )}
                                        <span style={{ width: "3px" }}></span>
                                        {props.data.count_difference.toLocaleString()}
                                      </span>
                                      <span>
                                        {props.value.toLocaleString()}
                                      </span>
                                    </div>
                                  ),
                                },
                                {
                                  field: "trend",
                                  headerName: "Trend",
                                  cellRenderer: "agSparklineCellRenderer",
                                  maxWidth: 300,
                                  minWidth: 300,
                                  cellRendererParams: {
                                    sparklineOptions: {
                                      tooltip: {
                                        renderer: (params) => {
                                          return {
                                            title: new Date(
                                              Date.parse(params.xValue),
                                            ).toLocaleDateString("en-US", {
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                              timeZone: "UTC",
                                            }),
                                            content: params.yValue,
                                          };
                                        },
                                      },
                                      marker: {
                                        formatter: (params) => {
                                          const { min, max } = params;
                                          return {
                                            size: max ? 5 : 2,
                                            fill: max ? "#3ba272" : "skyBlue",
                                            stroke: max ? "#3ba272" : "skyBlue",
                                          };
                                        },
                                      },
                                    },
                                  },
                                },
                              ]}
                            />
                          </div>
                        )}
                      </div>
                    )}
                </ColumnLayout>
              </Container>
            </SpaceBetween>
          </ContentLayout>
        </>
      }
      navItems={navItems}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default TopicModelingPantheon;
