import { PLATFORMS, DATAPOINTS } from "../../../../../../config-global";
import { METRIC_GROUPS } from "../../../../../../components/datapoint-comparison/constants";

export const SCORE_COMPARE_METRICS = {
  ALL: [
    {
      _platform: PLATFORMS.WIKIPEDIA,
      name: "Wikipedia Z-Score",
      key: "global_wiki_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.WIKIPEDIA,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE,
          weight: 1.0,
        },
      ],
    },
    {
      _platform: PLATFORMS.YOUTUBE,
      name: "YouTube Z-Score",
      key: "global_yt_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.YOUTUBE,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.YOUTUBE.ZSCORE,
          weight: 1.0,
        },
      ],
    },
  ],
  MOVIES_SERIES: [
    {
      name: "Weighted Score",
      key: "weighted_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      hideInSummary: true,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.WIKIPEDIA,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE,
          weight: 1.0,
        },
        {
          _platform: PLATFORMS.YOUTUBE,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.YOUTUBE.ZSCORE,
          weight: 1.0,
        },
        {
          _platform: PLATFORMS.IMDB,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.IMDB.ZSCORE,
          weight: 1.0,
        },
        {
          _platform: PLATFORMS.PIRACY,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.PIRACY.ZSCORE,
          weight: 1.0,
        },
        {
          _platform: PLATFORMS.ROTTEN_TOMATOES,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE,
          weight: 1.0,
        },
      ],
    },
    {
      _platform: PLATFORMS.IMDB,
      name: "IMDb Z-Score",
      key: "global_imdb_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.IMDB,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.IMDB.ZSCORE,
          weight: 1.0,
        },
      ],
    },
    {
      _platform: PLATFORMS.PIRACY,
      name: "Piracy Z-Score",
      key: "global_piracy_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.PIRACY,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.PIRACY.ZSCORE,
          weight: 1.0,
        },
      ],
    },
    {
      _platform: PLATFORMS.ROTTEN_TOMATOES,
      name: "Rotten Tomatoes Z-Score",
      key: "global_rt_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.ROTTEN_TOMATOES,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.ZSCORE,
          weight: 1.0,
        },
      ],
    },
  ],
  GAMES: [
    {
      name: "Weighted Score",
      key: "weighted_score",
      group: METRIC_GROUPS.ZSCORE,
      hideInSummary: true,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.WIKIPEDIA,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.WIKIPEDIA.ZSCORE,
          weight: 1.0,
        },
        {
          _platform: PLATFORMS.YOUTUBE,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.YOUTUBE.ZSCORE,
          weight: 1.0,
        },
        {
          _platform: PLATFORMS.STEAM,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.STEAM.ZSCORE,
          weight: 1.0,
        },
        {
          _platform: PLATFORMS.TWITCH,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.TWITCH.ZSCORE,
          weight: 1.0,
        },
      ],
    },
    {
      _platform: PLATFORMS.STEAM,
      name: "Steam Z-Score",
      key: "global_steam_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.STEAM,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.STEAM.ZSCORE,
          weight: 1.0,
        },
      ],
    },
    {
      _platform: PLATFORMS.TWITCH,
      name: "Twitch Z-Score",
      key: "global_twitch_score",
      group: METRIC_GROUPS.ZSCORE,
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          _platform: PLATFORMS.TWITCH,
          platform: PLATFORMS.PANTHEON_GLOBAL,
          datapoint: DATAPOINTS.TWITCH.ZSCORE,
          weight: 1.0,
        },
      ],
    },
  ],
};

export const DATA_COMPARE_METRICS = {
  ALL: [
    {
      name: "Wikipedia Page Views",
      key: "global_wiki_page_views",
      group: METRIC_GROUPS.PLATFORM,
      unit: "views",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_WIKIPEDIA,
          datapoint: DATAPOINTS.WIKIPEDIA.PAGE_VIEWS,
          weight: 1.0,
        },
      ],
    },
    {
      name: "YouTube Views",
      key: "global_yt_views",
      group: METRIC_GROUPS.PLATFORM,
      unit: "views",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_YOUTUBE,
          datapoint: DATAPOINTS.YOUTUBE.VIEWS,
          weight: 1.0,
          difference: true,
        },
      ],
    },
    {
      name: "YouTube Likes",
      key: "global_yt_likes",
      group: METRIC_GROUPS.PLATFORM,
      unit: "likes",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_YOUTUBE,
          datapoint: DATAPOINTS.YOUTUBE.LIKES,
          weight: 1.0,
          difference: true,
        },
      ],
    },
    {
      name: "YouTube Comments",
      key: "global_yt_comments",
      group: METRIC_GROUPS.PLATFORM,
      unit: "comments",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_YOUTUBE,
          datapoint: DATAPOINTS.YOUTUBE.COMMENTS,
          weight: 1.0,
          difference: true,
        },
      ],
    },
  ],
  MOVIES_SERIES: [
    {
      name: "IMDb Votes",
      key: "global_imdb_votes",
      group: METRIC_GROUPS.PLATFORM,
      unit: "votes",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_IMDB,
          datapoint: DATAPOINTS.IMDB.VOTES,
          weight: 1.0,
        },
      ],
    },
    {
      name: "IMDb Rating",
      key: "global_imdb_rating",
      group: METRIC_GROUPS.PLATFORM,
      unit: "rating",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_IMDB,
          datapoint: DATAPOINTS.IMDB.RATING,
          weight: 1.0,
        },
      ],
    },
    {
      name: "Rotten Tomatoes Audience Rating",
      key: "global_rt_audience_rating",
      group: METRIC_GROUPS.PLATFORM,
      unit: "rating",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES,
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.AUDIENCE_RATING,
          weight: 1.0,
        },
      ],
    },
    {
      name: "Rotten Tomatoes Audience Votes",
      key: "global_rt_audience_votes",
      group: METRIC_GROUPS.PLATFORM,
      unit: "votes",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_ROTTEN_TOMATOES,
          datapoint: DATAPOINTS.ROTTEN_TOMATOES.AUDIENCE_VOTES,
          weight: 1.0,
        },
      ],
    },
    {
      name: "Piracy Downloads",
      key: "piracy_downloads",
      group: METRIC_GROUPS.PLATFORM,
      unit: "downloads",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.PIRACY,
          datapoint: DATAPOINTS.PIRACY.DOWNLOADS,
          weight: 1.0,
        },
      ],
    },
  ],
  GAMES: [
    {
      name: "Steam Players",
      key: "global_steam_ccu",
      group: METRIC_GROUPS.PLATFORM,
      unit: "players",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_STEAM,
          datapoint: DATAPOINTS.STEAM.PLAYER_COUNT,
          weight: 1.0,
        },
      ],
    },
    {
      name: "Twitch Viewers",
      key: "global_twitch_viewers",
      group: METRIC_GROUPS.PLATFORM,
      unit: "viewers",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_TWITCH,
          datapoint: DATAPOINTS.TWITCH.VIEWER_COUNT,
          weight: 1.0,
        },
      ],
    },
    {
      name: "Twitch Streamers",
      key: "global_twitch_streamers",
      group: METRIC_GROUPS.PLATFORM,
      unit: "streamers",
      normalize: false,
      decimalDigits: 0,
      datapoints: [
        {
          platform: PLATFORMS.GLOBAL_TWITCH,
          datapoint: DATAPOINTS.TWITCH.STREAMER_COUNT,
          weight: 1.0,
        },
      ],
    },
  ],
};
