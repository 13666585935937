import { useEffect, useState } from "react";
import axiosInstance from "../../../../utils/axios";
import { useSearchParams } from "react-router-dom";

const conditionMaker = (condition, isExclude = false) => {
  if (!condition.propertyKey) condition.propertyKey = "name";
  switch (condition.operator) {
    case "=":
    case "!=":
      if (condition.propertyKey === "name") {
        return {
          match: { [`${condition.propertyKey}.keyword`]: condition.value },
        };
      }
      return { match: { [condition.propertyKey]: condition.value } };
    case ":":
      return {
        query_string: {
          default_field: condition.propertyKey,
          query: `${condition.value}`,
        },
      };

    case "<":
      return { range: { [condition.propertyKey]: { lt: condition.value } } };

    case ">":
      return { range: { [condition.propertyKey]: { gt: condition.value } } };
  }
};

export function useItemPaginationCallback(params = {}) {
  const [apiCount, setApiCount] = useState(0);
  const { pageSize, currentPageIndex } = params.pagination || {};
  const { sortingDescending, sortingColumn } = params.sorting || {};
  const { filteringText, filteringTokens, filteringOperation } =
    params.filtering || {};
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [flashbarItems, setFlashbarItems] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { loaded } = params || false;

  const setUrlParams = (params) => {
    if (params == null) {
      setSearchParams({});
    } else {
      let newSearchParams = {};
      if (params.filteringTokens.length > 0) {
        newSearchParams.q = JSON.stringify(params.filteringTokens);
      }
      if (params.currentPageIndex > 1) {
        newSearchParams.page = params.currentPageIndex;
      }
      if (params.filteringOperation && params.filteringOperation != "and") {
        newSearchParams.operation = params.filteringOperation;
      }
      if (params.sortingColumn) {
        newSearchParams.sort = params.sortingColumn;
        newSearchParams.desc = params.sortingDescending;
      }
      setSearchParams(newSearchParams);
    }
  };

  const fields = [
    "id",
    "name",
    "type",
    "overview",
    "summary",
    "first_release_date",
    "first_air_date",
    "release_date",
    "poster_path",
    "cover_image_id",
  ];

  useEffect(() => {
    setLoading(true);
    if (!loaded) return;
    const params = {
      filteringText,
      filteringTokens,
      filteringOperation,
      pageSize,
      currentPageIndex,
      sortingDescending,
      ...(sortingColumn
        ? {
            sortingColumn:
              sortingColumn.sortingField || sortingColumn.sortingComparator,
          }
        : {}),
    };

    let requestParams = null;

    if (
      (filteringTokens && filteringTokens.length > 0) ||
      sortingColumn.sortingField ||
      currentPageIndex > 1
    ) {
      let includeCondition = [];
      let excludeCondition = [];

      filteringTokens.forEach((condition) => {
        if (condition.operator === "=" || condition.operator === ":") {
          includeCondition.push(conditionMaker(condition));
        } else if (condition.operator === "!=") {
          excludeCondition.push(conditionMaker(condition, true));
        }
        if (condition.operator === "<" || condition.operator === ">") {
          includeCondition.push(conditionMaker(condition));
        }
      });

      let payload = {
        include: includeCondition,
        exclude: excludeCondition,
        condition: params.filteringOperation || "and",
      };

      if (sortingColumn.sortingField) {
        payload.sort = {
          field: sortingColumn.sortingField,
          order: sortingDescending ? "desc" : "asc",
        };
      }

      requestParams = {
        method: "POST",
        maxBodyLength: Infinity,
        headers: {
          "Content-Type": "application/json",
        },
        url: `/ipmanagement/search?size=${params.pageSize}&after=${
          params.pageSize * (params.currentPageIndex - 1)
        }&include_count=1&fields=${fields.join(",")}`,
        data: JSON.stringify(payload),
      };
      setUrlParams(params);
    } else {
      requestParams = {
        method: "GET",
        url: `/ipmanagement/search?size=${params.pageSize}&after=${
          params.pageSize * (params.currentPageIndex - 1)
        }&include_count=1&fields=${fields.join(",")}`,
      };
      setUrlParams(null);
    }
    axiosInstance
      .request(requestParams)
      .then((result) => {
        setApiCount(result.data.count);

        setPagesCount(Math.ceil(result.data.count / params.pageSize));
        setItems(
          result.data.data.map((item, index) => ({
            ...item,
            id: item.id,
            name: item.name,
          })),
        );
        setLoading(false);

        if (totalCount === 0) {
          setTotalCount(result.data.count);
        }
      })
      .catch((e) => setLoading(false));
  }, [
    pageSize,
    sortingDescending,
    sortingColumn,
    currentPageIndex,
    filteringText,
    filteringTokens,
    filteringOperation,
    loaded,
  ]);

  return {
    items,
    loading,
    totalCount,
    pagesCount,
    currentPageIndex,
    apiCount,
    flashbarItems,
    sortingDescending,
    sortingColumn,
  };
}
