export const genreMapping = {
    Drama: ["Movies", "Television"],
    Comedy: ["Movies", "Television"],
    Adventure: ["Gaming", "Movies", "Television"],
    Thriller: ["Movies", "Television"],
    Documentary: ["Movies", "Television"],
    Action: ["Movies", "Television"],
    Crime: ["Movies", "Television"],
    Romance: ["Movies", "Television"],
    Family: ["Movies", "Television"],
    Horror: ["Movies", "Television"],
    'Sci-Fi': ["Movies", "Television"],
    Mystery: ["Movies", "Television"],
    Fantasy: ["Movies", "Television"],
    Animation: ["Movies", "Television"],
    Sport: ["Gaming", "Movies", "Television"],
    Strategy: ["Gaming"],
    Shooter: ["Gaming"],
    "Role-playing (RPG)": ["Gaming"],
    Simulator: ["Gaming"],
    Indie: ["Gaming"],
    Music: ["Movies", "Television", "Gaming"],
    Biography: ["Movies", "Television"],
    Puzzle: ["Gaming"],
    Platform: ["Gaming"],
    History: ["Movies", "Television"],
    "Reality-TV": ["Television", "Movies"],
    Short: ["Movies", "Television"],
    Arcade: ["Gaming"],
    Racing: ["Gaming"],
    War: ["Movies", "Television"],
    Fighting: ["Gaming"],
    Musical: ["Movies", "Television"],
    "Hack and slash/Beat 'em up": ["Gaming"],
    "Turn-based strategy (TBS)": ["Gaming"],
    "Real Time Strategy (RTS)": ["Gaming"],
    Tactical: ["Gaming"],
    "Game-Show": ["Television", "Movies"],
    "Point-and-click": ["Gaming"],
    Western: ["Movies", "Television"],
    "Talk-Show": ["Television", "Movies"],
    "Visual Novel": ["Gaming"],
    News: ["Movies", "Television"],
    "Card & Board Game": ["Gaming"],
    "Quiz/Trivia": ["Gaming"],
    Pinball: ["Gaming"],
    MOBA: ["Gaming"],
    Adult: ["Movies", "Television"],
};
