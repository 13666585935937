import Header from "./header/Header";
import { Outlet } from "react-router-dom";

export default function CommonLayout({ title = "" }) {
  return (
    <>
      <div className="flex flex-1 flex-col">
        <Header title={title} />
        <Outlet />
      </div>
    </>
  );
}
