import { SERVICES, TYPES } from "../../../config-global";
import { CATEGORIES, categoryOptions } from "../utils/categoryUtils";

const module = "twitch";
const TABS = {
  TRENDS: "trends",
};

export const twitchApiParams = {
  type: TYPES.PERSONAL,
  service: SERVICES.PLATFORMS,
  module,
  tab: TABS.TRENDS,
};

const twitchCategories = [
  CATEGORIES.GAMING,
];

export const twitchCategoryOptions = categoryOptions.filter((option) =>
  twitchCategories.includes(option.value),
);

export const defaultCategory = CATEGORIES.GAMING;