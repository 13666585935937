import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
const initialState = {
  flashes: [],
};

const flashMessageSlice = createSlice({
  name: "flashMessages",
  initialState: initialState,
  reducers: {
    addFlashMessage: (state, { payload }) => {
      _.set(state, "flashes", [...state.flashes, payload]);
    },
    removeFlash: (state, { payload }) => {
      _.set(
        state,
        "flashes",
        state?.flashes?.length > 0
          ? state?.flashes?.filter((flash) => flash.id !== payload)
          : []
      );
    },
    cleanFlashMessages: (state) => {
      _.set(state, "flashes", []);
    },
  },
});

const flashMessagesAction = flashMessageSlice.actions;

const flashMessagesReducer = flashMessageSlice.reducer;

export { flashMessagesReducer, flashMessagesAction };
