import axiosInstance from "../../../../../utils/axios";

export const getTitleSearchData = ({
  queryString="",
}) =>
  (queryString == null || queryString?.length === 0)
    ? Promise.resolve({ data: [] })
    :
  axiosInstance.get(
    `topsearch?keyword=${queryString}&fields=ip,ip_id,vertical,image_url,release_date&vertical=Movies,Television,Gaming`,
  );

export const getTitleSearchByIdData = ({
  ipIds=[],
}) =>
  (ipIds == null || ipIds?.length === 0)
    ? Promise.resolve({ data: [] })
    :
  axiosInstance.get(
    `ipmanagement/v2/iplist?id=${ipIds.join(",")}&include=ip,ip_id,vertical,image_url,release_date`,
  );