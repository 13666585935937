import { Mode } from "@cloudscape-design/global-styles";
import { useEffect } from "react";
import { useThemeContext } from "../../app/ThemeProvider";

export const useSetGridAdvancedFilterTheme = () => {
  const { mode } = useThemeContext();

  useEffect(() => {
    const className =
      mode === Mode.Dark ? "ag-theme-quartz-dark" : "ag-theme-quartz";

    const timeoutId = setTimeout(() => {
      const advancedFilterElement =
        document.getElementById("advancedFilterParent")?.firstElementChild ||
        document.getElementsByClassName("ag-advanced-filter")?.[0];

      if (advancedFilterElement) {
        advancedFilterElement.classList.remove("ag-theme-quartz-dark");
        advancedFilterElement.classList.add(className);
      }
    }, 0);

    return () => clearTimeout(timeoutId);
  }, [mode]);
};
