import {
  PropertyFilter,
  PropertyFilterProps,
} from "@cloudscape-design/components";
import React, { FC, useState } from "react";
import { DEFAULT_FILTERING_QUERY } from "../../config-global";
import { isSomeOrElse } from "../../utils/sugarUtils";
import { AdvancedFilters } from "./AdvancedFilters";
import { FiltersDropdown } from "./FiltersDropdown";

type TableFilterProps = {
  defaultFilters?: any;
  filteringQuery: PropertyFilterProps.Query;
  filteringProperties: Array<PropertyFilterProps.FilteringProperty>;
  setFilteringQuery: (filteringQuery: any) => void;
  getPreferencesAfterSaveOrUpdate: () => void;
  getPreferencesAfterDelete: (name: string) => void;
  filtersPath: string;
};

export const TableFilter: FC<TableFilterProps> = ({
  defaultFilters,
  filteringQuery,
  filteringProperties,
  setFilteringQuery,
  getPreferencesAfterSaveOrUpdate,
  getPreferencesAfterDelete,
  filtersPath,
}) => {
  const [selectedFilterName, setSelectedFilterName] = useState("");

  const handleClearFilter = () => setFilteringQuery(DEFAULT_FILTERING_QUERY);

  const handleFilterItemClick = (id: string, filters: any) => {
    const newFilterSet = isSomeOrElse(filters[id], null);
    setSelectedFilterName(id);
    if (newFilterSet) setFilteringQuery(newFilterSet);
  };

  return (
    <PropertyFilter
      i18nStrings={{
        filteringAriaLabel: "your choice",
        dismissAriaLabel: "Dismiss",
        clearAriaLabel: "Clear",
        filteringPlaceholder: "Filter by text, property or value",
        groupValuesText: "Values",
        groupPropertiesText: "Properties",
        operatorsText: "Operators",
        operationAndText: "and",
        operationOrText: "or",
        operatorLessText: "Less than",
        operatorLessOrEqualText: "Less than or equal",
        operatorGreaterText: "Greater than",
        operatorGreaterOrEqualText: "Greater than or equal",
        operatorContainsText: "Contains",
        operatorDoesNotContainText: "Does not contain",
        operatorEqualsText: "Equals",
        operatorDoesNotEqualText: "Does not equal",
        editTokenHeader: "Edit filter",
        propertyText: "Property",
        operatorText: "Operator",
        valueText: "Value",
        cancelActionText: "Cancel",
        applyActionText: "Apply",
        allPropertiesLabel: "All properties",
        tokenLimitShowMore: "Show more",
        tokenLimitShowFewer: "Show fewer",
        clearFiltersText: "Clear filters",
        removeTokenButtonAriaLabel: (token: PropertyFilterProps.Token) =>
          `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
        enteredTextLabel: (text: string) => `Use: "${text}"`,
      }}
      filteringProperties={filteringProperties.sort((a, b) =>
        a.propertyLabel.localeCompare(b.propertyLabel),
      )}
      onChange={({ detail }) => setFilteringQuery(detail)}
      query={filteringQuery}
      customControl={
        <FiltersDropdown
          defaultFilters={defaultFilters}
          handleFilterItemClick={handleFilterItemClick}
          path={filtersPath}
        />
      }
      customFilterActions={
        <AdvancedFilters
          filter={filteringQuery}
          defaultFilters={defaultFilters}
          selectedFilterName={selectedFilterName}
          getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
          getPreferencesAfterDelete={getPreferencesAfterDelete}
          handleClearFilter={handleClearFilter}
        />
      }
    />
  );
};
