import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { mapPiracyTrendsData } from "../mappers/mapPiracyTrendsData";
import { getTrendsData, GetTrendsDataType } from "../request";

type UseGetPiracyTrendsDataProps = Omit<GetTrendsDataType, "type">;

export const useGetPiracyTrendsData = ({
  ipId,
  dateRange,
}: UseGetPiracyTrendsDataProps) => {
  const { isLoading, data, error } = useQuery(
    [
      QUERY_KEYS.GET_PIRACY_TRENDS_DATA,
      ipId,
      dateRange.startDate,
      dateRange.endDate,
    ],
    () => getTrendsData({ ipId, dateRange, type: "piracy_generic" }),
  );
  return {
    data: mapPiracyTrendsData(data),
    isLoading,
    error,
  };
};
