import { Link } from "@cloudscape-design/components";

const RottenTomatesAudienceScore = ({ rtFanSummary, compact = false }) => {
  if (!rtFanSummary || rtFanSummary.score == null) {
    return (
      <div className="flex items-center space-x-2 mt-2">
        <img
          src="/rticons/aud_score-empty.svg"
          alt="No Score"
          className={compact ? "h-6 w-6" : "h-12 w-12"}
        />
        <div className="text-black dark:text-slate-100 mt-2">
          <div className={`${compact ? 'text-lg' : 'text-xl font-bold'} dark:text-slate-50  leading-[1.25rem]`}>
            - %
          </div>
          {!compact && <div className="text-xs font-bold">Popcornmeter</div>}
        </div>
      </div>
    );
  }

  const isUpright =
    rtFanSummary.audienceRating === "Upright" ||
    rtFanSummary.rating === "Upright";
  const isSpilled =
    rtFanSummary.audienceRating === "Spilled" ||
    rtFanSummary.rating === "Spilled";
  const count = rtFanSummary.numReviews || rtFanSummary.count;
  const score = rtFanSummary.score;
  const isVerifiedHot = rtFanSummary["verified-hot"] === "true";

  return (
    <div className="flex items-center space-x-2">
      {isVerifiedHot ? (
        <img
          src="/rticons/aud_score-verifiedhot.svg"
          alt="Verified Hot"
          className={compact ? "h-6 w-6" : "h-12 w-12"}
        />
      ) : isUpright ? (
        <img
          src="/rticons/aud_score-fresh.svg"
          alt="Upright"
          className={compact ? "h-6 w-6" : "h-12 w-12"}
        />
      ) : isSpilled ? (
        <img
          src="/rticons/aud_score-rotten.svg"
          alt="Spilled"
          className={compact ? "h-6 w-6" : "h-12 w-12"}
        />
      ) : null}
      <div className="text-black dark:text-slate-100">
        <div className={`${compact ? 'text-md' : 'text-xl font-bold'} dark:text-slate-50  leading-[1.25rem]`}>
          {score ? `${score}%` : "N/A"}
        </div>
        {!compact && (
          <>
            {rtFanSummary.url ? (
              <div className="font-bold">
                <Link
                  fontSize="body-s"
                  href={rtFanSummary.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Popcornmeter
                </Link>
              </div>
            ) : (
              <div className="text-xs font-bold">Popcornmeter</div>
            )}
          </>
        )}
        <div className="text-xs italic dark:text-slate-400">
          {parseInt(count, 10).toLocaleString()} ratings
        </div>
      </div>
    </div>
  );
};

export default RottenTomatesAudienceScore;
