import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";
const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchTwitterRaw = createAsyncThunk(
  "twitterRawData/fetchTwitterRaw",

  async ({ lte, gte, interval }) => {
    const response = await axiosInstance.request({
      url: `twitter/raw`,
      method: `GET`,
      params: {
        lte,
        gte,
        interval,
      },
    });
    return response;
  },
);

const twitterRawDataSlice = createSlice({
  name: "twitterRawData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTwitterRaw.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTwitterRaw.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
      })
      .addCase(fetchTwitterRaw.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const TwitterRawDataReducer = twitterRawDataSlice.reducer;

export default TwitterRawDataReducer;

export const twitterRawDataAction = twitterRawDataSlice.actions;
