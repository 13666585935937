import { createSlice } from "@reduxjs/toolkit";

const editRoleInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const editRoleSlice = createSlice({
    name:"editRole",
    initialState:editRoleInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        editRoleComepleted: (state, action) => {
            state.isLoading = false;
            state.message = action.payload
            state.isCompleted = true;
            state.isError = false;
        },
        clearRoleEditReducer:() => {
            return editRoleInitialState;
        },
        editRoleFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const editRoleAction = editRoleSlice.actions;
const editRoleReducer = editRoleSlice.reducer;

export default editRoleReducer;


