import React, { useEffect, useState } from "react";
import moment from "moment";
import { Box, Button, ColumnLayout, FormField, Input, Modal, Popover, Select, SpaceBetween, Spinner } from "@cloudscape-design/components";
import TitleSelector from "./TitleSelector";
import axiosInstance from "../../../../utils/axios";

const AlignDateRangeModal = ({ visible, setVisible, dateRange, setDateRange, onSubmit, defaultSelectedTitle, defaults }) => {

  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const dateOffsetTypes = [
    { value: "days", label: "days" },
    { value: "weeks", label: "weeks" },
    { value: "months", label: "months" },
    { value: "years", label: "years" },
  ];

  const [ startDateSelectedTitle, setStartDateSelectedTitle ] = useState(null);
  const [ startDateTitleResponse, setStartDateTitleResponse ] = useState(defaultResponseState);
  const [ startDateSearchQuery, setStartDateSearchQuery ] = useState("");
  const [ startDateEventOptions, setStartDateEventOptions ] = useState([]);
  const [ startDateSelectedEvent, setStartDateSelectedEvent ] = useState(null);
  const [ startDateOffsetValue, setStartDateOffsetValue ] = useState(-30);
  const [ startDateOffsetType, setStartDateOffsetType ] = useState(dateOffsetTypes[0]);

  const [ endDateOffsetValue, setEndDateOffsetValue ] = useState(6);
  const [ endDateOffsetType, setEndDateOffsetType ] = useState(dateOffsetTypes[2]);

  const [ defaultDateRange, setDefaultDateRange ] = useState(dateRange);

  const processFullTitleDetailsResponse = ({ responseData }) => {
    const vertical = responseData?.vertical;

    const events = [];
    switch (vertical) {
      case "Movies":
        if (responseData.release_dates) {
          responseData.release_dates.forEach((releaseDate) => {
            if (!responseData.release_date) return;
            events.push({
              value: releaseDate.release_date.split("T")[0],
              label: `${releaseDate.release_type} release`,
              description: moment(releaseDate.release_date.split("T")[0]).format("MMMM D, YYYY") + (releaseDate.note.length > 0 ? (" \u2022 " + releaseDate.note) : ""),
            });
          });
        }
        if (responseData.release_date && !events.some((event) => event.value === responseData.release_date)) {
          if (!responseData.release_date) return;
          events.push({
            value: responseData.release_date,
            label: "Release date",
            description: moment(responseData.release_date).format("MMMM D, YYYY"),
          });
        }
        if (responseData.timeline_events) {
          responseData.timeline_events.forEach((event) => {
            if (!event.timestamp) return;
            switch (event.eventType) {
              case "services_added":
                events.push({
                  value: moment.utc(event.timestamp * 1000).format("YYYY-MM-DD"),
                  label: `Added to ${event.details}`,
                  description: moment.utc(event.timestamp * 1000).format("MMMM D, YYYY"),
                });
                break;
              case "services_removed":
                events.push({
                  value: moment.utc(event.timestamp * 1000).format("YYYY-MM-DD"),
                  label: `Removed from ${event.details}`,
                  description: moment.utc(event.timestamp * 1000).format("MMMM D, YYYY"),
                });
                break;
            }
          });
        }
        break;
      case "Television":
        if (responseData.seasons) {
          responseData.seasons.forEach(season => {
            if (!season.air_date) return;
            events.push({
              value: season.air_date,
              label: `${season.name} released`,
              description: moment(season.air_date).format("MMMM D, YYYY"),
            });
          });
        }
        if (responseData.first_air_date && !events.some((event) => event.value === responseData.first_air_date)) {
          if (!responseData.first_air_date) return;
          events.push({
            value: responseData.first_air_date,
            label: "Release date",
            description: moment(responseData.first_air_date).format("MMMM D, YYYY"),
          });
        }
        if (responseData.timeline_events) {
          responseData.timeline_events.forEach((event) => {
            if (!event.timestamp) return;
            switch (event.eventType) {
              case "services_added":
                events.push({
                  value: moment.utc(event.timestamp * 1000).format("YYYY-MM-DD"),
                  label: `Added to ${event.details}`,
                  description: moment.utc(event.timestamp * 1000).format("MMMM D, YYYY"),
                });
                break;
              case "services_removed":
                events.push({
                  value: moment.utc(event.timestamp * 1000).format("YYYY-MM-DD"),
                  label: `Removed from ${event.details}`,
                  description: moment.utc(event.timestamp * 1000).format("MMMM D, YYYY"),
                });
                break;
            }
          });
        }
        break;
      case "Gaming":
        const regionMapping = {
          europe: "Europe",
          north_america: "North America",
          australia: "Australia",
          new_zealand: "New Zealand",
          japan: "Japan",
          china: "China",
          asia: "Asia",
          worldwide: "Worldwide",
          korea: "Korea",
          brazil: "Brazil",
        };
        if (responseData.release_dates) {
          responseData.release_dates.forEach((releaseDate) => {
            if (!releaseDate.date) return;
            events.push({
              value: moment.utc(releaseDate.date * 1000).format("YYYY-MM-DD"),
              label: releaseDate.region === "worldwide" ? `Released ${regionMapping[releaseDate.region]}` : `Released in ${regionMapping[releaseDate.region]}`,
              description: moment.utc(releaseDate.date * 1000).format("MMMM D, YYYY") + (releaseDate.platform?.length > 0 ? (" \u2022 " + releaseDate.platform) : ""),
            });
          });
        }
        if (responseData.release_date && !events.some((event) => event.value === responseData.release_date)) {
          if (!responseData.release_date) return;
          events.push({
            value: responseData.release_date,
            label: "Release date",
            description: moment(responseData.release_date).format("MMMM D, YYYY"),
          });
        }
        if (responseData.dlcs) {
          responseData.dlcs.forEach((dlc) => {
            if (!dlc.first_release_date) return;
            events.push({
              value: moment.utc(dlc.first_release_date * 1000).format("YYYY-MM-DD"),
              label: `${dlc.name} DLC released`,
              description: moment.utc(dlc.first_release_date * 1000).format("MMMM D, YYYY"),
            });
          });
        }
        if (responseData.expansions) {
          responseData.expansions.forEach((expansion) => {
            if (!expansion.first_release_date) return;
            events.push({
              value: moment.utc(expansion.first_release_date * 1000).format("YYYY-MM-DD"),
              label: `${expansion.name} expansion released`,
              description: moment.utc(expansion.first_release_date * 1000).format("MMMM D, YYYY"),
            });
          });
        }
        break;
    }

    const sortedEvents = events.sort((a, b) => new Date(a.value) - new Date(b.value)).map(x => ({...x, disabled: moment(x.value).isBefore(moment("2020-01-01"))}));
    setStartDateEventOptions(sortedEvents);
    setStartDateSelectedEvent(sortedEvents.find((event) => event.disabled === false && event.value === responseData.release_date) || sortedEvents.find(x => x.disabled === false) || null);
  };

  const getFullTitleDetails = ({ titleId, abortSignal }) => {
    setStartDateTitleResponse({ ...startDateTitleResponse, loading: true, completed: false, error: null });

    // Why was our API designed like this??
    let endpoint = "";
    if (titleId.startsWith("film-")) {
      endpoint = "/movies/search/v2";
    } else if (titleId.startsWith("series-")) {
      endpoint = "/series/search/v2";
    } else if (titleId.startsWith("game-")) {
      endpoint = "/games/search";
    }

    axiosInstance.request({
      url: endpoint,
      method: "GET",
      params: {
        ip_id: titleId,
      },
      signal: abortSignal,
    }).then((response) => {
      setStartDateTitleResponse({ ...startDateTitleResponse, loading: false, completed: true, data: response.data, error: null });
      processFullTitleDetailsResponse({ responseData: response.data });
    }).catch((error) => {
      console.error(error);
      setStartDateTitleResponse({ ...startDateTitleResponse, loading: (abortSignal.reason === "Request cancelled"), completed: true, error: error });
    });
  };

  useEffect(() => {
    if (visible === true) {
      setStartDateSearchQuery("");
      setStartDateSelectedTitle(null);
      setStartDateSelectedEvent(null);
      setStartDateEventOptions([]);
      setDefaultDateRange(dateRange);
    }
  }, [visible]);

  useEffect(() => {
    if (startDateSelectedTitle) {
      const controller = new AbortController();
      const signal = controller.signal;
      getFullTitleDetails({ titleId: startDateSelectedTitle.value, abortSignal: signal });

      return () => {
        controller.abort("Request cancelled");
      };
    }
  }, [startDateSelectedTitle]);

  useEffect(() => {
    if (startDateSelectedEvent?.value) {
      const offsetDate = moment(startDateSelectedEvent.value).add(parseInt(startDateOffsetValue), startDateOffsetType.value);
      setDateRange({
        type: "absolute",
        startDate: offsetDate.format("YYYY-MM-DD"),
        endDate: offsetDate.add(parseInt(endDateOffsetValue), endDateOffsetType.value).format("YYYY-MM-DD"),
      });
    }
  }, [startDateSelectedEvent, startDateOffsetValue, startDateOffsetType, endDateOffsetType, endDateOffsetValue]);

  useEffect(() => {
    if (visible === true) {
      if (defaultSelectedTitle && !defaults) {
        setStartDateSelectedTitle(defaultSelectedTitle);
        setStartDateSearchQuery(defaultSelectedTitle.ip);
      } else if (defaults) {
        setEndDateOffsetType(defaults.endDateOffsetType);
        setEndDateOffsetValue(defaults.endDateOffsetValue);
        setStartDateEventOptions(defaults.startDateEventOptions);
        setStartDateOffsetType(defaults.startDateOffsetType);
        setStartDateOffsetValue(defaults.startDateOffsetValue);
        setStartDateSelectedEvent(defaults.startDateSelectedEvent);
        setStartDateSelectedTitle(defaults.startDateSelectedTitle);
        setStartDateSearchQuery(defaults.startDateSelectedTitle.ip);
      }
    }
  }, [defaultSelectedTitle, defaults, visible]);

  return (
    <Modal
      size="large"
      onDismiss={() => setVisible(false)}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => setVisible(false)}>Cancel</Button>
            <Button 
              variant="primary" 
              onClick={() => {
                onSubmit({
                  detail: {
                    dateRange: dateRange,
                    startDateSelectedTitle: startDateSelectedTitle,
                    startDateSelectedEvent: startDateSelectedEvent,
                    startDateEventOptions: startDateEventOptions,
                    startDateOffsetValue: startDateOffsetValue,
                    startDateOffsetType: startDateOffsetType,
                    endDateOffsetValue: endDateOffsetValue,
                    endDateOffsetType: endDateOffsetType,
                  },
                });
                setVisible(false);
              }}
              disabled={!startDateSelectedEvent || startDateEventOptions.length === 0}
            >
              Submit
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Align date range with event"
    >
      <SpaceBetween direction="vertical" size="s">
        <SpaceBetween direction="horizontal" size="l">
          <FormField
            label="Current date range"
          >
            {defaultDateRange?.startDate} &mdash; {defaultDateRange?.endDate}
          </FormField>
          <div className="text-3xl">&#x2192;</div>
          <FormField
            label="New date range"
          >
            {dateRange?.startDate} &mdash; {dateRange?.endDate}
          </FormField>
        </SpaceBetween>
        <div className="mt-2"></div>
        <ColumnLayout columns={2}>
          <FormField
            label="Align with"
            stretch
          >
            <TitleSelector
              onSelectedItemChanged={(item) => {
                setStartDateSelectedTitle(item);
                setStartDateSelectedEvent(null);
                setStartDateEventOptions([]);
              }}
              searchQuery={startDateSearchQuery}
              setSearchQuery={setStartDateSearchQuery}
              defaultSelectedTitleId={startDateSelectedTitle?.ip_id}
            />
          </FormField>
          <FormField
            label="Event"
            stretch
            info={
              <Popover
                dismissButton={false}
                position="top"
                size="medium"
                triggerType="custom"
                content={
                  <div>
                    Events before 2020 are disabled because data is not available for that period.
                  </div>
                }
              >
                <Button iconName="status-info" variant="inline-icon" />
              </Popover>
            }
          >
            <Select
              selectedOption={startDateSelectedEvent}
              onChange={({ detail }) => setStartDateSelectedEvent(detail.selectedOption)}
              options={startDateEventOptions}
              placeholder={startDateTitleResponse?.loading ? <div><Spinner />{" "}Loading events</div> : "Select event"}
              disabled={startDateEventOptions.length === 0}
              statusType={startDateTitleResponse?.loading ? "loading" : "finished"}
            />
          </FormField>
        </ColumnLayout>
        <ColumnLayout columns={2}>
          <FormField
            label="Start date offset"
            stretch
            description="Relative to the selected event date"
            constraintText="To align the date before the event, use a negative value"
          >
            <div className="flex space-x-2 w-full">
              <div className="grow">
                <Input
                  type="number"
                  inputMode="numeric"
                  value={startDateOffsetValue}
                  onChange={({ detail }) => setStartDateOffsetValue(detail.value)}
                />
              </div>
              <Select
                options={dateOffsetTypes}
                selectedOption={startDateOffsetType}
                onChange={({ detail }) => setStartDateOffsetType(detail.selectedOption)}
              />
            </div>
          </FormField>
          <FormField
            label="End date offset"
            stretch
            description="Relative to the start date"
          >
            <div className="flex space-x-2 w-full">
              <div className="grow">
                <Input
                  type="number"
                  inputMode="numeric"
                  value={endDateOffsetValue}
                  onChange={({ detail }) => parseInt(detail.value) < 0 ? "0" : setEndDateOffsetValue(detail.value)}
                />
              </div>
              <Select
                options={dateOffsetTypes}
                selectedOption={endDateOffsetType}
                onChange={({ detail }) => setEndDateOffsetType(detail.selectedOption)}
              />
            </div>
          </FormField>
        </ColumnLayout>
      </SpaceBetween>
    </Modal>
  );
};

export default AlignDateRangeModal;