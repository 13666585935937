import React from 'react';

const customChartThemes = {
    myCustomTheme: {
        baseTheme: 'ag-default-dark',
        palette: {
            fills: [
                '#2196F3', '#4CAF50', '#F44336', '#9C27B0', '#3F51B5', '#FF9800', '#009688', '#8BC34A', '#CDDC39', '#00BCD4',
            ],
            strokes: ['#874349', '#718661', '#a48f5f'],
        },
        overrides: {
            common: {
                background: {
                    fill: '#0F172B',
                },
                title: {
                    fontSize: 22,
                    fontFamily: 'Arial, sans-serif',
                },
            },
        },
    },
    myCustomThemeLight: {
        baseTheme: 'ag-default',
        palette: {
            fills: [
                '#42A5F5',
                '#66BB6A',
                '#EF5350',
                '#AB47BC',
                '#5C6BC0',
                '#FFA726',
                '#26A69A',
                '#9CCC65',
                '#D4E157',
                '#29B6F6',
            ],
            strokes: ['#6D4C41', '#5F6368', '#8D6E63'],
        },
        overrides: {
            common: {
                background: {
                    fill: '#F5F5F5',
                },
                title: {
                    fontSize: 22,
                    fontFamily: 'Arial, sans-serif',
                },
            },
        },
    },
};

const chartThemes = ['myCustomTheme', 'myCustomThemeLight'];

// CustomChartTheme component doesn't render anything , but it's there in case we need to add any additional logic or rendering in the future. 

const CustomChartTheme = () => {
    return <></>;
};

export { CustomChartTheme, customChartThemes, chartThemes };
