import { useQuery } from "react-query";
import { QUERY_KEYS } from "./api/constants";
import { getTimelineEvents } from "./api/request";
import { useMemo } from "react";

const getFormattedEventLabel = (event) => {
  switch (event.event_type) {
    case "season_released":
      return `${event.details} released`;
    case "services_added":
      return `Added to ${event.details.split(",").join(", ")}`;
    case "services_removed":
      return `Removed from ${event.details.split(",").join(", ")}`;
    case "movie_released":
      const movieReleasedParts = event.details.split(" | ");
      if (movieReleasedParts.length === 1) {
        return `${movieReleasedParts[0]} release`;
      } else {
        return `${movieReleasedParts[0]} release (${movieReleasedParts[1]})`;
      }
    case "game_released":
      const gameReleasedParts = event.details.split(" | ");
      const region = gameReleasedParts[0];
      if (gameReleasedParts.length === 1) {
        if (region === "Worldwide") {
          return `Released worldwide`;
        } else {
          return `Released in ${region}`;
        }
      } else {
        if (region === "Worldwide") {
          return `Released worldwide on ${gameReleasedParts[1]}`;
        } else {
          return `Released in ${region} on ${gameReleasedParts[1]}`;
        }
      }
    case "dlc_released":
      return `${event.details} DLC released`;
    case "expansion_released":
      return `${event.details} expansion released`;
    case "released":
      return `Released`;
    default:
      return event.details;
  }
};

export const useGetTimelineEvents = ({ enabled=true, titleIds }) => {
  const params = {
    ipIds: titleIds,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_GOOGLE_SEARCH_DATA, { ...params }],
    () => getTimelineEvents(params),
    { enabled },
  );

  const dataWithFormattedLabels = (data) => {
    if (!data) return null;
    const newData = {};

    Object.keys(data).forEach(key => {
      const events = data[key].events.map(event => ({
        ...event,
        label: getFormattedEventLabel(event),
      }));
      newData[key] = {
        ...data[key],
        events,
      };
    });

    return newData;
  };

  return {
    data: dataWithFormattedLabels(data?.data),
    isLoading,
    error,
  };
};