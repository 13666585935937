import { Link } from "@cloudscape-design/components";
import React from "react";
import { dateFormatter } from "../../../../../utils/dateFormatter";
import shortenInteger from "../../../../../utils/shortenInteger";
import { getSentimentCategory, getSentimentColorClasses } from "../../../../youtube/channelPage/components/VideoList/utils";
import { TrailerResponseData } from "../../../api/types";

export const columnDefs = [
  {
    id: "title",
    header: "Title",
    cell: (params: TrailerResponseData) => {
      return (
        <Link
          href={`/youtube/videos/${params.videoId}`}
          fontSize="heading-xs"
          variant="secondary"
        >
          {params.title}
        </Link>
      );
    },
  },
  {
    id: "channel",
    header: "Channel",
    cell: (params: TrailerResponseData) => {
      return (
        <Link
          href={`/youtube/channels/${params.channelId}`}
          fontSize="heading-xs"
          variant="secondary"
        >
          {params.channel}
        </Link>
      );
    },
  },
  {
    id: "publishedAt",
    header: "Published On",
    width: 180,
    cell: (params: TrailerResponseData) => {
      const date = params.publishedAt * 1000;
      return dateFormatter(date);
    },
    sortingField: "publishedAt",
  },
  {
    id: "views",
    header: "Views",
    width: 180,
    cell: (e: TrailerResponseData) => <span>{shortenInteger(e.views)}</span>,
    sortingField: "views",
  },
  {
    id: "likes",
    header: "Likes",
    width: 180,
    cell: (e: TrailerResponseData) => <span>{shortenInteger(e.likes)}</span>,
    sortingField: "likes",
  },
  {
    id: "comments",
    header: "Comments",
    width: 180,
    cell: (e: TrailerResponseData) => <span>{shortenInteger(e.comments)}</span>,
    sortingField: "comments",
  },
  {
    id: "sentimentScore",
    header: "User Sentiment",
    width: 180,
    cell: (params: TrailerResponseData) => {
      if (!params.sentimentScore) {
        return "N/A";
      }
      const category = getSentimentCategory(params?.sentimentScore);
      const score = params.sentimentScore?.toFixed(2) ?? " ";
      const colorClasses = getSentimentColorClasses(params.sentimentScore);
      return <span className={colorClasses}>{`${category} (${score})`}</span>;
    },
    sortingField: "sentimentScore",
  },
];
