import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { GetChannelsListDataProps, getChannelsListData } from "../request";

export const useChannelsListData = (
  params: GetChannelsListDataProps,
  enabled: boolean = false,
) => {
  const { isLoading, data, error } = useQuery(
    [
      QUERY_KEYS.GET_CHANNELS_LIST_DATA,
      params.from,
      params.size,
      params.include,
      params.exclude,
      params.sort,
      params.condition,
    ],
    () => getChannelsListData(params),
    {
      enabled,
    },
  );

  return {
    data: isSomeOrElse(data?.data, []),
    isLoading,
    error,
  };
};
