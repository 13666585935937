import React, { useContext } from "react";
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Helmet } from "react-helmet-async";
import FormProvider from "../../components/forms/FormProvider";
import SnackbarContext from "../../hooks/notify/snack-bar/snackbar-context";
import { useAuthContext } from "../../auth/useAuthContext";
import PasswordTextField from "../../components/forms/PasswordTextField";
import { AuthLayout } from "../../layouts/AuthLayout";

export default function NewPasswordPage() {
    const { changeFirstPassword } = useAuthContext();
    const snackbarCtx = useContext(SnackbarContext);
    const newSecurePasswordDefaultValue = {
        password: '',
        confirmPassword: ''

    }
    const NewSecurePasswordSchema = Yup.object().shape({
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
            .required('Confirm password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    });

    const methods = useForm({
        defaultValues: newSecurePasswordDefaultValue,
        mode: 'onSubmit',
        reValidateMode: 'onSubmit',
        resolver: yupResolver(NewSecurePasswordSchema)
    });

    const {
        reset,
        setError,
        handleSubmit,
        formState: { errors, isSubmitting, isSubmitSuccessful },
    } = methods;

    const onSubmit = async (data) => {
        try {
            await changeFirstPassword(data.password);
        } catch (error) {
            snackbarCtx.displayMsg("Error", error.message, "error", 3000);
        }
    }

    return <AuthLayout>
        <Helmet>
            <title> Change Password</title>
        </Helmet>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <PasswordTextField name={'password'} label="Password" placeholder={"password"} type={'password'} />
            <PasswordTextField name={'confirmPassword'} label="Confirm Password" placeholder={"Confirm Password"} type={'password'} />
            <div className="flex items-center justify-between mt-5">
                <div className="flex items-center">

                </div>

                <div className="text-sm">
                    <button
                        type="submit"
                        className="inline-flex items-center rounded-md border border-transparent bg-blue-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                        
                        Submit
                    </button>
                </div>
            </div>
        </FormProvider>

    </AuthLayout>
}