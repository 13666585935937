import { Icon, Link } from "@cloudscape-design/components";
import React from "react";
import { formatDate } from "../../../../../../utils/dateFormatter";
import TagList from "../../../../../item/pages/components/TagList";

const getImageSrc = (vertical: string, url: string) =>
  vertical === "games"
    ? `https://images.igdb.com/igdb/image/upload/t_cover_big/${url}.png`
    : `https://image.tmdb.org/t/p/w92${url}`;

const columnDefinitions = (vertical: string) => [
  {
    id: "poster",
    header: "Poster",
    width: 120,
    cell: (e) => {
      return e.image_url ? (
        <a href={`${window.location.origin}/item/${e.ip_id}`}>
          <img
            src={getImageSrc(vertical, e.image_url)}
            alt={e.name}
            className="rounded"
            loading="lazy"
          />
        </a>
      ) : (
        <div className="h-32 flex items-center justify-center rounded-md bg-gray-400">
          <span className="text-black text-xs truncate">{e.ip}</span>
        </div>
      );
    },
  },
  {
    id: "name",
    header: "Title & Summary",
    width: 600,
    minWidth: 300,
    cell: (e) => {
      const truncatedSummary =
        e?.summary?.length > 250
          ? e.summary.substring(0, 250) + "..."
          : e.summary;

      return (
        <div>
          <Link
            href={`${window.location.origin}/item/${e.ip_id}`}
            fontSize="heading-m"
            variant="secondary"
          >
            <span className="font-bold">{e.ip}</span>
          </Link>
          <div className="flex space-x-2 mt-1">
            <TagList
              title=""
              tagData={e.genre.map((genre) => ({
                label: genre,
                color: "purple",
              }))}
              tagLimit={10}
            />
          </div>
          <div className="dark:text-slate-400 text-slate-600 mt-2 line-clamp-3">
            {truncatedSummary}
          </div>
        </div>
      );
    },
    sortingField: "name",
  },
  {
    id: "status",
    header: "Status",
    width: 150,
    cell: (e) => {
      return <span>{e.status}</span>;
    },
    sortingField: "status",
  },
  {
    id: "release_date",
    header: "Release Date",
    width: 180,
    cell: (e) => {
      return <span>{formatDate(new Date(e.release_date))}</span>;
    },
    sortingField: "release_date",
  },
  {
    id: "tracked",
    header: "Tracked",
    cell: (e) =>
      e.tracked ? (
        <div className="dark:text-green-500 text-green-700 whitespace-nowrap flex gap-1">
          <Icon name="status-positive" variant="success" />
          <span>
            {e.ip_list?.length > 0 ? e.ip_list.join(", ") : "Tracked"}
          </span>
        </div>
      ) : (
        <div className="dark:text-slate-400 text-slate-600 whitespace-nowrap flex gap-1">
          <Icon name="status-stopped" variant="subtle" />
          <span>Not Tracked</span>
        </div>
      ),
    sortingField: "tracked",
  },
];

export const companiesColumnDefinitions = [
  {
    id: "name",
    header: "Company Name",
    width: 700,
    minWidth: 300,
    cell: (e) => {
      return (
        <div>
          <Link
            href={`${window.location.origin}/item/companies-${e.id}`}
            fontSize="heading-s"
          >
            {e.name}
          </Link>
        </div>
      );
    },

    sortingField: "name",
  },
];

export const getColumnDefinitions = (vertical: string) => {
  const columnDefs = columnDefinitions(vertical);

  if (vertical === "games")
    return columnDefs.filter((item) => item.id !== "status");

  if (vertical === "companies") return companiesColumnDefinitions;

  return columnDefs;
};
