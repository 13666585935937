import { createSlice } from "@reduxjs/toolkit";

const createPermissionRouteInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const createPermissioinsRouteSlice = createSlice({
    name:"createPermissionRoute",
    initialState:createPermissionRouteInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        createPermissionRouteComepleted: (state, action) => {
            state.isLoading = false;
            state.message = "Permission Route Created"
            state.isCompleted = true;
            state.isError = false;
        },
        clearPermissionRouteCreateReducer:() => {
            return createPermissionRouteInitialState;
        },
        createPermissionRouteFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const createPermissioinsRouteAction = createPermissioinsRouteSlice.actions;
const createPermissioinsRouteReducer = createPermissioinsRouteSlice.reducer;
export default createPermissioinsRouteReducer;


