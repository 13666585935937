import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { queryClient } from "../../../app/ReactQueryWrapper";
import { useAuthContext } from "../../../auth/useAuthContext";
import { QUERY_KEYS } from "../constants";
import { deletePantheonRankingPreference } from "../requests";

export const useDeletePlatformPreference = (platform: string, onError) => {
  const { user } = useAuthContext();

  const { isLoading, mutateAsync, error } = useMutation<any, AxiosError, any>(
    ({ name, type }) =>
      deletePantheonRankingPreference({
        user,
        fileName: name,
        platform,
        type,
      }),
    {
      onSuccess: (result) => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_ALL_PLATFORM_WEIGHTAGES);
        if (result.error) return onError(result.error);
      },
      onError: ({ error }: any) => {
        onError(error);
      },
    },
  );

  return {
    mutateAsync,
    isLoading,
    error,
  };
};
