import { CHART_LABELS } from "../../hooks/useGridConfig";

export const defaultColumn = CHART_LABELS.VOTE_TREND;

export const additionalPlatformColumns = [
  CHART_LABELS.FRANCHISE,
  CHART_LABELS.GENRE,
  CHART_LABELS.CUSTOM_TAGS,
  CHART_LABELS.COUNTRIES,
  CHART_LABELS.DISTRIBUTORS,
  CHART_LABELS.PRODUCTION,
  CHART_LABELS.OTT,
  CHART_LABELS.VOTE_TREND,
];

export const corePlatformColumns = [
  CHART_LABELS.RANK,
  CHART_LABELS.DAILY_AUD_VOTES,
  CHART_LABELS.AUDIENCE_VOTES,
  CHART_LABELS.AUDIENCE_RATING,
  CHART_LABELS.CRITIC_VOTES,
  CHART_LABELS.CRITIC_RATING,
];
