import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
import { useRef } from "react";
import "../../index.css";
// form
import { useFormContext, Controller } from "react-hook-form";
import useEventListener from "../../hooks/useEventListener";

OtpInputController.propTypes = {
  keyName: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.string),
};

export default function OtpInputController({
  keyName = "",
  inputs = [],
  helperText = "",
  ...other
}) {
  const codesRef = useRef(null);
  const { control, setValue } = useFormContext();
  const handlePaste = (event) => {
    let data = event.clipboardData.getData("text");
    data = data.split("");
    inputs.map((input, index) => setValue(input, data[index]));
    event.preventDefault();
  };

  const handleChangeWithNextField = (event, handleChange) => {
    const { maxLength, value, name } = event.target;
    const fieldIndex = name.replace(keyName, "");
    const fieldIntIndex = Number(fieldIndex);
    const nextfield = document.querySelector(
      `input[name=${keyName}${fieldIntIndex + 1}]`
    );
    if (value.length > maxLength) {
      event.target.value = value[0];
    }
    if (value.length >= maxLength && fieldIntIndex < 6 && nextfield !== null) {
      nextfield.focus();
    }
    handleChange(event);
  };

  useEventListener("paste", handlePaste, codesRef);
  const normalStyle =
    "block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm text-black";
  const errorStyle =
    "block w-full rounded-md border-red-300 pr-10 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm";

  return (
    <div
      className={"flex flex-row justify-between items-center"}
      ref={codesRef}
    >
      <label
        htmlFor="email"
        className="block text-sm font-medium text-gray-700"
      >
        {other.label}
      </label>
      {inputs.map((name, index) => (
        <div className="controller_container">
          <Controller
            key={name}
            name={`${keyName}${index + 1}`}
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <div>
                  <div className="relative mt-1 rounded-md shadow-sm">
                    <input
                      {...field}
                      type={"number"}
                      readonly
                      value={field.value}
                      {...other}
                      autoFocus={index === 0}
                      className={error ? errorStyle : normalStyle}
                      aria-invalid="true"
                      aria-describedby={`${name}-error`}
                      onChange={(event) => {
                        handleChangeWithNextField(event, field.onChange);
                      }}
                      onFocus={(event) => event.currentTarget.select()}
                    />
                  </div>
                </div>
              );
            }}
          />
        </div>
      ))}
    </div>
  );
}
