import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { Dispatch, FC, SetStateAction } from "react";
import { isNone } from "../../../../utils/sugarUtils";
import { useCreateChannel } from "../api/hooks/useCreateChannel";
import { PostCreateChannelType } from "../api/request";
import { ChannelCards } from "./ChannelCards";

type CreateChannelModalProps = {
  title: string;
  visible: boolean;
  selectedChannelData: PostCreateChannelType;
  setSelectedChannelData: Dispatch<SetStateAction<PostCreateChannelType>>;
  onDismiss: () => void;
  onSuccess: () => void;
  onFailure: () => void;
};

export const CreateChannelModal: FC<CreateChannelModalProps> = ({
  title,
  visible,
  onDismiss,
  onSuccess,
  onFailure,
  selectedChannelData,
  setSelectedChannelData,
}) => {
  const { mutate: createChannel, isLoading } = useCreateChannel(
    onSuccess,
    onFailure,
  );

  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      size="large"
      header={title}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="normal" onClick={onDismiss}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => createChannel(selectedChannelData)}
              loading={isLoading}
              disabled={
                isNone(selectedChannelData) ||
                Object.keys(selectedChannelData).length === 0
              }
            >
              Continue
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <ChannelCards
        selectedChannelData={selectedChannelData}
        setSelectedChannelData={setSelectedChannelData}
      />
    </Modal>
  );
};
