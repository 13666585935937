import { Box, Container, Link, SpaceBetween } from "@cloudscape-design/components";
import React from "react";

export const GRPageCards = () => {
  const items = [
    {
      link: "/dashboards/globalranking/movies",
      image: "/images/gr-movies.png",
      name: "Movies",
      path: "Ranking > Global ",
      description: "Curated ranking based on defined platform weights.",
    },
    {
      link: "/dashboards/globalranking/series",
      image: "/images/gr-series.png",
      name: "Series",
      path: "Ranking > Global",
      description: "Curated ranking based on defined platform weights.",
    },
    {
      link: "/dashboards/globalranking/gaming",
      image: "/images/gr-games.png",
      name: "Gaming",
      path: "Ranking > Global",
      description: "Curated ranking based on defined platform weights.",
    },
    {
      link: "/dashboards/raw-data",
      image: "/images/gr-rawdata.png",
      name: "Trending Data Table",
      path: "Ranking > Trending",
      description: "Comprehensive ranking of tracked titles based on weighted platform metrics.",
    },
  ];

  return (
    <div className="mx-auto">
      <div className="grid gap-2 sm:grid-cols-2 sm:grid-rows-2 lg:grid-cols-4 lg:grid-rows-1">
        {items.map((item) => (
          <Container
            fitHeight
            disableContentPaddings
            key={item.link}
            media={{
              content: (
                <Link href={item.link}>
                  <img src={item.image} alt={item.name} className="object-cover" />
                </Link>
              ),
              height: 150,
              position: "top",
            }}
          >
            <SpaceBetween direction="vertical" size="xs">
              <div key={item.path} className="py-4 px-4">
                <Link href={item.link}><b className="text-base">{item.name}</b></Link>
                <p key={item.description}>{item.description}</p>
              </div>
            </SpaceBetween>
          </Container>
        ))}
      </div>
    </div>
  );
};
