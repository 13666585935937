import React, { useEffect, useState } from "react";
import { FormField, Multiselect, Toggle } from "@cloudscape-design/components";
import { useGetFilterOptionsData } from "../api/hooks/useGetFilterOptionsData";
import { FILTER_JOIN_OPERATIONS, FILTER_OPERATIONS } from "../../../config-global";

export const SetFilter = ({
  filterObject,
  setFilterObject,
  tokenLimit=2,
}) => {

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [disabled, setDisabled] = useState(true);

  const { data, isLoading } = useGetFilterOptionsData({
    vertical: filterObject.vertical,
    filterKey: filterObject.key,
    query: filterObject.autoComplete ? searchQuery : undefined,
    size: filterObject.autoComplete ? 50 : undefined,
    disabled,
  });

  useEffect(() => {
    if (filterObject.value) {
      setSelectedOptions(filterObject.value.map(value => value.replaceAll("\\,", "\\|").split(",")).flat().map(option => ({ label: option.replaceAll("\\|", ","), value: option.replaceAll("\\|", "\\,") })));
    } else {
      setSelectedOptions([]);
    }
  }, [filterObject]);

  return (
    <FormField label={
      <div className="flex justify-between">
        <div>{filterObject.name}</div>
        <div className="flex items-center gap-x-2 font-normal">
          <div className="text-xs dark:text-slate-400">AND</div>
          <Toggle
            checked={filterObject.valueJoinOperation === FILTER_JOIN_OPERATIONS.AND}
            onChange={({ detail }) => {
              const joinOperation = detail.checked ? FILTER_JOIN_OPERATIONS.AND : FILTER_JOIN_OPERATIONS.OR;
              setFilterObject({
                ...filterObject,
                value: selectedOptions.map(option => option.value),
                valueJoinOperation: joinOperation,
              });
            }}
          />
        </div>
      </div>
    }>
      <Multiselect
        selectedOptions={selectedOptions}
        onChange={({ detail }) => {
          setSelectedOptions(detail.selectedOptions);
          setFilterObject({
            ...filterObject,
            value: detail.selectedOptions.map(option => option.value),
          });
        }}
        options={data?.data ? [
          ...(filterObject.allowBlank ? [
            { label: "Is blank", value: "Is blank", disabled: selectedOptions.length > 0 && selectedOptions[0].value !== "Is blank" }, 
            { label: "Is not blank", value: "Is not blank", disabled: selectedOptions.length > 0 && selectedOptions[0].value !== "Is not blank" }
          ] : []), 
          ...data?.data?.map(option => ({ label: option, value: option.replaceAll(",", "\\,"), disabled: selectedOptions.some(selectedOption => ["Is blank", "Is not blank"].includes(selectedOption.value)) }))
        ] : []}
        loadingText="Loading"
        placeholder="Select options"
        statusType={isLoading ? "loading" : "finished"}
        onLoadItems={({ detail }) => {
          setSearchQuery(detail.filteringText);
        }}
        filteringType="manual"
        onFocus={() => {
          setDisabled(false);
        }}
        tokenLimit={tokenLimit}
        i18nStrings={{
          tokenLimitShowFewer: "Show less",
          tokenLimitShowMore: "Show more",
        }}
        virtualScroll
      />
    </FormField>
  );
};