import axiosInstance from "../../../../../../utils/axios";

export const getNetflixWeeklyData = ({
  timestamp
}: {
  timestamp: number
}) => {
  return axiosInstance.post(
    'pql',
    {
      sql: `SELECT * FROM S3Object s WHERE s."timestamp" = ${timestamp}`
    },
    {
      params: {
        key: 'netflix/netflix_weekly',
        file_type: 'P',
        sort: 'weekly_rank:asc',
        size: 100
      }
    }
  );
};

export const getNetflixWeeklySummary = () => {
  return axiosInstance.post(
    'test/s3path',
    {
      sql: `SELECT * FROM s3object s`
    },
    {
      params: {
        key: 'netflix/netflix_weekly_grouped',
        file_type: 'CSV'
      }
    }
  );
};
