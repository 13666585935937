import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import { GetPersonalListDataProps, getPersonalListData } from "../request";

export const usePersonalListData = (
  params: GetPersonalListDataProps,
  enabled: boolean = false,
) => {
  const { isLoading, data, error, refetch } = useQuery(
    [
      QUERY_KEYS.GET_PERSONAL_LIST_DATA,
      params.category,
    ],
    () => getPersonalListData(params),
    {
      enabled,
    },
  );

  return {
    data: isSomeOrElse(data?.data, []),
    isLoading,
    error,
    refetch,
  };
};
