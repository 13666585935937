import axiosInstance from "../../../utils/axios";

export type PutVideosToIPParamsType = {
  videoId: string;
  payload: {
    ip: string;
    ip_id: string;
  };
};

export const putVideosToIP = ({ videoId, payload }: PutVideosToIPParamsType) =>
  axiosInstance.request({
    url: `/youtube/v1/videos/${videoId}`,
    method: "PUT",
    headers: {
      "Content-Type": "text/plain",
    },
    data: payload,
  });
