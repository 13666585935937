import React from "react";
import { Button, ButtonDropdown, Icon } from "@cloudscape-design/components";
import { getPantheonRankingDataV2 } from "../api/request";
import { VERTICALS } from "../../../../../config-global";

const verticalMap = {
  [VERTICALS.MOVIES]: "movies",
  [VERTICALS.SERIES]: "series",
  [VERTICALS.GAMES]: "gaming",
};

export const ExportButton = ({
  filterQuery,
  vertical,
  sort,
  sortOrder,
  startTs,
  endTs,
  weightageValues,
}) => {

  const [isLoading, setIsLoading] = React.useState(false);

  const exportData = (format) => {
    setIsLoading(true);

    getPantheonRankingDataV2({
      filterQuery,
      vertical: verticalMap[vertical],
      sort,
      sortOrder,
      startTs,
      endTs,
      weightageValues,
      download: format,
    }).then(data => {
      const url = data.data;
      window.location.href = url;
      setIsLoading(false);
    });
  };

  return (
    <ButtonDropdown
      items={[
        { text: "Data as CSV", id: "csv" },
        { text: "Data as XLSX", id: "xlsx" },
      ]}
      onItemClick={({ detail }) => exportData(detail.id)}
      loading={isLoading}
    >
      Download
    </ButtonDropdown>
  );
};