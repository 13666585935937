import _ from "lodash";
const preferences = (state) => state;
const issuesPreference = (state) =>
  state?.globalPersonalPref?.preferences?.issues || null;
const tarTelevisionPreferences = (state) =>
  state?.globalPersonalPref?.preferences?.tar?.television?.titleAvailability
    ?.preferences || null;
const auditPreferences = (state) =>
  state?.globalPersonalPref?.preferences?.audit?.preferences || null;
export const getIssuesPreference = _.flow(preferences, issuesPreference);
export const getTarTelevisionPreferences = _.flow(
  preferences,
  tarTelevisionPreferences
);

export const getAuditPreferences = _.flow(preferences, auditPreferences);

const viewersPulsePreferences = (state) =>
  state?.globalPersonalPref?.preferences?.dashboards?.viewersPulse
    ?.personalDashboard || [];

export const getViewersPulsePreferences = _.flow(
  preferences,
  viewersPulsePreferences
);
