import { Button, FormField, Link, SpaceBetween } from "@cloudscape-design/components";
import classnames from "classnames";
import { useState } from "react";

const TagList = ({ title, tagData, tagLimit=4, ...props }) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <FormField 
            label={
                <span className="flex flex-nowrap gap-3 items-center">
                    <span className="dark:text-slate-200 text-black font-bold text-xs text-nowrap">{title}</span>
                    {tagData.length > tagLimit && (
                        <Button variant="inline-link" onClick={() => setShowMore(!showMore)}>
                            <span className="text-xs text-nowrap">{showMore ? "show less" : "show more"}</span>
                        </Button>
                    )}
                </span>
            } 
            stretch 
        >
            <div className="flex gap-2 justify-start flex-wrap">
                {tagData.slice(0, showMore ? 1000 : tagLimit).map((tag, index) => (
                    <span key={index}>
                        <Link href={tag.href} variant="primary">
                            <button
                                type="button"
                                className={classnames(
                                    "font-normal rounded-md px-2 py-1 text-xs shadow-sm text-nowrap",
                                    {
                                        "bg-purple-200 hover:bg-purple-400 dark:bg-purple-950 hover:dark:bg-purple-700 ring-1 ring-inset ring-purple-500 text-purple-900 dark:text-purple-200":
                                            tag.color === "purple",
                                        "bg-green-200 hover:bg-green-400 dark:bg-green-950 hover:dark:bg-green-700 ring-1 ring-inset ring-green-500 text-green-900 dark:text-green-200":
                                            tag.color === "green",
                                        "bg-red-200 hover:bg-red-400 dark:bg-red-950 hover:dark:bg-red-700 ring-1 ring-inset ring-red-500 text-red-900 dark:text-red-200":
                                            tag.color === "red",
                                        "bg-blue-200 hover:bg-blue-400 dark:bg-blue-950 hover:dark:bg-blue-700 ring-1 ring-inset ring-blue-500 text-blue-900 dark:text-blue-200":
                                            tag.color === "blue",
                                        "bg-indigo-200 hover:bg-indigo-400 dark:bg-indigo-950 hover:dark:bg-indigo-700 ring-1 ring-inset ring-indigo-500 text-indigo-900 dark:text-indigo-200":
                                            tag.color === "indigo",
                                        "bg-amber-200 hover:bg-amber-400 dark:bg-amber-950 hover:dark:bg-amber-700 ring-1 ring-inset ring-amber-500 text-amber-900 dark:text-amber-200":
                                            tag.color === "amber",
                                    }
                                )}
                            >
                                {tag.label}
                            </button>
                        </Link>
                    </span>
                ))}
            </div>
        </FormField>
    );
};

export default TagList;