import {
  Box,
  Button,
  Drawer,
  FormField,
  Select,
  SpaceBetween,
  Multiselect,
  MultiselectProps,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import React, { FC, useState } from "react";
import { getOptions } from "../../utils";
import _ from "lodash";

interface FilterDrawerProps {
  data: any;
  setFilters: any;
}

export const FilterDrawer: FC<FilterDrawerProps> = ({ data, setFilters }) => {
  const [selectedOptionsGenre, setSelectedOptionsGenre] =
    useState<MultiselectProps.Options>([]);
  const [selectedDistributor, setSelectedDistributor] =
    useState<MultiselectProps.Options>();
  const [selectedProduction, setSelectedProduction] =
    useState<MultiselectProps.Options>();
  const [sortOption, setSortOption] = useState<OptionDefinition>();

  const multiSelectFilterDropdownElements = [
    {
      key: "genre",
      label: "Genre",
      placeholder: "Genre",
      option: selectedOptionsGenre,
      setOption: setSelectedOptionsGenre,
    },
    {
      key: "distributors",
      label: "Distributor",
      placeholder: "Distributor",
      option: selectedDistributor,
      setOption: setSelectedDistributor,
    },
    {
      key: "production",
      label: "Production",
      placeholder: "Production",
      option: selectedProduction,
      setOption: setSelectedProduction,
    },
  ];

  const uiFilterDropdownElements = [
    {
      key: "sort",
      label: "Sort by",
      placeholder: "Sort by",
      option: sortOption,
      setOption: setSortOption,
    },
  ];

  const saveFilters = () => {
    const newFilters = {};
    multiSelectFilterDropdownElements.forEach(({ option, key }) => {
      newFilters[key] = option?.map((item) => item.label);
    });
    uiFilterDropdownElements.forEach(({ option, key }) => {
      newFilters[key] = option?.value;
    });

    setFilters(newFilters);
  };

  const handleMultiSelectOnChange = (
    selectedOptions,
    option,
    key,
    setOption,
  ) => {
    const isUnSelectAll = _.differenceBy(option, selectedOptions).find(
      ({ label }) => label === "All",
    );

    const isSelectAll = _.differenceBy(selectedOptions, option).find(
      ({ label }) => label === "All",
    );

    setOption(
      isUnSelectAll
        ? []
        : isSelectAll
        ? getOptions(key, data)
        : selectedOptions,
    );
  };

  return (
    <Drawer header={<h2>Additional Filters</h2>}>
      <Box margin={{ bottom: "l" }}>
        <SpaceBetween direction="vertical" size="l">
          {multiSelectFilterDropdownElements.map(
            ({ key, label, placeholder, option, setOption }) => (
              <FormField label={label} key={label}>
                <Multiselect
                  selectedOptions={option}
                  onChange={({ detail }) => {
                    handleMultiSelectOnChange(
                      detail.selectedOptions,
                      option,
                      key,
                      setOption,
                    );
                  }}
                  options={getOptions(key, data)}
                  placeholder={placeholder}
                  hideTokens
                />
              </FormField>
            ),
          )}

          {/* {uiFilterDropdownElements.map(
            ({ key, label, placeholder, option, setOption }) => (
              <FormField label={label} key={label}>
                <Select
                  placeholder={placeholder}
                  selectedOption={option}
                  onChange={({ detail }) => setOption(detail.selectedOption)}
                  options={getOptions(key, data)}
                  filteringType="auto"
                />
              </FormField>
            ),
          )} */}
        </SpaceBetween>
      </Box>
      <Box float="right" margin={{ top: "l" }}>
        <SpaceBetween direction="horizontal" size="xxs">
          <Button variant="normal" onClick={saveFilters}>
            Apply
          </Button>
        </SpaceBetween>
      </Box>
    </Drawer>
  );
};
