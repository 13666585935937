import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import axiosInstance from "../../../utils/axios";
import { fetchGlobalPref } from "./global-pref-action";

const initialState = {
  preferences: null,
};

export const fetchPersonalGlobalPref = createAsyncThunk(
  "globalPersonalPref/fetchPersonalGlobalPref",

  async (personal, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.request({
        url: `/preferences/personal/${personal}/pantheon`,
        method: `GET`,
      });
      return response;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const savePersonalPreferenceApi = createAsyncThunk(
  "globalPersonalPref/savePersonalPreferenceApi",

  async ({ personal, data }, { rejectWithValue }) => {
    try {
      const res = await axiosInstance.post(
        `/preferences/personal/${personal}/pantheon`,
        data,
      );
      fetchGlobalPref(personal);
      return res;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

const globalPersonalPrefSlice = createSlice({
  name: "globalPersonalPref",
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPersonalGlobalPref.fulfilled, (state, { payload }) => {
      _.set(state, "preferences", payload?.data ?? null);
      _.set(state, "hasApiCompleted", true);
    });
    builder.addCase(fetchPersonalGlobalPref.rejected, (state, action) => {
      _.set(state, "hasApiCompleted", true);
    });
  },
});

const globalPersonalPrefReducer = globalPersonalPrefSlice.reducer;

export { globalPersonalPrefReducer };
