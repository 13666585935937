import moment from "moment";

const convertIntervalDate = (res) => {
  return {
    type: res.type,
    endDate: moment().toDate(),
    startDate: moment().subtract(res.amount, res.unit).toDate(),
    amount: res.amount,
    unit: res.unit,
  };
};

export default convertIntervalDate;
