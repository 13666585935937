import React from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { FranchiseHome } from "./components/FranchiseHome";

const breadcrumbs = [
  { text: "Explore", href: "/explore" },
  { text: "Franchises" },
];

export default function ExploreFranchise({
  itemData,
  onFranchiseCreateSubmit,
  ...props
}) {
  return (
    <Layout
      title="Explore Franchise"
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      contentType="table"
      content={<FranchiseHome itemData={itemData} />}
    />
  );
}
