import React, { useCallback } from "react";
import { useAuthContext } from "../../../auth/useAuthContext";
import {
  deleteItemAction,
  getItemData,
  trackIP,
  unTrackIP,
} from "../redux/actions/item-actions";
import { useParams } from "react-router";

export const useItemActions = (flashbarRef) => {
  const { id } = useParams();
  const { user } = useAuthContext();

  const setFlashBarLoading = useCallback(
    (item, isTracking) => {
      flashbarRef?.current?.setFlashbarMessage(
        "loading",
        <div>
          {isTracking ? "Tracking" : "Untracking"} title{" "}
          <b>{item?.data.name}</b>
        </div>,
      );
    },
    [flashbarRef],
  );

  const setFlashBarSuccess = useCallback(
    (item, isTracking) => {
      flashbarRef?.current?.setFlashbarMessage(
        "success",
        <div>
          Successfully {isTracking ? "tracked" : "untracked"} title
          <b>{item?.data.name}</b>
        </div>,
        null,
        5000,
      );
    },
    [flashbarRef],
  );

  const trackItem = useCallback(
    (item) => {
      setFlashBarLoading(item, true);

      trackIP(
        item?.ip_id,
        item?.ip_list,
        user.username,
        user["custom:role"],
      ).then((d) => {
        setTimeout(() => {
          getItemData(id, {});
          setFlashBarSuccess(item, true);
        }, 1000 * 6);
      });
    },
    [id, setFlashBarLoading, setFlashBarSuccess, user],
  );

  const unTrackItem = useCallback(
    (item) => {
      setFlashBarLoading(item, false);
      unTrackIP(
        item?.ip_id,
        item?.ip_list,
        user.username,
        user["custom:role"],
      ).then((d) => {
        setTimeout(() => {
          getItemData(id, {});
          setFlashBarSuccess(item, false);
        }, 1000 * 6);
      });
    },
    [id, setFlashBarLoading, setFlashBarSuccess, user],
  );

  const deleteItem = useCallback((ipId: string) => {
    deleteItemAction(ipId).then(() => {
      window.location.href = "/explore/movies";
    });
  }, []);

  return { trackItem, unTrackItem, deleteItem };
};
