import {
  Box,
  Button,
  Flashbar,
  FormField,
  Input,
  Modal,
  SpaceBetween,
  Spinner,
} from "@cloudscape-design/components";
import React, { FC, useState } from "react";
import { Controller, Resolver, useForm } from "react-hook-form";
import FormProvider from "../../../components/forms/FormProvider";
import { isNone } from "../../../utils/sugarUtils";
import { useCreateMedia } from "../api/hooks/useCreateMedia";
import { MediaCard } from "./MediaCard";
import { ID_TYPES, checkIdType } from "../utils/checkIdType";

type AddMediaModalprops = {
  visible: boolean;
  vertical: string;
  header: string;
  placeholder: string;
  onDismiss: () => void;
};

export const AddMediaModal: FC<AddMediaModalprops> = ({
  visible,
  vertical,
  header,
  placeholder,
  onDismiss,
}) => {
  const [showFlashbar, setShowFlashbar] = useState(false);
  const [showPoster, setShowPoster] = useState(false);
  const [items, setItems] = useState([]);

  const isInValidId = (id: string) => {
    if (vertical === "games") {
      return !(checkIdType(id) === ID_TYPES.IGDB);
    } else {
      return !(checkIdType(id) === ID_TYPES.IMDB);
    }
  };

  const resolver: Resolver<{ id: string }> = async (values) => {
    return {
      errors: {
        ...(!values.id
          ? { id: { type: "required", message: "ID is required" } }
          : isInValidId(values.id)
          ? { id: { type: "required", message: "Enter a valid ID format" } }
          : {}),
      },
      values,
    };
  };

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver,
  });

  const { control, formState, watch, reset, handleSubmit } = methods;

  const onSubmit = () => {
    try {
      createMedia(watch().id);
    } catch (e) {}
  };

  const closeModal = () => {
    setShowPoster(false);
    setShowFlashbar(false);
    setItems([]);
    reset();
    onDismiss();
  };

  const getItems = (message: string = `${vertical} creation failed`) => [
    {
      type: "error",
      content: message,
      dismissible: true,
      dismissLabel: "Dismiss message",
      onDismiss: () => setItems([]),
      id: "message_1",
    },
  ];

  const getCallback = (message: string) => {
    const items = getItems(message);
    setItems(items);
    setShowFlashbar(true);

    setTimeout(() => {
      setShowFlashbar(false);
    }, 5000);
  };

  const onSuccess = () => setShowPoster(true);
  const onError = (message: string) => getCallback(message);

  const {
    data: mediaData,
    mutate: createMedia,
    isLoading,
  } = useCreateMedia({
    vertical,
    onSuccess,
    onError,
  });

  return (
    <Modal onDismiss={closeModal} visible={visible} header={header}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <SpaceBetween direction="vertical" size="l">
          {showPoster ? (
            <>
              <MediaCard data={mediaData} />
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button
                    variant="primary"
                    formAction="none"
                    onClick={closeModal}
                  >
                    Close
                  </Button>
                </SpaceBetween>
              </Box>
            </>
          ) : (
            <>
              {showFlashbar && <Flashbar items={items} />}
              <Controller
                name={"id"}
                control={control}
                render={({ field }) => {
                  return (
                    <FormField
                      stretch={true}
                      errorText={formState.errors?.id?.message ?? ""}
                    >
                      <Input
                        {...field}
                        value={field.value}
                        placeholder={placeholder}
                        onChange={(e) => field.onChange(e.detail.value)}
                      />
                    </FormField>
                  );
                }}
              />
              <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                  <Button variant="link" formAction="none" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    variant="primary"
                    formAction="submit"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <SpaceBetween direction="horizontal" size="xs">
                        <Spinner />
                        Adding...
                      </SpaceBetween>
                    ) : (
                      "Add"
                    )}
                  </Button>
                </SpaceBetween>
              </Box>
            </>
          )}
        </SpaceBetween>
      </FormProvider>
    </Modal>
  );
};
