import { useQuery } from "react-query";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";
import { QUERY_KEYS } from "../constants";
import {
  GetYoutubeChannelsDataProps,
  getYoutubeChannelsData,
  GetYoutubeVideosDataProps,
  getYoutubeVideosData,
} from "../request";

export const useGetYoutubeChannelsData = (
  params: GetYoutubeChannelsDataProps,
) => {
  // Serialize the params and use it as a key for the query
  const serializedParams = JSON.stringify(params);

  const { data, isLoading, error } = useQuery(
    [QUERY_KEYS.GET_YOUTUBE_CHANNELS_DATA, serializedParams],
    () => getYoutubeChannelsData(params).then(response => {
      const transformedData = response.data.data.map((channel: { trends: any[]; }) => ({
        ...channel,
        trends: channel.trends.flatMap(trendObject => {
          return Object.entries(trendObject).map(([timestamp, value]) => ({
            timestamp: Number(timestamp) * 1000,
            value,
          }));
        }).sort((a: { timestamp: number; }, b: { timestamp: number; }) => a.timestamp - b.timestamp),
      }));
      return transformedData;
    }),
  );

  return {
    data: isSomeOrElse(data, []),
    isLoading,
    error,
  };
};

export const useGetYoutubeVideosData = (
  params: GetYoutubeVideosDataProps,
) => {
  // Serialize the params and use it as a key for the query
  const serializedParams = JSON.stringify(params);

  const { data, isLoading, error } = useQuery(
    [QUERY_KEYS.GET_YOUTUBE_VIDEOS_DATA, serializedParams],
    () => getYoutubeVideosData(params).then(response => {
      const transformedData = response.data.data.map((video: { trends: any[]; }) => ({
        ...video,
        trends: video.trends.flatMap(trendObject => {
          return Object.entries(trendObject).map(([timestamp, value]) => ({
            timestamp: Number(timestamp) * 1000,
            value: Number(value),
          }));
        }).sort((a: { timestamp: number; }, b: { timestamp: number; }) => a.timestamp - b.timestamp),
      }));
      return transformedData;
    }),
  );

  return {
    data: isSomeOrElse(data, []),
    isLoading,
    error,
  };
};
