import React, { useMemo } from "react";
import { TYPE_LABELS } from "../constants";

export const useTopGenresTableConfig = ({
  genreTotals,
  selectedCategory="Movies",
  selectedGenreTypes=["genre"],
  imdbData,
  twitchData,
}) => {
  const colDefs = useMemo(() => {
    return [
      {
        headerName: "Rank",
        field: "rank",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        maxWidth: 110,
        pinned: "left",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      {
        headerName: "Genre",
        field: "label",
        flex: 1,
        wrapHeaderText: true,
        autoHeaderHeight: true,
        valueGetter: (params) => `${params.data.label} (${TYPE_LABELS[params.data.type]})`,
      },
      {
        headerName: "Total Titles",
        field: "total",
        cellRenderer: (params) => params.value.toLocaleString(),
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      ...(selectedCategory === "Gaming" ? [
        {
          headerName: "Average Viewers (Twitch)",
          field: "twitchAvgViewers",
          cellRenderer: (params) => Math.round(params.value).toLocaleString(),
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        {
          headerName: "Today's Average Viewers (Twitch)",
          field: "twitchAvgViewersDay",
          cellRenderer: (params) => Math.round(params.value).toLocaleString(),
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        {
          headerName: "Today's Average Streamers (Twitch)",
          field: "twitchAvgStreamersDay",
          cellRenderer: (params) => Math.round(params.value).toLocaleString(),
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        {
          headerName: "Viewer Trend (Twitch)",
          field: "twitchViewersSparkline",
          cellRenderer: "agSparklineCellRenderer",
          cellRendererParams: {
            sparklineOptions: {
              tooltip: {
                renderer: (params) => ({
                  content: `${params.yValue.toLocaleString()}`,
                })
              },
            },
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
      ] : [
        {
          headerName: "Average Rating (IMDb)",
          field: "imdbAvgRating",
          cellRenderer: (params) => params.value.toLocaleString(),
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        {
          headerName: "Total Votes (IMDb)",
          field: "imdbTotalVotes",
          cellRenderer: (params) => params.value.toLocaleString(),
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        {
          headerName: "Votes Today (IMDb)",
          field: "imdbTotalVotesDay",
          cellRenderer: (params) => params.value.toLocaleString(),
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
        {
          headerName: "Vote Distribution (IMDb)",
          field: "imdbVotesSparkline",
          cellRenderer: "agSparklineCellRenderer",
          cellRendererParams: {
            sparklineOptions: {
              tooltip: {
                renderer: (params) => ({
                  content: `${params.yValue.toLocaleString()}%`,
                })
              },
            },
          },
          wrapHeaderText: true,
          autoHeaderHeight: true,
        },
      ]),
    ];
  }, [selectedCategory]);

  const rowData = useMemo(() => {
    return genreTotals.filter(t => (selectedCategory ? t.genre.vertical === selectedCategory : true) && (selectedGenreTypes.includes(t.genre.type)) && t.total > 0).map((genreTotal) => ({
      label: genreTotal.genre.label,
      total: genreTotal.total,
      value: genreTotal.genre.value,
      type: genreTotal.genre.type,
      imdbAvgRating: imdbData[genreTotal.genre.value]?.average_rating ?? 0,
      imdbTotalVotes: imdbData[genreTotal.genre.value]?.total_votes ?? 0,
      imdbTotalVotesDay: imdbData[genreTotal.genre.value]?.total_votes_day ?? 0,
      imdbVotesSparkline: imdbData[genreTotal.genre.value]?.sparkline ?? [],
      twitchAvgViewers: twitchData[genreTotal.genre.value]?.average_viewers ?? 0,
      twitchAvgViewersDay: twitchData[genreTotal.genre.value]?.average_viewers_day ?? 0,
      twitchAvgStreamersDay: twitchData[genreTotal.genre.value]?.average_streamers_day ?? 0,
      twitchViewersSparkline: twitchData[genreTotal.genre.value]?.sparkline ?? [],
    })).sort((a, b) => selectedCategory === "Gaming" ? (b.twitchAvgViewersDay - a.twitchAvgViewersDay) : (b.imdbTotalVotesDay - a.imdbTotalVotesDay)).map((rowData, i) => ({ 
      ...rowData, 
      rank: i + 1,
    }));
  }, [genreTotals, selectedCategory, imdbData, twitchData, selectedGenreTypes]);

  return {
    columnDefs: colDefs,
    rowData: rowData,
  };
};