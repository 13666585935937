import { Icon, Link } from "@cloudscape-design/components";

const shouldRenderColumn = (field, weightageValues) =>
  weightageValues[mappedWeightage[field]]?.isChecked;

const getNormalScores = (field, headerName) => {
  return {
    field,
    headerName,
    filter: "agNumberNumberFilter",
    cellStyle: {
      backgroundColor: "#338fcc44",
      textAlign: "center",
    },
    headerClass: "global-platform-column",
    cellRenderer: (params) => params?.value?.toFixed(2),
  };
};

const renderNormalScoreColumns = (weightageValues) => {
  const columns = [
    { key: "wiki_score", name: "Wikipedia Score" },
    { key: "yt_view_score", name: "YouTube View Score" },
    { key: "piracy_score", name: "P2P Score" },
    { key: "twitch_score", name: "Twitch Score" },
    { key: "imdb_score", name: "IMDB Score" },
    { key: "rt_score", name: "Rotten Tomatoes Score" },
    { key: "yt_like_score", name: "YouTube Like Score" },
    { key: "yt_comment_score", name: "YouTube Comment Score" },
  ];

  const result = [];
  columns.forEach(({ key, name }) => {
    if (shouldRenderColumn(key, weightageValues)) {
      result.push(getNormalScores(key, name));
    }
  });

  return result;
};

export const mappedWeightage = {
  wiki_score: "wikipedia",
  yt_view_score: "youtubeViews",
  piracy_score: "p2p",
  twitch_score: "twitch",
  imdb_score: "imdb",
  rt_score: "rottenTomatoes",
  yt_like_score: "youtubeLikes",
  yt_comment_score: "youtubeComments",
};

const getWeightedScore = (field, headerName) => {
  // const pantheonScoreProps = field === "pantheon_score" ? { sort: "desc" } : {};

  return {
    field,
    headerName,
    filter: "agNumberNumberFilter",
    headerClass: "global-weighted-column",
    cellStyle: { backgroundColor: "#cc333344", textAlign: "center" },
    cellRenderer: (params) => params?.value?.toFixed(2),
    // ...pantheonScoreProps,
  };
};

export const getColumnDefs = (weightageValues) => [
  {
    field: "rank",
    headerName: "Rank",
    pinned: "left",
    maxWidth: 80,
  },
  {
    field: "pantheon_score",
    headerName: "Pantheon Score",
    pinned: "left",
    maxWidth: 100,
    cellRenderer: (params) => params?.value?.toFixed(2),
  },
  {
    field: "ip",
    headerName: "Title",
    minWidth: 250,
    filter: "agNumberColumnFilter",
    pinned: "left",
    cellRenderer: (params) => {

      let iconVariant;
      if (params.data.tracked === true) {
        iconVariant = "success";
      } else if (params.data.tracked === false) {
        iconVariant = "disabled";
      } else {
        iconVariant = "disabled";
      }

      if (!params.value) return null;
      const ipId = params.data.ip_id;
      const release_year = new Date(
        params.data.release_date * 1000,
      ).getFullYear();
      return (
        <div className="flex gap-2 mt-2.5 align-middle">
          <Icon
            name={
              params.data.tracked != null
                ? "status-positive"
                : "status-negative"
            }
            variant={iconVariant}
          />
          <Link href={`/item/${ipId}`}>
            <span>{params.data.ip}</span>
          </Link>
          <span className="text-xs text-slate-500 mt-0.5">
            ({params.data?.vertical?.charAt(0)}) {release_year}
          </span>
        </div>
      );
    },
  },
  {
    field: "vertical",
    headerName: "Category",
    hide: true,
  },
  {
    field: "release_date",
    headerName: "Release Date",
    hide: true,
  },
  {
    headerName: "Normalized Scores for Platforms",
    marryChildren: true,
    headerClass: "global-platform-column",
    children: renderNormalScoreColumns(weightageValues),
  },
  // {
  //   headerName: "Weighted Scores",
  //   marryChildren: true,
  //   headerClass: "global-weighted-column",
  //   children: [
  //     { ...getWeightedScore("search_score", "Search Score") },
  //     { ...getWeightedScore("demand_score", "Demand Score") },
  //     { ...getWeightedScore("engagement_score", "Engagement Score") },
  //     // { ...getWeightedScore("pantheon_score", "Pantheon Score") },
  //   ],
  // },
];
