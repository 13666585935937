import { AxiosError } from "axios";
import { useMutation } from "react-query";
import { updateDiscovery } from "../request";

export const useUpdateRowData = () => {
  const { isLoading, mutateAsync, error } = useMutation<any, AxiosError, any>(
    ({ id, data }) => updateDiscovery({ id, data }),
  );

  return { mutateAsync, isLoading, error };
};
