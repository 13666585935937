import classnames from "classnames";
import React, { FC, ReactNode, useState } from "react";
import { FaStar } from "react-icons/fa";

interface ServicesListItemProps {
  children: ReactNode;
  isFavorite: boolean;
  path: string;
  handleFavorites: () => void;
}

export const ServicesListItem: FC<ServicesListItemProps> = ({
  children,
  isFavorite,
  path,
  handleFavorites,
}) => {
  const [selected, setSelected] = useState(isFavorite);

  return (
    <div className="flex items-start group bg-slate-900/30 hover:bg-slate-700 pr-4 pt-4 pb-4 pl-2 mb-2 rounded-lg shadow-md">
      <button
        className={classnames(
          "group-hover:visible transition ease-in-out delay-50",
          {
            "text-blue-400": selected,
            "text-slate-600": !selected,
            invisible: !selected,
          },
        )}
        onClick={() => {
          // timeout is needed to perform this click action after bothe events in ServicesMenu.tsx
          setTimeout(() => {
            setSelected((prev) => !prev);
            handleFavorites();
          }, 10);
        }}
      >
        <FaStar size={24} />
      </button>
      <a className="pl-2" href={path} target="_top" aria-disabled="false">
        {children}
      </a>
    </div>
  );
};
