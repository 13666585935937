import { CHART_LABELS } from "../../hooks/useGridConfig";

export const defaultColumn = CHART_LABELS.VIEWERS_TREND;

export const additionalPlatformColumns = [
  CHART_LABELS.FRANCHISE,
  CHART_LABELS.PLATFORM,
  CHART_LABELS.GENRE,
  CHART_LABELS.THEME,
  CHART_LABELS.CUSTOM_TAGS,
  CHART_LABELS.DEVELOPER,
  CHART_LABELS.PUBLISHER,
  CHART_LABELS.AGE_RATINGS,
  CHART_LABELS.COUNTRIES,
  CHART_LABELS.PLAYER_PERSPECTIVE,
  CHART_LABELS.GAME_MODE,
  CHART_LABELS.VIEWERS_TREND,
  CHART_LABELS.BROADCASTERS_TREND,
  CHART_LABELS.FOLLOWERS_TREND,
];

export const corePlatformColumns = [
  CHART_LABELS.AVG_VIEWERS,
  CHART_LABELS.PEAK_VIEWERS,
  CHART_LABELS.AVG_BROADCASTERS,
  CHART_LABELS.PEAK_BROADCASTERS,
  CHART_LABELS.AT_PV,
  CHART_LABELS.AT_PVD,
  CHART_LABELS.MAX_FOLLOWERS,
  CHART_LABELS.NEW_FOLLOWERS,
];
