import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getTopSearchData } from "../request";

export const useGetTopSearchData = ({
  keyword="",
  fields=[],
  verticals=[],
}) => {
  const params = {
    keyword,
    fields,
    verticals,
  };

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_TOP_SEARCH_DATA, { ...params }],
    () => getTopSearchData(params),
  );

  return {
    data: data?.data,
    isLoading,
    ...error,
  };
};
