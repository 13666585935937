import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getItemWikipediaSummaryData } from "../request";

export const useGetItemWikipediaSummaryData = (id: string) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_ITEM_WIKIPEDIA_SUMMARY_DATA, id],
    () => getItemWikipediaSummaryData(id),
  );
  return {
    data: data?.data ?? {},
    isLoading,
    error,
  };
};
