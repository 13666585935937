import React from "react";
import { ItemTypes } from "../../../../utils/itemUtils";

const formatDate = (date) => {
  if (!date) return "";
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
};

const noImage = require("../../../../assets/images/icons/no-image_92.jpg");

const getItemImage = (item) => {
  switch (item.type.id) {
    case ItemTypes.TELEVISION_SERIES: // Television Series
    case ItemTypes.FILM: // Film
      if (!item.poster_path) return noImage;
      return `https://image.tmdb.org/t/p/w92${item.poster_path}`;
    case ItemTypes.VIDEO_GAME: // Video Game
      if (!item.cover_image_id) return noImage;
      return `https://images.igdb.com/igdb/image/upload/t_cover_small/${item.cover_image_id}.png`;
    case ItemTypes.FRANCHISE:
      return `https://images.searchpantheon.com/collections/${item.id}_342.jpg`;
    default:
      return noImage;
  }
};

const getItemReleaseDate = (item) => {
  switch (item.type.id) {
    case ItemTypes.TELEVISION_SERIES: // Television Series
      if (!item.first_air_date) return null;
      return new Date(Date.parse(item.first_air_date));
    case ItemTypes.FILM: // Film
      if (!item.release_date) return null;
      return new Date(Date.parse(item.release_date));
    case ItemTypes.VIDEO_GAME: // Video Game
      if (!item.first_release_date) return null;
      return new Date(item.first_release_date * 1000);
    default:
      return null;
  }
};

export const TableColumns = [
  {
    id: "poster",
    header: "Image",
    cell: (e) => (
      <a href={`/item/${e.id}`} style={{ fontWeight: "bold" }}>
        <img
          loading="lazy"
          src={getItemImage(e)}
          className="w-20"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = noImage;
          }}
        />
      </a>
    ),
    //minWidth: "50px",
    maxWidth: "100px",
  },
  {
    id: "name",
    header: "Name",
    cell: (e) => (
      <a href={`/item/${e.id}`}>
        <div style={{ fontWeight: "bold" }}>{e.name}</div>
        <div className="line-clamp-3 text-slate-400">
          {e.overview || e.summary}
        </div>
      </a>
    ),
    sortingField: "name",
    isRowHeader: true,
    minWidth: "200px",
    maxWidth: "300px",
  },
  {
    id: "type",
    header: "Type",
    cell: (e) => <span>{e.type.name}</span>,
    sortingField: "type",
    isRowHeader: true,
    //minWidth: "100px",
    //maxWidth: "300px",
  },
  {
    id: "release_date",
    header: "Release Date",
    cell: (e) => (
      <span>
        {!!getItemReleaseDate(e) && formatDate(getItemReleaseDate(e))}
      </span>
    ),
    sortingField: "release_date",
    //minWidth: "100px",
    //maxWidth: "200px",
  },
  {
    id: "id",
    header: "ID",
    cell: (e) => e.id,
    sortingField: "id",
  },
];
