import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { mapImdbResponse } from "../mappers/mapImdbResponse";
import { getIMDbData } from "../request";
import { useMemo } from "react";

export const useGetIMDbData = (
  filters = {
    imdbRating: [1, 10],
    yearRange: ["1980-01-01", `${new Date().getFullYear()}-12-31`],
    genre: "",
    distributor: "",
    ottPlatform: "",
  },
) => {
  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_IMDB_DATA, filters],
    () => getIMDbData(filters),
  );

  const memoData = useMemo(() => mapImdbResponse(data), [data]);

  return {
    data: data ? memoData : [],
    isLoading,
    ...(error as any),
  };
};
