import { FILTER_JOIN_OPERATIONS, FILTER_OPERATIONS, FILTER_TYPES, VERTICALS } from "../../../../../config-global";


export const DEFAULT_FILTER_OBJECTS = {
  [VERTICALS.MOVIES]: {
    category: VERTICALS.MOVIES,
    name: "Default movie filters",
    advancedFilter: null,
    titleSearchQuery: "",
    filters: [
      {
        name: "Genre",
        key: "genre",
        filterType: FILTER_TYPES.OBJECT,
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Franchise", 
        key: "franchise", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Distributor", 
        key: "distributors", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        vertical: VERTICALS.MOVIES,
      },
      { 
        name: "Production", 
        key: "production", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        vertical: VERTICALS.MOVIES,
      },
    ],
  },
  [VERTICALS.SERIES]: {
    category: VERTICALS.SERIES,
    name: "Default series filters",
    advancedFilter: null,
    titleSearchQuery: "",
    filters: [
      { 
        name: "Genre", 
        key: "genre", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: false,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Franchise", 
        key: "franchise", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Distributor", 
        key: "distributors", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        vertical: VERTICALS.SERIES,
      },
      { 
        name: "Production", 
        key: "production", 
        filterType: FILTER_TYPES.OBJECT, 
        filterOperation: FILTER_OPERATIONS.CONTAINS,
        valueJoinOperation: FILTER_JOIN_OPERATIONS.OR,
        autoComplete: true,
        vertical: VERTICALS.SERIES,
      },
    ],
  },
};