import React from "react";
import { navItems } from "../../../layouts/common/menu/side-menu";
import { Layout } from "../Layout";
import { ChannelsTable } from "./ChannelsTable";

const breadcrumbs = [
  { text: "YouTube", href: "/youtube" },
  { text: "Channels" },
];

export const YoutubeChannels = () => {
  return (
    <Layout
      title="Channels"
      breadcrumbs={breadcrumbs}
      navItems={navItems}
      content={<ChannelsTable />}
      contentType="table"
      headerSelector="#header"
    />
  );
};
