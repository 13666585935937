import React, { useState, useRef, useEffect } from "react";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useGetGridTheme } from "../../../hooks/UseTheme/useGetGridTheme";
import { useFetchServerSideGridData } from "../../platforms/hooks/useFetchServerSideGridData";
import { usePlatformRankingTableConfig } from "../hooks/usePlatformRankingTableConfig";
import { SpaceBetween } from "@cloudscape-design/components";
import { genreCategoryOptions, defaultCategory } from "../constants";

export const PlatformRankingTable = ({
  fetchMethod,
  extraColumnDefs=[],
  selectedCategory=genreCategoryOptions.find(({ value }) => value === defaultCategory),
  selectedGenres=[],
  filter={},
}) => {
  const gridRef = useRef(null);
  const { theme } = useGetGridTheme();
  
  const [ paginationPageSize, setPaginationPageSize ] = useState(20);

  const {
    columnDefs,
    defaultColDef,
  } = usePlatformRankingTableConfig({
    selectedGenres,
  });

  useFetchServerSideGridData({
    filter,
    vertical: selectedCategory.value,
    gridRef,
    fetchMethod,
    disabled: (fetchMethod === null) ? true : false,
  });

  return (
    <SpaceBetween size="m" direction="vertical">
      <div style={{ height: "70vh" }} className={theme}>
        <AgGridReact
          ref={gridRef}
          defaultColDef={defaultColDef}
          columnDefs={[...columnDefs, ...extraColumnDefs]}
          paginationPageSize={paginationPageSize}
          pagination={true}
          paginationPageSizeSelector={[20, 50, 100]}
          onPaginationChanged={(params) => {
            const size = params.api.paginationGetPageSize();
            if (size !== paginationPageSize) setPaginationPageSize(size);
          }}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={false}
          rowModelType={"serverSide"}
          blockLoadDebounceMillis={100}
        />
      </div>
    </SpaceBetween>
  );
};