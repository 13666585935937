import "ag-grid-enterprise";
import React from "react";
import { PlatformTrendsCharts } from "../../components/PlatformTrendsCharts";
import { getPiracyTrendsData } from "../api/request";
import { piracyCategoryOptions, piracyTrendsApiParams, defaultCategory as defCategory } from "../constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "../utils/gridConfig";

export const PiracyTrendsCharts = ({
  defaultCategory=defCategory,
  setSelectedVertical,
}) => {
  return (
    <PlatformTrendsCharts
      apiParams={piracyTrendsApiParams}
      defaultColumn={defaultColumn}
      defaultCategory={defaultCategory}
      categoryOptions={piracyCategoryOptions}
      corePlatformColumns={corePlatformColumns}
      fetchMethod={getPiracyTrendsData}
      additionalPlatformColumns={additionalPlatformColumns}
      setSelectedVertical={setSelectedVertical}
    />
  );
};
