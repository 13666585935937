export const rolesFilteringProperties = [
  {
    propertyLabel: "Name",
    key: "roleName",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));

export const roleVisibleContentPreference = [
  {
    id: "roleName",
    label: "Name",
    visible: true,
  },
  {
    id: "permissions",
    label: "Permissions",
    visible: true,
  },
  { id: "createdAt", label: "Created At" },
  { id: "updatedAt", label: "Updated At" },
];
