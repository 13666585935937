export const permissionsFilteringProperties = [
  {
    propertyLabel: "Name",
    key: "permission_name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));
export const permissionsVisibleContentPreference = [
  {
    id: "permission_name",
    label: "Name",
    visible: true,
  },
  {
    id: "api_route",
    label: "Route",
    visible: true,
  },
  { id: "created", label: "Created At", visible: false },
  { id: "last_updated", label: "Updated At", visible: false },
];
