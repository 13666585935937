import { Spinner } from "@cloudscape-design/components";

const CustomLoadingOverlay = ({ compact = false }) => {
  const imageSize = compact ? "50px" : "100px";

  return (
    <div
      className={"ag-overlay-loading-center"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/*<img
        src="/images/loading.gif"
        alt="Loading..."
        style={{ width: imageSize, height: imageSize }}
      />*/}
      <Spinner size="large" />
    </div>
  );
};

export default CustomLoadingOverlay;
