import React, { useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import "./App.css";
import "./ag-styles.css";
import { ThemeProvider } from "./app/ThemeProvider";
import { ReactQueryWrapper } from "./app/ReactQueryWrapper";
import { persistor, store } from "./app/store.js";
import { AuthProvider } from "./auth/AwsCognitoContext.js";
import SnackBar from "./components/notify/SnackBar/SnackBar.js";
import SnackbarContext from "./hooks/notify/snack-bar/snackbar-context.js";
import Navigator from "./router/Navigator.jsx";

function App() {
  const snackbarCtx = useContext(SnackbarContext);
  const link = document.createElement("link");
  link.href =
    "https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&display=swap";
  link.rel = "stylesheet";
  document.head.appendChild(link);

  return (
    <AuthProvider>
      <HelmetProvider>
        <ReduxProvider store={store}>
          <ThemeProvider>
            <ReactQueryWrapper>
              <PersistGate loading={null} persistor={persistor}>
                <Navigator />
                {snackbarCtx.isDisplayed && <SnackBar />}
              </PersistGate>
            </ReactQueryWrapper>
          </ThemeProvider>
        </ReduxProvider>
      </HelmetProvider>
    </AuthProvider>
  );
}

export default App;
