import _ from "lodash";

export const getNestedObjectValue = (obj: Object = {}, path: string) =>
  path.split(".").reduce((acc, part) => acc && acc[part], obj);

export const generateNestedObject = (
  obj: Object = {},
  path: string,
  value: any,
) => {
  const keys = path.split(".");
  const newObject = { ...obj };
  let currentObject = newObject;

  for (const key of keys.slice(0, -1)) {
    currentObject[key] = { ...currentObject[key] };
    currentObject = currentObject[key];
  }

  currentObject[keys[keys.length - 1]] = value;

  return newObject;
};

export const arrayDifference = (
  arr1: Array<any>,
  arr2: Array<any>,
): Array<any> => {
  const diff1 = _.difference(arr1, arr2);
  const diff2 = _.difference(arr2, arr1);

  return [...diff1, ...diff2];
};
