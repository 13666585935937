import { yupResolver } from "@hookform/resolvers/yup";
import React, { useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { useAuthContext } from "../../auth/useAuthContext";
import FormProvider from "../../components/forms/FormProvider";
import LoadingButton from "../../components/forms/LoadingButton";
import PasswordTextField from "../../components/forms/PasswordTextField";
import { TextField } from "../../components/forms/TextField";
import SnackbarContext from "../../hooks/notify/snack-bar/snackbar-context";
import { AuthLayout } from "../../layouts/AuthLayout";
import "./login.css";

export const LoginPage = () => {
  const { login } = useAuthContext();
  const snackbarCtx = useContext(SnackbarContext);

  const loginFormDefaultData = {
    username: "",
    password: "",
  };
  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password field is required"),
  });

  const methods = useForm({
    defaultValues: loginFormDefaultData,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(loginSchema),
  });

  const {
    getValues,
    handleSubmit,

    formState: { isSubmitting },
  } = methods;

  const onSubmit = async ({ username, password }) => {
    try {
      await login(username, password);
    } catch (error) {
      console.error(error.message);
      snackbarCtx.displayMsg("Error", error.message, "error", 3000);
    }
  };

  return (
    <AuthLayout>
      <Helmet>
        <title> Login | Pantheon</title>
      </Helmet>

      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(onSubmit)}
        id="loginPage"
      >
        <TextField
          name={"username"}
          label="Email"
          placeholder={"Email"}
          type={"text"}
        />
        <PasswordTextField
          name={"password"}
          label="Password"
          placeholder={"Password"}
        />

        <div className="mt-5">
          <LoadingButton
            text={"Sign In"}
            loading={isSubmitting}
            type={"submit"}
            classes={"bg-blue-500 font-bold text-lg"}
          />
        </div>
      </FormProvider>
      <div className="flex items-center justify-between mt-5">
        <div className="flex items-center"></div>

        <div className="text-sm">
          <a
            href="/forgot-password"
            className="font-medium text-lg text-blue-500 hover:text-blue-600"
            onClick={() => {
              localStorage.setItem("email", getValues().username);
            }}
          >
            Forgot your password?
          </a>
        </div>
      </div>
    </AuthLayout>
  );
};
