import * as Yup from 'yup';

export const createIssueFormValidation = Yup.object().shape({
  title: Yup.string().required("Title is required").max(50, "Title can't exceed 50 characters"),
  type: Yup.object().required("Type is required"),
  description: Yup.string().required("Description is required"),
});
export const editIssueFormValidation = Yup.object().shape({
  title: Yup.string().required("Title is required").max(50, "Title can't exceed 50 characters"),
  type: Yup.object().required("Type is required"),
  status: Yup.object().required("Type is required"),
  description: Yup.string().required("Description is required"),
});
export const ISSUE_TYPES = [
  { label: "Bug", value: "Bug" },
  { label: "New Feature", value: "New Feature" },
  { label: "Feedback", value: "Feedback" },
];
export const STATUSES = [
  { label: "Open", value: "Open" },
  { label: "Closed", value: "Closed" },
];
export const qModules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"]
  ],
};


export const FILTERING_PROPERTIES = [
  {
    propertyLabel: "Title",
    key: "title",
    groupValuesLabel: "Title values",
    operators: [":", "="],
  },
  {
    propertyLabel: "Status",
    key: "status",
    groupValuesLabel: "Status",
    operators: [":", "="],
    values: ["Open", "Closed"],
  },
  {
    propertyLabel: "Description",
    key: "description",
    groupValuesLabel: "Description",
    operators: [":", "="],
  },
];