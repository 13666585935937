import { Fragment, useContext, useState } from 'react'
import { Transition } from '@headlessui/react'
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';

import { XMarkIcon } from '@heroicons/react/20/solid'
import SnackbarContext from '../../../hooks/notify/snack-bar/snackbar-context';




export default function SnackBar() {
    const snackbarCtx = useContext(SnackbarContext);
    const icons = [
        { type: "error", icon: <ExclamationCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" /> },
        { type: "success", icon: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" /> },
        { type: "info", icon: <InformationCircleIcon className="h-6 w-6 text-400" aria-hidden="true" /> },
        { type: "warning", icon: <ExclamationTriangleIcon className="h-6 w-6 text-yellow-400" aria-hidden="true" /> },
    ];

    const colors = [
        { type: "error", color: "bg-red-100" },
        { type: "success", color:"bg-green-100"},
        { type: "info", color: "bg-white"},
        { type: "warning", color:"bg-yellow-100"}
    ];

    const getIcon = (type) => {

        return icons.find(item => item.type === type).icon;

    }

    const getColor = (type) => {

        return colors.find(item => item.type === type).color;

    }

    return (
        <>
            {/* Global notification live region, render this permanently at the end of the document */}
            <div
                aria-live="assertive"
                className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-10"
            >
                <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
                    {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
                    <Transition
                        show={snackbarCtx.isDisplayed}
                        as={Fragment}
                        enter="transform ease-out duration-300 transition"
                        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg ${getColor(snackbarCtx.type)} shadow-lg ring-1 ring-black ring-opacity-5`}>
                            <div className="p-4">
                                <div className="flex items-start">
                                    <div className="flex-shrink-0">
                                        {getIcon(snackbarCtx.type)}
                                    </div>
                                    <div className="ml-3 w-0 flex-1 pt-0.5">
                                        <p className="text-sm font-medium text-gray-900">{snackbarCtx.title}</p>
                                        <p className="mt-1 text-sm text-gray-500">{snackbarCtx.msg}</p>
                                    </div>
                                    <div className="ml-4 flex flex-shrink-0">
                                        <button
                                            type="button"
                                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                                            onClick={() => {
                                                snackbarCtx.onClose();
                                            }}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className={`h-5 w-5 ${getColor(snackbarCtx.type)} `} aria-hidden="true" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Transition>
                </div>
            </div>
        </>
    )
}
