import { Container, Header } from "@cloudscape-design/components";
import React from "react";
import { AwardsTable } from "../../components/AwardsTable";

const awardEvents = [
  "Academy Awards, USA",
  "Golden Globes, USA",
  "Sundance Film Festival",
  "Critics Choice Awards",
  "Screen Actors Guild Awards",
];

const shortenAwardName = (fullName: string) => {
  const nameMap = {
    "Academy Awards, USA": "Academy Awards",
    "Golden Globes, USA": "Golden Globes",
    "Sundance Film Festival": "Sundance",
    "Critics Choice Awards": "Critics' Choice",
    "Screen Actors Guild Awards": "SAG Awards",
  };

  return nameMap[fullName] || fullName;
};

export const Awards = ({ itemData }: { itemData: any }) => {
  const awardCounts = {};

  // Calculate award counts
  awardEvents.forEach((event) => {
    awardCounts[event] = itemData.data.awards
      ? itemData.data.awards.filter((award) => award.event === event).length
      : 0;
  });

  const awardstats = Object.keys(awardCounts)
    .filter((event) => awardCounts[event] > 0)
    .map((event) => ({
      name: event,
      stat: awardCounts[event],
    }));

  const getIconPath = (awardTitle: string) => {
    const formattedTitle = awardTitle.toLowerCase().replace(/[^a-z0-9]/g, "_");

    return `/awards/award_${formattedTitle}.png`;
  };

  return (
    <Container
      header={
        <Header>
          <div>
            <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              {awardstats.map((item, index) => (
                <div
                  key={index}
                  className="mt-2 overflow-hidden rounded-2xl shadow-lg dark:bg-darkerBg px-4 py-5 sm:p-6 flex items-center"
                >
                  <div className="award-icon">
                    <img
                      src={getIconPath(item.name)}
                      alt={`${item.name} icon`}
                      className="h-[50px] object-contain"
                    />
                  </div>
                  <div className="ml-4">
                    <div className="truncate text-sm font-bold">
                      {shortenAwardName(item.name)}
                    </div>
                    <div className="mt-1 text-3xl font-semibold tracking-tight">
                      {item.stat}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Header>
      }
    >
      <AwardsTable itemData={itemData} />
    </Container>
  );
};
