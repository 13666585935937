import moment from "moment";

export const isDataAvailable = (data) =>
  data?.errors == null ||
  data?.errors?.length === 0 ||
  data?.errors?.filter((x) => x.code === "NO_DATA_AVAILABLE")?.length === 0;

export const calculateStartDate = (releaseDate) => {
  const now = moment();
  const xMonthsAgo = now.clone().subtract(2, "months");
  const releaseDateMoment = moment(releaseDate);

  if (releaseDateMoment.isAfter(now)) {
    return now.subtract(30, "days").format("YYYY-MM-DD");
  } else if (releaseDateMoment.isAfter(xMonthsAgo)) {
    return releaseDateMoment.subtract(20, "days").format("YYYY-MM-DD");
  } else {
    return xMonthsAgo.format("YYYY-MM-DD");
  }
};
