import React, { useMemo, useState } from "react";
import { Box, Table, Link } from "@cloudscape-design/components";
import SocialPlatforms from "./SocialPlatform";
import { transformApiResponse } from "./utils";

const getImageUrl = (type, id, imageUrl) => {
  switch (type) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${imageUrl}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${imageUrl}`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${id}_684.jpg`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${imageUrl}.png`;
    default:
      return "NA";
  }
};

const TableColumns = [
  {
    id: "poster",
    header: "Poster",
    width: 100,
    minWidth: 100,
    cell: (e) => {
      const imageUrl = getImageUrl(e.type, e.id, e.imageUrl);
      return (
        <div>
          {imageUrl !== "NA" ? (
            <img src={imageUrl} alt={e.name} />
          ) : (
            <div className="h-10 w-10 flex items-center justify-center rounded-lg bg-gray-400">
              <span className="text-white text-xl">?</span>
            </div>
          )}
        </div>
      );
    },
  },

  {
    id: "name",
    header: "Name",
    width: 500,
    minWidth: 300,
    cell: (e) => (
      <div>
        <Link
          href={`${window.location.origin}/item/${e.id}`}
          fontSize="heading-m"
        >
          {e.name}
        </Link>
        <br />
        <div>{e.description}</div>
          <span >
            Genres:
            {e.genre ? (
              e.genre.map((g, index) => (
                <span key={index} className="m-1 inline-block whitespace-nowrap bg-blue-900 text-white py-1 px-3 rounded-full text-xs">
                  {g}
                </span>
              ))
            ) : (<>N/A</>)
            }
          </span>
      </div>
    ),
    sortingField: "name",
  },
  {
    id: "parentCompany",
    header: "Parent Company",
    width: 200,
    minWidth: 200,
    cell: (e) => {
      return <span>{e.parentCompany}</span>;
    },
    sortingField: "parentCompany",
  },
  {
    id: "type",
    header: "Type",
    width: 150,
    minWidth: 150,
    cell: (e) => {
      return <span>{e.type}</span>;
    },
    sortingField: "type",
  },
  {
    id: "platforms",
    header: "Platforms",
    cell: (e) => {
      return (
        <div>
          <SocialPlatforms item={e} />
        </div>
      );
    },
    width: 300,
    minWidth: 200,
  },
];

export default function TableView({ items, loading, preferences }) {
  const [sortingColumn, setSortingColumn] = useState(TableColumns[1]);
  const [sortingDescending, setSortingDescending] = useState(false);

  const handleSortingChange = (event) => {
    setSortingDescending(event.detail.isDescending);
    setSortingColumn(event.detail.sortingColumn);
  };

  const data = useMemo(() => {
    return transformApiResponse(items);
  }, [items]);

  const sortedItems = useMemo(() => {
    const id = sortingColumn.id;
    data.sort((a, b) => {
      let fa = a?.[id]?.toLowerCase(),
        fb = b?.[id]?.toLowerCase();

      if (fa < fb) {
        return sortingDescending ? 1 : -1;
      }
      if (fa > fb) {
        return sortingDescending ? -1 : 1;
      }

      return 0;
    });

    return data;
  }, [sortingColumn, sortingDescending, data]);

  return (
    <>
     
        <Table
          onSortingChange={handleSortingChange}
          sortingColumn={sortingColumn}
          sortingDescending={sortingDescending}
          visibleColumns={["name", ...preferences.visibleContent]}
          loading={loading}
          columnDefinitions={TableColumns}
          items={sortedItems}
          loadingText="Loading resources"
          wrapLines
          // stickyHeader
          resizableColumns={true}
          variant="borderless"
          empty={
            <Box textAlign="center" color="inherit">
              <b>No items</b>
              <Box padding={{ bottom: "s" }} variant="p" color="inherit">
                No items to display.
              </Box>
            </Box>
          }
        />
      
    </>
  );
}
