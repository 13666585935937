import React, { useState } from 'react';

interface VideoTitleRendererProps {
  node: { group: boolean };
  data: { title: string; video_id: string };
  value: string;
}

const VideoTitleRenderer: React.FC<VideoTitleRendererProps> = (props) => {
  const [expanded, setExpanded] = useState(false);
  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  if (props.node.group) {
    return props.value;
  }
  return (
    <div>
      <button
        onClick={toggleExpand}
        className="text-blue-600 hover:blue-800 dark:text-blue-400 dark:hover:text-blue-200"
      >
        {props.data.title} {expanded ? '▲' : '▼'}
      </button>
      {expanded && (
        <div style={{ height: "200px", width: "100%", marginBottom: "10px" }}>
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${props.data.video_id}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default VideoTitleRenderer;
