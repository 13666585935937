import { SplitPanel } from "@cloudscape-design/components";
import DatapointComparison from "../../../../../components/datapoint-comparison/DatapointComparison";
import { METRIC_GROUPS } from "../../../../../components/datapoint-comparison/constants";
import { useEffect, useMemo, useState } from "react";
import { useMetrics } from "../../../../tools/compare/hooks/useMetrics";

export const CompareContainer = ({ ipIds, dateRange, onParametersChanged }) => {

  const { metrics } = useMetrics({});

  const [properties, setProperties] = useState({});

  useEffect(() => {
    const param = {
      dateRange: {
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      }
    };
    if (ipIds?.length > 0) {
      param.titleIds = ipIds;
    }
    setProperties({
      metricKeys: metrics
        .filter((metric) => metric.group === METRIC_GROUPS.ZSCORE)
        .map((metric) => metric.key),
      activeMetricKey: properties.activeMetricKey ?? "summary",
      parameters: [
        param,
      ],
    });
  }, [ipIds, dateRange]);

  useEffect(() => {
    if (!properties) return;
    onParametersChanged(properties.parameters);
  }, [properties]);

  return (
    <SplitPanel
      header="Compare Titles"
      i18nStrings={{
        preferencesTitle: "Preferences",
        preferencesPositionLabel: "Panel position",
        preferencesPositionDescription:
          "Choose the default position for the split panel.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesCancel: "Cancel",
        preferencesConfirm: "Confirm",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
    >
      <div className="mb-4">
        <DatapointComparison
          metrics={metrics}
          properties={properties}
          setProperties={setProperties}
          options={{
            container: {
              disablePadding: true,
              disableBorder: true,
            },
            parameters: {
              enabled: true,
              variant: "tokens",
              showDateRanges: false,
            },
            chart: {
              height: 400,
            },
            summary: {
              enabled: true,
            },
          }}
        />
      </div>
    </SplitPanel>
  );
};
