/* PLEASE DO NOT REFORMAT THIS FILE (breaks spacing in certain areas) */

import {
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  FormField,
  Header,
  Link,
  Popover,
  Select,
  SpaceBetween,
  Spinner,
  Toggle,
} from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import { Layout } from "../Layout";
import TopicSearchBar from "../components/TopicSearchBar";
import { rawNameToTitle, slugify } from "../utils/name-utils";
import {
  addToFavorites,
  callPreferences,
  removeFromFavorites,
} from "./preferences";
import { useGetGridTheme } from "../../../hooks/UseTheme/useGetGridTheme";
import { useGetChartTheme } from "../../../hooks/UseTheme/useGetChartTheme";

const TopicModelingTrueCrimeTopicPageV2 = () => {
  const { theme } = useGetGridTheme();
  const { theme: chartTheme } = useGetChartTheme();
  const flashbarRef = useRef(null);

  const dl_overviewGridRef = useRef(null);
  const dl_postsOverTimeGridRef = useRef(null);
  const dl_postsGridRef = useRef(null);
  const dl_videosGridRef = useRef(null);

  const { topicId } = useParams();

  const domain = "true-crime";
  const [breadcrumbs, setBreadcrumbs] = useState([
    { text: "Topic Modeling" },
    { text: "True Crime", href: "/topic-modeling/true-crime" },
    { text: "Topic", href: "/" },
  ]);
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const defaultDateRange = {
    type: "absolute",
    startDate: moment().subtract(2, "weeks").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
  const granularityOptions = [
    { label: "Day", value: "day" },
    { label: "Week", value: "week" },
    { label: "Month", value: "month" },
  ];
  const sortOptions = [
    { label: "Popularity", value: "popularity" },
    { label: "Date", value: "date" },
  ];
  const postsPageSize = 50;

  const [topicsOverTimeResponse, setTopicsOverTimeResponse] =
    useState(defaultResponseState);
  const [postsByTopicResponse, setPostsByTopicResponse] =
    useState(defaultResponseState);
  const [videosByTopicResponse, setVideosByTopicResponse] =
    useState(defaultResponseState);

  const [topicLabel, setTopicLabel] = useState("");
  const [topicSeriesData, setTopicSeriesData] = useState([]);
  const [topicWordsSeriesData, setTopicWordsSeriesData] = useState([]);
  const [topicTotalPosts, setTopicTotalPosts] = useState(0);
  const [topicIps, setTopicIps] = useState([]);
  const [showMarkers, setShowMarkers] = useState(false);
  const [namesShowMore, setNamesShowMore] = useState(false);
  const [granularity, setGranularity] = useState(granularityOptions[1]);
  const [postsTargetDate, setPostsTargetDate] = useState(defaultDateRange);
  const [selectedPostsSort, setSelectedPostsSort] = useState(sortOptions[0]);
  const [postsCache, setPostsCache] = useState({});
  const [currentPosts, setCurrentPosts] = useState([]);

  const [isFavorite, setIsFavorite] = useState(null);

  const handleTopicsOverTimeResponse = (response) => {
    const label = response.topic_labels[topicId];
    const words = response.topic_words[topicId];
    const ips = response.topic_ips;
    const timeseriesData = response.data;

    const seriesData = timeseriesData.map((item) => ({
      timestamp: new Date(moment(item.timestamp).toDate()),
      value: item.data[0].count,
      num_upvotes: item.data[0].num_upvotes,
      num_comments: item.data[0].num_comments,
    }));

    const wordsSeriesData = words.map((item) => ({
      word: item.word,
      score: item.score,
    }));

    if (
      seriesData.slice(-1)[0].timestamp - seriesData[0].timestamp <
      1000 * 60 * 60 * 24 * 7 * 4
    ) {
      setGranularity(granularityOptions[0]);
    }

    setTopicLabel(label);
    setTopicSeriesData(seriesData);
    setTopicWordsSeriesData(wordsSeriesData);
    setTopicIps(ips);
    setTopicTotalPosts(
      timeseriesData.reduce((acc, item) => acc + item.data[0].count, 0),
    );
    setBreadcrumbs([
      { text: "Topic Modeling" },
      { text: "True Crime", href: "/topic-modeling/true-crime" },
      {
        text: "Topic: " + label,
        href: `/topic-modeling/true-crime/topic/${topicId}`,
      },
    ]);
  };

  const groupSeries = (seriesData, groupBy) => {
    const grouped = {};

    seriesData.forEach((item) => {
      const startOfGroup = moment(item.timestamp).startOf(groupBy).toDate();
      const key = moment(startOfGroup).format("YYYY-MM-DD");

      if (!grouped[key]) {
        grouped[key] = { timestamp: startOfGroup, value: 0 };
      }
      grouped[key].value += item.value;
    });

    const newSeriesData = Object.values(grouped).sort(
      (a, b) => a.timestamp - b.timestamp,
    );

    return newSeriesData;
  };

  const setPostsPage = (dateRange, page, sort) => {
    const startDate = moment(dateRange.startDate).format("YYYY-MM-DD");
    const endDate = moment(dateRange.endDate).format("YYYY-MM-DD");
    const key = `${startDate}_${endDate}_${sort}_${topicId}_${page}`;
    const newPostsCache = { ...postsCache };
    if (!(key in newPostsCache)) {
      let params = {
        start_date: startDate,
        end_date: endDate,
        min_prob: 0,
        topic_ids: topicId,
        size: postsPageSize,
        page: page - 1,
        order: sort,
      };
      const existingQueryKey = Object.keys(newPostsCache).find((key) =>
        key.startsWith(`${startDate}_${endDate}_${sort}_`),
      );
      if (existingQueryKey) {
        const executionId = newPostsCache[existingQueryKey].execution_id;
        console.log(executionId);
        params.execution_id = executionId;
      }
      getPostsByTopic(params).then((data) => {
        newPostsCache[key] = data;
        setPostsCache(newPostsCache);
        setCurrentPosts(data);
      });
    } else {
      setCurrentPosts(newPostsCache[key]);
    }
  };

  const downloadPage = () => {
    console.log(dl_overviewGridRef);
    const spreadsheets = [
      dl_overviewGridRef.current.api.getSheetDataForExcel({
        sheetName: "Topic Overview",
      }),
      dl_postsOverTimeGridRef.current.api.getSheetDataForExcel({
        sheetName: "Posts Over Time",
      }),
    ];
    if (dl_postsGridRef?.current?.api) {
      spreadsheets.push(
        dl_postsGridRef.current.api.getSheetDataForExcel({
          sheetName: "Reddit Posts",
        }),
      );
    }
    if (dl_videosGridRef?.current?.api) {
      spreadsheets.push(
        dl_videosGridRef.current.api.getSheetDataForExcel({
          sheetName: "YouTube Videos",
        }),
      );
    }
    dl_overviewGridRef.current.api.exportMultipleSheetsAsExcel({
      data: spreadsheets,
      fileName: `topic_${slugify(topicLabel).slice(0, 32)}_${moment().format(
        "YYYY-MM-DD",
      )}.xlsx`,
    });
  };

  const getTopicsOverTime = (params) => {
    setTopicsOverTimeResponse({
      ...topicsOverTimeResponse,
      loading: true,
      completed: false,
      error: null,
    });

    axiosInstance
      .request({
        method: "GET",
        url: "/topicmodeling/pantheon/topicsovertime",
        params: {
          domain: domain,
          ...params,
        },
      })
      .then((response) => {
        setTopicsOverTimeResponse({
          ...topicsOverTimeResponse,
          data: response.data,
          loading: false,
          completed: true,
          error: null,
        });
        handleTopicsOverTimeResponse(response.data);
      })
      .catch((error) => {
        setTopicsOverTimeResponse({
          ...topicsOverTimeResponse,
          data: null,
          error: error,
          loading: false,
          completed: true,
        });
        console.log(error);
      });
  };

  const getPostsByTopic = (params) => {
    setPostsByTopicResponse({
      ...postsByTopicResponse,
      loading: true,
      completed: false,
      error: null,
    });

    return axiosInstance
      .request({
        method: "GET",
        url: "/topicmodeling/pantheon/postsbytopic",
        params: {
          domain: domain,
          platform: "reddit",
          ...params,
        },
      })
      .then((response) => {
        setPostsByTopicResponse({
          ...postsByTopicResponse,
          data: response.data,
          loading: false,
          completed: true,
          error: null,
        });
        return response.data;
      })
      .catch((error) => {
        setPostsByTopicResponse({
          ...postsByTopicResponse,
          data: null,
          error: error,
          loading: false,
          completed: true,
        });
        console.log(error);
      });
  };

  useEffect(() => {
    if (!topicId) return;
    setPostsCache({});
    const getPreferences = async () => {
      const newPreferences = await callPreferences(domain, "get_preferences");
      const favoriteTopics = newPreferences?.favorites?.topics?.map((x) =>
        parseInt(x.value),
      );
      setIsFavorite(favoriteTopics?.includes(parseInt(topicId)));
    };
    getPreferences();
    getTopicsOverTime({
      start_date: "2008-01-01",
      end_date: moment().format("YYYY-MM-DD"),
      granularity: "day",
      min_prob: 0,
      topic_ids: topicId,
    });
  }, [topicId]);

  return (
    <Layout
      title={
        topicsOverTimeResponse?.data
          ? "Topic: " + topicsOverTimeResponse?.data?.topic_labels[topicId]
          : "Topic"
      }
      content={
        <>
          <ContentLayout
            header={
              <>
                <Header
                  actions={
                    <div className="flex space-x-2 items-center">
                      <Button
                        loading={
                          topicsOverTimeResponse?.loading ||
                          postsByTopicResponse?.loading ||
                          videosByTopicResponse?.loading
                        }
                        onClick={downloadPage}
                      >
                        Download
                      </Button>
                      <TopicSearchBar
                        domain={domain}
                        searchApiUrl="/topicmodeling/pantheon/search"
                        namePageUrl="/topic-modeling/true-crime/person"
                        topicPageUrl="/topic-modeling/true-crime/topic"
                        limit={10}
                      />
                    </div>
                  }
                >
                  <div className="flex items-center space-x-1">
                    {isFavorite === null ? (
                      <Spinner size="normal" />
                    ) : (
                      <Button
                        ariaLabel={
                          isFavorite
                            ? "Remove from favorites"
                            : "Add to favorites"
                        }
                        iconName={isFavorite ? "star-filled" : "star"}
                        variant="inline-icon"
                        onClick={() => {
                          if (isFavorite) {
                            removeFromFavorites(domain, { topics: topicId });
                          } else {
                            addToFavorites(domain, { topics: topicId });
                          }
                          setIsFavorite(!isFavorite);
                        }}
                      />
                    )}
                    <div>
                      {topicsOverTimeResponse?.data
                        ? "Topic: " +
                          topicsOverTimeResponse?.data?.topic_labels[topicId]
                        : "Topic"}
                    </div>
                  </div>
                </Header>
                <PantheonFlashbar ref={flashbarRef} />
              </>
            }
          >
            <SpaceBetween size="m">
              <Container>
                <ColumnLayout columns={2} borders="vertical">
                  <SpaceBetween size="s">
                    <FormField label="Name">
                      {topicsOverTimeResponse?.loading ? (
                        <Spinner />
                      ) : (
                        topicLabel
                      )}
                    </FormField>
                    <FormField label="ID">
                      {topicsOverTimeResponse?.loading ? <Spinner /> : topicId}
                    </FormField>
                    <FormField label="Total posts">
                      {topicsOverTimeResponse?.loading ? (
                        <Spinner />
                      ) : (
                        <div>
                          {topicTotalPosts.toLocaleString()} (from{" "}
                          {moment(topicSeriesData?.[0]?.timestamp).format("l")}{" "}
                          to {moment().format("l")})
                        </div>
                      )}
                    </FormField>
                    <FormField label="Associated people">
                      {topicsOverTimeResponse?.loading ? (
                        <Spinner />
                      ) : (
                        <div>
                          {topicsOverTimeResponse?.data?.connected_names?.[
                            topicId
                          ] &&
                          Object.keys(
                            topicsOverTimeResponse?.data?.connected_names?.[
                              topicId
                            ],
                          ).length > 0 ? (
                            <>
                              <span>
                                {Object.keys(
                                  topicsOverTimeResponse?.data
                                    ?.connected_names?.[topicId] || {},
                                )
                                  .slice(0, namesShowMore ? 1000 : 10)
                                  .map((name) => (
                                    <Link
                                      href={`/topic-modeling/true-crime/person/${name}`}
                                    >
                                      {rawNameToTitle(name)}
                                    </Link>
                                  ))
                                  .reduce((prev, curr) => [
                                    prev,
                                    " \u2022 ",
                                    curr,
                                  ])}
                              </span>
                              <span>
                                {Object.keys(
                                  topicsOverTimeResponse?.data
                                    ?.connected_names?.[topicId],
                                ).length > 10 && (
                                  <span className="ml-2">
                                    <Button
                                      variant="inline-link"
                                      onClick={() => {
                                        setNamesShowMore(!namesShowMore);
                                      }}
                                    >
                                      {namesShowMore ? (
                                        "show less"
                                      ) : (
                                        <span>
                                          ...and{" "}
                                          {Object.keys(
                                            topicsOverTimeResponse?.data
                                              ?.connected_names?.[topicId],
                                          ).length - 10}{" "}
                                          more
                                        </span>
                                      )}
                                    </Button>
                                  </span>
                                )}
                              </span>
                            </>
                          ) : (
                            <div>No associated people</div>
                          )}
                        </div>
                      )}
                    </FormField>
                  </SpaceBetween>
                  <FormField
                    label="Top keywords"
                    description="Top ten keywords associated with this topic"
                  >
                    {topicsOverTimeResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div>
                    ) : (
                      <div
                        style={{ height: "250px", width: "100%" }}
                        className={theme}
                      >
                        <AgCharts
                          options={{
                            data: topicWordsSeriesData,
                            series: [
                              {
                                type: "bar",
                                direction: "horizontal",
                                xKey: "word",
                                yKey: "score",
                              },
                            ],
                            axes: [
                              {
                                type: "number",
                                position: "bottom",
                                label: {
                                  enabled: false,
                                },
                                tick: {
                                  interval: 0.025,
                                  size: 0,
                                },
                                crosshair: {
                                  enabled: false,
                                },
                              },
                              {
                                type: "category",
                                position: "left",
                              },
                            ],
                            tooltip: {
                              enabled: false,
                            },
                            theme: chartTheme,
                            padding: {
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                            },
                          }}
                          style={{ height: "100%" }}
                        />
                      </div>
                    )}
                  </FormField>
                </ColumnLayout>
              </Container>
              {/*<Container
                  header={
                    <Header>
                      Related media
                    </Header>
                  }
                >
                  {topicsOverTimeResponse?.loading ? (
                    <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                      <Spinner size="large" />
                    </div> 
                  ) : (
                    <div className="flex scrollbar-thin scrollbar-thumb-sky-600 scrollbar-track-slate-800 overflow-x-auto overflow-y-visible scrollbar-thumb-rounded-full scrollbar-track-rounded-full pb-4">
                      <div className="flex flex-nowrap space-x-4">
                        {Array.isArray(topicIps) && topicIps.map((item, index) => {
                          return (
                            <a key={item.name} className="inline-block relative" href={`/item/${item.id}`}>
                              <div title={item.name} className={"w-36 max-w-xs overflow-x-hidden rounded-lg shadow-md bg-optionCard hover:shadow-xl transition-shadow duration-300 ease-in-out hover:bg-slate-700 border-2 border-slate-600 p-1.5 h-64"}>
                                <img
                                  src={getItemImage(item)}
                                  alt={item.name}
                                  className={" w-32 rounded-md object-cover object-center h-48"}
                                />
                                <div className={"absolute font-bold text-sm text-white px-0.5 text-left w-[8.25rem] top-52"}>
                                  <div className="line-clamp-2">{item.name}</div>
                                </div>
                                <div className="absolute top-2 right-2 p-3 h-2 mt-1 mr-1 flex items-center justify-center bg-black bg-opacity-50 rounded-full text-white font-bold text-md">
                                  {item.count.toLocaleString()} mentions
                                </div>
                              </div>
                            </a>

                          );
                        })}
                      </div>
                    </div>
                  )}
                </Container>*/}
              <Container
                header={
                  <Header
                    description="Number of posts matching this topic over time"
                    actions={
                      <div className="flex space-x-2 items-center">
                        <Select
                          options={granularityOptions}
                          selectedOption={granularity}
                          onChange={({ detail }) => {
                            setGranularity(detail.selectedOption);
                          }}
                        />
                        <Toggle
                          onChange={({ detail }) => {
                            setShowMarkers(detail.checked);
                          }}
                          checked={showMarkers}
                        >
                          Show markers
                        </Toggle>
                      </div>
                    }
                  >
                    Posts over time{" "}
                    <Popover
                    dismissButton={false}
                      position="right"
                      size="small"
                      triggerType="custom"
                      content={
                        <div>
                          Click any point on the chart to see posts from that
                          time period
                        </div>
                      }
                    >
                      <Button iconName="status-info" variant="inline-icon" />
                    </Popover>
                  </Header>
                }
              >
                {topicsOverTimeResponse?.loading ? (
                  <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                    <Spinner size="large" />
                  </div>
                ) : (
                  <div
                    style={{ height: "400px", width: "100%" }}
                    className={theme}
                  >
                    <AgCharts
                      options={{
                        data:
                          granularity.value === "day"
                            ? topicSeriesData
                            : groupSeries(topicSeriesData, granularity.value),
                        series: [
                          {
                            xKey: "timestamp",
                            yKey: "value",
                            title: "Post count",
                            tooltip: {
                              renderer: (params) => ({
                                title: params.datum.title,
                                content:
                                  (granularity.value === "day"
                                    ? moment(params.datum.timestamp).format(
                                        "ll",
                                      )
                                    : granularity.value === "week"
                                    ? moment(params.datum.timestamp).format(
                                        "ll",
                                      ) +
                                      " - " +
                                      moment(params.datum.timestamp)
                                        .add(6, "days")
                                        .format("ll")
                                    : moment(params.datum.timestamp).format(
                                        "MMM YYYY",
                                      )) +
                                  ": " +
                                  params.datum.value.toLocaleString() +
                                  " posts",
                              }),
                            },
                            marker: {
                              fillOpacity: showMarkers ? 0.5 : 0,
                            },
                            connectMissingData: false,
                            listeners: {
                              nodeClick: (params) => {
                                const clickedDate = moment(
                                  params.datum.timestamp,
                                ).format("YYYY-MM-DD");
                                const startDate = clickedDate;
                                const endDate =
                                  granularity.value === "day"
                                    ? clickedDate
                                    : granularity.value === "week"
                                    ? moment(clickedDate)
                                        .add(6, "days")
                                        .format("YYYY-MM-DD")
                                    : moment(clickedDate)
                                        .endOf("month")
                                        .format("YYYY-MM-DD");
                                const dateRange = {
                                  type: "absolute",
                                  startDate: startDate,
                                  endDate: endDate,
                                };
                                setPostsTargetDate(dateRange);
                                setPostsPage(
                                  dateRange,
                                  1,
                                  selectedPostsSort.value,
                                );
                              },
                            },
                            nodeClickRange: "nearest",
                          },
                        ],
                        axes: [
                          {
                            type: "time",
                            position: "bottom",
                            title: "Date",
                            min: topicSeriesData?.[0]?.timestamp,
                            max: new Date(),
                            label: {
                              format: "%b %d, %Y",
                            },
                            tick: {
                              minSpacing: 50,
                              maxSpacing: 200,
                            },
                          },
                          {
                            type: "number",
                            position: "left",
                            title: "Post count",
                            label: {
                              formatter: (params) => {
                                const n = params.value;
                                if (n < 1e3) return n;
                                if (n >= 1e3 && n < 1e6)
                                  return +(n / 1e3).toFixed(1) + "K";
                                if (n >= 1e6 && n < 1e9)
                                  return +(n / 1e6).toFixed(1) + "M";
                                if (n >= 1e9 && n < 1e12)
                                  return +(n / 1e9).toFixed(1) + "B";
                                if (n >= 1e12)
                                  return +(n / 1e12).toFixed(1) + "T";
                              },
                            },
                          },
                        ],
                        theme: chartTheme,
                        navigator: {
                          enabled: true,
                        },
                      }}
                      style={{ height: "100%" }}
                    />
                  </div>
                )}
              </Container>
              {/*<div className="flex justify-end space-x-2">
                  <Select
                    selectedOption={selectedPostsSort}
                    onChange={({ detail }) => {
                      setSelectedPostsSort(detail.selectedOption);
                      setPostsPage(postsTargetDate, 1, detail.selectedOption.value);
                    }}
                    options={sortOptions}
                  />
                  <RangeDateSelector
                    defaults={postsTargetDate}
                    onChange={(e) => setPostsTargetDate(e)}
                  />
                </div>*/}
              <ColumnLayout columns={1}>
                {/*
                  <Container
                    header={
                      <Header
                        actions={
                          <Pagination
                            currentPageIndex={postsPageIndex}
                            onChange={({ detail }) => {
                              setPostsPageIndex(detail.currentPageIndex);
                              setPostsPage(postsTargetDate, detail.currentPageIndex, selectedPostsSort.value);
                            }}
                            pagesCount={Math.ceil(currentPosts?.total_rows / postsPageSize)}
                          />
                        }
                        counter={!postsByTopicResponse?.loading && currentPosts?.total_rows ? `(${currentPosts?.total_rows.toLocaleString()})` : null}
                        description={(postsTargetDate.startDate !== defaultDateRange.startDate && postsTargetDate.endDate !== defaultDateRange.endDate) ? (postsTargetDate.startDate === postsTargetDate.endDate ? `Posts matching this topic on ${moment(postsTargetDate.startDate).format("ll")}` : `Posts matching this topic from ${moment(postsTargetDate.startDate).format("ll")} to ${moment(postsTargetDate.endDate).format("ll")}`) : "Latest posts matching this topic"}
                      >
                        Reddit posts
                      </Header>
                    }
                  >
                    {postsByTopicResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div> 
                    ) : (
                      <div className="max-h-[48rem] overflow-y-auto overflow-x-hidden">
                        {currentPosts?.reddit?.length > 0 ? (
                          <ColumnLayout columns={1} borders="horizontal">
                            {currentPosts?.reddit?.map(post => (
                              <div className="grid">
                                <div className="flex justify-between">
                                  <div className="flex flex-col flex-wrap h-full">
                                    <div className="grow">
                                      <div>
                                        <a className="text-sm font-bold" href={post.url} target="_blank">{post.title}</a>
                                      </div>
                                      <div>
                                        <a className="text-xs text-slate-400" href={`https://reddit.com/r/${post.subreddit}`} target="_blank">Posted by u/{post.author} to r/{post.subreddit}{" "}&bull;{" "}{moment(post.created_at * 1000).format("ll")}</a>
                                      </div>
                                      {post.body?.length > 0 && (
                                        <div 
                                          className="mt-2 text-xs" 
                                          style={{ 
                                            overflow: "hidden",
                                            maskImage: "linear-gradient(180deg, #000 60%, transparent)",
                                            maxHeight: "50px",
                                          }}
                                        >
                                          <div 
                                            style={{
                                              wordBreak: "break-word",
                                              overflow: "auto",
                                              paddingBottom: "5px",
                                            }}
                                          >
                                            {post.body}
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div>
                                      <div className="text-sm mt-2"><Icon name="arrow-left" className="rotate-90 saturate-[10] hue-rotate-180 brightness-90" />{" "}{post.num_upvotes.toLocaleString()}{" "}&bull;{" "}<Icon name="contact" />{" "}{post.num_comments.toLocaleString()}</div>
                                    </div>
                                  </div>
                                  <div className="mx-4 min-w-24">
                                    {post.thumbnail && (
                                      <img 
                                        src={post.thumbnail} 
                                        alt="thumbnail" 
                                        className="w-24 h-24 object-cover rounded-md shadow-md" 
                                        onError={(e) => {
                                          e.target.style.display = "none";
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </ColumnLayout>
                        ) : (
                          <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                            <div>No posts found for this time period</div>
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                  
                  <Container
                    header={
                      <Header
                      description={(postsTargetDate.startDate !== defaultDateRange.startDate && postsTargetDate.endDate !== defaultDateRange.endDate) ? (postsTargetDate.startDate === postsTargetDate.endDate ? `Videos matching this topic on ${moment(postsTargetDate.startDate).format("ll")}` : `Videos matching this topic from ${moment(postsTargetDate.startDate).format("ll")} to ${moment(postsTargetDate.endDate).format("ll")}`) : "Latest videos matching this topic"}
                      >
                        YouTube videos
                      </Header>
                    }
                  >
                    {videosByTopicResponse?.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div> 
                    ) : (
                      <div className="max-h-[48rem] overflow-y-auto overflow-x-hidden">
                        {videosByTopicResponse?.data?.youtube?.length > 0 ? (
                          <ColumnLayout columns={1} borders="horizontal">
                            {videosByTopicResponse.data.youtube.map(video => (
                              <div key={video.thumbnail} className="h-32 flex space-x-4">
                                <a href={`https://youtube.com/watch?v=${video.post_id}`} target="_blank"
                                  style={{
                                    minWidth: 320/1.5,
                                    minHeight: 180/1.5,
                                  }} 
                                >
                                  <img 
                                    src={video.thumbnail} 
                                    style={{
                                      width: 320/1.5,
                                      height: 180/1.5,
                                    }} 
                                    className="object-cover rounded-md shadow-md"
                                  />
                                </a>
                                <div>
                                  <div>
                                    <div>
                                      <a className="text-sm font-bold" href={`https://youtube.com/watch?v=${video.post_id}`} target="_blank">{video.title}</a>
                                    </div>
                                    <div>
                                      <a className="text-xs text-slate-400" href={video.channel_url} target="_blank">{video.channel}{" "}&bull;{" "}{new Date(video.created_at * 1000).toLocaleDateString("en-US", {year: 'numeric', month: 'short', day: 'numeric',})}</a>
                                    </div>
                                  </div>
                                  <div className="mt-2">
                                    <div className="text-sm"><Icon url="/rticons/eye.svg" className="brightness-75" />{" "}{video.num_views.toLocaleString()}{" "}&bull;{" "}<Icon name="thumbs-up" />{" "}{video.num_likes.toLocaleString()}{" "}&bull;{" "}<Icon name="contact" />{" "}{video.num_comments.toLocaleString()}</div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </ColumnLayout>
                        ) : (
                          <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                            <div>No videos found for this time period</div>
                          </div>
                        )}
                      </div>
                    )}
                  </Container>
                  */}
              </ColumnLayout>
            </SpaceBetween>
            <div className="hidden">
              {topicsOverTimeResponse?.loading === false &&
                topicsOverTimeResponse?.data && (
                  <div>
                    <div
                      style={{ height: "250px", width: "100%" }}
                      className={theme}
                    >
                      <AgGridReact
                        ref={dl_overviewGridRef}
                        columnDefs={[
                          {
                            field: "name",
                            headerName: "Topic name",
                          },
                          {
                            field: "id",
                            headerName: "Topic ID",
                          },
                          {
                            field: "total_posts",
                            headerName: "Total posts",
                          },
                          {
                            field: "connected_names",
                            headerName: "Associated people",
                          },
                          {
                            field: "top_keywords",
                            headerName: "Top keywords",
                          },
                        ]}
                        rowData={[
                          {
                            name: topicLabel,
                            id: topicId,
                            total_posts: topicTotalPosts,
                            connected_names: Object.keys(
                              topicsOverTimeResponse.data?.connected_names?.[
                                topicId
                              ] || {},
                            )
                              .map((name) => rawNameToTitle(name))
                              .join(", "),
                            top_keywords: topicWordsSeriesData
                              .map((item) => item.word)
                              .join(", "),
                          },
                        ]}
                      />
                    </div>
                    <div
                      style={{ height: "250px", width: "100%" }}
                      className={theme}
                    >
                      <AgGridReact
                        ref={dl_postsOverTimeGridRef}
                        columnDefs={[
                          {
                            field: "timestamp_formatted",
                            headerName: "Date (formatted)",
                          },
                          {
                            field: "timestamp",
                            headerName: "Date",
                          },
                          {
                            field: "count",
                            headerName: "Post count",
                          },
                        ]}
                        rowData={topicSeriesData.map((item) => ({
                          timestamp_formatted: moment(item.timestamp).format(
                            "ll",
                          ),
                          timestamp: moment(item.timestamp).format(
                            "YYYY-MM-DD",
                          ),
                          count: item.value,
                        }))}
                      />
                    </div>
                  </div>
                )}
              {postsByTopicResponse?.loading === false &&
                postsByTopicResponse?.data && (
                  <div>
                    <div
                      style={{ height: "250px", width: "100%" }}
                      className={theme}
                    >
                      <AgGridReact
                        ref={dl_postsGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "author",
                            headerName: "Author",
                          },
                          {
                            field: "subreddit",
                            headerName: "Subreddit",
                          },
                          {
                            field: "created_at",
                            headerName: "Created at",
                          },
                          {
                            field: "num_upvotes",
                            headerName: "Upvotes",
                          },
                          {
                            field: "num_comments",
                            headerName: "Comments",
                          },
                          {
                            field: "post_id",
                            headerName: "Post ID",
                          },
                          {
                            field: "url",
                            headerName: "URL",
                          },
                          {
                            field: "body",
                            headerName: "Body",
                          },
                        ]}
                        rowData={currentPosts.reddit}
                      />
                    </div>
                  </div>
                )}
              {videosByTopicResponse?.loading === false &&
                videosByTopicResponse?.data && (
                  <div>
                    <div
                      style={{ height: "250px", width: "100%" }}
                      className={theme}
                    >
                      <AgGridReact
                        ref={dl_videosGridRef}
                        columnDefs={[
                          {
                            field: "title",
                            headerName: "Title",
                          },
                          {
                            field: "channel",
                            headerName: "Channel",
                          },
                          {
                            field: "created_at",
                            headerName: "Created at",
                          },
                          {
                            field: "num_views",
                            headerName: "Views",
                          },
                          {
                            field: "num_likes",
                            headerName: "Likes",
                          },
                          {
                            field: "num_comments",
                            headerName: "Comments",
                          },
                          {
                            field: "post_id",
                            headerName: "Video ID",
                          },
                          {
                            field: "url",
                            headerName: "URL",
                          },
                        ]}
                        rowData={videosByTopicResponse.data.youtube.map(
                          (video) => ({
                            ...video,
                            url: `https://youtube.com/watch?v=${video.post_id}`,
                          }),
                        )}
                      />
                    </div>
                  </div>
                )}
            </div>
          </ContentLayout>
        </>
      }
      navItems={navItems}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default TopicModelingTrueCrimeTopicPageV2;
