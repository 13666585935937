import React from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import { Layout } from "../../Layout";
import { MediaHome } from "../media/components/MediaHome";

const breadcrumbs = [
  { text: "Explore", href: "/explore" },
  { text: "Movies" },
];

export default function ExploreMovies() {
  return (
    <Layout
      title="Explore Movies"
      navItems={navItems as NavItemsWithId}
      breadcrumbs={breadcrumbs}
      contentType="table"
      content={
        <MediaHome
          vertical="movies"
          header="Movies"
          headerAddButtonText="Add movie"
          modalTitle="Add Movie"
          modalPlaceholder="Enter an IMDb ID"
        />
      }
    />
  );
}
