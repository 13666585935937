import React, { useEffect } from "react";
import {
  CollectionPreferences,
  Flashbar,
  Header,
  Pagination,
  PropertyFilter,
  Table,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import getTextFilterCounterText from "../utils/getTextFilterCounterText";
import createTableSortLabelFn from "../utils/createTableSortLabelFn";
import TableEmptyState from "../utils/TableEmptyState";
import TableNoMatchState from "../utils/TableNoMatchState";

export const CustomTable = ({
  header,
  data,
  columnDefinitions,
  selectionGroupLabel,
  visibleContent,
  setSelectedItems,
  selectedItems,
  actionsButton,
  filteringProperties,
  visibleContentPreference,
  onSubmit,
  flashMessages,
  onFlashDismiss,
  ...other
}) => {
  const [preferences, setPreferences] = React.useState({
    pageSize: 20,
    wrapLines: false,
    visibleContent: visibleContent,
  });
  const rawColumns = columnDefinitions.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  useEffect(() => {
    setPreferences((prevState) => {
      return {
        ...prevState,
        visibleContent: visibleContent,
      };
    });
  }, [visibleContent]);
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(data, {
    selection: {},
    propertyFiltering: {
      filteringProperties: filteringProperties,
      empty: other.loading ? "" : <TableEmptyState resourceName={header} />,
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: "and" });
          }}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: rawColumns[0] } },
  });


  return (
    <Table
      submitEdit={async (e) => {
        await onSubmit();
      }}
      {...collectionProps}
      onSelectionChange={(d) => setSelectedItems(d.detail.selectedItems)}
      selectedItems={selectedItems}
      items={items}
      columnDefinitions={columnDefinitions}
      variant="full-page"
      selectionType="multi"
      resizableColumns={true}
      ariaLabels={{
        itemSelectionLabel: (_data, row) => `select ${row.id}`,
        allItemsSelectionLabel: () => "select all",
        selectionGroupLabel: `${selectionGroupLabel} selection`,
      }}
      preferences={
        <CollectionPreferences
          onConfirm={({ detail }) => setPreferences(detail)}
          preferences={preferences}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 5, label: "5 resources" },
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" },
              { value: 30, label: "30 resources" },
              { value: 40, label: "40 resources" },
            ],
          }}
          wrapLinesPreference={{
            label: "Wrap lines",
            description: "Wrap lines description",
          }}
          stripedRowsPreference={{
            label: "Striped rows",
            description: "Striped rows description",
          }}
          contentDensityPreference={{
            label: "Compact mode",
            description: "Content density description",
          }}
          visibleContentPreference={visibleContentPreference}
          cancelLabel="Cancel"
          confirmLabel="Confirm"
          title="Preferences"
        />
      }
      pagination={
        <Pagination
          {...paginationProps}
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      visibleColumns={preferences.visibleContent}
      wrapLines={preferences.wrapLines}
      stripedRows={preferences.stripedRows}
      contentDensity={preferences.contentDensity}
      header={
        <>
          <Header
            variant="h3"
            actions={actionsButton}
            counter={
              selectedItems.length
                ? "(" + selectedItems.length + "/" + data.length + ")"
                : "(" + data.length + ")"
            }
          >
            {header}
          </Header>
          {flashMessages && flashMessages.length > 0 && (
            <div className="py-5">
              <Flashbar
                items={flashMessages.map((m) => ({
                  ...m,
                  onDismiss: () => onFlashDismiss(m.id),
                }))}
                i18nStrings={{
                  ariaLabel: "Notifications",
                  notificationBarAriaLabel: "View all notifications",
                  notificationBarText: "Notifications",
                  errorIconAriaLabel: "Error",
                  warningIconAriaLabel: "Warning",
                  successIconAriaLabel: "Success",
                  infoIconAriaLabel: "Info",
                  inProgressIconAriaLabel: "In progress",
                }}
                stackItems
              />
            </div>
          )}
        </>
      }
      filter={
        <PropertyFilter
          countText={getTextFilterCounterText(filteredItemsCount)}
          {...propertyFilterProps}
          i18nStrings={{
            filteringAriaLabel: "your choice",
            dismissAriaLabel: "Dismiss",
            clearAriaLabel: "Clear",

            filteringPlaceholder: "Filter users by text, property or value",
            groupValuesText: "Values",
            groupPropertiesText: "Properties",
            operatorsText: "Operators",

            operationAndText: "and",
            operationOrText: "or",

            operatorLessText: "Less than",
            operatorLessOrEqualText: "Less than or equal",
            operatorGreaterText: "Greater than",
            operatorGreaterOrEqualText: "Greater than or equal",
            operatorContainsText: "Contains",
            operatorDoesNotContainText: "Does not contain",
            operatorEqualsText: "Equals",
            operatorDoesNotEqualText: "Does not equal",

            editTokenHeader: "Edit filter",
            propertyText: "Property",
            operatorText: "Operator",
            valueText: "Value",
            cancelActionText: "Cancel",
            applyActionText: "Apply",
            allPropertiesLabel: "All properties",

            tokenLimitShowMore: "Show more",
            tokenLimitShowFewer: "Show fewer",
            clearFiltersText: "Clear filters",
            removeTokenButtonAriaLabel: (token) =>
              `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
            enteredTextLabel: (text) => `Use: "${text}"`,
          }}
          {...propertyFilterProps}
          expandToViewport={true}
        />
      }
      {...other}
    />
  );
};
