import { Container, Header } from "@cloudscape-design/components";
import { AgCharts } from "ag-charts-react";
import "ag-grid-enterprise";
import { AgChartOptions } from "ag-grid-enterprise";
import React, { FC } from "react";
import { useGetPiracyTrendsData } from "../../../../api/hooks/useGetPiracyTrendsData";
import { DateRangeType } from "../../DateRange";
import { EmptyTrends } from "../EmptyTrends";
import { useChartConfig } from "./useChartConfig";

interface PiracyTrendsProps {
  ipId: string;
  dateRange: DateRangeType;
}

export const PiracyTrends: FC<PiracyTrendsProps> = ({ ipId, dateRange }) => {
  const { data: piracyTrendsData } = useGetPiracyTrendsData({
    ipId,
    dateRange,
  });
  const piracyTrendsChartOption = useChartConfig(piracyTrendsData);

  return (
    <Container
      header={
        <Header
          variant="h3"
        >
          Piracy
        </Header>
      }
    >
        {piracyTrendsChartOption && piracyTrendsData?.length > 0 && (
          <div className="h-[260px]">
            <AgCharts
              options={piracyTrendsChartOption as any | AgChartOptions}
              style={{ height: "100%" }}
            />
          </div>
        )}
        {piracyTrendsData?.length === 0 && (
          <EmptyTrends text="No data available for the selected date range" />
        )}
    </Container>
  );
};
