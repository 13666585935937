import moment from "moment";
import { useMemo } from "react";
import { useItemChartConfig } from "../../../../chartConfig";

export const useChartConfig = (data) => {
  const { trackedRedditTrendsChartOption } = useItemChartConfig();

  const redditTrendChartOption = useMemo(() => {
    if (data?.length === 0) return null;

    const dataWithDailyGrowth = data.map((d, index, array) => {
      const previousDaySubscribers =
        index === 0 ? d.subscriber_count : array[index - 1].subscriber_count;
      return {
        ...d,
        timestamp: moment(d.timestamp * 1000).format("DD MMM YYYY"),
        subscriberGrowth: d.subscriber_count - previousDaySubscribers,
        activeUsers: d.active_user_count,
        comments: d.comments,
      };
    });

    const newDataKeys = Object.keys(dataWithDailyGrowth[0]);
    const newChartOptions = {
      ...trackedRedditTrendsChartOption,
      data: dataWithDailyGrowth,
      series: trackedRedditTrendsChartOption.series
        .map((series) => ({
          ...series,
          visible:
            newDataKeys.includes(series.yKey) ||
            series.yKey === "subscriberGrowth",
        }))
        .filter((x) => newDataKeys.includes(x.yKey)),
    };

    return newChartOptions;
  }, [data, trackedRedditTrendsChartOption]);

  return { redditTrendChartOption };
};
