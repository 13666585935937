import { ButtonDropdown } from "@cloudscape-design/components";
import moment from "moment";
import React from "react";
import ReactQuill from "react-quill";
const UserCommentView = ({ user, comment, onEdit, onDelete }) => {
  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <div className="flex-1 flex flex-col">
          <div className="flex items-center space-x-4 mb-4">
            <div className="text-m font-semibold text-blue-400">
              {comment?.created_by}
            </div>
            <div className="text-gray-600">
              Commented {moment.unix(comment?.created_at).fromNow()}
            </div>
            {comment?.modified_at &&
              comment?.created_at !== comment?.modified_at && (
                <div className="text-gray-600">
                  Modified: {moment.unix(comment?.modified_at).fromNow()}
                </div>
              )}
          </div>
          <hr className="border-slate-700" />
          <div className="comment-234-wrapper">
            <ReactQuill
              value={comment?.comment || ""}
              readOnly={true}
              theme={"bubble"}
            />
          </div>
        </div>

        {user?.username === comment?.created_by && (
          <ButtonDropdown
            onItemClick={({ detail }) => {
              if (detail.id === "edit") onEdit({ user, comment });
              if (detail.id === "delete") onDelete();
            }}
            items={[
              { id: "edit", text: "Edit" },
              { id: "delete", text: "Delete" },
            ]}
            ariaLabel="Control instance"
            variant="icon"
          />
        )}
      </div>
    </div>
  );
};

export default UserCommentView;
