import React, { useEffect, useMemo, useState } from 'react';
import { useChartConfig } from './hooks/useChartConfig';
import { AgCharts } from 'ag-charts-react';
import { Box, Button, Checkbox, Header, Popover, Select, SpaceBetween, Spinner } from '@cloudscape-design/components';
import { useGetGoogleSearchData } from '../../api/hooks/useGetGoogleSearchData';

const mapOptions = [
  { value: "dma", label: "United States DMA" },
  { value: "countries", label: "Countries" },
];

export const CountryMap = ({ 
  selectedTitle,
  categories,
  countries,
  dmaRegions,
  dateRange,
}) => {

  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [selectedMap, setSelectedMap] = useState(mapOptions[0]);
  const [lowVolumeEnabled, setLowVolumeEnabled] = useState(false);

  const { data: totalsData, isLoading: totalsIsLoading, error: totalsError } = useGetGoogleSearchData({
    enabled: selectedTitle != null,
    ipIds: [selectedTitle?.ip_id],
    geoType: selectedMap.value === "countries" ? "country" : "DMA Region",
    geoNames: selectedMap.value === "countries" ? countries.map(country => country.value) : dmaRegions.map(dma => dma.value),
    categories: categories.map(category => category.value),
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    totalsOnly: true,
  });

  const filteredTotalsData = useMemo(() => (
    totalsData?.data?.filter(d => (
      d.category == selectedCategory.value && 
      d.has_data && 
      d.indexed_volume > (
        lowVolumeEnabled ? 0 : 
        ((Math.max(...totalsData?.data?.filter(x => x.category == selectedCategory.value && x.has_data)?.map(x => x.indexed_volume) ?? [0])) * (selectedMap.value === "countries" ? 0.001 : 0.01))
      )
    )) ?? []), [totalsData, selectedCategory, lowVolumeEnabled, selectedMap]);
  const maxIndexedVolume = useMemo(() => Math.max(...filteredTotalsData?.map(d => d.indexedVolume) ?? [0]), [filteredTotalsData]);
  const maxNormalizedVolume = useMemo(() => Math.max(...filteredTotalsData?.map(d => d.normalized_volume) ?? [0]), [filteredTotalsData]);

  const chartOptions = useChartConfig({
    totalsData: filteredTotalsData,
    selectedCategory,
    selectedMap,
  });

  useEffect(() => {
    setSelectedCategory(categories[0]);
  }, [totalsData]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center flex-wrap gap-2"> 
        <div className="flex items-center gap-1">
          <span className="font-bold text-xl">Search popularity by region</span>
          <span>
            <Popover
              triggerType="custom"
              size="large"
              content={
                <div>Values range from 0 to 100, with 100 being the location with the highest search popularity, and lower values indicating less popularity. These values reflect the proportion of search volume for the selected title and category relative to the total search volume within a region.</div>
              }
            >
              <Button variant="inline-link" iconName="status-info" />
            </Popover>
          </span>
        </div>
        <div className="flex gap-3 flex-nowrap items-center">
          <Checkbox
            checked={lowVolumeEnabled}
            onChange={({ detail }) => setLowVolumeEnabled(detail.checked)}
          >
            Include low volume regions
          </Checkbox>
          <Select
            options={mapOptions}
            selectedOption={selectedMap}
            onChange={({ detail }) => setSelectedMap(detail.selectedOption as any)}
          />
          <Select
            options={categories.filter(cat => totalsData?.data?.some((keyInfo: any) => keyInfo.category === cat.value && keyInfo.has_data))}
            selectedOption={selectedCategory}
            onChange={({ detail }) => setSelectedCategory(detail.selectedOption)}
            disabled={totalsIsLoading}
          />
        </div>
      </div>
      <div style={{ width: "100%", height: "500px" }}>
        {totalsIsLoading ? (
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="large" />
          </div>
        ) : totalsData?.data?.length > 0 ? (
          <div className="flex lg:flex-nowrap flex-wrap h-96 gap-4 relative w-full">
            <div className="w-full basis-auto lg:basis-2/3 min-w-96">
              <AgCharts
                options={chartOptions as any}
              />
            </div>
            <div className="h-full max-h-full basis-auto lg:basis-1/3 min-w-96 overflow-y-auto overflow-x-hidden w-full">
              <div className="flex flex-col gap-2 h-auto">
                {filteredTotalsData.sort((a, b) => b.normalized_volume - a.normalized_volume).map((d, i) => (
                  <div key={d.geo_name} className="flex gap-2 items-start">
                    <div className="w-8 text-right dark:text-slate-400 text-slate-600">{i+1}</div>
                    <div>
                      <b>{selectedMap.value === "countries" ? countries.find(country => country.value === d.geo_name).label : dmaRegions.find(dma => dma.value === d.geo_name).label}</b>
                      <div className="flex gap-2 items-center">
                        <div className="w-48 h-4 dark:bg-slate-600 bg-slate-300 shadow-md">
                          <div className="h-full bg-blue-500" style={{ width: `${(d.normalized_volume / maxNormalizedVolume) * 100}%` }}></div>
                        </div>
                        <div className="dark:text-slate-400 text-slate-600">{`${Math.round((d.normalized_volume / maxNormalizedVolume) * 10000) / 100}`}</div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full w-full flex items-center justify-center">
            <Box>No data available</Box>
          </div>
        )}
      </div>
    </div>
  );
};