import { useState, useCallback } from 'react';
import { getNetflixWeeklyData } from '../api/request';

interface NetflixWeeklyData {
  title: string;
  weekly_rank: number;
  season_title: string;
  weekly_hours_viewed: number;
  runtime: number;
  weekly_views: number;
  cumulative_weeks_in_top_10: number;
  vertical: string;
  language: string;
  timestamp: number;
  ip_id: string;
}

interface UseFetchDataResult {
  data: NetflixWeeklyData[] | null;
  loading: boolean;
  error: Error | null;
  fetchData: (timestamp: number) => Promise<void>;
}

const useFetchData = (): UseFetchDataResult => {
  const [data, setData] = useState<NetflixWeeklyData[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async (timestamp: number) => {
    setLoading(true);
    setError(null);

    try {
      const response = await getNetflixWeeklyData({ timestamp });

      if (response.data && Array.isArray(response.data)) {
        setData(response.data);
      } else {
        throw new Error('Invalid data structure in API response');
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err instanceof Error ? err : new Error('An error occurred while fetching data'));
    } finally {
      setLoading(false);
    }
  }, []);

  return { data, loading, error, fetchData };
};

export default useFetchData;

