import "ag-grid-enterprise";
import React from "react";
import { PlatformTrendsCharts } from "../../components/PlatformTrendsCharts";
import { getIMDbTrendsData } from "../api/request";
import {
  defaultCategory as defCategory,
  imdbApiParams,
  imdbCategoryOptions,
} from "../constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "../utils/gridConfig";

export const IMDbTrendsCharts = ({
  defaultCategory=defCategory,
  setSelectedVertical,
}) => {
  return (
    <PlatformTrendsCharts
      apiParams={imdbApiParams}
      defaultColumn={defaultColumn}
      defaultCategory={defaultCategory}
      categoryOptions={imdbCategoryOptions}
      corePlatformColumns={corePlatformColumns}
      fetchMethod={getIMDbTrendsData}
      setSelectedVertical={setSelectedVertical}
      additionalPlatformColumns={additionalPlatformColumns}
    />
  );
};
