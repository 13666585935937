import { Mode, applyMode } from "@cloudscape-design/global-styles";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useSelector } from "react-redux";
import "./theme.js";

interface ThemeContextData {
  mode: Mode;
  setAppTheme: Dispatch<SetStateAction<Mode>>;
}

const ThemeContext = createContext<ThemeContextData | null>(null);

export const ThemeProvider = ({ children }: { children: ReactNode }) => {
  const globalPref = useSelector(
    (state: any) => state?.globalPersonalPref?.preferences,
  );
  const [mode, setMode] = useState(
    (globalPref?.theme as Mode) ||
      (localStorage.getItem("theme") as Mode) ||
      Mode.Dark,
  );

  useEffect(() => {
    applyMode(mode);
  }, [mode]);

  if (
    localStorage.getItem("theme") === "dark" ||
    (!("theme" in localStorage) &&
      window.matchMedia("(prefers-color-scheme: dark)").matches)
  ) {
    document.documentElement.classList.add("dark");
  } else {
    document.documentElement.classList.remove("dark");
  }

  const setAppTheme = (mode: Mode) => setMode(mode);

  return (
    <ThemeContext.Provider value={{ mode, setAppTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("context must be used inside a provider");
  }

  return themeContext;
};
