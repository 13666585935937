import {
  Link,
  Multiselect,
  SpaceBetween
} from "@cloudscape-design/components";
import { ColDef, GridApi, TooltipRendererParams } from "ag-grid-community";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { interpolateRgb } from "d3-interpolate";
import { format } from "date-fns";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import shortenInteger from "../../../../utils/shortenInteger";
import { useGetChannelData } from "../api/hooks/useGetChannelData";
import { YouTubeChannelTrendsResponse } from "../api/types";
import { YOUTUBE_CATEGORIES } from "../../../../config-global";

interface ProcessedChannelData extends YouTubeChannelTrendsResponse {
  viewCountChangeData: number[];
  subscriberCountChangeData: number[];
  videoCountChangeData: number[];
  totalViewIncrease: number;
  totalSubscriberIncrease: number;
  totalVideoIncrease: number;
}

function createTooltipRenderer(type: 'view' | 'subscriber' | 'video') {
  return function tooltipRenderer(params: TooltipRendererParams) {
    const { context, xValue } = params;
    const rowData = context.data;
    const dateStrings = Object.keys(rowData.trend_data).sort(
      (a, b) => new Date(a).getTime() - new Date(b).getTime()
    );
    const dateString = dateStrings[xValue];
    const date = new Date(dateString);
    const formattedDate = format(date, "dd MMM, yyyy");

    const trendData = rowData.trend_data[dateString];
    const changeData = rowData[`${type}CountChangeData`][xValue];

    const tooltipContent = `
      <div class='tooltip-title'>${formattedDate}</div>
      <div class='tooltip-content'>
        <div>${type.charAt(0).toUpperCase() + type.slice(1)} Count: ${shortenInteger(trendData[`${type}_count`])}</div>
        <div>${type.charAt(0).toUpperCase() + type.slice(1)} Change: ${changeData > 0 ? "+" + shortenInteger(changeData) : "No increase"}</div>
      </div>
    `;

    return `
      <div class='dark:bg-sky-100 bg-sky-900 rounded-md p-2 text-slate-100 dark:text-slate-900'>
        ${tooltipContent}
      </div>
    `;
  };
}

const tooltipViewRenderer = createTooltipRenderer('view');
const tooltipSubscriberRenderer = createTooltipRenderer('subscriber');
const tooltipVideoRenderer = createTooltipRenderer('video');

const VideoComponent: React.FC = () => {
  const [interval, setInterval] = useState<string>("weekly");
  const { data: channelData, isLoading } = useGetChannelData(interval);
  const { theme } = useGetGridTheme();

  const gridApiRef = useRef<GridApi | null>(null);
  const [selectedCategories, setSelectedCategories] = useState([
    { label: "All", value: "All" },
  ]);
  const [processedChannelData, setProcessedChannelData] = useState<
    ProcessedChannelData[]
  >([]);
  const [isDataProcessing, setIsDataProcessing] = useState(false);
  const [maxViewCount, setMaxViewCount] = useState<number>(0);

  const handleCategoryChange = useCallback(({ detail }) => {
    const newSelectedOptions = detail.selectedOptions;

    // If "All" is being selected
    if (newSelectedOptions.some(option => option.value === "All") &&
      !selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([{ label: "All", value: "All" }]);
    }
    // If "All" is being deselected
    else if (!newSelectedOptions.some(option => option.value === "All") &&
      selectedCategories.some(option => option.value === "All")) {
      setSelectedCategories([]);
    }
    // If other categories are being selected/deselected
    else {
      const filteredOptions = newSelectedOptions.filter(option => option.value !== "All");
      setSelectedCategories(filteredOptions.length > 0 ? filteredOptions : [{ label: "All", value: "All" }]);
    }
  }, [selectedCategories]);

  const processChannelData = useCallback(
    (data: YouTubeChannelTrendsResponse[]): ProcessedChannelData[] => {
      return data.map((channel) => {
        const trendDates = Object.keys(channel.trend_data).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());

        const viewCountChangeData = trendDates.map((date, index, array) => {
          if (index === 0) return 0;
          const currentViews = channel.trend_data[date].view_count;
          const previousViews = channel.trend_data[array[index - 1]].view_count;
          return currentViews - previousViews;
        });

        const subscriberCountChangeData = trendDates.map((date, index, array) => {
          if (index === 0) return 0;
          const currentSubscribers = channel.trend_data[date].subscriber_count;
          const previousSubscribers = channel.trend_data[array[index - 1]].subscriber_count;
          return currentSubscribers - previousSubscribers;
        });

        const videoCountChangeData = trendDates.map((date, index, array) => {
          if (index === 0) return 0;
          const currentVideos = channel.trend_data[date].video_count;
          const previousVideos = channel.trend_data[array[index - 1]].video_count;
          return currentVideos - previousVideos;
        });

        const totalViewIncrease = viewCountChangeData.reduce((sum, change) => sum + change, 0);
        const totalSubscriberIncrease = subscriberCountChangeData.reduce((sum, change) => sum + change, 0);
        const totalVideoIncrease = videoCountChangeData.reduce((sum, change) => sum + change, 0);

        return {
          ...channel,
          viewCountChangeData,
          subscriberCountChangeData,
          videoCountChangeData,
          totalViewIncrease,
          totalSubscriberIncrease,
          totalVideoIncrease,
        };
      });
    },
    []
  );

  const getHeatmapColor = useCallback((viewCount: number) => {
    const colorScale = interpolateRgb("#e5f5e0", "#008f00");
    return colorScale(viewCount / maxViewCount);
  }, [maxViewCount]);

  useEffect(() => {
    if (Array.isArray(channelData) && channelData.length > 0) {
      setIsDataProcessing(true);
      let processedData = processChannelData(
        channelData as YouTubeChannelTrendsResponse[],
      );

      // Apply category filter
      if (selectedCategories.length > 0 && !selectedCategories.some(cat => cat.value === "All")) {
        processedData = processedData.filter(channel =>
          selectedCategories.some(cat => channel.category === cat.value)
        );
      }

      // Calculate maxViewCount here
      const maxView = Math.max(...processedData.map(channel => channel.view_count));
      setMaxViewCount(maxView);

      processedData.sort((a, b) => {
        if (b.doc_count !== a.doc_count) {
          return b.doc_count - a.doc_count;
        }
        return (b.view_count || 0) - (a.view_count || 0);
      });

      setProcessedChannelData(processedData);
      setIsDataProcessing(false);
    }
  }, [channelData, selectedCategories, processChannelData]);

  const columnDefs = useMemo<ColDef[]>(
    () => [
      { field: "doc_count", headerName: "Times in top 200", maxWidth: 80 },
      {
        field: "title",
        headerName: "Channel",
        flex: 2,
        minWidth: 300,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: [
            "contains",
            "notContains",
            "equals",
            "notEqual",
            "startsWith",
            "endsWith",
          ],
          defaultOption: "contains",
        },
        cellRenderer: (params: any) => (
          <Link
            href={`https://www.youtube.com/channel/${params.data.channel_id}`}
            external
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: "view_count",
        headerName: "Views",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 100,
        cellStyle: (params) => {
          const color = getHeatmapColor(params.value);
          return {
            backgroundColor: color,
            color: params.value > (maxViewCount / 2) ? 'white' : 'black'
          };
        },
      },
      {
        field: "subscriber_count",
        headerName: "Subscribers",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 130,
      },
      {
        field: "video_count",
        headerName: "Videos",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 110,
      },
      {
        field: "viewCountChangeData",
        headerName: "View Count Change",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: {
            type: "column",
            fill: "#4caf50",
            stroke: "#4caf50",
            paddingInner: 0.3,
            padding: { top: 5, bottom: 5 },
            axis: { stroke: "#e0e0e0" },
            tooltip: { renderer: tooltipViewRenderer },
          },
        },
        maxWidth: 180,
      },
      {
        field: "subscriberCountChangeData",
        headerName: "Subscriber Count Change",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: {
            type: "column",
            fill: "#2196F3",  // Changed to blue
            stroke: "#2196F3",
            paddingInner: 0.3,
            padding: { top: 5, bottom: 5 },
            axis: { stroke: "#e0e0e0" },
            tooltip: { renderer: tooltipSubscriberRenderer },
          },
        },
        maxWidth: 180,
      },
      {
        field: "videoCountChangeData",
        headerName: "Video Count Change",
        cellRenderer: "agSparklineCellRenderer",
        sortable: false,
        cellRendererParams: {
          sparklineOptions: {
            type: "column",
            fill: "#ff5722",
            stroke: "#ff5722",
            paddingInner: 0.3,
            padding: { top: 5, bottom: 5 },
            axis: { stroke: "#e0e0e0" },
            tooltip: { renderer: tooltipVideoRenderer },
          },
        },
        maxWidth: 180,
      },
      {
        field: "publishedOn",
        cellDataType: "date",
        cellRenderer: (params: any) => {
          const date = new Date(params.value);
          return format(date, "dd MMM, yyyy");
        },
        maxWidth: 120,
        // pass the actual value in the valueFormatter
        valueFormatter: (params: any) => {
          return params.value;
        },
      },
      {
        field: "totalViewIncrease",
        headerName: "New Views",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 120,
      },
      {
        field: "totalSubscriberIncrease",
        headerName: "New Subscribers",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 120,
      },
      {
        field: "totalVideoIncrease",
        headerName: "New Videos",
        valueFormatter: (params) => String(shortenInteger(params.value)),
        maxWidth: 120,
      },
      {
        field: "category",
        headerName: "Category",
        maxWidth: 150,
      },
    ],
    [getHeatmapColor, maxViewCount]
  );

  const defaultColDef = useMemo<ColDef>(
    () => ({
      sortable: true,
      filter: false,
      suppressHeaderMenuButton: true,
      autoHeaderHeight: true,
      wrapHeaderText: true,
      floatingFilter: true,
    }),
    [],
  );

  const handleIntervalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterval(event.target.value);
    setIsDataProcessing(true);
  };

  const IntervalOptions = [
    { id: "half_yearly", title: "6 months" },
    { id: "quarterly", title: "3 months" },
    { id: "monthly", title: "1 month" },
    { id: "weekly", title: "7 days" },
  ];

  return (
    <SpaceBetween size="m" direction="vertical">
      <div className="flex flex-row justify-between bg-gradient-to-r from-slate-200 to-white dark:from-slate-800 dark:to-[#161D26] p-2 px-8 rounded-lg">
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
            Interval
          </legend>
          <div className=" space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
            {IntervalOptions.map((intervalOption) => (
              <div key={intervalOption.id} className="flex items-center">
                <input
                  id={intervalOption.id}
                  name="interval-option"
                  type="radio"
                  value={intervalOption.id}
                  checked={interval === intervalOption.id}
                  onChange={handleIntervalChange}
                  className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                />
                <label
                  htmlFor={intervalOption.id}
                  className="ml-3 block text-sm font-medium leading-6 text-gray-900 dark:text-gray-100"
                >
                  {intervalOption.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
        <fieldset>
          <legend className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-100">
            Category
          </legend>
          <Multiselect
            selectedOptions={selectedCategories}
            onChange={handleCategoryChange}
            options={YOUTUBE_CATEGORIES}
            placeholder="Filter by category"
            hideTokens
          />
        </fieldset>
      </div>
      <div className={`${theme} h-[75vh]`}>
        <AgGridReact
          rowData={processedChannelData}
          loading={isLoading || isDataProcessing}
          loadingOverlayComponent={CustomLoadingOverlay}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          onGridReady={(params) => (gridApiRef.current = params.api)}
          pagination={true}
          paginationPageSize={20}
        />
      </div>
    </SpaceBetween>
  );
};

export default VideoComponent;
