import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchItemNewsDataApi = createAsyncThunk(
  "itemData/fetchItemNewsDataApi",
  async ({ id }) => {
    const endDate = new Date();
    const formattedEndDate = endDate.toISOString().split('T')[0];
    const startDate = new Date();
    startDate.setDate(endDate.getDate() - 30);
    const formattedStartDate = startDate.toISOString().split('T')[0];

    const response = await axiosInstance.request({
      url: `googlenews/search?q=${id}&start_date=${formattedStartDate}&end_date=${formattedEndDate}`,
      method: "GET",
    });
    return response;
  }
);

const itemNewsDataSlice = createSlice({
  name: "itemNewsData",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchItemNewsDataApi.pending, (state) => {
        state.loading = true;
        state.data = [];
      })
      .addCase(fetchItemNewsDataApi.fulfilled, (state, action) => {
        state.completed = true;
        state.data = action.payload.data;
        state.loading = false;
      })
      .addCase(fetchItemNewsDataApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const itemNewsDataReducer = itemNewsDataSlice.reducer;

export default itemNewsDataReducer;

export const itemNewsDataActions = itemNewsDataSlice.actions;
