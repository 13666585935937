import { Box, Link } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import CustomLoadingOverlay from "../../../../../../components/PantheonLoading";
import { useGetGridTheme } from "../../../../../../hooks/UseTheme/useGetGridTheme";
import axiosInstance from "../../../../../../utils/axios";
import renderTrend from "../../../../../../utils/trendIcon";

type TrendsHeaderProps = {
  title: string;
};

export function TrendsHeader({ title }: TrendsHeaderProps) {
  return (
    <Box variant="h4" textAlign="center">
      {title}
    </Box>
  );
}

type TrendsFooterProps = {
  linkHref: string;
};

export function TrendsFooter({ linkHref }: TrendsFooterProps) {
  return (
    <Box>
      <Link fontSize="inherit" href={linkHref} variant="secondary">
        View All
      </Link>
    </Box>
  );
}

type TrendsContentProps = {
  vertical: string;
  platform: "imdb" | "wiki" | "twitch" | "steam" | "youtube"| "piracy" ;
  sort: string;
};

const platformFieldMapping: Record<string, string> = {
  imdb: "monthly_votes",
  wiki: "last_30_days_views",
  twitch: "monthly_avg_viewers",
  steam: "monthly_player_count",
  youtube: "monthly_views",
  piracy: "monthly_downloads",
};

const fetchData = async (vertical: string, platform: string, sort: string) => {
  const field = platformFieldMapping[platform];
  const response = await axiosInstance.request({
    url: `/pql?key=platform/${platform}_${vertical}&file_type=P&sort=${sort}:asc&size=10`,
    method: "POST",
    data: {
      "sql": `SELECT s.ip_id, s.ip, s.daily_rank, s.weekly_rank, s.daily_rank_change, s.weekly_rank_change, s.daily_rank_trend, s.weekly_rank_trend, s.${field} FROM s3object s`,
    },
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error("Error fetching Data");
  }

  return response.data;
};

export function TrendsContent({ vertical, platform, sort }: TrendsContentProps) {
  const { theme } = useGetGridTheme();
  const field = platformFieldMapping[platform];

  const { data, isLoading, error } = useQuery(
    ["trendsData", vertical, platform, sort],
    () => fetchData(vertical, platform, sort),
    {}
  );

  const renderDailyRank = (params: any) =>
    renderTrend(params, "daily_rank", "daily_rank_change", "daily_rank_trend");

  const renderWeeklyRank = (params: any) =>
    renderTrend(params, "weekly_rank", "weekly_rank_change", "weekly_rank_trend");

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        headerName: "Daily Rank",
        maxWidth: 90,
        cellDataType: "number",
        minWidth: 90,
        sortable: true,
        cellRenderer: renderDailyRank,
        valueGetter: (params: any) => parseInt(params.data.weekly_rank),
      },
      {
        headerName: "7-Day Rank",
        maxWidth: 90,
        cellDataType: "number",
        minWidth: 90,
        sortable: true,
        cellRenderer: renderWeeklyRank,
        valueGetter: (params: any) => parseInt(params.data.weekly_rank),
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 100,
        cellRenderer: (params: any) => {
          if (!params.value) return null;

          const ipId = params.data.ip_id;
          return (
            <Link href={`/item/${ipId}`}>
              <span>{params.data.ip}</span>
            </Link>
          );
        },
      },
      {
        field: field,
        headerName: "Trend",
        cellRenderer: "agSparklineCellRenderer",
        cellRendererParams: {
          sparklineOptions: {
            type: "area",
            fill: "#0972d3",
            highlightStyle: {
              fill: "#033160",
            },
          },
        },
      },
    ];
    return allColumns;
  }, [platform]);

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      suppressHeaderFilterButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    []
  );

  return (
    <>
      <hr className="border-slate-600 border-t-[1px]" />
      <div className={`${theme} compact`} style={{ height: "99.5%" }}>
        <AgGridReact
          rowData={data || []}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          suppressDragLeaveHidesColumns={true}
          loading={isLoading}
          loadingOverlayComponent={CustomLoadingOverlay}
          columnDefs={columnDefs}
          rowHeight={29.4}
          suppressContextMenu={true}
        />
      </div>
    </>
  );
}
