import {
  Alert,
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import { usePutGenericPreference_V2 } from "../../services/generic_v2/hooks/usePutGenericPreference_V2";
import { ApiURLParams } from "../../services/generic_v2/requests";

type DeleteFilterModalProps = {
  name: string;
  title: string;
  visible: boolean;
  existingPref: any;
  onDismiss: () => void;
  handleClearFilter: () => void;
  getPreferencesAfterDelete: (name: string) => void;
  apiParams: ApiURLParams;
};

export const DeleteFilterModalV2: FC<DeleteFilterModalProps> = ({
  name,
  title,
  visible,
  existingPref,
  onDismiss,
  handleClearFilter,
  getPreferencesAfterDelete,
  apiParams,
}) => {
  const { mutate: updatePreference } = usePutGenericPreference_V2();

  const onDelete = () => {
    const newGlobalPreferences = getPreferencesAfterDelete(name);

    updatePreference({ id: existingPref.id, payload: newGlobalPreferences });
    onDismiss();
    handleClearFilter();
  };

  return (
    <Modal onDismiss={onDismiss} visible={visible} header={title}>
      <SpaceBetween direction="vertical" size="l">
        <Alert statusIconAriaLabel="Warning" type="warning">
          Are you sure that you want to delete filter - {name} ?
        </Alert>
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onDelete}>
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      </SpaceBetween>
    </Modal>
  );
};
