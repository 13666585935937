import React, { useState, useEffect } from 'react';
import { Container, Select, Box, SpaceBetween, FormField, Multiselect } from '@cloudscape-design/components';
import { useGetGenreTimeseriesData } from '../api/hooks/useGetGenreTimeseriesData';
import { genreCategoryOptions, TYPE_LABELS } from "../constants";

const VERTICAL_MAP = {
  "Movies": "movies",
  "Television": "series",
  "Gaming": "gaming",
};

export const PlatformRankingParameters = ({
  selectedCategory,
  setSelectedCategory,
  selectedGenres,
  setSelectedGenres,
}) => {
  const [ genreOptions, setGenreOptions ] = useState([]);

  const {
    data: genreTimeseriesResponse,
    isLoading,
    error,
  } = useGetGenreTimeseriesData("1Y");

  const processTimeseriesData = (data) => {
    const metadata = data?.metadata;

    const genreInfo = metadata?.genres;
    const genreOptions = genreInfo.filter(g => VERTICAL_MAP[g.vertical] === selectedCategory.value).map((genre, i) => (
      { label: `${genre.label} (${TYPE_LABELS[genre.type]})`, value: genre.label, genre: genre }
    ));
    setGenreOptions(genreOptions);
  };

  useEffect(() => {
    if (genreTimeseriesResponse) {
      setSelectedGenres([]);
      processTimeseriesData(genreTimeseriesResponse.data);
    }
  }, [genreTimeseriesResponse, selectedCategory.value]);

  return (
    <Container>
      <Box float="left">
        <SpaceBetween size="s" direction="horizontal">
          <FormField description="Category">
            <Select
              options={genreCategoryOptions}
              selectedOption={selectedCategory}
              onChange={({ detail }) => setSelectedCategory(detail.selectedOption)}
            />
          </FormField>
          <FormField description="Filter genres and tags">
            <Multiselect
              options={genreOptions}
              selectedOptions={selectedGenres}
              onChange={({ detail }) => setSelectedGenres(detail.selectedOptions)}
              placeholder="Choose options"
              //hideTokens
            />
          </FormField>
        </SpaceBetween>
      </Box>
    </Container>
  );
};