import React, { useEffect, useState } from "react";
import { SplitPanel } from "@cloudscape-design/components";

const SplitPanelContainer = ({
  selectedItems,
  contents,
  onTogglePanelStatus,
}) => {
  const [splitPanelSize, setSplitPanelSize] = useState(300);
  const [splitPanelOpen, setSplitPanelOpen] = useState(false);

  const onSplitPanelResize = ({ detail: { size } }) => {
    setSplitPanelSize(size);
  };

  useEffect(() => {
    if (selectedItems.length) {
      setSplitPanelOpen(true);
    } else {
      setSplitPanelOpen(false);
    }
  }, [selectedItems.length]);

  useEffect(() => {
    onTogglePanelStatus(splitPanelOpen);
  }, [splitPanelOpen]);

  return (
    <SplitPanel
      header={<span>Compare IPs</span>}
      i18nStrings={{
        preferencesTitle: "Split panel preferences",
        preferencesPositionLabel: "Split panel position",
        preferencesPositionDescription:
          "Choose the default split panel position for the service.",
        preferencesPositionSide: "Side",
        preferencesPositionBottom: "Bottom",
        preferencesConfirm: "Confirm",
        preferencesCancel: "Cancel",
        closeButtonAriaLabel: "Close panel",
        openButtonAriaLabel: "Open panel",
        resizeHandleAriaLabel: "Resize split panel",
      }}
      closeBehavior="collapse"
      defaultPosition="bottom"
      size={splitPanelSize}
      onResize={onSplitPanelResize}
    >
      {contents}
    </SplitPanel>
  );
};

export default SplitPanelContainer;
