
import { dispatch } from "../../../../../app/store";
import axiosInstance from "../../../../../utils/axios";
import { createPermissioinsRouteAction } from "../slices/create-permission-route-slice";
import { editPermissioinsRouteAction } from "../slices/edit-permission-route-slice";
import { routesSliceActions } from "../slices/permissions-route-list-slice";
import { permissionsSliceActions } from "../slices/permissions-slice";
import { ummappedRouteSliceActions } from "../slices/permissions-unmapped-routes-slice";


export async function getPermissionsList () {
    dispatch(permissionsSliceActions.loading());
    try {
        const response = await axiosInstance.request({
            url: `permissions`,
            method: `GET`,
        });
        dispatch(permissionsSliceActions.createPermissionsList(response.data));
    } catch (e) {
        dispatch(permissionsSliceActions.createError(e.message));
    }
}

export async function getRouteListFn () {
    dispatch(routesSliceActions.loading());
    try {
        const response = await axiosInstance.request({
            url: `permissions/routes`,
            method: `GET`,
        });
      
        dispatch(routesSliceActions.createRouteList(response.data));
    } catch (e) {
        dispatch(routesSliceActions.createError(e.message));
    }
}

export async function getUnmappedRouteListFn () {
    dispatch(ummappedRouteSliceActions.loading());
    try {
        const response = await axiosInstance.request({
            url: `permissions/routes?status=unmapped`,
            method: `GET`,
        });
      
        dispatch(ummappedRouteSliceActions.createRouteList(response.data));
    } catch (e) {
        dispatch(ummappedRouteSliceActions.createError(e.message));
    }
}

export async function createPermissioinsRouteFn ({data}) {
    dispatch(createPermissioinsRouteAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `permissions`,
            method: `POST`,
            data:{
                permission_name:data.permission_name,
                api_route:data.api_route.map(d=>d.value)
            }
        });
      
        dispatch(createPermissioinsRouteAction.createPermissionRouteComepleted(response.data));
    } catch (e) {
        dispatch(createPermissioinsRouteAction.createError(e.message));
    }
}

export  function clearPermissionRouteCreateFn() {
    dispatch(createPermissioinsRouteAction.clearPermissionRouteCreateReducer());
}



export async function editPermissioinsRouteFn ({data}) {
    dispatch(editPermissioinsRouteAction.loading());
    try {
        const response = await axiosInstance.request({
            url: `permissions/${data.permission_name}`,
            method: `PATCH`,
            data:{
               
                api_route:data.api_route.map(d=>d.value)
            }
        });
      
        dispatch(editPermissioinsRouteAction.editPermissionRouteComepleted(response.data));
    } catch (e) {
        dispatch(editPermissioinsRouteAction.editPermissionRouteFailed(e.message));
    }
}

export  function clearPermissionRouteEditFn() {
    dispatch(editPermissioinsRouteAction.clearPermissionRouteCreateReducer());
}



