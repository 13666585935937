import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from "../../../../utils/axios";
const initialState = {
    data: undefined,
    loading: false,
    message: "",
    completed: false,
    hasError: false,
};

export const fetchGetNbcuSettingData = createAsyncThunk(
    'nbcuSetting/fetchGetNbcuSettingData',
    async (id) => {
       
        const response = await axiosInstance.request({
            url: `/settings/nbcuweighting/${id}`,
            method: `GET`,
        });
   
        return response;
    }
);


const getNbcuSettingDataSlice = createSlice({
    name: 'nbcuSetting',
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
        makeChange: (state, action) => {
    
            state.data = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchGetNbcuSettingData.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGetNbcuSettingData.fulfilled, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.data = action.payload.data;
            })
            .addCase(fetchGetNbcuSettingData.rejected, (state, action) => {
                state.loading = false;
                state.completed = true;
                state.hasError = true;
                state.message = action.error.message;
            });

    },
});

const NbcuSettingDataReducer = getNbcuSettingDataSlice.reducer;

export default NbcuSettingDataReducer;

export const getNbcuSettingDataAction = getNbcuSettingDataSlice.actions;