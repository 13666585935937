import {
  Button,
  Container,
  ExpandableSection,
  Header,
  SpaceBetween,
  Tabs,
} from "@cloudscape-design/components";
import _ from "lodash";
import moment from "moment";
import { React, useEffect, useState } from "react";
import AddFranchiseIcon from "../../../../assets/images/AddFranchiseIcon";
import CompareIcon from "../../../../assets/images/CompareIcon";
import { useAuthContext } from "../../../../auth/useAuthContext";
import { IP_LIST, TAGS_LIST } from "../../../../config-global";
import { formatDate } from "../../../../utils/dateFormatter";
import getTextFilterCounterText from "../../../../utils/getTextFilterCounterText";
import { addQueryParams, getQueryParams } from "../../../../utils/queryUtils";
import { useAddYoutubeVideos } from "../../api/hooks/useAddYoutubeVideos";
import { calculateStartDate } from "../../utils";
import { AwardsTable } from "../components/AwardsTable";
import CastAndCrewComponent from "../components/CastAndCrewComponent";
import NewsBox from "../components/News";
import OTTProvider from "../components/OTTProvider";
import ProdnDistrib from "../components/ProductionnDistribution";
import IMDbScore from "../components/Scores/IMDbScore";
import RottenTomatesAudienceScore from "../components/Scores/RTAudienceScore";
import RottenTomatesCriticScore from "../components/Scores/RTCriticScore";
import { SCORE_TYPES, Score } from "../components/Scores/Score";
import StreamingWindowComponent from "../components/StreamingWindow";
import TagList from "../components/TagList";
import TagsAndIDsComponent from "../components/TagsAndIDsComponent";
import TrailerGrid from "../components/Trailer/Trailer";
import { PlatformTrends } from "../components/Trends/PlatformTrends";
import { RankTrends } from "../components/Trends/RankTrends";
import WikiSummaryBox from "../components/WikipediaTab";
import { AddToFranchiseModal } from "../components/modals/AddToFranchiseModal";
import { AddYoutubeTrailerModal } from "../components/modals/AddYoutubeTrailerModal";
import AttributesEditModal from "../components/modals/AttributesEditModal";
import AuditModal from "../components/modals/AuditModal";
import { CompareModal } from "../components/modals/CompareModal/CompareModal";
import {
  DATA_COMPARE_METRICS,
  SCORE_COMPARE_METRICS,
} from "../components/modals/CompareModal/metrics";
import { DeleteConfirmationModal } from "../components/modals/DeleteConfirmationModal";
import { TrackUnTrackConfirmationModal } from "../components/modals/TrackUnTrackConfirmationModal";
import { useGetPlatformData } from "../game/api/hooks/useGetPlatformData";
import SeasonsGrid from "./components/Seasons";
import SortableFilterableTable from "./components/SortableFilterableTable";
import RankingTable from "../components/RankingTable";

const defaultDateRange = {
  type: "absolute",
  startDate: moment().subtract(6, "months").startOf("day").format("YYYY-MM-DD"),
  endDate: moment().format("YYYY-MM-DD"),
};

export default function ItemTelevisionSeries({
  itemData,
  onTrackItem,
  onUntrackItem,
  onDelete,
  setSearchDate = (e) => { },
}) {
  const { user } = useAuthContext();
  const [isTracked, setIsTracked] = useState(false);
  const [showTrackUnTrackModal, setShowTrackUnTrackModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddFranchiseModal, setShowAddFranchiseModal] = useState(false);
  const [showAddYoutubeTrailerModal, setShowAddYoutubeTrailerModal] =
    useState(false);
  const [showAttrModal, setShowAttrModal] = useState(false);
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [addTrack, setAddTrack] = useState(false);
  const onTrackUnTrackInit = () => setShowTrackUnTrackModal(true);
  const onTrackUnTrackClose = () => setShowTrackUnTrackModal(false);
  const onDeleteModalInit = () => setShowDeleteModal(true);
  const onDeleteModalClose = () => setShowDeleteModal(false);
  const onAddFranchiseModalInit = () => setShowAddFranchiseModal(true);
  const onAddFranchiseModalClose = () => setShowAddFranchiseModal(false);
  const onShowAttrInit = () => setShowAttrModal(true);
  const onShowAttrClose = () => setShowAttrModal(false);
  const onShowAuditInit = () => setShowAuditModal(true);
  const onShowCompareModalInit = () => setShowCompareModal(true);
  const [showAuditModal, setShowAuditModal] = useState(false);
  const [searchDateRange, setSearchDateRange] = useState(defaultDateRange);
  const [triggerTrailersApi, setTriggerTrailersApi] = useState(true);

  const tabQuery = getQueryParams("tab");
  const [activeTabId, setActiveTabId] = useState(tabQuery ?? "rtrends");

  const allEpisodes = itemData?.data?.seasons?.reduce((acc, season) => {
    const seasonNumber = season.season_number ?? 0; 
    const episodesWithSeason = (season.episodes ?? []).map((episode) => ({
      ...episode,
      season_number: seasonNumber,
      sortKey: `${seasonNumber.toString().padStart(3, "0")}${(episode.episode_number ?? 0).toString().padStart(3, "0")}`,
    }));
    return acc.concat(episodesWithSeason);
  }, []) ?? [];

  const episodeColumnDefinition = [
    {
      id: "sortKey",
      header: "Episode",
      cell: (item) => `S${item.season_number}E${item.episode_number}` || "N/A",
      sortingField: "sortKey",
    },
    {
      id: "name",
      header: "Title",
      cell: (item) => item.name || "N/A",
      sortingField: "name",
    },
    {
      id: "release_date",
      header: "Air Date",
      cell: (item) => {
        if (!item.release_date) return "N/A";
        const date = new Date(item.release_date);
        const day = date.getDate().toString().padStart(2, '0');
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
      },
      sortingField: "release_date",
    },
    {
      id: "overview",
      header: "Overview",
      minWidth: 300,
      maxWidth: 300,
      cell: (item) => {
        const truncatedSummary =
          item?.overview?.length > 100
            ? item?.overview?.substring(0, 100) + "..."
            : item.overview;

        return <span>{truncatedSummary}</span>;
      },
      sortingField: "overview",
    },
    {
      id: "imdb_vote_average",
      header: "IMDb Rating",
      cell: (item) => item.imdb_vote_average || "N/A",
      sortingField: "imdb_vote_average",
    },
    {
      id: "imdb_vote_count",
      header: "IMDb Votes",
      cell: (item) => item.imdb_vote_count || "N/A",
      sortingField: "imdb_vote_count",
    },
  ];

  useEffect(() => {
    setSearchDate(searchDateRange);
  }, [searchDateRange]);

  useEffect(() => {
    if (itemData.data && itemData.data.tracked !== isTracked) {
      setIsTracked(itemData.data.tracked);
    }
  }, [itemData, isTracked]);

  const checkIpList = (ip_list) => {
    return _.every(IP_LIST, ({ value }) => ip_list?.includes(value));
  };

  const isAdmin = user?.role === "superAdmin" || user?.role === "admin";

  useEffect(() => {
    onShowAttrClose();
  }, [itemData]);

  const rtCriticSummary = itemData?.data?.rtCriticSummary;
  const rtFanSummary = itemData?.data?.rtFanSummary;
  const releaseDate = new Date(Date.parse(itemData.data.release_date));
  const formattedDate = formatDate(releaseDate);
  const releaseYear = releaseDate.getFullYear();
  const newsBoxId = `${itemData?.data?.ip}+${releaseYear}+${itemData?.data?.vertical}`;

  const [dateRange, setDateRange] = useState({
    ...defaultDateRange,
    startDate: calculateStartDate(releaseDate),
  });

  const { data: youtubeTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "youtube",
    dateRange,
  });
  const { data: imdbTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "imdb",
    dateRange,
  });
  const { data: wikipediaTrendsData } = useGetPlatformData({
    ipId: itemData?.data?.ip_id,
    platform: "wiki",
    dateRange,
  });

  const onSuccess = () => {
    setTriggerTrailersApi(true);
    setShowAddYoutubeTrailerModal(false);
  };
  const { mutate: addYoutubeVideos } = useAddYoutubeVideos(onSuccess);

  const handleTabChange = ({ detail }) => {
    setActiveTabId(detail.activeTabId);
    addQueryParams({ tab: detail.activeTabId });
  };

  return (
    <Container
      variant="borderless"
      disableContentPaddings
      disableHeaderPaddings
      header={
        <Header
          className="mb-4"
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                ariaLabel="Track Title"
                variant="normal"
                iconName="check"
                onClick={() => {
                  setAddTrack(true);
                  onTrackUnTrackInit();
                }}
                disabled={
                  !isAdmin
                    ? true
                    : (itemData?.data?.ip_list?.length >= 3)
                }
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to track this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Untrack Title"
                variant="normal"
                iconName="close"
                onClick={() => {
                  setAddTrack(false);
                  onTrackUnTrackInit();
                }}
                disabled={!isAdmin ? true : !itemData?.data?.tracked}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to untrack this title."
                    : ""
                }
              />
              <Button
                ariaLabel="Edit Tags"
                variant="normal"
                iconName="edit"
                onClick={() => onShowAttrInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin ? "You don't have permission to edit tags." : ""
                }
              />
              <Button
                ariaLabel="Compare Titles"
                variant="normal"
                onClick={() => onShowCompareModalInit()}
                disabled={false}
                disabledReason="This feature is currently unavailable."
                iconSvg={<CompareIcon />}
              />
              <Button
                ariaLabel="Add to Franchise"
                variant="normal"
                onClick={() => onAddFranchiseModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to add to franchise."
                    : ""
                }
                iconSvg={<AddFranchiseIcon />}
              />
              <Button
                ariaLabel="Add Youtube Trailer"
                variant="normal"
                onClick={() => setShowAddYoutubeTrailerModal(true)}
                iconSvg={
                  <svg
                    fill="#000000"
                    width="800px"
                    height="800px"
                    viewBox="-2 -5 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    preserveAspectRatio="xMinYMin"
                    className="jam jam-youtube"
                  >
                    <path d="M15.812.017H4.145C1.855.017 0 1.852 0 4.116v5.768c0 2.264 1.856 4.1 4.145 4.1h11.667c2.29 0 4.145-1.836 4.145-4.1V4.116c0-2.264-1.856-4.1-4.145-4.1zM13.009 7.28L7.552 9.855a.219.219 0 0 1-.314-.196V4.35c0-.161.173-.266.318-.193l5.458 2.735a.216.216 0 0 1-.005.389z" />
                  </svg>
                }
              />
              <Button
                ariaLabel="Audit Logs"
                iconName="security"
                variant="normal"
                onClick={() => {
                  console.log("audit logs");
                  onShowAuditInit();
                }}
              />

              <Button
                ariaLabel="Delete Title"
                iconName="remove"
                variant="normal"
                onClick={() => onDeleteModalInit()}
                disabled={!isAdmin}
                disabledReason={
                  !isAdmin
                    ? "You don't have permission to delete this title."
                    : ""
                }
              />
            </SpaceBetween>
          }
        >
          <div className="flex items-center">
            <div className="text-xl font-bold inline-block">
              {itemData.data.name}
            </div>
            <div className="inline-block whitespace-nowrap ml-2">
              {isTracked ? (
                <div className="flex space-x-2">
                  {itemData.data?.ip_list?.length > 0 ? (
                    itemData.data.ip_list.map((ip) => (
                      <span
                        key={ip}
                        className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700"
                      >
                        <svg
                          className="h-1.5 w-1.5 fill-green-400"
                          viewBox="0 0 6 6"
                          aria-hidden="true"
                        >
                          <circle cx={3} cy={3} r={3} />
                        </svg>
                        {ip}
                      </span>
                    ))
                  ) : (
                    <span className="inline-flex items-center rounded-md bg-red-400/10 px-2 py-1 text-xs font-medium text-red-400 ring-1 ring-inset ring-red-400/30">
                      Tracked
                    </span>
                  )}
                </div>
              ) : (
                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-white-600 ring-1 ring-inset ring-slate-700">
                  <svg
                    className="h-1.5 w-1.5 fill-slate-400"
                    viewBox="0 0 6 6"
                    aria-hidden="true"
                  >
                    <circle cx={3} cy={3} r={3} />
                  </svg>
                  Not tracked
                </span>
              )}
            </div>
          </div>

          <div className="dark:text-slate-400 text-slate-600 text-sm font-normal">
            {[
              itemData.data.type.name,
              ...(itemData.data.certificates ? [itemData.data.certificates.rating] : []),
              ...(itemData.data.original_language ? [itemData.data.original_language.toUpperCase()] : []),
              ...(itemData.data.countries ? [itemData.data.countries.map((country) => country.toUpperCase()).join(", ")] : []),
              ...(itemData.data.release_date ? [formattedDate] : []),
              ...(itemData.data.number_of_seasons ? [`${itemData.data.number_of_seasons} ${itemData.data.number_of_seasons > 1 ? "Seasons" : "Season"}`] : []),
              ...(itemData.data.number_of_episodes ? [`${itemData.data.number_of_episodes} ${itemData.data.number_of_episodes > 1 ? "Episodes" : "Episode"}`] : []),
            ].join(" \u2022 ")}
          </div>
        </Header>
      }
    >
      <Container>
        <div className="space-y-2">
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <div className="w-full rounded-md sm:w-auto">
              {itemData.data.image_url ? (
                <div className="w-64 h-auto">
                  <img
                    src={`https://image.tmdb.org/t/p/w342/${itemData?.data?.image_url}`}
                    alt="..."
                    className="w-64 drop-shadow-lg h-min rounded-md dark:border-slate-600 border-slate-400 border-solid border"
                  />
                </div>
              ) : (
                <div className="w-64 h-full  flex items-center justify-center rounded-md bg-gray-400">
                  <span className="text-white font-extrabold text-4xl">?</span>
                </div>
              )}
            </div>

            <div className="flex flex-col justify-between">
              <div className="flex flex-col gap-1">
                <div className="flex gap-3 items-start">
                {itemData.data?.genre && itemData.data.genre.length > 0 && (
                  <TagList
                    title="Genres"
                    tagData={itemData.data.genre.map((genre) => ({
                      label: genre,
                      color: "purple",
                    }))}
                    />
                  )}
                  {itemData.data.cTags && itemData.data.cTags.length > 0 && (
                    <>
                      <div className="w-0 h-full border-r dark:border-slate-600 border-slate-400"></div>
                      <TagList
                        title="Tags"
                        tagData={itemData.data.cTags.map((cTags) => ({
                          label: cTags,
                          color: "green",
                        }))}
                      />
                    </>
                  )}
                </div>
                {itemData.data.franchise &&
                  itemData.data.franchise.length > 0 && (
                    <TagList
                      title="Franchises"
                      tagData={itemData.data.franchise.map((franchise) => ({
                        label: franchise.name,
                        color: "green",
                        href: `/item/${franchise.id}`,
                      }))}
                    />
                  )}
                {itemData.data.production &&
                  itemData.data.production.length > 0 && (
                    <TagList
                      title="Production companies"
                      tagData={itemData.data.production.map((company) => ({
                        label: company.name,
                        color: "red",
                        href: `/item/companies-${company.id}`,
                      }))}
                    />
                  )}
                {itemData.data.providers && itemData.data.providers.length > 0 && <OTTProvider itemData={itemData} />}
              </div>
              <div className="pt-4">
                <SpaceBetween direction="vertical" size="s">
                  <SpaceBetween direction="horizontal" size="xl">
                    {!!rtCriticSummary?.score && (
                      <RottenTomatesCriticScore
                        rtCriticSummary={rtCriticSummary}
                      />
                    )}
                    {!!rtFanSummary?.score && (
                      <RottenTomatesAudienceScore rtFanSummary={rtFanSummary} />
                    )}
                    {!!itemData.data.imdb_vote_average && (
                      <IMDbScore
                        score={itemData.data.imdb_vote_average}
                        votes={itemData.data.imdb_vote_count}
                        id={itemData.data.imdb_id}
                      />
                    )}
                  </SpaceBetween>
                  <SpaceBetween direction="horizontal" size="xl">
                    {itemData.data?.global_rank?.pantheon && (
                      <Score
                        type={SCORE_TYPES.PANTHEON}
                        rank={itemData.data.global_rank?.pantheon?.daily_rank}
                        weeklyRank={
                          itemData.data.global_rank?.pantheon?.weekly_rank
                        }
                      />
                    )}
                    {!!itemData.data?.ip_ranking?.piracy && (
                      <Score
                        type={SCORE_TYPES.PIRACY}
                        rank={itemData.data.ip_ranking?.piracy?.daily_rank}
                        rankType={"Series"}
                        weeklyRank={
                          itemData.data.ip_ranking?.piracy?.weekly_rank
                        }
                      />
                    )}
                    {!!itemData.data?.ip_ranking?.wiki && (
                      <Score
                        type={SCORE_TYPES.WIKIPEDIA}
                        rank={itemData.data.ip_ranking?.wiki?.daily_rank}
                        weeklyRank={itemData.data.ip_ranking?.wiki?.weekly_rank}
                      />
                    )}
                    {!!itemData.data?.ip_ranking?.youtube && (
                      <Score
                        type={SCORE_TYPES.YOUTUBE}
                        rank={itemData.data.ip_ranking?.youtube?.daily_rank}
                        weeklyRank={
                          itemData.data.ip_ranking?.youtube?.weekly_rank
                        }
                      />
                    )}
                    {!!itemData.data?.ip_ranking?.imdb && (
                      <Score
                        type={SCORE_TYPES.IMDB}
                        rank={itemData.data.ip_ranking?.imdb?.daily_rank}
                        weeklyRank={itemData.data.ip_ranking?.imdb?.weekly_rank}
                      />
                    )}
                  </SpaceBetween>
                </SpaceBetween>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="flex space-x-2 mt-4">
        <div className="w-full ">
          <Tabs
            onChange={handleTabChange}
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Rank Trends",
                id: "rtrends",
                content: (
                  <SpaceBetween direction="vertical" size="l">
                    {itemData.data.ip_ranking && Object.keys(itemData.data.ip_ranking).length > 0 && (
                        <RankingTable ip_ranking={itemData.data.ip_ranking} />
                    )}
                    <RankTrends
                      dateRange={dateRange}
                      setDateRange={setDateRange}
                      wikipediaTrendsData={wikipediaTrendsData}
                      youtubeTrendsData={youtubeTrendsData}
                      imdbTrendsData={imdbTrendsData}
                    />
                  </SpaceBetween>
                ),
              },
              {
                label: "Platform Trends",
                id: "advancedTrends",
                content: (
                  <PlatformTrends
                    dateRange={dateRange}
                    setDateRange={setDateRange}
                    itemData={itemData}
                    wikipediaTrendsData={wikipediaTrendsData}
                    youtubeTrendsData={youtubeTrendsData}
                    imdbTrendsData={imdbTrendsData}
                  />
                ),
              },
              {
                label: "Summary",
                id: "wiki",
                content: (
                  <WikiSummaryBox
                    id={itemData.data.wikidata_id}
                    summary={itemData.data.summary}
                  />
                ),
              },
              {
                label: "Cast & Crew",
                id: "CCT",
                content: (
                    <CastAndCrewComponent itemData={itemData.data} />
                ),
              },
              {
                label: "IDs",
                id: "tags",
                content: (
                  <ExpandableSection
                    defaultExpanded
                    headingTagOverride="h4"
                    variant="container"
                    headerText="IDs"
                  >
                    <TagsAndIDsComponent itemData={itemData.data} />
                  </ExpandableSection>
                ),
              },
              {
                label: "News",
                id: "news",
                content: <NewsBox id={newsBoxId} />,
              },
              {
                label: "Seasons",
                id: "seasons",
                content: itemData.data.seasons.length > 0 && (
                  <SeasonsGrid itemData={itemData.data.seasons} />
                ),
              },
              {
                label: "Episodes",
                id: "episodes",
                content: allEpisodes.length > 0 && (
                  <SortableFilterableTable
                    columnDefinitions={episodeColumnDefinition}
                    items={allEpisodes}
                    filterCountTextFn={getTextFilterCounterText}
                  />
                ),
              },
              {
                label: "Trailers",
                id: "Trailers",
                content: (
                  <TrailerGrid
                    id={itemData.data.ip_id}
                    triggerTrailersApi={triggerTrailersApi}
                    setTriggerTrailersApi={setTriggerTrailersApi}
                    videoId={undefined}
                    setShowAddYoutubeTrailerModal={
                      setShowAddYoutubeTrailerModal
                    }
                  />
                ),
              },
              ...(itemData.data.distributors &&
                itemData.data.distributors.length > 0
                ? [
                  {
                    label: "Distribution",
                    id: "PD",
                    content: (
                      <ProdnDistrib
                        distributors={itemData.data.distributors}
                      />
                    ),
                  },
                ]
                : []),
              ...(itemData.data.awards && itemData.data.awards.length > 0
                ? [
                  {
                    label: "Awards",
                    id: "awards",
                    content: (
                      <Container>
                        <AwardsTable itemData={itemData} />
                      </Container>
                    ),
                  },
                ]
                : []),
              {
                label: "Streaming Window",
                id: "tl",
                content: (
                  <SpaceBetween direction="vertical" size="xl">
                    <StreamingWindowComponent
                      titleId={itemData.data.imdb_id}
                      vertical={itemData.data.vertical}
                    />
                  </SpaceBetween>
                ),
              },
            ]}
          />
        </div>
      </div>
      <TrackUnTrackConfirmationModal
        visible={showTrackUnTrackModal}
        ipLists={IP_LIST}
        track={!addTrack}
        itemData={itemData?.data}
        ip_id={itemData?.data?.ip_id}
        defaultSelectedIps={itemData?.data?.ip_list}
        onDiscard={onTrackUnTrackClose}
        consentText={itemData?.data?.name}
        onConfirm={(item) => {
          if (item.track) {
            onUntrackItem(item);
            onTrackUnTrackClose();
          } else {
            onTrackItem(item);
            onTrackUnTrackClose();
          }
        }}
      />
      <DeleteConfirmationModal
        instances={[itemData?.data]}
        visible={showDeleteModal}
        onDiscard={onDeleteModalClose}
        onDelete={() => onDelete(itemData?.data?.ip_id)}
        consentText={itemData?.data?.name}
      />
      <AddToFranchiseModal
        visible={showAddFranchiseModal}
        onDiscard={onAddFranchiseModalClose}
        itemData={itemData}
      />
      <AddYoutubeTrailerModal
        visible={showAddYoutubeTrailerModal}
        onSubmit={(videoIds) => {
          addYoutubeVideos({
            videoIds,
            ip: itemData?.data?.ip,
            ipId: itemData?.data?.ip_id,
          });
        }}
        onDiscard={() => setShowAddYoutubeTrailerModal(false)}
      />
      <AttributesEditModal
        visible={showAttrModal}
        itemData={itemData?.data}
        onDismiss={() => setShowAttrModal(false)}
        editableAttributes={TAGS_LIST.map((x) => x.value).filter(
          (x) => !["youtubeChannel", "twitch", "discord", "steam"].includes(x),
        )}
      />
      <AuditModal
        visible={showAuditModal}
        ip_id={itemData?.data?.ip_id}
        onDiscard={() => setShowAuditModal(false)}
      />
      <CompareModal
        visible={showCompareModal}
        setVisible={setShowCompareModal}
        titleId={itemData?.data?.ip_id}
      />
    </Container>
  );
}