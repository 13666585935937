import { useMutation } from "react-query";
import { deleteVideos, DeleteVideosParamsType } from "../request";

export const useDeleteVideos = () => {
  const { isLoading, mutateAsync, error } = useMutation(
    (params: DeleteVideosParamsType) => deleteVideos(params),
  );

  return {
    mutateAsync,
    isLoading,
    error,
  };
};
