const shortenInteger = (x) => {
  // Convert x to a number in case it's a string
  const num = Number(x);

  // Check if conversion is successful and num is a finite number
  if (isNaN(num) || !isFinite(num)) {
    return 'N/A'; // or handle the error as needed
  }

  const formatNumber = (value, divisor, suffix) => {
    const result = value / divisor;
    // Check if the result is an integer or needs decimal places
    // If result is integer, return as is, otherwise keep one decimal place
    return `${result % 1 === 0 ? Math.floor(result) : result.toFixed(1)}${suffix}`;
  };

  if (Math.abs(num) >= 1_000_000_000) {
    return formatNumber(num, 1_000_000_000, 'B');
  } else if (Math.abs(num) >= 1_000_000) {
    return formatNumber(num, 1_000_000, 'M');
  } else if (Math.abs(num) >= 1000) {
    return formatNumber(num, 1000, 'K');
  }

  // Return the number with one decimel if it's less than 1000
  return Math.abs(num) % 1 === 0 ? num : num.toFixed(0);
};

export default shortenInteger;
