export const awardColumnDefinition = [
  {
    id: "category",
    header: "Category",
    cell: (item) => item.category || "N/A",
    sortingField: "category",
  },
  {
    id: "year",
    header: "Year",
    cell: (item) => item.year || "N/A",
    sortingField: "year",
  },
  {
    id: "name",
    header: "Name",
    cell: (item) => item.name || "N/A",
    sortingField: "name",
  },
  {
    id: "event",
    header: "Event",
    cell: (item) => item.event || "N/A",
    sortingField: "event",
  },
];

export const organizationFilteringProperties = [
  {
    propertyLabel: "Category",
    key: "category",
    groupValuesLabel: "Category",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Year",
    key: "year",
    groupValuesLabel: "Year",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Name",
    key: "name",
    groupValuesLabel: "Name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Event",
    key: "event",
    groupValuesLabel: "Event",
    operators: [":", "!:", "=", "!="],
  },
].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));
