import { isSome } from "../../../../../../utils/sugarUtils";
import { PantheonRankingResponseItem } from "../request";

const initialValue = {
  distributors: [],
  genre: [],
  franchise: [],
  production: [],
  data: [],
};

type InitialValueType = typeof initialValue;

export const mapGetPantheonResponse = (data) => {
  const mappedResponse = data?.data?.results?.reduce(
    (acc: InitialValueType, item: PantheonRankingResponseItem) => {
      Object.keys(item).forEach((key) => {
        if (initialValue[key] && isSome(item[key])) {
          acc[key] = Array.from(new Set([...acc[key], ...item[key]]));
        }
      });

      acc["data"] = acc["data"].concat({
        ...item,
        IP: item.ip,
        score: item.score,
        selected: false,
      });

      return acc;
    },
    {
      ...initialValue,
    },
  );

  return { ...mappedResponse, count: data?.data?.total };
};

export const combineMappedResponses = (data1, data2) => {
  let value = {};
  Object.keys(data1).forEach((key) => {
    switch (key) {
      case "count":
        value[key] = data1[key];
        break;
      case "data":
        value[key] = data1[key].concat(data2[key]);
        break;
      default:
        value[key] = Array.from(new Set([...data1[key], ...data2[key]]));
        break;
    }
  });

  return value;
};
