import { createSlice } from "@reduxjs/toolkit";

const createRoleInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const createRoleSlice = createSlice({
    name:"createRole",
    initialState:createRoleInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        createRoleComepleted: (state, action) => {
            state.isLoading = false;
            state.message = action.payload
            state.isCompleted = true;
            state.isError = false;
        },
        clearRoleCreateReducer:() => {
            return createRoleInitialState;
        },
        createRoleFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const createRoleAction = createRoleSlice.actions;
const createRoleReducer = createRoleSlice.reducer;

export default createRoleReducer;


