import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getAllPlatformWeightages } from "../requests";

export const useGetAllPlatformWeightages = (platform: string) => {
  const { isLoading, data, error } = useQuery(
    QUERY_KEYS.GET_ALL_PLATFORM_WEIGHTAGES,
    () => getAllPlatformWeightages(platform),
  );

  return {
    data,
    isLoading,
    error,
  };
};
