import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  ContentLayout,
  Header,
  SideNavigation,
  SideNavigationProps,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import SplitPanelContainer from "../../components/SearchDemandSplitPanelContainer";
import { GetPanelContent } from "../../components/SearchDemandUtils";
import { navItems } from "../../layouts/common/menu/side-menu";
import CustomFlashBar from "../common/CustomFlashBar";
import { GeneralTrendsDrawer } from "./components/GeneralTrendsDrawer";
import { WikipediaContent } from "./components/WikipediaContent";

export default function WikipediaPage() {
  const [splitPanelStatus, setSplitPanelStatus] = useState(false);
  const [selectedIps, setSelectedIps] = useState([]);
  const [isHelpPanelOpen, setIsHelpPanelOpen] = useState(false);

  const toggleHelpPanel = () => {
    setIsHelpPanelOpen(!isHelpPanelOpen);
  };

  const breadcrumbs = [
    {
      text: "Platforms",
    },
    {
      text: "Wikipedia",
      href: "/platforms/wikipedia",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Wikipedia</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        splitPanelOpen={splitPanelStatus}
        contentType="dashboard"
        toolsOpen={isHelpPanelOpen}
        onToolsChange={({ detail }) => setIsHelpPanelOpen(detail.open)}
        toolsWidth={600}
        tools={<GeneralTrendsDrawer />}
        onSplitPanelToggle={({ detail: { open } }) => setSplitPanelStatus(open)}
        splitPanel={
          <SplitPanelContainer
            selectedItems={selectedIps}
            onTogglePanelStatus={(status) => setSplitPanelStatus(status)}
            contents={
              <SpaceBetween size={"m"}>
                <GetPanelContent
                  items={selectedIps}
                  setItems={(items) => setSelectedIps(items)}
                />
              </SpaceBetween>
            }
          />
        }
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems as ReadonlyArray<SideNavigationProps.Item>}
          />
        }
        content={
          <ContentLayout
            header={
              <>
                <Header
                  variant="h3"
                  actions={
                    <Button onClick={toggleHelpPanel}>General Trends</Button>
                  }
                >
                  Wikipedia
                </Header>
                <CustomFlashBar />
              </>
            }
          >
            <WikipediaContent />
          </ContentLayout>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs as any}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}
