import { ContentLayout, Header, Tabs } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import React, { useRef, useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../layouts/common/menu/side-menu";
import { addQueryParams, getQueryParams } from "../../../utils/queryUtils";
import CustomFlashBar from "../../common/CustomFlashBar";
import { Layout } from "../Layout";
import CategoryComponent from "./components/CategoryComponent";
// import Example from "./components/ExampleComponent";
import VideoComponent from "./components/VideoComponent";
import ChannelComponent from "./components/ChannelComponent";
import { InfoDrawer } from "./components/InfoDrawer";
const title = "YouTube Trends Dashboard";
const tabs = ["category", "video", "channel"];

function YouTubeTrendsDashboardPage() {
  const breadcrumbs = [{ text: "YouTube", href: "/youtube" }, { text: title }];
  const tabQuery = getQueryParams("tab");
  const isValidTab = tabs.some((str) => str.includes(tabQuery));
  const [activeTabId, setActiveTabId] = useState(
    isValidTab ? tabQuery : "category",
  );
  const flashbarRef = useRef(null);

  if (!isValidTab) {
    addQueryParams({ tab: activeTabId });
  }

  const infoDrawerConfig = {
    id: "infoDrawer",
    content: (
      <InfoDrawer />
    ),
    trigger: {
      iconName: "status-info",
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
  };
  
  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs.map((item) => ({
        ...item,
        href: item.href || "#",
      }))}
      navItems={navItems as NavItemsWithId}
      drawers={[infoDrawerConfig]}
      contentType="table"
      content={
        <ContentLayout
          header={
            <>
              <Header
                variant="h2"
                description="YouTube content consumption data aggregated from YouTube's top 200 trending videos. "
              >
                {title}
              </Header>
              <CustomFlashBar ref={flashbarRef} />
            </>
          }
        >
          <Tabs
            onChange={({ detail }) => {
              setActiveTabId(detail.activeTabId);
              addQueryParams({ tab: detail.activeTabId });
            }}
            activeTabId={activeTabId}
            tabs={[
              {
                label: "Category Trends",
                id: "category",
                content: <CategoryComponent />,
              },
              {
                label: "Channel Trends",
                id: "channel",
                content: <ChannelComponent />,
              },
              {
                label: "Video Trends",
                id: "video",
                content: <VideoComponent />,
              },
              // {
              //   label: "Example",
              //   id: "example",
              //   content: (
              //    <Example />
              //   ),
              // },
            ]}
          />
        </ContentLayout>
      }
    />
  );
}

export default YouTubeTrendsDashboardPage;
