export const ID_TYPES = {
  IMDB: "IMDB",
  IGDB: "IGDB",
};

export const checkIdType = (id: string) => {
  const regexImdb = /^tt\d+$/; // starts with 'tt' and then numbers
  const regexIgdb = /^\d+$/; // contains only numbers

  if (regexImdb.test(id)) return ID_TYPES.IMDB;
  if (regexIgdb.test(id)) return ID_TYPES.IGDB;

  return null;
};
