import { FILTER_OPERATIONS, FILTER_TYPES, VERTICALS } from "../../../../../../config-global";
import { FilteredTrendsHeader, FilteredTrendsFooter, FilteredTrendsContent } from "../component/FilteredTrends";

const title = "Top Netflix Movies";
const vertical = VERTICALS.MOVIES;
const filterObject = {
  colId: "providers",
  filter: "Netflix",
  filterType: FILTER_TYPES.OBJECT,
  type: FILTER_OPERATIONS.CONTAINS,
};

export const netflixTopMovies = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Top movies on Netflix",
    header: () => <FilteredTrendsHeader title={title} />,
    content: () => <FilteredTrendsContent vertical={vertical} filterObject={filterObject} sort="daily_rank" />,
    footer: () => <FilteredTrendsFooter vertical={vertical} filterObject={filterObject} />,
  },
};
