import { SpaceBetween } from "@cloudscape-design/components";
import "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useAuthContext } from "../../../../auth/useAuthContext";
import CustomLoadingOverlay from "../../../../components/PantheonLoading";
import { EmptyGrid } from "../../../../components/grid/EmptyGrid";
import { useGetGridTheme } from "../../../../hooks/UseTheme/useGetGridTheme";
import {
  PLATFORMS,
  UserTypes,
} from "../../../../services/platformPreference/constants";
import { useGetPlatformWeightages } from "../../../../services/platformPreference/hooks/useGetPlatformWeightages";
import { generateNestedObject } from "../../../../utils/helpers";
import { isNone, isSome, isSomeOrElse } from "../../../../utils/sugarUtils";
import TableLegend from "../../../../components/table/TableLegend";
import { getGlobalRanking } from "./api/request";
import { getColumnDefs, mappedWeightage } from "./columnDefs";
import { mapResponseToFormValue } from "./domain/mapResponseToFormValue";
import HeaderContainer from "./header/HeaderContainer";

const FILTERS_PATH = "dashboards.globalRanking.globalScore.filters";

export const RawData = ({ weightageValues, setWeightageValues }) => {
  const defaultDateRange = {
    type: "absolute",
    startDate: moment().subtract(2, "week").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  };
  const { user } = useAuthContext();
  const { theme } = useGetGridTheme();
  const [filterText, setFilterText] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const gridRef = useRef();
  const [selectedFilterName, setSelectedFilterName] = useState(null);
  const [filter, setFilter] = useState(null);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({
    label: "All",
    value: "all",
  });
  const [sortConfig, setSortConfig] = useState({
    colId: "score",
    sort: "desc",
  });
  const platformWeightageProp = {
    user,
    type: UserTypes.PERSONAL,
    platform: PLATFORMS.PANTHEON_RANKING,
    isDefault: true,
    service: "weightage",
    fileName: "default.json",
  };
  const { data: preferenceData } = useGetPlatformWeightages(
    platformWeightageProp,
  );
  useEffect(() => {
    if (!preferenceData) return;
    setWeightageValues(mapResponseToFormValue(preferenceData));
  }, [preferenceData, setWeightageValues]);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );
    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  const getPreferencesAfterDelete = (name) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.globalRanking?.globalScore?.filters,
        {},
      ),
    };
    if (isNone(currentFilters[name])) return;
    delete currentFilters[name];
    return getExistingPantheonScorePreferences(currentFilters);
  };

  const getPreferencesAfterSaveOrUpdate = (name, prevName, isUpdate) => {
    const currentFilters = {
      ...isSomeOrElse(
        globalPref?.dashboards?.globalRanking?.globalScore?.filters,
        {},
      ),
    };
    currentFilters[name] = {
      ...filter,
    };
    if (isUpdate) delete currentFilters[prevName];
    return getExistingPantheonScorePreferences(currentFilters);
  };

  const globalPref = useSelector(
    (state) => state?.globalPersonalPref?.preferences,
  );

  const getExistingPantheonScorePreferences = (currentFilters) => {
    const newGlobalPreferences = generateNestedObject(
      {
        ...globalPref,
      },
      FILTERS_PATH,
      currentFilters,
    );
    return newGlobalPreferences;
  };

  const scoreMapper = (key) => {
    switch (key) {
      case "wiki_score":
      case "yt_view_score":
        return "search_score";
      case "piracy_score":
      case "twitch_score":
        return "demand_score";
      case "imdb_score":
      case "rt_score":
      case "yt_like_score":
      case "yt_comment_score":
        return "engagement_score";
      default:
        return null;
    }
  };

  const getScoredValues = useCallback((item, weightageValues) => {
    const aggregateValues = Object.keys(item)
      .filter((key) => isSome(mappedWeightage[key]))
      .reduce(
        (acc, prev) => {
          const key = scoreMapper(prev);
          const { isChecked, weightage } =
            weightageValues[mappedWeightage[prev]];
          if (isChecked) acc[key] += (item[prev] * Number(weightage)) / 10;
          return acc;
        },
        {
          search_score: 0,
          demand_score: 0,
          engagement_score: 0,
        },
      );

    return {
      ...aggregateValues,
    };
  }, []);

  useEffect(() => {
    if (!dateRange) return;
    setData([]);
    setLoading(true); // Set loading to true
    const fetchData = async () => {
      let allData = [];
      const numPartitions = 7;
      const getParams = (index) => ({
        vertical: "all",
        startdate: moment.utc(dateRange.startDate).unix(),
        enddate: moment.utc(dateRange.endDate).unix(),
        partition: index,
        size: 10000,
        noofpartition: numPartitions,
      });
      const requests = [...Array(numPartitions)].map((_, index) =>
        getGlobalRanking(getParams(index)),
      );
      try {
        const responses = await Promise.all(requests);
        responses.forEach((response) => {
          allData.push(...response.data);
        });
        allData = allData.filter(
          (item) => item.pantheon_score && !isNaN(item.pantheon_score),
        );
        setData(
          allData
            .sort((a, b) => b.pantheon_score - a.pantheon_score)
            .map((item, index) => ({
              ...item,
              rank: index + 1,
              ...getScoredValues(item, weightageValues),
            })),
        );
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        setLoading(false); // Set loading to false
        if (gridRef.current && gridRef.current.api) {
          gridRef.current.api.hideOverlay(); // Hide loading overlay
        }
      }
    };
    fetchData();
  }, [dateRange]);

  useEffect(() => {
    let filtered;
    if (selectedCategory.value === "all") {
      filtered = data;
    } else {
      filtered = data.filter((item) => {
        return item.vertical === selectedCategory.value;
      });
    }
    setFilteredData(filtered);
  }, [selectedCategory, data]);

  const [columnDefs, setColumnDefs] = useState([]);
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 100,
    }),
    [],
  );

  useEffect(() => {
    setData((data) =>
      data
        .sort((a, b) => {
          if (sortConfig.sort === "desc")
            return b[sortConfig.colId] - a[sortConfig.colId];
          else return a[sortConfig.colId] - b[sortConfig.colId];
        })
        .map((item, index) => {
          return {
            ...item,
            rank: index + 1,
            ...getScoredValues(item, weightageValues),
          };
        }),
    );
  }, [getScoredValues, weightageValues, sortConfig]);

  useEffect(() => {
    if (data?.length > 0) {
      const columnDefinitions = getColumnDefs(weightageValues);
      setColumnDefs(columnDefinitions);
    }
  }, [data, weightageValues]);

  const handleFilterItemClick = (id, filters) => {
    const filter = filters[id];
    gridRef.current.api.setAdvancedFilterModel(filter);
    setSelectedFilterName(id);
  };
  const defaultExcelExportParams = useMemo(() => {
    return {
      exportAsExcelTable: true,
    };
  }, []);

  const exportParams = {
    allColumns: true,
    columnKeys: [
      "rank",
      "pantheon_score",
      "ip",
      "release_date",
      "vertical",
      "imdb_score",
      "rt_score",
      "wiki_score",
      "yt_view_score",
      "yt_like_score",
      "yt_comment_score",
      "piracy_score",
      "twitch_score",
      "search_score",
      "demand_score",
      "engagement_score",
    ],
    processCellCallback: (cellParams) => {
      if (cellParams.column.getColId() === "release_date" && cellParams.value) {
        const date = new Date(cellParams.value * 1000);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
      }
      return cellParams.value;
    },
  };

  return (
    <SpaceBetween direction="vertical" size="xs">
      <HeaderContainer
        filterText={filterText}
        setFilterText={setFilterText}
        handleFilterItemClick={handleFilterItemClick}
        selectedFilterName={selectedFilterName}
        filter={filter}
        getPreferencesAfterDelete={getPreferencesAfterDelete}
        getPreferencesAfterSaveOrUpdate={getPreferencesAfterSaveOrUpdate}
        gridRef={gridRef}
        exportParams={exportParams}
        dateRange={dateRange}
        setDateRange={setDateRange}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
      <div style={{ height: "75vh" }} className={theme} data-id="ag-grid-react">
        <AgGridReact
          loading={loading}
          ref={gridRef}
          rowData={filteredData}
          quickFilterText={filterText}
          paginationPageSize={100}
          pagination={true}
          defaultColDef={defaultColDef}
          suppressRowClickSelection={true}
          loadingOverlayComponent={CustomLoadingOverlay}
          noRowsOverlayComponent={EmptyGrid}
          suppressDragLeaveHidesColumns={true}
          enableAdvancedFilter={true}
          tooltipShowDelay={100}
          columnDefs={columnDefs}
          onGridReady={onGridReady}
          onFilterChanged={(params) => {
            const f = params?.api?.getAdvancedFilterModel();
            setFilter(f);
          }}
          onSortChanged={(params) => {
            const { colId, sort } = params.columns[0];
            setSortConfig({ colId, sort });
          }}
          defaultExcelExportParams={defaultExcelExportParams}
        />
      </div>
      <TableLegend />
    </SpaceBetween>
  );
};
