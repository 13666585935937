import {
  Alert,
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";

type WarningModalProps = {
  title: string;
  description: string;
  visible: boolean;
  onDismiss: () => void;
  onSuccess: () => void;
};

export const WarningModal: FC<WarningModalProps> = ({
  title,
  description,
  visible,
  onDismiss,
  onSuccess,
}) => {
  return (
    <Modal
      onDismiss={onDismiss}
      visible={visible}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onSuccess}>
              Continue
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={title}
    >
      <Alert statusIconAriaLabel="Warning" type="warning">
        {description}
      </Alert>
    </Modal>
  );
};
