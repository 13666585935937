import {
    Box,
    Button,
    Checkbox,
    DatePicker,
    Form,
    FormField,
    Input,
    Modal,
    Select,
    SpaceBetween,
    Spinner,
    StatusIndicator
} from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import axiosInstance from '../../../../../utils/axios';

const initialFormData = {
    vertical: '',
    start_date: '',
    end_date: '',
    platform: [],
    report_type: '',
    category: '',
    filename: '',
};

const GenerateReportModal = ({ visible, onDismiss }) => {
    const [formData, setFormData] = useState(initialFormData);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        if (visible) {
            setFormData(initialFormData);
            setApiResponse(null);
            setIsFormValid(false);
        }
    }, [visible]);

    useEffect(() => {
        const isValid = Object.values(formData).every(value =>
            Array.isArray(value) ? value.length > 0 : value !== ''
        );
        setIsFormValid(isValid);
    }, [formData]);

    const formatDate = (dateString) => {
        return dateString.replace(/-/g, '');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!isFormValid) return;
        setIsLoading(true);
        try {
            const formattedData = {
                ...formData,
                start_date: formatDate(formData.start_date),
                end_date: formatDate(formData.end_date),
            };
            const response = await axiosInstance.post('/report', formattedData);
            setApiResponse(response.data);
            if (response.status === 200) {
                console.log('Report generated successfully');
            } else {
                console.error('Failed to generate report');
            }
        } catch (error) {
            console.error('Error:', error);
            setApiResponse({ error: 'An error occurred while generating the report.' });
        } finally {
            setIsLoading(false);
        }
    };

    const handlePlatformChange = (platform) => {
        setFormData(prevData => ({
            ...prevData,
            platform: prevData.platform.includes(platform)
                ? prevData.platform.filter(p => p !== platform)
                : [...prevData.platform, platform]
        }));
    };

    const handleClose = () => {
        setFormData(initialFormData);
        setApiResponse(null);
        onDismiss(); // This will trigger the refresh
    };

    const renderApiResponse = () => {
        if (!apiResponse) return null;

        return (
            <Box padding="l">
                <SpaceBetween direction="vertical" size="l">
                <hr className='border-t border-gray-300 dark:border-gray-700' />
                <StatusIndicator type="success">
                        {apiResponse.message}
                    </StatusIndicator>
                    <div>
                        <strong>Report ID:</strong> {apiResponse.id}
                    </div>
                    <div>
                        <strong>Status:</strong> {apiResponse.status}
                    </div>

                    <hr className='border-t border-gray-300 dark:border-gray-700' />
                    <div className="flex justify-end gap-2">
                        <Button onClick={handleClose}>Close</Button>
                    </div>
                </SpaceBetween>
            </Box>
        );
    };

    return (
        <Modal
            visible={visible}
            onDismiss={isLoading ? () => { } : onDismiss}
            header="Generate Report"
        >
            {apiResponse ? renderApiResponse() : (
                <Form>
                    <SpaceBetween direction="vertical" size="l">
                        <hr className='border-t border-gray-300 dark:border-gray-700' />
                        <FormField
                            label="Filename"
                            description="Enter a name for the report file"
                        >
                            <Input
                                value={formData.filename}
                                onChange={({ detail }) => setFormData({ ...formData, filename: detail.value })}
                            />
                        </FormField>

                        <FormField
                            label="Vertical"
                            description="Select the content vertical for the report"
                        >
                            <Select
                                options={[
                                    { label: 'Series', value: 'series' },
                                    { label: 'Movies', value: 'movies' },
                                ]}
                                selectedOption={formData.vertical ? { label: formData.vertical, value: formData.vertical } : null}
                                onChange={({ detail }) =>
                                    setFormData(prevData => ({ ...prevData, vertical: detail.selectedOption.value }))
                                }
                            />
                        </FormField>
                        <div className="flex flex-row gap-2">
                            <FormField
                                label="Start Date"
                                description="Select the start date for the report period"
                            >
                                <DatePicker
                                    value={formData.start_date}
                                    onChange={({ detail }) => setFormData({ ...formData, start_date: detail.value })}
                                />
                            </FormField>
                            <FormField
                                label="End Date"
                                description="Select the end date for the report period"
                            >
                                <DatePicker
                                    value={formData.end_date}
                                    onChange={({ detail }) => setFormData({ ...formData, end_date: detail.value })}
                                />
                            </FormField>
                        </div>
                        <FormField
                            label="Platforms"
                            description="Select one or more platforms to include in the report"
                        >
                            <SpaceBetween direction="vertical" size="xs">
                                <Checkbox
                                    checked={formData.platform.includes('wikipedia')}
                                    onChange={() => handlePlatformChange('wikipedia')}
                                >
                                    Wikipedia
                                </Checkbox>
                                {/* <Checkbox
                                    checked={formData.platform.includes('piracy_downloads')}
                                    onChange={() => handlePlatformChange('piracy_downloads')}
                                >
                                    Piracy Downloads
                                </Checkbox> */}
                            </SpaceBetween>
                        </FormField>
                        <FormField
                            label="Report Type"
                            description="Select the type of report to generate"
                        >
                            <Select
                                options={[{ label: 'Non-OTT', value: 'nonott' }]}
                                selectedOption={{ label: formData.report_type, value: formData.report_type }}
                                onChange={({ detail }) => setFormData({ ...formData, report_type: detail.selectedOption.value })}
                            />
                        </FormField>
                        <FormField
                            label="Category"
                            description="Select the category for this report"
                        >
                            <Select
                                options={[
                                    { label: 'Personal', value: 'personal' },
                                    { label: 'Organization', value: 'organization' }
                                ]}
                                selectedOption={formData.category ? { label: formData.category, value: formData.category } : null}
                                onChange={({ detail }) => setFormData(prevData => ({ ...prevData, category: detail.selectedOption.value }))}
                            />
                        </FormField>
                        <hr className='border-t border-gray-300 dark:border-gray-700' />
                        <div className="flex justify-end gap-2">
                            <Button
                                variant="primary"
                                onClick={handleSubmit}
                                disabled={isLoading || !isFormValid}
                            >
                                {isLoading ? <Spinner /> : 'Generate Report'}
                            </Button>
                            <Button
                                variant="normal"
                                onClick={onDismiss}
                                disabled={isLoading}
                            >
                                Cancel
                            </Button>
                        </div>
                    </SpaceBetween>
                </Form>
            )}
        </Modal>
    );
};

export default GenerateReportModal;
