import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  Alert,
  ButtonDropdown,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Pagination,
  PropertyFilter,
  SpaceBetween,
  StatusIndicator,
  Table
} from "@cloudscape-design/components";
import AppLayout from "@cloudscape-design/components/app-layout";
import Box from "@cloudscape-design/components/box";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import Button from "@cloudscape-design/components/button";
import SideNavigation from "@cloudscape-design/components/side-navigation";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useAuthContext } from "../../../auth/useAuthContext";
import { CommonPreference } from "../../../components/CommonPreference";
import FormModel from "../../../components/FormModel";
import { navItems } from "../../../layouts/common/menu/side-menu";
import TableEmptyState from "../../../utils/TableEmptyState";
import TableNoMatchState from "../../../utils/TableNoMatchState";
import axiosInstance from "../../../utils/axios";
import createFlashMessage from "../../../utils/createFlashMessage";
import createTableSortLabelFn from "../../../utils/createTableSortLabelFn";
import getTextFilterCounterText from "../../../utils/getTextFilterCounterText";
import CustomFlashBar from "../../common/CustomFlashBar";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../../common/redux/flash-action";
import { getOrganizationsList } from "../organizations/redux/actions/organizations-actions";
import { getRolesList } from "../roles/redux/actions/roles-action";
import CreateUserForm from "./forms/create-user-form";
import EditUserForm from "./forms/edit-user-form";
import { clearUserCreateFn, getUsersList } from "./redux/actions/user-actions";
import {
  filteringProperties,
  visibleContentPreference,
} from "./table-properties/user-table-properties";
import { useNavigate } from "react-router-dom";
import { SERVICES_GROUP } from "../../../layouts/common/menu/side-menu";


export default function UserListPage() {
  const userList = useSelector((state) => state.userList);
  const rolesList = useSelector((state) => state.rolesList);
  const organizationsList = useSelector((state) => state.organizationsList);
  const [userListData, setUserListData] = useState([]);
  const [openModel, setOpenModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [openResetModel, setOpenResetModel] = useState(false);
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { logout, user: LoginUser } = useAuthContext();

  const createUser = useSelector((state) => state.createUser);
  useEffect(() => {
    if (
      !createUser.isLoading &&
      createUser.isCompleted &&
      !createUser.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: "User is created",
        })
      );
    } else if (
      !createUser.isLoading &&
      createUser.isCompleted &&
      createUser.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: createUser.message,
        })
      );
    }
  }, [createUser]);

  const editUser = useSelector((state) => state.editUser);
  useEffect(() => {
    if (!editUser.isLoading && editUser.isCompleted && !editUser.isError) {
      addMessageToFlash(
        createFlashMessage({
          type: "success",
          message: "User is updated",
        })
      );
    } else if (
      !editUser.isLoading &&
      editUser.isCompleted &&
      editUser.isError
    ) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: editUser.message,
        })
      );
    }
  }, [editUser]);

  const [preferences, setPreferences] = React.useState({
    pageSize: 20,
    wrapLines: false,
  });

  const getUserListData = () => {
    getUsersList();
  };
  const getOrganizationsListData = () => {
    getOrganizationsList();
  };

  const getRolesListData = () => {
    getRolesList();
  };

  useEffect(() => {
    setSelectedItems([]);
    getUserListData();
    getOrganizationsListData();
    getRolesListData();
    return () => {
      cleanAllFlashMessage();
    };
  }, []);

  const navigate = useNavigate();

  const onFollowHandler = (event) => {
    event.preventDefault();
    navigate(event.detail.href);
  };

  const filteredNavItems = navItems.filter(
    (item) =>
      item.type === "section-group" && item?.id === SERVICES_GROUP.ACCOUNT_MANAGEMENT,
  );

  useEffect(() => {
    if (
      !userList.isUserListLoading &&
      userList.isUserListLoadingCompleted &&
      userList.userList.length > 0
    ) {
      const data = userList.userList.map((d) => {
        const ob = d.Attributes.reduce(
          (obj, item) => Object.assign(obj, { [item.Name]: item.Value }),
          {}
        );
        return {
          ...d,
          ...ob,
          organization: ob["custom:organization"],
          role: ob["custom:role"],
          enabled: d.Enabled,
          createdAt: d.UserCreateDate,
          updatedAt: d.UserLastModifiedDate,
          status: d.UserStatus,
          isVerified: ob.email_verified,
        };
      });
      setUserListData(data);
    }
  }, [userList]);

  const enableDisableUser = (user, status) => {
    axiosInstance
      .request({
        url: status
          ? `/admin/user/${user}/disable`
          : `/admin/user/${user}/enable`,
        method: "PATCH",
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.data.message,
          })
        );

        getUserListData();
      })
      .catch((e) =>
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.message,
          })
        )
      );
  };

  const resetPassword = (user) => {
    setOpenResetModel(false);
    axiosInstance
      .request({
        url: `/admin/user/${user}/resetpassword`,
        method: "PATCH",
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.data.message,
          })
        );
        setSelectedItems([]);

        getUserListData();
        if (LoginUser.username === user) {
          logout();
        }
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.message,
          })
        );

        setSelectedItems([]);
      });
  };

  const deleteUser = (user) => {
    setOpenDeleteModel(false);
    axiosInstance
      .request({
        url: `/admin/users`,
        method: "DELETE",
        data: {
          userNames: user,
        },
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.data.message,
          })
        );

        setSelectedItems([]);

        getUserListData();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message: e.message,
          })
        );
        setSelectedItems([]);
      });
  };

  const columnDefinitions = [
    {
      header: "Name",
      id: "name",
      sortingField: "name",
      cell: (e) => e.name,
    },
    {
      header: "Username",
      id: "Username",
      sortingField: "Username",
      cell: (e) => e.Username,
    },
    {
      header: "Email",
      id: "email",
      sortingField: "email",
      cell: (e) => e.email,
    },
    {
      header: "Organization",
      id: "organization",
      sortingField: "organization",
      cell: (e) => e.organization,
    },
    {
      header: "Status",
      id: "status",
      sortingField: "status",
      cell: (e) => (
        <StatusIndicator type={e.status === "CONFIRMED" ? "success" : "error"}>
          {e.status}
        </StatusIndicator>
      ),
    },
    {
      header: "Role",
      id: "role",
      sortingField: "role",
      cell: (e) => e.role,
    },

    {
      header: "Enabled",
      id: "enabled",
      sortingField: "enabled",
      cell: (e) => <StatusIndicator type={!e.enabled ? "error" : "success"} />,
    },

    {
      header: "Created At",
      id: "createdAt",
      sortingField: "createdAt",
      cell: (e) =>
        e.createdAt ? moment(e.createdAt).format("MM/DD/yyyy, hh:mm A") : "",
    },
    {
      header: "Updated At",
      id: "updatedAt",
      sortingField: "updatedAt",
      cell: (e) =>
        e.updatedAt ? moment(e.updatedAt).format("MM/DD/yyyy, hh:mm A") : "",
    },
  ];
  const rawColumns = columnDefinitions.map((column) => ({
    ...column,
    ariaLabel: createTableSortLabelFn(column),
  }));

  const onDeleteInit = () => setOpenDeleteModel(true);
  const deletingItemsSelected =
    selectedItems.filter((it) => it.state === "deleting").length > 0;

  function DeleteModal({ users, visible, onDiscard, onDelete }) {
    const deleteConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onDelete();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === deleteConsentText;

    const isMultiple = users.length > 1;
    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={isMultiple ? "Delete users" : "Delete user"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onDelete}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Delete
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        {users.length > 0 && (
          <SpaceBetween size="m">
            {isMultiple ? (
              <Box variant="span">
                Permanently delete
                <Box variant="span" fontWeight="bold">
                  {users.length} users
                </Box>
                ? You can’t undo this action.
              </Box>
            ) : (
              <Box variant="span">
                Permanently delete user
                <Box variant="span" fontWeight="bold">
                  {users[0].id}
                </Box>
                ? You can’t undo this action.
              </Box>
            )}

            <Alert type="warning" statusIconAriaLabel="Warning">
              Proceeding with this action will delete the
              {isMultiple
                ? " users with all their content "
                : " user with all its content"}
              and can affect related items.
            </Alert>

            <Box>
              To avoid accidental deletions, we ask you to provide additional
              written consent.
            </Box>

            <ColumnLayout columns={2}>
              <form onSubmit={handleDeleteSubmit}>
                <FormField
                  label={`To confirm this deletion, type "${deleteConsentText}".`}
                >
                  <Input
                    placeholder={deleteConsentText}
                    onChange={(event) => setDeleteInputText(event.detail.value)}
                    value={deleteInputText}
                    ariaRequired={true}
                  />
                </FormField>
              </form>
            </ColumnLayout>
          </SpaceBetween>
        )}
      </Modal>
    );
  }

  function ResetPasswordModel({ username, visible, onDiscard, onReset }) {
    const resetConsentText = "confirm";

    const [deleteInputText, setDeleteInputText] = useState("");
    useEffect(() => {
      setDeleteInputText("");
    }, [visible]);

    const handleDeleteSubmit = (event) => {
      event.preventDefault();
      if (inputMatchesConsentText) {
        onReset();
      }
    };

    const inputMatchesConsentText =
      deleteInputText.toLowerCase() === resetConsentText;

    return (
      <Modal
        visible={visible}
        onDismiss={onDiscard}
        header={"Reset Password"}
        closeAriaLabel="Close dialog"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onDiscard}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={onReset}
                disabled={!inputMatchesConsentText}
                data-testid="submit"
              >
                Reset
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween size="m">
          <Box>
            To avoid accidental action, we ask you to provide additional written
            consent.
          </Box>

          <ColumnLayout columns={2}>
            <form onSubmit={handleDeleteSubmit}>
              <FormField
                label={`To confirm this action, type "${resetConsentText}".`}
              >
                <Input
                  placeholder={resetConsentText}
                  onChange={(event) => setDeleteInputText(event.detail.value)}
                  value={deleteInputText}
                  ariaRequired={true}
                />
              </FormField>
            </form>
          </ColumnLayout>
        </SpaceBetween>
      </Modal>
    );
  }

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
    propertyFilterProps,
  } = useCollection(userListData, {
    selection: {},
    propertyFiltering: {
      filteringProperties: filteringProperties,
      empty:
        userList.isUserListLoading && !userList.isUserListLoadingCompleted ? (
          ""
        ) : (
          <TableEmptyState resourceName={"User"} />
        ),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() => {
            actions.setPropertyFiltering({ tokens: [], operation: "and" });
          }}
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: { defaultState: { sortingColumn: rawColumns[0] } },
  });

  const Content = () => {
    return (
      <Table
        {...collectionProps}
        onSelectionChange={(d) => setSelectedItems(d.detail.selectedItems)}
        selectedItems={selectedItems}
        columnDisplay={preferences.contentDisplay}
        items={items}
        columnDefinitions={columnDefinitions}
        variant="full-page"
        header={
          <>
            <Header
              variant="h3"
              actions={
                <SpaceBetween direction="horizontal" size="l">
                  <Button
                    variant="primary"
                    onClick={() => setOpenModel(true)}
                  >
                    Create
                  </Button>
                  <ButtonDropdown
                    disabled={
                      selectedItems.length === 0 || selectedItems.length > 1
                        ? true
                        : false
                    }
                    onItemClick={(e) => {
                      if (e.detail.id === "edit") {
                        setOpenEditModel(true);
                      } else if (e.detail.id === "enable") {
                        enableDisableUser(selectedItems[0].Username, false);
                        setSelectedItems([]);
                      } else if (e.detail.id === "disable") {
                        enableDisableUser(selectedItems[0].Username, true);
                        setSelectedItems([]);
                      } else if (e.detail.id === "reset_password") {
                        setOpenResetModel(true);
                      }
                    }}
                    items={[
                      { text: "Edit User", id: "edit", disabled: false },
                      {
                        text: "Reset Password",
                        id: "reset_password",
                        disabled: false,
                      },
                      selectedItems.length > 0 && selectedItems[0].enabled
                        ? {
                          text: "Disable User",
                          id: "disable",
                          disabled: false,
                        }
                        : {
                          text: "Enable User",
                          id: "enable",
                          disabled: false,
                        },
                    ]}
                  >
                    Actions
                  </ButtonDropdown>
                  <Button
                    disabled={
                      selectedItems.length === 0 || deletingItemsSelected
                    }
                    onClick={() => {
                      onDeleteInit();
                    }}
                  >
                    Delete
                  </Button>
                </SpaceBetween>
              }
              counter={
                selectedItems.length
                  ? "(" +
                  selectedItems.length +
                  "/" +
                  userListData.length +
                  ")"
                  : "(" + userListData.length + ")"
              }
            >
              Users
            </Header>

            <div className="py-5">
              <CustomFlashBar />
            </div>
          </>
        }
        selectionType="multi"
        resizableColumns={true}
        ariaLabels={{
          itemSelectionLabel: (_data, row) => `select ${row.id}`,
          allItemsSelectionLabel: () => "select all",
          selectionGroupLabel: `User selection`,
        }}
        preferences={
          <CommonPreference
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            contentDisplayOptions={visibleContentPreference}
          />
        }
        pagination={
          <Pagination
            {...paginationProps}
            ariaLabels={{
              nextPageLabel: "Next page",
              previousPageLabel: "Previous page",
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        wrapLines={preferences.wrapLines}
        stripedRows={preferences.stripedRows}
        contentDensity={preferences.contentDensity}
        filter={
          <PropertyFilter
            countText={getTextFilterCounterText(filteredItemsCount)}
            {...propertyFilterProps}
            i18nStrings={{
              filteringAriaLabel: "your choice",
              dismissAriaLabel: "Dismiss",
              clearAriaLabel: "Clear",

              filteringPlaceholder: "Filter users by text, property or value",
              groupValuesText: "Values",
              groupPropertiesText: "Properties",
              operatorsText: "Operators",

              operationAndText: "and",
              operationOrText: "or",

              operatorLessText: "Less than",
              operatorLessOrEqualText: "Less than or equal",
              operatorGreaterText: "Greater than",
              operatorGreaterOrEqualText: "Greater than or equal",
              operatorContainsText: "Contains",
              operatorDoesNotContainText: "Does not contain",
              operatorEqualsText: "Equals",
              operatorDoesNotEqualText: "Does not equal",

              editTokenHeader: "Edit filter",
              propertyText: "Property",
              operatorText: "Operator",
              valueText: "Value",
              cancelActionText: "Cancel",
              applyActionText: "Apply",
              allPropertiesLabel: "All properties",

              tokenLimitShowMore: "Show more",
              tokenLimitShowFewer: "Show fewer",
              clearFiltersText: "Clear filters",
              removeTokenButtonAriaLabel: (token) =>
                `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
              enteredTextLabel: (text) => `Use: "${text}"`,
            }}
            {...propertyFilterProps}
            expandToViewport={true}
          />
        }
      />
    );
  };
  const breadcrumbs = [
    {
      text: "Account Management",
      // href: '/users',
    },
    {
      text: "Users",
      href: "/users",
    },
  ];

  return (
    <>
      <Helmet>
        <title>Pantheon - Users</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
          onFollow={onFollowHandler}
          activeHref={window.location.pathname}
          items={filteredNavItems}
          />
        }
        contentType="table"
        content={
          <Content />
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
      <DeleteModal
        visible={openDeleteModel}
        users={selectedItems}
        onDelete={(e) => {
          if (selectedItems.length > 0) {
            deleteUser(selectedItems.map((m) => m.Username));
          }
        }}
        onDiscard={(e) => {
          setSelectedItems([]);
          setOpenDeleteModel(false);
        }}
      />
      <ResetPasswordModel
        visible={openResetModel}
        onReset={(e) => {
          if (selectedItems.length > 0) {
            resetPassword(selectedItems[0].Username);
          }
        }}
        onDiscard={(e) => {
          setSelectedItems([]);
          setOpenResetModel(false);
        }}
      />

      <FormModel
        header="Create User"
        open={openModel}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="normal"
                formAction="none"
                onClick={() => {
                  clearUserCreateFn();
                  setOpenModel(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                formAction="submit"
                form="user_create_form"
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
        }
        onClose={() => {
          clearUserCreateFn();
          setOpenModel(false);
        }}
      >
        <>
          <CreateUserForm
            formStatus={openModel}
            setCompleted={() => {
              getUserListData();
            }}
            orgs={
              organizationsList.organizationsList.length > 0
                ? organizationsList.organizationsList.map((d) => ({
                  value: d.id,
                  label: d.name,
                }))
                : []
            }
            rolesList={
              rolesList.rolesList.length > 0
                ? rolesList.rolesList.map((i) => ({
                  value: i.id,
                  label: i.roleName,
                }))
                : []
            }
            setCancel={() => {
              clearUserCreateFn();
              setOpenModel(false);
            }}
          />
        </>
      </FormModel>
      <FormModel
        header={"Edit User"}
        open={openEditModel}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="normal"
                formAction="none"
                onClick={() => {
                  setOpenEditModel(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                formAction="submit"
                form="edit_create_form"
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
        }
        onClose={() => {
          setOpenEditModel(false);
        }}
      >
        <>
          <EditUserForm
            defaults={openEditModel ? selectedItems[0] : null}
            setCompleted={() => {
              setSelectedItems([]);
              getUserListData();
            }}
            orgs={
              organizationsList.organizationsList.length > 0
                ? organizationsList.organizationsList.map((d) => ({
                  value: d.id,
                  label: d.name,
                }))
                : []
            }
            rolesList={
              rolesList.rolesList.length > 0
                ? rolesList.rolesList.map((i) => ({
                  value: i.id,
                  label: i.roleName,
                }))
                : []
            }
            setCancel={() => setOpenEditModel(false)}
          />
        </>
      </FormModel>
    </>
  );
}
