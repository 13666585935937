export const getSentimentCategory = (score: number) => {
  const sentimentScores = {
    Overwhelmingly_positive: [0.8, 1.0],
    Very_positive: [0.6, 0.8],
    Positive: [0.4, 0.6],
    Mostly_positive: [0.2, 0.4],
    Mixed: [-0.2, 0.2],
    Mostly_negative: [-0.4, -0.2],
    Negative: [-0.6, -0.4],
    Very_negative: [-0.8, -0.6],
    Overwhelmingly_negative: [-1.0, -0.8],
  };

  for (const [sentiment, [min, max]] of Object.entries(sentimentScores)) {
    if (score >= min && score <= max) {
      return sentiment.replace(/_/g, " ");
    }
  }

  return " ";
};

export const getSentimentColor = (score: number) => {
  if (score > 0.2) {
    return "text-status-success";
  } else if (score >= -0.2 && score <= 0.2) {
    return "text-body-secondary";
  } else {
    return "text-status-error";
  }
};

export const getSentimentColorClasses = (score: number) => {
  const colorMap = {
    "text-status-success": "text-green-600 dark:text-green-400",
    "text-body-secondary": "text-slate-600 dark:text-slate-400",
    "text-status-error": "text-red-600 dark:text-red-400",
  };
  return colorMap[getSentimentColor(score)];
};