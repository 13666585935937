import { TrendsContent, TrendsFooter, TrendsHeader } from "../component/Trends";

const title = "Piracy Movies Trends";

export const piracyMovies = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Daily Piracy downloads for movies",
    header: () => <TrendsHeader title={title} />,
    content: () => <TrendsContent vertical="movies" platform="piracy" sort="daily_rank" />,
    footer: () => <TrendsFooter linkHref="/platforms/piracy?tab=trends&category=movies" />,
  },
};