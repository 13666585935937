import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import _ from "lodash";
import axiosInstance from "../../../../utils/axios";

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

export const fetchAo3Raw = createAsyncThunk(
  "ao3RawData/fetchAo3Raw",

  async ({ lte, gte, interval }) => {
    const response = await axiosInstance.request({
      url: "ao3/raw",
      method: "GET",
      params: {
        lte,
        gte,
        interval,
      },
    });
    return response;
  }
);

const ao3RawDataSlice = createSlice({
  name: "ao3RawData",
  initialState,
  reducers: {
    clearData: () => initialState,
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAo3Raw.pending, (state) => {
        _.set(state, "loading", true);
      })
      .addCase(fetchAo3Raw.fulfilled, (state, action) => {
        _.set(state, "loading", false);
        _.set(state, "completed", true);
        _.set(state, "data", action?.payload?.data || []);
      })
      .addCase(fetchAo3Raw.rejected, (state, action) => {
        _.set(state, "loading", false);
        _.set(state, "completed", true);
        _.set(state, "hasError", true);
        _.set(state, "message", action?.error?.message);
      });
  },
});

const Ao3RawDataReducer = ao3RawDataSlice.reducer;

export default Ao3RawDataReducer;

export const ao3RawDataAction = ao3RawDataSlice.actions;
