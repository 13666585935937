import React from "react";
import { NetflixSummaryChart } from "./NetflixSummaryChart";
import { Container } from "@cloudscape-design/components";

interface NetflixWeeklySummary {
  timestamp: string;
  date: string;
  Films_en_views: string;
  Films_en_hours: string;
  Films_non_en_views: string;
  Films_non_en_hours: string;
  TV_en_views: string;
  TV_en_hours: string;
  TV_non_en_views: string;
  TV_non_en_hours: string;
}

interface NetflixSummaryChartsGridProps {
  data: NetflixWeeklySummary[];
  showViews: boolean;
}

export const NetflixSummaryChartsGrid: React.FC<NetflixSummaryChartsGridProps> = ({ data, showViews }) => {
  return (
      <NetflixSummaryChart
        data={data?.map(item => ({
          timestamp: item.timestamp,
          date: item.date,
          Films_English: parseInt(showViews ? item.Films_en_views : item.Films_en_hours),
          Films_Non_English: parseInt(showViews ? item.Films_non_en_views : item.Films_non_en_hours),
          TV_English: parseInt(showViews ? item.TV_en_views : item.TV_en_hours),
          TV_Non_English: parseInt(showViews ? item.TV_non_en_views : item.TV_non_en_hours),
        }))}
        showViews={showViews}
      />
  );
};