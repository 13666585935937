import { min } from "lodash";
import React, { useCallback, useMemo } from "react";

const imageRenderer = (params) => (
  <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    {params.value && (
      <img
        alt="poster"
        src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${params.value}.png`}
        style={{ width: '80px', height: 'auto', paddingTop: '4px', paddingBottom: '4px'}}
      />
    )}
  </span>
);

const columnDefs = [
  // other column definitions
  {
    headerName: 'Image',
    field: 'imageUrl', // replace with your actual field name
    cellRendererFramework: imageRenderer,
  },
  // other column definitions
];



export const useGridConfig = (selectedVertical, fetchData) => {
  const initialCheckboxConfig = useMemo(
    () => [
    ],
    [selectedVertical],
  );

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      wrapText: true,
      autoHeight: true,
    }),
    [],
  );
  const columnDefs = useMemo(() => {
    const baseColumns = [
//  create a row index field that will be used to num,ber the rows
      // {
      //   headerName : "Index",
      //   valueGetter: "node.rowIndex + 1",
      //   maxWidth: 100,
      //   filter: false,
      // },
      {
        field: "image_url",
        headerName: "Image",
        filter: false,
        cellRenderer: imageRenderer,
        maxWidth: 100,
        minWidth: 100,
      },
      {
        field: "id",
        headerName: "Id",
        filter: true,
        filterParams: {
          filterOptions: ["equals"],
        },
        maxWidth: 120,
      },
      {
        field: "name",
        headerName: "Title",
        filterParams: {
          filterOptions: ["equals"],
        },
        maxWidth: 300,
      },
      {
        field: "release_date",
        headerName: "Release Date",
        maxWidth: 150,
        cellDataType: "date",
        valueFormatter: (params) => {
          if (!params.value) return "";
          const dateParts = params.value.split('-');
          if (dateParts.length !== 3) return params.value;
          const year = dateParts[0];
          const month = dateParts[1].padStart(2, '0'); 
          const day = dateParts[2].padStart(2, '0');
          return `${year}-${month}-${day}`;
        },
      },
      {
        field : "summary",
        headerName : "Summary",
        filter: false,
        minWidth: 500,
      },
      {
        field : "genre",
        headerName : "Genre",
        filter: true,
        filterParams: {
          filterOptions: ["equals", "contains"],
        },
      }
    ];


    return [...baseColumns];
  }, []);

  const onGridReady = useCallback(
    (params) => {
      const advancedFilterElement = document.getElementById(
        "advancedFilterParent",
      );
      if (advancedFilterElement) {
        params.api.setGridOption("advancedFilterParent", advancedFilterElement);
        params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
      }

      fetchData();
    },
    [fetchData],
  );

  return {
    initialCheckboxConfig,
    defaultColDef,
    columnDefs,
    onGridReady,
  };
};
