import Logo from "../assets/images/Logo";
import FeatureImage from '../assets/images/FeatureImage.png'

export const AuthLayout = ({ children }) => {

    return (
        <div className="h-screen">
            <div className=" px-6 py-12 h-full m-auto">
                <div className="flex justify-center items-center flex-wrap h-full">
                    <div className="hidden md:block md:w-8/12 lg:w-7/12">
                        <img
                            className="w-full h-full border-0 lg:border-r lg:border-r-gray-700 lg:border-dotted xl:scale-75"
                            src={FeatureImage}
                            alt="Feature Image"
                        />
                    </div>
                    <div className="md:w-8/12 lg:w-3/12 lg:m-5">
                        <div className="flex flex-col justify-center items-center">
                            <Logo height={12} width={12} />
                            <h6 className="mt-6 text-2xl p-5 font-bold tracking-tight text-gray-100">Sign in to your account</h6>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );

    
}