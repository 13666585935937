import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../../utils/axios";
import { debounce } from 'lodash';

const initialState = {
  data: [],
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

const handler = async ({ params }) => {
  const response = await axiosInstance.request({
    url: `https://en.wikipedia.org/w/rest.php/v1/search/title`,
    method: `GET`,
    params: params,
    headers: {
      "Authorization": null,
    }
  });
  return response;
};
const debouncedHandler = (func, wait) => {
  const debounced = debounce((resolve, reject, args) => {
    func(...args).then(resolve).catch(reject);
  }, wait);
  return (...args) => new Promise((resolve, reject) => {
    debounced(resolve, reject, args);
  });
}

export const fetchWikipediaTopics = createAsyncThunk(
  "amazonvendor/fetchWikipediaTopics",
  debouncedHandler(handler, 500)
);

const wikipediaTopicSearchSlice = createSlice({
  name: "amazonvendor",
  initialState,
  reducers: {
    clearData: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchWikipediaTopics.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchWikipediaTopics.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload.data;
      })
      .addCase(fetchWikipediaTopics.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const WikipediaTopicSearchReducer = wikipediaTopicSearchSlice.reducer;

export default WikipediaTopicSearchReducer;

export const wikipediaTopicSearchAction = wikipediaTopicSearchSlice.actions;
