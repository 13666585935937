import {
  ContentLayout,
  Header,
  IconProps,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import {
  NavItemsWithId,
  navItems,
} from "../../../../layouts/common/menu/side-menu";
import CustomFlashBar from "../../../common/CustomFlashBar";
import { Layout } from "../../Layout";
import { RawData } from "./RawData";
import {
  PlatformWeightageDrawer,
  defaultWeightageValues,
} from "./components/PlatformWeightageDrawer";

function GRRawDataIndexPage({vertical}) {
  const section = "Global";
  const title = "Data Table";
  const breadcrumbs = [
    { text: "Ranking" },
    { text: section, href: "/ranking/global" },
    { text: title },
  ];

  const [weightageValues, setWeightageValues] = useState(
    defaultWeightageValues,
  );

  const weightageDrawer = {
    id: "weightageDrawer",
    content: (
      <PlatformWeightageDrawer
        weightageValues={weightageValues}
        setWeightageValues={setWeightageValues}
        vertical={vertical}
      />
    ),
    trigger: {
      iconName: "Weightage" as IconProps.Name,
      iconSvg: <img src="/svg/weight.svg" alt="Weight" />,
    },
    ariaLabels: {
      drawerName: "My Drawer",
      closeButton: "Close",
      triggerButton: "Open",
      resizeHandle: "Resize",
    },
    resizable: true,
    defaultSize: 290,
  };

  return (
    <Layout
      title={title}
      breadcrumbs={breadcrumbs}
      navItems={navItems as NavItemsWithId}
      content={
        <ContentLayout
          header={
            <>
              <Header
                description="Global Ranking is done on a weekly basis and is based on various metrics like user activity, searches, and votes."
                variant="h2"
              >
                {title}
              </Header>
              <CustomFlashBar />
            </>
          }
        >
          <RawData
            weightageValues={weightageValues}
            setWeightageValues={setWeightageValues}
          />
        </ContentLayout>
      }
      contentType="table"
      drawers={[weightageDrawer]}
    />
  );
}

export default GRRawDataIndexPage;
