import { TrendsContent, TrendsFooter, TrendsHeader } from "../component/Trends";

const title = "Wikipedia Games Trends";

export const wikiGames = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2 },
  data: {
    title: title,
    description: "Daily Wikipedia Article views for games",
    header: () => <TrendsHeader title={title} />,
    content: () => <TrendsContent vertical="gaming" platform="wiki" sort="daily_rank" />,
    footer: () => <TrendsFooter linkHref="/platforms/wikipedia?tab=trends&category=gaming" />,
  },
};