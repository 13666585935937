import {
  Button,
  Container,
  Header,
  SpaceBetween,
  TextFilter,
} from "@cloudscape-design/components";
import React from "react";
import { AdvancedFilters } from "../../../../../components/filters/AdvancedFilters";
import { FiltersDropdown } from "../../../../../components/filters/FiltersDropdown";
import { useSetGridAdvancedFilterTheme } from "../../../../../hooks/UseTheme/useSetGridAdvancedFilterTheme";
import CategorySelector from "./CategorySelector";
import DateRangeSelector from "./DateRangeSelector";

const HeaderContainer = ({
  filterText,
  setFilterText,
  handleFilterItemClick,
  handleClearFilter,
  selectedFilterName,
  filter,
  gridRef,
  getPreferencesAfterDelete,
  exportParams,
  getPreferencesAfterSaveOrUpdate,
  dateRange,
  setDateRange,
  selectedCategory,
  setSelectedCategory,
}) => {
  useSetGridAdvancedFilterTheme();

  return (
    <Container
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <TextFilter
                filteringText={filterText}
                filteringPlaceholder="Search title"
                filteringAriaLabel="Filter title"
                onChange={({ detail }) => setFilterText(detail.filteringText)}
              />
              <FiltersDropdown
                handleFilterItemClick={handleFilterItemClick}
                path="dashboards.globalRanking.globalScore.filters"
              />
              <AdvancedFilters
                handleClearFilter={handleClearFilter}
                selectedFilterName={selectedFilterName}
                filter={filter}
                getPreferencesAfterDelete={getPreferencesAfterDelete}
                getPreferencesAfterSaveOrUpdate={
                  getPreferencesAfterSaveOrUpdate
                }
              />
              <Button
                onClick={() =>
                  gridRef.current.api.exportDataAsExcel(exportParams)
                }
              >
                Export
              </Button>
            </SpaceBetween>
          }
        >
          <SpaceBetween direction="horizontal" size="xs">
            <DateRangeSelector
              defaults={dateRange}
              onChange={(e) => setDateRange(e)}
              isDateEnabled={(date) =>
                date >= new Date("2024-04-01") && date <= new Date()
              }
            />
            <CategorySelector
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
            />
          </SpaceBetween>
        </Header>
      }
    >
      <div className="pt-2" id="advancedFilterParent"></div>
    </Container>
  );
};

export default HeaderContainer;
