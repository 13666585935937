import { FormField, SpaceBetween } from "@cloudscape-design/components";

const OTTProvider = ({ itemData }) => {
    return (
        <FormField 
            label={<span className="dark:text-slate-200 text-black font-bold text-xs text-nowrap">OTT Providers</span>} 
            stretch 
        >
            <SpaceBetween direction="horizontal" size="xs">
                {itemData.data.providers.map((provider) => {
                    const formattedProvider = provider
                        .replace(/\s+/g, "")
                        .replace(/\+/g, "_");
                    return (
                        <div
                            title={provider}
                            key={provider}
                            style={{
                                transition: "transform 0.2s ease-out",
                            }}
                            onMouseOver={(e) =>
                                (e.currentTarget.style.transform = "scale(1.3)")
                            }
                            onMouseOut={(e) =>
                                (e.currentTarget.style.transform = "scale(1)")
                            }
                        >
                            <img
                                src={`https://images.searchpantheon.com/serviceProviderIcons/${formattedProvider}.webp`}
                                alt={`${provider} Icon`}
                                className="rounded-lg shadow-md"
                                style={{ width: "40px", height: "40px" }}
                            />
                        </div>
                    );
                })}
            </SpaceBetween>
        </FormField>
    );
}

export default OTTProvider;