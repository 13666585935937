import { Alert, Button, SpaceBetween } from "@cloudscape-design/components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import FormProvider from "../../../../components/forms/FormProvider";
import { MultiSelectBox } from "../../../../components/forms/MultiSelectBox";
import { TextField } from "../../../../components/forms/TextField";
import SnackbarContext from "../../../../hooks/notify/snack-bar/snackbar-context";
import {
  clearPermissionRouteEditFn,
  editPermissioinsRouteFn,
} from "../redux/actions/permissions-actions";
export default function EditPermissionsForm({
  defaults,
  setCancel,
  setCompleted,
  routesList,
  ...others
}) {
  const snackbarCtx = useContext(SnackbarContext);
  const editPermissionRoute = useSelector((state) => state.editPermissionRoute);
  const [submittedData, setSubmittedData] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const onSubmit = async (data) => {
    setSubmittedData(data);
    setShowConfirm(true);
  };
  const regex = /\w+[\:\.\_\-]+\w+/gm;

  useEffect(() => {
    if (
      !editPermissionRoute.loading &&
      editPermissionRoute.isCompleted &&
      !editPermissionRoute.isError
    ) {
      clearPermissionRouteEditFn();
      setCompleted();
      setCancel(true);
    }
  }, [editPermissionRoute]);

  const getAllApis = (items) => {
    return routesList.filter((d) => items.includes(d.routeId));
  };

  const createPermissionDefaultValue = {
    permission_name: "",
    api_route: [],
  };
  const createPermissionsRouteSchema = Yup.object().shape({
    permission_name: Yup.string()
      .matches(regex)
      .required("Permissions is required"),
    api_route: Yup.array().min(1, "Api route is required"),
  });

  const methods = useForm({
    defaultValues: createPermissionDefaultValue,
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: yupResolver(createPermissionsRouteSchema),
  });

  const {
    setValue,
    reset,
    setError,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  useEffect(() => {
    if (defaults) {
      setValue("permission_name", defaults.permission_name);
      setValue(
        "api_route",
        getAllApis(defaults.api_route).map((d) => ({
          value: d.routeId,
          label: d.routeKey,
        })),
      );
    }
  }, [defaults]);

  return showConfirm ? (
    <div>
      <div className="py-5 w-full text-center">
        <h1 className="font-bold">Confirmation</h1>
        <p>
          You are about to create new permissions for API access in the website.
          These permissions will determine the level of access that each user
          will have to the platforms API’s. Are you sure you want to proceed?
          Please double-check the permissions before confirming.
        </p>
      </div>

      {!editPermissionRoute.isLoading &&
        editPermissionRoute.isCompleted &&
        !editPermissionRoute.isError && (
          <Alert type="success" dismissible={true}>
            {editPermissionRoute.message}
          </Alert>
        )}
      <div className="rounded-md border border-gray-300">
        <div className=" dark:bg-white bg-legendBg rounded-t grid grid-cols-2">
          <div className="uppercase text-center py-1">Permissions</div>
          <div className="uppercase text-center py-1">API’s</div>
        </div>
        <div className="grid grid-cols-2 py-2">
          <div className=" text-center flex flex-row justify-center items-center">
            {submittedData.permission_name}
          </div>
          <div className="flex flex-wrap">
            {submittedData.api_route.map((val) => {
              return (
                <span
                  key={val.value}
                  className=" rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 m-1"
                >
                  {val.label}
                </span>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-end items-center pt-3">
        <SpaceBetween direction="horizontal" size="m">
          <span>
            <Button
              formAction="none"
              loading={isSubmitting}
              onClick={() => setShowConfirm(false)}
            >
              Cancel
            </Button>
          </span>
          <span>
            <Button
              variant="primary"
              loading={isSubmitting}
              formAction="none"
              onClick={() => {
                editPermissioinsRouteFn({ data: submittedData });
              }}
            >
              Submit
            </Button>
          </span>
        </SpaceBetween>
      </div>
    </div>
  ) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div className="py-5">
        <div className="grid grid-cols-1 gap-6">
          <span>
            <TextField
              name={"permission_name"}
              label="Permission Name"
              placeholder={"Permission Name"}
              type={"text"}
            />
          </span>
          <span>
            <MultiSelectBox
              name={"api_route"}
              label="Api Routes"
              options={routesList.map((d) => ({
                value: d.routeId,
                label: d.routeKey,
              }))}
            />
          </span>
        </div>
        <div className="flex flex-row justify-end items-center pt-3">
          <SpaceBetween direction="horizontal" size="m">
            <span>
              <Button
                formAction="none"
                loading={isSubmitting}
                onClick={() => setCancel(true)}
              >
                Cancel
              </Button>
            </span>
            <span>
              <Button
                variant="primary"
                loading={isSubmitting}
                formAction="submit"
              >
                Submit
              </Button>
            </span>
          </SpaceBetween>
        </div>
      </div>
    </FormProvider>
  );
}
