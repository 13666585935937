import axiosInstance from "../../../utils/axios";
import { Autosuggest } from "@cloudscape-design/components";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { rawNameToTitle } from "../utils/name-utils";

export const getSearch = async (domain, searchApiUrl, params) => {
  try {
    const response = await axiosInstance.request({
      method: "GET",
      url: searchApiUrl,
      params: {
        domain: domain,
        ...params,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const TopicSearchBar = ({ domain, searchApiUrl, topicPageUrl, namePageUrl, limit=10 }) => {

  const navigate = useNavigate();

  const [ searchValue, setSearchValue ] = useState("");
  const [ searchResponse, setSearchResponse ] = useState({
    data: null,
    loading: false,
    completed: false,
    error: null,
  });

  const getSearchResponse = (params) => {
    setSearchResponse({ ...searchResponse, loading: true, completed: false, error: null });
    getSearch(domain, searchApiUrl, params).then(response => {
      setSearchResponse({ data: response, loading: false, completed: true, error: null });
    }).catch(error => {
      setSearchResponse({ ...searchResponse, loading: false, error: error, completed: true });
    });
  };

  const getOptions = () => {
    const options = [];

    if (topicPageUrl) {
      options.push({ label: "Topics", options: searchResponse?.data?.topics?.map(item => (
        { 
          label: item.label, 
          value: item.id,
          href: `${topicPageUrl}/${item.id}`,
          iconName: "contact",
        }
      )) || [] });
    }

    if (namePageUrl) {
      options.push({ label: "Names", options: searchResponse?.data?.names?.map(item => (
        { 
          label: rawNameToTitle(item.name), 
          value: item.name,
          href: `${namePageUrl}/${item.name}`,
          iconName: "user-profile",
        }
      )) || [] });
    }

    return options;
  };

  return (
    <div className="w-96">
      <Autosuggest
        onChange={({ detail }) => {
          setSearchValue(detail.value);
        }}
        onLoadItems={({ detail }) => {
          if (detail.filteringText.length > 0) {
            getSearchResponse({ q: detail.filteringText, limit: limit });
          }
        }}
        options={getOptions()}
        onSelect={({ detail }) => {
          navigate(detail.selectedOption.href);
          setSearchValue("");
        }}
        filteringType="none"
        value={searchValue}
        placeholder={namePageUrl ? "Search topics and names" : "Search topics"}
        empty="No matches found"
        loadingText="Searching..."
        statusType={searchResponse.loading ? "loading" : searchResponse.error ? "error" : "finished"}
        hideEnteredTextOption
      />
    </div>
  );
};

export default TopicSearchBar;