import { Box, SpaceBetween } from "@cloudscape-design/components";
import React from "react";

export const EmptyGrid = () => {
  return (
    <div
      className={"ag-overlay-loading-center"}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      No matching data found
    </div>
  );
};
