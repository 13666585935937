import { Toggle } from "@cloudscape-design/components";
import { Mode } from "@cloudscape-design/global-styles";
import React, { useState } from "react";
import { FaMoon } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useThemeContext } from "../../../app/ThemeProvider";
import { usePostGenericPreference } from "../../../services/generic/hooks/usePostGenericPreference";
import { generateNestedObject } from "../../../utils/helpers";

const PREFERENCE_PATH = "theme";

export const ModeToggle = () => {
  const { setAppTheme } = useThemeContext();
  const globalPref = useSelector(
    (state: any) => state?.globalPersonalPref?.preferences,
  );
  const [checked, setChecked] = useState<boolean>(
    localStorage.getItem("theme") === Mode.Dark,
  );

  const { mutate: postGenericPreference } = usePostGenericPreference();

  const handleOnChange = ({ detail }) => {
    const theme = detail.checked ? Mode.Dark : Mode.Light;

    setChecked(detail.checked);
    setAppTheme(theme);
    localStorage.setItem("theme", theme);

    const newGlobalPreferences = generateNestedObject(
      { ...globalPref },
      PREFERENCE_PATH,
      theme,
    );

    postGenericPreference(newGlobalPreferences);
  };

  return (
    <div className="flex gap-2 items-center mx-5">
      <Toggle onChange={handleOnChange} checked={checked} />
      <FaMoon size={13} color="white" />
    </div>
  );
};
