import {
  BarChart,
  Button,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  ExpandableSection,
  Header,
  Icon,
  LineChart,
  SpaceBetween,
  Spinner,
  Table,
  Tabs,
} from "@cloudscape-design/components";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useAuthContext } from "../../../auth/useAuthContext";
import PantheonFlashbar from "../../../components/PantheonFlashbar";
import { navItems } from "../../../layouts/common/menu/side-menu";
import axiosInstance from "../../../utils/axios";
import { Layout } from "../Layout";

const GoogleRealtimeSearchReport = () => {
  const defaultResponseState = {
    data: null,
    loading: false,
    completed: false,
    error: null,
  };
  const regionOptions = [
    { value: "", label: "Worldwide" },
    { value: "US", label: "United States" },
  ];
  const defaultBreadcrumbs = [
    { text: "Tools" },
    { text: "Google Search Trends", href: "/tools/google-search-trends" },
  ];

  const [jobResponse, setJobResponse] = useState(defaultResponseState);
  const [newsArticlesResponse, setNewsArticlesResponse] =
    useState(defaultResponseState);

  const [breadcrumbs, setBreadcrumbs] = useState(defaultBreadcrumbs);
  const [activeTab, setActiveTab] = useState("timeseries");

  const [lineChartSeries, setLineChartSeries] = useState([]);
  const [barChartSeries, setBarChartSeries] = useState([]);
  const [highlightedSeries, setHighlightedSeries] = useState([]);
  const [visibleSeries, setVisibleSeries] = useState([]);
  const [yDomain, setYDomain] = useState([0, 100]);
  const [newsParameters, setNewsParameters] = useState({});

  const { user } = useAuthContext();
  const { id } = useParams();

  const flashbarRef = useRef(null);

  const handleChangeTab = (tabId) => {
    setActiveTab(tabId);
  };

  const loadJobData = (job_id) => {
    setJobResponse({
      ...jobResponse,
      loading: true,
      completed: false,
      error: null,
    });

    axiosInstance
      .request({
        method: "GET",
        url: "/googletrends/realtime/getjob",
        params: {
          id: job_id,
        },
      })
      .then((response) => {
        setJobResponse({
          ...jobResponse,
          data: response.data,
          loading: false,
          completed: true,
          error: null,
        });

        const responseData = { ...response.data };

        // Filter out data outside of the selected timeframe
        const timeframe = responseData.parameters.date.split(" ");
        responseData.data.forEach((item) => {
          item.data = Object.keys(item.data).reduce((a, b) => {
            const date = new Date(Date.parse(b));
            if (
              date >= new Date(Date.parse(timeframe[0])) &&
              date <= new Date(Date.parse(timeframe[1]))
            ) {
              return { ...a, [b]: item.data[b] };
            } else {
              return a;
            }
          }, {});
        });

        const colors = ["#4c8df6", "#e46962", "#f7ce52", "#1ea446", "#886cd5"];

        // Scale each timeseries by its ratio and normalize to 100
        const maxValue = Math.max(
          ...responseData.data.map(
            (item) => Math.max(...Object.values(item.data)) * item.ratio,
          ),
        );
        const newLineChartSeries = responseData.data
          .map((item) => {
            const avg =
              Object.values(item.data).reduce((a, b) => a + b, 0) /
              Object.values(item.data).length;
            return {
              title: `${item.parameters.name} (${item.parameters.type})`,
              type: "line",
              data: Object.keys(item.data).map((k) => ({
                x: new Date(Date.parse(k)),
                y: ((item.data[k] * item.ratio) / maxValue) * 100,
              })),
              avg: ((avg * item.ratio) / maxValue) * 100,
              termName: item.parameters.name,
              termTerm: item.parameters.keyword,
              termType: item.parameters.type,
              valueFormatter: (e) => `${Math.round(e * 100) / 100}`,
            };
          })
          .sort((a, b) => b.avg - a.avg)
          .map((x, i) => ({
            ...x,
            color: i < colors.length ? colors[i] : null,
          }));

        const newBarChartSeries = newLineChartSeries
          .map((item) => {
            return {
              title: item.title,
              type: "bar",
              data: [{ x: "Average", y: Math.round(item.avg * 100) / 100 }],
            };
          })
          .sort((a, b) => b.data[0].y - a.data[0].y)
          .map((x, i) => ({
            ...x,
            color: i < colors.length ? colors[i] : null,
          }));

        setLineChartSeries(newLineChartSeries);
        setVisibleSeries(newLineChartSeries);
        setBarChartSeries(newBarChartSeries);
        setBreadcrumbs([
          ...defaultBreadcrumbs,
          {
            text: responseData.name,
            href: `/tools/google-search-trends/searches/${responseData.id}`,
          },
        ]);
      })
      .catch((error) => {
        setJobResponse({
          ...jobResponse,
          loading: false,
          completed: true,
          error: error,
        });
      });
  };

  const getNewsArticles = ({
    title,
    clickDate,
    q,
    kgmid,
    startDate,
    endDate,
    sortType = "relevance",
    start = 0,
  }) => {
    let params = { title, clickDate };
    if (q && q.length > 0) params.q = q;
    if (kgmid && kgmid.length > 0) params.kgmid = kgmid;
    if (startDate) params.start_date = startDate;
    if (endDate) params.end_date = endDate;
    if (sortType) params.sort_type = sortType;
    if (start) params.start = start;

    setNewsParameters(params);
    setNewsArticlesResponse({
      ...newsArticlesResponse,
      loading: true,
      completed: false,
      error: null,
    });

    axiosInstance
      .request({
        method: "GET",
        url: "/googlenews/search",
        params: params,
      })
      .then((response) => {
        setNewsArticlesResponse({
          ...newsArticlesResponse,
          data: response.data,
          loading: false,
          completed: true,
          error: null,
        });
      })
      .catch((error) => {
        console.log(error);
        setNewsArticlesResponse({
          ...newsArticlesResponse,
          loading: false,
          completed: true,
          error: error,
        });
      });
  };

  const getReport = () => {
    flashbarRef?.current?.setFlashbarMessage(
      "loading",
      <div>Generating report.</div>,
      null,
    );

    axiosInstance
      .request({
        method: "GET",
        url: "/googletrends/realtime/getreport",
        params: {
          id: jobResponse.data.id,
        },
        responseType: "blob",
      })
      .then((response) => {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", `${jobResponse.data.name}.zip`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);

        flashbarRef?.current?.setFlashbarMessage(
          "success",
          <div>Successfully generated report.</div>,
          null,
          5000,
        );
      })
      .catch((error) => {
        console.log(error);
        flashbarRef?.current?.setFlashbarMessage(
          "error",
          error.response?.data?.message || "Failed to generate report.",
          "Failed to generate report",
        );
      });
  };

  useEffect(() => {
    loadJobData(id);
  }, [id]);

  const title =
    !jobResponse?.loading && jobResponse?.data
      ? `${jobResponse.data.name} - Google Realtime Search`
      : "Google Realtime Search";

  return (
    <Layout
      title={title}
      content={
        <>
          <ContentLayout header={<PantheonFlashbar ref={flashbarRef} />}>
            {!jobResponse?.loading && jobResponse?.data ? (
              <SpaceBetween direction="vertical" size="l">
                <Container
                  disableContentPaddings
                  disableHeaderPaddings
                  variant="borderless"
                  header={
                    <Header
                      variant="h2"
                      description={`${new Date(
                        Date.parse(
                          jobResponse.data.parameters.date.split(" ")[0],
                        ),
                      ).toLocaleString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                        timeZone: "UTC",
                      })} to ${new Date(
                        Date.parse(
                          jobResponse.data.parameters.date.split(" ")[1],
                        ),
                      ).toLocaleString("en-US", {
                        month: "short",
                        day: "numeric",
                        year: "numeric",
                        timeZone: "UTC",
                      })} \u2022 ${
                        regionOptions.find(
                          (x) => x.value === jobResponse.data.parameters.geo,
                        ).label
                      }`}
                      actions={
                        <ButtonDropdown
                          items={[
                            {
                              id: "download",
                              text: "Download report",
                            },
                            {
                              id: "view_external",
                              text: "View in Google Trends",
                              href: `https://trends.google.com/trends/explore?q=${visibleSeries
                                .slice(0, 5)
                                .map((x) => x.termTerm)
                                .join(",")}&date=${
                                jobResponse.data.parameters.date
                              }&geo=${jobResponse.data.parameters.geo}`,
                              external: true,
                            },
                          ]}
                          onItemClick={({ detail }) => {
                            switch (detail.id) {
                              case "download":
                                getReport();
                                break;
                            }
                          }}
                        >
                          Actions
                        </ButtonDropdown>
                      }
                    >
                      {jobResponse.data.name}
                    </Header>
                  }
                >
                  {jobResponse.data.status === "FINISHED" ? (
                    <Tabs
                      onChange={({ detail }) =>
                        handleChangeTab(detail.activeTabId)
                      }
                      activeTabId={activeTab}
                      tabs={[
                        {
                          id: "timeseries",
                          label: "Time series",
                          content: (
                            <div className="h-fit">
                              <div className="flex space-x-4">
                                <div className="grow">
                                  <LineChart
                                    series={lineChartSeries}
                                    xDomain={[
                                      new Date(
                                        Date.parse(
                                          jobResponse.data.parameters.date.split(
                                            " ",
                                          )[0],
                                        ),
                                      ),
                                      new Date(
                                        Date.parse(
                                          jobResponse.data.parameters.date.split(
                                            " ",
                                          )[1],
                                        ),
                                      ),
                                    ]}
                                    yDomain={yDomain}
                                    xScaleType="time"
                                    yScaleType="linear"
                                    height={200}
                                    detailPopoverSize="large"
                                    visibleSeries={visibleSeries}
                                    onFilterChange={({ detail }) => {
                                      setVisibleSeries(detail.visibleSeries);
                                      if (
                                        detail.visibleSeries.length ==
                                        lineChartSeries.length
                                      ) {
                                        setYDomain([0, 100]);
                                      } else {
                                        const maxValue = Math.max(
                                          ...detail.visibleSeries.map(
                                            (series) =>
                                              Math.max(
                                                ...series.data.map(
                                                  (item) => item.y,
                                                ),
                                              ),
                                          ),
                                        );
                                        setYDomain([0, maxValue]);
                                      }
                                    }}
                                    onHighlightChange={({ detail }) =>
                                      setHighlightedSeries(
                                        detail.highlightedSeries,
                                      )
                                    }
                                    i18nStrings={{
                                      xTickFormatter: (value) => {
                                        if (jobResponse.data.resolution > 7) {
                                          return `${value.toLocaleDateString(
                                            "en-US",
                                            {
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                              timeZone: "UTC",
                                            },
                                          )}`;
                                        } else if (
                                          jobResponse.data.resolution == 7
                                        ) {
                                          return `${value.toLocaleDateString(
                                            "en-US",
                                            {
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                              timeZone: "UTC",
                                            },
                                          )} - ${new Date(
                                            value.getTime() +
                                              6 * 60 * 60 * 24 * 1000,
                                          ).toLocaleDateString("en-US", {
                                            year: "numeric",
                                            month: "short",
                                            day: "numeric",
                                            timeZone: "UTC",
                                          })}`;
                                        } else if (
                                          jobResponse.data.resolution == 1
                                        ) {
                                          return `${value.toLocaleDateString(
                                            "en-US",
                                            {
                                              year: "numeric",
                                              month: "short",
                                              day: "numeric",
                                              timeZone: "UTC",
                                            },
                                          )}`;
                                        }
                                      },
                                      yTickFormatter: (value) => {
                                        return `${
                                          Math.abs(value) >= 1e9
                                            ? (value / 1e9)
                                                .toFixed(1)
                                                .replace(/\.0$/, "") + "G"
                                            : Math.abs(value) >= 1e6
                                            ? (value / 1e6)
                                                .toFixed(1)
                                                .replace(/\.0$/, "") + "M"
                                            : Math.abs(value) >= 1e3
                                            ? (value / 1e3)
                                                .toFixed(1)
                                                .replace(/\.0$/, "") + "K"
                                            : value.toFixed(0)
                                        }`;
                                      },
                                      filterPlaceholder: "Filter data",
                                    }}
                                    detailPopoverFooter={(xValue) => {
                                      const dayBefore = new Date(
                                        xValue.getTime(),
                                      );
                                      dayBefore.setDate(dayBefore.getDate());
                                      const dayAfter = new Date(
                                        xValue.getTime(),
                                      );
                                      dayAfter.setDate(
                                        dayAfter.getDate() +
                                          jobResponse.data.resolution,
                                      );
                                      const startDate = dayBefore
                                        .toISOString()
                                        .split("T")[0];
                                      const endDate = dayAfter
                                        .toISOString()
                                        .split("T")[0];
                                      return (
                                        <Button
                                          variant="inline-link"
                                          onClick={() => {
                                            // Get the top three terms on this date
                                            const termsToFetch = visibleSeries
                                              .map((x) => ({
                                                term: x.termTerm,
                                                value: x.data.find(
                                                  (y) =>
                                                    y.x.getTime() ===
                                                    xValue.getTime(),
                                                ),
                                              }))
                                              .sort(
                                                (a, b) => b.value.y - a.value.y,
                                              )
                                              .slice(0, 3)
                                              .map((x) => x.term);

                                            getNewsArticles({
                                              title:
                                                lineChartSeries[0].termName,
                                              clickDate: xValue,
                                              q: termsToFetch
                                                .filter(
                                                  (x) =>
                                                    !(
                                                      x.includes("/m/") ||
                                                      x.includes("/g/")
                                                    ),
                                                )
                                                .join(","),
                                              kgmid: termsToFetch
                                                .filter(
                                                  (x) =>
                                                    x.includes("/m/") ||
                                                    x.includes("/g/"),
                                                )
                                                .join(","),
                                              startDate: startDate,
                                              endDate: endDate,
                                            });
                                          }}
                                        >
                                          What happened here?
                                        </Button>
                                      );
                                    }}
                                  />
                                </div>
                                <div className="mt-14">
                                  <BarChart
                                    series={barChartSeries}
                                    yDomain={yDomain}
                                    height={200}
                                    detailPopoverSize="large"
                                    xScaleType="categorical"
                                    hideLegend={true}
                                    hideFilter={true}
                                    highlightedSeries={
                                      highlightedSeries?.title
                                        ? barChartSeries.find(
                                            (x) =>
                                              x.title ===
                                              highlightedSeries.title,
                                          )
                                        : null
                                    }
                                    visibleSeries={barChartSeries.filter((x) =>
                                      visibleSeries
                                        .map((y) => y.title)
                                        .includes(x.title),
                                    )}
                                    i18nStrings={{
                                      yTickFormatter: () => "",
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ),
                        },
                        {
                          id: "raw",
                          label: "Raw data",
                          content: activeTab === "raw" && (
                            <Table
                              columnDefinitions={[
                                {
                                  id: "term",
                                  header: "Search term",
                                  cell: (item) => (
                                    <b title={item.term}>{item.term}</b>
                                  ),
                                  maxWidth: 150,
                                },
                                {
                                  id: "type",
                                  header: "Type",
                                  cell: (item) => <b>{item.type}</b>,
                                  maxWidth: 150,
                                },
                                {
                                  id: "keyword",
                                  header: "Keyword",
                                  cell: (item) => <b>{item.keyword}</b>,
                                  maxWidth: 150,
                                },
                                ...lineChartSeries[0].data.map((point) => {
                                  const dateString = point.x.toLocaleString(
                                    "en-US",
                                    {
                                      month: "short",
                                      day: "numeric",
                                      year: "numeric",
                                      timeZone: "UTC",
                                    },
                                  );
                                  return {
                                    id: dateString,
                                    header: dateString,
                                    cell: (item) =>
                                      Math.round(item[dateString] * 100) / 100,
                                  };
                                }),
                              ]}
                              items={lineChartSeries.map((series) => {
                                return {
                                  term: series.termName,
                                  type: series.termType,
                                  keyword: series.termTerm,
                                  ...series.data
                                    .map((item) => ({
                                      [item.x.toLocaleString("en-US", {
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                        timeZone: "UTC",
                                      })]: item.y,
                                    }))
                                    .flat()
                                    .reduce((a, b) => ({ ...a, ...b }), {}),
                                };
                              })}
                              stickyColumns={{ first: 3, last: 0 }}
                              variant="embedded"
                              sortingDisabled
                            />
                          ),
                        },
                      ]}
                    />
                  ) : null}
                </Container>

                {(newsArticlesResponse.loading ||
                  newsArticlesResponse.data) && (
                  <Container
                    header={
                      <Header description="News articles about the top three search terms">
                        {jobResponse.data.resolution == 1 ? (
                          <>
                            What happened on{" "}
                            {newsParameters.clickDate?.toLocaleString("en-US", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              timeZone: "UTC",
                            })}
                          </>
                        ) : jobResponse.data.resolution == 7 ? (
                          <>
                            What happened from{" "}
                            {newsParameters.clickDate?.toLocaleString("en-US", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              timeZone: "UTC",
                            })}{" "}
                            to{" "}
                            {new Date(
                              newsParameters.clickDate?.getTime() +
                                6 * 60 * 60 * 24 * 1000,
                            ).toLocaleString("en-US", {
                              month: "long",
                              day: "numeric",
                              year: "numeric",
                              timeZone: "UTC",
                            })}
                          </>
                        ) : null}
                      </Header>
                    }
                  >
                    {newsArticlesResponse.loading ? (
                      <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                        <Spinner size="large" />
                      </div>
                    ) : newsArticlesResponse.data ? (
                      newsArticlesResponse.data.map((news) => (
                        <ExpandableSection
                          headerText={
                            lineChartSeries.find(
                              (x) => x.termTerm === news.keyword,
                            ).termName
                          }
                          defaultExpanded
                        >
                          <ColumnLayout columns={2}>
                            {news.results.map((article) => (
                              <Container variant="borderless" disableContentPaddings >
                                <div className="flex space-x-4 items-center">
                                  <img
                                    src={article.thumbnail}
                                    alt={article.title}
                                    className="w-28 h-28 rounded-md shadow-md"
                                    loading="lazy"
                                  />
                                  <div>
                                    <Header
                                      variant="h4"
                                      description={article.snippet}
                                    >
                                      <p>
                                        <small>
                                          {article.source} &bull; {article.date}
                                        </small>
                                      </p>
                                      <Button
                                        variant="inline-link"
                                        target="_blank"
                                        href={article.article_link}
                                        alt={article.title}
                                      >
                                        {article.title.length > 50
                                          ? article.title.slice(0, 50) + " ..."
                                          : article.title}{" "}
                                        <Icon name="external" />
                                      </Button>
                                    </Header>
                                  </div>
                                </div>
                              </Container>
                            ))}
                          </ColumnLayout>
                        </ExpandableSection>
                      ))
                    ) : null}
                  </Container>
                )}
              </SpaceBetween>
            ) : (
              <div className=" py-10 flex flex-1 flex-row justify-center items-center">
                <Spinner size="large" />
              </div>
            )}
          </ContentLayout>
        </>
      }
      navItems={navItems}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default GoogleRealtimeSearchReport;
