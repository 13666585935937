import { ExpandableSection, Grid } from "@cloudscape-design/components";
import { Mode } from "@cloudscape-design/global-styles";
import React, { FC, useMemo } from "react";
import { useThemeContext } from "../../../../../app/ThemeProvider";
import { isDataAvailable } from "../../../utils";
import { DateRange } from "../DateRange";
import { ImdbTrends } from "./ImdbTrends/ImdbTrends";
import { SteamTrends } from "./SteamTrends/SteamTrends";
import { TwitchTrends } from "./TwitchTrends/TwitchTrends";
import { WikipediaTrends } from "./WikipediaTrends/WikipediaTrends";
import { YoutubeTrends } from "./YoutubeTrends/YoutubeTrends";

interface RankTrendsProps {
  dateRange: any;
  setDateRange: (range: [Date, Date]) => void;
  wikipediaTrendsData: any;
  youtubeTrendsData: any;
  imdbTrendsData: any;
  twitchTrendsData: any;
  steamTrendsData: any;
  itemData: any;
}

export const RankTrends: FC<RankTrendsProps> = ({
  dateRange,
  setDateRange,
  itemData,
  wikipediaTrendsData,
  youtubeTrendsData,
  imdbTrendsData,
  twitchTrendsData,
  steamTrendsData,
}) => {
  const { mode } = useThemeContext();

  const hasTwitchData =
    twitchTrendsData !== undefined &&
    isDataAvailable(twitchTrendsData) &&
    (Array.isArray(twitchTrendsData)
      ? twitchTrendsData.length > 0
      : Object.keys(twitchTrendsData).length > 0);

  const hasSteamData =
    steamTrendsData !== undefined &&
    isDataAvailable(steamTrendsData) &&
    (Array.isArray(steamTrendsData)
      ? steamTrendsData.length > 0
      : Object.keys(steamTrendsData).length > 0);

  const crosslines = useMemo(
    () =>
      itemData?.data?.timeline_events?.map((event) => ({
        type: "line",
        enabled: true,
        value: event.timestamp * 1000,
        trueValue: event.timestamp,
        lineDash: [5, 5],
        strokeOpacity: 0.7,
        stroke: mode === Mode.Dark ? "lightgray" : "gray",
        text: event.details ?? "",
        event_type: event.eventType ?? "",
        label: {
          enabled: false,
          text: event.details,
        },
        created_by: event.createdBy ?? "",
      })) ?? [],
    [itemData, mode],
  );

  return (
    <ExpandableSection
      defaultExpanded={true}
      headerText="Ranking Trends"
      headerActions={
        <DateRange dateRange={dateRange} setDateRange={setDateRange} />
      }
    >
      <Grid
        gridDefinition={[
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
          { colspan: { default: 12, m: 6 } },
        ]}
      >
        {isDataAvailable(wikipediaTrendsData) && (
          <WikipediaTrends
            itemName={itemData?.data?.name}
            crosslines={crosslines}
            data={wikipediaTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {isDataAvailable(youtubeTrendsData) && (
          <YoutubeTrends
            itemName={itemData?.data?.name}
            crosslines={crosslines}
            data={youtubeTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {isDataAvailable(imdbTrendsData) && (
          <ImdbTrends
            itemName={itemData?.data?.name}
            crosslines={crosslines}
            data={imdbTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {hasTwitchData && (
          <TwitchTrends
            itemName={itemData?.data?.name}
            crosslines={crosslines}
            data={twitchTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
        {hasSteamData && (
          <SteamTrends
            itemName={itemData?.data?.name}
            crosslines={crosslines}
            data={steamTrendsData}
            dateRange={dateRange}
            configType="basic"
          />
        )}
      </Grid>
    </ExpandableSection>
  );
};
