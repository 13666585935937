import { useMutation } from "react-query";
import { useAuthContext } from "../../../auth/useAuthContext";
import { ApiURLParams, postGenericPreference } from "../requests";

type UsePostGenericPreferenceProps = {
  apiParams: ApiURLParams;
  onSuccess?: (data: any) => void;
  onError?: (e: any) => void;
  onSettled?: () => void;
};

export const usePostGenericPreference_V2 = ({
  apiParams,
  onSuccess,
  onError,
  onSettled,
}: UsePostGenericPreferenceProps) => {
  const { user } = useAuthContext();
  const { mutate, isLoading, error } = useMutation(
    (payload: any) =>
      postGenericPreference({ ...apiParams, user: user.username }, payload),
    {
      onSuccess,
      onError,
      onSettled,
    },
  );

  return {
    mutate,
    isLoading,
    error,
  };
};
