import React from "react";

export const ErrorLayout = () => {
  return (
    <div className="text-gray-300 text-center">
      <h1>Oops! Something went wrong.</h1>
      <p className="mb-5">
        We're working on fixing this. Please try again later.
      </p>
      <div className="flex justify-center gap-5">
        <button onClick={() => {}}>Retry</button>
        <a href="/contact-support">Contact Support</a>
      </div>
      {/* Show/Hide Details button to toggle error details */}
    </div>
  );
};
