export const lineTheme = {
    baseTheme: "ag-default-dark",
    overrides: {
        line: {
            series: {
                highlightStyle: {
                    series: {
                        dimOpacity: 0.2,
                        strokeWidth: 2,
                    },
                },
            },
        },
        bar: {
            series: {
                highlightStyle: {
                    series: {
                        dimOpacity: 0.2,
                        strokeWidth: 2,
                    },
                },
            },
        },
        common: {
            background: { fill: "#161D26" },
        },
    },
};