import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";
import _ from "lodash";

const initialState = {
  data: null,
  loading: false,
  message: "",
  completed: false,
  hasError: false,
};

// Create an asynchronous thunk for fetching YouTube trends data
export const fetchTrackedYouTubeTrendsDataApi = createAsyncThunk(
  "trackedYouTubeTrends/fetchTrackedYouTubeTrendsDataApi",
  async ({ ip_id }) => {
    try {
      const response = await axiosInstance.get(
        `youtubetrends/${ip_id}?gte=now-6M&lte=now&fetch=ByID`
      );
      return response.data; // Return the data from the response
    } catch (error) {
      throw error; // Propagate the error to be handled in the rejected case
    }
  }
);

const trackedYouTubeTrendsSlice = createSlice({
  name: "trackedYouTubeTrends",
  initialState,
  reducers: {
    clearData: (state) => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTrackedYouTubeTrendsDataApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchTrackedYouTubeTrendsDataApi.fulfilled, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.data = action.payload; // Update state with the fetched data
      })
      .addCase(fetchTrackedYouTubeTrendsDataApi.rejected, (state, action) => {
        state.loading = false;
        state.completed = true;
        state.hasError = true;
        state.message = action.error.message;
      });
  },
});

const trackedYouTubeTrendsReducer = trackedYouTubeTrendsSlice.reducer;

export default trackedYouTubeTrendsReducer;
