import React from "react";
import {
  Grid,
  Container,
  Box,
  SpaceBetween,
} from "@cloudscape-design/components";
import { Link } from "react-router-dom";

function IndexPageGrid({ items, gridDefinition }) {
  return (
    <div className="pt-10">
      <Grid gridDefinition={gridDefinition}>
        {items.map((item) => (
          <Container
            variant="stacked"
            fitHeight
            key={item.link}
            media={{
              content: (
                <Link to={item.link}>
                  <img src={item.image} alt={item.name} />
                </Link>
              ),
              height: 200,
              position: "top",
            }}
          >
            <SpaceBetween direction="vertical" size="s">
              <div key={item.path}>
                <Box color="text-status-inactive" fontSize="body-s">
                  {item.path}
                </Box>
                <Box variant="h2">
                  <Link to={item.link}>{item.name}</Link>
                </Box>
              </div>
              <p key={item.description}>{item.description}</p>
            </SpaceBetween>
          </Container>
        ))}
      </Grid>
    </div>
  );
}

export default IndexPageGrid;
