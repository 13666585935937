import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getChannelTrends } from "../request";
import { mapToUpdateTrends } from "../mappers/mapToUpdatedTrends";
import { isSomeOrElse } from "../../../../../utils/sugarUtils";

export const useGetChannelTrends = (channelId: string) => {
  const { isLoading, data, error } = useQuery(
    QUERY_KEYS.GET_CHANNEL_TRENDS,
    () => getChannelTrends(channelId),
  );

  return {
    data: mapToUpdateTrends(isSomeOrElse(data?.data, [])),
    isLoading,
    error,
  };
};
