import React, { useMemo } from "react";

export const useIMDbRankingTableConfig = ({
  
}) => {
  const colDefs = useMemo(() => {
    return [
      {
        headerName: "Today's Votes (IMDb)",
        field: "daily_votes",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      {
        headerName: "Total Votes (IMDb)",
        field: "votes",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      {
        headerName: "Rating (IMDb)",
        field: "rating",
        cellRenderer: (params) => params.value.toLocaleString(),
        cellDataType: "number",
        wrapHeaderText: true,
        autoHeaderHeight: true,
      },
      {
        headerName: "Vote Trend (IMDb)",
        field: "monthly_votes",
        cellRenderer: "agSparklineCellRenderer",
        wrapHeaderText: true,
        autoHeaderHeight: true,
        flex: 1,
      },
    ];
  }, []);

  return {
    columnDefs: colDefs,
  };
};