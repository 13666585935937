import { createSlice } from "@reduxjs/toolkit";

const editUserInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const editUserSlice = createSlice({
    name:"editUser",
    initialState:editUserInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        editUserComepleted: (state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = false;
        },
        clearUserEditReducer:() => {
            return editUserInitialState;
        },
        editUserFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const editUserAction = editUserSlice.actions;
const editUserReducer = editUserSlice.reducer;
export default editUserReducer;


