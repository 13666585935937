import { useMutation } from "react-query";
import { createMedia } from "../request";
import { mapCreateMediaResponse } from "../mappers/mapCreateMediaResponse";

interface UseCreateMediaProps {
  vertical: string;
  onSuccess: () => void;
  onError: (message: string) => void;
}

export const useCreateMedia = ({
  onSuccess,
  onError,
  vertical,
}: UseCreateMediaProps) => {
  // Adjust the mutate function to handle id type based on vertical
  const { isLoading, mutate, error, data } = useMutation(
    (id: string | number | number[]) => {
      if (vertical === "games") {
        return createMedia(id as number | number[], vertical);
      }
      return createMedia(id as string, vertical);
    },
    {
      onSuccess,
      onError: ({ message }) => onError(message),
    },
  );

  return {
    data: data?.data ? mapCreateMediaResponse(data?.data) : {},
    mutate,
    isLoading,
    error,
  };
};
