import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getGoogleRankingData } from "../request";

export const useGetGoogleRankingData = ({
  enabled=false,
  geoType="country",
  geoName="us",
  startDate,
  endDate,
  totalsOnly=false,
  category=null,
  categories=[],
}) => {

  const params = {
    geoType,
    geoName,
    startDate,
    endDate,
    totalsOnly,
  };

  if (category) {
    params.category = category;
  }
  if (categories.length > 0) {
    params.categories = categories.join(",");
  }

  const { isLoading, data, error } = useQuery(
    [QUERY_KEYS.GET_GOOGLE_RANKING_DATA, { ...params }],
    () => getGoogleRankingData(params),
    { enabled },
  );

  return {
    data: data?.data,
    isLoading,
    ...error,
  };
};
