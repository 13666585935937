import { useCallback, useEffect, useState } from "react";
import axiosInstance from "../../../../../../utils/axios";
import {
  Box,
  Button,
  Modal,
  Multiselect,
  SpaceBetween,
} from "@cloudscape-design/components";

const getImageUrl = (vertical, ip_id, image_url) => {
  switch (vertical) {
    case "Movies":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Television":
      return `https://image.tmdb.org/t/p/w92/${image_url}`;
    case "Gaming":
      return `https://images.igdb.com/igdb/image/upload/t_cover_big/${image_url}.png`;
    case "Franchise":
      return `https://images.searchpantheon.com/collections/${ip_id}_342.jpg`;
    default:
      return "";
  }
};

const convertData = (data) => {
  // Separate the Franchise data from the rest
  const franchiseData = data.filter((item) => item.vertical === "Franchise");
  const otherData = data.filter((item) => item.vertical !== "Franchise");

  // Sort the non-Franchise data by release year
  otherData.sort((a, b) => {
    const yearA = a.release_date
      ? new Date(a.release_date).getFullYear()
      : Infinity;
    const yearB = b.release_date
      ? new Date(b.release_date).getFullYear()
      : Infinity;
    return yearB - yearA;
  });

  // Convert the Franchise data to the desired format for Autosuggest
  const convertedFranchiseData = franchiseData.map((item) => {
    const { vertical, ip_id, ip, image_url } = item;
    const imageUrl = getImageUrl(vertical, ip_id, image_url);
    return {
      value: ip_id,
      label: ip,
      iconUrl: imageUrl,
      iconAlt: `${ip} cover`,
      tags: [vertical],
    };
  });

  // Convert the other sorted data to the desired format for Autosuggest
  const convertedOtherData = otherData.map((item) => {
    const { vertical, ip_id, ip, image_url, release_date } = item;
    const imageUrl = getImageUrl(vertical, ip_id, image_url);
    const labelTag = release_date
      ? `${new Date(release_date).getFullYear()}`
      : "";
    return {
      value: ip_id,
      label: ip,
      iconUrl: imageUrl,
      iconAlt: `${ip} cover`,
      tags: [vertical, labelTag],
    };
  });

  const result = [...convertedFranchiseData, ...convertedOtherData];

  return result;
};

export function MergeFranchisesModal({
  visible,
  onDiscard,
  onSubmit,
  ip,
  ip_id,
}) {
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchItems, setSearchItems] = useState([]);
  const [query, setQuery] = useState(null);
  const [loading, setLoading] = useState(false);
  const searchItem = useCallback(
    (query) => {
      setLoading(true);
      axiosInstance
        .get(
          `topsearch?keyword=${query}&fields=ip,ip_id,vertical,image_url,release_date&vertical=Franchise`,
        )
        .then((data) => data.data)
        // .then((data) => setSearchItems(data))
        .then((data) => {
          setSearchItems(data.filter((item) => item.ip_id !== ip_id));
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    },
    [setSearchItems, setLoading],
  );

  useEffect(() => {
    if (query) {
      searchItem(query);
    }
  }, [query]);

  return (
    <Modal
      size="large"
      visible={visible}
      onDismiss={onDiscard}
      header={"Merge franchise"}
      closeAriaLabel="Close dialog"
      footer={
        <>
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => {
                  setSelectedItems([]);
                  setQuery("");
                  onDiscard();
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                data-testid="submit"
                onClick={() => {
                  if (selectedItems.length !== -1) {
                    onSubmit({
                      id: selectedItems[0]?.label,
                      name: selectedItems[0].value,
                    });
                  }
                }}
              >
                Submit
              </Button>
            </SpaceBetween>
          </Box>
          <div className="text-red-500">
            The selected franchise will be merged into the franchise:
            <span className="inline-flex ml-2 mt-2 items-center rounded-md bg-red-400/10 px-2 py-1 text-md font-bold text-red-500 ring-1 ring-inset ring-red-400/20">
              {ip}
            </span>
          </div>
        </>
      }
    >
      <Multiselect
        onChange={({ detail }) => {
          setSelectedItems(detail.selectedOptions);
        }}
        selectedOptions={selectedItems}
        options={
          searchItems && searchItems?.length > 0 ? convertData(searchItems) : []
        }
        placeholder="Search for franchises"
        filteringType="manual"
        statusType={loading ? "loading" : "finished"}
        onLoadItems={({ detail }) => setQuery(detail?.filteringText)}
      />
    </Modal>
  );
}
