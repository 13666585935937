import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getComparisonData } from "../request";

export const useGetComparisonData = ({
  ipIds=[],
  datapoints=[],
  startTs,
  endTs,
}) => {
  const params = {
    ipIds,
    datapoints,
    startTs,
    endTs,
  };

  const { isLoading, isFetching, data, error } = useQuery(
    [QUERY_KEYS.GET_COMPARISON_DATA, { ...params }],
    () => getComparisonData(params),
  );

  return {
    data: data?.data,
    isLoading: isLoading || isFetching,
    ...error,
  };
};
