import React from "react";
import { GRPantheonRankingIndex } from "../../GRPantheonRankingIndex";
import { FilterDrawer } from "./FilterDrawer";
import { VERTICALS } from "../../../../../../config-global";

export const GRMovies = () => {
  return (
    <GRPantheonRankingIndex
      vertical={VERTICALS.MOVIES}
      DrawerComponent={FilterDrawer}
      title="Movies"
    />
  );
};
