import React from "react";
import { ExpandableSection, SpaceBetween, Grid, Container, Box } from "@cloudscape-design/components";

const GenericExpandableSection = ({ title, items }) => {
    if (!items || items.length === 0) {
        return null;
    }
    const gridDefinitions = items.map(() => ({
        colspan: { xxs: 12, s: 6, l: 4, xl: 3 }
    }));

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString("en-US");
    };

    return (
        <ExpandableSection
            defaultExpanded
            headingTagOverride="h2"
            variant="container"
            headerText={title}
        >
            <Grid gridDefinition={gridDefinitions}>
                {items.map((item, index) => (
                    <Container
                        fitHeight
                        key={item.id || index} 
                        media={{
                            content: item.cover ? (
                                <img src={`https://images.igdb.com/igdb/image/upload/t_cover_big/${item.cover}.webp`} alt={item.name || "cover"} />
                            ) : (
                                <div style={{ width: '154px', height: '238px', backgroundColor: 'gray' }}></div>
                            ),
                            width: 180,
                            position: "side"
                        }}
                        footer={
                            item.first_release_date ? (
                                <div className='flex justify-center gap-4'>
                                    First Release Date: {new Date(item.first_release_date * 1000).toLocaleDateString()}
                                </div>
                            ) : null
                        }>
                        <Box color="text-status-info" variant="h4"> {item.name ?? 'Unknown Game'} </Box>
                        <Box padding={{ top: "xs" }} fontSize="body-m">
                            {item.release_dates?.length > 0 ? (
                                item.release_dates
                                    .slice()
                                    .sort((a, b) => a.date - b.date)
                                    .map((release) => (
                                        <div key={release.id}>{release.platform ?? 'Unknown Platform'} - {release.date ? formatDate(release.date) : 'N/A'}</div>
                                    ))
                            ) : (
                                <div>Release dates not available.</div>
                            )}
                        </Box>
                    </Container>
                ))}
            </Grid>

        </ExpandableSection>
    );
};

const AddOnsComponent = ({ dlcs, bundles, expansions, remakes, remasters }) => {
    return (
        <SpaceBetween direction="vertical" size="m">
            {dlcs && dlcs.length > 0 && (
                <GenericExpandableSection title="DLCs" items={dlcs} />
            )}
            {bundles && bundles.length > 0 && (
                <GenericExpandableSection title="Bundles" items={bundles} />
            )}
            {expansions && expansions.length > 0 && (
                <GenericExpandableSection title="Expansions" items={expansions} />
            )}
            {remakes && remakes.length > 0 && (
                <GenericExpandableSection title="Remakes" items={remakes} />
            )}
            {remasters && remasters.length > 0 && (
                <GenericExpandableSection title="Remasters" items={remasters} />
            )}
        </SpaceBetween>
    );
};


export default AddOnsComponent;