import { createSlice } from "@reduxjs/toolkit";

const routesListState= {
    isLoading:false,
    data:[],
    isLoadingCompleted:false,
    isError: false,
    message:""
}


const routesSlice = createSlice({
    name:"routesList",
    initialState:routesListState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
            state.data = [];
        },
        createRouteList: (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
            state.isLoadingCompleted = true;
            state.isError = false;
        },
        clearRouteList:() => {
            return routesListState;
        },
        createError:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isLoadingCompleted = true;
            state.isError = false;
        },
    }
});
const routesListReducer = routesSlice.reducer;
export default routesListReducer;

export const routesSliceActions = routesSlice.actions;