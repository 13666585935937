export interface GetWikipediaDataResponseItem {
  timestamp: number;
  daily_rank: number;
  rank: number;
  views: number;
}

export interface MappedGetWikipediaResponseItem {
  dailyRank: number;
  timestamp: number;
  allTimeRank: number;
  pageViews: number;
}

export const mapWikipediaResponse = (item: GetWikipediaDataResponseItem) => ({
  dailyRank: item.daily_rank,
  timestamp: (item.timestamp * 1000) + 86400000,
  allTimeRank: item.rank,
  pageViews: item.views,
});

export const WIKIPEDIA_GRID_HEADER_MAP = {
  dailyRank: "Daily rank",
  allTimeRank: "All time rank",
  pageViews: "Page views",
};
