import { useEffect, useState } from "react";
import { CustomTable } from "../../../components/CustomTable";

export function RankingTable({
  data = [],
  columnDefinitions,
  setSelectedItems,
  actionsButton,
  selectedItems,
  onSubmit,
  flashMessages,
  onFlashDismiss,
  enableEdit,
  ...other
}) {
  const filteringProperties = [
    {
      propertyLabel: "Name",
      key: "name",
      groupValuesLabel: "Name",
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: "Search Demand",
      key: "searchDemand",
      groupValuesLabel: "Search Demand",
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: "Fan Sites",
      key: "fanSites",
      groupValuesLabel: "Fan Sites",
      operators: [":", "!:", "=", "!="],
    },
    {
      propertyLabel: "Content Consumption",
      key: "contentConsumption",
      groupValuesLabel: "Content Consumption",
      operators: [":", "!:", "=", "!="],
    },
    // {
    //   propertyLabel: "Consumer Products",
    //   key: "cpMarketplace",
    //   groupValuesLabel: "Consumer Products",
    //   operators: [":", "!:", "=", "!="],
    // },
    {
      propertyLabel: "Piracy",
      key: "piracy",
      groupValuesLabel: "Piracy",
      operators: [":", "!:", "=", "!="],
    },
  ].sort((a, b) => a.propertyLabel.localeCompare(b.propertyLabel));
  const visibleContentPreference = {
    title: "Select visible content",
    options: [
      {
        label: "Main Ranking properties",
        options: [
          {
            id: "name",
            label: "Name",
            editable: true,
          },
          {
            id: "searchDemand",
            label: "Search Demand",
            editable: true,
          },
          {
            id: "fanSites",
            label: "Fan Sites",
            editable: true,
          },
          {
            id: "contentConsumption",
            label: "Content Consumption",
            editable: true,
          },
          // {
          //   id: "cpMarketplace",
          //   label: "Consumer Products",
          //   editable: true,
          // },
          {
            id: "piracy",
            label: "Piracy",
            editable: true,
          },
          {
            id: "actions",
            label: "Actions",
            editable: true,
          },
        ],
      },
      {
        label: "Secondary Ranking properties",
        options: [
          { id: "id", label: "Id" },
          { id: "order", label: "Sort Order" },
        ],
      },
    ],
  };

  return (
    <CustomTable
      data={data}
      header={"Ranking"}
      columnDefinitions={columnDefinitions}
      selectionGroupLabel={"Ranking"}
      setSelectedItems={setSelectedItems}
      selectedItems={selectedItems}
      actionsButton={actionsButton}
      filteringProperties={filteringProperties}
      visibleContentPreference={visibleContentPreference}
      visibleContent={
        enableEdit
          ? [
              "name",
              "searchDemand",
              "fanSites",
              "contentConsumption",
              "cpMarketplace",
              "piracy",
              'actions'
            ]
          : [
              "name",
              "searchDemand",
              "fanSites",
              "contentConsumption",
              "cpMarketplace",
              "piracy",
            ]
      }
      onSubmit={onSubmit}
      flashMessages={flashMessages}
      onFlashDismiss={onFlashDismiss}
      {...other}
    />
  );
}
