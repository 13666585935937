import axiosInstance from "../../../../utils/axios";

export type GetYoutubeChannelsDataProps = {
  include: Array<any>;
  exclude: Array<any>;
  condition: string;
  sort: Array<any>;
};

export const getYoutubeChannelsData = ({
  sort = [],
  include = [],
  exclude = [],
  condition = "and",
}) => {
  return axiosInstance.request({
    url: "/youtube/v1/channels/list?size=10&from=0&trends=true",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ sort, include, exclude, condition }),
  });
};

export type GetYoutubeVideosDataProps = {
  include: Array<any>;
  exclude: Array<any>;
  condition: string;
  sort: Array<any>;
};

export const getYoutubeVideosData = ({
  sort = [],
  include = [],
  exclude = [],
  condition = "and",
}) => {
  return axiosInstance.request({
    url: "/youtube/v1/videos/list?size=10&from=0&trends=true",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify({ sort, include, exclude, condition }),
  });
};