import React from "react";
import { Header, Table, Box, TokenGroup } from "@cloudscape-design/components";
import _, { uniqueId } from "lodash";

const Chip = ({ label }) => (
  <span style={{
    display: 'inline-block',
    padding: '3px',
    paddingLeft: '8px',
    paddingRight: '8px',
    borderRadius: '14px',
    backgroundColor: 'rgb(30 58 138)',
    margin: '2px',
    maxWidth: '100px', // fixed width
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '11px',
  }}>
    {label}
  </span>
);


const COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE = [
  {
    id: "rank",
    header: "#",
    cell: (item) => item.rank,
    isRowHeader: true,
  },
  {
    id: "ip",
    header: "Title",
  },
  {
    id: "vertical",
    header: "Vertical",
  },
  {
    id: "views",
    header: "Views",
  },
  {
    id: "genre",
    header: "Genre",
    cell: (item) => (
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {item.genre.map((g, index) => (
          <Chip key={uniqueId()} label={g} />
        ))}
      </div>
    ),
  },
];

export const GetPanelContent = ({ items, setItems }) => {
  return getPanelContentComparison(items, setItems);
};

export const getPanelContentSingle = (items, setItems) => {
  if (!items.length) {
    return <>No items selected</>;
  }
  const item = items[0];

  return (
    <Table
      header={
        <Header variant="h2" counter={`(${item.length})`}>
          Compare Ips
        </Header>
      }
      columnDefinitions={COLUMN_DEFINITIONS_PANEL_CONTENT_SINGLE}
      items={item}
    ></Table>
  );
};

export const getPanelContentComparison = (items, setItems) => {
  if (!items.length) {
    return <>No items selected</>;
  }

  const sortedItemsByViews = _.sortBy(items, "views").reverse();

  const rankedItemsByViews = sortedItemsByViews.map((item, index) => {
    return { ...item, rank: index + 1 };
  });

  const keyHeaderMap = {
    rank: "Rank",
    ip: "Title",
    vertical: "Vertical",
    views: "Views",
    genre: "Genre",
  };
  const transformedData = ["rank", "ip", "vertical", "views", "genre"].map(
    (key) => {
      const data = { comparisonType: keyHeaderMap[key] };

      rankedItemsByViews.forEach((item) => {
        if (key === "rank") {
          data[item.ip_id] = item.rank;
        } else {
          data[item.ip_id] = item[key];
        }
      });

      return data;
    }
  );
  const columnDefinitions = [
    {
      id: "comparisonType",
      header: "",
      cell: ({ comparisonType }) => <b>{comparisonType}</b>,
    },
    ...rankedItemsByViews.map(({ ip, ip_id }) => ({
      ip,
      header: ip,
      cell: (item) => {
        if (item.comparisonType === 'Genre') {
          return (
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '200px' }}>
              {Array.isArray(item[ip_id])
                ? item[ip_id].map((g, index) => <Chip key={index} label={g} />)
                : item[ip_id]
              }
            </div>
          );
        }

        // Handle other cells
        if (Array.isArray(item[ip_id])) {
          return item[ip_id].join(", ");
        }

        return item[ip_id];
      }
    })),
  ];

  return (
    <Box padding={{ bottom: "l" }}>
      <TokenGroup
        onDismiss={({ detail: { itemIndex } }) => {
          setItems([
            ...items.slice(0, itemIndex),
            ...items.slice(itemIndex + 1),
          ]);
        }}
        items={items.map((i) => ({
          ...i,
          label: i.ip,
          dismissLabel: "Remove " + i.ip,
        }))}
      />
      <Table
        variant="compact"
        items={transformedData}
        columnDefinitions={columnDefinitions}
      />
    </Box>
  );
};
