import {
  TopNavigation,
  TopNavigationProps,
} from "@cloudscape-design/components";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/Logo";
import { useAuthContext } from "../../../auth/useAuthContext";
import CommonSearch from "../../../components/CommonSearch/CommonSearch";
import { SERVICES, TYPES } from "../../../config-global";
import { useGetGenericPreference_V2 } from "../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../../../services/generic_v2/hooks/usePostGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../../../services/generic_v2/hooks/usePutGenericPreference_V2";
import { invalidatePreferenceData } from "../../../services/generic_v2/utils/serviceUtils";
import { FavoritesBarList } from "./FavoriresBarList/FavoritesBarList";
import { ModeToggle } from "./ModeToggle";
import { FavoriteServices, ServicesMenu } from "./services-menu/ServicesMenu";

export default function Header() {
  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.FAVORITES };
  const { logout, user } = useAuthContext();
  const [favoriteServices, setFavoriteServices] = useState<FavoriteServices>(
    {},
  );

  const { data: favoritesPreferenceData } = useGetGenericPreference_V2({
    apiParams,
  });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: invalidatePreferenceData,
  });

  useEffect(() => {
    const pref = favoritesPreferenceData?.[0]?.data;

    if (pref) setFavoriteServices(pref);
  }, [favoritesPreferenceData]);

  const updateFavorites = useCallback(
    (data: FavoriteServices) => {
      updatePreference({ id: favoritesPreferenceData?.[0]?.id, payload: data });
    },
    [favoritesPreferenceData, updatePreference],
  );

  const handleFavorites = (path: string, label: string) => {
    const prefValue = favoritesPreferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;

      if (data[path]) delete data[path];
      else data[path] = label;

      updateFavorites(data);
      return;
    }

    const data = { [path]: label };
    postGenericPreference(data);
  };

  return (
    <div className="flex flex-col border-b border-slate-600 bg-appBg">
      <div className="grid grid-cols-[auto_1fr_auto]">
        <div className="flex items-center gap-5 ml-6">
          <Link to="/">
            <Logo width={8} height={8} />
          </Link>
          <ServicesMenu
            favoriteServices={favoriteServices}
            handleFavorites={handleFavorites}
          />
        </div>
        <TopNavigation
          identity={{} as TopNavigationProps.Identity}
          search={<CommonSearch />}
          i18nStrings={{
            searchIconAriaLabel: "Search",
            searchDismissIconAriaLabel: "Close search",
            overflowMenuTriggerText: "More",
            overflowMenuTitleText: "All",
            overflowMenuBackIconAriaLabel: "Back",
            overflowMenuDismissIconAriaLabel: "Close menu",
          }}
          utilities={[
            // {
            //   type: "button",
            //   iconName: "notification",
            //   title: "Notifications",
            //   ariaLabel: "Notifications (unread)",
            //   badge: true,
            //   disableUtilityCollapse: false,
            // },
            {
              type: "menu-dropdown",
              iconName: "settings",
              ariaLabel: "Settings",
              title: "Settings",
              items: [
                // {
                //   id: "account-mgmt",
                //   text: "Account Management",
                //   items: [
                //     { id: "users", text: "Users", href: "/users" },
                //     { id: "roles", text: "Roles", href: "/roles" },
                //     {
                //       id: "permissions",
                //       text: "Permissions",
                //       href: "/permissions",
                //     },
                //     { id: "teams", text: "Teams", href: "/teams" },
                //   ],
                // },
                {
                  text: "IP Management",
                  items: [
                    // { id: "ip", text: "NBCU IP's", href: "/ipmanagement" },
                    // {
                    //   id: "pantheon",
                    //   text: "Pantheon IP's",
                    //   href: "/ipmanagement/pantheon",
                    // },
                    {
                      id: "data",
                      text: "Data Mapping",
                      href: "/datamapping/idmanagement",
                    },
                    {
                      id: "data",
                      text: "Discovery",
                      href: "/datamapping/discover",
                    },
                  ],
                },
                // {
                //   text: "Weightage Settings",
                //   items: [
                //     {
                //       id: "tracked",
                //       text: "Tracked Titles",
                //       href: "/settings/nbcu-weightage/ranking",
                //     },
                //   ],
                // },
              ],
            },
            {
              type: "menu-dropdown",
              text: user.name,
              description: user.email,
              iconName: "user-profile",
              onItemClick: (e) => {
                if (e.detail.id === "signout") {
                  logout();
                }
              },
              items: [
                { id: "profile", text: "Profile" },
                {
                  text: "Support",
                  items: [
                    {
                      text: "Documentation",
                      id: "docs",
                      href: "https://guide.searchpantheon.com",
                      external: true,
                    },
                    {
                      text: "Job Monitoring",
                      id: "jb",
                      href: "/platformjobstate",
                    },
                    { text: "Audit Events", id: "audit", href: "/audit" },
                    { id: "link", text: "Issue Tracker", href: "/issues" },
                  ],
                },
                { id: "signout", text: "Sign out" },
              ],
            },
          ]}
        />
        <ModeToggle />
      </div>
      <FavoritesBarList
        favoriteServices={favoriteServices}
        setFavoriteServices={setFavoriteServices}
        updateFavorites={updateFavorites}
      />
    </div>
  );
}
