import { SideNavigationProps } from "@cloudscape-design/components";

export type BreadcrumbsItem = {
  text: string;
  href?: string;
};

export type NavItemWithId = SideNavigationProps.SectionGroup & {
  id: string;
};

export type NavItemsWithId = ReadonlyArray<NavItemWithId>;

export const SERVICES_GROUP = {
  RANKING: "RANKING",
  DASHBOARDS: "DASHBOARDS",
  PLATFORMS: "PLATFORMS",
  TOPIC_MODELLING: "TOPIC_MODELLING",
  TOOLS: "TOOLS",
  EXPLORE: "EXPLORE",
  YOUTUBE_CHANNELS_AND_TRAILERS: "YOUTUBE_CHANNELS_AND_TRAILERS",
  ACCOUNT_MANAGEMENT: "ACCOUNT_MANAGEMENT",
};

export const navItems = [
  {
    type: "section-group",
    title: "Account Management",
    id: SERVICES_GROUP.ACCOUNT_MANAGEMENT,
    items: [
      { type: "divider" },
      {
        type: "link",
        text: "Users",
        href: "/users",
      },
      {
        type: "link",
        text: "Roles",
        href: "/roles",
      },
      {
        type: "link",
        text: "Permissions",
        href: "/permissions",
      },
      {
        type: "link",
        text: "Teams",
        href: "/teams",
      },
      { type: "divider" },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Rankings",
    id: SERVICES_GROUP.RANKING,
    items: [
      { type: "divider" },
      {
        type: "link",
        text: "Overview",
        href: "/ranking/global",
      },
      { type: "divider" },
      {
        type: "section",
        defaultExpanded: true,
        text: "Global",
        items: [
          {
            type: "link",
            text: "Movies",
            href: "/dashboards/globalranking/movies",
          },
          {
            type: "link",
            text: "Series",
            href: "/dashboards/globalranking/series",
          },
          {
            type: "link",
            text: "Games",
            href: "/dashboards/globalranking/gaming",
          },
        ],
      },
      { type: "divider" },
      // {
      //   type: "expandable-link-group",
      //   text: "Peacock Dashboard",
      //   href: "/peacock-dashboard",
      //   items: [
      //     {
      //       type: "expandable-link-group",
      //       text: "Series Analysis",
      //       items: [
      //         {
      //           type: "link",
      //           text: "Genre Insights",
      //           href: "/tar/series/wiki/view/genre",
      //         },
      //         {
      //           type: "link",
      //           text: "Platform Influence",
      //           href: "/tar/series/wiki/view/genre/platform",
      //         },
      //         {
      //           type: "link",
      //           text: "Platform Ratio by Genre",
      //           href: "/tar/series/wiki/view/genre/platform/marketshare",
      //         },
      //       ],
      //     },
      //     {
      //       type: "link",
      //       text: "Series: Beyond OTT",
      //       href: "/peacock-dashboard/non-ott-titles/series",
      //     },
      //     {
      //       type: "link",
      //       text: "Movies: Unstreamed Picks",
      //       href: "/peacock-dashboard/non-ott-titles/movies",
      //     },
      //   ],
      // },
      {
        type: "section",
        text: "Trending",
        defaultExpanded: true,
        items: [
          // {
          //   type: "link",
          //   text: "Overview",
          //   href: "/ranking/trending",
          // },
          // {
          //   type: "link",
          //   text: "Pantheon Ranking",
          //   href: "/dashboards/pantheonranking",
          // },
          {
            type: "link",
            text: "Data Table",
            href: "/dashboards/raw-data",
          },
        ],
      },
      { type: "divider" },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Dashboards",
    id: SERVICES_GROUP.DASHBOARDS,
    items: [
      { type: "divider" },
      {
        type: "link",
        text: "Personalized Dashboard",
        href: "/dashboards/wizard",
      },
      // {
      //   type: "link",
      //   text: "Netflix Dashboard",
      //   href: "/dashboards/netflix",
      // },
      { type: "divider" },
      {
        type: "link",
        text: "Non-OTT Titles",
        href: "/dashboards/nonott",
      },
      { type: "divider" },
      {
        type: "link",
        text: "Genre Dashboard",
        href: "/dashboards/genres",
      },
      { type: "divider" },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Platforms",
    id: SERVICES_GROUP.PLATFORMS,

    items: [
      { type: "divider" },
      { type: "link", text: "Pantheon", href: "/platforms/global" },
      { type: "divider" },
      {
        type: "link",
        text: "Wikipedia",
        href: "/platforms/wikipedia",
      },
      {
        type: "link",
        text: "IMDb",
        href: "/platforms/imdb",
      },
      {
        type: "link",
        text: "Rotten Tomatoes",
        href: "/platforms/rottentomatoes",
      },
      {
        type: "link",
        text: "Twitch",
        href: "/platforms/twitch",
      },
      {
        type: "link",
        text: "Steam",
        href: "/platforms/steam",
      },
      {
        type: "link",
        text: "YouTube",
        href: "/platforms/youtube",
      },
      // {
      //   type: "link",
      //   text: "Amazon Search",
      //   href: "/platforms/consumer-products/amazon-search",
      //   info: <Badge color="green">POC</Badge>,
      // },
      {
        type: "link",
        text: "Piracy",
        href: "/platforms/piracy",
      },
      { type: "divider" },
      // {
      //   type: "expandable-link-group",
      //   text: "Raw Data",
      //   items: [
      //     {
      //       type: "link",
      //       text: "Wikipedia",
      //       href: "/platforms/wikipedia/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "Google Trends",
      //       href: "/platforms/google/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "X (Twitter)",
      //       href: "/platforms/content-consumption/twitter/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "TikTok",
      //       href: "/platforms/content-consumption/tiktok/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "Instagram",
      //       href: "/platforms/content-consumption/instagram/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "Reddit",
      //       href: "/platforms/fan-sites/reddit/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "Fandom",
      //       href: "/platforms/fan-sites/fandom/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "Wattpad",
      //       href: "/platforms/fan-sites/wattpad/raw-data",
      //     },
      //     {
      //       type: "link",
      //       text: "AO3",
      //       href: "/platforms/fan-sites/ao3/raw-data",
      //     },
      //   ],
      // },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Topic Modeling",
    id: SERVICES_GROUP.TOPIC_MODELLING,
    items: [
      { type: "divider" },
      {
        type: "link",
        text: "General",
        href: "/topic-modeling/pantheon",
      },
      {
        type: "link",
        text: "True Crime",
        href: "/topic-modeling/true-crime",
      },
      { type: "divider" },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Tools",
    id: SERVICES_GROUP.TOOLS,
    items: [
      { type: "divider" },
      {
        type: "link",
        text: "Compare",
        href: "/tools/compare",
      },
      {
        type: "link",
        text: "Google Search Trends",
        href: "/tools/google-search-trends",
      },
      {
        type: "link",
        text: "Twitter Query Trends",
        href: "/tools/twitter-query-trends",
      },
      {
        type: "link",
        text: "JustWatch Charts",
        href: "/dashboards/jwstreaming",
      },
      { type: "divider" },
      {
        type: "expandable-link-group",
        text: "Box Office Analysis",
        defaultExpanded: true,
        href: "/box-office",
        items: [
          { type: "link", text: "Rankings", href: "/box-office/ranking" },
          {
            type: "link",
            text: "Timeseries",
            href: "/box-office/grosstimeseries",
          },
          {
            type: "link",
            text: "By Distributor",
            href: "/box-office/bo-by-studio",
          },
        ],
      },
      { type: "divider" },
      {
        type: "link",
        text: "Offline Reports",
        href: "/tools/offline-reports",
      },
      { type: "divider" },
    ],
  },
  { type: "divider" },
  {
    type: "section-group",
    title: "Explore",
    id: SERVICES_GROUP.EXPLORE,
    items: [
      { type: "divider" },
      { type: "link", text: "Summary", href: "/explore" },
      { type: "divider" },
      { type: "link", text: "Movies", href: "/explore/movies" },
      { type: "link", text: "Series", href: "/explore/television" },
      { type: "link", text: "Games", href: "/explore/games" },
      { type: "divider" },
      { type: "link", text: "Franchises", href: "/explore/franchise" },
      { type: "link", text: "Companies", href: "/explore/companies" },
      { type: "divider" },
    ],
  },
  // {
  //   type: "expandable-link-group",
  //   text: "OTT Availability",
  //   items: [
  //     {
  //       type: "link",
  //       text: "Movies",
  //       href: "/tar/movie/titleavailability",
  //     },
  //     {
  //       type: "link",
  //       text: "Series",
  //       href: "/tar/television/titleavailability",
  //     },
  //   ],
  // },
  { type: "divider" },
  {
    type: "section-group",
    title: "YouTube",
    id: SERVICES_GROUP.YOUTUBE_CHANNELS_AND_TRAILERS,
    items: [
      { type: "divider" },
      {
        type: "link",
        text: "Overview",
        href: "/youtube",
      },
      { type: "divider" },
      {
        type: "link",
        text: "Trends",
        href: "/youtube/trends",
      },
      { type: "divider" },
      {
        type: "link",
        text: "Channels",
        href: "/youtube/channels",
      },
      { type: "divider" },
      {
        type: "section",
        text: "Trailers",
        defaultExpanded: true,
        items: [
          {
            type: "link",
            text: "Movie Trailers",
            href: "/youtube/movietrailers",
          },
          {
            type: "link",
            text: "Series Trailers",
            href: "/youtube/seriestrailers",
          },
          {
            type: "link",
            text: "Game Trailers",
            href: "/youtube/gamestrailers",
          },
          {
            type: "link",
            text: "Kids Trailers",
            href: "/youtube/kidstrailers",
          },
          {
            type: "link",
            text: "Unmapped Trailers",
            href: "/youtube/unmappedtrailers",
          },
          {
            type: "link",
            text: "Unofficial Trailers",
            href: "/youtube/unofficialtrailers",
          },
          {
            type: "link",
            text: "All Trailers",
            href: "/youtube/alltrailers",
          },
          
        ],
        
      },
      { type: "divider" },
    ],
  },
];
