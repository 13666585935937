export const getQueryParams = (param: string) => {
  const urlParams = new URLSearchParams(window.location.search);

  return urlParams.get(param);
};

type QueryParams = {
  [key: string]: string;
};

export const addQueryParams = (params: QueryParams) => {
  if (Object.keys(params).length === 0) return;

  const url = new URL(window.location.href);

  Object.keys(params).forEach((key) => url.searchParams.set(key, params[key]));
  window.history.pushState({}, "", url);
};
