import {
  Button,
  FormField,
  Header,
  Multiselect,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import "ag-grid-enterprise";
import React, { forwardRef, useEffect, useState } from "react";
import { PreferenceItem } from "../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../../../services/generic_v2/hooks/usePostGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../../../services/generic_v2/hooks/usePutGenericPreference_V2";
import { createOptionsField } from "../../../utils/selectUtils";
import { usePlatformContext } from "../PlatformProvider";
import { getCategories } from "../utils/categoryUtils";
import { PlatformFilters } from "./PlatformFilters";

export const PlatformChartHeader = forwardRef(
  (
    {
      defaultColumn,
      additionalPlatformColumns,
      filter,
      selectedVertical,
      setSelectedVertical,
      selectedOptions,
      setSelectedOptions,
      apiParams,
      categoryOptions,
    }: any,
    ref: any,
  ) => {
    const { platformPreference } = usePlatformContext();
    const [initialState, setInitialState] = useState({
      category: "movies",
      additionalColumns: [],
    });
    const [options, setOptions] = useState();

    const path = `${apiParams.service}/${apiParams.module}/${apiParams.tab}`;
    const existingPref = platformPreference?.find(
      (item: PreferenceItem) => item.path === path,
    );

    const { mutate: updatePreference } = usePutGenericPreference_V2();
    const { mutate: postGenericPreference, isLoading } =
      usePostGenericPreference_V2({ apiParams });

    useEffect(() => {
      const prefValue = existingPref?.data?.[selectedVertical?.value];

      if (prefValue?.additionalColumns)
        setSelectedOptions(
          prefValue.additionalColumns?.map(createOptionsField),
        );
      else setSelectedOptions([createOptionsField(defaultColumn)]);

      setInitialState({
        category: prefValue?.category ?? "movies",
        additionalColumns: prefValue?.additionalColumns ?? [],
      });
    }, [
      defaultColumn,
      existingPref?.data,
      selectedVertical?.value,
      setSelectedOptions,
    ]);

    useEffect(() => {
      const newOptions = additionalPlatformColumns
        .filter((key: string) =>
          getCategories(key)?.includes(selectedVertical.value),
        )
        .map(createOptionsField);

      setOptions(newOptions);
    }, [additionalPlatformColumns, selectedOptions.value, selectedVertical]);

    const handlePreferenceChange = () => {
      const vertical = selectedVertical?.value;

      if (existingPref) {
        const payload = {
          ...existingPref?.data,
          [vertical]: {
            ...existingPref?.data?.[vertical],
            additionalColumns: selectedOptions.map(({ label }) => label),
          },
          category: vertical,
        };

        updatePreference({ id: existingPref.id, payload });
        return;
      }

      const payload = {
        [vertical]: {
          ...existingPref?.data?.[vertical],
          additionalColumns: selectedOptions.map(({ label }) => label),
        },
        category: vertical,
      };

      postGenericPreference(payload);
    };

    const onCategoryChange = ({ detail }) =>
      setSelectedVertical(detail.selectedOption);

    const hasChangesToSave =
      selectedVertical.value !== initialState.category ||
      selectedOptions.length !== initialState.additionalColumns.length ||
      selectedOptions.some(
        (option: OptionDefinition) =>
          !initialState.additionalColumns.includes(option.label),
      );

    return (
      <Header
        actions={
          <PlatformFilters
            existingPref={existingPref}
            filter={filter}
            vertical={selectedVertical.value}
            apiParams={apiParams}
            ref={ref}
          />
        }
      >
        <SpaceBetween direction="horizontal" size="xs">
          <FormField description="Category">
            <Select
              selectedOption={selectedVertical}
              expandToViewport
              onChange={onCategoryChange}
              controlId="select-vertical"
              placeholder="Category"
              options={categoryOptions}
            />
          </FormField>
          <FormField description="Additional Columns">
            <Multiselect
              selectedOptions={selectedOptions}
              onChange={({ detail }) => {
                setSelectedOptions(detail.selectedOptions);
              }}
              options={options}
              placeholder="Choose options"
              hideTokens
            />
          </FormField>
          <div className="mt-5">
            <Button
              variant="normal"
              disabled={!hasChangesToSave}
              onClick={handlePreferenceChange}
              loading={isLoading}
            >
              Save options
            </Button>
          </div>
        </SpaceBetween>
      </Header>
    );
  },
);
