import { Grid } from "@cloudscape-design/components";
import "ag-charts-enterprise";
import { AgChartOptions } from "ag-charts-enterprise";
import { AgCharts } from "ag-charts-react";
import React, { FC } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../hooks/UseTheme/useGetChartTheme";
import shortenInteger from "../../../../utils/shortenInteger";
import { useGetVideoTrends } from "../api/hooks/useGetVideoTrends";

interface VideoTrendsProps {
  videoId: string;
}

export const VideoTrends: FC<VideoTrendsProps> = ({ videoId }) => {
  const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
  const { theme: viewsThemeOverrides } = useGetChartTheme(CHART_TYPES.VIVID, {
    palette: {
      fills: ["#E69138"],
    },
  });
  const { theme: commentThemeOverrides } = useGetChartTheme(CHART_TYPES.VIVID, {
    palette: {
      fills: ["#E63A38"],
    },
  });
  const { data: videoTrendsData } = useGetVideoTrends(videoId);
  const sumOfComments = videoTrendsData.reduce((acc, currentItem) => {
    if (currentItem?.comments > 0) {
      return acc + currentItem.comments;
    }
    return acc;
  }, 0);

  const viewsOptions = {
    theme: viewsThemeOverrides,
    
    zoom: {
      enabled: true,
    },
    title: {
      text: "Viewership Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: videoTrendsData,
    series: [
      {
        type: "area",
        xKey: "timestamp",
        yKey: "views",
        yName: "Views",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Daily Views",
              content: `
                <b>Date:</b> ${date.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })} <br />
                <b>Views:</b> ${Math.round(
                  params.datum.views,
                ).toLocaleString()}<br/>
              `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Views",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["views"],
        label: {
          formatter: (params) => {
            return String(shortenInteger(params.value));
          },
        },
      },
    ],
  } as AgChartOptions;

  const likeOptions = {
    theme: theme,
    
    zoom: {
      enabled: true,
    },
    title: {
      text: "Like Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: videoTrendsData,
    series: [
      {
        type: "line",
        xKey: "timestamp",
        yKey: "likes",
        yName: "Likes",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Likes",
              content: `
               <b>Date:</b> ${date.toLocaleDateString("en-US", {
                 day: "numeric",
                 month: "long",
                 year: "numeric",
               })} <br />
              <b>New Likes:</b> ${Math.round(
                params.datum.likes,
              ).toLocaleString()}<br/>
              `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Likes",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["likes"],
        label: {
          formatter: (params) => {
            return String(shortenInteger(params.value));
          },
        },
      },
    ],
  } as AgChartOptions;

  const commentOptions = {
    theme: commentThemeOverrides,
    
    zoom: {
      enabled: true,
    },
    title: {
      text: "Comment Trends",
      fontSize: 14,
      color: "rgb(100 116 139)",
    },
    data: videoTrendsData,
    series: [
      {
        type: "bar",
        xKey: "timestamp",
        yKey: "comments",
        yName: "Comments",
        tooltip: {
          renderer: (params) => {
            const date = new Date(params.datum.timestamp);
            return {
              title: "Comments",
              content: `
                <b>Date:</b> ${date.toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })} <br />
                <b>New Comments:</b> ${Math.round(
                  params.datum.comments,
                ).toLocaleString()}<br/>
                `,
            };
          },
        },
      },
    ],
    axes: [
      {
        type: "time",
        position: "bottom",
        nice: true,
        label: {
          autoRotate: true,
          format: "%d %b %Y",
        },
      },
      {
        type: "number",
        position: "left",
        gridLine: { style: [{ stroke: "#192e47", lineDash: [10, 10] }] },
        title: {
          text: "Videos",
          fontSize: 11,
          color: "rgb(100 116 139)",
        },
        keys: ["comments"],
      },
    ],
  } as AgChartOptions;

  return (
    <Grid
      gridDefinition={[
        { colspan: { xxs: 12, s: 6, l: 4 } },
        { colspan: { xxs: 12, s: 6, l: 4 } },
        { colspan: { xxs: 12, s: 6, l: 4 } },
      ]}
    >
      <div className="h-60">
        <AgCharts options={viewsOptions} style={{ height: "100%" }} />
      </div>
      <div className="h-60">
        <AgCharts options={likeOptions} style={{ height: "100%" }} />
      </div>
      {sumOfComments > 0 ? (
        <div className="h-60">
          <AgCharts options={commentOptions} style={{ height: "100%" }} />
        </div>
      ) : null}
    </Grid>
  );
};
