export const ItemTypes = {
    TELEVISION_SERIES: "thing-Q5398426",
    FILM: "thing-Q11424",
    VIDEO_GAME: "thing-Q7889",
    FRANCHISE: "thing-Q196600",
    COMPANY: "thing-Q783794",
};

export const ItemNames = {
    "thing-Q5398426": "Television Series",
    "thing-Q11424": "Film",
    "thing-Q7889": "Video Game",
    "thing-Q196600": "Franchise",
    "thing-Q783794": "Company",
};