import { Button, SpaceBetween, Textarea } from "@cloudscape-design/components";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { qModules } from "../modules/bug-reporting/constants";
import '../index.css' 

const CommentBox = ({ user, onChange, userComment, onSubmit, onCancel, onSubmitAndClose }) => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex flex-row justify-start gap-2">
        <div className="w-10 h-10 bg-slate-700 rounded-full flex flex-row justify-center text-m items-center text-white font-bold">
          {user?.username?.substring(0, 1).toUpperCase()}&nbsp;
          {user?.username?.substring(1, 2).toUpperCase()}
        </div>
        <div className="567placeholder-quill-wrapper flex-1">
          <ReactQuill
            modules={qModules}
            value={userComment}
            onChange={(t) => onChange(t)}
          />
          <div className="flex justify-end pt-4">
            <SpaceBetween direction="horizontal" size="m">
              <Button onClick={() => { onCancel(); }} > Cancel </Button>
              <Button variant="primary" disabled={!userComment} onClick={() => { onSubmit(); }} > Comment </Button>
              <Button variant="primary" disabled={!userComment} onClick={() => { onSubmitAndClose(); }} > Comment and Close </Button>
            </SpaceBetween>
          </div>


          
        </div>
        
      </div>
      
    </div>
    
  );
};

export default CommentBox;
