import { useMutation } from "react-query";
import { queryClient } from "../../../../../app/ReactQueryWrapper";
import { QUERY_KEYS } from "../constants";
import { PostDeleteChannelType, postDeleteChannel } from "../request";

export const useDeleteChannel = (
  successCallback: () => void,
  failureCallback: () => void,
) => {
  const { isLoading, mutate, error } = useMutation(
    (payload: PostDeleteChannelType) => postDeleteChannel(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.GET_CHANNELS_LIST_DATA);
        successCallback();
      },
      onError: failureCallback,
    },
  );

  return {
    mutate,
    isLoading,
    error,
  };
};
