import { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { Button, ButtonDropdown, Checkbox, Drawer, FormField, Input, Slider, SpaceBetween } from "@cloudscape-design/components";
import { EditableTextAttribute } from "./EditableTextAttribute";
import { invalidatePreferenceData } from "../../../services/generic_v2/utils/serviceUtils";
import { TYPES, SERVICES } from "../../../config-global";
import { useGetGenericPreference_V2 } from "../../../services/generic_v2/hooks/useGetGenericPreference_V2";
import { usePutGenericPreference_V2 } from "../../../services/generic_v2/hooks/usePutGenericPreference_V2";
import { usePostGenericPreference_V2 } from "../../../services/generic_v2/hooks/usePostGenericPreference_V2";
import { DEFAULT_WEIGHTS_OBJECTS } from "../constants";
import { MessageBox } from "../../../components/MessageBox";


export const PlatformWeightsDrawerV2 = ({
  weightsObject,
  setWeightsObject,
  vertical,
}) => {

  const apiParams = { type: TYPES.PERSONAL, service: SERVICES.RANKING, module: "global" };
  const messageBoxRef = useRef(null);

  const [currentWeightsObject, setCurrentWeightsObject] = useState(weightsObject);
  const [loadedWeights, setLoadedWeights] = useState([]);

  const { data: rankingPreferenceData } = useGetGenericPreference_V2({
    apiParams,
  });
  const { mutate: updatePreference } = usePutGenericPreference_V2();
  const { mutate: postGenericPreference } = usePostGenericPreference_V2({
    apiParams,
    onSuccess: invalidatePreferenceData,
  });

  useEffect(() => {
    const pref = rankingPreferenceData?.[0]?.data;

    if (pref) {
      if (pref.weights) {
        setLoadedWeights(pref.weights);
        if (pref.lastLoadedWeights && pref.lastLoadedWeights[vertical]) {
          const lastLoadedWeightsName = pref.lastLoadedWeights[vertical];
          setCurrentWeightsObject(pref.weights.find(w => w.name === lastLoadedWeightsName && w.category === vertical) ?? weightsObject);
        }
      }
    }
  }, [rankingPreferenceData]);

  const savePreferences = (weightObjects, currWeightsObject) => {
    const prefValue = rankingPreferenceData?.[0];

    if (Object.keys(prefValue?.data ?? {}).length > 0) {
      const { data } = prefValue;

      data.weights = weightObjects;
      data.lastLoadedWeights = { ...data.lastLoadedWeights, [vertical]: currWeightsObject ? currWeightsObject.name : null };

      updatePreference({ id: prefValue.id, payload: data });
      return;
    }

    const data = { weights: weightObjects, lastLoadedWeights: { [vertical]: currWeightsObject ? currWeightsObject.name : null } };
    postGenericPreference(data);
  };

  const currentWeightsAreSaved = useMemo(() => {
    return loadedWeights?.map(w => _.isEqual(w, currentWeightsObject)).includes(true);
  }, [loadedWeights, currentWeightsObject]);

  return (
    <Drawer header="Platform Weights">
      <SpaceBetween direction="vertical" size="m">
        <div className="border-b border-1 pb-4 mb-2 border-slate-600">
          <div className="flex justify-between items-center w-full">
            <div className="min-w-0">
              <EditableTextAttribute
                value={currentWeightsObject.name}
                onChange={(value) => setCurrentWeightsObject({ ...currentWeightsObject, name: value })}
              />
            </div>
            <ButtonDropdown
              expandableGroups
              expandToViewport
              items={[
                {
                  text: "Load weights",
                  id: "load",
                  disabled: loadedWeights?.filter(w => w.category === vertical)?.length === 0,
                  items: loadedWeights?.filter(w => w.category === vertical)?.map(w => (
                    {
                      text: w.name,
                      id: `load_${w.name}`,
                      disabled: false,
                    }
                  ))
                },
                { text: "Load defaults", id: "reset", disabled: (_.isEqual(currentWeightsObject, DEFAULT_WEIGHTS_OBJECTS[vertical])) },
                { text: "Save weights", id: "save", disabled: (_.isEqual(currentWeightsObject, DEFAULT_WEIGHTS_OBJECTS[vertical]) || currentWeightsAreSaved) },
                { text: "Delete weights", id: "delete", disabled: (loadedWeights.filter(w => w.name === currentWeightsObject.name && w.category === vertical).length === 0) },
              ]}
              variant="icon"
              onItemClick={({ detail }) => {
                switch (detail.id) {
                  case "save":
                    const save = () => {
                      const newLoadedWeights = [...loadedWeights.filter(w => !(w.name === currentWeightsObject.name && w.category === vertical)), currentWeightsObject];
                      setLoadedWeights(newLoadedWeights);
                      savePreferences(newLoadedWeights, currentWeightsObject);
                    };
                    if (loadedWeights.filter(w => w.name === currentWeightsObject.name && w.category === vertical).length > 0) {
                      messageBoxRef.current.open({
                        headerText: "Overwrite weights?",
                        messageBody: (<div>Weights named <b>{currentWeightsObject.name}</b> already exist. Overwrite it?</div>),
                        primaryButtonText: "Yes",
                        secondaryButtonText: "No",
                        onPrimaryButtonClick: save,
                      });
                    } else {
                      save();
                    }
                    break;
                  case "delete":
                    messageBoxRef.current.open({
                      headerText: "Are you sure?",
                      messageBody: (<div>Are you sure you want to delete weights <b>{currentWeightsObject.name}</b>?</div>),
                      primaryButtonText: "Delete",
                      secondaryButtonText: "Cancel",
                      onPrimaryButtonClick: () => {
                        const newLoadedWeights2 = loadedWeights.filter(w => !(w.name === currentWeightsObject.name && w.category === vertical));
                        setLoadedWeights(newLoadedWeights2);
                        savePreferences(newLoadedWeights2, null);
                        setCurrentWeightsObject(DEFAULT_WEIGHTS_OBJECTS[vertical]);
                      },
                    });
                    break;
                  case "reset":
                    setCurrentWeightsObject(DEFAULT_WEIGHTS_OBJECTS[vertical]);
                    savePreferences(loadedWeights, null);
                    break;
                  default:
                    if (detail.id.startsWith("load_")) {
                      const newWeights = loadedWeights.find(w => w.name === detail.id.replace("load_", "") && w.category === vertical);
                      if (newWeights) {
                        setCurrentWeightsObject(newWeights);
                        savePreferences(loadedWeights, newWeights);
                      }
                    }
                }
              }}
            />
          </div>
          <div className="flex w-full">
            {!currentWeightsAreSaved && !_.isEqual(currentWeightsObject, DEFAULT_WEIGHTS_OBJECTS[vertical]) && (
              <div className="text-xs text-slate-400">You have unsaved changes</div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-2 w-full">
          {currentWeightsObject.weights.map((weight, index) => (
            <div className="flex items-center justify-between w-full" key={index}>
              <FormField
                label={
                  <div className="flex items-center justify-between w-full">
                    <Checkbox
                      checked={weight.enabled}
                      onChange={({ detail }) => {
                        const newWeights = currentWeightsObject.weights.map((w, i) => {
                          if (i === index) {
                            return { ...w, enabled: detail.checked };
                          }
                          return w;
                        });
                        setCurrentWeightsObject({ ...currentWeightsObject, weights: newWeights });
                      }}
                    >
                      {weight.platform.name}
                    </Checkbox>
                    <div className="w-16">
                      <Input
                        value={weight.weight}
                        onChange={({ detail }) => {
                          const newWeights = currentWeightsObject.weights.map((w, i) => {
                            if (i === index) {
                              return { ...w, weight: detail.value };
                            }
                            return w;
                          });
                          setCurrentWeightsObject({ ...currentWeightsObject, weights: newWeights });
                        }}
                        inputMode="decimal"
                        type="number"
                        disabled={!weight.enabled}
                        step={0.5}
                      />
                    </div>
                  </div>
                }
                stretch
                className="w-full"
              >
                <div className="grow w-full">
                  <Slider
                    className="slider-no-labels"
                    onChange={({ detail }) => {
                      const newWeights = currentWeightsObject.weights.map((w, i) => {
                        if (i === index) {
                          return { ...w, weight: detail.value };
                        }
                        return w;
                      });
                      setCurrentWeightsObject({ ...currentWeightsObject, weights: newWeights });
                    }}
                    value={weight.weight}
                    max={10.0}
                    min={0.0}
                    step={0.5}
                    disabled={!weight.enabled}
                  />
                </div>
              </FormField>
            </div>
          ))}
        </div>
        <div className="flex space-x-2 float-right">
          <Button
            variant="normal"
            onClick={() => {
              const defaultWeights = DEFAULT_WEIGHTS_OBJECTS[vertical];
              setCurrentWeightsObject(defaultWeights);
            }}
            disabled={_.isEqual(DEFAULT_WEIGHTS_OBJECTS[vertical], currentWeightsObject)}
          >
            Reset
          </Button>
          <Button
            variant="primary"
            onClick={() => setWeightsObject(currentWeightsObject)}
            disabled={_.isEqual(weightsObject, currentWeightsObject)}
          >
            Apply
          </Button>
        </div>
      </SpaceBetween>
      <MessageBox ref={messageBoxRef} />
    </Drawer>
  );
};