import { useQuery } from "react-query";
import { YOUTUBE_TRENDS_QUERY_KEYS } from "../contants";
import { getYouTubeVideoTrendsData } from "../request";

export const useGetVideoData = (interval: string) => {
  const { isLoading, data, error } = useQuery(
    [YOUTUBE_TRENDS_QUERY_KEYS.GET_VIDEO_DATA, interval],
    () => getYouTubeVideoTrendsData(interval),
    {
      keepPreviousData: true,
    }
  );

  return {
    data: data ?? [],
    isLoading,
    error,
  };
};
