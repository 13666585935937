import {
  Box,
  Button,
  Cards,
  FormField,
  Select,
  SpaceBetween,
  TextFilter,
} from "@cloudscape-design/components";
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { SelectedOptionType } from "../../../../types/optionTypes";
import { isNone, isSomeOrElse } from "../../../../utils/sugarUtils";
import { useChannelsSearch } from "../api/hooks/useChannelsSearch";
import { PostCreateChannelType } from "../api/request";

type ItemType = {
  title: string;
  thumbnails: string;
  description: string;
  channel_id: string;
  publishedAt: number;
};

type ChannelCardsProps = {
  selectedChannelData: PostCreateChannelType;
  setSelectedChannelData: Dispatch<SetStateAction<PostCreateChannelType>>;
};

export const ChannelCards: FC<ChannelCardsProps> = ({
  selectedChannelData,
  setSelectedChannelData,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedOptions, setSelectedOptions] = useState<
    Record<string, SelectedOptionType>
  >({});

  const { data: channelsSearchData } = useChannelsSearch(searchQuery);

  useEffect(() => {
    if (!selectedChannelData) {
      setSearchInput("");
      setSearchQuery("");
      setSelectedItems([]);
      setSelectedOptions({});
    }
  }, [selectedChannelData]);

  useEffect(() => {
    const channelId = selectedItems[0]?.channel_id;

    if (isNone(selectedOptions[channelId])) {
      setSelectedChannelData(null);
      return;
    }

    setSelectedChannelData({
      channel_id: channelId,
      type: selectedOptions[channelId].value,
      todaysViews: 0,
    });
  }, [selectedItems, selectedOptions, setSelectedChannelData]);

  return (
    <SpaceBetween direction="vertical" size="m">
      <div className="flex w-full">
        <div style={{ flexGrow: 1 }}>
          <TextFilter
            filteringPlaceholder="Search channels"
            filteringText={searchInput}
            onChange={({ detail }) => setSearchInput(detail.filteringText)}
          />
        </div>
        <Button onClick={() => setSearchQuery(searchInput)} variant="primary">
          Search
        </Button>
      </div>
      <Cards
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail?.selectedItems ?? []);
          setSelectedOptions({});
        }}
        selectedItems={selectedItems}
        ariaLabels={{
          itemSelectionLabel: (e, t) => `select ${t.channel_id}`,
          selectionGroupLabel: "Item selection",
        }}
        cardDefinition={{
          header: (item: ItemType) => item.title,
          sections: [
            {
              id: "thumbnails",
              content: (item: ItemType) => (
                <div className="flex gap-2">
                  <img
                    src={item.thumbnails}
                    alt={item.title}
                    className="rounded"
                    loading="lazy"
                    width={100}
                  />
                  <div>
                    <Box>{item.description}</Box>
                    <Box variant="small" color="text-status-info">
                      Published On:
                      {new Date(item.publishedAt * 1000).toLocaleDateString(
                        "en-US",
                        { day: "numeric", month: "long", year: "numeric" },
                      )}
                    </Box>
                  </div>
                </div>
              ),
            },
            {
              id: "description",
              content: (item: ItemType) => (
                <div onClick={(e) => e.stopPropagation()}>
                  <FormField label="Select Channel Type">
                    <Select
                      selectedOption={
                        selectedOptions[item.channel_id] || {
                          label: "",
                          value: "",
                        }
                      }
                      onChange={({ detail }) => {
                        setSelectedOptions((prevOptions) => ({
                          ...prevOptions,
                          [item.channel_id]: {
                            label: detail.selectedOption.label ?? "",
                            value: detail.selectedOption.value,
                          },
                        }));
                      }}
                      options={[
                        { label: "Company", value: "Company" },
                        { label: "Franchise", value: "Franchise" },
                        { label: "Gaming", value: "Gaming" },
                        { label: "General", value: "General" },
                        { label: "Movies", value: "Movies" },
                        { label: "News", value: "News" },
                        { label: "OTT Platform", value: "OTT" },
                        { label: "Television", value: "TV" },
                      ]}
                    />
                  </FormField>
                </div>
              ),
            },
          ],
        }}
        cardsPerRow={[{ cards: 1 }, { minWidth: 500, cards: 2 }]}
        items={
          searchInput.length > 0 ? isSomeOrElse(channelsSearchData, []) : []
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="channel_id"
        visibleSections={["description", "thumbnails"]}
        empty={
          <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
            No channels found
          </Box>
        }
      />
    </SpaceBetween>
  );
};
