type ChannelTrendsItemType = {
  channel_id: string;
  subscriberCount: number;
  timestamp: number;
  videoCount: number;
  viewCount: number;
};

export const mapToUpdateTrends = (data: Array<ChannelTrendsItemType>) => {
  const trendsData = data.map((trend) => ({
    ...trend,
    timestamp: trend.timestamp * 1000,
  }));

  return trendsData.map((trend, index, array) => {
    if (index === 0) {
      return {
        ...trend,
        subscriberGrowth: 0,
        videoGrowth: 0,
      };
    }

    const previousTrend = array[index - 1];
    return {
      ...trend,
      subscriberGrowth: trend.subscriberCount - previousTrend.subscriberCount,
      videoGrowth: trend.videoCount - previousTrend.videoCount,
    };
  });
};
