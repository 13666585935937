import {
  Box,
  ColumnLayout,
  Container,
  FormField,
  Icon,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import React, { FC } from "react";
import shortenInteger from "../../../../../utils/shortenInteger";
import {
  getSentimentCategory,
  getSentimentColor,
} from "../../../../youtube/channelPage/components/VideoList/utils";
import { TrailerResponseData } from "../../../api/types";
import { HorizontalGuage } from "../../../../../components/HorizontalGuage";

interface TrailerProps {
  video: TrailerResponseData;
  showItemPageLink?: boolean;
}

export const TrailerCard: FC<TrailerProps> = ({ video, showItemPageLink=true }) => {
  return (
    <Container
      disableHeaderPaddings
      disableContentPaddings
      fitHeight
      media={{
        content: (
          <iframe
            title={video.ip}
            src={`https://www.youtube.com/embed/${video.videoId}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        ),
        height: 350,
        position: "top",
      }}
      footer={
        <>
          <ColumnLayout minColumnWidth={100} columns={3} variant="text-grid">
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Views
              </Box>
              <Box textAlign="center">{shortenInteger(video?.views)}</Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Likes
              </Box>
              <Box textAlign="center">{shortenInteger(video?.likes)}</Box>
            </div>
            <div>
              <Box variant="awsui-key-label" textAlign="center">
                Comments
              </Box>
              <Box textAlign="center"> {shortenInteger(video?.comments)} </Box>
            </div>
          </ColumnLayout>
        </>
      }
    >
      <div className="p-4 flex flex-col justify-between gap-4">
        <div>
          <div className="flex items-center gap-2">
            <Link
              fontSize="heading-m"
              href={`/youtube/videos/${video.videoId}`}
              target="_blank"
            >
              <span className="line-clamp-1 font-bold">
                {video.title}
              </span>
            </Link>
            {video?.madeForKids && (
              <span className="inline-flex items-center rounded-md bg-green-700 px-1 py-0.5 text-xs font-medium text-white ring-1 ring-inset ring-green-400 text-nowrap">
                Kids
              </span>
            )}
            {video?.official && (
              <span className="inline-flex items-center rounded-md bg-amber-600 px-1 py-0.5 text-xs font-medium text-white ring-1 ring-inset ring-amber-400 text-nowrap">
                Official
              </span>
            )}
          </div>
          <div className="text-xs dark:text-slate-400 text-slate-600">
            <span>Published by </span>
            <Link
              fontSize="heading-xs"
              href={`/youtube/channels/${video.channelId}`}
            >
              {video.channel}
            </Link>
            <span> on </span>
            {new Date(video.publishedAt * 1000).toLocaleDateString("en-US", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </div>
          {/*video.sentimentScore != null && (
            <Box
              variant="small"
              color={getSentimentColor(video.sentimentScore)}
            >
              {`${getSentimentCategory(video.sentimentScore)} sentiment (score: ${video.sentimentScore.toFixed(3)})`}
            </Box>
          )*/}
        </div>
        <div className="grow">
          <ColumnLayout columns={showItemPageLink ? 2 : 1}>
            {(showItemPageLink && video.ip_id != null) && (
              <FormField label="Associated Title">
                <Link
                  fontSize="heading-xs"
                  href={`/item/${video.ip_id}`}
                >
                  <div className="flex gap-2"><span className="line-clamp-1">{video.ip}</span> <Icon name="external" /></div>
                </Link>
              </FormField>
            )}
            {video.sentimentScore != null && (
              <FormField label="Sentiment">
                <HorizontalGuage 
                  value={video.sentimentScore} 
                  text={`${getSentimentCategory(video.sentimentScore)} (${video.sentimentScore.toFixed(2)})`} 
                  min={-1} 
                  max={1} 
                  colorText
                />
              </FormField>
            )}
          </ColumnLayout>
        </div>
      </div>
    </Container>
  );
};
