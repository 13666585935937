import { useQuery } from "react-query";
import { QUERY_KEYS } from "../constants";
import { getWeightage } from "../requests";

export const useGetWikipediaWeightage = () => {
  const { isLoading, data, error } = useQuery(
    QUERY_KEYS.GET_WIKIPEDIA_PREFERENCE,
    () => getWeightage("wikipedia"),
  );

  return {
    data,
    isLoading,
    error,
  };
};
