import { useEffect, useState } from "react";
import { useGetBOData } from "./useGetBoByStudio";

interface Movie {
  ip: string;
  ip_id: string;
  release_date: string;
  year: number;
  Domestic: number;
  International?: number;
  Worldwide: number;
  Budget?: number;
}
interface ProcessedData {
  distributor: string;
  domestic: number;
  international: number;
  productionBudget: number;
  count: number;
  worldwide?: number;
  domesticMarketShare?: number;
  internationalMarketShare?: number;
  profitMargin?: number;
  movies: Movie[];
}

interface BODataProcessingResult {
  isLoading: boolean;
  processedData: ProcessedData[] | null;
  error: { name: string; message: string } | null;
}

export const useBODataProcessing = (year: number): BODataProcessingResult => {
  const { isLoading, data, error } = useGetBOData(year);
  const [processedData, setProcessedData] = useState<ProcessedData[] | null>(null);

  useEffect(() => {
    if (data && data.length > 0) {
      const cleanedData: ProcessedData[] = data.flatMap((item) => {
        // Remove duplicate distributors
        const uniqueDistributors = Array.from(new Set(item.distributor));
        
        return uniqueDistributors.map((distributor) => ({
          distributor,
          domestic: parseInt(item.Domestic, 10) || 0,
          international: parseInt(item.International, 10) || 0,
          productionBudget: parseInt(item.Budget, 10) || 0,
          count: 1,
          worldwide: parseInt(item.Worldwide, 10) || 0,
          movies: [
            {
              ip: item.ip,
              ip_id: item.ip_id,
              release_date: item.release_date,
              year: item.year,
              Domestic: item.Domestic,
              International: item.International,
              Worldwide: item.Worldwide,
              Budget: item.Budget,
            },
          ],
        }));
      });

      const groupedData = cleanedData.reduce<Record<string, ProcessedData>>((acc, item) => {
        const distributor = item.distributor;

        if (!acc[distributor]) {
          acc[distributor] = {
            distributor,
            domestic: 0,
            international: 0,
            productionBudget: 0,
            count: 0,
            worldwide: 0,
            domesticMarketShare: 0,
            internationalMarketShare: 0,
            profitMargin: 0,
            movies: [],
          };
        }

        acc[distributor].domestic += item.domestic;
        acc[distributor].international += item.international;
        acc[distributor].productionBudget += item.productionBudget;
        acc[distributor].count += 1;
        acc[distributor].worldwide += item.worldwide;
        acc[distributor].movies.push(...item.movies);

        if (acc[distributor].productionBudget > 0) {
          acc[distributor].profitMargin = ((acc[distributor].worldwide - acc[distributor].productionBudget) / acc[distributor].productionBudget) * 100;
        } else {
          acc[distributor].profitMargin = 0;
        }

        return acc;
      }, {});

      const totalDomestic = Object.values(groupedData).reduce( (acc, studio) => acc + studio.domestic, 0 );
      const totalInternational = Object.values(groupedData).reduce( (acc, studio) => acc + studio.international, 0 );

      const sortedData = Object.values(groupedData).sort(
        (a, b) => b.domestic + b.international - a.domestic - a.international
      );

      sortedData.forEach(studio => {
        studio.domesticMarketShare = totalDomestic ? (studio.domestic / totalDomestic) * 100 : 0;
        studio.internationalMarketShare = totalInternational ? (studio.international / totalInternational) * 100 : 0;
      });

      setProcessedData(sortedData);
    }
  }, [data, year]);

  const formattedError = error && error instanceof Error ? { name: error.name, message: error.message } : null;

  return { isLoading, processedData, error: formattedError };
};
