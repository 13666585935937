import { useQuery, UseQueryOptions } from "react-query";
import { getNetflixWeeklySummary } from "../api/request";

interface NetflixWeeklySummary {
    timestamp: string;
    date: string;
    Films_en_views: string;
    Films_en_hours: string;
    Films_non_en_views: string;
    Films_non_en_hours: string;
    TV_en_views: string;
    TV_en_hours: string;
    TV_non_en_views: string;
    TV_non_en_hours: string;
}

type NetflixWeeklySummaryError = Error;

export const useFetchSummaryData = (
    options?: UseQueryOptions<NetflixWeeklySummary[], NetflixWeeklySummaryError>
) => {
    const {
        data,
        isLoading,
        error,
        refetch
    } = useQuery<NetflixWeeklySummary[], NetflixWeeklySummaryError>(
        "netflixWeeklySummary",
        async () => {
            const response = await getNetflixWeeklySummary();
            return response.data;
        },
        options
    );

    return { data, isLoading, error, refetch };
};

export default useFetchSummaryData;
