import { Alert, Drawer, ExpandableSection } from '@cloudscape-design/components';
import React from 'react';

export const InfoDrawer = () => {
  return (
    <Drawer header="Information">
      <div className="flex flex-col gap-6">
        <section>
          <h2 className="text-xl font-bold mb-3">What is YouTube Trending?</h2>
          <p className="mb-2">Trending on YouTube is a feature that helps viewers discover what's currently popular and relevant on the platform and in the world. It aims to surface videos and Shorts that appeal to a wide range of viewers, showcasing both predictable trends and unexpected viral content.</p>

        </section>
        <ExpandableSection defaultExpanded={true} headerText="Ranking Factors for Trending Videos">
          <p className="mb-2">YouTube's Trending algorithm aims to balance various considerations when ranking videos:</p>
          <ul className="list-disc pl-5 mb-2">
            <li><strong>Wide appeal:</strong> Videos that can engage a broad audience across different demographics.</li>
            <li><strong>Content quality:</strong> Videos that are not misleading, clickbaity, or sensational.</li>
            <li><strong>Relevance:</strong> Content that captures the breadth of what's happening on YouTube and in the world.</li>
            <li><strong>Creator diversity:</strong> Showcasing a variety of creators to represent the platform's diverse community.</li>
            <li><strong>Novelty:</strong> Content that is surprising, innovative, or presents new perspectives.</li>
          </ul>
          <p>By considering these factors, Trending aims to create a list that is both interesting and representative of the platform's content ecosystem.</p>
        </ExpandableSection>

        <ExpandableSection defaultExpanded={true} headerText="Ranking Signals and How They Work">
          <p className="mb-2">To determine which videos appear on Trending, YouTube's algorithm considers multiple signals, including:</p>
          <ul className="list-disc pl-5 mb-2">
            <li><strong>View count:</strong> The total number of views a video has received.</li>
            <li><strong>"Temperature" or view velocity:</strong> How quickly the video is generating views, which can indicate rising popularity.</li>
            <li><strong>View sources:</strong> Where the views are coming from, including both within YouTube and external sources.</li>
            <li><strong>Video age:</strong> How recently the video was uploaded, favoring newer content.</li>
            <li><strong>Channel performance:</strong> How the video performs compared to recent uploads from the same channel.</li>
          </ul>
          <p className="mb-2">These signals are combined to produce a list of videos that showcases what's happening on YouTube while remaining relevant to viewers and reflective of the platform's content diversity.</p>
          </ExpandableSection>
          <Alert
            statusIconAriaLabel="Info"
            header="Important note:"
          >
            The video with the highest view count on a given day may not necessarily be #1 on Trending. The algorithm balances various factors, which means videos with fewer views might sometimes rank higher than those with more views.
          </Alert>
        
      </div>
    </Drawer>
  );
};
