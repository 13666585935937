import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
    data: [],
    loading: false,
    message: "",
    completed: false,
    hasError: false,
    hasFetched: false,
};

const createManagementDataSlice = (group) => {
    const fetchData = createAsyncThunk(
        `${group}ManagementData/fetchData`,
        async () => {
            try {
                const response = await axiosInstance.request({
                    url: `ipmanagement/v2/iplist?group=${group}&exclude=ip_list,tracked,wikidata_id,occurence,release_date,status`,
                    method: "GET",
                });
                return response;
            } catch (error) {
                throw error;
            }
        }
    );

    const managementDataSlice = createSlice({
        name: `${group}ManagementData`,
        initialState,
        reducers: {
            clearData: () => initialState,
        },
        extraReducers: (builder) => {
            builder
                .addCase(fetchData.pending, (state) => {
                    state.loading = true;
                })
                .addCase(fetchData.fulfilled, (state, action) => {
                    state.loading = false;
                    state.completed = true;
                    state.data = action.payload.data;
                    state.hasFetched = true;
                })
                .addCase(fetchData.rejected, (state, action) => {
                    state.loading = false;
                    state.completed = true;
                    state.hasError = true;
                    state.hasFetched = true;
                    state.message = action.error.message;
                });
        },
    });

    return {
        reducer: managementDataSlice.reducer,
        actions: managementDataSlice.actions,
        asyncThunk: fetchData,
    };
};

export const ipManagement = createManagementDataSlice("nbcu");
export const pantheonManagement = createManagementDataSlice("Pantheon");