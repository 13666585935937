import { useMemo } from "react";
import {
  CHART_TYPES,
  useGetChartTheme,
} from "../../../../../../hooks/UseTheme/useGetChartTheme";

const tooltip = {
  renderer: ({ datum }) => {
    const formattedDate = new Date(datum.release_date).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "long",
        year: "numeric",
      },
    );

    return {
      title: `<span style="font-size: 16px; color: black">${datum.name}</span>`,
      content: `
                  <b>Release Date:</b> ${formattedDate} <br />
                  <b>Votes:</b> ${datum.imdb_vote.toLocaleString()}<br/>
                  <b>Rating:</b> ${datum.imdb_rating}<br/>
              `,
    };
  },
  position: {
    type: "pointer",
    xOffset: 100,
    yOffset: -30,
  },
};

export const useChartConfig = (data) => {
  const { theme } = useGetChartTheme(CHART_TYPES.VIVID);
  const options = useMemo(
    () =>
      ({
        theme: theme,
        animation: {
          enabled: true,
        },
        zoom: {
          enabled: true,
        },
        title: {
          text: `IMDb Ratings and Votes Analysis - ${data?.length} Titles`,
        },
        subtitle: {
          text: "Assessing the Impact of Release Dates on Audience Ratings and Votes; Star Size Indicates Vote Count",
        },
        series: [
          {
            type: "bubble",
            data: data,
            xKey: "release_date",
            xName: "Release Date",
            sizeKey: "imdb_vote",
            sizeName: "Votes",
            yKey: "imdb_rating",
            yName: "Rating",
            labelKey: "name",
            shape: "star",
            fill: "gold",
            fillOpacity: 0.6,
            stroke: "gold",
            strokeWidth: 1,
            highlightStyle: {
              item: {
                fill: "red",
                fillOpacity: 0.8,
                strokeWidth: 0,
              },
              series: {
                dimOpacity: 0.4,
              },
            },
            tooltip,
          },
        ],
        axes: [
          {
            position: "bottom",
            type: "time",
            keys: ["release_date"],
            label: {
              autoRotate: true,
              minSpacing: 10,
              avoidCollisions: true,
              format: "%b %Y",
            },
            gridLine: {
              enabled: false,
            },
            line: {
              enabled: false,
            },
            title: {
              text: "Release Dates →",
            },
          },
          {
            position: "left",
            type: "number",
            keys: ["imdb_rating"],
            line: {
              width: 1,
            },
            gridLine: {
              enabled: false,
            },
            title: {
              text: "IMDb Rating →",
            },
          },
        ],
      } as unknown),
    [data],
  );

  return { options };
};
