import {
  AppLayout,
  BreadcrumbGroup
} from "@cloudscape-design/components";
import React, { createContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import CategoryDiscovery from "./discovery";

export const DiscoveryContext = createContext(null);

const title = "Discovery";
const breadcrumbs = [
  { text: "Settings" },
  { text: "Data Mapping" },
  { text: title },
];

function DiscoverIndex() {
  const [selectedVertical, setSelectedVertical] = useState({
    label: "All",
    value: "all",
  });

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AppLayout
        stickyNotifications
        toolsHide
        contentType="table"
        ariaLabels={{ navigationClose: "close" }}
        navigationHide
        content={
          <DiscoveryContext.Provider
            value={{ selectedVertical, setSelectedVertical }}
          >
            <CategoryDiscovery />
          </DiscoveryContext.Provider>
        }
        breadcrumbs={
          <BreadcrumbGroup
            items={breadcrumbs}
            expandAriaLabel="Show path"
            ariaLabel="Breadcrumbs"
          />
        }
      />
    </>
  );
}

export default DiscoverIndex;
