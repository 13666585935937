import { Box, Table, TokenGroup } from "@cloudscape-design/components";
import _ from "lodash";
import React from "react";
import CsvDownloader from "../../../../../components/CsvDownloader";

export const GetPanelContent = ({
  items,
  setItems,
  selectedItems,
  setSelectedIps,
}) => {
  return getPanelContentComparison(
    items,
    setItems,
    selectedItems,
    setSelectedIps,
  );
};

export const getPanelContentComparison = (
  items,
  setItems,
  selectedItems,
  setSelectedIps,
) => {
  if (!items.length) {
    return <>No items selected</>;
  }

  const sortedItemsByViews = _.sortBy(items, "views").reverse();

  const rankedItemsByViews = sortedItemsByViews.map((item, index) => {
    return { ...item, rank: index + 1 };
  });

  const keyHeaderMap = {
    // rank: "Rank",
    vertical: "Vertical",
    a03: "A03",
    fandom: "Fandom",
    gtrends: "Google Trends",
    reddit: "Reddit",
    twitter: "Twitter",
    wiki: "Wikipedia",
    youtube: "YouTube",
  };
  const transformedData = [
    // "rank",
    "vertical",
    "a03",
    "fandom",
    "gtrends",
    "reddit",
    "twitter",
    "wiki",
    "youtube",
  ].map((key) => {
    const data = { comparisonType: keyHeaderMap[key] };

    rankedItemsByViews.forEach((item) => {
      if (key === "rank") {
        data[item.ip_id] = item.rank;
      } else {
        data[item.ip_id] = item[key];
      }
    });

    return data;
  });
  const columnDefinitions = [
    {
      id: "comparisonType",
      header: "",
      cell: ({ comparisonType }) => <b>{comparisonType}</b>,
    },
    ...rankedItemsByViews.map(({ ip, ip_id }) => ({
      ip,
      header: ip,
      cell: (item) => {
        if (Array.isArray(item[ip_id])) {
          return item[ip_id].join(", ");
        }
        return item[ip_id];
      },
    })),
  ];

  return (
    <Box padding={{ bottom: "l" }}>
      <div className="flex flex-row justify-between items-start">
        <div className="flex-1 flex-wrap">
          <TokenGroup
            onDismiss={({ detail: { itemIndex } }) => {
              setItems(items.filter((d) => d.ip_id !== items[itemIndex].ip_id));
              setSelectedIps(
                selectedItems.filter((d) => d.ip_id !== items[itemIndex].ip_id),
              );
            }}
            items={items.map((i) => ({
              ...i,
              label: i.ip,
              dismissLabel: "Remove " + i.ip,
            }))}
          />
        </div>

        <div className="flex flex-row gap-x-1 items-center">
          {/*  <Button
            onClick={() => {
              setItems([]);
              setSelectedIps([]);
            }}
          >
            Clear All Filters
          </Button> */}
          {transformedData && transformedData.length > 0 && (
            <CsvDownloader data={transformedData} />
          )}
        </div>
      </div>
      <Table
        variant="compact"
        items={transformedData}
        columnDefinitions={columnDefinitions}
      />
    </Box>
  );
};
