import { uniqueId } from "lodash";
import { removeFlashMessage } from "../modules/common/redux/flash-action";

const createFlashMessage = ({ type, message }) => {
  const id = uniqueId("flash-");
  switch (type) {
    case "success": {
      return {
        type: "success",
        content: message,
        dismissible: true,
        dismissLabel: "Dismiss message",
        id: id,
        onDismiss: () => removeFlashMessage(id),
      };
    }
    case "error": {
      return {
        type: "error",
        content: message,
        dismissible: true,
        dismissLabel: "Dismiss message",
        id: id,
        onDismiss: () => removeFlashMessage(id),
      };
    }
    case "warning": {
      return {
        type: "warning",
        content: message,
        dismissible: true,
        dismissLabel: "Dismiss message",
        id: id,
      };
    }
    case "inProgress": {
      return {
        type: "success",
        loading: true,
        content: message,
        dismissible: true,
        dismissLabel: "Dismiss message",
        id: id,
        onDismiss: () => removeFlashMessage(id),
      };
    }

    default: {
      return {
        type: "info",
        content: message,
        dismissible: true,
        dismissLabel: "Dismiss message",
        id: id,
        onDismiss: () => removeFlashMessage(id),
      };
    }
  }
};

export default createFlashMessage;
