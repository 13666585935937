// snackbar-context.js
import React, { useState } from "react";
const SnackbarContext = React.createContext({
    msg:"",
    title:"",
    type:"",
    duration:3000,
    isDisplayed: false,
    displayMsg: (title,msg,type, duration) => { },
    onClose: () => { },
});
export default SnackbarContext;
let timer;
export const SnackBarContextProvider = (props) => {
    const [msg, setMsg] = useState("");
    const [title, setTitle] = useState("");
    const [type, setType] = useState("");
    const [duration, setDuration] = useState(3000);
    const [isDisplayed, setIsDisplayed] = useState(false);

    const displayHandler = (title,msg,type, duration) => {
        setMsg(msg);
        setTitle(title);
        setType(type);
        setIsDisplayed(true);
        setDuration(duration);
        timer = setTimeout(() => {
            closeHandler();
          }, duration); // close snackbar after 3 seconds
    };
    const closeHandler = () => {
        clearTimeout(timer);
        setIsDisplayed(false);
    };

    return (
        <SnackbarContext.Provider
            value={{
                msg, 
                title, 
                type,
                displayMsg: displayHandler,
                isDisplayed,
                onClose: closeHandler,
            }}
        >
            {props.children}
        </SnackbarContext.Provider>
    );
}