import React from 'react';
import LogoIcon from './icons/logo.png';

// eslint-disable-next-line react/prop-types
export default function Logo({ height = 12, width = 12 }) {
  return (
    <div className={`h-${height} w-${width} object-cover`}>
      <img src={LogoIcon} alt="LogoIcon" />
    </div>
  );
}
