import moment from "moment";
import React, { useState } from "react";

import { Button, Spinner, StatusIndicator } from "@cloudscape-design/components";
import axiosInstance from "../../../../utils/axios";
import { ResponseDataItemType } from "./api/type";

const StatusColumn = ({ status }: { status: string }) => {
  switch (status) {
    case "completed":
      return <StatusIndicator type="success" />;
    case "failed":
      return <StatusIndicator type="error" />;
    case "running":
      return <StatusIndicator type="in-progress" />;
    default:
      return null;
  }
};

const DownloadButton = ({ e, activeTabId }: { e: ResponseDataItemType; activeTabId: string }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleExport = async () => {
    setIsLoading(true);
    try {
      const { vertical, filename, timestamp, organization, user_name } = e;

      const filepath =
        activeTabId === "my-reports"
          ? `offline_report/${user_name}/${filename}_${timestamp}.parquet`
          : `offline_report/${organization}/${filename}_${timestamp}.parquet`;

      const response = await axiosInstance.post("/export", {
        filepath,
        include: [],
        exclude: [],
      });

      if (response.data) {
        const presignedUrl = response.data;

        const link = document.createElement("a");
        link.href = presignedUrl;
        link.setAttribute(
          "download",
          filepath.split("/").pop() || "export.csv",
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  if (e.status !== "completed") {
    return <Button disabled iconName="download" />;
  }

  return isLoading ? (
    <Spinner size="normal" />
  ) : (
    <Button
      variant="normal"
      iconName="download"
      onClick={handleExport}
    />
  );
};

const getReportTypeLabel = (reportType: string): string => {
  switch (reportType) {
    case "nonott":
      return "Non-OTT Titles";

    default:
      return reportType;
  }
};

export const columnDefinitions = (activeTabId: string) => [
  {
    id: "timestamp",
    header: "Report Generated On",
    width: 210,
    cell: (e: ResponseDataItemType) => {
      const timestamp = parseInt(e.timestamp, 10) * 1000;
      return <span>{moment(timestamp).format("MM/DD/YYYY, h:mm:ss a")}</span>;
    },
    sortingField: "timestamp",
  },
  {
    id: "filename",
    header: "Report Name",
    minWidth: 280,
    cell: (e: ResponseDataItemType) => {
      const reportTypeLabel = getReportTypeLabel(e.report_type);
      return (
        <div style={{
          wordWrap: 'break-word',
          whiteSpace: 'normal',
        }}
          className="flex flex-col">
          <span>{`${e.filename} | ${reportTypeLabel}`}</span>
          <span className="text-slate-400 dark:text-slate-600 text-xs">
            Report generated for: {`${moment(e.start_date).format("MMM DD, YYYY")} - ${moment(e.end_date).format("MMM DD, YYYY")}`}
          </span>
        </div>
      );
    },
    sortingField: "filename",
  },
  {
    id: "platform",
    header: "Platforms",
    width: 180,
    cell: (e: ResponseDataItemType) => {
      const platforms = e.platform.join(", ");
      return (
        <div style={{
          maxWidth: '180px',
          wordWrap: 'break-word',
          whiteSpace: 'normal',
        }}>
          {platforms}
        </div>
      );
    },
    sortingField: "platforms",
  },
  {
    id: "vertical",
    header: "Category",
    width: 120,
    cell: (e: ResponseDataItemType) => <span>{e.vertical}</span>,
    sortingField: "vertical",
  },
  {
    id: "user_name",
    header: "Created By",
    width: 180,
    cell: (e: ResponseDataItemType) => <span>{e.user_name}</span>,
    sortingField: "user_name",
  },
  {
    id: "status",
    header: "Status",
    width: 100,
    cell: (e: ResponseDataItemType) => <StatusColumn status={e.status} />,
    sortingField: "status",
  },
  {
    id: "download",
    header: "Download",
    width: 140,
    cell: (e: ResponseDataItemType) => (
      <DownloadButton e={e} activeTabId={activeTabId} />
    ),
  },
];

export const getVisibleContentPreference = () => {
  const preferences = [
    {
      id: "timestamp",
      label: "Report Generated On",
      visible: true,
    },
    {
      id: "filename",
      label: "Report Name",
      visible: true,
    },
    {
      id: "vertical",
      label: "Category",
      visible: true,
    },
    {
      id: "user_name",
      label: "Created By",
      visible: true,
    },
    {
      id: "status",
      label: "Status",
      visible: true,
    },
    {
      id: "download",
      label: "Download",
      visible: true,
    }

  ];


  return preferences;
};
