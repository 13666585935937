import { Categories, Category } from "./constants";


export const getAllCategories = (categories: Categories): Category[] => {
  let result: Category[] = [];

  for (let key in categories) {
    if (typeof categories[key] === "object" && categories[key] !== null && categories[key].value === undefined) {
      result = result.concat(getAllCategories(categories[key]));
    } else {
      result.push(categories[key]);
    }
  }

  return result;
};