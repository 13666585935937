import "ag-grid-enterprise";
import React from "react";
import { PlatformTrendsCharts } from "../../components/PlatformTrendsCharts";
import { getWikipediaTrendsData } from "../api/request";
import {
  defaultCategory as defCategory,
  wikiCategoryOptions,
  wikipediaApiParams,
} from "../constants";
import {
  additionalPlatformColumns,
  corePlatformColumns,
  defaultColumn,
} from "../utils/gridConfig";

export const WikipediaTrendsCharts = (
  {
    defaultCategory=defCategory,
    setSelectedVertical,
  }
) => {
  return (
    <PlatformTrendsCharts
      apiParams={wikipediaApiParams}
      defaultColumn={defaultColumn}
      defaultCategory={defaultCategory}
      categoryOptions={wikiCategoryOptions}
      corePlatformColumns={corePlatformColumns}
      fetchMethod={getWikipediaTrendsData}
      setSelectedVertical={setSelectedVertical}
      additionalPlatformColumns={additionalPlatformColumns}
    />
  );
};
