import Autosuggest from "@cloudscape-design/components/autosuggest";
import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import axiosInstance from "../../../utils/axios";
import { DataMappingContext } from "../id-mapping";

const AutosuggestEditor = forwardRef((props, ref) => {
  const { selectedVertical } = useContext(DataMappingContext);
  const [optionValue, setOptionValue] = useState(null);
  const [options, setOptions] = useState([]);
  const [status, setStatus] = useState("pending");

  useEffect(() => {
    setOptionValue(props?.value);
  }, [props?.value]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        const item = options?.find((option) => option.tmsId === optionValue);

        return item?.tmsId
          ? JSON.stringify({ tmsId: item?.tmsId, emsId: item?.emsId })
          : JSON.stringify({ tmsId: "", emsId: "" });
      },
    };
  });

  const fetchSuggestions = async (filteringText) => {
    setStatus("loading");
    try {
      const response = await axiosInstance.get("/datamapping/tmsid/search", {
        params: {
          query: filteringText,
          vertical: selectedVertical.value.toLowerCase(),
        },
      });
      setOptions(response.data);
      setStatus("success");
    } catch (error) {
      console.error("Error fetching suggestions:", error);
      setStatus("error");
    }
  };

  const handleLoadItems = ({ detail: { filteringText } }) => {
    if (!filteringText) return;
    fetchSuggestions(filteringText);
  };

  const handleChange = (event) => {
    setOptionValue(event.detail.value);
  };

  return (
    <Autosuggest
      value={optionValue}
      options={
        options?.map((option) => ({
          value: option?.tmsId,
          label: `${option?.title}(${option?.releaseYear})`,
        })) || []
      }
      statusType={status}
      onChange={handleChange}
      onLoadItems={handleLoadItems}
    />
  );
});

export default AutosuggestEditor;
