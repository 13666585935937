import { FormField } from "@cloudscape-design/components";
import Input, { InputProps } from "@cloudscape-design/components/input";
import React, { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

interface TextFieldProps {
  name: string;
  label: string;
  placeholder: string;
  type: InputProps.Type;
}

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  placeholder,
  type,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        return (
          <div>
            <FormField label={label}>
              <Input
                type={type}
                placeholder={placeholder}
                value={field.value}
                onChange={({ detail }) => field.onChange(detail.value)}
              />
            </FormField>
          </div>
        );
      }}
    />
  );
};
