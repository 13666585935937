import React, { useEffect, useState } from "react";
import { DatePicker, FormField, Input, Select } from "@cloudscape-design/components";
import { FILTER_OPERATIONS } from "../../../config-global";
import moment from "moment";

const operatorOptions = [
  { label: "Before", value: "before", operator: FILTER_OPERATIONS.LESS_THAN },
  { label: "After", value: "after", operator: FILTER_OPERATIONS.GREATER_THAN },
  { label: "Before (Relative)", value: "before_rel", operator: FILTER_OPERATIONS.LESS_THAN },
  { label: "After (Relative)", value: "after_rel", operator: FILTER_OPERATIONS.GREATER_THAN },
];

const relativeUnitOptions = [
  { label: "days ago", value: "days" },
  { label: "weeks ago", value: "weeks" },
  { label: "months ago", value: "months" },
  { label: "years ago", value: "years" },
];

export const DateFilter = ({
  filterObject,
  setFilterObject,
}) => {

  const [value, setValue] = useState("");
  const [selectedOperator, setSelectedOperator] = useState(operatorOptions.find(o => o.operator === filterObject.type) ?? operatorOptions[0]);
  const [relativeValue, setRelativeValue] = useState(6);
  const [relativeUnit, setRelativeUnit] = useState(relativeUnitOptions[2]);

  useEffect(() => {
    if (filterObject.value) {
      const value = filterObject.value[0];
      if (value.startsWith("rel")) {
        const [_, relativeValue, relativeUnit] = value.split(" ");
        setRelativeValue(relativeValue);
        setRelativeUnit(relativeUnitOptions.find(o => o.value === relativeUnit) ?? relativeUnitOptions[2]);
        setSelectedOperator(operatorOptions.find(o => o.operator === filterObject.filterOperation && o.value.endsWith("_rel")) ?? operatorOptions[0]);
      } else {
        setValue(value);
        setSelectedOperator(operatorOptions.find(o => o.operator === filterObject.filterOperation && !o.value.endsWith("_rel")) ?? operatorOptions[0]);
      }
    } else {
      setValue("");
      setSelectedOperator(operatorOptions[0]);
    }
  }, [filterObject.value]);

  return (
    <FormField label={
      <div className="flex justify-between">
        <div>{filterObject.name}</div>
        <div className="flex items-center gap-x-2 font-normal">
          <select 
            value={selectedOperator.value} 
            onChange={e => {
              const selectedOption = operatorOptions.find(option => option.value === e.target.value);
              setSelectedOperator(selectedOption);
              setFilterObject({
                ...filterObject,
                filterOperation: selectedOption.operator,
                value: selectedOption.value.endsWith("_rel") ? [`rel ${relativeValue} ${relativeUnit.value}`] : [moment(value).format("YYYY-MM-DD")],
              });
            }}
            className="p-0 pl-2 pr-4 text-xs bg-transparent border-0 focus:outline-none w-auto dark:text-slate-400 text-slate-600" 
            style={{ appearance: "none !important", backgroundPosition: "right 0rem center" }}
          >
            {operatorOptions.map(option => (
              <option key={option.value} value={option.value} className="dark:bg-slate-800 bg-white text-left">{option.label}</option>
            ))}
          </select>
        </div>
      </div>
    }>
      {selectedOperator.value.endsWith("_rel") ? (
        <div className="flex gap-2">
          <Input
            value={relativeValue}
            onChange={({ detail }) => {
              const numberValue = parseInt(detail.value);
              if (numberValue < 1) return;
              setRelativeValue(detail.value);
              setFilterObject({
                ...filterObject,
                value: [`rel ${detail.value} ${relativeUnit.value}`],
              });
            }}
            inputMode="numeric"
            type="number"
          />
          <Select 
            options={relativeUnitOptions}
            selectedOption={relativeUnit}
            onChange={({ detail }) => {
              setRelativeUnit(detail.selectedOption);
              setFilterObject({
                ...filterObject,
                value: [`rel ${relativeValue} ${detail.selectedOption.value}`],
              });
            }}
          />
        </div>
      ) : (
        <DatePicker 
          value={value}
          onChange={({ detail }) => {
            setValue(detail.value);
            setFilterObject({
              ...filterObject,
              value: [moment(detail.value).format("YYYY-MM-DD")],
            });
          }}
          placeholder="YYYY/MM/DD"
        />
      )}
    </FormField>
  );
};