import React from "react";
import { Box, Button, SpaceBetween } from "@cloudscape-design/components";

export function TableNoMatchState(props) {
  return (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <b>No matches</b>
          <Box variant="p" color="inherit">
            We can&apos;t find a match.
          </Box>
        </div>
        <Button onClick={props.onClearFilter}>Clear filter</Button>
      </SpaceBetween>
    </Box>
  );
}

export default TableNoMatchState;
