import React from "react";
import { Table, Link, Icon } from "@cloudscape-design/components";

const toHumanReadable = (str) => {
    return str
        .replace(/_/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};

const idNameMap = {
    homepage: "Homepage",
    ip_id: "IP ID",
    vertical: "Vertical",
    tmsId: "Gracenote ID",
    emsId: "EMS ID",
    rtUrl: "Rotten Tomatoes URL",
    rtId: "Rotten Tomatoes ID",
    imdb_id: "IMDb ID",
    wikidata_id: "Wikidata ID",
    tmdb_id: "TMDB ID",
    wikipedia: "Wikipedia Article",
    trends: "Google KGMID",
    youtube: "YouTube Query",
    youtubeChannel: "YouTube Channel",
    a03: "AO3 Tag",
    instagram: "Instagram Hashtags",
    wattpad: "Wattpad Tags",
    subreddit: "Subreddit",
    parentCompany: "Parent Company",
    twitter: "Twitter Query",
    tiktok: "TikTok Hashtags",
    fandom: "Fandom",
    igdb_id: "IGDB ID",
    twitch: "Twitch ID",
    discord: "Discord ID",
    steam: "Steam App ID",
};

const TagsAndIDsComponent = ({ itemData }) => {
    const tagsAndIDs = Object.keys(idNameMap)
        .map((key) => ({ property: key, value: itemData[key] }))
        .filter((item) => item.value !== null && item.value !== undefined);

    const renderValueCell = (item) => {
        if (!item.value) return null;

        switch (item.property) {
            case "imdb_id":
                return (
                    <Link
                        href={`https://www.imdb.com/title/${item.value}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                );
            case "wikidata_id":
                return (
                    <Link
                        href={`https://www.wikidata.org/wiki/${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                );
            case "wikipedia":
                return (
                    <Link
                        href={`https://en.wikipedia.org/wiki/${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                );
            case "tmdb_id":
                const baseUrl = itemData.vertical === "Movies"
                    ? "https://www.themoviedb.org/movie/"
                    : itemData.vertical === "Television"
                        ? "https://www.themoviedb.org/tv/"
                        : "";
                return (
                    <Link
                        href={`${baseUrl}${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                    
                );

            case "trends":
                return (
                    <Link
                        href={`https://trends.google.com/trends/explore?q=${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                );
            case "fandom":
                return (
                    <Link
                        href={`https://${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                );
            case "tiktok":
                if (Array.isArray(item.value)) {
                    return (
                        <div className="flex flex-wrap gap-4">
                            {item.value.map((handle, index) => (
                                <Link
                                    key={index}
                                    href={`https://www.tiktok.com/tag/${handle}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {handle} <Icon name="external" />
                                </Link>
                            ))}
                        </div>
                    );
                } else {
                    console.error("Unexpected data type for TikTok handle: ", item.value);
                    return <div>Invalid Data</div>;
                }
            case "homepage":
                return (
                    <Link href={item.value} target="_blank" rel="noopener noreferrer">
                        {item.value}
                        <Icon name="external" />
                    </Link>
                );
            case "rtUrl":
                return (
                    <Link href={item.value} target="_blank" rel="noopener noreferrer">
                        {item.value}
                        <Icon name="external" />
                    </Link>
                );
            case "a03":
                return (
                    <Link
                        href={`https://archiveofourown.org/tags/${item.value}/works`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                );
            case "instagram":
                if (Array.isArray(item.value) && item.value.length > 0) {
                    return (
                        <div className="flex flex-wrap gap-4">
                            {item.value.map((handle, index) => (
                                <Link
                                    key={index}
                                    href={`https://www.instagram.com/explore/tags/${handle.trim()}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {handle.trim()} <Icon name="external" />
                                </Link>
                            ))}
                        </div>
                    );
                } else {
                    return null;
                }
            case "subreddit":
                return (
                    <Link
                        href={`https://reddit.com/r/${item.value}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {item.value} <Icon name="external" />
                    </Link>
                );
            case "wattpad":
                if (typeof item.value === "string" && item.value.trim() !== "") {
                    const wattpadSearchTerms = item.value
                        .split(",")
                        .map((term) => term.trim());
                    return (
                        <div className="flex flex-wrap gap-4">
                            {wattpadSearchTerms.map((term, index) => (
                                <Link
                                    key={index}
                                    href={`https://wattpad.com/search/%23${term}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {term} <Icon name="external" />
                                </Link>
                            ))}
                        </div>
                    );
                } else {
                    return null;
                }
            case "youtube":
                if (typeof item.value === "string" && item.value.trim() !== "") {
                    const youtubeSearchQuery = encodeURIComponent(item.value.trim());
                    return (
                        <span>
                            <Link
                                href={`https://www.youtube.com/results?search_query=${youtubeSearchQuery}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.value} <Icon name="external" />
                            </Link>
                        </span>
                    );
                } else {
                    return null;
                }
            case "youtubeChannel":
                if (typeof item.value === "string" && item.value.trim() !== "") {
                    const youtubeChannelId = encodeURIComponent(item.value.trim());
                    return (
                        <span>
                            <Link
                                href={`https://www.youtube.com/channel/${youtubeChannelId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.value} <Icon name="external" />
                            </Link>
                        </span>
                    );
                } else {
                    return null;
                }


            default:
                return item.value;
        }
    };

    const columnDefinitions = [
        {
            id: "property",
            header: "Attribute",
            cell: (item) => {
                return (
                    <b>
                        {item.property in idNameMap ? idNameMap[item.property] : toHumanReadable(item.property)}
                    </b>
                );
            },
        },
        {
            id: "value",
            header: "Value",
            cell: (item) => renderValueCell(item),
        },
    ];

    return (
        <Table
            columnDefinitions={columnDefinitions}
            items={tagsAndIDs}
            sortingDisabled
            variant="embedded"
        />
    );
};

export default TagsAndIDsComponent;
