import { Icon, Link } from "@cloudscape-design/components";
import moment from "moment";
import React, { useCallback, useMemo } from "react";
import renderTrend from "../../../utils/trendIcon";
import { getCategories } from "../utils/categoryUtils";
import {
  dateColumnDefinition,
  getAdditionalColumnDefinition,
  getChartDefinition,
  numberColumnDefinition,
  numberFilterOptions,
  stringFilterOptions,
} from "../utils/gridUtils";

export const CHART_LABELS = {
  
  // Additional columns
  FRANCHISE: "Franchise",
  PLATFORM: "Platform",
  GENRE: "Genre",
  THEME: "Themes",
  CUSTOM_TAGS: "Custom Tags",
  DEVELOPER: "Developer",
  PUBLISHER: "Publisher",
  AGE_RATINGS: "Age Rating",
  COUNTRIES: "Countries",
  PLAYER_PERSPECTIVE: "Player Perspective",
  GAME_MODE: "Game Mode",
  DISTRIBUTORS: "Distributors",
  PRODUCTION: "Production",
  OTT: "OTT",

  // Sparkline Trend columns
  TREND: "Trend",
  VIEWERS_TREND: "Viewers Trends",
  BROADCASTERS_TREND: "Broadcasters Trends",
  FOLLOWERS_TREND: "New Followers Trends",
  POSITIVE_REVIEW_TRENDS: "Positive Review Trends",
  PLAYERS_COUNT_TRENDS: "Players Count Trends",
  RECOMMENDATIONS_TRENDS: "Recommendations Trends",
  PAGEVIEW_TREND: "PageView Trend",
  VIEWS_TREND: "Views Trend",
  LIKES_TREND: "Likes Trend",
  COMMENTS_TREND: "Comments Trend",
  VOTE_TREND: "Vote Trend",
  DOWNLOAD_TRENDS: "Download Trend",
  SCORE_TRENDS: "Score Trend",

  // Specific to platforms
  RANK: "Rank",
  SCORE: "Score",
  SCORE_CHANGE: "Score Change",
  TODAYS_VIEWS: "Todays Views",
  TODAY_VIEWS: "Today Views",
  TODAY_LIKES: "Today Likes",
  TODAY_COMMENTS: "Today Comments",
  TOTAL_VIEWS: "Total Views",
  VIEWS: "Views",
  LIKES: "Likes",
  COMMENTS: "Comments",
  AVERAGE_VIEWS: "Average Views",
  PEAK_VIEWS: "Peak Views",
  PEAK_DATE: "Peak Date",
  START_DATE: "Start Date",
  DAYS_SINCE_START: "Days Since Start",
  DAILY_VOTES: "Daily Votes",
  VOTES: "Votes",
  RATING: "Rating",
  DOWNLOADS: "Downloads",
  WEEKLY_SUM: "Weekly Sum",
  MONTHLY_SUM: "Monthly Sum",
  TOTAL_DOWNLOADS: "Total Downloads",
  TOTAL_DAYS: "Total Days",
  FIRST_SEEN: "First Seen",
  TOTAL_VOTES: "Total Votes",
  DAYS_IN_TOP_10: "Days in Top 10",
  AUDIENCE_VOTES: "Audience Votes",
  AUDIENCE_RATING: "Audience Rating",
  CRITIC_VOTES: "Critic Votes",
  CRITIC_RATING: "Critic Rating",
  DAILY_AUD_VOTES: "Daily Audience Votes",
  AVG_VIEWERS: "Avg Viewers",
  PEAK_VIEWERS: "Peak Viewers",
  AVG_BROADCASTERS: "Avg Broadcasters",
  PEAK_BROADCASTERS: "Peak Broadcasters",
  AT_PV: "All Time Peak Viewers",
  AT_PVD: "All Time Peak Viewers Date",
  MAX_FOLLOWERS: "Max Followers",
  NEW_FOLLOWERS: "New Followers",
  AVG_PLAYER_COUNT: "Avg Player Count",
  PEAK_PLAYER_COUNT: "Peak Player Count",
  RECOMMENDATIONS: "Recommendations",
  POSITIVE_REVIEWS: "Positive Reviews",
  NEGATIVE_REVIEWS: "Negative Reviews",
  WEEKLY_SCORE: "Weekly Score",
  MONTHLY_SCORE: "Monthly Score",
  DAILY_SCORE: "Daily Score",

  // Data labels
  WIKIPEDIA_PAGE_VIEWS: "Wikipedia Page Views",
  IMDB_VOTES: "IMDb Votes",
  IMDB_RATING: "IMDb Rating",
  YOUTUBE_VIEWS: "YouTube Views",
  ROTTEN_TOMATOES_VOTES: "Rotten Tomatoes Votes",
  ROTTEN_TOMATOES_RATING: "Rotten Tomatoes Rating",
  PIRACY_DOWNLOADS: "Piracy Downloads",
};

const platformColumnsMap = {
  [CHART_LABELS.RANK]: {
    ...numberColumnDefinition("rank", "All Time Rank"),
    maxWidth: 110,
    pinned: "left",
    cellRenderer: (params) =>
      renderTrend(params, "rank", "rank_change", "rank_trend"),
    valueGetter: (params) => parseInt(params.data.rank),
    valueFormatter: (params) => `${params.value}`,
  },
  [CHART_LABELS.DAILY_SCORE]: numberColumnDefinition("score", "Daily Score"),
  [CHART_LABELS.WEEKLY_SCORE]: numberColumnDefinition("weekly_sum", "Weekly Score"),
  [CHART_LABELS.MONTHLY_SCORE]: numberColumnDefinition("monthly_sum", "Monthly Score"),
  [CHART_LABELS.AVG_PLAYER_COUNT]: numberColumnDefinition("player_count_avg", "Avg Player Count"),
  [CHART_LABELS.PEAK_PLAYER_COUNT]: numberColumnDefinition("player_count_peak", "Peak Player Count"),
  [CHART_LABELS.RECOMMENDATIONS]: numberColumnDefinition("recommendations", "Recommendations"),
  [CHART_LABELS.POSITIVE_REVIEWS]: numberColumnDefinition("positive_reviews", "Positive Reviews"),
  [CHART_LABELS.NEGATIVE_REVIEWS]: numberColumnDefinition("negative_reviews", "Negative Reviews"),
  [CHART_LABELS.AVG_VIEWERS]: numberColumnDefinition("average_viewers", "Avg Viewers"),
  [CHART_LABELS.PEAK_VIEWERS]: numberColumnDefinition("peak_viewers", "Peak Viewers"),
  [CHART_LABELS.AVG_BROADCASTERS]: numberColumnDefinition("average_broadcasters", "Avg Broadcasters"),
  [CHART_LABELS.PEAK_BROADCASTERS]: numberColumnDefinition("peak_broadcasters", "Peak Broadcasters"),
  [CHART_LABELS.AT_PV]: numberColumnDefinition("at_pv", "All Time Peak Viewers"),
  [CHART_LABELS.AT_PVD]: dateColumnDefinition("at_pvd", "All Time Peak Viewers Date"),
  [CHART_LABELS.MAX_FOLLOWERS]: numberColumnDefinition("max_followers", "Max Followers"),
  [CHART_LABELS.NEW_FOLLOWERS]: numberColumnDefinition("new_followers", "New Followers"),
  [CHART_LABELS.TODAYS_VIEWS]: numberColumnDefinition( "today_views", "Daily Views", ),
  [CHART_LABELS.TOTAL_VIEWS]: numberColumnDefinition( "total_views", "Total Views", ),
  [CHART_LABELS.AVERAGE_VIEWS]: numberColumnDefinition( "avg_views", "Avg Views", ),
  [CHART_LABELS.PEAK_VIEWS]: numberColumnDefinition("peak_views", "Peak Views"),
  [CHART_LABELS.PEAK_DATE]: dateColumnDefinition("peak_date", "Peak Date"),
  [CHART_LABELS.START_DATE]: dateColumnDefinition("start_date", "Start Date"),
  [CHART_LABELS.DAYS_SINCE_START]: {
    field: "total_days",
    headerName: "Days Since Start",
    cellDataType: "number",
    filterParams: {
      filterOptions: numberFilterOptions,
    },
  },
  [CHART_LABELS.DAILY_VOTES]: numberColumnDefinition( "daily_votes", "Daily Votes", ),
  [CHART_LABELS.VOTES]: numberColumnDefinition("votes", "Votes"),
  [CHART_LABELS.RATING]: {
    field: "rating",
    headerName: "Rating",
    cellDataType: "number",
    filterParams: {
      filterOptions: numberFilterOptions,
    },
  },
  [ CHART_LABELS.DAILY_AUD_VOTES]: numberColumnDefinition( "daily_audience_votes", "Daily Audience Votes", ),
  [CHART_LABELS.AUDIENCE_VOTES]: numberColumnDefinition( "audience_votes", "Audience Votes", ),
  [CHART_LABELS.AUDIENCE_RATING]: {
    field: "audience_rating",
    headerName: "Audience Rating",
    cellDataType: "number",
    filterParams: {
      filterOptions: numberFilterOptions,
    },
  },
  [CHART_LABELS.CRITIC_VOTES]: numberColumnDefinition( "critic_votes", "Critic Votes", ),
  [CHART_LABELS.CRITIC_RATING]: {
    field: "critic_rating",
    headerName: "Critic Rating",
    cellDataType: "number",
    filterParams: {
      filterOptions: numberFilterOptions,
    },
  },
  [CHART_LABELS.DOWNLOADS]: numberColumnDefinition("downloads", "Downloads"),
  [CHART_LABELS.WEEKLY_SUM]: numberColumnDefinition( "weekly_sum", "Weekly Downloads", ),
  [CHART_LABELS.MONTHLY_SUM]: numberColumnDefinition( "monthly_sum", "Monthly Downloads", ),
  [CHART_LABELS.TOTAL_DOWNLOADS]: numberColumnDefinition( "total_downloads", "Total Downloads", ),
  [CHART_LABELS.TOTAL_DAYS]: numberColumnDefinition( "total_days", "Days in list", ),
  [CHART_LABELS.FIRST_SEEN]: {
    headerName: "First Seen",
    field: "first_seen",
    minWidth: 110,
    cellRenderer: (params) => {
      return moment(params.value * 1000).format("MMM DD, YY");
    },
  },
  [CHART_LABELS.TODAY_VIEWS]: numberColumnDefinition("todayViews", "Today Views"),
  [CHART_LABELS.TODAY_LIKES]: numberColumnDefinition("todayLikes", "Today Likes"),
  [CHART_LABELS.TODAY_COMMENTS]: numberColumnDefinition("todayComments", "Today Comments"),
  [CHART_LABELS.VIEWS]: numberColumnDefinition("views", "Views"),
  [CHART_LABELS.LIKES]: numberColumnDefinition("likes", "Likes"),
  [CHART_LABELS.COMMENTS]: numberColumnDefinition("comments", "Comments"),
  [CHART_LABELS.TOTAL_VOTES]: numberColumnDefinition("votes", "Total Votes"),
  [CHART_LABELS.DAYS_IN_TOP_10]: numberColumnDefinition("days_in_top_10", "Days in Top 10"),
};

const chartMap = {
  [CHART_LABELS.BROADCASTERS_TREND]: {
    headerName: "Broadcasters Trend",
    field: "monthly_avg_broadcasters",
    isChart: true,
  },
  [CHART_LABELS.FOLLOWERS_TREND]: {
    headerName: "New Followers Trend",
    field: "monthly_new_followers",
    isChart: true,
  },
  [CHART_LABELS.VIEWERS_TREND]: {
    headerName: "Viewers Trend",
    field: "monthly_avg_viewers",
    isChart: true,
  },
  [CHART_LABELS.VOTE_TREND]: {
    headerName: "Vote Trend",
    field: "monthly_votes",
    isChart: true,
  },
  [CHART_LABELS.DOWNLOAD_TRENDS]: {
    headerName: "Download Trend",
    field: "monthly_downloads",
    isChart: true,
  },
  [CHART_LABELS.PLAYERS_COUNT_TRENDS]: {
    headerName: "Players Trend",
    field: "monthly_player_count",
    isChart: true,
  },
  [CHART_LABELS.RECOMMENDATIONS_TRENDS]: {
    headerName: "Recommendations Trend",
    field: "monthly_recommendations",
    isChart: true,
  },
  [CHART_LABELS.POSITIVE_REVIEW_TRENDS]: {
    headerName: "Postive Review Trend",
    field: "monthly_positive_reviews",
    isChart: true,
  },
  [CHART_LABELS.BROADCASTERS_TREND]: {
    headerName: "Broadcasters Trend",
    field: "monthly_avg_broadcasters",
    isChart: true,
  },
  [CHART_LABELS.FOLLOWERS_TREND]: {
    headerName: "New Followers Trend",
    field: "monthly_new_followers",
    isChart: true,
  },
  [CHART_LABELS.PAGEVIEW_TREND]: {
    headerName: "PageView Trend",
    field: "last_30_days_views",
    isChart: true,
  },
  [CHART_LABELS.VIEWS_TREND]: {
    headerName: "Views Trend",
    field: "monthly_views",
    isChart: true,
  },
  [CHART_LABELS.LIKES_TREND]: {
    headerName: "Likes Trend",
    field: "monthly_likes",
    isChart: true,
  },
  [CHART_LABELS.COMMENTS_TREND]: {
    headerName: "Comments Trend",
    field: "monthly_comments",
    isChart: true,
  },
  [CHART_LABELS.SCORE_TRENDS]: {
    headerName: "Score Trend",
    field: "month_score",
    isChart: true,
  },
  [CHART_LABELS.FRANCHISE]: {
    headerName: "Franchise",
    field: "franchise",
    cellDataType: "object",
  },
  [CHART_LABELS.PLATFORM]: {
    headerName: "Platform",
    field: "platform",
    cellDataType: "object",
  },
  [CHART_LABELS.GENRE]: {
    headerName: "Genre",
    field: "genre",
    cellDataType: "object",
  },
  [CHART_LABELS.THEME]: {
    headerName: "Themes",
    field: "themes",
    cellDataType: "object",
  },
  [CHART_LABELS.CUSTOM_TAGS]: {
    headerName: "Custom Tags",
    field: "cTags",
    cellDataType: "object",
  },
  [CHART_LABELS.DEVELOPER]: {
    headerName: "Developer",
    field: "developer",
    cellDataType: "object",
  },
  [CHART_LABELS.PUBLISHER]: {
    headerName: "Publisher",
    field: "publisher",
    cellDataType: "object",
  },
  [CHART_LABELS.AGE_RATINGS]: {
    headerName: "Age Rating",
    field: "age_ratings",
    cellDataType: "object",
  },
  [CHART_LABELS.COUNTRIES]: {
    headerName: "Countries",
    field: "countries",
    cellDataType: "object",  },
  [CHART_LABELS.PLAYER_PERSPECTIVE]: {
    headerName: "Player Perspective",
    field: "player_perspectives",
    cellDataType: "object",
  },
  [CHART_LABELS.GAME_MODE]: {
    headerName: "Game Mode",
    field: "game_modes",
    cellDataType: "object",
  },
  [CHART_LABELS.COUNTRIES]: {
    headerName: "Countries",
    field: "countries",
    cellDataType: "object",
  },
  [CHART_LABELS.DISTRIBUTORS]: {
    headerName: "Distributors",
    field: "distributors",
    cellDataType: "object",
  },
  [CHART_LABELS.PRODUCTION]: {
    headerName: "Production",
    field: "production",
    cellDataType: "object",
  },
  [CHART_LABELS.OTT]: {
    headerName: "OTT",
    field: "providers",
    cellDataType: "object",
  },
};

export const useGridConfig = (
  selectedOptions,
  platformColumns: Array<string> = [],
  additionalPlatformColumns: Array<string> = [],
  vertical: string,
) => {
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      flex: 1,
      suppressHeaderMenuButton: true,
      filter: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      minWidth: 100,
      suppressMovable: true,
    }),
    [],
  );

  const columnDefs = useMemo(() => {
    const allColumns = [
      {
        field: "daily_rank_change",
        headerName: "Daily Rank Change",
        cellDataType: "number",
        filter: "agNumberColumnFilter",
        hide: true,
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      },
      {
        field: "daily_rank",
        headerName: "Rank",
        cellDataType: "number",
        maxWidth: 110,
        pinned: "left",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "daily_rank",
            "daily_rank_change",
            "daily_rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.daily_rank),
        valueFormatter: (params) => `${params.value}`,
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      },
      {
        field: "ip",
        headerName: "Title",
        cellDataType: "text",
        minWidth: 270,
        pinned: "left",
        filterParams: {
          filterOptions: stringFilterOptions,
        },
        cellRenderer: (params) => {
          const releaseYear = params.data.release_date ? new Date(params.data.release_date).getFullYear() : 'NA';
          const ipId = params.data.ip_id;
          const destination = `/item/${ipId}`;

          let iconVariant;
          if (params.data.tracked === true) {
            iconVariant = "success";
          } else if (params.data.tracked === false) {
            iconVariant = "disabled";
          } else {
            iconVariant = "disabled";
          }

          return (
            <div className="flex gap-2 mt-1.5">
              <Icon
                name={
                  params.data.ip_id != null
                    ? "status-positive"
                    : "status-negative"
                }
                variant={iconVariant}
              />
              <Link
                variant="secondary"
                href={destination}
                onClick={(e: any) => !ipId && e.preventDefault()}
              >
                {params.value}
              </Link>
              <span className="text-xs text-slate-500 mt-1">
                {releaseYear}
              </span>
            </div>
          );
        },
      },
      {
        field: "weekly_rank",
        headerName: "Weekly Rank",
        pinned: "left",
        maxWidth: 110,
        cellDataType: "number",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "weekly_rank",
            "weekly_rank_change",
            "weekly_rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.weekly_rank),
        valueFormatter: (params) => `${params.value}`,
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      },
      {
        field: "weekly_rank_change",
        headerName: "Weekly Rank Change",
        cellDataType: "number",
        filter: "agNumberColumnFilter",
        hide: true,
      },
      {
        field: "monthly_rank_change",
        headerName: "Monthly Rank Change",
        cellDataType: "number",
        filter: "agNumberColumnFilter",
        hide: true
      },
      {
        field: "monthly_rank",
        headerName: "Monthly Rank",
        pinned: "left",
        maxWidth: 110,
        cellDataType: "number",
        cellRenderer: (params) =>
          renderTrend(
            params,
            "monthly_rank",
            "monthly_rank_change",
            "monthly_rank_trend",
          ),
        valueGetter: (params) => parseInt(params.data.monthly_rank),
        valueFormatter: (params) => `${params.value}`,
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      },
      {
        field: "release_date",
        headerName: "Release Date",
        cellDataType: "date",
        hide: true,
      },
      {
        headerName: "Days since Release",
        cellDatatype: "number",
        cellRenderer: (params) => {
          const releaseDate = params.data.release_date;
          const daysSinceRelease = moment().diff(releaseDate, "days");
          return daysSinceRelease;
        }
      }
    ];

    const platformSpecificColumns = platformColumns?.map(
      (key) => platformColumnsMap[key],
    );

    const additionalColumns = additionalPlatformColumns
      .filter(
        (key: string) =>
          getCategories(key)?.includes(vertical) && !chartMap[key]?.isChart,
      )
      .map((key: string) => {
        const { field, headerName, cellDataType } = chartMap[key];
        const hide = !selectedOptions.some(({ value }) => value === key);
        return getAdditionalColumnDefinition(
          field,
          headerName,
          cellDataType,
          hide,
        );
      });

    const chartColumns = selectedOptions
      .filter(({ label }) => chartMap[label])
      .map(({ label }) => {
        const chart = chartMap[label];
        if (chart.isChart) {
          const { field, headerName } = chart;
          return getChartDefinition(field, headerName);
        }
        return null;
      })
      .filter(Boolean);

    return [
      ...allColumns,
      ...platformSpecificColumns,
      ...additionalColumns,
      ...chartColumns,
    ];
  }, [additionalPlatformColumns, platformColumns, selectedOptions, vertical]);

  const onGridReady = useCallback((params) => {
    const advancedFilterElement = document.getElementById(
      "advancedFilterParent",
    );

    if (advancedFilterElement) {
      params.api.setGridOption("advancedFilterParent", advancedFilterElement);
      params.api.setGridOption("includeHiddenColumnsInAdvancedFilter", true);
    }
  }, []);

  return { defaultColDef, columnDefs, onGridReady };
};
