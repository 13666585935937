import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import {
  Pagination,
  BreadcrumbGroup,
  SideNavigation,
  AppLayout,
  Container,
  ContentLayout,
  Header,
  Toggle,
  SpaceBetween,
  Flashbar,
} from "@cloudscape-design/components";
import {
  clearPantheonManagementData,
  getPantheonManagementData,
} from "./redux/actions/ip-management-actions";
import { useAuthContext } from "../../auth/useAuthContext";
import { navItems } from "../../layouts/common/menu/side-menu";
import axiosInstance from "../../utils/axios";
import createFlashMessage from "../../utils/createFlashMessage";
import PreferencesComponent from "./PreferencesComponent";
import TableView from "./TableView";
import Filtering from "./FilteringComponent";
import { isPresent } from "./utils";
import {
  addMessageToFlash,
  cleanAllFlashMessage,
} from "../common/redux/flash-action";
import CustomFlashBar from "../common/CustomFlashBar";

const DEFAULT_FILTERING_QUERY = { tokens: [], operation: "and" };

const breadcrumbs = [
  { text: "Settings" },
  { text: "IP Management" },
  { text: "Pantheon" },
];

export default function PantheonManagement() {
  const { user } = useAuthContext();
  const pantheonManagementData = useSelector(
    (state) => state.pantheonManagement
  );
  const [isFullWidth, setIsFullWidth] = useState(false);
  const [preferences, setPreferences] = useState({
    pageSize: 50,
    visibleContent: [
      "platforms",
      "type",
      "parentCompany",
      "poster",
      "description",
    ],
  });
  const [currentPage, setPage] = useState(1);
  const [filteringQuery, setFilteringQuery] = useState(DEFAULT_FILTERING_QUERY);

  const getPref = () => {
    axiosInstance
      .get(`/preferences/personal/${user.username}/pantheon`)
      .then((response) => response.data)
      .then((data) => {
        const pantheonPreferences = data?.ipmanagement?.pantheon?.preferences;
        if (pantheonPreferences) {
          setPreferences(pantheonPreferences);
        }
      })
      .catch((error) => {
        // Handle the error here
      });
  };

  const saveResponse = (pref) => {
    axiosInstance
      .request({
        url: `/preferences/personal/${user.username}/pantheon`,
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        data: JSON.stringify({
          ipmanagement: {
            pantheon: {
              preferences: pref,
            },
          },
        }),
      })
      .then((d) => {
        addMessageToFlash(
          createFlashMessage({
            type: "success",
            message: d.response?.data?.message,
          })
        );
        getPref();
      })
      .catch((e) => {
        addMessageToFlash(
          createFlashMessage({
            type: "error",
            message:
              e.response?.data?.message || "Failed to save data to the API.",
          })
        );
      });
  };

  const handlePreferencesChange = (newPreferences) => {
    saveResponse(newPreferences);
    setPreferences(newPreferences);
  };

  useEffect(() => {
    getPref();
    getPantheonManagementData();

    return () => {
      clearPantheonManagementData();
    };
  }, []);

  useEffect(() => {
    return () => cleanAllFlashMessage();
  }, []);

  useEffect(() => {
    if (pantheonManagementData.completed && pantheonManagementData.hasError) {
      addMessageToFlash(
        createFlashMessage({
          type: "error",
          message: pantheonManagementData.message,
        })
      );
    }
  }, [pantheonManagementData]);

  const items = useMemo(() => {
    if (pantheonManagementData.data) {
      let data = pantheonManagementData.data;
      const skip = (currentPage - 1) * preferences.pageSize;
      const limit = skip + preferences.pageSize;

      if (filteringQuery.tokens.length > 0) {
        const { tokens, operation } = filteringQuery;
        data = pantheonManagementData.data.filter(
          ({ ip, summary, vertical, parentCompany }) => {
            if (tokens.length === 1) {
              const key = tokens[0].propertyKey;
              const value = tokens[0].value;
              const operator = tokens[0].operator;
              return isPresent(key, value, operator, {
                ip,
                summary,
                vertical,
                parentCompany,
              });
            } else {
              const conditions = tokens.map((t) => {
                return isPresent(t.propertyKey, t.value, t.operator, {
                  ip,
                  summary,
                  vertical,
                  parentCompany,
                });
              });
              if (!conditions.includes(false)) return true;

              if (operation === "or") {
                const conditions = tokens.map((t) => {
                  return isPresent(t.propertyKey, t.value, t.operator, {
                    ip,
                    summary,
                    vertical,
                    parentCompany,
                  });
                });
                if (conditions.includes(true)) return true;

                return false;
              }

              return false;
            }
          }
        );
      }

      return { data: data.slice(skip, limit), total: data.length };
    }
    return null;
  }, [preferences, pantheonManagementData.data, currentPage, filteringQuery]);

  const totalIP = items?.total ?? "N/A";

  return (
    <>
      <Helmet>
        <title> Pantheon IP Management | Pantheon</title>
      </Helmet>
      <AppLayout
        disableContentPaddings={isFullWidth}
        stickyNotifications
        toolsHide
        // variant="embedded"
        headerSelector="#header"
        ariaLabels={{ navigationClose: "close" }}
        navigation={
          <SideNavigation
            activeHref={window.location.pathname}
            items={navItems}
          />
        }
        breadcrumbs={
          <div>
            <BreadcrumbGroup
              items={breadcrumbs}
              expandAriaLabel="Show path"
              ariaLabel="Breadcrumbs"
            />
          </div>
        }
        content={
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <ContentLayout
              header={
                <>
                  <Header
                    variant="h3"
                    counter={`(${totalIP})`}
                    actions={
                      <Toggle
                        checked={isFullWidth}
                        onChange={() => setIsFullWidth(!isFullWidth)}
                      >
                        
                        Full width
                      </Toggle>
                    }
                  >
                    Pantheon IP Management
                  </Header>
                  <div className="flex justify-between items-center gap-5 p-[10px]">
                    <div className="flex-1">
                      <Filtering
                        data={pantheonManagementData.data}
                        preferences={preferences}
                        query={filteringQuery}
                        handleChange={(detail) => {
                          setFilteringQuery(detail);
                        }}
                      />
                    </div>
                    <SpaceBetween direction="horizontal" size="l">
                      <Pagination
                        currentPageIndex={currentPage}
                        pagesCount={Math.ceil(
                          items?.total / preferences.pageSize
                        )}
                        onChange={(page) => {
                          setPage(page.detail.currentPageIndex);
                        }}
                      />
                      <PreferencesComponent
                        initialPreferences={preferences}
                        onPreferencesChange={handlePreferencesChange}
                      />
                    </SpaceBetween>
                  </div>

                  <div className="py-5">
                    <CustomFlashBar />
                  </div>
                </>
              }
            >
              <Container fitHeight>
                <TableView
                  items={items.data}
                  loading={pantheonManagementData.loading}
                  preferences={preferences}
                />
              </Container>
            </ContentLayout>
          </div>
        }
      />
    </>
  );
}
