
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../utils/axios";

const initialState = {
    data: [],
    loading: false,
    message: "",
    completed: false,
    hasError: false,
};

export const fetchItemChildrenData = createAsyncThunk(
    "itemData/fetchItemChildrenData",
  
    async (params) => {
      const response = await axiosInstance.request({
        url: `/ipmanagement/search`,
        method: `GET`,
        params: params,
      });
      return response;
    }
);

const itemChildrenDataSlice = createSlice({
    name: "itemChildrenData",
    initialState,
    reducers: {
      clearData: () => {
        return initialState;
      },
    },
  
    extraReducers: (builder) => {
      builder
        .addCase(fetchItemChildrenData.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchItemChildrenData.fulfilled, (state, action) => {
          state.loading = false;
          state.completed = true;
          state.data = action.payload.data;
        })
        .addCase(fetchItemChildrenData.rejected, (state, action) => {
          state.loading = false;
          state.completed = true;
          state.hasError = true;
          state.message = action.error.message;
        });
    },
});

const itemChildrenDataReducer = itemChildrenDataSlice.reducer;

export default itemChildrenDataReducer;

export const itemChildrenDataActions = itemChildrenDataSlice.actions;

