import { createSlice } from "@reduxjs/toolkit";

const editOrgInitialState = {
    isLoading:false,
    isCompleted:false,
    isError:false,
    message:""
}

const editOrgSlice = createSlice({
    name:"editOrg",
    initialState:editOrgInitialState,
    reducers:{
        loading:(state)=>{
            state.isLoading = true;
        },
        editOrgComepleted: (state, action) => {
            state.isLoading = false;
            state.message = "Organization Created"
            state.isCompleted = true;
            state.isError = false;
        },
        clearOrgCreateReducer:() => {
            return editOrgInitialState;
        },
        editOrgFailed:(state, action) => {
            state.isLoading = false;
            state.message = action.payload;
            state.isCompleted = true;
            state.isError = true;
        },
    }
});
export const editOrgAction = editOrgSlice.actions;
const editOrgReducer = editOrgSlice.reducer;
export default editOrgReducer;


